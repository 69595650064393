import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postFeedback } from 'api/services/common.services';

import { FeedbackSliceTypes, FeedbackTypes } from './feedback.types';

const initialState: FeedbackSliceTypes = {
  feedbackInformation: {
    rating: 3,
    feedback: 'feedback',
    images: [],
    description: '',
  },
};

export const sendFeedback = createAsyncThunk(
  'feedback/sendFeedback',
  async (data: FeedbackTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await postFeedback(data);
      dispatch(setFeedbackInfo(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const feedbackSlice = createSlice({
  name: 'feedbackSlice',
  initialState,
  reducers: {
    setFeedbackInfo(state, action: PayloadAction<FeedbackTypes>) {
      const { rating, feedback, description, images } = action.payload;
      return {
        ...state,
        feedbackInformation: {
          rating,
          feedback,
          description,
          images,
        },
      };
    },
  },
});

export const { setFeedbackInfo } = feedbackSlice.actions;

export default feedbackSlice.reducer;
