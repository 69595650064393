import { Box } from '@mui/material';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React from 'react';

import { CustomerOverview } from './customer-overview';

export function CustomerPage({ pathName, parentTab, homePage }) {
  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      <Box component='div' p='1rem'>
        <CustomerOverview />
      </Box>
    </PageWrapper>
  );
}
