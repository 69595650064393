import { Box, Button, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { useStyles } from './styles';
import type { StartCreateQMSProps } from './types';

export function StartCreateQMSComponent({
  onBack,
  onNext,
}: StartCreateQMSProps) {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.startCreateBox}>
      <Box
        component='div'
        display='flex'
        justifyContent='flex-start'
        width='100%'
      >
        <IconButton onClick={onBack}>
          <Icon name='arrow_back' fill='rgba(0, 0, 0, 0.87)' size={2} />
        </IconButton>
      </Box>
      <Icon name='paint-brush' />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap='1.5rem'
      >
        <Box component='div' textAlign='center'>
          <Typography component='h4' className={classes.startCreateTitle}>
            QM-System eigenständig erstellen
          </Typography>
          <Typography component='h6' className={classes.startCreateDescription}>
            Folge Schritt für Schritt unserem intutiven Onboarding und erstelle
            dein QM-System in Qsistant. Wir verhelfen dir effektiv zu einem
            zertifizierungsfähigem System.
          </Typography>
        </Box>
        <Button variant='contained' color='qsistant' onClick={onNext}>
          Erstellen starten
        </Button>
      </Box>
    </Box>
  );
}
