export { EvidenceLocationStep as EvidenceLocation } from './evidence-location.step';
export { CycleAuditsStep as CycleAudits } from './cycle-audits.step';
export { ScopeAuditsStep as ScopeAudits } from './scope-audits.step';
export { ResponsibilityStep as Responsibility } from './responsibility.step';
export { AuditCriteriaStep as AuditCriteria } from './audit-criteria.step';
export { TableListingAuditStep as TableListingAudit } from './table-listing-audit.step';
export { QualificationAuditsStep as QualificationAudits } from './qualification-audits.step';
// additional
export { DocumentStructureCardComponent as DocumentStructureCard } from './document-structure-card.component';
export { DocumentCompletedPage as DocumentCompleted } from './document-completed.page';
