/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

export enum ICON {
  PLUS = 'add',
  MINUS = 'minus',
  DONE = 'done',
  LABEL_OFF = 'label_off',
  ARROW_DROP_DOWN = 'arrow_drop_down',
  VISIBILITY_FILLED = 'visibility_filled',
  COPY_LINK = 'copy_link',
  INVISIBLE = 'visibility_off_filled',
  EDIT = 'edit',
  COMPARE = 'compare',
  COMMENT = 'comment',
  ARROW_BACK_IOS = 'arrow_back_ios',
  BOOKMARK = 'bookmark',
  BOOKMARK_FILLED = 'bookmark_filled',
  SHARE = 'share',
  PREMIUM = 'workspace_premium_filled',
  ARCHIVE = 'inventory',
  DOWNLOAD = 'download',
  RESTORE = 'restore_filled',
  RESTORE_FILE = 'restore_page',
  LOCK_FILLED = 'lock_filled',
  LOCK_OPEN = 'lock_open',
  SAVE = 'save_fill_rounded',
  MONITOR_HEART = 'monitor_heart',
  FACT_CHECK = 'fact_check',
  HOME = 'home',
  MAP = 'map',
  LIGHTNING = 'bolt',
  DOCUMENT = 'description',
  FILL_DOCUMENT = 'task',
  SCANNER = 'document_scanner',
  BUSINESS = 'business',
  HOUSE = 'other_houses',
  GROUP_PEOPLE = 'groups_filled',
  PROCESS = 'label',
  NEW_PROCESS = 'new_label_filled',
  REPEAT = 'published_with_changes',
  PROFILE_SETTINGS = 'manage_accounts_filled',
  SOURCE = 'source',
  SCIENCE_BOTTLE = 'science_black',
  UPLOAD_FILE = 'upload_file_rounded',
  ADD_DOCUMENT = 'note_add',
  CLOSE = 'close',
  FEEDBACK = 'feedback_filled',
  FEEDBACK_OUTLINED = 'feedback_outlined',
  ARROW_LEFT = 'arrow_back',
  CHECKED = 'check_circle',
  INFO = 'info',
  CATEGORY = 'rectangle',
  MORE = 'more',
  DELETE = 'delete',
  ADD_PERSON = 'person_add',
  REMOVE_PERSON = 'person_off',
  EDIT_PERSON = 'manage_accounts_filled',
  PRINT = 'print',
  EMAIL = 'email_filled',
  KEY = 'vpn_key_filled',
  OPEN_NEW_TAB = 'open_in_new',
  RENAME = 'text_fields',
  ARROW_EAST = 'arrow_east',
  ARROW_SOUTH = 'arrow_south',
  ARROW_NORTH = 'arrow_north',
  ARROW_SOUTH_EAST = 'arrow_south_east',
  ARROW_NORTH_EAST = 'arrow_north_east',
  UPLOAD = 'file_upload_fill_rounded',
  EMPTY_IMAGE = 'image_filled',
  ORGANIZATION_CHART = 'account',
  TEST_TUBE = 'science_white',
  QUESTION_ANSWER = 'question_answer',
  HISTORY = 'history',
  SCHEDULE_SEND = 'schedule_send',
  DESIGN_SERVICES = 'design_services',
  SEARCH = 'search',
  SEARCH_FILE = 'qs_green',
  SEARCH_QS_FILE = 'qs_icon_blue',
  QSISTANT_LOGO = 'new_logo',
  Q_LOGO = 'only_q_logo',
  MENU = 'menu',
  LOCATION = 'location_on',
  PLANET = 'public',
  NEXT_DOCUMENT = 'next_document',
  CREDIT_CARD = 'credit_card',
  LOGOUT = 'logout',
  DRAG_HANDLE = 'drag_handle',

  // ----images - empty document list or no filters----
  NO_DOCUMENT_TYPES = 'no_document_types',
  UNFILTER_DOCUMENT_TYPES = 'unfilter_document_type',
  UNFILTER_PROCESSES = 'unfilter_processes',
  NO_PROCESSES = 'no_processes_1',
  NOFILTERS_MEMBERS = 'no_filters_members',
  NO_DEPARTMENTS = 'no_departments',
  NOFILTERS_DEPARTMENTS = 'no_filters_departments',
  NO_ORGANIGRAMM = 'no_organigramm',
  NO_ARCHIVES = 'no_archives',
  NOFILTERS_ARCHIVE = 'no_filters_archive',
  NO_DOCUMENTS = 'no_documents',
  NO_FILTERS_DOCUMENTS = 'no_filters_documents',
  NO_DATA_BELL = 'no_data_bell',
  NO_DATA_FILES = 'no_data_files',
  NO_DATA_STARS = 'no_data_stars',
  NO_FOUND_DATA = 'no_results_img',
  NO_CONNECTION = 'no_connection',
  //--------------------------------------------------

  // -----------images New Features---------------------
  NEW_FEATURE = 'new_feature',
  NEW_FEATURE1 = 'new_feature_1',
  NEW_FEATURE2 = 'new_feature_2',
  NEW_FEATURE3 = 'new_feature_3',
  //----------------------------------------------------

  // ------------------images for upgrade plan modal
  LOW_MOUNTAIN = 'mountain_low',
  MID_MOUNTAIN = 'mountain_mid_blue',
  BIG_MOUNTAIN = 'mountain_big',
  // ---------------------------------------------------
}
