import { Avatar } from '@mui/material';
import { API_URL } from 'api/api-urls';
import { UserAvatarTypes } from 'components/user/user-avatar.types';

import React from 'react';

export const UserAvatarComponent = React.forwardRef<
  HTMLDivElement,
  UserAvatarTypes
>(({ user, className, ...rest }, ref) => {
  return (
    <Avatar
      ref={ref}
      sx={{ backgroundColor: user?.avatarColor }}
      className={className}
      src={
        !user?.avatar
          ? null
          : `${process.env.REACT_APP_API_URL}${API_URL.files.download}/${user?.avatar}?thumb=100`
      }
      {...rest}
    >
      {user?.prename?.charAt(0)}
    </Avatar>
  );
});

UserAvatarComponent.displayName = 'UserAvatarComponent';
