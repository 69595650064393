// TODO Change imports from CUSTOMER and PROCESSTYPE and remove this component
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

export function EmptyListComponent({
  icon = null,
  buttonLabel = null,
  title = null,
  text = null,
  onClick = null,
}) {
  const { t } = useTranslation('common');

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        marginTop: 184,
      }}
    >
      <img
        style={{ width: 215 }}
        src={icon || '/assets/test.svg'}
        alt='qsistant document'
      />
      <h1
        style={{
          fontWeight: 400,
          fontSize: 48,
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.8)',
        }}
      >
        {title || t('common:empty_title')}
      </h1>
      <p
        style={{
          fontWeight: 400,
          fontSize: 16,
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.8)',
        }}
      >
        {text || t('common:empty_text')}
      </p>
      <Button
        style={{
          marginTop: 21,
          width: 217,
          height: 37,
          background: '#4599BF',
          color: 'white',
        }}
        startIcon={<Add />}
        variant='filled'
        onClick={onClick}
      >
        {buttonLabel || t('common:create')}
      </Button>
    </div>
  );
}
