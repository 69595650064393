/* eslint-disable global-require */
import { Box, ThemeProvider } from '@mui/material';
import api from 'api/api';

import React, { useState } from 'react';

import { AuthContext } from './AuthContext';
import CheckEmail from './CheckEmail';
import LoginContent from './LoginContent';
import PasswordStrength from './PasswordStrength';
import ResetContent from './ResetContent';
import TFAuthentication from './TFAuthentication';
import VerifyAccount from './VerifyAccount';
import { pageBox, theme, video } from './theme';

function Login() {
  const { dispatch } = React.useContext(AuthContext);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentStage, setCurrentStage] = useState('login');
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    username: '',
    password: '',
  });
  const [newPassword, setNewPassword] = useState({
    password: '',
    confirmPassword: '',
  });
  const [verificationMethod, setVerificationMethod] = useState('');
  const [otp, setOtp] = React.useState('');

  const resetAction = (email) => {
    setError(false);
    setIsLoading(true);
    api
      .post(
        '/login/reset',
        {
          email,
        },
        {}
      )
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
        setCurrentStage('check_email');
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  const submitAction = (code) => {
    setError(false);
    setIsLoading(true);
    api
      .post(
        '/tfa_login',
        {
          email: values.username,
          password: values.password,
          code,
        },
        {}
      )
      .then((result) => {
        dispatch({
          type: 'LOGIN',
          user: result?.data?.user,
          token: result?.data?.token,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box component='div' sx={pageBox}>
        <Box component='video' loop autoPlay muted controls={false} sx={video}>
          <Box
            component='source'
            src={require('../../video/animatedgradient.mp4')}
            type='video/mp4'
          />
        </Box>
        {currentStage === 'login' ? (
          <LoginContent
            onSubmit={submitAction}
            values={values}
            setValues={setValues}
            isLoading={isLoading}
            onChangeStage={setCurrentStage}
            error={error}
            setError={setError}
          />
        ) : currentStage === 'forgot_password' ? (
          <ResetContent
            error={error}
            setError={setError}
            resetAction={(email) => resetAction(email)}
            success={success}
            setSuccess={setSuccess}
            isLoading={isLoading}
            onChangeStage={setCurrentStage}
          />
        ) : currentStage === 'check_email' ? (
          <CheckEmail onChangeStage={setCurrentStage} />
        ) : currentStage === 'password_strength' ? (
          <PasswordStrength
            value={newPassword}
            onChange={setNewPassword}
            onChangeStage={setCurrentStage}
          />
        ) : currentStage === 'tfa' ? (
          <TFAuthentication
            value={verificationMethod}
            onChange={setVerificationMethod}
            onChangeStage={setCurrentStage}
          />
        ) : currentStage === 'auth_account' ? (
          <VerifyAccount
            onChangeStage={setCurrentStage}
            value={otp}
            onChange={setOtp}
            onSubmit={submitAction}
            error={error}
            setError={setError}
            email={values.username}
            loading={isLoading}
          />
        ) : null}
      </Box>
    </ThemeProvider>
  );
}

export default Login;
