import { Box, Divider, List, Typography, useMediaQuery } from '@mui/material';
import { ListItem } from 'components/list-item';
import {
  handleActiveList,
  handleModalHasChanges,
  handleQAModal,
  hideSidebar,
  setDiscardedRoute,
} from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BottomMenu } from './components/bottom-menu';
import { SidebarProps } from './sidebar.types';
import { labelStyle, sidebarWrapper } from './styles';

export function SidebarComponent({ config, openFeedback, open }: SidebarProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const middleBreakpoint = useMediaQuery('(max-width:1200px)');

  const { activeTabValue, activeListItem, hasChanges } = useAppSelector(
    (state) => ({
      activeTabValue: state.commonSlice.activeTabValue,
      activeListItem: state.commonSlice.activeListItem,
      hasChanges: state.commonSlice.hasChanges,
    })
  );

  const handleListItem = (itemList: string, i: number, route: string) => {
    if (itemList === 'Schnellzugriff') {
      dispatch(handleQAModal(true));
    } else {
      navigate(route);
      dispatch(
        handleActiveList({
          parent: activeTabValue,
          item: i,
        })
      );
    }
  };

  const navigateTo = (path: string, iIndex: number, lItem: string) => {
    if (hasChanges) {
      dispatch(handleModalHasChanges(true));
      dispatch(setDiscardedRoute({ route: path, tab: 2, item: iIndex }));
      return;
    }
    handleListItem(lItem, iIndex, path);
  };

  useEffect(() => {
    if (middleBreakpoint) {
      dispatch(hideSidebar());
    }
  }, [middleBreakpoint]);

  return (
    <Box
      component='nav'
      sx={{
        width: open || middleBreakpoint ? '4.5rem' : '15rem',
        ...sidebarWrapper,
      }}
    >
      <List sx={{ width: open || middleBreakpoint ? '100%' : '97%' }}>
        {config.map((item, index) => (
          <React.Fragment key={item.id}>
            <ListItem
              showSidebar={open}
              middleBreakpoint={middleBreakpoint}
              item={item}
              selected={activeListItem[activeTabValue] === index}
              onClick={() => navigateTo(item.path, index, item.list)}
            />
            {item.divider && <Divider sx={{ margin: '0.75rem 0' }} />}
            {item.label && !open && !middleBreakpoint && (
              <Typography component='p' sx={{ ...labelStyle }}>
                {item.label}
              </Typography>
            )}
          </React.Fragment>
        ))}
      </List>
      <BottomMenu
        sidebarSqueezed={open || middleBreakpoint}
        onOpen={openFeedback}
      />
    </Box>
  );
}
