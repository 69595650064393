import { Box, Button, Typography } from '@mui/material';
import { FabButton } from 'components/button';
import { FiltersBox, SortFilterBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { FilterDropdown } from 'components/filter-dropdown';
import { Icon } from 'components/icon';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { ModalCreateNewSupportDocument } from 'modals/create-new-document';
import { routes } from 'routes/routes';
import { handleActiveList } from 'store/common/common-slice';
import {
  resetFilters,
  setMultipleFilters,
} from 'store/common/page-filters/page-filters-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  fetchDocuments,
  handleModal,
} from 'store/qsistant/supporting-documents/supporting-documents-slice';
import type { SupportingDocumentTypes } from 'store/qsistant/supporting-documents/types';
import { sortByCriteria, tableMultipleFiltersChange } from 'utils/utils';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { sortByOptions } from './supporting-documents.constants';
import useTableCells from './table-cells.hook';

export function SupportingDocumentsPage({ pathName, parentTab, homePage }) {
  const { t } = useTranslation(['sort', 'status', 'document', 'fillout']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    documents,
    modal,
    statusList,
    listOfOrganizations,
    listOfUsers,
    status,
  } = useAppSelector((state) => ({
    documents: state.supportingDocumentsReducer.documents,
    modal: state.supportingDocumentsReducer.modal,
    statusList: state.supportingDocumentsReducer.statusList,
    listOfOrganizations: state.supportingDocumentsReducer.listOfOrganizations,
    listOfUsers: state.supportingDocumentsReducer.listOfUsers,
    status: state.supportingDocumentsReducer.status,
  }));

  const {
    persistedStatus,
    persistedFormTemplate,
    persistedOrganization,
    persistedPeson,
  } = useAppSelector((state) => ({
    persistedStatus:
      state.pageFiltersSlice.supportingDocumentsPage.persistedStatus,
    persistedFormTemplate:
      state.pageFiltersSlice.supportingDocumentsPage.persistedFormTemplate,
    persistedOrganization:
      state.pageFiltersSlice.supportingDocumentsPage.persistedOrganization,
    persistedPeson:
      state.pageFiltersSlice.supportingDocumentsPage.persistedPeson,
  }));

  const { cells } = useTableCells();

  const [sortBy, setSortBy] = useState<(typeof sortByOptions)[number]['value']>(
    sortByOptions[0].value
  );
  const [filtered, setFiltered] = useState<SupportingDocumentTypes[]>(null);

  const [filters, setFilters] = useState({
    person: persistedPeson || [''],
    organization: persistedOrganization || [''],
    formTemplate: persistedFormTemplate || [''],
    status: persistedStatus || [''],
  });

  const goToOverview = () => {
    navigate(routes().document);
    dispatch(handleActiveList({ parent: 0, item: 3 }));
  };

  const handleResetFilters = () => {
    dispatch(resetFilters({ key: 'supportingDocumentsPage' }));
    setFilters({
      formTemplate: [''],
      organization: [''],
      person: [''],
      status: [''],
    });
  };

  const filterByOptions = (
    arrayToFilter: Array<SupportingDocumentTypes>,
    filter1: string[],
    filter2: string[],
    filter3: string[],
    filter4: string[]
  ) =>
    arrayToFilter.filter((item) => {
      if (filter1.length > 1 && !filter1.includes(item.status)) {
        return false;
      }
      // if (filter2.length > 1 && !filter2.includes(item.filed1)) {
      //   return false;
      // }
      if (
        filter3.length > 1 &&
        item.users.some((user) =>
          filter3.slice(1).includes(user.organization.name)
        )
      ) {
        return false;
      }
      if (filter4.length > 1 && !filter4.includes(item.creator.fullName)) {
        return false;
      }
      return true;
    });

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const filtersRes = filterByOptions(
      documents,
      filters.status,
      filters.formTemplate,
      filters.organization,
      filters.person
    );
    const sorting = sortByCriteria(filtersRes, sortBy);
    setFiltered(sorting);
  }, [
    filters.status,
    documents,
    filters.formTemplate,
    filters.organization,
    filters.person,
    sortBy,
  ]);

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      {status === 'pending' || filtered === null || status === null ? (
        <Box component='div' mt={2}>
          {cells.map((cell) => (
            <TableSkeleton key={cell.id} />
          ))}
        </Box>
      ) : documents.length > 0 && status === 'resolved' ? (
        <>
          <Typography component='p' className='PageInfo'>
            Insgesamt {filtered && filtered.length} Nachweisdokumente verfügbar.
          </Typography>
          <SortFilterBox>
            <FiltersBox>
              <FilterDropdown
                multiple
                value={filters.status}
                isFilterApplied={filters.status.length > 1}
                onChange={(e) => {
                  tableMultipleFiltersChange(e, 'status', setFilters);
                  dispatch(
                    setMultipleFilters({
                      key: 'supportingDocumentsPage',
                      subKey: 'persistedStatus',
                      values: e.target.value as string[],
                    })
                  );
                }}
                options={statusList.map((item) => ({
                  ...item,
                  label: t(`status:${item.label}`),
                }))}
                filterLabel={t('sort:status')}
              />
              <FilterDropdown
                multiple
                disabled
                value={filters.formTemplate}
                isFilterApplied={filters.formTemplate.length > 1}
                onChange={(e) => {
                  tableMultipleFiltersChange(e, 'formTemplate', setFilters);
                  dispatch(
                    setMultipleFilters({
                      key: 'supportingDocumentsPage',
                      subKey: 'persistedFormTemplate',
                      values: e.target.value as string[],
                    })
                  );
                }}
                options={[{ id: 1, label: 'Empty', value: 'Empty' }]}
                filterLabel={t('sort:formtemplate')}
              />
              <FilterDropdown
                multiple
                value={filters.organization}
                isFilterApplied={filters.organization.length > 1}
                onChange={(e) => {
                  tableMultipleFiltersChange(e, 'organization', setFilters);
                  dispatch(
                    setMultipleFilters({
                      key: 'supportingDocumentsPage',
                      subKey: 'persistedOrganization',
                      values: e.target.value as string[],
                    })
                  );
                }}
                options={listOfOrganizations}
                filterLabel={t('sort:customerorsupplier')}
              />
              <FilterDropdown
                multiple
                value={filters.person}
                isFilterApplied={filters.person.length > 1}
                onChange={(e) => {
                  tableMultipleFiltersChange(e, 'person', setFilters);
                  dispatch(
                    setMultipleFilters({
                      key: 'supportingDocumentsPage',
                      subKey: 'persistedPeson',
                      values: e.target.value as string[],
                    })
                  );
                }}
                options={listOfUsers}
                filterLabel={t('sort:person')}
              />
              <Button
                variant='text'
                color='primary'
                disabled={[
                  filters.status,
                  filters.formTemplate,
                  filters.organization,
                  filters.person,
                ].every((filter) => filter.length === 1)}
                onClick={handleResetFilters}
              >
                {t('sort:resetfilter')}
              </Button>
            </FiltersBox>
            <SortSelect
              options={sortByOptions}
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              noOneChosen={sortBy === sortByOptions[0].value}
            />
          </SortFilterBox>
          {filtered && filtered.length > 0 ? (
            <>
              <Table cells={cells} data={filtered} />
              <Box component='div' display='flex' justifyContent='center'>
                <FabButton
                  className='AbsolutePosition primary'
                  onClick={() => dispatch(handleModal(true))}
                  label={t('fillout:addfilloutdocument')}
                />
              </Box>
            </>
          ) : (
            <EmptyList
              image={{ name: ICON.NO_FILTERS_DOCUMENTS }}
              title='Wir konnten keine Nachweise dazu finden.'
              primaryButton={
                <Button
                  variant='outlined'
                  color='qsistant'
                  onClick={handleResetFilters}
                >
                  {t('document:resetfilter')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <EmptyList
          image={{ name: ICON.NO_DOCUMENTS }}
          title='Du hast noch keine Nachweise erstellt oder hochgeladen.'
          description='Du hast noch keine Nachweise. Füge mit Hilfe der Buttons “Nachweis hochladen” und Nachweis ableiten deine ersten Nachweise hinzu.'
          primaryButton={
            <Button
              variant='contained'
              color='qsistant'
              onClick={() => dispatch(handleModal(true))}
              startIcon={<Icon name={ICON.UPLOAD_FILE} fill='white' />}
            >
              {t('fillout:uploadfilloutdocument')}
            </Button>
          }
          secondaryButton={
            <Button
              variant='text'
              color='qsistant'
              onClick={goToOverview}
              startIcon={<Icon name={ICON.LIGHTNING} fill='inherit' />}
            >
              {t('fillout:getfilloutdocument')}
            </Button>
          }
        />
      )}
      {modal && (
        <ModalCreateNewSupportDocument
          open={modal}
          onClose={() => dispatch(handleModal(false))}
        />
      )}
    </PageWrapper>
  );
}
