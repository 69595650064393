import { PROJECT_TABS } from 'enum/common.enum';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';

export const productDevSidebarConfig = [
  {
    id: 1,
    list: 'Produkte',
    icon: {
      name: ICON.SCIENCE_BOTTLE,
      activeColor: PROJECT_TABS.PRODUCT,
    },
    path: routes().productDevelopment,
    label: 'MATERIALIEN',
    divider: true,
  },
  {
    id: 2,
    list: 'Dokumentenübersicht',
    icon: { name: ICON.DOCUMENT, activeColor: PROJECT_TABS.PRODUCT },
    path: routes().documentOverview,
    squeezedLabel: 'Dokumente',
  },
  {
    id: 3,
    list: 'Dateiübersicht',
    icon: { name: ICON.SOURCE, activeColor: PROJECT_TABS.PRODUCT },
    path: routes().fileOverview,
    squeezedLabel: 'Dateien',
  },
  {
    id: 5,
    list: 'Aktenarchiv',
    icon: { name: ICON.ARCHIVE, activeColor: PROJECT_TABS.PRODUCT },
    path: routes().fileArchive,
    divider: true,
    squeezedLabel: 'Akten',
  },
];

export const datasetData = [
  {
    id: `${crypto.randomUUID()}`,
    label: 'Maxime Meister',
    value: 'Maxime Meister',
    avatar: '1',
  },
  {
    id: `${crypto.randomUUID()}`,
    label: 'Berit Sperber',
    value: 'Berit Sperber',
    avatar: '2',
  },
  {
    id: `${crypto.randomUUID()}`,
    label: 'Alea Giebert',
    value: 'Alea Giebert',
    avatar: '3',
  },
];
