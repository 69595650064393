import { Box, Button, TextField, Typography } from '@mui/material';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useState } from 'react';

import type { ModalCreateOptionProps } from './create-option.modal.types';

export function ModalCreateOption({
  open,
  onClose,
  onCreate,
  color,
}: ModalCreateOptionProps) {
  const [value, setValue] = useState('');
  return (
    <ModalWrapper
      open={open}
      sx={{ width: 'max-content', padding: '1rem 1.5rem' }}
    >
      <Typography component='h6' className='ModalHeader'>
        Erstellen Sie Ihre eigene Option.
      </Typography>
      <TextField
        fullWidth
        margin='dense'
        color={color}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Box component='div' display='flex' justifyContent='flex-end'>
        <Button
          variant='text'
          onClick={onClose}
          color={color}
          sx={{ textTransform: 'capitalize', letterSpacing: '0.08rem' }}
        >
          Abbrechen
        </Button>
        <Button
          variant='text'
          color={color}
          disabled={!value}
          sx={{ textTransform: 'capitalize', letterSpacing: '0.08rem' }}
          onClick={() => {
            if (!value.trim()) return;
            onCreate(value);
            onClose();
          }}
        >
          Schaffen
        </Button>
      </Box>
    </ModalWrapper>
  );
}
