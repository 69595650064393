import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { useEffect } from 'react';

import { Icon } from '../icon';

export function CustomSelectComponent({
  fullWidth = false,
  label,
  variant = 'outlined',
  value,
  onChange,
  listData,
  className,
  defaultValue,
  sx,
  color,
  edit,
}) {
  const dropdownIcon = (props) => (
    <IconButton sx={{ padding: 0 }} {...props}>
      <Icon name='arrow_drop_down' fill={edit ? '#7F7120' : '#45BD81'} />
    </IconButton>
  );

  const renderValue = (selectedOption) => {
    const selectedItem = listData.find((item) => item.label === selectedOption);
    const displayText = edit ? 'Bearbeiten' : 'Vorschau';
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          pl: '4.75px',
          maxHeight: '36px',
        }}
      >
        {selectedItem.name ? (
          <Icon
            name={selectedItem.name}
            size={selectedItem.size || 1.5}
            fill={edit ? '#7F7120' : '#45BD81'}
          />
        ) : (
          <AccountCircle />
        )}
        <Typography
          component='p'
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            color: edit ? '#7F7120' : '#45BD81',
            letterSpacing: 0,
          }}
        >
          {selectedOption === '' ? displayText : selectedOption}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {}, [edit]);
  return (
    <TextField
      select
      sx={{ ...sx }}
      color={color}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      label={label}
      variant={variant}
      value={value}
      className={className}
      onChange={onChange}
      edit={edit}
      InputProps={{
        disableUnderline: true,
      }}
      SelectProps={{
        renderValue,
        IconComponent: (props) => dropdownIcon(props),
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          sx: {
            '& .MuiPaper-root': {
              backgroundColor: '#fff',
              mr: '-12px',
              '& .MuiMenu-list': {
                maxHeight: '15rem',
                width: '18.4375rem',
                mr: '-1rem',
                '& .Mui-selected': {
                  backgroundColor: '#00000014',
                },
              },
            },
          },
        },
      }}
    >
      {listData &&
        listData.map((item) => (
          <MenuItem
            key={item.id}
            value={item?.label ? item.label : item.name}
            // disabled={!!(!saved && item.label === 'Vorschau')}
            sx={{ gap: '1rem' }}
          >
            {item.name ? (
              <Icon
                name={item.name}
                size={item.size || 1.5}
                fill={item.fill || '#00000099'}
              />
            ) : null}
            <Box component='div'>
              <ListItemText
                sx={{
                  '& > span': {
                    fontSize: '1rem',
                    fontWeight: 500,
                    color: '#000000DE',
                    letterSpacing: '0.5px',
                    lineHeight: '1rem',
                    textTransform: 'capitalize',
                    mb: '2px',
                  },
                }}
                primary={item.label}
              />
              <Typography
                component='p'
                sx={{
                  color: '#00000099',
                  letterSpacing: '0.21px',
                  fontSize: '0.75rem',
                }}
              >
                {item?.subLabel}
              </Typography>
            </Box>
          </MenuItem>
        ))}
    </TextField>
  );
}
