import { Box, Button, Typography } from '@mui/material';
import { InputField } from 'components/custom-input';
import { Icon } from 'components/icon';

import React, { useState } from 'react';

import { isEmpty } from 'lodash';

import { useStyles } from './styles';
import type { AuditCriteriaStepProps } from './types';

export function AuditCriteriaStep({
  stepId,
  selectedOption,
  createField,
  editField,
  onRemove,
  toNextStep,
}: AuditCriteriaStepProps) {
  const classes = useStyles();

  const [isCreation, setIsCreation] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');

  return (
    <>
      <Typography component='h4' className={classes.actionTitle}>
        Welche sind Ihre Auditkriterien?
      </Typography>
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        gap='1rem'
        mb='2rem'
      >
        {selectedOption.length > 0 &&
          selectedOption.map(
            (audit: { id: string | number; label: string }) => (
              <InputField
                key={audit.id}
                className={classes.cycleAudits}
                customWidth
                label={audit.label}
                onChange={(e) =>
                  editField(
                    { id: audit.id.toString(), label: e.target.value },
                    stepId
                  )
                }
                onDelete={() => onRemove(audit.id, stepId)}
                {...audit}
              />
            )
          )}
        {isCreation && (
          <InputField
            label={label}
            className={classes.cycleAudits}
            onChange={(e) => setLabel(e.target.value)}
            onBlur={() => {
              if (!label) return;
              setIsCreation(false);
              createField(label, stepId);
              setLabel('');
            }}
          />
        )}
      </Box>
      <Box component='div'>
        <Button
          variant='text'
          color='documentSetup'
          onClick={() => setIsCreation(true)}
          startIcon={<Icon name='add' fill='#6E358C' size={1.5} />}
        >
          AUDITKRITERIUM HINZUFÜGEN
        </Button>
      </Box>
      <Box component='div' display='flex' justifyContent='flex-end' mt='2rem'>
        <Button
          variant='contained'
          color='documentSetup'
          disabled={isEmpty(selectedOption)}
          onClick={() =>
            toNextStep(
              selectedOption as Array<{ id: string | number; label: string }>,
              stepId
            )
          }
        >
          EINGABEN SPEICHERN & WEITER
        </Button>
      </Box>
    </>
  );
}
