import {
  Avatar,
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { shortName } from 'utils/utils';

import React from 'react';
import { useTranslation } from 'react-i18next';

import type { UneditableContentProps } from './types';

const gridBox = {
  display: 'grid',
  gridTemplateRows: '1.5fr 1fr',
};

export function UneditableContent({
  documentType,
  revision,
  documentNumber,
  processCategory,
  processName,
  approved,
  expirydate,
  creatorName,
}: UneditableContentProps) {
  const { t } = useTranslation(['common', 'document', 'status']);

  return (
    <Stack
      direction='row'
      divider={<Divider orientation='vertical' flexItem />}
      spacing={2}
    >
      <Box component='div'>
        <Box component='div' mb='1.25rem'>
          <Typography component='p' className='SDCaption'>
            {t('document:documenttype')}
          </Typography>
          <Typography component='p' className='SDText'>
            {documentType}
          </Typography>
        </Box>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            {t('document:number')}
          </Typography>
          <Typography component='p' className='SDText'>
            {documentNumber}
          </Typography>
          <Typography component='p' className='SDText'>
            {t('document:revisiontext', { revision })}
          </Typography>
        </Box>
      </Box>
      <Box component='div'>
        <Box component='div' mb='1.25rem'>
          <Typography component='p' className='SDCaption'>
            {t('document:processcategory')}
          </Typography>
          <Box component='div' display='flex' gap='0.25rem' alignItems='center'>
            <Icon name={ICON.CATEGORY} size={0.8} fill='rgba(0, 0, 0, 0.87)' />
            <Typography component='p' className='SDText'>
              {processCategory}
            </Typography>
          </Box>
        </Box>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            {t('document:subprocess')}
          </Typography>
          <Box component='div' display='flex' gap='0.25rem' alignItems='center'>
            <Icon name={ICON.PROCESS} size={0.8} fill='rgba(0, 0, 0, 0.87)' />
            <Typography component='p' className='SDText'>
              {processName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box component='div'>
        <Box component='div' display='flex' gap='3rem' mb='1.25rem'>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              {t('document:releaser')}
            </Typography>
            <Box
              component='div'
              display='flex'
              alignItems='center'
              gap='0.25rem'
            >
              {approved ? (
                <>
                  <Tooltip title={creatorName}>
                    <Avatar className='SDAvatar' />
                  </Tooltip>
                  <Typography component='p' className='SDText'>
                    {shortName(creatorName)}
                  </Typography>
                </>
              ) : (
                '-'
              )}
            </Box>
          </Box>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              {t('document:updater')}
            </Typography>
            <Tooltip title={creatorName}>
              <Avatar alt={creatorName} className='SDAvatar' />
            </Tooltip>
          </Box>
        </Box>
        <Box component='div' display='flex' justifyContent='space-between'>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              {t('document:releasedate')}
            </Typography>
            <Typography component='p' className='SDText'>
              {approved ? new Date(approved).toLocaleDateString() : '-'}
            </Typography>
          </Box>
          <Box>
            <Typography component='p' className='SDCaption'>
              {t('document:expirydate')}
            </Typography>
            <Box
              component='div'
              display='flex'
              alignItems='center'
              gap='0.25rem'
            >
              <Typography component='p' className='SDText'>
                {expirydate ? new Date(expirydate).toLocaleString() : '-'}
              </Typography>
              <Icon name={ICON.PLANET} size={0.8} fill='rgba(0, 0, 0, 0.87' />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component='div'>
        <Box component='div' mb='1.25rem'>
          <Typography component='p' className='SDCaption'>
            {t('document:customer')}
          </Typography>
          <Typography component='p' className='SDText'>
            Weber Instrumente GmbH & Co. KG
          </Typography>
        </Box>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            {t('document:locationofpurpose')}
          </Typography>
          <Box component='div' display='flex' gap='0.25rem' alignItems='center'>
            <Icon name={ICON.LOCATION} size={0.8} fill='rgba(0, 0, 0, 0.87' />
            <Typography component='p' className='SDText'>
              78576 Emmingen-Liptingen
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box component='div' sx={gridBox}>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            {t('document:assignedstandard')}
          </Typography>
          <Typography component='p' className='SDText'>
            DIN EN ISO 9001:2015
          </Typography>
          <Typography component='p' className='SDText'>
            DIN EN ISO 9001:2015
          </Typography>
        </Box>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            {t('document:assignedstandardchapter')}
          </Typography>
          <Typography component='p' className='SDText'>
            5.1 Führung & Verpflichtung
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
}
