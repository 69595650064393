import { Box, Button, SelectChangeEvent, Typography } from '@mui/material';
import { FabButton } from 'components/button';
import { FiltersBox, SortFilterBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { FilterDropdown } from 'components/filter-dropdown';
import { Icon } from 'components/icon';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { DepartmentAddModal } from 'modals/department';
import { routes } from 'routes/routes';
import {
  addDepartment,
  fetchDepartments,
  handleModal,
} from 'store/administration/departments/departments-slice';
import type { DepartmentTypes } from 'store/administration/departments/types';
import { handleActiveList, handleTabValue } from 'store/common/common-slice';
import {
  resetFilters,
  setMultipleFilters,
} from 'store/common/page-filters/page-filters-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { multipleFiltersChange } from 'utils/utils';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { sortOptions } from './constants';
import useTableCells from './table-cells.hook';

export function DepartmentsPage({ pathName, parentTab, homePage }) {
  const { t } = useTranslation(['status', 'department', 'common', 'document']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    departments: documents,
    modal,
    departmentsList,
    status,
  } = useAppSelector((state) => ({
    departments: state.departmentsReducer.departments,
    modal: state.departmentsReducer.modal,
    departmentsList: state.departmentsReducer.departmentsList,
    status: state.departmentsReducer.fetchStatus,
  }));

  const { persistedParentDeps } = useAppSelector((state) => ({
    persistedParentDeps:
      state.pageFiltersSlice.departmentsPage.persistedParentDeps,
  }));

  const [filtered, setFiltered] = useState<DepartmentTypes[]>(null);
  const [sortBy, setSortBy] = useState<(typeof sortOptions)[number]['value']>(
    sortOptions[0].value
  );
  const [departments, setDepartments] = useState<Array<string>>(
    persistedParentDeps || ['']
  );

  const { cells } = useTableCells();

  const filterArrayByOptions = (
    arrayToFilter: DepartmentTypes[],
    options: Array<string>
  ) => {
    if (options.length === 1) {
      return arrayToFilter;
    }
    return arrayToFilter.filter((doc) => options.includes(doc.name));
  };

  const sortByCriteria = (array: DepartmentTypes[], criteria: string) => {
    const modifiedArray = [...array];
    switch (criteria) {
      case 'default':
        break;
      case 'alphabet ascending':
        modifiedArray.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'alphabet descending':
        modifiedArray.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'last modified ascending':
        modifiedArray.sort(
          (a, b) =>
            new Date(a.updated).getDate() - new Date(b.updated).getDate()
        );
        break;
      case 'last modified descending':
        modifiedArray.sort(
          (a, b) =>
            new Date(b.updated).getDate() - new Date(a.updated).getDate()
        );
        break;
      default:
        break;
    }
    return modifiedArray;
  };

  const handleFilter = (e: SelectChangeEvent<string | string[]>) => {
    multipleFiltersChange(e, setDepartments);
    dispatch(
      setMultipleFilters({
        key: 'departmentsPage',
        subKey: 'persistedParentDeps',
        values: e.target.value as string[],
      })
    );
  };

  const resetFilter = () => {
    dispatch(resetFilters({ key: 'departmentsPage' }));
    setDepartments(['']);
  };

  const handleAddDepartment = (data: {
    name: string;
    parent: number;
    id: number;
  }) => {
    dispatch(addDepartment(data));
    dispatch(handleModal(false));
  };

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const filtersRes = filterArrayByOptions(documents, departments);
    const sorting = sortByCriteria(filtersRes, sortBy);
    setFiltered(sorting);
  }, [documents, departments, sortBy]);

  const navigateToPage = () => {
    navigate(routes().organigramm);
    dispatch(handleTabValue(0));
    dispatch(handleActiveList({ parent: 0, item: 3 }));
  };

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      button={
        <Button
          variant='outlined'
          color='qsistant'
          startIcon={<Icon name={ICON.ORGANIZATION_CHART} fill='inherit' />}
          onClick={navigateToPage}
        >
          {t('department:toorganigramm')}
        </Button>
      }
    >
      {status === 'pending' || filtered === null || status === null ? (
        <Box component='div' mt={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <TableSkeleton key={item} />
          ))}
        </Box>
      ) : status === 'resolved' && documents.length > 0 ? (
        <>
          <Typography component='p' className='PageInfo'>
            {t('department:numberofdepartments', { count: filtered.length })}
          </Typography>
          <SortFilterBox>
            <FiltersBox>
              <FilterDropdown
                multiple
                value={departments}
                isFilterApplied={departments.length > 1}
                onChange={handleFilter}
                options={departmentsList}
                primaryColor='management'
                filterLabel={t(`sort:departmentparent`)}
              />
              <Button
                variant='text'
                color='management'
                disabled={departments.length === 1}
                onClick={resetFilter}
              >
                {t(`sort:resetfilter`)}
              </Button>
            </FiltersBox>
            <SortSelect
              options={sortOptions}
              value={sortBy}
              primaryColor='management'
              onChange={(e) => setSortBy(e.target.value)}
              noOneChosen={sortBy === sortOptions[0].value}
            />
          </SortFilterBox>
          {filtered && filtered.length > 0 ? (
            <>
              <Table cells={cells} data={filtered} />
              <Box component='div' display='flex' justifyContent='center'>
                <FabButton
                  label={t('department:new')}
                  className='AbsolutePosition management'
                  onClick={() => dispatch(handleModal(true))}
                />
              </Box>
            </>
          ) : (
            <EmptyList
              image={{ name: ICON.NOFILTERS_DEPARTMENTS }}
              title='Wir konnten keine Abteilungen finden.'
              primaryButton={
                <Button
                  variant='outlined'
                  color='management'
                  onClick={resetFilter}
                >
                  {t('document:resetfilter')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <EmptyList
          image={{ name: ICON.NO_DEPARTMENTS }}
          title='Du hast noch keine Abteilungen erstelllt.'
          description='Füge mit Hilfe des Buttons “Abteilung hinzufügen” deine erste Abteilung hinzu.'
          primaryButton={
            <Button
              variant='contained'
              color='management'
              onClick={() => dispatch(handleModal(true))}
            >
              <Icon name={ICON.PLUS} size={1.5} fill='white' />
              {t('department:add')}
            </Button>
          }
        />
      )}
      {modal && (
        <DepartmentAddModal
          closeModal={() => dispatch(handleModal(false))}
          handleSuccess={(data) => handleAddDepartment(data)}
          modal={modal}
          icon={{ name: ICON.HOUSE, color: 'management' }}
        />
      )}
    </PageWrapper>
  );
}
