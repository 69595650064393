export const API_URL = {
  documentOverview: {
    getAll: '/document/list',
  },
  quickaccess: {
    get: '/document/fastaccess',
  },
  changemanagement: {
    get: '/organization/changemanagement',
    updateChange: '/organization/changemanagement/change',
    updateExpiry: '/organization/changemanagement/expiry',
  },
  organization: {
    get: '/organization/get',
    save: '/organization/update',
  },
  supportingDocuments: {
    getAll: '/document-fill/list',
    getVersions: '/document/getversions',
  },
  archiveDocuments: {
    getAll: '/document/archive/list',
    restore: '/document/archive/restore',
  },
  singleDocument: {
    getSingle: '/document/get',
    removeFavourite: '/document/favourite/remove',
    addFavourite: '/document/favourite/add',
    addToArchive: '/document-fill/archive',
  },
  files: {
    download: '/files/download',
  },
  departments: {
    delete: '/department/delete',
    edit: '/department/edit',
    add: '/department/add',
    getAll: '/department/list',
  },
  onboarding: {
    save: '/onboarding/save',
    verifytoken: '/onboarding/verifytoken',
  },
  standards: {
    getAll: '/standards',
  },
  documentTypes: {
    getAll: '/documenttype/list',
    add: '/documenttype/add',
    delete: '/documenttype/remove',
    edit: '/documenttype/edit',
    hide: '/documenttype/hide',
    unHide: '/documenttype/unhide',
  },
  processCategories: {
    getAll: '/process/types',
    add: '/processcategory/add',
    edit: '/processcategory/edit',
    delete: '/processcategory/remove',
  },
  customers: {
    delete: '/customer/delete', // delete a customer
    edit: '/customer/edit', // edit a customer
    add: '/customer/add', // add a customer
    getAll: '/customer/list', // get all customers
    get: '/customer/get', // get one customer
  },
  home: {
    getDashboardData: '/dashboard',
  },
  processes: {
    getAll: '/process/list',
    add: '/process/add',
    edit: '/process/edit',
    remove: '/process/remove',
    hide: '/process/hide',
    unhide: '/process/unhide',
  },
  user: {
    getUserData: '/user/details',
    updateUserData: '/user/update',
    uploadUserAvatar: '/files/upload',
    changePassword: '/user/change_password',
    renewAuthCode: '/user/renewemailauthcode',
    disableEmailAuth: '/user/disableemailauth',
    initiateEmailAuth: '/user/initiateemailauth',
    validateEmailAuth: '/user/validateemailauth',
  },
  members: {
    getAll: '/user/list',
    add: '/user/add',
    remove: '/user/remove',
    edit: '/user/edit',
  },
  typesAndProcesses: {
    getAll: '/document/typesandprocesses',
  },
  feedback: {
    send: '/feedback',
  },
} as const;
