import { Box, TextField, Typography } from '@mui/material';
import { AvatarColorPicker } from 'components/avatar-color-picker';
import { AvatarUploadImage } from 'components/avatar-upload-image';
import { LoadingButton } from 'components/button';
import { InputPassword } from 'components/custom-input';
import { DividerOder } from 'components/divider-oder';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import type { BasicProfileSettingsProps } from './types';

export function BasicProfileSettingsComponent({
  avatar,
  avatarColor,
  name,
  surName,
  email,
  previousPassword,
  newPassword,
  confirmPassword,
  updateFields,
  updateAvatar,
  onNext,
  formDisabled,
  isLoading,
}: BasicProfileSettingsProps) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'status']);
  const [activeOption, setActiveOption] = useState<'image' | 'colored'>(
    'image'
  );

  return (
    <Box component='div' className={classes.profileSetWrapper}>
      <Box component='div' textAlign='center'>
        <Typography component='h3' className={classes.onboardingFormTitle}>
          Persönliches Profil anpassen
        </Typography>
        <Typography component='p' className={classes.onboardingFormDescription}>
          Wähle dein Profilbild und ändere dein Passwort.
        </Typography>
      </Box>
      <Box component='div' display='flex' justifyContent='space-between'>
        <Box component='div' p='0.5rem'>
          <AvatarUploadImage
            activeOption={activeOption}
            avatar={avatar}
            avatarSize='5rem'
            setActiveOption={() => setActiveOption('image')}
            onChange={(e) => updateAvatar('image', e.target.files[0])}
          />
        </Box>
        <DividerOder sx={{ paddingRight: '1.5rem' }} />
        <Box component='div' p='0.5rem'>
          <AvatarColorPicker
            activeOption={activeOption}
            avatarSize='5rem'
            avatarColor={avatarColor}
            setActiveOption={() => setActiveOption('colored')}
            onClick={(color) => updateAvatar('colored', color)}
          />
        </Box>
      </Box>
      <Box component='div' display='flex' flexDirection='column' gap='1.5rem'>
        <Box
          component='div'
          display='flex'
          justifyContent='center'
          gap='1.5rem'
        >
          <TextField
            autoComplete='off'
            fullWidth
            variant='filled'
            label='Vorname'
            value={name}
            className={classes.filledInputStyle}
            onChange={(e) => updateFields({ name: e.target.value })}
          />
          <TextField
            fullWidth
            autoComplete='off'
            variant='filled'
            label='Nachname'
            value={surName}
            onChange={(e) => updateFields({ surName: e.target.value })}
          />
        </Box>
        <TextField
          autoComplete='off'
          variant='filled'
          type='email'
          fullWidth
          label='E-Mail'
          value={email}
          onChange={(e) => updateFields({ email: e.target.value })}
        />
      </Box>
      <Box component='div' display='flex' flexDirection='column' gap='1.5rem'>
        <InputPassword
          variant='filled'
          fullWidth
          label='Vorheriges Passwort (siehe E-Mail)'
          value={previousPassword}
          onChange={(e) => updateFields({ previousPassword: e.target.value })}
        />
        <InputPassword
          variant='filled'
          fullWidth
          label='Neues Passwort (optional)'
          value={newPassword}
          onChange={(e) => updateFields({ newPassword: e.target.value })}
        />
        <InputPassword
          variant='filled'
          fullWidth
          label='Neues Passwort wiederholen (optional)'
          value={confirmPassword}
          onChange={(e) => updateFields({ confirmPassword: e.target.value })}
        />
      </Box>
      <Box component='div' display='flex' justifyContent='flex-end'>
        <LoadingButton
          variant='contained'
          disabled={formDisabled}
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onNext}
          label={t('common:next')}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
}
