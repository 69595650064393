export const productHeaderCell = {
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '0.875rem',
  fontWeight: '500',
  pr: '0.2rem',
  borderBottom: 'none',
  display: 'grid',
  justifyContent: 'start',
  alignContent: 'center',
};
