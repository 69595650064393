import { Avatar, Box, Divider, Typography } from '@mui/material';
import { API_URL } from 'api/api-urls';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';
import {
  handleModalHasChanges,
  setDiscardedRoute,
} from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../login/AuthContext';
import { iconStyle, useStyles } from './styles';
import { ContextProps } from './types';

export function UserMenuComponent({ onClose, open, anchorEl }: ContextProps) {
  const { state, dispatch } = React.useContext(AuthContext);
  const { t } = useTranslation(['profile', 'common']);
  const classes = useStyles();
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  const { hasChanges } = useAppSelector((states) => ({
    hasChanges: states.commonSlice.hasChanges,
  }));

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  const navigateToProfile = () => {
    if (hasChanges) {
      dispatcher(handleModalHasChanges(true));
      dispatcher(setDiscardedRoute(routes().profile));
      return;
    }
    navigate(routes().profile);
  };

  return (
    <ContextMenuWrapper
      offset
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      placement='bottom-start'
    >
      <Box component='div' className={classes.userMenuWrapper}>
        <Box
          component='div'
          className={classes.profileInfoWrapper}
          onMouseDown={navigateToProfile}
        >
          <Avatar
            className={classes.avatarStyle}
            sx={{ backgroundColor: state.user?.avatarColor }}
            src={
              !state.user?.avatar
                ? null
                : `${process.env.REACT_APP_API_URL}${API_URL.files.download}/${state.user?.avatar}?thumb=100`
            }
          >
            {state.user?.prename.charAt(0)}
          </Avatar>
          <Box component='div'>
            <Typography component='p' className={classes.nameStyle}>
              {state.user?.fullName}
            </Typography>
            <Typography component='p' className={classes.positionStyle}>
              {t('profile:viewprofile')}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ width: '92%', margin: '0 auto' }} />
        <Box component='div' onMouseDown={logout} className={classes.exitBox}>
          <Icon name={ICON.LOGOUT} style={{ ...iconStyle }} size={1.5} />
          <Typography component='p' className={classes.exitText}>
            {t('common:logout')}
          </Typography>
        </Box>
      </Box>
    </ContextMenuWrapper>
  );
}
