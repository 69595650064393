import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    wrapper: {
      display: 'flex',
      flex: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      marginTop: '8.16rem',
    },
    btn: {
      maxHeight: '36px',
      '&:hover': { background: '#45BD81' },
      letterSpacing: '0.078125rem',
    },
  };
});
