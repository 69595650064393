import { Box, Button, Typography } from '@mui/material';
import { Icon as MyIcon } from 'components/icon';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import React, { useRef } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import { renderToolbar } from './plugins';
import { useStyles } from './styles';
import type { FileViewerProps } from './types';

const dragAndDropFileTypes = [
  'PDF',
  'DOC',
  'DOCX',
  'XLS',
  'XLSX',
  'JPG',
  'JPEG',
  'PNG',
];
const inputFileTypes = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.jpg',
  '.jpeg',
  '.png',
];

export function FileViewerComponent({
  onRemove,
  file,
  onInputChange,
  onUploaderChange,
  fileName,
  onDownload,
}: FileViewerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['file', 'common']);
  const classes = useStyles();

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (props) => renderToolbar(props, fileName, classes),
    sidebarTabs: () => [],
  });

  return (
    <Box
      component='div'
      className={classes.wrapper}
      sx={{ height: !file ? '100%' : 'max-content' }}
    >
      <Box component='div' className={classes.topBar}>
        <Typography component='h6' className={classes.header}>
          Aktionsmenü Datei
        </Typography>
        <Box component='div' className={classes.btnsBox}>
          <Box component='div'>
            {onUploaderChange ? (
              <Button
                variant='text'
                onClick={() => inputRef.current.click()}
                startIcon={
                  <MyIcon
                    name='file_upload_fill_rounded'
                    size={1.5}
                    fill='#fff'
                  />
                }
              >
                {!file ? t('file:uploadnew') : t('file:changefile')}
              </Button>
            ) : (
              ''
            )}
            <input
              hidden
              ref={inputRef}
              type='file'
              accept={inputFileTypes.join(',')}
              onChange={onInputChange}
            />
            {onDownload ? (
              <Button
                variant='text'
                disabled={!file}
                onClick={onDownload}
                startIcon={
                  <MyIcon
                    name='download'
                    size={1.5}
                    fill={!file ? 'rgba(255, 255, 255, 0.54)' : '#fff'}
                  />
                }
              >
                {t('file:downloadoriginfile')}
              </Button>
            ) : (
              ''
            )}
          </Box>
          {onRemove ? (
            <Button
              variant='text'
              disabled={!file}
              onClick={onRemove}
              startIcon={
                <MyIcon
                  name='delete'
                  size={1.5}
                  fill={!file ? 'rgba(255, 255, 255, 0.54)' : '#fff'}
                />
              }
            >
              {t('file:remove')}
            </Button>
          ) : null}
        </Box>
      </Box>
      {!file ? (
        <FileUploader
          multiple={false}
          handleChange={onUploaderChange}
          name='file'
          types={dragAndDropFileTypes}
          classes='dragDropPDF'
        >
          <Box component='div' className={classes.fileUplTextBox}>
            <MyIcon
              name='upload'
              fill='#4599BD'
              style={{ cursor: 'pointer' }}
              size={3}
            />
            <Typography component='p' className={classes.dragDropText}>
              Drag & Drop deine Dateien oder <span>suchen</span>
            </Typography>
            <Typography component='p' className={classes.types}>
              PDF, WORD, EXCEL,JPG oder PNG
            </Typography>
          </Box>
        </FileUploader>
      ) : (
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
          <Viewer
            theme='dark'
            fileUrl={file}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>
      )}
    </Box>
  );
}
