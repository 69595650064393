import { Avatar, Box, Button } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React, { useRef } from 'react';

import { buttonStyle, wrapper } from './styles';
import { AvatarUploadImageProps } from './types';

export function AvatarUploadImageComponent({
  avatar,
  activeOption = 'image',
  onChange,
  onRemove,
  setActiveOption,
  avatarSize = '5rem',
  uploadBtnLabel = 'BILD HOCHLADEN',
  color = 'qsistant',
}: AvatarUploadImageProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Box
      component='div'
      onClick={setActiveOption || undefined}
      sx={{
        opacity: activeOption === 'image' || !activeOption ? 1 : 0.2,
        '&:hover': {
          opacity: 1,
        },
        ...wrapper,
      }}
    >
      <Avatar
        alt='image'
        src={
          avatar && typeof avatar !== 'string'
            ? URL.createObjectURL(avatar)
            : `${process.env.REACT_APP_API_URL}/files/download/${avatar}`
        }
        sx={{
          width: avatarSize || '5rem',
          height: avatarSize,
          background: '#E6E6E6',
          objectFit: 'cover',
        }}
      >
        <Icon name={ICON.EMPTY_IMAGE} size={1.5} fill='#00000033' />
      </Avatar>
      <Button
        variant='outlined'
        type='button'
        aria-label='Upload Avatar'
        color={color}
        onClick={() => inputRef?.current?.click()}
        startIcon={<Icon name={ICON.UPLOAD} fill='inherit' size={1.5} />}
        sx={{
          ...buttonStyle,
          fill: ({ palette }) => palette[color].main,
          marginTop: ({ typography, size }) => typography.pxToRem(size.fontL),
        }}
      >
        {uploadBtnLabel}
      </Button>
      <input
        type='file'
        hidden
        ref={inputRef}
        accept='image/png, image/jpeg, image/jpg'
        onChange={onChange}
      />
      {onRemove ? (
        <Button
          variant='text'
          type='button'
          aria-label='Reset Avatar'
          onClick={onRemove}
          color={color}
          sx={{
            ...buttonStyle,
            marginTop: ({ typography, size }) =>
              typography.pxToRem(size.font_8),
          }}
        >
          ENTFERNEN
        </Button>
      ) : null}
    </Box>
  );
}
