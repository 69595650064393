export const sortTypeOptions = [
  { id: 1, value: 'default', label: 'noselection' },
  { id: 2, value: 'alphabet ascending', label: 'alphabetascending' },
  { id: 3, value: 'alphabet descending', label: 'alphabetdescending' },
  {
    id: 4,
    value: 'last modified ascending',
    label: 'changedateascending',
  },
  {
    id: 42,
    value: 'last modified descending',
    label: 'changedatedescending',
  },
];

export const sortProcessOptions = [
  { id: 1, value: 'default', label: 'noselection' },
  { id: 2, value: 'alphabet ascending', label: 'alphabetascending' },
  { id: 3, value: 'alphabet descending', label: 'alphabetdescending' },
  {
    id: 4,
    value: 'last modified ascending',
    label: 'changedateascending',
  },
  {
    id: 42,
    value: 'last modified descending',
    label: 'changedatedescending',
  },
];
