import { Box, Button, Typography } from '@mui/material';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { CreateTypeTooltipProps } from './types';

export function CreateTypeTooltip({
  open,
  onClose,
  anchorEl,
}: CreateTypeTooltipProps) {
  const classes = useStyles();
  return (
    <ContextMenuWrapper
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className={classes.createTypeModal}
    >
      <Typography component='h6' className={classes.title}>
        Informationen Ableiten von Nachweisen
      </Typography>
      <Typography component='h6' className={classes.description}>
        Aus dieser Art von Dokumenten können Nachweisdokumente abgeleitet
        werden. Das heißt es werden ausfüllbare Dokumente aus der Dokumentenart
        (Elterndokument) abgeleitet. Diese Funktion kann jederzeit geändert
        werden.
      </Typography>
      <Box component='div' className={classes.btnBox}>
        <Button variant='text' onClick={onClose} color='qsistant'>
          Ok
        </Button>
      </Box>
    </ContextMenuWrapper>
  );
}
