import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addNewMember, getMembersList } from 'api/services/members.services';

import {
  asyncThunkAddNewMember,
  asyncThunkFetchMembersDocuments,
  initialState,
} from './state';
import type { MemberDocumentTypes, NewMemberDataTypes } from './types';

export const fetchDocuments = createAsyncThunk(
  'membersDocuments/fetchMembersDocuments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getMembersList();
      dispatch(updateDocuments(response.data));
      dispatch(handleRoleList(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const addUser = createAsyncThunk(
  'membersDocuments/addUser',
  async (data: NewMemberDataTypes, { dispatch, rejectWithValue }) => {
    try {
      const response = await addNewMember(data);
      dispatch(fetchDocuments());
      dispatch(handleModal(false));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const membersSlice = createSlice({
  name: 'membersSlice',
  initialState,
  reducers: {
    updateDocuments(state, action: PayloadAction<MemberDocumentTypes[]>) {
      return {
        ...state,
        documents: action.payload,
      };
    },

    handleModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    handleRoleList(state, action: PayloadAction<MemberDocumentTypes[]>) {
      const uniqueRoles = [
        ...new Set(
          action.payload
            .flatMap((item) => item.access_Roles?.map((role) => role.name))
            .filter(Boolean)
        ),
      ].map((name) => ({ id: crypto.randomUUID(), label: name, value: name }));
      return {
        ...state,
        rolesList: uniqueRoles,
      };
    },
  },

  extraReducers: (builder) => {
    asyncThunkFetchMembersDocuments(builder, fetchDocuments);
    asyncThunkAddNewMember(builder, addUser);
  },
});

export const { updateDocuments, handleModal, handleRoleList } =
  membersSlice.actions;

export default membersSlice.reducer;
