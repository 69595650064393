import { Box, Typography } from '@mui/material';
import { LoadingButton } from 'components/button';
import { Icon } from 'components/icon';

import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

export function FinalPage({ onSave, isLoading }) {
  const classes = useStyles();
  const { t } = useTranslation('onboarding');
  return (
    <Box component='div' className={classes.finalPageBox}>
      <Icon name='img_brick1' />
      <Box component='div' textAlign='center' margin='1.5rem 0'>
        <Typography
          component='h3'
          className={classes.finalPageTitle}
          sx={{ mb: '0.5rem' }}
        >
          {t('onboarding:readytostartwithqsistant')}
        </Typography>
        <Typography component='h6' className={classes.finalPageDescription}>
          {t('onboarding:usebuttontocontinue')}
        </Typography>
      </Box>
      <LoadingButton
        label={t('onboarding:discoverqsistant')}
        isLoading={isLoading}
        variant='contained'
        color='qsistant'
        onClick={onSave}
      />
    </Box>
  );
}
