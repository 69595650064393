import { createSlice } from '@reduxjs/toolkit';

// import { Product } from 'store/product/types';
interface File {
  id: string;
  name: string;
  revision: string;
  fileVersion: string;
  release: string;
  folders: Folder[];
}

interface Folder {
  id: string;
  name: string;
  revision: string;
  fileVersion: string;
  release: string;
  status?: string;
  path: { id: string; name: string }[];
  folders: (Folder | any)[];
  files: File[];
}

interface FilesProductState {
  id: string;
  name: string;
  path: { id: string; name: string }[];
  folders: (Folder | any)[];
}

const findFolderById = (folders, id) => {
  for (const folder of folders) {
    if (folder.id === id) {
      return folder;
    }
    if (folder.folders && folder.folders.length) {
      const result = findFolderById(folder.folders, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const filesProduct = createSlice({
  name: 'filesProduct',
  initialState: {
    id: '1',
    name: 'Akten',
    path: [{ id: '1', name: 'Akten' }],
    folders: [
      {
        id: '2',
        name: 'Subfolder 1',
        revision: 'Revision 0',
        fileVersion: 'AKT-00002',
        release: '-',
        status: 'entwurf',
        path: [
          { id: '1', name: 'Akten' },
          { id: '2', name: 'Subfolder 1' },
        ],
        files: [],
        folders: [
          {
            id: 4,
            name: '1. Subfolder 1.1',
            revision: 'Revision 0',
            fileVersion: 'AKT-00002',
            release: '-',
            creator: {
              fullName: 'Morty Meister',
            },
            updated: '2023-05-26T09:12:27+02:00',
            path: [
              { id: 1, name: 'Akten' },
              { id: 2, name: 'Subfolder 1' },
              { id: 4, name: 'Subfolder 1.1' },
            ],
            files: [
              {
                id: 13,
                name: 'file 1.1',
                revision: 'Revision 0',
                fileVersion: 'AKT-00002',
                release: '-',
                creator: {
                  fullName: 'Morty Meister',
                },
                updated: '2023-05-26T09:12:27+02:00',
                path: [
                  { id: 1, name: 'Akten' },
                  { id: 2, name: 'Subfolder 1' },
                  { id: 4, name: 'Subfolder 1.1' },
                  { id: 5, name: 'Subfile 1.1.1' },
                ],
              },
            ],
            folders: [
              {
                id: 11,
                name: 'folder 1.1',
                revision: 'Revision 0',
                fileVersion: 'AKT-00002',
                release: '-',
                creator: {
                  fullName: 'Morty Meister',
                },
                updated: '2023-05-26T09:12:27+02:00',
                path: [
                  { id: 1, name: 'Akten' },
                  { id: 2, name: 'Subfolder 1' },
                  { id: 4, name: 'Subfolder 1.1' },
                  { id: 5, name: 'Subfolder 1.1.1' },
                ],
                files: [],
                folders: [
                  {
                    id: 66,
                    name: 'folder 1.1.1',
                    revision: 'Revision 0',
                    fileVersion: 'AKT-00002',
                    release: '-',
                    creator: {
                      fullName: 'Morty Meister',
                    },
                    updated: '2023-05-26T09:12:27+02:00',
                    path: [
                      { id: 1, name: 'Akten' },
                      { id: 2, name: 'Subfolder 1' },
                      { id: 4, name: 'Subfolder 1.1' },
                      { id: 5, name: 'Subfolder 1.1.1' },
                      { id: 66, name: 'Subfolder 1.1.2' },
                    ],
                    files: [],
                    folders: [],
                  },
                  {
                    id: 77,
                    name: 'folder 1.2',
                    revision: 'Revision 0',
                    fileVersion: 'AKT-00002',
                    release: '-',
                    creator: {
                      fullName: 'Morty Meister',
                    },
                    updated: '2023-05-26T09:12:27+02:00',
                    path: [
                      { id: 1, name: 'Akten' },
                      { id: 2, name: 'Subfolder 1' },
                      { id: 4, name: 'Subfolder 1.1' },
                      { id: 77, name: 'Subfolder 1.1.2' },
                    ],
                    files: [],
                    folders: [],
                  },
                ],
              },
              {
                id: 12,
                name: 'folder 1.2',
                revision: 'Revision 0',
                fileVersion: 'AKT-00002',
                release: '-',
                creator: {
                  fullName: 'Morty Meister',
                },
                updated: '2023-05-26T09:12:27+02:00',
                path: [
                  { id: 1, name: 'Akten' },
                  { id: 2, name: 'Subfolder 1' },
                  { id: 4, name: 'Subfolder 1.1' },
                  { id: 6, name: 'Subfolder 1.1.2' },
                ],
                files: [],
                folders: [],
              },
            ],
          },
        ],
      },
      {
        id: '3',
        name: 'Subfolder 2',
        revision: 'Revision 0',
        fileVersion: 'AKT-00002',
        release: '-',
        status: 'entwurf',
        path: [
          { id: '1', name: 'Akten' },
          { id: '3', name: 'Subfolder 2' },
        ],
        folders: [],
        files: [],
      },
    ],
  } as FilesProductState,
  reducers: {
    addFolderItem: (state, action) => {
      const { newFile, parentId } = action.payload;
      if (parentId === '1') {
        state.folders.push(newFile);
      } else {
        const parentFolder = findFolderById(state.folders, parentId);
        if (parentFolder && parentFolder.folders) {
          parentFolder.folders.push(newFile);
        }
      }
    },
    deleteFolderItem: (state, action) => {
      const { folderId } = action.payload;

      const deleteRecursive = (folders) => {
        return folders.filter((folder) => {
          if (folder.id === folderId) {
            return false;
          }
          if (folder.folders && folder.folders.length) {
            folder.folders = deleteRecursive(folder.folders);
          }
          if (folder.files && folder.files.length) {
            folder.files = folder.files.filter((file) => file.id !== folderId);
          }
          return true;
        });
      };

      state.folders = deleteRecursive(state.folders);
    },
    updateFolderName: (state, action) => {
      const { itemId, newName } = action.payload;

      const updateRecursive = (folders) => {
        return folders.map((folder) => {
          if (folder.id === itemId) {
            return {
              ...folder,
              name: newName,
            };
          }
          const files = folder.files || [];
          folder.files = files.map((file) => {
            if (file.id === itemId) {
              return {
                ...file,
                name: newName,
              };
            }
            return file;
          });
          if (folder.folders && folder.folders.length) {
            folder.folders = updateRecursive(folder.folders);
          }
          return folder;
        });
      };

      state.folders = updateRecursive(state.folders);
    },
    addDoc: (state, action) => {
      const { addedDoc, parentId } = action.payload;
      const parentFolder = findFolderById(state.folders, parentId);
      if (parentFolder && parentFolder.folders) {
        parentFolder.files.push(...addedDoc);
      }
    },
    updateFileStatus: (state, action) => {
      const { checkedIds, updates } = action.payload;
      state.folders = state.folders.map((folder) => {
        if (checkedIds.includes(folder.id)) {
          folder.status = updates.status;
        }
        return folder;
      });
    },
  },
});

export const {
  addFolderItem,
  addDoc,
  updateFileStatus,
  deleteFolderItem,
  updateFolderName,
} = filesProduct.actions;

export default filesProduct.reducer;
