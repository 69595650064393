import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    contentWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // paddingBottom: `${typography.pxToRem(6)}`,
    },
    mainContentBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    messageStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.15px !important',
    },
    openinStyle: {
      fontSize: `${typography.pxToRem(16)} !important`,
      marginBottom: 4,
    },
    dateStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.4px !important',
      fontSize: `${typography.pxToRem(12)} !important`,
    },
    textStyle: {
      color: 'rgba(0, 0, 0, 0.6)',
      letterSpacing: `${typography.pxToRem(0.25)} !important`,
      fontSize: `${typography.pxToRem(14)} !important`,
    },
    iconButton: {
      backgroundColor: '#4599bf !important',
      cursor: 'auto !important',
      width: `${typography.pxToRem(36)} !important`,
      height: `${typography.pxToRem(36)} !important`,
    },
  };
});
