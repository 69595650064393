import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import type { ToggleButtonGroupComponentProps } from './types';

export function ToggleButtonGroupComponent({
  options,
  selectedValue,
  onChange,
}: ToggleButtonGroupComponentProps) {
  return (
    <ToggleButtonGroup exclusive value={selectedValue} onChange={onChange}>
      {options.map(({ id, label, value, disabled, tooltip }) =>
        !disabled ? (
          <Tooltip key={id} title={tooltip}>
            <ToggleButton value={value}>
              <Icon
                name={label}
                fill={selectedValue === value ? '#4599BF' : '#00000099'}
              />
            </ToggleButton>
          </Tooltip>
        ) : (
          <ToggleButton
            key={id}
            value={value}
            disabled={disabled}
            sx={{
              opacity: disabled ? 0.54 : 1,
            }}
          >
            <Icon
              name={label}
              fill={selectedValue === value ? '#4599BF' : '#00000099'}
            />
          </ToggleButton>
        )
      )}
    </ToggleButtonGroup>
  );
}
