import { Box, IconButton, Tooltip } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import type { TableIconsBoxProps } from './types';

export function TableIconsBoxComponent({
  options,
  position = 'end',
}: TableIconsBoxProps) {
  const handleClick = (onClick: () => void): void => {
    onClick();
  };
  return (
    <Box
      component='div'
      sx={{ display: 'flex', alignItems: 'center', justifyContent: position }}
    >
      {options.map((option) =>
        option.show ? (
          <React.Fragment key={option.id}>
            {option.modal}
            <Box component='span'>
              <Tooltip title={option.tooltip} placement='bottom'>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(option.onClick);
                  }}
                >
                  <Icon
                    name={option.icon.name}
                    size={option.icon.size || 1.5}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </React.Fragment>
        ) : null
      )}
    </Box>
  );
}
