import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';
import { ModalActionDocumentType } from 'modals/document-type-action';

import React, { useMemo, useRef, useState } from 'react';

import type { ProcessCategoryTypesCardProps } from './process-categorie.card.types';
import { absIcon, useStyles } from './styles';

export function ProcessCategorieTypeCard({
  id,
  title,
  description,
  checked,
  disabled = false,
  onChange,
  isSelected = false,
  isCreated,
  onRemove,
  onAction,
  allowOutput,
}: ProcessCategoryTypesCardProps) {
  const classes = useStyles();
  const iconRef = useRef<HTMLButtonElement>(null);

  const [isMenu, setIsMenu] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [titleState, setTitleState] = useState<string>(title);
  const [descriptionState, setDescriptionState] = useState<string>(description);
  const [switchValue, setSwitchValue] = useState<boolean>(allowOutput);

  const contextContent = useMemo(
    () => [
      {
        id: 124,
        name: 'delete',
        label: 'Entfernen',
        show: true,
        onClick: onRemove,
      },
      {
        id: 413,
        name: 'edit',
        label: 'Bearbeiten',
        show: true,
        onClick: () => setEditing(true),
      },
    ],
    []
  );

  return (
    <Box
      component='div'
      className={classes.cardWrapper}
      sx={{
        opacity: disabled ? 0.54 : 1,
        borderColor: isSelected ? '#4599bf' : '#0000001F',
      }}
    >
      <Box component='div'>
        <Typography component='h6' className={classes.cardTitle}>
          {title}
        </Typography>
        <Typography component='p' className={classes.cardDescription}>
          {description}
        </Typography>
      </Box>
      <Box component='div'>
        <Checkbox
          checked={checked}
          color='qsistant'
          disabled={disabled}
          sx={{ padding: 0 }}
          onChange={() => onChange(id)}
        />
      </Box>
      {isCreated && isSelected ? (
        <IconButton
          sx={absIcon}
          ref={iconRef}
          onClick={() => setIsMenu((prev) => !prev)}
        >
          <Icon name='more_horiz' />
        </IconButton>
      ) : null}
      {isMenu ? (
        <ContextMenu
          open={isMenu}
          anchorEl={iconRef}
          onClose={() => setIsMenu(false)}
          dataList={contextContent}
        />
      ) : null}
      {editing ? (
        <ModalActionDocumentType
          header='Dokumentenart bearbeiten'
          open={editing}
          title={titleState}
          onChangeTitle={(e) => setTitleState(e.target.value)}
          description={descriptionState}
          onChangeDescription={(e) => setDescriptionState(e.target.value)}
          switchValue={switchValue}
          onSwitch={() => setSwitchValue((prev) => !prev)}
          onClose={() => setEditing(false)}
          onAction={() => {
            onAction(titleState, descriptionState, switchValue, id);
            setEditing(false);
          }}
        />
      ) : null}
    </Box>
  );
}
