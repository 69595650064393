import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    cardStyle: {
      height: '38rem',
      width: '21.875rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.625rem 2rem 2rem 2rem',
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
      borderRadius: '0.375rem',
      background: '#fff',
      border: '2px solid #fff',
      borderTop: '6px solid #fff',
    },
    buttonStyle: {
      letterSpacing: '0.025rem',
    },
    listStyle: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  };
});

export const labelStyle = {
  fontWeight: 400,
  fontSize: '1.5rem',
  lineHeight: '2.25rem',
};

export const idealStyle = {
  fontWeight: 400,
  fontSize: '0.875rem',
  color: '#404040',
  lineHeight: '1rem',
  mb: '0.75rem',
};

export const validStyle = {
  fontWeight: 400,
  fontSize: '0.625rem',
  color: '#404040',
  lineHeight: '1rem',
};

export const priceStyle = {
  color: '#404040',
  fontWeight: 500,
  fontSize: '1.625rem',
  '& > span': {
    fontSize: '4.375rem',
    fontWeight: 400,
  },
};

export const privLabelStyle = {
  color: '#404040',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1rem',
};

export const actualStyle = {
  fontWeight: 500,
  color: '#000000',
  textTransform: 'uppercase',
  fontSize: '0.875rem',
  letterSpacing: '0.078rem',
};
