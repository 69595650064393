import {
  Badge,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { ListItemProps } from './list-item.types';

export function ListItemComponent({
  item,
  showSidebar,
  selected,
  onClick,
  middleBreakpoint,
}: ListItemProps) {
  const { list, icon, waitingCount, squeezedLabel } = item;

  return (
    <ListItem disablePadding>
      <Tooltip
        title={list}
        placement='bottom-end'
        enterDelay={1000}
        enterNextDelay={1000}
        componentsProps={{ tooltip: { className: 'SidebarTooltip' } }}
      >
        <ListItemButton
          disableTouchRipple
          selected={selected}
          onClick={onClick}
          sx={{
            p: 0,
            height: showSidebar || middleBreakpoint ? '4.5rem' : '100%',
            fill: ({ palette }) => palette.icon.main,
            flexDirection: showSidebar
              ? 'column'
              : middleBreakpoint
              ? 'column'
              : 'row',
            '&.Mui-selected': {
              fill: ({ palette }) => palette[icon.activeColor].main,
              background: ({ palette }) => palette[icon.activeColor].hover,
              '&:hover': {
                background: ({ palette }) => palette[icon.activeColor].hover,
              },
            },
          }}
        >
          <Badge
            badgeContent={waitingCount}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiBadge-badge': {
                display: waitingCount ? 'flex' : 'none',
                top: '0.5rem',
                left: '1.5rem',
              },
            }}
          >
            <Icon
              name={icon.name}
              fill='inherit'
              size={1.5}
              style={{ padding: '0.5rem 1.5rem' }}
            />
          </Badge>
          <ListItemText
            primary={
              showSidebar || middleBreakpoint ? squeezedLabel || list : list
            }
            sx={{
              mb: 0,
              '& > span': {
                fontWeight: selected ? 500 : 400,
                color: '#000000cc',
                letterSpacing: 0,
                whiteSpace: 'nowrap',
                fontSize:
                  showSidebar || middleBreakpoint ? '0.625rem' : '0.875rem',
              },
            }}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}
