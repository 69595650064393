import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    textfieldBox: {
      gap: '1rem',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    modalStyle: {
      width: '35rem',
      paddingBottom: `${typography.pxToRem(size.fontL)}`,
      display: 'flex',
      flexDirection: 'column',
    },
    imageUploadBox: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      gap: '0.3rem',
    },
    buttonsBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    contentBox: {
      padding: `${typography.pxToRem(size.fontXS)} ${typography.pxToRem(
        size.font24L
      )}`,
      gap: `${typography.pxToRem(size.fontL)}`,
      display: 'flex',
      flexDirection: 'column',
    },
    captionStyle: {
      color: '#000000DE',
      letterSpacing: '0.008rem !important',
      fontWeight: `${typography.fontWeightMedium} !important`,
    },
  };
});
