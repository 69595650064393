import api from 'api/api';
import { API_URL } from 'api/api-urls';

export const getChangemanagement = () => {
  return api.get(`${API_URL.changemanagement.get}`);
};

export const sendChangeDocument = (id?: string) => {
  return api.put(`${API_URL.changemanagement.updateChange}/${id}`);
};

export const sendExpiryDocument = (id?: string) => {
  return api.put(`${API_URL.changemanagement.updateExpiry}/${id}`);
};
