import type { DocumentsSetupTypes } from './documents-setup.types';

export const initialState: DocumentsSetupTypes = {
  documentSettings: {
    certification: '',
    activeDocument: {},
    documents: [
      {
        id: '1',
        label: 'Dokumentenlenkung',
        progress: 0,
        activeStep: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '2',
        label: 'Infrastruktur und Ressourcen',
        progress: 0,
        activeStep: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '3',
        label: 'Internes Audit',
        activeStep: 0,
        progress: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '4',
        label: 'Kompetenzen und Schulungen',
        activeStep: 0,
        progress: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '5',
        label: 'Managementbewertung',
        activeStep: 0,
        progress: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '6',
        label: 'Steuerung nichtkonformer Ergebnisse',
        activeStep: 0,
        progress: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '7',
        label: 'Steuerung nichtkonformer Ergebnisse',
        activeStep: 0,
        progress: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '8',
        label: 'Steuerung nichtkonformer Ergebnisse',
        activeStep: 0,
        progress: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
      {
        id: '9',
        label: 'Steuerung nichtkonformer Ergebnisse',
        activeStep: 0,
        progress: 0,
        structureSteps: [
          {
            id: 0,
            label: 'Zyklen Audits',
            value: 'Cycle audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 1,
            label: 'Umfang Audits',
            value: 'Scope audits',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Option', disabled: true, blocked: true },
              { id: 1, label: 'Beispiel', disabled: true, blocked: true },
              { id: 2, label: 'Muster', disabled: true, blocked: true },
              { id: 3, label: 'Andere', disabled: true, blocked: true },
              { id: 4, label: 'Example', disabled: true, blocked: true },
              {
                id: 5,
                label: 'Beispieleros größeros',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 2,
            label: 'Tabelle - Auflistung Audit',
            value: 'Table - Listing Audit',
            selectedOption: [],
            departments: [
              { id: 0, label: 'Management', value: 'Management' },
              { id: 1, label: 'Production', value: 'Production' },
            ],
          },
          {
            id: 3,
            label: 'Verantwortung',
            value: 'Responsibility',
            selectedOption: [],
            audits: [
              {
                id: 0,
                label: 'Qualitätsmanagementbeauftragte/Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 1,
                label: 'Externer Auditor',
                blocked: true,
                disabled: true,
              },
              {
                id: 2,
                label: 'Interner Auditor',
                blocked: true,
                disabled: true,
              },
            ],
          },
          {
            id: 4,
            label: 'Qualifikationen Auditoren',
            value: 'Qualifications auditors',
            selectedOption: {},
            audits: [
              { id: 0, label: 'Jährlich', disabled: true, blocked: true },
              { id: 1, label: 'Monatlich', disabled: true, blocked: true },
              {
                id: 2,
                label: 'Quartalsweise',
                disabled: true,
                blocked: true,
              },
            ],
          },
          {
            id: 5,
            label: 'Auditkriterien',
            value: 'Audit Criteria',
            selectedOption: [],
          },
          {
            id: 6,
            label: 'Speicherort Nachweise',
            value: 'Location of evidence',
            selectedOption: '',
          },
        ],
      },
    ],
  },
};
