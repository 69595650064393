import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { ICON } from 'enum/icons.enum';
import { fetchAllTypesAndProcesses } from 'store/administration/document-type/document-type-slice';
import { useAppDispatch } from 'store/hook';
import { ModalWrapper } from 'wrappers/modal-wrapper';

import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import { CustomDocument } from './tabs/custom-document';
import { TemplateDocument } from './tabs/template-document';
import type { CreateNewDocumentProps } from './types';

export function ModalCreateNewDocument({
  onClose,
  open,
}: CreateNewDocumentProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    dispatch(fetchAllTypesAndProcesses());
  }, [dispatch]);

  return (
    <ModalWrapper open={open} onClose={onClose} className={classes.modal}>
      <ModalHeader
        header='Neues Dokument erstellen'
        onClose={onClose}
        icon={{ name: ICON.ADD_DOCUMENT }}
      />
      <Box display='flex' justifyContent='center' height='4rem' component='div'>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          TabIndicatorProps={{ className: 'QsistantTab' }}
        >
          <Tab
            label='Qsistant Dokument'
            className='Capitalize IconTextSpace'
            iconPosition='start'
            icon={
              <Icon name={tabValue === 0 ? 'qs_icon_blue' : 'new_qs_icon'} />
            }
          />
          <Tab
            label='Datei als Qsistant Dokument'
            className='Capitalize IconTextSpace'
            iconPosition='start'
            icon={
              <Icon
                name={ICON.UPLOAD_FILE}
                fill={tabValue === 1 ? '#4599BF' : '#00000099'}
              />
            }
          />
        </Tabs>
      </Box>
      <Divider />
      {tabValue === 0 && <TemplateDocument onSuccess={() => onClose()} />}
      {tabValue === 1 && <CustomDocument onSuccess={() => onClose()} />}
    </ModalWrapper>
  );
}
