import { Box, Button, Typography } from '@mui/material';
import { ProcessCategorieTypeCard } from 'cards/process-categorie-card';
import { Icon } from 'components/icon';
import { ModalActionDocumentType } from 'modals/document-type-action';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  handleCreateType,
  handleEditType,
  handleRemoveType,
  handleSelectType,
  handleUnSelectType,
} from 'store/user-profile/basic-user';

import React, { useState } from 'react';

import { useStyles } from './styles';

export function DocumentTypes({ onBack, onNext }) {
  const dispatcher = useAppDispatch();
  const classes = useStyles();
  const documentTypes = useAppSelector(
    (state) => state.basicUserReducer.documentTypes
  );
  const selectedDocumentTypes = useAppSelector(
    (state) => state.basicUserReducer.selectedDocumentTypes
  );

  const [createType, setCreateType] = useState<boolean>(false);
  const [typeTitle, setTypeTitle] = useState<string>('');
  const [typeDescription, setTypeDescription] = useState<string>('');
  const [allowOutput, setAllowOutput] = useState<boolean>(false);

  const createNewType = (
    title: string,
    description: string,
    allow: boolean
  ) => {
    dispatcher(handleCreateType({ title, description, allow }));
    setTypeTitle('');
    setTypeDescription('');
    setAllowOutput(false);
    setCreateType(false);
  };

  const removeType = (id: string) => dispatcher(handleRemoveType({ id }));

  const editType = (
    title: string,
    description: string,
    allow: boolean,
    id: string
  ) => dispatcher(handleEditType({ title, description, allow, id }));

  const selectType = (id: string) => dispatcher(handleSelectType({ id }));

  const unSelectType = (id: string) => dispatcher(handleUnSelectType({ id }));

  return (
    <Box component='div' textAlign='center' width='35%'>
      <Typography component='h3' className={classes.onboardingFormTitle}>
        Dokumentenarten
      </Typography>
      <Typography component='h6' className={classes.processCatDescription}>
        Dokumentenarten kategorisieren Dokumente in unterschiedliche Arten, um
        eine bessere Übersicht aller Dokumente gewährleisten zu können.
      </Typography>
      <Typography
        component='h6'
        mt='1.5rem'
        className={classes.processCarCaption}
      >
        Dokumentenarten für dein QM-System:
      </Typography>
      <Button
        variant='text'
        color='qsistant'
        startIcon={<Icon name='add' fill='#3F97BE' />}
        sx={{ marginTop: '1.5rem', color: '#3F97BE' }}
        onClick={() => setCreateType(true)}
      >
        Dokumentenart hinzufügen
      </Button>
      <Box
        component='div'
        textAlign='left'
        display='flex'
        flexDirection='column'
        gap='1rem'
      >
        {selectedDocumentTypes.length > 0 &&
          selectedDocumentTypes.map((cat) => (
            <ProcessCategorieTypeCard
              key={cat.id}
              isSelected
              onChange={unSelectType}
              onRemove={(e) => {
                e.stopPropagation();
                removeType(cat.id);
              }}
              onAction={(title, description, allow, id) => {
                editType(title, description, allow, id);
              }}
              {...cat}
            />
          ))}
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='space-around'
        margin='1.5rem 0'
      >
        <Button variant='text' color='qsistant' onClick={onBack}>
          zurück
        </Button>
        <Button
          variant='contained'
          color='qsistant'
          onClick={onNext}
          disabled={selectedDocumentTypes.length === 0}
        >
          Weiter
        </Button>
      </Box>
      <Box
        component='div'
        textAlign='left'
        display='flex'
        flexDirection='column'
        gap='1rem'
      >
        <Typography component='h6' className={classes.processCarCaption}>
          Von uns vorgeschlagene Dokumentenarten:
        </Typography>
        {documentTypes.length > 0 &&
          documentTypes.map((cat) => (
            <ProcessCategorieTypeCard
              key={cat.id}
              onChange={selectType}
              {...cat}
            />
          ))}
      </Box>
      {createType ? (
        <ModalActionDocumentType
          open={createType}
          header='Dokumentenart hinzufügen'
          onClose={() => setCreateType(false)}
          title={typeTitle}
          onChangeTitle={(e) => setTypeTitle(e.target.value)}
          description={typeDescription}
          onChangeDescription={(e) => setTypeDescription(e.target.value)}
          switchValue={allowOutput}
          onSwitch={() => setAllowOutput((prev) => !prev)}
          onAction={createNewType}
        />
      ) : null}
    </Box>
  );
}
