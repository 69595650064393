import { Button, Snackbar } from '@mui/material';

import React from 'react';

import type { GlobalSnackbarComponentProps } from './types';

export function GlobalSnackbarComponent({
  message,
  button,
  anchor,
}: GlobalSnackbarComponentProps) {
  const action = (
    <Button color={button.color} size='small' onClick={button.onClick}>
      {button.text}
    </Button>
  );
  return (
    <Snackbar
      open
      message={message}
      action={action}
      anchorOrigin={{
        vertical: anchor.vertical,
        horizontal: anchor.horizontal,
      }}
    />
  );
}
