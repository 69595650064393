import { Box, Button, Typography } from '@mui/material';
import { ProductFilesCardComponent } from 'cards/product-files-card/product-files-card.component';
import { ContextButtonComponent } from 'components/button/context-button.component';
import { Icon } from 'components/icon';
import { ImportedRecordsTable } from 'components/product-imported-records-table';
import { ModalAddFile } from 'modals/product-development/create-new-file';
import { ImportDocumentListModal } from 'modals/product-development/import-document-list';
import { ModalUploadRecord } from 'modals/product-development/upload-record';
import { RootState } from 'store/index';
import {
  addDoc,
  addFolderItem,
  updateFileStatus,
} from 'store/product/product-files-slice';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addDatasetBtn } from '../product_phases_tab/theme';
import { saveBtn } from '../theme';
import { cardsFilesData } from './data';
import { ProductFilesTabNav } from './product-files-tab-navigation';
import { ProductFilesTabComponentProps } from './types';

export function ProductFilesTabComponent({
  handleImportedFiles,
  importedFiles,
  savedFiles,
  setSavedFiles,
  docsModal,
  setDocsModal,
  setReviewModal,
  checkedIds,
  setCheckedIds,
  setShareDataModal,
  selectedRows,
  setSelectedRows,
}: ProductFilesTabComponentProps) {
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  // const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [file, setFile] = useState(null);
  const [newFileName, setNewFileName] = useState('');
  const [fileModal, setFileModal] = useState(false);
  const [addedDoc, setAddedDoc] = useState([]);
  const [disableFilesBtn, setDisableFilesBtn] = useState(false);
  // ----------------------------------------------------------------
  // const { cells } = useCells(isEditMode);
  const [currentPath, setCurrentPath] = useState([{ id: '1', name: 'Akten' }]);
  const filesData = useSelector(
    (state: RootState) => state.filesProductReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPath(filesData.path);
  }, [filesData.path]);
  console.log('checkedId', checkedIds);
  const findSelectedFolder = (folders, id) => {
    for (const folder of folders) {
      if (folder.id === id) {
        return folder;
      }
      if (Array.isArray(folder.folders)) {
        const selectedFolder = findSelectedFolder(folder.folders, id);
        if (selectedFolder) {
          return selectedFolder;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    setDisableFilesBtn(false);
  }, [filesData, dispatch]);

  useEffect(() => {
    const selectedFolder =
      currentPath.length > 0
        ? findSelectedFolder(
            filesData.folders,
            currentPath[currentPath.length - 1]?.id
          )
        : null;
    const childFolders =
      currentPath.length > 0 && selectedFolder?.folders
        ? selectedFolder.folders
        : filesData.folders;
    const childFiles =
      currentPath.length > 0 && selectedFolder?.files
        ? selectedFolder.files
        : [];
    setChildFolders(childFolders);
    setChildFiles(childFiles);
  }, [currentPath, filesData.folders]);

  console.log('selectedRows', selectedRows);
  const handleFolderClick = (folder) => {
    setSelectedRows([]); // Clear selected rows
    if (Array.isArray(folder.folders)) {
      setCurrentPath([...currentPath, folder]);
      setChildFolders(folder.folders);
    }
  };

  const handleBreadcrumbItemClick = (index) => {
    const newPath = currentPath.slice(0, index + 1);
    setCurrentPath(newPath);
    const selectedFolderId = newPath[newPath.length - 1].id;
    const selectedFolder = findSelectedFolder(
      filesData.folders,
      selectedFolderId
    );
    if (selectedFolder) {
      setChildFolders(selectedFolder.folders);
    } else {
      setChildFolders(filesData.folders);
    }
  };

  const handlePrevClick = () => {
    if (currentPath.length > 1) {
      const newPath = currentPath.slice(0, currentPath.length - 1);
      setCurrentPath(newPath);
      const selectedFolderId = newPath[newPath.length - 1].id;
      const selectedFolder = findSelectedFolder(
        filesData.folders,
        selectedFolderId
      );
      if (selectedFolder) {
        setChildFolders(selectedFolder.folders);
      } else {
        setChildFolders(filesData.folders);
      }
    }
  };

  const [childFolders, setChildFolders] = useState(filesData.folders);
  const [childFiles, setChildFiles] = useState([]);
  // ----------------------------------------------------------------
  const handleRowSelect = (id) => {
    if (checkedIds?.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
      setCheckedIds([...checkedIds, id]);
    }
  };

  const filteredFolders = childFolders.filter((folder) =>
    checkedIds.includes(folder.id)
  );
  const areCheck = () => filteredFolders.every((f) => f.status === 'check');
  const areDraft = () => filteredFolders.every((f) => f.status === 'entwurf');

  const handleUploadBtn = () => {
    setIsOpenUploadModal(true);
  };
  const checkedArr = selectedRows?.length > 0;

  const handleSubmitUploaded = () => {
    const newFile = {
      id: `${crypto.randomUUID()}`,
      name: file?.[0].name.replace(/\.[^.]+$/, ''),
      fileVersion: 'AKT-00001 ',
      revision: 'Revision 0',
      release: '-',
      status: 'rejected',
      path: [
        ...currentPath,
        {
          id: `${crypto.randomUUID()}`,
          name: file?.[0].name.replace(/\.[^.]+$/, ''),
        },
      ],
      files: [],
      folders: [],
    };

    dispatch(addFolderItem(newFile));
    handleImportedFiles();
  };

  const handleChangeDatasetName = (e) => {
    setNewFileName(e.target.value);
  };

  const handleDocsSubmit = () => {
    dispatch(
      addDoc({
        addedDoc,
        parentId: currentPath[currentPath.length - 1].id,
      })
    );
    setDocsModal(false);
    setAddedDoc([]);
  };

  const handlenewFileSubmit = () => {
    const newFile = {
      id: `${crypto.randomUUID()}`,
      name: newFileName,
      fileVersion: 'AKT-00001 ',
      revision: 'Revision 0',
      release: '-',
      status: 'entwurf',
      creator: {
        fullName: 'Morty Meister',
      },
      updated: '2023-05-26T09:12:27+02:00',
      path: [
        ...currentPath,
        {
          id: `${crypto.randomUUID()}`,
          name: file?.[0].name.replace(/\.[^.]+$/, ''),
        },
      ],
      folders: [],
      files: [],
    };
    dispatch(
      addFolderItem({
        newFile,
        parentId: currentPath[currentPath.length - 1].id,
      })
    );
    setNewFileName('');
    setFileModal(false);
  };

  const handleCreateNewFilesBtn = () => {
    console.log('set modal for CREATE NEW DEVELOPMENT FILES true');
  };

  const columns1Lvl = ['BEZEICHNUNG', 'NUMMER', 'FREIGABE', 'STATUS'];
  const columnsOtherLvls = ['BEZEICHNUNG', 'NUMMER', 'GEÄNDERT'];
  const aktenTab = currentPath.length === 1;

  return (
    <Box>
      <Box>
        {importedFiles ? (
          <Box
            sx={{
              background: '#fff',
              borderRadius: '0.25rem',
              m: '2rem 0',
              p: '1.25rem',
              minHeight: '30.25rem',
            }}
          >
            <ProductFilesTabNav
              editMode={isEditMode}
              savedFiles={savedFiles}
              setIsEditMode={setIsEditMode}
              setSavedFiles={setSavedFiles}
              // isCollapsed={false}
              disableFilesBtn={disableFilesBtn}
              setDisableFilesBtn={setDisableFilesBtn}
              currentPath={currentPath}
              handleBreadcrumbItemClick={handleBreadcrumbItemClick}
              handlePrevClick={handlePrevClick}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                m: '2rem 0 1.5rem 0',
              }}
            >
              {isEditMode && (
                <Box>
                  <ContextButtonComponent
                    setDocsModal={setDocsModal}
                    disabled={aktenTab}
                  />
                  {docsModal && (
                    <ImportDocumentListModal
                      open={docsModal}
                      addedDocument={addedDoc}
                      setAddedDocument={setAddedDoc}
                      handleDocumentsSubmit={handleDocsSubmit}
                      onClose={() => {
                        setDocsModal(false);
                      }}
                    />
                  )}
                  <Button
                    variant='outlined'
                    startIcon={<Icon name='add' fill='#45BD81' size={1.5} />}
                    sx={{ ...addDatasetBtn }}
                    onClick={() => {
                      setFileModal(true);
                    }}
                  >
                    {aktenTab ? 'NEUE AKTE' : 'NEUER ORDNER'}
                  </Button>
                </Box>
              )}
              {checkedArr && !isEditMode && aktenTab && areDraft() && (
                <Button
                  type='submit'
                  onClick={() => {
                    setReviewModal(true);
                  }}
                  startIcon={<Icon name='lock_open' fill='#fff' size={1.5} />}
                  className='ProductPhasesButton'
                  sx={{
                    ...saveBtn,
                    p: '6px 16px 6px  12px',
                    ml: 0,
                  }}
                  variant='contained'
                  color='product'
                >
                  <Typography
                    className='TextPhasesBtn'
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    FREIGABE ANFORDERN
                  </Typography>
                </Button>
              )}
              {fileModal && (
                <ModalAddFile
                  open={fileModal}
                  onClose={() => {
                    setFileModal(false);
                  }}
                  aktenTab={aktenTab}
                  newFileName={newFileName}
                  newFileSubmit={handlenewFileSubmit}
                  onChangeFileName={handleChangeDatasetName}
                />
              )}
              {checkedArr && !isEditMode && aktenTab && areCheck() && (
                <>
                  <Button
                    type='submit'
                    onClick={() => setShareDataModal(true)}
                    startIcon={<Icon name='lock_open' fill='#fff' size={1.5} />}
                    className='ProductPhasesButton'
                    sx={{ ...saveBtn, ml: 0 }}
                    variant='contained'
                    color='product'
                  >
                    <Typography
                      className='TextPhasesBtn'
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      FREIGABE
                    </Typography>
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      dispatch(
                        updateFileStatus({
                          checkedIds,
                          updates: {
                            status: 'entwurf',
                          },
                        })
                      );
                    }}
                    startIcon={
                      <Icon name='not_interested' fill='#45BD81' size={1.5} />
                    }
                    sx={{ ...addDatasetBtn }}
                  >
                    ABLEHNEN
                  </Button>
                </>
              )}
              {!isEditMode && (
                <Button
                  variant='outlined'
                  startIcon={
                    <Icon name='inventory' fill='#45BD81' size={1.5} />
                  }
                  sx={{ ...addDatasetBtn, ml: isEditMode ? '1.5rem' : 'auto' }}
                >
                  ARCHIVIEREN
                </Button>
              )}
            </Box>
            <Box sx={{ width: '100%' }}>
              <ImportedRecordsTable
                columnNames={aktenTab ? columns1Lvl : columnsOtherLvls}
                columnWidths={[5.5, 26, 14.125, 16.75, 33.5]}
                editMode={isEditMode}
                selectedRows={selectedRows} // delete ?
                childFiles={childFiles}
                childFolders={childFolders}
                handleFolderClick={handleFolderClick}
                // cells={cells}
                handleRowSelect={handleRowSelect}
                currentPath={currentPath}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: '2rem' }}>
            {cardsFilesData.map((cardData, i) => (
              <Fragment key={cardData.pOne}>
                <ProductFilesCardComponent
                  cardData={cardData}
                  onClick={i ? handleUploadBtn : handleCreateNewFilesBtn}
                  // onClose={hadleCloseModal}
                />
              </Fragment>
            ))}
            {isOpenUploadModal && (
              <ModalUploadRecord
                file={file}
                setFile={setFile}
                open={isOpenUploadModal}
                onClose={() => setIsOpenUploadModal(false)}
                onSubmitFiles={handleSubmitUploaded}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

ProductFilesTabComponent.displayName = 'ProductFilesTab';
