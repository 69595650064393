import { Box, IconButton, Typography } from '@mui/material';
import { UpgradePlanCard } from 'cards/upgrade-plan-card';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { UpgradePlanModalProps } from './types';

export function UpgradePlanModal({
  open,
  onClose,
  options,
  onClick,
}: UpgradePlanModalProps) {
  const classes = useStyles();
  return (
    <ModalWrapper open={open} className={classes.upgradePlanModal}>
      <Box component='div' display='flex' justifyContent='flex-end'>
        <IconButton onClick={onClose}>
          <Icon name={ICON.CLOSE} />
        </IconButton>
      </Box>
      <Typography component='h6' className={classes.label}>
        Bringe dein Qualitätsmanagement auf ein neues Level 🎫
      </Typography>
      <Typography component='h4' className={classes.header}>
        Upgrade jetzt deinen Free-Plan mit MiQ GmbH & Co. KG
      </Typography>
      <Box component='div' className={classes.planCardsBox}>
        {options.map((option) => (
          <UpgradePlanCard
            key={option.id}
            onClick={() => onClick(option)}
            {...option}
          />
        ))}
      </Box>
    </ModalWrapper>
  );
}
