import {
  Autocomplete,
  Box,
  Divider,
  FilterOptionsState,
  IconButton,
  ListItem,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { searchTheme } from './theme';
import type { AppbarSearchOptions, AppbarSearchProps } from './types';

export function AppbarSearchComponent({
  sx,
  options,
  placeholder,
  value,
  onInputChange,
  onKeyDown,
  onHistoryChange,
}: AppbarSearchProps) {
  const filter = createFilterOptions();
  // const highlightText = useCallback(
  //   (text, highlight) => {
  //     const reg = new RegExp(`(${highlight})`, 'gi');
  //     const parts = text.split(reg);
  //     for (let i = 1; i < parts.length; i += 2) {
  //       parts[i] = (
  //         <span key={i} style={{ fontWeight: 500, letterSpacing: '0.016rem' }}>
  //           {parts[i]}
  //         </span>
  //       );
  //     }
  //     return (
  //       <Typography component='p' style={{ ...title }}>
  //         {parts}
  //       </Typography>
  //     );
  //   },
  //   [value]
  // );

  return (
    <ThemeProvider theme={searchTheme}>
      <Autocomplete
        // open
        freeSolo
        sx={{ ...sx }}
        autoHighlight
        inputValue={value}
        onInputChange={onInputChange}
        options={options}
        onKeyDown={onKeyDown}
        className='SearchTextField'
        getOptionLabel={(option) => {
          if (typeof option !== 'string' && 'name' in option) {
            return option.name || '';
          }
          return '';
        }}
        clearIcon={<Icon name={ICON.CLOSE} size={1.5} />}
        filterOptions={(
          opt: AppbarSearchOptions[],
          params: FilterOptionsState<AppbarSearchOptions>
        ): AppbarSearchOptions[] => {
          const filtered = filter(opt, params as FilterOptionsState<unknown>);
          const { inputValue } = params;
          // const isExisting = opt.some((o) => inputValue === o.name);
          if (inputValue) {
            filtered.unshift({
              id: crypto.randomUUID(),
              name: `${value}`,
              isSearch: true,
            });
          }
          return filtered as AppbarSearchOptions[];
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <IconButton disableRipple sx={{ padding: '0 8px' }}>
                  <Icon
                    name={ICON.SEARCH}
                    size={1.375}
                    fill='rgba(0, 0, 0, 0.6)'
                  />
                </IconButton>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          if ('component' in option && option.component === 'searchHistory') {
            return (
              <ListItem
                {...props}
                key={option.id}
                sx={{ display: 'flex', gap: '0.5rem' }}
                onClick={() => onHistoryChange(option.lab)}
              >
                <Icon name={ICON.HISTORY} />
                <Typography className='Title'>{option.lab}</Typography>
              </ListItem>
            );
            // eslint-disable-next-line no-else-return
          } else if (
            'component' in option &&
            option.component === 'dividerTop'
          ) {
            return <Divider key={option.id} />;
          } else if (
            'component' in option &&
            option.component === 'dividerBottom'
          ) {
            return <Divider key={option.id} />;
          } else if ('component' in option && option.component === 'label') {
            return (
              <Typography
                component='p'
                key={option.id}
                className='LastSearchLabel'
              >
                {option.lab}
              </Typography>
            );
          } else if (
            'component' in option &&
            option.component === 'viewHistory' &&
            !value
          ) {
            return (
              <ListItem
                {...props}
                key={option.id}
                sx={{ display: 'flex', gap: '0.5rem' }}
              >
                <Icon name={ICON.SEARCH_QS_FILE} />
                <Box component='div' display='flex' flexDirection='column'>
                  <Typography className='Title'>{option.lab}</Typography>
                  <Stack
                    direction='row'
                    spacing={1}
                    divider={<Divider orientation='vertical' flexItem />}
                  >
                    <Typography component='span' className='Caption'>
                      DOC-0012
                    </Typography>
                    <Typography component='span' className='Caption'>
                      Revision 0
                    </Typography>
                    <Typography component='span' className='Caption'>
                      Führungsebene
                    </Typography>
                  </Stack>
                </Box>
              </ListItem>
            );
          } else if ('isSearch' in option && value.length > 1) {
            return (
              <ListItem
                {...props}
                key={option.id}
                sx={{
                  display: 'flex',
                  gap: '0.5rem',
                  borderTop: option?.isSearch && '1px rgba(0, 0, 0, 0.12)',
                  borderBottom:
                    option?.isSearch && '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                <Icon
                  name={option.isSearch ? ICON.SEARCH : ICON.SEARCH_QS_FILE}
                />
                <Box component='div' display='flex' flexDirection='column'>
                  {/* {highlightText(option.name, inputValue)} */}
                  {option.name}
                  {!option.isSearch ? (
                    <Stack
                      direction='row'
                      spacing={1}
                      divider={<Divider orientation='vertical' flexItem />}
                    >
                      <Typography component='span' className='Caption'>
                        DOC-0012
                      </Typography>
                      <Typography component='span' className='Caption'>
                        Revision 0
                      </Typography>
                      <Typography component='span' className='Caption'>
                        Führungsebene
                      </Typography>
                    </Stack>
                  ) : null}
                </Box>
              </ListItem>
            );
          } else {
            return null;
          }
        }}
      />
    </ThemeProvider>
  );
}
