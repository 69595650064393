import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
} from '@mui/material';

import React from 'react';

import type { RadioGroupProps } from './radio-group.types';
import { theme } from './theme';

export function RadioGroupComponent({
  title,
  options,
  value,
  onChange,
}: RadioGroupProps) {
  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <FormLabel id='demo-controlled-radio-buttons-group'>{title}</FormLabel>
        <RadioGroup
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={value}
          onChange={onChange}
        >
          {options &&
            options.map(({ id, value: optionValue, label }) => (
              <FormControlLabel
                key={id}
                value={optionValue}
                control={<Radio />}
                label={label}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </ThemeProvider>
  );
}
