import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    modal: {
      width: `${typography.pxToRem(560)}`,
    },
    topInputsBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(24)}`,
      padding: `${typography.pxToRem(24)}`,
    },
    caption: {
      color: '#000000DE',
      letterSpacing: '0.016rem !important',
    },
    list: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gap: '0 1rem',
    },
    circle: {
      width: `${typography.pxToRem(8)}`,
      height: `${typography.pxToRem(8)}`,
      borderRadius: '50%',
    },
    listItem: {
      fontSize: `${typography.pxToRem(14)}`,
      color: '#7F7F7F',
      letterSpacing: '0.015rem',
      fontWeight: `${typography.fontWeightMedium}`,
      padding: '0.5rem 0 0.5rem 0.5rem !important',
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: `${typography.pxToRem(24)}`,
    },
  };
});
