import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchArchiveDocuments,
  getSingleDocument,
  restoreDocument,
} from 'api/services/archive.services';

import {
  asyncThunkFetchArchives,
  asyncThunkSingleArchive,
  initialState,
} from './state';
import type {
  ArchiveDocumentsTypes,
  RestoredArchiveData,
  RootState,
} from './types';

export const fetchDocuments = createAsyncThunk(
  'archive/fetchArchiveDocuments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchArchiveDocuments();
      dispatch(updateDocuments(response.data));
      dispatch(setListOfUsers(response.data));
      dispatch(handleElementTypesList(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const restoreArchiveDocument = createAsyncThunk(
  'archive/restoreArchiveDocument',
  async (
    { id, data }: RestoredArchiveData,
    { rejectWithValue, dispatch, getState }
  ) => {
    const { documents } = (getState() as RootState).archiveReducer;
    const filtered = documents.filter((document) => document.id !== id);
    try {
      const response = await restoreDocument({ id, data });
      dispatch(updateDocuments(filtered));
      return [...response.data, ...filtered];
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const fetchSingleDocument = createAsyncThunk(
  'archive/fetchSingleArchiveDocument',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await getSingleDocument(id);
      dispatch(handleSingleDocument(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const archiveSlice = createSlice({
  name: 'archiveSlice',
  initialState,
  reducers: {
    updateDocuments(state, action: PayloadAction<ArchiveDocumentsTypes[]>) {
      return {
        ...state,
        documents: action.payload,
      };
    },

    handleSingleDocument(state, action: PayloadAction<ArchiveDocumentsTypes>) {
      return {
        ...state,
        singleDocument: action.payload,
      };
    },

    handleToggleValue(
      state,
      action: PayloadAction<'visibility' | 'edit' | 'compare'>
    ) {
      return {
        ...state,
        toggleValue: action.payload,
      };
    },

    handleCloseModal(state) {
      return {
        ...state,
        modal: false,
      };
    },

    setListOfUsers(state, action: PayloadAction<ArchiveDocumentsTypes[]>) {
      const uniqueValues = [
        ...new Set(
          action.payload.map((item) =>
            item.users.map((user) => `${user.prename} ${user.surname}`).join('')
          )
        ),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          avatar: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        listOfUsers: uniqueValues,
      };
    },

    handleElementTypesList(
      state,
      action: PayloadAction<ArchiveDocumentsTypes[]>
    ) {
      const uniqueTypes = [
        ...new Set(action.payload.map((item) => item.elementType)),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        elTypesList: uniqueTypes,
      };
    },
  },
  extraReducers: (builder) => {
    asyncThunkFetchArchives(builder, fetchDocuments);
    asyncThunkSingleArchive(builder, fetchSingleDocument);
  },
});

export const {
  updateDocuments,
  handleSingleDocument,
  handleToggleValue,
  handleCloseModal,
  setListOfUsers,
  handleElementTypesList,
} = archiveSlice.actions;

export default archiveSlice.reducer;
