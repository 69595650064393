import { generateExtraReducers } from 'utils/utils';

import type { ArchiveSliceTypes } from './types';

export const initialState: ArchiveSliceTypes = {
  documents: [],
  toggleValue: 'visibility',
  listOfUsers: [],
  elTypesList: [],
  modal: true,
  status: null,
  error: null,
  loading: false,
  singleDocument: null,
  singleStatus: null,
  singleError: null,
  singleLoader: false,
};

export const asyncThunkFetchArchives = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'status',
  errorKey: 'error',
});

export const asyncThunkSingleArchive = generateExtraReducers({
  initialStateProp: 'singleDocument',
  statusKey: 'singleStatus',
  errorKey: 'singleError',
  loadingKey: 'singleLoader',
});
