// TODO define type any
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'api/api';

import { asyncThunkSearch, initialState } from './state';

export const searchDocuments = createAsyncThunk(
  'searchDocumentsSlice/searchDocuments',
  async (query: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get(`/search/?q=${query}`);
      const filteredData = response.data.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      dispatch(setFoundDocuments(filteredData));
      return filteredData;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const searchResultsSlice = createSlice({
  name: 'searchResultsSlice',
  initialState,
  reducers: {
    setFoundDocuments(state, action: PayloadAction<any>) {
      return {
        ...state,
        searchResult: action.payload,
      };
    },
  },
  extraReducers(builder) {
    asyncThunkSearch(builder, searchDocuments);
  },
});

export const { setFoundDocuments } = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
