import { Box, Button, TextField, Typography } from '@mui/material';
import { AvatarUploadImage } from 'components/avatar-upload-image';
import { Filter } from 'components/filter';
import { BasicSelect } from 'components/select/basic-select';

import React from 'react';

import { useStyles } from './styles';
import type { CompanyProfileSettingsProps } from './types';

export function CompanyProfileSettingsComponent({
  updateFields,
  firmLogo,
  companyName,
  industry,
  workersNumber,
  industryTypes,
  workersAmount,
  onNext,
  onBack,
}: CompanyProfileSettingsProps) {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.profileSetWrapper}>
      <Box component='div' textAlign='center'>
        <Typography component='h3' className={classes.onboardingFormTitle}>
          Unternehmensprofil anpassen
        </Typography>
        <Typography component='p' className={classes.onboardingFormDescription}>
          Wähle euer Firmelogo, Firmenname und Firmengröße aus.
        </Typography>
      </Box>
      <AvatarUploadImage
        avatarSize='5rem'
        avatar={firmLogo}
        uploadBtnLabel='FIRMENLOGO HOCHLADEN'
        onChange={(e) => updateFields({ firmLogo: e.target.files[0] })}
      />
      <Box component='div' display='flex' flexDirection='column' gap='1.5rem'>
        <TextField
          variant='filled'
          fullWidth
          label='Name deines Unternehmens'
          value={companyName}
          onChange={(e) => updateFields({ companyName: e.target.value })}
        />
        <BasicSelect
          fullWidth
          variant='filled'
          label='Branche'
          value={industry}
          options={industryTypes}
          onChange={(e) => updateFields({ industry: e.target.value })}
        />
        <Box component='div' width='100%' mt='1rem'>
          <Typography component='p' className={classes.employeeLabelStyle}>
            Anzahl der Teammitglieder
          </Typography>
          <Box component='div' display='flex' justifyContent='space-between'>
            {workersAmount.map((option) => (
              <Filter
                key={option.id}
                label={option.label}
                value={option.value}
                checked={workersNumber === option.value}
                onClick={() => updateFields({ workersNumber: option.value })}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box component='div' display='flex' justifyContent='space-between'>
        <Button
          variant='text'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onBack}
        >
          ZURÜCK
        </Button>
        <Button
          variant='contained'
          className={classes.furtherButtonStyle}
          onClick={onNext}
          color='qsistant'
        >
          Weiter
        </Button>
      </Box>
    </Box>
  );
}
