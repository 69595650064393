import { Box, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import {
  AuditCriteria,
  CycleAudits,
  DocumentCompleted,
  DocumentStructureCard,
  EvidenceLocation,
  QualificationAudits,
  Responsibility,
  ScopeAudits,
  TableListingAudit,
} from 'forms/document-structure-components';
import type { TableAuditSelectedOptiosTypes } from 'forms/document-structure-components/types';
import useMultistepForm from 'hooks/useMultistepForm';
import { handleUpdateDocument } from 'store/documents-setup/documents-setup-slice';
import type {
  DocumentTypes,
  FifthStepTypes,
  FirstStepTypes,
  FourthStepTypes,
  SecondSTepTypes,
  SeventhStepTypes,
  SixthStepTypes,
  StructureSteps,
  ThirdStepTypes,
} from 'store/documents-setup/documents-setup.types';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

export function DocumentStructurePage({ setDocumentView }) {
  const dispatcher = useAppDispatch();

  const { activeDocument, documentsList } = useAppSelector((state) => ({
    activeDocument: state.documentsSetupReducer.documentSettings
      .activeDocument as DocumentTypes,
    documentsList: state.documentsSetupReducer.documentSettings.documents,
  }));

  const completedDocs = documentsList.filter(
    (document) => document.progress === 100
  );

  const [localDocument, setLocalDocument] = useState(activeDocument);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const documentProgress = localDocument.structureSteps
    .map((o) => o)
    .filter((w) => !isEmpty(w.selectedOption));

  const currentStepContent = localDocument.structureSteps.find(
    (step) => step.id === localDocument.activeStep
  );

  // updates the entire document on change
  useEffect(() => {
    dispatcher(handleUpdateDocument({ ...localDocument }));
  }, [localDocument]);

  // save document progress
  useEffect(() => {
    setLocalDocument({
      ...localDocument,
      progress: Number(
        (
          (documentProgress.length * 100) /
          localDocument.structureSteps.length
        ).toFixed(0)
      ),
    });
  }, [documentProgress.length]);

  const handleActiveStep = (i: number) => {
    setLocalDocument({ ...localDocument, activeStep: i });
    goToStructureStep(i);
  };

  const handleNextStructureStep = (
    data:
      | { id: number | string; label: string }
      | Array<{ id: number | string; label: string }>
      | string
      | Array<TableAuditSelectedOptiosTypes>,
    stepId: number
  ) => {
    const updatedStructureSteps = localDocument.structureSteps.map((step) => {
      if (step.id === stepId) {
        return {
          ...step,
          selectedOption: data,
        };
      }
      return step;
    }) as StructureSteps;
    setLocalDocument({
      ...localDocument,
      activeStep: structureStepIndex + 1,
      structureSteps: updatedStructureSteps,
    });
    goToStructureStep(structureStepIndex + 1);
  };

  const handleSave = (data: string, id: number) => {
    const saved = localDocument.structureSteps.map((step) => {
      if (step.id === id) {
        return {
          ...step,
          selectedOption: data,
        };
      }
      return step;
    }) as StructureSteps;
    setLocalDocument({
      ...localDocument,
      structureSteps: saved,
    });
    setIsCompleted(true);
  };

  const handleSelectedOption = (
    data:
      | { id: number | string; label: string }
      | Array<TableAuditSelectedOptiosTypes>
      | string,
    stepId: number
  ) => {
    const selected = localDocument.structureSteps.map((step) => {
      if (step.id === stepId) {
        return { ...step, selectedOption: data };
      }
      return step;
    }) as StructureSteps;
    setLocalDocument({
      ...localDocument,
      structureSteps: selected,
    });
  };

  const handleCreateDepartment = (department: string, id: number) => {
    const newData = localDocument.structureSteps.map((step) => {
      if ('departments' in step && step.id === id) {
        return {
          ...step,
          departments: [
            ...step.departments,
            { id: crypto.randomUUID(), label: department, value: department },
          ],
        };
      }
      return step;
    }) as StructureSteps;
    setLocalDocument({
      ...localDocument,
      structureSteps: newData,
    });
  };

  const handleCreateCheckField = (label: string, id: string | number) => {
    const updatedStructureSteps = localDocument.structureSteps.map((step) => {
      if ('audits' in step && step.id === id) {
        return {
          ...step,
          audits: [...step.audits, { id: crypto.randomUUID(), label }],
        };
      }
      return step;
    }) as StructureSteps;
    setLocalDocument({
      ...localDocument,
      structureSteps: updatedStructureSteps,
    });
  };

  const handleEditCheckField = (
    data: { id: string | number; label: string },
    stepId: number
  ) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if ('audits' in step && step.id === stepId) {
          const updatedAudits = [...step.audits].map((audit) => {
            if (audit.id.toString() === data.id) {
              return {
                ...audit,
                label: data.label,
              };
            }
            return audit;
          });
          return {
            ...step,
            audits: updatedAudits,
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const handleEditAuditCriteriaField = (
    data: { id: string; label: string },
    stepId: number
  ) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if (
          'selectedOption' in step &&
          Array.isArray(step.selectedOption) &&
          step.id === stepId
        ) {
          const updatedAudits = [...step.selectedOption].map((audit) => {
            if (audit.id === data.id) {
              return {
                ...audit,
                label: data.label,
              };
            }
            return audit;
          });
          return {
            ...step,
            selectedOption: updatedAudits,
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const handleRemoveCheckField = (
    removeId: string | number,
    stepId: number
  ) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if ('audits' in step && step.id === stepId) {
          let updatedAudits = [...step.audits] || [];
          if (updatedAudits.find((item) => item.id === removeId)) {
            updatedAudits = updatedAudits.filter(
              (item) => item.id !== removeId
            );
          }
          return {
            ...step,
            audits: updatedAudits,
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const handleRemoveField = (removeId: string | number, stepId: number) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if ('audits' in step && step.id === stepId) {
          let updatedAudits = [...step.audits] || [];
          if (updatedAudits.find((item) => item.id === removeId)) {
            updatedAudits = updatedAudits.filter(
              (item) => item.id !== removeId
            );
          }
          return {
            ...step,
            selectedOption: {},
            audits: updatedAudits,
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const createResponsField = (label: string, parentId: number) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if ('audits' in step && step.id === parentId) {
          return {
            ...step,
            audits: [...step.audits, { id: crypto.randomUUID(), label }],
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const selectCheckFields = (
    data: { label: string; id: number | string },
    stepId: number
  ) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if (
          'selectedOption' in step &&
          Array.isArray(step.selectedOption) &&
          step.id === stepId
        ) {
          let updatedSelectionOption = [...step.selectedOption] || [];
          if (updatedSelectionOption.find((item) => item.id === data.id)) {
            updatedSelectionOption = updatedSelectionOption.filter(
              (item) => item.id !== data.id
            );
          } else {
            updatedSelectionOption.push(data);
          }
          return {
            ...step,
            selectedOption: updatedSelectionOption,
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const createUnselectedField = (label: string, stepId: number) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if (
          'selectedOption' in step &&
          Array.isArray(step.selectedOption) &&
          step.id === stepId
        ) {
          return {
            ...step,
            selectedOption: [
              ...step.selectedOption,
              { id: crypto.randomUUID(), label },
            ],
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const handleRemoveData = (removeId: string | number, stepId: number) => {
    setLocalDocument({
      ...localDocument,
      structureSteps: localDocument.structureSteps.map((step) => {
        if (
          'selectedOption' in step &&
          Array.isArray(step.selectedOption) &&
          step.id === stepId
        ) {
          let updatedSelectionOption = [...step.selectedOption] || [];
          if (updatedSelectionOption.find((item) => item.id === removeId)) {
            updatedSelectionOption = updatedSelectionOption.filter(
              (item) => item.id !== removeId
            );
          }
          return {
            ...step,
            selectedOption: updatedSelectionOption,
          };
        }
        return step;
      }) as StructureSteps,
    });
  };

  const { structureStep, structureStepIndex, isLast, goToStructureStep } =
    useMultistepForm(
      [
        <CycleAudits
          key='cycleAudits'
          stepId={currentStepContent.id}
          createField={handleCreateCheckField}
          removeField={handleRemoveField}
          editField={handleEditCheckField}
          setOption={handleSelectedOption}
          toNextStep={handleNextStructureStep}
          {...(currentStepContent as FirstStepTypes)}
        />,
        <ScopeAudits
          key='scopeAudits'
          stepId={currentStepContent.id}
          createField={handleCreateCheckField}
          removeField={handleRemoveField}
          editField={handleEditCheckField}
          setOption={handleSelectedOption}
          toNextStep={handleNextStructureStep}
          {...(currentStepContent as SecondSTepTypes)}
        />,
        <TableListingAudit
          key='tableListingAudit'
          stepId={currentStepContent.id}
          setOption={handleSelectedOption}
          toNextStep={handleNextStructureStep}
          createDepartment={handleCreateDepartment}
          {...(currentStepContent as ThirdStepTypes)}
        />,
        <Responsibility
          key='resposibility'
          stepId={currentStepContent.id}
          toNextStep={handleNextStructureStep}
          createField={createResponsField}
          removeField={handleRemoveCheckField}
          editField={handleEditCheckField}
          onSelect={selectCheckFields}
          {...(currentStepContent as FourthStepTypes)}
        />,
        <QualificationAudits
          key='qualificationAudits'
          stepId={currentStepContent.id}
          createField={handleCreateCheckField}
          removeField={handleRemoveField}
          editField={handleEditCheckField}
          setOption={handleSelectedOption}
          toNextStep={handleNextStructureStep}
          {...(currentStepContent as FifthStepTypes)}
        />,
        <AuditCriteria
          key='auditCriteria'
          stepId={currentStepContent.id}
          createField={createUnselectedField}
          onRemove={handleRemoveData}
          editField={handleEditAuditCriteriaField}
          toNextStep={handleNextStructureStep}
          {...(currentStepContent as SixthStepTypes)}
        />,
        <EvidenceLocation
          key='evidenceLocation'
          stepId={currentStepContent.id}
          setOption={handleSelectedOption}
          onSave={handleSave}
          progress={localDocument.progress}
          {...(currentStepContent as SeventhStepTypes)}
        />,
      ],
      localDocument.activeStep
    );

  return (
    <Box component='div' p='2rem' width='100%'>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        sx={{ cursor: 'pointer', mb: '2rem', width: 'max-content' }}
        onClick={setDocumentView}
      >
        <IconButton className='Back' onClick={setDocumentView}>
          <Icon name='arrow_back_ios' size={2} />
        </IconButton>
        <Typography component='h2' className='PageHeader'>
          Alle Dokumente
        </Typography>
      </Box>
      {isCompleted ? (
        <DocumentCompleted
          documentsNumber={documentsList.length}
          completedDocumentsNumber={completedDocs.length}
          onClick={setDocumentView}
        />
      ) : (
        <Box
          component='div'
          display='flex'
          justifyContent='flex-start'
          p='0 1rem'
        >
          <DocumentStructureCard
            structureStepIndex={structureStepIndex}
            activeDocument={localDocument}
            onClick={handleActiveStep}
            {...currentStepContent}
          />
          <Box
            component='div'
            paddingLeft='13.2rem'
            width={
              isLast
                ? '53rem'
                : structureStepIndex === 5
                ? '35rem'
                : structureStepIndex === 3
                ? '58rem'
                : structureStepIndex === 0 || structureStepIndex === 1
                ? '60rem'
                : '67rem'
            }
          >
            {structureStep}
          </Box>
        </Box>
      )}
    </Box>
  );
}
