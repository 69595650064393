import Menu from '@mui/material/Menu';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/styles';

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiButton-root': {
    backgroundColor: '#E2D16B80',
  },
  '& .MuiPaper-root': {
    borderRadius: 4,
    marginTop: theme.spacing(0.5),
    minWidth: '382px',
    color:
      theme.palette.mode === 'light' ? '#000000DE' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      minHeight: '48px',
      position: 'relative',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.menuSelected
        ),
      },
    },
  },
}));

export const DividerFullWidth = styled('div')({
  height: '1px',
  background: '#0000001F',
  width: '100%',
  position: 'relative',
  bottom: '1px',
  '&::before, &::after': {
    content: "''",
    position: 'absolute',
    zIndex: 5,
    top: 0,
    bottom: 0,
    width: '2rem',
    background: '#0000001F',
  },
  '&::before': {
    left: '-2rem',
  },
  '&::after': {
    right: '-2rem',
  },
});
