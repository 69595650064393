import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import api from 'api/api';
import { LoadingButton } from 'components/button';
import { AuthContext } from 'components/login/AuthContext';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputPassword } from '../custom-input';
import { Icon } from '../icon';
import { contentBox } from './theme';

function LoginContent({ values, setValues, onChangeStage }) {
  const { t } = useTranslation(['login', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { dispatch } = React.useContext(AuthContext);
  const handleLogin = () => {
    setIsLoading(true);
    api
      .post(
        '/login',
        {
          email: values.username,
          password: values.password,
        },
        {}
      )
      .then((result) => {
        if (result.data?.tfa) {
          onChangeStage('auth_account');
        } else {
          dispatch({
            type: 'LOGIN',
            user: result?.data?.user,
            token: result?.data?.token,
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Collapse
        in={error}
        sx={{ position: 'absolute', top: 0, width: '100vw' }}
      >
        <Alert
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setError(false);
              }}
            >
              <Icon name='close' size={1.5} />
            </IconButton>
          }
        >
          {t('login:invalidcredentials')}
        </Alert>
      </Collapse>
      <Box sx={{ ...contentBox, width: '38rem' }} component='form'>
        <Icon name='new_logo' height={4.75} width={22.5} />
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          textAlign='center'
          alignItems='center'
          gap='1.5rem'
          width='100%'
          marginBottom='2.5rem'
        >
          <Typography className='StageLabel' component='h4'>
            {t('login:qsistantlogin')}
          </Typography>
          <TextField
            fullWidth
            id='email_textfield'
            label={t('login:email')}
            variant='outlined'
            name='email'
            type='email'
            error={error}
            value={values.username}
            onChange={(e) => setValues({ ...values, username: e.target.value })}
            className='LoginTextfield'
          />
          <InputPassword
            fullWidth
            id='login_password'
            variant='outlined'
            label='Passwort'
            className='LoginTextfield'
            value={values.password}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            onKeyUp={(e) => e.key === 'Enter' && handleLogin()}
          />
          <Button
            variant='text'
            disableRipple
            className='ForgotPassword'
            onClick={() => onChangeStage('forgot_password')}
          >
            {t('login:forgot-password')}
          </Button>
          <LoadingButton
            isLoading={isLoading}
            loaderSize={2}
            label={t('login:login')}
            onClick={handleLogin}
            className='Register'
            disabled={
              values.username === '' || values.password === '' || isLoading
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default LoginContent;
