import { Box, TextField } from '@mui/material';
import { BasicSelect } from 'components/select/basic-select';
import { useAppSelector } from 'store/hook';

import React from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { useStyles } from './styles';
import type { NewSupportDocumentFormProps } from './types';

export function NewSupportDocumentForm({
  title,
  onTitleChange,
  reference,
  onReferenceChange,
  externReference,
  onExternReferenceChange,
  className,
}: NewSupportDocumentFormProps) {
  const classes = useStyles();
  const { t } = useTranslation(['document', 'fillout']);

  const { versions } = useAppSelector(({ supportingDocumentsReducer }) => ({
    versions: supportingDocumentsReducer.versions,
  }));

  return (
    <Box
      component='form'
      className={clsx(classes.wrapper, className && className)}
    >
      <TextField
        fullWidth
        autoFocus
        variant='outlined'
        color='qsistant'
        label={t('document:title')}
        value={title || ''}
        onChange={onTitleChange}
      />
      <BasicSelect
        fullWidth
        options={versions}
        color='qsistant'
        label={t('fillout:referencedocument')}
        value={reference}
        onChange={onReferenceChange}
      />
      <Box component='div' className={classes.bottomInputsBox}>
        <TextField
          fullWidth
          disabled
          id='outlined-text'
          variant='outlined'
          label={t('fillout:filloutnumber')}
          type='text'
          value='DOC-00012'
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .Mui-disabled:not(label)': {
              backgroundColor: '#F5F5F5',
            },
          }}
        />
        <TextField
          fullWidth
          type='text'
          variant='outlined'
          label={t('fillout:externalreference')}
          autoFocus={false}
          value={externReference}
          onChange={onExternReferenceChange}
        />
      </Box>
    </Box>
  );
}
