import api from 'api/api';
import { API_URL } from 'api/api-urls';
import { NewProcessType } from 'store/administration/process/types';

export const getProcessesList = () => {
  return api.get(`${API_URL.processes.getAll}`);
};

export const handleAddProcess = (data: NewProcessType) => {
  return api.post(`${API_URL.processes.add}`, data);
};

export const handleEditProcess = (id: number, data: NewProcessType) => {
  return api.post(`${API_URL.processes.edit}/${id}`, data);
};

export const onRemoveProcess = (id: number) => {
  return api.delete(`${API_URL.processes.remove}/${id}`);
};

export const onHideProcess = (id: number) => {
  return api.post(`${API_URL.processes.hide}/${id}`);
};

export const onUnhideProcess = (id: number) => {
  return api.post(`${API_URL.processes.unhide}/${id}`);
};
