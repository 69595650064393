import api from 'api/api';
import { API_URL } from 'api/api-urls';
import { NewDocumentTypeTypes } from 'store/administration/document-type/types';

export const getAllDocumentTypes = () => {
  return api.get(`${API_URL.documentTypes.getAll}`);
};

export const getAllTypesAndProcesses = () => {
  return api.get(`${API_URL.typesAndProcesses.getAll}`);
};

export const createNewDocumentType = (data: NewDocumentTypeTypes) => {
  return api.post(`${API_URL.documentTypes.add}`, data);
};

export const removeDocumentType = (id: number) => {
  return api.delete(`${API_URL.documentTypes.delete}/${id}`);
};

export const editingDocumentType = (
  id: number,
  documentData: NewDocumentTypeTypes
) => {
  return api.post(`${API_URL.documentTypes.edit}/${id}`, documentData);
};

export const hideDocumentType = (id: number) => {
  return api.post(`${API_URL.documentTypes.hide}/${id}`);
};

export const unHideDocumentType = (id: number) => {
  return api.post(`${API_URL.documentTypes.unHide}/${id}`);
};
