import { Box, Typography } from '@mui/material';

import React from 'react';

import { labelStyle, lineColumn, lineRow, wrapper } from './styles';

interface DividerOderComponentProps {
  sx?: React.CSSProperties;
  orientation?: 'vertical' | 'horizontal';
  primaryColor?: string;
  className?: string;
}

export function DividerOderComponent({
  sx,
  orientation = 'vertical',
  primaryColor = '#4599bf',
  className,
}: DividerOderComponentProps) {
  return (
    <Box component='div' className={className} sx={{ ...wrapper, ...sx }}>
      <Box
        component='div'
        sx={orientation === 'vertical' ? lineColumn : lineRow}
      />
      <Typography component='p' sx={{ ...labelStyle, color: primaryColor }}>
        oder
      </Typography>
      <Box
        component='div'
        sx={orientation === 'vertical' ? lineColumn : lineRow}
      />
    </Box>
  );
}
