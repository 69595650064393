import api from 'api/api';
import { API_URL } from 'api/api-urls';

export const getDocumentOverviewList = () => {
  return api.get(`${API_URL.documentOverview.getAll}`);
};

export const getSingleDocument = (id: string) => {
  return api.get(`${API_URL.singleDocument.getSingle}/${id}`);
};

export const removeFromFavourite = (id: number) => {
  return api.delete(`${API_URL.singleDocument.removeFavourite}/${id}`);
};

export const addToFavourite = (id: number) => {
  return api.post(`${API_URL.singleDocument.addFavourite}/${id}`);
};
