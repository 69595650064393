import { Box, Button, SelectChangeEvent } from '@mui/material';
import { FabButton } from 'components/button';
import { FiltersBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { FilterDropdown } from 'components/filter-dropdown';
import { Icon } from 'components/icon';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalActionProcess } from 'modals/process-action';
import {
  addProcess,
  fetchProcesses,
  handleModal,
} from 'store/administration/process/process-slice';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';
import {
  resetFilters,
  setMultipleFilters,
} from 'store/common/page-filters/page-filters-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { multipleFiltersChange, sortByCriteria } from 'utils/utils';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sortProcessOptions } from '../constants';
import useTableCells from './table-cells.hook';

export function ProcessOverview() {
  const { t } = useTranslation(['status', 'document', 'processes']);
  const dispatch = useAppDispatch();
  const addNotification = useNotification();

  const {
    documents: processes,
    modal,
    processCatList,
    addProcessError: errors,
    status,
  } = useAppSelector((state) => ({
    documents: state.processReducer.documents,
    modal: state.processReducer.modal,
    processCatList: state.processReducer.processCatList,
    addProcessError: state.processReducer.addProcessError,
    status: state.processReducer.fetchProcessStatus,
  }));

  const { documents: categories } = useAppSelector((state) => ({
    documents: state.processTypeReducer.documents,
  }));

  const { persistedProcessCats } = useAppSelector((state) => ({
    persistedProcessCats:
      state.pageFiltersSlice.processPage.persistedProcessCats,
  }));

  const { cells } = useTableCells(categories, errors);

  const [filtered, setFiltered] = useState<ProcessDocumentsTypes[]>(null);
  const [sortBy, setSortBy] =
    useState<(typeof sortProcessOptions)[number]['value']>('default');
  const [title, setTitle] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [list, setList] = useState<string[]>(persistedProcessCats || ['']);

  const filterArrayByOptions = (
    arrayToFilter: ProcessDocumentsTypes[],
    options: string[]
  ) => {
    if (options.length === 1) {
      return arrayToFilter;
    }
    return arrayToFilter.filter((doc) => options.includes(doc.category.name));
  };

  const handleFilter = (e: SelectChangeEvent<string | string[]>) => {
    multipleFiltersChange(e, setList);
    dispatch(
      setMultipleFilters({
        key: 'processPage',
        subKey: 'persistedProcessCats',
        values: e.target.value as string[],
      })
    );
  };

  const resetFilter = () => {
    dispatch(resetFilters({ key: 'processPage' }));
    setList(['']);
  };

  const createNewProcess = (lab: string, cat: string) => {
    const categoryObj = categories.find((c) => c.name === cat && c.id);
    const categoryId = categoryObj && categoryObj.id ? categoryObj.id : null;
    const data = {
      category: categoryId,
      name: lab,
      id: 0,
    };
    dispatch(addProcess(data));
    addNotification({
      text: t('processes:alertprocessadded'),
    });
    setTitle('');
    setCategory('');
    dispatch(handleModal(false));
  };

  useEffect(() => {
    dispatch(fetchProcesses());
  }, [dispatch]);

  useEffect(() => {
    if (processes.length === 0) {
      return;
    }
    const filterRes = filterArrayByOptions(processes, list);
    const sorting = sortByCriteria(filterRes, sortBy);
    setFiltered(sorting as ProcessDocumentsTypes[]);
  }, [list, processes, sortBy]);

  return (
    <>
      {status === 'pending' || filtered === null || status === null ? (
        <Box component='div' mt={2}>
          {cells.map((item) => (
            <TableSkeleton key={item.id} />
          ))}
        </Box>
      ) : processes.length > 0 ? (
        <>
          <Box component='div' display='flex' justifyContent='space-between'>
            <FiltersBox>
              <FilterDropdown
                multiple
                value={list}
                isFilterApplied={list.length > 1}
                onChange={handleFilter}
                options={processCatList}
                primaryColor='management'
                filterLabel={t('processes:processcategory')}
              />
              <Button
                variant='text'
                color='management'
                disabled={list.length === 1}
                onClick={resetFilter}
              >
                {t('sort:resetfilter')}
              </Button>
            </FiltersBox>
            <SortSelect
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              options={sortProcessOptions}
              primaryColor='management'
              noOneChosen={sortBy === sortProcessOptions[0].value}
            />
          </Box>
          {filtered && filtered.length > 0 ? (
            <>
              <Table cells={cells} data={filtered} />
              <Box component='div' display='flex' justifyContent='center'>
                <FabButton
                  className='AbsolutePosition management'
                  label={t('processes:newprocess')}
                  onClick={() => dispatch(handleModal(true))}
                />
              </Box>
            </>
          ) : (
            <EmptyList
              image={{ name: ICON.UNFILTER_PROCESSES }}
              title={t('processes:filternoprocesses')}
              primaryButton={
                <Button
                  variant='outlined'
                  color='management'
                  onClick={resetFilter}
                >
                  {t('sort:resetfilter')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <EmptyList
          image={{ name: ICON.NO_PROCESSES }}
          title={t('processes:nocreatedprocesstitle')}
          description={t('processes:nocreatedprocessdesc')}
          primaryButton={
            <Button
              variant='contained'
              color='management'
              onClick={() => dispatch(handleModal(true))}
            >
              <Icon name={ICON.PLUS} size={1.5} fill='white' />
              {t('processes:addprocess')}
            </Button>
          }
        />
      )}
      {modal ? (
        <ModalActionProcess
          icon={{ name: ICON.NEW_PROCESS }}
          open={modal}
          errors={errors}
          options={categories}
          onClose={() => dispatch(handleModal(false))}
          title={title}
          header={t('processes:newprocess')}
          color='management'
          onChangeTitle={(e) => setTitle(e.target.value)}
          category={category}
          onChangeCategory={(e) => setCategory(e.target.value)}
          onAction={(processName, categoryName) =>
            createNewProcess(processName, categoryName)
          }
        />
      ) : null}
    </>
  );
}
