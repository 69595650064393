import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    cardWrapper: {
      border: '0.125rem solid #0000001F',
      background: '#fff',
      borderRadius: '0.625rem',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    labelStyle: {
      fontSize: `${typography.pxToRem(16)} !important`,
      color: '#000000DE',
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.007rem !important',
    },
    buttonStyle: {
      textTransform: 'capitalize !important',
      fontSize: `${typography.pxToRem(16)} !important`,
      '&:hover': {
        background: 'none',
      },
    } as CSSProperties & { textTransform: 'capitalize !important' },
    datePickerStyle: {
      '& .MuiInput-root:after': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      '& .MuiInput-root': {
        marginTop: '16px',
      },
      '& .MuiInput-root.Mui-focused:after': {
        borderBottom: '2px solid #6E358C',
      },
    },
  };
});
