import { Box, Fab } from '@mui/material';
import { Icon } from 'components/icon';
import { EmptyList } from 'components/list';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalProcessCategoryAction } from 'modals/process-category-action';
import {
  addProcessCategorie,
  fetchDocuments,
  handleModal,
} from 'store/administration/process-category/processtype-slice';
import type { CategoryTypes } from 'store/administration/process-category/types';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { sortByCriteria } from 'utils/utils';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sortTypeOptions } from '../constants';
import useTableCells from './table-cells.hook';

export function ProcessTypeOverview() {
  const { t } = useTranslation(['sort', 'status', 'document', 'processes']);
  const dispatch = useAppDispatch();
  const addNotification = useNotification();

  const {
    documents: categories,
    modal,
    addProcessCatError: errors,
  } = useAppSelector((state) => ({
    documents: state.processTypeReducer.documents,
    modal: state.processTypeReducer.modal,
    addProcessCatError: state.processTypeReducer.addProcessCatError,
  }));

  const { cells } = useTableCells();

  const [sortBy, setSortBy] = useState<
    (typeof sortTypeOptions)[number]['value']
  >(sortTypeOptions[0].value);
  const [filtered, setFiltered] = useState<CategoryTypes[]>(null);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const createNewCategorie = (catName: string, catDesc: string) => {
    const data = {
      name: catName,
      description: catDesc,
    };
    dispatch(addProcessCategorie(data));
    addNotification({
      text: t('processes:alertcategoryadded'),
    });
    dispatch(handleModal(false));
    setTitle('');
    setDescription('');
  };

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (categories.length === 0) {
      return;
    }
    setFiltered(sortByCriteria(categories, sortBy));
  }, [sortBy, categories]);

  return (
    <Box component='div'>
      <Box component='div' display='flex' justifyContent='flex-end'>
        <SortSelect
          options={sortTypeOptions}
          value={sortBy}
          primaryColor='management'
          onChange={(e) => setSortBy(e.target.value)}
          noOneChosen={sortBy === sortTypeOptions[0].value}
        />
      </Box>
      {categories.length > 0 ? (
        <Table cells={cells} data={filtered} />
      ) : categories.length !== 0 ? (
        <EmptyList
          title={t('document:emptydocumenttitle')}
          text={t('document:emptydocumenttext')}
          buttonLabel={t('document:createdocument').toUpperCase()}
          onClick={() => dispatch(handleModal(true))}
        />
      ) : (
        <Box component='div' mt={2}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <TableSkeleton key={item} />
          ))}
        </Box>
      )}
      {categories.length > 0 ? (
        <Box component='div' display='flex' justifyContent='center'>
          <Fab
            variant='extended'
            size='large'
            className='AbsolutePosition management'
            onClick={() => dispatch(handleModal(true))}
          >
            <Icon name={ICON.PLUS} size={1.5} fill='white' />
            {t('processes:newprocesscategory')}
          </Fab>
        </Box>
      ) : (
        ''
      )}
      {modal ? (
        <ModalProcessCategoryAction
          open={modal}
          icon={{ name: ICON.CATEGORY }}
          errors={errors}
          name={title}
          onChangeTitle={(e) => setTitle(e.target.value)}
          description={description}
          onChangeDescription={(e) => setDescription(e.target.value)}
          onClose={() => dispatch(handleModal(false))}
          color='management'
          header={t('processes:newprocesscategory')}
          onAction={(categoryName, desc) =>
            createNewCategorie(categoryName, desc)
          }
        />
      ) : null}
    </Box>
  );
}
