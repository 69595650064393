import { TBG_OPTIONS } from 'enum/common.enum';
import { ICON } from 'enum/icons.enum';

export const toggleOptions = [
  {
    id: 234,
    value: 'visibility',
    label: ICON.VISIBILITY_FILLED,
    disabled: false,
    tooltip: TBG_OPTIONS.VIEW_MODE,
  },
  {
    id: 1124,
    value: 'edit',
    label: ICON.EDIT,
    disabled: false,
    tooltip: TBG_OPTIONS.EDIT_MODE,
  },
  {
    id: 8924,
    value: 'compare',
    label: ICON.COMPARE,
    disabled: false,
    tooltip: TBG_OPTIONS.COMPARE_MODE,
  },
];

export const documentTypes = [
  { id: 24, label: 'Arbeitsanweisung', value: 'Arbeitsanweisung' },
  { id: 14, label: 'Anweisung', value: 'Anweisung' },
];

export const categorieType = [
  {
    id: 24,
    label: 'Managementprozesse',
    value: 'Managementprozesse',
    icon: 'rectangle',
    size: 0.8,
    fill: 'rgba(0, 0, 0, 0.87)',
  },
  {
    id: 14,
    label: 'Verwaltung',
    value: 'Verwaltung',
    icon: 'rectangle',
    size: 0.8,
    fill: 'rgba(0, 0, 0, 0.87)',
  },
];
// delete from support docs
export const subProcessType = [
  {
    id: 241,
    label: 'Managementprozesse',
    value: 'Managementprozesse',
    icon: 'label',
    size: 0.8,
    fill: 'rgba(0, 0, 0, 0.87)',
  },
  {
    id: 414,
    label: 'Verwaltung',
    value: 'Verwaltung',
    icon: 'label',
    size: 0.8,
    fill: 'rgba(0, 0, 0, 0.87)',
  },
];

// 21.02.23 New Filters
export const sortByOptions = [
  { id: 1, value: 'default', label: 'noselection' },
  { id: 2, value: 'alphabet ascending', label: 'alphabetascending' },
  { id: 3, value: 'alphabet descending', label: 'alphabetdescending' },
  {
    id: 4,
    value: 'completion date ascending',
    label: 'changedateascending',
  },
  {
    id: 42,
    value: 'completion date descending',
    label: 'changedatedescending',
  },
  {
    id: 429,
    value: 'number ascending',
    label: 'numberascending',
  },
  {
    id: 420,
    value: 'number descending',
    label: 'numberdescending',
  },
];
