import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getStandards } from 'api/services/common.services';

import type { StandardTypes, StandardsSlice } from './types';

const initialState: StandardsSlice = {
  standards: [],
};

export const fetchStandards = createAsyncThunk(
  'standards/fetchStandards',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getStandards();
      dispatch(setStandardsList(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const standardsSlice = createSlice({
  name: 'standardsSlice',
  initialState,
  reducers: {
    setStandardsList(state, action: PayloadAction<StandardTypes[]>) {
      return {
        ...state,
        standards: action.payload,
      };
    },
  },
});

export const { setStandardsList } = standardsSlice.actions;

export default standardsSlice.reducer;
