import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes = 'belowLineBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size }) => {
    return {
      belowLineBox: {
        width: '100%',
        gap: typography.pxToRem(size.fontL),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: typography.pxToRem(size.font24L),
      },
    };
  }
);

export const commonHeaderProductBox = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1.43rem 2.285rem',
};
