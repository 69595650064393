import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { StandardsAutocomplete } from 'components/autocomplete/standards-autocomplete';
import { DatePicker } from 'components/date-picker';
import { Icon } from 'components/icon';
import { BasicSelect } from 'components/select/basic-select';
import { useAppSelector } from 'store/hook';
import { shortName } from 'utils/utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { EditableContentProps } from './types';

export function EditableContent({
  expirydate,
  documentNumber,
  creatorName,
  revision,
  approved,
  creator,
  visibility,
  onChangeDate,
  docTypeValue,
  onDocTypeChange,
  processValue,
  onProcessChange,
  onVisibilityChange,
  standardChaptersValue,
  onChangeChapters,
  standardOptions,
}: EditableContentProps) {
  const { t } = useTranslation(['common', 'document', 'status']);
  const [state1, setState1] = useState([]);
  const { processes, categories } = useAppSelector((state) => ({
    categories: state.typeOverviewReducer.processCategories,
    processes: state.typeOverviewReducer.processes,
  }));

  const handleState = (
    value: { chapter: string; sub_chapters: any[]; standard: string },
    standard: string
  ) => {
    const exists = state1.some((item) => item.chapter === value.chapter);
    if (exists) {
      setState1(state1.filter((item) => item.chapter !== value.chapter));
    } else {
      setState1([...state1, { ...value, standard }]);
    }
  };
  return (
    <Stack
      direction='row'
      divider={<Divider orientation='vertical' flexItem />}
      spacing={2}
    >
      <Box component='div' display='flex' flexDirection='column' gap='0.625rem'>
        <BasicSelect
          label={t('document:documenttype')}
          sx={{ minWidth: '12rem' }}
          color='qsistant'
          options={categories}
          value={docTypeValue}
          onChange={(e) => onDocTypeChange(e.target.value)}
        />
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            {t('document:number')}
          </Typography>
          <Typography component='p' className='SDText'>
            {documentNumber}
          </Typography>
          <Typography component='p' className='SDText'>
            {t('document:revisiontext', { revision })}
          </Typography>
        </Box>
      </Box>
      <Box component='div' display='flex' flexDirection='column' gap='1rem'>
        <BasicSelect
          label={t('document:process')}
          sx={{ minWidth: '12rem' }}
          color='qsistant'
          options={processes}
          groupBy='category.name'
          value={processValue}
          onChange={(e) => onProcessChange(e.target.value)}
        />
      </Box>
      <Box component='div'>
        <Box
          component='div'
          display='flex'
          mb='0.35rem'
          justifyContent='space-between'
        >
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              {t('document:releaser')}
            </Typography>
            <Box
              component='div'
              display='flex'
              alignItems='center'
              gap='0.25rem'
            >
              {approved ? (
                <>
                  <Tooltip title={creatorName}>
                    <Avatar
                      className='SDAvatar'
                      src={`${process.env.REACT_APP_API_URL}/files/download/${creatorName}`}
                    />
                  </Tooltip>
                  <Typography component='p' className='SDText'>
                    {shortName(creatorName)}
                  </Typography>
                </>
              ) : (
                '-'
              )}
            </Box>
          </Box>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              {t('document:updater')}
            </Typography>
            <Tooltip title={creatorName}>
              <Avatar
                alt={creatorName}
                src={`${process.env.REACT_APP_API_URL}/files/download/${creator.avatar}`}
                className='SDAvatar'
              />
            </Tooltip>
          </Box>
        </Box>
        <Box component='div' display='flex' flexDirection='column' mb='1.25rem'>
          <Typography component='p' className='SDCaption'>
            {t('document:releasedate')}
          </Typography>
          <Typography component='p' className='SDText'>
            {approved ? new Date(approved).toLocaleDateString() : '-'}
          </Typography>
        </Box>
        <DatePicker
          color='qsistant'
          value={expirydate}
          variant='outlined'
          onChange={onChangeDate}
          label={t('document:expirydate')}
        />
        <Box component='div' display='flex' alignItems='center'>
          <Switch
            color='qsistant'
            value={visibility === 'public' ? 'private' : 'public'}
            onChange={onVisibilityChange}
            checked={visibility === 'public'}
          />
          <Typography component='p' className='SDText'>
            {visibility === 'public'
              ? t('document:visibilitypublic')
              : t('document:visibilityprivate')}
          </Typography>
          <IconButton>
            <Icon name='info' size={1.25} />
          </IconButton>
        </Box>
      </Box>
      <StandardsAutocomplete
        options={standardOptions}
        value={state1}
        onChange={(val, standard) => handleState(val, standard)}
        handler={setState1}
        label={t('document:assignedstandardchapter')}
        sx={{ width: '16rem' }}
      />
    </Stack>
  );
}
