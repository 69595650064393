import { Box, Button, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { InfoTooltipProps } from './types';

export function TooltipInfo({
  width = '100%',
  onClose,
  title,
  description,
  content = [],
  open,
  anchorEl,
}: InfoTooltipProps) {
  const classes = useStyles();
  return (
    <ContextMenuWrapper
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{ width, padding: '1.5rem' }}
    >
      <Typography component='h5' className={classes.title}>
        {title}
      </Typography>
      <Typography component='p' className={classes.description}>
        {description}
      </Typography>
      {content &&
        content.length > 0 &&
        content.map((item) => (
          <Box display='flex' flexDirection='column' key={item.id}>
            <Box
              component='div'
              display='flex'
              gap='0.5rem'
              alignItems='center'
            >
              <Icon name={item.icon} fill={item.iconColor} size={1} />
              <Typography component='p' className={classes.header}>
                {item.header}
              </Typography>
            </Box>
            <Box component='div' ml='1.5rem'>
              <Typography component='p' className={classes.text}>
                {item.text}
              </Typography>
            </Box>
          </Box>
        ))}
      <Box component='div' className={classes.btnBox}>
        <Button variant='text' color='qsistant' disableRipple onClick={onClose}>
          OK
        </Button>
      </Box>
    </ContextMenuWrapper>
  );
}
