export const wrapper = {
  position: 'relative',
  width: '12.4rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  padding: '2rem 1rem 1rem 1rem',
  background: '#fff',
  border: '2px solid #0000001F',
  borderRadius: '0.25rem',
  textAlign: 'center',
};

export const infoStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 'max-content',
  transform: 'translate(-50%, -50%)',
  fontSize: '0.75rem',
  color: '#3F97BE',
  background: '#D9EAF2',
  padding: '0.5rem',
  borderRadius: '0.25rem',
  fontWeight: 500,
};

export const checkboxStyle = {
  position: 'absolute',
  top: '-0.3rem',
  right: '-0.3rem',
};

export const radiosStyle = {
  position: 'absolute',
  top: '-0.3rem',
  right: '-0.3rem',
  '&.MuiRadio-root.Mui-checked': {
    color: '#4599BF',
  },
};

export const headerStyle = {
  fontSize: '1rem',
  color: 'black',
  fontWeight: 500,
  letterSpacing: '0.008rem',
};

export const descriptionStyle = {
  fontSize: '0.875rem',
  color: '#000000DE',
  fontWeight: 400,
  letterSpacing: 0,
};
