import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { statusData } from 'components/status/styles';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';
import { handleActiveList } from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchQuickaccess } from 'store/qsistant/quickaccess/quickaccess-slice';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { capitalize } from 'lodash';

import { QuickAccessProps } from './quick-access.types';
import { useStyles } from './styles';

export function ModalQuickAccess({ open, onClose }: QuickAccessProps) {
  const classes = useStyles();
  const { t } = useTranslation(['status', 'document']);
  const dispatch = useAppDispatch();
  const { categories, loading } = useAppSelector((state) => ({
    categories: state.quickaccessReducer.categories,
    loading: state.quickaccessReducer.categoriesLoading,
  }));

  useEffect(() => {
    dispatch(fetchQuickaccess());
  }, [dispatch]);

  const navigateToDocumentPage = () => {
    onClose();
    dispatch(handleActiveList({ parent: 0, item: 3 }));
  };

  return (
    <ModalWrapper open={open} onClose={onClose} className={classes.modalStyle}>
      <ModalHeader
        header={t('document:fastaccess')}
        onClose={onClose}
        icon={{ name: ICON.LIGHTNING }}
        className={classes.modalHeaderStyle}
      />
      <Divider className={classes.dividerStyle} />
      <Box component='div' className={classes.processesWrapper}>
        {loading
          ? [1, 2, 3, 4, 5, 6, 7, 8].map((item) => <TableSkeleton key={item} />)
          : categories?.map((option) => (
              <Box key={option.id} component='div'>
                <Box component='div' className={classes.processCard}>
                  {option.name}
                </Box>
                {option.processes && option.processes.length > 0 ? (
                  option.processes.map(({ name, id, versions }) => (
                    <Box key={id} component='div' sx={{ mb: '0.75rem' }}>
                      <Typography
                        component='p'
                        className={classes.processStyle}
                      >
                        {name}
                      </Typography>
                      {versions && versions.length > 0 ? (
                        versions?.map((item) => (
                          <Box
                            key={item.id}
                            component='div'
                            className={classes.subProcessBox}
                          >
                            <Tooltip
                              title={capitalize(t(`status:${item.status}`))}
                            >
                              <IconButton
                                disableRipple
                                sx={{
                                  p: '0.25rem',
                                  borderRadius: '0.25rem',
                                  background:
                                    statusData[item.status].backgroundColor ||
                                    '',
                                }}
                              >
                                <Icon
                                  name={
                                    item.status === 'released'
                                      ? ICON.LOCK_OPEN
                                      : item.status === 'check'
                                      ? ICON.SCHEDULE_SEND
                                      : item.status === 'edit'
                                      ? ICON.EDIT
                                      : item.status === 'checkout'
                                      ? ICON.DESIGN_SERVICES
                                      : ''
                                  }
                                  size={0.875}
                                  fill={statusData[item.status].color || ''}
                                />
                              </IconButton>
                            </Tooltip>
                            <Link
                              to={routes().singleDocumentOverview.replace(
                                ':id',
                                item.id.toString()
                              )}
                              style={{ display: 'flex', gap: '0.5rem' }}
                              onClick={navigateToDocumentPage}
                            >
                              <Typography
                                component='p'
                                className={classes.processSubStyle}
                              >
                                {item.name}
                              </Typography>
                              <Box
                                component='span'
                                className={classes.typeStyle}
                              >
                                {item.documentType.name}
                              </Box>
                            </Link>
                          </Box>
                        ))
                      ) : (
                        <Box component='div' className={classes.subProcessBox}>
                          <Typography
                            component='p'
                            className={classes.emptyVersionLabel}
                          >
                            Keine zugeordneten Dokumente/Nachweise.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))
                ) : (
                  <Typography component='p' className={classes.processStyle}>
                    Keine Prozesse
                  </Typography>
                )}
              </Box>
            ))}
      </Box>
    </ModalWrapper>
  );
}
