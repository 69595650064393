import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    labelBox: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: `${typography.pxToRem(8)}`,
    },
    fileTypeStyle: {
      fontWeight: `${typography.fontWeightMedium} !important`,
      color: '#CCCCCC',
      fontSize: `${typography.pxToRem(size.fontXS)} !important`,
      letterSpacing: '0.02rem !important',
    },
    label: {
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.025rem !important',
      color: '#666666',
      fontSize: `${typography.pxToRem(size.fontS)} !important`,
      '& > span > span': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  };
});
