import { Box, Button, Divider, TextField } from '@mui/material';
import { ModalHeader } from 'components/modal-header';

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ICON } from '../../../enum/icons.enum';
import { ModalWrapper } from '../../../wrappers/modal-wrapper/modal-wrapper';

export function ModalRenameProduct({ open, onClose, handleRenameProduct, id }) {
  // const { t } = useTranslation([]);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid, errors },
  } = useForm();

  const onSubmit = (data) => {
    const { productName } = data;
    if (productName.length < 3) {
      return;
    }

    if (!isValid) {
      return;
    }

    // mutate(id, productName);
    handleRenameProduct(id, productName);
    reset();
    onClose();
  };

  return (
    <ModalWrapper open={open} onClose={onClose} sx={{ width: '55rem' }}>
      <ModalHeader
        onClose={onClose}
        header='Produkt umbenennen'
        primaryColor='product'
        icon={{ name: ICON.TEST_TUBE }}
        gap='0.875rem'
      />
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ m: '1.5rem 1.5rem 0 1.5rem' }}>
          <Controller
            name='productName'
            control={control}
            defaultValue=''
            rules={{ required: true, minLength: 3 }}
            render={({ field }) => (
              <TextField
                color='product'
                label='Produktname'
                type='text'
                value={field.value}
                onChange={field.onChange}
                inputRef={field.ref}
                autoFocus
                fullWidth
                sx={{
                  m: '1.5rem 0',
                }}
              />
            )}
          />
          <Controller
            name='productId'
            control={control}
            defaultValue=''
            render={({ field }) => <input type='hidden' {...field} />}
          />
        </Box>
        <Box
          component='div'
          display='flex'
          justifyContent='end'
          p='1rem 1.5rem 1.5rem 1.5rem'
        >
          <Button
            variant='text'
            color='product'
            onClick={onClose}
            sx={{
              maxHeight: '36px',
              '&:hover': {
                background: 'rgb(27%, 74%, 51%, 0.12);',
                color: '#45BD81',
                borderColor: 'rgb(27%, 74%, 51%, 0.12)',
              },
            }}
            // disabled={status === 'loading'}
          >
            ABBRECHEN
            {/* {t('product:abort')} */}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='product'
            disabled={!isValid}
            sx={{
              maxHeight: '36px',
              '&.Mui-disabled': {
                background: 'rgb(27%, 74%, 51%, 0.54)',
                color: '#fff',
              },
            }}
          >
            ERSTELLEN
            {/* {t('product:upload')} */}
          </Button>
        </Box>
      </form>
    </ModalWrapper>
  );
}
