import api from 'api/api';
import { API_URL } from 'api/api-urls';
import type { CompanyDataTypes } from 'store/company-profile/types';

export const getOrganizationData = () => {
  return api.get(`${API_URL.organization.get}`);
};

export const saveOrganizationData = (data: CompanyDataTypes) => {
  return api.post(`${API_URL.organization.save}`, data);
};
