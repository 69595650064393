export const labelStyle = {
  fontSize: '1rem',
  color: '#000000DE',
  letterSpacing: '0.14px',
  fontWeight: 500,
};

export const descriptionStyle = {
  fontSize: '0.875rem',
  color: '#00000099',
  letterSpacing: '0.25px',
  fontWeight: 400,
};

export const sectionStyle = {
  p: '0.875rem 1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.875rem',
};
