import { Box, ListItemText, MenuItem, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';

import { useStyles } from './styles';
import { ContextMenuProps } from './types';

export function ContextMenuComponent({
  open,
  onClose,
  anchorEl,
  dataList,
  sx,
  textSize = '0.875rem',
}: ContextMenuProps) {
  const classes = useStyles();
  return (
    <ContextMenuWrapper
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      sx={{ padding: '0.25rem 0', ...sx }}
    >
      {dataList.map((item) => (
        <MenuItem
          key={item.id}
          disabled={item.disabled}
          sx={{ display: item.show ? 'flex' : 'none', gap: '0.5rem' }}
          onClick={item.onClick}
        >
          <Icon
            name={item.name}
            size={item.size || 1.5}
            fill={item.fill || '#00000099'}
          />
          <Box component='div'>
            <ListItemText
              className={classes.listItem}
              sx={{
                '& > span': {
                  fontSize: textSize,
                },
              }}
              primary={item.label}
            />
            <Typography component='p' className={classes.subLabel}>
              {item?.subLabel}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </ContextMenuWrapper>
  );
}
