import { Box, Button, Typography } from '@mui/material';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { ModalAttentionProps } from './types';

export function ModalAttention({
  open,
  onClose,
  title,
  description,
  className,
}: ModalAttentionProps) {
  const classes = useStyles();
  return (
    <ModalWrapper open={open} onClose={onClose} className={className}>
      <Box component='div' className={classes.contentBox}>
        <Typography component='h5' className={classes.title}>
          {title}
        </Typography>
        <Typography component='p' className={classes.description}>
          {description}
        </Typography>
      </Box>
      <Box component='div' className={classes.btnBox}>
        <Button variant='text' onClick={onClose} color='qsistant'>
          Ok
        </Button>
      </Box>
    </ModalWrapper>
  );
}
