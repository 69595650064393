import { generateExtraReducers } from 'utils/utils';

import type { MembersSliceTypes } from './types';

export const initialState: MembersSliceTypes = {
  documents: [],
  rolesList: [],
  modal: false,
  status: null,
  loading: false,
  error: null,
  addUserError: null,
  addUserStatus: null,
};

export const asyncThunkFetchMembersDocuments = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'status',
  errorKey: 'error',
  loadingKey: 'loading',
});

export const asyncThunkAddNewMember = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'addUserStatus',
  errorKey: 'addUserError',
});
