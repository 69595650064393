import { Button, ButtonProps } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

type ReviewerModeProps = {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  color?: ButtonProps['color'];
};

export function ReviewerMode({
  confirmLabel,
  onConfirm,
  color,
  cancelLabel,
  onCancel,
}: ReviewerModeProps) {
  return (
    <>
      <Button
        variant='contained'
        color={color}
        onClick={onConfirm}
        startIcon={<Icon name='fact_check' fill='#fff' size={1.5} />}
      >
        {confirmLabel}
      </Button>
      <Button
        variant='outlined'
        color={color}
        onClick={onCancel}
        startIcon={<Icon name='block' fill='#4599bf' size={1.5} />}
      >
        {cancelLabel}
      </Button>
    </>
  );
}
