// TODO define all types
import api from 'api/api';
import { API_URL } from 'api/api-urls';

export const getAllCustomers = () => {
  return api.get(`${API_URL.customers.getAll}`);
};

export const createNewCustomer = (data: any) => {
  return api.post(`${API_URL.customers.add}`, data);
};

export const editConcreteCustomer = (data: any) => {
  return api.put(`${API_URL.customers.edit}/${data.id}`, data);
};

export const deleteConcreteCustomer = (data: any) => {
  return api.delete(`${API_URL.customers.delete}/${data.id}`, data);
};
