import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React, { useState } from 'react';

import { VersionItem } from './components/version-item';
import { useStyles } from './styles';
import type { StepVersionBoxProps } from './types';

export function StepVersionBoxComponent({
  label,
  date,
  users,
}: StepVersionBoxProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Box component='div' className={classes.boxWrapper}>
      <Box
        component='div'
        className={classes.mainBoxContent}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Typography component='p' className={classes.title}>
          {label} - {date}
        </Typography>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setExpanded((prev) => !prev);
          }}
          className={classes.expandIcon}
          sx={{ rotate: expanded ? '180deg' : '0deg' }}
        >
          <Icon name={ICON.ARROW_DROP_DOWN} fill='black' />
        </IconButton>
      </Box>
      {expanded
        ? users.map((user) => (
            <VersionItem
              key={user.id}
              date={user.date}
              name={user.name}
              desc={user.desc}
            />
          ))
        : null}
      <Divider />
    </Box>
  );
}
