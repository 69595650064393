// TODO add missing fields for document overvie type (description, file, etc). If they are common please add it to "QsistantTabDocumentTypes"
// resolve red marks. Api request logic should be removed to documentOverview-slice. All unnecessary code should be removed
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  addToFavourite,
  removeFromFavourite,
} from 'api/services/document-overview.services';
import { SingleComparisonCard } from 'cards/single-comparison-card';
import { SingleDocumentCard } from 'cards/single-document-card';
import { ActionBox, BackBox } from 'components/common';
import { FileViewer } from 'components/file-viewer';
import { Icon } from 'components/icon';
import { BasicSelect } from 'components/select/basic-select';
import { SingleDocumentSidebar } from 'components/single-document-sidebar';
import type { StatusValues } from 'components/status/status.types';
import { TinyMce } from 'components/tinymce-editor';
import { ToggleButtonGroup } from 'components/toggle-button-group';
import { ELEMENT_TYPES } from 'enum/common.enum';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import {
  ReleaseConfirmation,
  RequestRelease,
  SimpleConfirmation,
} from 'modals/release';
import { SwitchModeModal } from 'modals/switch-mode/switch-mode.modal';
import { fetchAllTypesAndProcesses } from 'store/administration/document-type/document-type-slice';
import type { DocumentTypes } from 'store/administration/document-type/types';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';
import type {
  AvailableRevisionTypes,
  ElementTypes,
  OpenApprovalsTypes,
  OpenReviewsTypes,
  VisibilityTypes,
} from 'store/common-document.types';
import { fetchStandards } from 'store/common/standards/standards-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchSingleDocument } from 'store/qsistant/document-overview/document-overview-slice';
import type {
  DocumentOverviewTypes,
  ShortRevisionType,
} from 'store/qsistant/document-overview/types';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import api from '../../../api/api';
import { routes } from '../../../routes/routes';
import { EditableContent } from './components/card-content/editable-content';
import { UneditableContent } from './components/card-content/uneditable-content';
import { ApprovalMode, NoOneMode, ReviewerMode } from './components/roles';
import { toggleOptions } from './document-overview.constants';
import { useStyles } from './styles';

export function SingleDocumentPage({
  pathName,
  parentTab,
  homePage,
  parentRoute,
}) {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation([
    'document',
    'common',
    'status',
    'modal',
    'snackbar',
    'home',
  ]);
  const addNotification = useNotification();

  const { categories, processes, types, standards, isLoading } = useAppSelector(
    (state) => ({
      categories: state.typeOverviewReducer.processCategories,
      processes: state.typeOverviewReducer.processes,
      types: state.typeOverviewReducer.documents,
      standards: state.standardsReducer.standards,
      isLoading: state.documentOverviewReducer.singleLoader,
    })
  );

  const [toggleValue, setToggleValue] = useState<
    'visibility' | 'edit' | 'compare'
  >('visibility');

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [compareMode, setCompareMode] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(
    parseInt(String(localStorage.getItem('documentSidebar') ?? 1), 10)
  );
  const [cardExpanded, setCardExpanded] = useState(true);

  const [isSaveLoading, setIsSaveLoading] = useState(true);
  const [requestLoader, setRequestLoader] = useState(false);

  const [error, setError] = useState('');
  const [requestOpen, setRequestOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmation, setConfirmation] = useState<{
    type: 'review' | 'approval' | 'no_one' | string;
    signature: any;
  } | null>(null);
  const [simpleConfirmationOpen, setSimpleConfirmation] = useState(false);
  const [tocList] = useState([]);

  const [multiple2, setMultiple2] = useState([]);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(1);
  const [review, setReview] = useState('');
  const [dataToCompare, setDataToCompare] = useState(null);

  const [document, setDocument] = useState<{
    documentData: DocumentOverviewTypes | null;
    openApprovals: OpenApprovalsTypes[] | null;
    openReviews: OpenReviewsTypes[] | null;
    availableRevisions: AvailableRevisionTypes[] | null;
    payload: any;
    name: string;
    content: string;
    status: StatusValues;
    elementType: ElementTypes | null;
    documentNumber: string;
    revision: number;
    approved: Date | null;
    expirydate: Date | null;
    documentType: string;
    process: string;
    fullProcess: ProcessDocumentsTypes;
    fullCategory: DocumentTypes;
    category: string;
    isFavourite: boolean;
    tabValue: number;
    id: number;
    companyLogo: string;
    creator: string;
    visibility: VisibilityTypes;
  }>({
    documentData: null,
    name: '',
    content: '',
    approved: null,
    documentType: '',
    fullProcess: null,
    fullCategory: null,
    process: '',
    category: '',
    isFavourite: false,
    tabValue: 0,
    id: 0,
    expirydate: null,
    status: null,
    documentNumber: '',
    revision: 0,
    elementType: null,
    openApprovals: [],
    openReviews: [],
    availableRevisions: [],
    companyLogo: '',
    creator: '',
    visibility: null,
    payload: null,
  });

  useEffect(() => {
    dispatch(fetchAllTypesAndProcesses());
  }, []);

  useEffect(() => {
    dispatch(fetchStandards());
  }, []);

  useEffect(() => {
    dispatch(fetchSingleDocument(id)).then((response) => {
      const data: DocumentOverviewTypes = response.payload;
      setDocumentValues(data);
    });
  }, [id]);

  const handleFile = (uploadedFile) => {
    if (uploadedFile) {
      setProperty(uploadedFile, 'file');
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onloadend = (e) => setPdfFile(e.target.result);
    }
  };

  const saveDocument = (saveType = 'save') => {
    if (document.documentData) {
      setIsSaveLoading(true);
      if (document.file) {
        const formData = new FormData();
        formData.append('file', document.file, document.file.name);
        api
          .post('/files/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((ret) => {
            updateDocumentValues('file', null);
            api
              .post(`/document/save/${document.id}/${saveType}`, {
                ...document,
                payload: JSON.stringify({
                  uuid: ret.data?.uuid,
                }),
              })
              .then((r) => {
                handleUpdateType(saveType, r.data);
                setIsSaveLoading(false);
              });
          })
          .catch(() => {
            setIsSaveLoading(false);
            addNotification({
              text: t('document:erroronfileupload'),
            });
          });
      } else {
        api
          .post(`/document/save/${document.id}/${saveType}`, document)
          .then((r) => {
            handleUpdateType(saveType, r.data);
            setIsSaveLoading(false);
          })
          .catch(() => setIsSaveLoading(false));
      }
    }
  };

  const handleUpdateType = (saveType, doc: DocumentOverviewTypes) => {
    setDocumentValues(doc);
    addNotification({
      text: t('document:hasbeensaved'),
    });
    setCompareMode(false);
    setEditMode(false);
    setModal(false);
  };

  const handleUpdateDraft = () => {
    if (document.documentData) {
      saveDocument('checkin');
    }
  };

  const handleRequestRelease = () => {
    if (document.documentData) {
      setRequestOpen(true);
    }
  };

  const handleSendRequest = (users) => {
    const rev = users.reviewers.map((u) => u.id);
    const app = users.approvers.id;
    setRequestLoader(true);
    api
      .post(`/approval/request/${document.id}`, {
        reviewer: rev,
        approver: app,
      })
      .then((ret) => {
        setDocumentValues(ret.data);
        setRequestOpen(false);
        setRequestLoader(false);
      });
  };

  const setDocumentValues = (data: DocumentOverviewTypes) => {
    const payload = JSON.parse(
      data.payload && data.payload !== '' ? data.payload : '{}'
    );
    if (payload?.uuid) {
      setPdfFile(
        `${process.env.REACT_APP_API_URL}/files/download/${payload.uuid}`
      );
    }
    setDocument({
      payload,
      documentData: data,
      name: data.name,
      content: data.content,
      status: data.status,
      approved: data.approved,
      documentNumber: data.documentNumber,
      openApprovals: data.openApprovals,
      openReviews: data.openReviews,
      availableRevisions: data.availableRevisions,
      revision: data.revision,
      documentType: data.documentType.name,
      process: data.process.name,
      category: data.process.category.name,
      isFavourite: data.isFavourite,
      id: data.id,
      creator: data.creator.fullName,
      fullProcess: data.process,
      fullCategory: data.documentType,
      expirydate: data.expirydate,
      elementType: data.elementType,
      companyLogo: data.creator.organization.logo,
      visibility: data.visibility,
      tabValue:
        data.elementType === ELEMENT_TYPES.QSISTANT_DOCUMENT ||
        data.elementType === ELEMENT_TYPES.QSISTANT_FILL_OUT
          ? 0
          : 1,
    });
  };

  const updateDocumentValues = (
    field: keyof typeof document,
    value: string | boolean | ProcessDocumentsTypes | DocumentTypes
  ) => {
    setDocument((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const toggleGroupChange = (e, newValue) => {
    if (newValue === 'edit') {
      if (document.status === 'released') {
        setModal(true);
        setCompareMode(false);
      } else if (document.status === 'checkout') {
        // if document is in checkout mode, set to true
        setEditMode(true);
        setToggleValue('edit');
      } else if (document.status === 'edit') {
        api.post(`/document/checkout/${document.id}`).then((r) => {
          setEditMode(true);
          setToggleValue('edit');
          setCompareMode(false);
          setDocument(r.data);
        });
      }
    }
    if (newValue === 'visibility') {
      setModal(false);
      setEditMode(false);
      setCompareMode(false);
      setToggleValue('visibility');
      setReview('');
      setDataToCompare(null);
    }
    if (newValue === 'compare') {
      setCompareMode(true);
      setModal(false);
      setEditMode(false);
      setToggleValue('compare');
    }
  };

  const handleProcessChange = (processName: string) => {
    const proc = processes.filter((p) => p.name === processName)[0];
    if (proc) {
      updateDocumentValues('fullProcess', proc);
      updateDocumentValues('process', processName);
    }
  };

  const handleCategoryChange = (catName: string) => {
    const fullCategory = types.filter((p) => p.name === catName)[0];
    if (fullCategory) {
      updateDocumentValues('documentType', catName);
      updateDocumentValues('fullCategory', fullCategory);
    }
  };

  useEffect(() => {
    const localUser = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;

    if (document && localUser) {
      let confirm = {
        type: 'no_one',
        signature: null,
      };

      if (
        document.openReviews?.length > 0 &&
        document.openApprovals?.length > 0
      ) {
        const rev = document.openReviews.find(
          (r) => r.user.id === localUser.id
        );

        if (rev) {
          confirm = {
            type: 'review',
            signature: rev,
          };
        }
      } else {
        const app = document.openApprovals?.find(
          (a) => a.user.id === localUser.id
        );

        if (app) {
          confirm = {
            type: 'approval',
            signature: app,
          };
        }
      }
      setConfirmation(confirm);
    }
  }, [document.openReviews, document.openApprovals]);

  const handleConfirmationSuccess = (doc) => {
    setDocumentValues(doc);
    setConfirmation(null);
    setConfirmationOpen(false);
  };

  const onFavouriteChange = () => {
    if (!document.isFavourite) {
      addToFavourite(document.id).then(() => {
        updateDocumentValues('isFavourite', true);
        addNotification({
          text: t('document:addedfavourite'),
        });
      });
    } else {
      removeFromFavourite(document.id).then(() => {
        updateDocumentValues('isFavourite', false);
        addNotification({
          text: t('document:removedfavourite'),
        });
      });
    }
  };

  const onCreateFillout = () => {
    if (
      document.documentData.documentType.fillable &&
      document.status === 'released'
    ) {
      api.get(`/document-fill/init/${document.id}`).then((fillout) => {
        window.document.location.href = routes().singleSupportDocument.replace(
          ':id',
          fillout?.data?.id
        );
      });
    }
  };

  const handleDownloadFile = () => {
    if (pdfFile) {
      window.document.location.href = pdfFile;
    }
  };

  const handleRevertConfirm = (password) => {
    api
      .post(`/signature/revertapprovalprocess/${document.id}`, {
        password,
      })
      .then((r) => {
        setDocumentValues(r.data);
        setConfirmation(null);
        setSimpleConfirmation(false);
        setError('');
      })
      .catch((r) => {
        if (r?.response?.data?.error) {
          setError(t(`snackbar:${r.response?.data?.error}`));
        } else {
          setError(t('document:erroronsave'));
        }
      });
  };

  const handleSidebarExpandend = (value) => {
    setSidebarExpanded(value);
    localStorage.setItem('documentSidebar', (value ? 1 : 0).toString());
  };

  const boxIcons = [
    {
      id: 3,
      iconName: ICON.RESTORE,
      title: 'Auf Revision X zurücksetzen',
      onClick: undefined,
    },
    { id: 4, iconName: ICON.SHARE, title: 'Teilen', onClick: undefined },
    { id: 5, iconName: ICON.ARCHIVE, title: 'Archivieren', onClick: undefined },
    {
      id: 7,
      iconName: ICON.DOWNLOAD,
      title: 'Herunterladen',
      onClick: undefined,
    },
  ];

  const handleReviewChange = (e) => {
    setReview(e.target.value);
    const doc = document.documentData?.availableRevisions.find(
      (rev) => rev.value === e.target.value
    );
    setDataToCompare(doc);
  };

  if (isLoading || document.documentData === null) {
    return (
      <PageWrapper
        pathName={pathName}
        parentTab={parentTab}
        homePage={homePage}
        parentRoute={parentRoute}
        nestedDocument={`${t('document:document')}`}
      >
        <CircularProgress />
      </PageWrapper>
    );
  }

  const handleComparsionClose = () => {
    setReview('');
    setDataToCompare(null);
  };

  const handleComparsionNewTab = (option: ShortRevisionType) => {
    const url = routes().singleDocumentOverview.replace(
      ':id',
      option.id.toString()
    );
    window.open(url, '_blank');
  };

  return (
    <PageWrapper
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      parentRoute={parentRoute}
      nestedDocument={`${t('document:document')} "${document.name}"`}
    >
      <RequestRelease
        modal={requestOpen}
        handleSuccess={handleSendRequest}
        closeModal={() => setRequestOpen(false)}
        isLoading={isLoading}
        icon={{ name: ICON.LOCK_FILLED, color: 'qsistant' }}
      />
      <ReleaseConfirmation
        signature={confirmation?.signature}
        isReview={confirmation?.type === 'review'}
        modal={confirmationOpen}
        handleSuccess={handleConfirmationSuccess}
        closeModal={() => setConfirmationOpen(false)}
        isLoading={isLoading}
      />
      <SimpleConfirmation
        modal={simpleConfirmationOpen}
        handleSuccess={handleRevertConfirm}
        successLabel={t('modal:finishapprovalprocess')}
        successColor='deny'
        closeModal={() => setSimpleConfirmation(false)}
        isLoading={isLoading}
        title={t('document:revertapprovalprocess')}
        error={error}
      />
      <BackBox>
        <IconButton
          disableRipple
          sx={{ padding: 0 }}
          onClick={() =>
            path.includes('home')
              ? navigate(routes().home)
              : navigate(routes().document)
          }
        >
          <Icon name={ICON.ARROW_BACK_IOS} size={2} fill='rgba(0, 0, 0, 0.6)' />
          <Typography component='h4' className='PageHeader'>
            {path.includes('home')
              ? t('home:toHome')
              : t('document:backtodocumentlist')}
          </Typography>
        </IconButton>
      </BackBox>
      <ActionBox>
        <Box component='div' display='flex' gap='1rem' alignItems='center'>
          {confirmation?.type === 'review' &&
          document.openApprovals.length > 0 &&
          document.openReviews.length > 0 ? (
            <ReviewerMode
              confirmLabel='Prüfung bestätigen'
              cancelLabel='zurückweisen'
              onConfirm={() => console.log('APPR')}
              onCancel={() => console.log('onCancel')}
            />
          ) : confirmation?.type === 'approval' &&
            document.openApprovals.length > 0 &&
            document.openReviews.length > 0 ? (
            <ApprovalMode
              confirmLabel='freigabe bestätigen'
              cancelLabel='zurückweisen'
              onConfirm={() => console.log('APPR')}
              onCancel={() => console.log('onCancel')}
            />
          ) : confirmation?.type === 'no_one' &&
            document.openApprovals?.length > 0 &&
            document.openReviews?.length > 0 ? (
            <NoOneMode
              onClick={() => console.log('No One Mode')}
              label='Freigabe/prüfung zurückziehen'
            />
          ) : null}
          {!compareMode &&
          document.status !== 'check' &&
          document.status !== 'released' ? (
            <Button
              color='qsistant'
              onClick={!editMode ? handleRequestRelease : handleUpdateDraft}
              variant='contained'
              startIcon={
                <Icon
                  name={!editMode ? ICON.LOCK_FILLED : ICON.SAVE}
                  fill='#fff'
                />
              }
            >
              {!editMode
                ? t('document:requestrelease')
                : t('document:updatedraft')}
            </Button>
          ) : null}
          {!editMode && !compareMode ? (
            <Box component='div' className={classes.iconsBox}>
              <Tooltip key={1} title={t('document:addfavourite')}>
                <IconButton onClick={onFavouriteChange}>
                  <Icon
                    name={
                      document.isFavourite
                        ? ICON.BOOKMARK_FILLED
                        : ICON.BOOKMARK
                    }
                    size={1.125}
                  />
                </IconButton>
              </Tooltip>
              {document.documentData?.documentType?.fillable &&
              document.status === 'released' ? (
                <Tooltip key={2} title={t('document:createfillout')}>
                  <IconButton onClick={onCreateFillout}>
                    <Icon name='task' size={1.125} />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {boxIcons.map((icon) => (
                <Tooltip key={icon.id} title={icon.title}>
                  <IconButton onClick={icon.onClick}>
                    <Icon name={icon.iconName} size={1.125} />
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          ) : compareMode ? (
            <BasicSelect
              value={review}
              label='Zu vergleichendes Dokument'
              onChange={handleReviewChange}
              options={document.availableRevisions}
              customLabel={(option) =>
                t('document:documentrevision', { rev: option.id })
              }
              color='qsistant'
              sx={{ width: '37rem' }}
            />
          ) : (
            <Button
              variant='text'
              color='qsistant'
              startIcon={<Icon name={ICON.DESIGN_SERVICES} fill='inherit' />}
              onClick={() => saveDocument()}
            >
              {t('document:backupsave')}
            </Button>
          )}
        </Box>
        <ToggleButtonGroup
          selectedValue={toggleValue}
          options={toggleOptions.map((option) => {
            return {
              ...option,
              tooltip: t(`document:${option.tooltip}`),
              disabled:
                (option.value === 'edit' && document.status === 'check') ||
                (option.value === 'compare' &&
                  document?.availableRevisions?.length === 0),
            };
          })}
          onChange={(e, newValue) => toggleGroupChange(e, newValue)}
        />
      </ActionBox>
      <Box component='div' className={classes.mainCardsBox}>
        <Box
          component='div'
          className={classes.singleCardBox}
          sx={{
            width: sidebarExpanded
              ? 'calc(100% - 25.5rem)'
              : 'calc(100% - 5rem)',
          }}
        >
          {!compareMode ? (
            <SingleDocumentCard
              expanded={cardExpanded}
              onClose={() => setCardExpanded((prev) => !prev)}
              cardTitle={document.name}
              badge={{
                label: t(`document:${document.elementType}`),
                color: '#4599bf',
              }}
              statusBadge={{
                text: t(`status:${document.status}`),
                status: document.status,
              }}
              documentBasicInfo={{
                document: document.documentNumber,
                revision: document.revision,
                date: document.status === 'released' ? document.approved : null,
                logo: document.companyLogo,
              }}
              editMode={editMode}
              textFieldWidth='21rem'
              value={document.name}
              onChange={(e) => updateDocumentValues('name', e.target.value)}
            >
              {!editMode ? (
                <UneditableContent
                  documentType={document.documentType}
                  documentNumber={document.documentNumber}
                  revision={document.revision}
                  processName={document.process}
                  categoryName={document.category}
                  approved={document.approved}
                  creatorName={document.creator}
                  expirydate={document.expirydate}
                  creator={document.documentData.creator}
                />
              ) : (
                <EditableContent
                  expirydate={document.expirydate}
                  creatorName={document.creator}
                  documentNumber={document.documentNumber}
                  approved={document.approved}
                  revision={document.revision}
                  creator={document.documentData.creator}
                  visibility={document.visibility}
                  onChangeDate={(e) => updateDocumentValues('expirydate', e)}
                  docTypeValue={document.documentType}
                  onDocTypeChange={(val) => handleCategoryChange(val)}
                  processValue={document.process}
                  onProcessChange={(val) => handleProcessChange(val)}
                  onVisibilityChange={(e) =>
                    updateDocumentValues('visibility', e.target.value)
                  }
                  standardChaptersValue={multiple2}
                  onChangeChapters={(event, newVal) => setMultiple2(newVal)}
                  standardOptions={standards}
                />
              )}
            </SingleDocumentCard>
          ) : (
            <Box component='div' className={classes.comparisonCardsBox}>
              {review ? (
                <SingleComparisonCard
                  isValid
                  title={dataToCompare?.label}
                  primaryColor='#D4BD35'
                  indicator={{
                    color: '#D4BD35',
                    label: t('document:oldcontent'),
                  }}
                  data={{
                    id: dataToCompare?.id,
                    label: t('document:documentrevision', {
                      rev: dataToCompare?.revision,
                    }),
                    documentNumber: dataToCompare?.documentNumber,
                    date: t('document:releasedon', {
                      date: new Date(
                        dataToCompare?.releasedate
                      ).toLocaleDateString(),
                    }),
                  }}
                  handleClose={handleComparsionClose}
                  handleNewTab={handleComparsionNewTab}
                  bgColor='#FDFDF7'
                  status={{
                    status: dataToCompare?.status,
                    text: t(`status:${dataToCompare?.status}`),
                  }}
                />
              ) : null}
              <SingleComparisonCard
                isValid
                title={document.name}
                indicator={{
                  color: '#5CB85C',
                  label: t('document:currentcontent'),
                }}
                handleClose={handleComparsionClose}
                handleNewTab={handleComparsionNewTab}
                data={{
                  id: document.id,
                  label: t('document:documentrevision', {
                    rev: document.revision,
                  }),
                  documentNumber: document.documentNumber,
                  date: dataToCompare?.created
                    ? t('document:releasedon', {
                        date: new Date(
                          dataToCompare?.created
                        ).toLocaleDateString(),
                      })
                    : '-',
                }}
                status={{
                  status: document.status,
                  text: t(`status:${document.status}`),
                }}
                bgColor='#F9FCF9'
              />
            </Box>
          )}
          {!editMode ? (
            !pdfFile ? (
              <TinyMce
                documentContent={dataToCompare?.diff ?? document.content}
                onChange={(content) => updateDocumentValues('content', content)}
                readOnly={
                  toggleValue === 'visibility' || toggleValue === 'compare'
                }
                hideToolbar={
                  toggleValue === 'visibility' || toggleValue === 'compare'
                }
              />
            ) : (
              <FileViewer
                file={pdfFile}
                onDownload={handleDownloadFile}
                fileName={document.name}
              />
            )
          ) : (
            <>
              {tabValue === 0 ? (
                <TinyMce
                  documentContent={document.content}
                  onChange={(content) =>
                    updateDocumentValues('content', content)
                  }
                />
              ) : (
                <FileViewer
                  onRemove={() => setPdfFile(null)}
                  file={pdfFile}
                  fileName={document.name}
                  onInputChange={(e) => handleFile(e.target.files[0])}
                  onUploaderChange={(file) => handleFile(file)}
                />
              )}
              <Tabs
                value={tabValue}
                allowScrollButtonsMobile
                onChange={(event, newValue) => {
                  if (newValue === 0) {
                    setModal1(true);
                  }
                  setTabValue(newValue);
                }}
                className='SingleDocumentTabs'
                TabIndicatorProps={{ className: 'Qsistant' }}
              >
                <Tab
                  value={0}
                  icon={
                    <Icon
                      name='edit_note'
                      fill={tabValue === 0 ? '#4599bf' : '#00000099'}
                    />
                  }
                  iconPosition='start'
                  label='Qsistant editor'
                />
                <Tab
                  value={1}
                  icon={
                    <Icon
                      name='upload_file'
                      fill={tabValue === 1 ? '#4599bf' : '#00000099'}
                    />
                  }
                  iconPosition='start'
                  label='datei als dokument'
                />
              </Tabs>
            </>
          )}
        </Box>
        <SingleDocumentSidebar
          open={!!sidebarExpanded}
          onChange={(e) => updateDocumentValues('description', e.target.value)}
          onClose={() => handleSidebarExpandend(false)}
          onExpand={() => handleSidebarExpandend(true)}
          title={t('document:morinformation', { name: document.name })}
          caption={t('document:shortdescription')}
          description={document.description}
          status={document.status}
          list={tocList}
          editMode={editMode}
        />
      </Box>
      {modal ? (
        <SwitchModeModal
          open={modal}
          onClose={() => {
            setToggleValue('visibility');
            setCompareMode(false);
            setEditMode(false);
            setModal(false);
          }}
          onChange={() => {
            api.post(`/document/checkout/${document.id}`).then((r) => {
              setEditMode(true);
              setToggleValue('edit');
              setCompareMode(false);
              setModal(false);
              setDocumentValues(r.data);
            });
          }}
          header={t('modal:switcheditmodeheader')}
          description={t('modal:switcheditmodaldescription')}
          confirmBtnLabel={t('common:edit')}
        />
      ) : null}
      <SwitchModeModal
        open={modal1}
        onChange={() => {
          setModal1(false);
          updateDocumentValues('payload', '');
        }}
        onClose={() => {
          setModal1(false);
          setTabValue(tabValue === 1 ? 0 : 1);
        }}
        header={t('modal:changetoqstdocumentheader')}
        confirmBtnLabel={t('common:change')}
        description={t('modal:changetoqstdocumentdescription')}
      />
    </PageWrapper>
  );
}
