import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createNewDocumentType,
  editingDocumentType,
  getAllDocumentTypes,
  getAllTypesAndProcesses,
  hideDocumentType,
  removeDocumentType,
  unHideDocumentType,
} from 'api/services/document-types.services';

import { AxiosError } from 'axios';

import { asyncThunkFetchDocuments, initialState } from './state';
import type {
  DocumentTypes,
  DocumentsData,
  NewDocumentTypeTypes,
} from './types';

export const fetchDocuments = createAsyncThunk(
  'documenttype/fetchTypeDocuments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllDocumentTypes();
      dispatch(updateDocuments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const fetchAllTypesAndProcesses = createAsyncThunk(
  'documenttype/fetchAllTypesAndProcesses',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllTypesAndProcesses();
      dispatch(setTypesAndProcesses(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const addDocumentType = createAsyncThunk(
  'documenttype/addDocumentType',
  async (data: NewDocumentTypeTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await createNewDocumentType(data);
      dispatch(updateDocuments(response.data));
      dispatch(handleModal(false));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const deleteDocumentType = createAsyncThunk(
  'documenttype/deleteDocumentType',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await removeDocumentType(id);
      dispatch(handleRemoveDocumentType(id));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const editDocumentType = createAsyncThunk(
  'documenttype/editDocumentType',
  async (type: NewDocumentTypeTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await editingDocumentType(type.id, type);
      dispatch(updateDocuments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const deactivateDocumentType = createAsyncThunk(
  'documenttype/deactivateDocumentType',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await hideDocumentType(id);
      dispatch(updateDocuments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const activateDocumentType = createAsyncThunk(
  'documenttype/activateDocumentType',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await unHideDocumentType(id);
      dispatch(updateDocuments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const documentTypeSlice = createSlice({
  name: 'typeSlice',
  initialState,
  reducers: {
    updateDocuments(state, action: PayloadAction<DocumentTypes[]>) {
      return {
        ...state,
        documents: action.payload.toReversed(),
      };
    },

    handleRemoveDocumentType(state, action: PayloadAction<number>) {
      const filtered = state.documents.filter((d) => d.id !== action.payload);
      return {
        ...state,
        documents: filtered,
      };
    },

    handleModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    setTypesAndProcesses(state, action: PayloadAction<DocumentsData>) {
      const { documentTypes, processCategories, processes } = action.payload;
      return {
        ...state,
        documents: documentTypes,
        processCategories,
        processes,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTypesAndProcesses.pending, (state) => {
        state.typesAndProcessesError = null;
        state.typesAndProcessesStatus = 'pending';
      })
      .addCase(fetchAllTypesAndProcesses.fulfilled, (state, action) => {
        state.typesAndProcessesError = null;
        state.typesAndProcessesStatus = 'resolved';
        if (state.typesAndProcessesStatus === 'resolved') {
          const { documentTypes, processCategories, processes } =
            action.payload;
          state.documents = documentTypes;
          state.processCategories = processCategories;
          state.processes = processes;
        }
      })
      .addCase(fetchAllTypesAndProcesses.rejected, (state, action) => {
        const { response } = action.payload as AxiosError<any, any>;
        state.typesAndProcessesError = response?.data ?? null;
        state.typesAndProcessesStatus = 'rejected';
      });

    asyncThunkFetchDocuments(builder, fetchDocuments);
  },
});

export const {
  updateDocuments,
  handleRemoveDocumentType,
  handleModal,
  setTypesAndProcesses,
} = documentTypeSlice.actions;

export default documentTypeSlice.reducer;
