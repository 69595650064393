import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { LoadingButton } from 'components/button';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DefaultModalProps } from './types';

export function DefaultModal({
  closeModal,
  modal,
  modalTitle,
  children,
  handleSuccess,
  handleDeny = null,
  denyLabel,
  denyDisabled,
  abortLabel,
  successLabel,
  successColor,
  isLoading = false,
  disabled = false,
  icon,
  className,
}: DefaultModalProps) {
  const { t } = useTranslation('modal');

  return (
    <ModalWrapper open={modal} onClose={closeModal} className={className}>
      <ModalHeader
        header={modalTitle ?? t('modal:defaulttitle')}
        onClose={closeModal}
        icon={{ name: icon?.name, fill: icon?.fill || '#fff' }}
        primaryColor={icon?.color || 'management'}
      />
      <Divider />
      {children ?? <CircularProgress />}
      <Box
        component='div'
        padding='0 2rem 1rem'
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          variant='text'
          onClick={closeModal}
          color={successColor ?? 'qsistant'}
        >
          {abortLabel ?? t('modal:abort')}
        </Button>
        {handleDeny ? (
          <LoadingButton
            isLoading={isLoading}
            variant='contained'
            disabled={denyDisabled}
            onClick={closeModal}
            color='warning'
            label=''
            sx={{ mr: 1 }}
          >
            {denyLabel ?? t('modal:deny')}
          </LoadingButton>
        ) : null}
        <LoadingButton
          isLoading={isLoading}
          variant='contained'
          color={successColor ?? 'qsistant'}
          onClick={handleSuccess}
          disabled={disabled}
          label={successLabel ?? t('modal:success')}
        />
      </Box>
    </ModalWrapper>
  );
}
