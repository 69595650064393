import {
  Box,
  Button,
  Divider,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { ICON } from 'enum/icons.enum';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useRef, useState } from 'react';

import { CreateTypeTooltip } from 'tooltips';

import type { DocumentTypeActionModalProps } from './document-type-action.types';
import { useStyles } from './styles';

export function ModalActionDocumentType({
  open,
  header,
  onClose,
  title,
  description,
  onChangeTitle,
  onChangeDescription,
  onAction,
  switchValue,
  onSwitch,
}: DocumentTypeActionModalProps) {
  const iconRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <ModalWrapper open={open} className={classes.modalStyle}>
      <ModalHeader
        header={header}
        onClose={onClose}
        icon={{ name: ICON.SCANNER }}
      />
      <Divider />
      <Box component='div' className={classes.inputsBox}>
        <TextField
          autoComplete='off'
          label='Titel'
          color='qsistant'
          value={title}
          onChange={onChangeTitle}
        />
        <TextField
          multiline
          rows={4}
          value={description}
          onChange={onChangeDescription}
          label='Beschreibung Prozesskategorie (optional)'
          color='qsistant'
        />
      </Box>
      <Box component='div' padding='1.5rem' display='flex' alignItems='center'>
        <Switch onChange={onSwitch} checked={switchValue} />
        <Typography component='p' className={classes.allowOutput}>
          Ableiten von Nachweisdokumenten aus Dokumentenart zulassen
        </Typography>
        <IconButton ref={iconRef} onClick={() => setShowTooltip(true)}>
          <Icon
            name='info'
            size={1.25}
            fill={showTooltip ? '#4599bf' : 'rgba(0, 0, 0, 0.6)'}
          />
        </IconButton>
      </Box>
      <Box component='div' className={classes.buttonsBox}>
        <Button variant='text' onClick={onClose} color='qsistant'>
          Abbrechen
        </Button>
        <Button
          disabled={!title}
          variant='contained'
          color='qsistant'
          onClick={() => onAction(title, description, switchValue)}
        >
          Hinzufügen
        </Button>
      </Box>
      {showTooltip ? (
        <CreateTypeTooltip
          open={showTooltip}
          onClose={() => setShowTooltip(false)}
          anchorEl={iconRef}
        />
      ) : null}
    </ModalWrapper>
  );
}
