import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    labelBox: {
      padding: '1.5rem 1.5rem 0 1.5rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    wrapperBox: {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '0.5rem',
      width: '100%',
      backgroundColor: '#fff',
    },
    labelStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.15px !important',
      fontWeight: `${typography.fontWeightMedium} !important`,
      fontSize: `${typography.pxToRem(20)} !important`,
    },
    btnBox: {
      display: 'flex',
      margin: '0 auto',
      width: 'max-content',
      padding: `${typography.pxToRem(16)} 0`,
    },
    buttonStyle: {
      letterSpacing: `${typography.pxToRem(1.25)} !important`,
      width: '100%',
    },
    cardsBox: {
      padding: `${typography.pxToRem(16)}`,
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(16)}`,
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#fff',
        display: 'list-item',
        width: 4,
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: '#6b6b6b',
        minHeight: 24,
      },
      '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
        backgroundColor: '#959595',
      },
      '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
        {
          backgroundColor: '#959595',
        },
      '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#959595',
      },
    } as CSSProperties & { overflowX: 'auto !important' },
  };
});
