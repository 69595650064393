import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'wrapper'
  | 'sectionBox'
  | 'sectionTitle'
  | 'sectionLabel'
  | 'btnBox'
  | 'btnStyle';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography }) => {
    return {
      wrapper: {
        minHeight: 'calc(100vh - 15rem)',
        background: '#FFFFFFDE',
        borderRadius: '0.5rem',
        padding: '1.5rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      },
      sectionBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${typography.pxToRem(24)}`,
      },
      sectionTitle: {
        letterSpacing: '0.02rem !important',
        fontWeight: `${typography.fontWeightMedium} !important`,
        fontSize: `${typography.pxToRem(20)} !important`,
      },
      sectionLabel: {
        fontSize: `${typography.pxToRem(16)} !important`,
        color: '#7F7F7F',
        fontWeight: `${typography.fontWeightRegular} !important`,
        letterSpacing: '0.033rem !important',
      },
      btnBox: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
      },
      btnStyle: {
        width: 'max-content',
        letterSpacing: '0.078rem !important',
      },
    };
  }
);
