import { Box, Button, Divider, TextField } from '@mui/material';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ShareRequestDataModal({
  value,
  open,
  onClose,
  handleShareDataSubmit,
  headerName,
}) {
  const { t } = useTranslation(['product', 'requestrelease']);
  const [shareDataPass, setShareDataPass] = useState('');
  const [comment, setComment] = useState('');

  return (
    <ModalWrapper open={open} onClose={onClose} sx={{ width: '37.5rem' }}>
      <ModalHeader
        header={`${value ? 'Dataset' : 'Akte'} "${headerName}" freigeben`}
        onClose={onClose}
        primaryColor='product'
        icon={{ name: 'lock_open' }}
        sx={{ p: '1.25rem 2rem 1.25rem 2rem' }}
      />
      <Divider />
      <form onSubmit={handleShareDataSubmit}>
        <Box
          sx={{
            m: '2.1875rem 2rem 0.5rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '2.1875rem',
          }}
        >
          <TextField
            color='product'
            label='Aktuelles Passwort'
            type='password'
            value={shareDataPass || ''}
            onChange={(e) => setShareDataPass(e.target.value)}
            fullWidth
          />
          {!!value && (
            <TextField
              color='product'
              label='Kommentare und Hinweise'
              type='text'
              value={comment || ''}
              onChange={(e) => setComment(e.target.value)}
              fullWidth
            />
          )}
        </Box>
        <Box component='div' display='flex' justifyContent='end' p='2rem'>
          <Button
            onClick={onClose}
            variant='outlined'
            sx={{
              borderColor: '#fff',
              color: '#45BD81',
              letterSpacing: '0',
              maxHeight: '36px',
              p: '6.5px 16px',
              '&:hover': {
                background: 'rgb(27%, 74%, 51%, 0.12);',
                color: '#45BD81',
                borderColor: 'rgb(27%, 74%, 51%, 0.12)',
              },
            }}
          >
            {t('product:abort')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            disabled={shareDataPass.length < 6}
            sx={{
              background: '#45BD81',
              letterSpacing: '0',
              p: '7.5px 16px',
              maxHeight: '36px',
              '&:hover': { background: '#45BD81' },
              '&.Mui-disabled': {
                background: 'rgb(27%, 74%, 51%, 0.54)',
                color: '#fff',
              },
            }}
          >
            {t('approval:requestrelease')}
          </Button>
        </Box>
      </form>
    </ModalWrapper>
  );
}
