// Perhaps its better to add GlobalToast when error occurs.
import { Box, Button, Divider, TextField } from '@mui/material';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ProcessCategoryActionProps } from './process-category.types';

export function ModalProcessCategoryAction({
  header,
  open,
  onClose,
  name,
  description,
  onChangeTitle,
  onChangeDescription,
  onAction,
  color,
  errors = null,
  icon,
  isEditing = false,
}: ProcessCategoryActionProps) {
  const { t } = useTranslation(['processes', 'modal']);

  const hasViolation = (fieldType) => {
    if (!errors) return false; // no errors no violation
    const violation = errors?.violations.filter(
      (v) => v.propertyPath === fieldType
    );
    return violation && violation.length > 0;
  };

  const getViolation = (fieldType) => {
    if (!hasViolation(fieldType)) return ''; // no errors no violation

    const violation = errors?.violations.filter(
      (v) => v.propertyPath === fieldType
    );
    return violation && violation.length > 0 ? violation[0].title : '';
  };
  return (
    <ModalWrapper open={open} sx={{ width: '35rem' }}>
      <ModalHeader
        header={header}
        onClose={onClose}
        primaryColor={color}
        icon={{ name: icon.name }}
      />
      <Divider />
      <Box
        component='div'
        padding='1.5rem'
        display='flex'
        flexDirection='column'
        gap='1.5rem'
      >
        <TextField
          autoComplete='off'
          autoFocus
          label={t('processes:processtypes')}
          color={color}
          value={name}
          onChange={onChangeTitle}
        />
        <TextField
          multiline
          autoComplete='off'
          rows={4}
          value={description}
          onChange={onChangeDescription}
          label='Beschreibung Prozesskategorie (optional)'
          color={color}
        />
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='flex-end'
        padding='0 1.5rem 1.5rem 1.5rem'
      >
        <Button variant='text' onClick={onClose} color={color}>
          {t('modal:abort')}
        </Button>
        <Button
          disabled={name.length < 1}
          variant='contained'
          color={color}
          onClick={() => {
            onAction(name, description);
            onClose();
          }}
        >
          {isEditing ? t('modal:save') : t('modal:success')}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
