import { ICON } from 'enum/icons.enum';

export const packagePrivelege = [
  {
    id: 1,
    icon: ICON.GROUP_PEOPLE,
    label: 'Unbegrenzte Lizenzen Teammitglieder',
  },
  {
    id: 2,
    icon: ICON.MONITOR_HEART,
    label: 'Med. Produktentwicklung nach 13485',
  },
  { id: 3, icon: ICON.FACT_CHECK, label: 'Softwarevalidierung inklusive' },
];
