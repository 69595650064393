import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'wrapper'
  | 'sectionBox'
  | 'btnBox'
  | 'verifyBox'
  | 'sectionTitle'
  | 'pVerTitle'
  | 'pVerText'
  | 'iconBtn'
  | 'infoIcon';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, palette }) => {
    return {
      wrapper: {
        background: '#FFFFFFDE',
        borderRadius: '0.5rem',
        padding: '1.5rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 'calc(100vh - 17rem)',
      },
      sectionBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
      },
      btnBox: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'end',
      },
      verifyBox: {
        width: '100%',
        background: '#fff',
        border: '2px solid #0000001F',
        borderRadius: '0.25rem',
        padding: '1rem',
        display: 'flex',
      },
      sectionTitle: {
        letterSpacing: '0.02rem !important',
        fontWeight: `${typography.fontWeightMedium} !important`,
        fontSize: `${typography.pxToRem(20)} !important`,
      },
      pVerTitle: {
        fontSize: `${typography.pxToRem(16)} !important`,
        fontWeight: `${typography.fontWeightMedium} !important`,
        letterSpacing: '0.03rem !important',
      },
      pVerText: {
        fontSize: `${typography.pxToRem(14)} !important`,
        color: '#00000099',
        fontWeight: `${typography.fontWeightRegular} !important`,
        letterSpacing: '0.015rem !important',
      },
      iconBtn: {
        padding: '0 !important',
        '&:hover': {
          fill: palette.outlined.main,
        },
      },
      infoIcon: {
        '&:hover': {
          fill: 'inherit',
        },
      },
    };
  }
);
