import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    background: 'white',
    display: 'flex',
  },
  LogoWrapper: {
    flex: 1,
    background: '#ccd9e3',
    maxWidth: '40vw',
    minWidth: '520px',
    width: 'auto',
    minHeight: '100vh',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  QsistantArrangement: {
    padding: '0 10px',
    width: '470px',
    margin: 'auto',
  },
  margin: {
    marginTop: 5,
  },
  Logo: {
    height: '32px',
  },
  LogoText: {
    margin: '42.6px 0 79px',
    fontFamily: 'Roboto',
    fontSize: '28px',
    fontWeight: '500',
    color: '#4199bf',
  },
  QsistantImage: {
    width: '450px',
  },

  // Form styling
  LoginContainer: {
    flex: 1,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  LoginArrangement: {
    padding: '0 10px',
    maxWidth: '580px',
    minWidth: '400px',
    margin: 'auto',
  },
  FormHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 32,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'normal',
    marginBottom: 12,
  },
  subTitle: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 21,
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '0px 0px 30px 0px',
  },

  // Login input

  InputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  Emailinput: {
    marginBottom: 20,
  },

  CheckboxWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '11px 0px',
  },

  forgetPassword: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 19,
    fontWeight: 'normal',
    color: '#4199bf',
  },
  Checkbox: {
    fontFamily: theme.fonts.family.primary,
    fontSize: 19,
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  LoginBtn: {
    background: '#3F98BD !important',
    width: '100%',
    height: 50,
    fontFamily: theme.fonts.family.primary,
    textTransform: 'none',
    fontSize: 19,
    fontWeight: 'normal',
    border: 'none',
    boxShadow: 'none !important',
    color: 'white !important',
    '&:hover': {
      background: '#e0d289',
    },
    '&:disabled': {
      opacity: 0.54,
    },
  },
  button: {
    background: '#3F98BD !important',
    fontFamily: theme.fonts.family.primary,
    textTransform: 'none',
    fontWeight: 'normal',
    border: 'none',
    marginLeft: 10,
    boxShadow: 'none !important',
    color: 'white !important',
    '&:hover': {
      background: '#e0d289',
    },
  },
  text: {
    width: 250,
  },
  confirmationWrapper: {
    marginTop: 10,
  },
}));
