import { Box, Button, Typography } from '@mui/material';
import { CheckCard } from 'cards/check-card';
import { DividerOder } from 'components/divider-oder';

import React from 'react';

import { useStyles } from './styles';
import type { CreateQmsProfileSettingsProps } from './types';

export function CreateQmsProfileSettingsComponent({
  qmSystem,
  updateFields,
  onNext,
  onBack,
}: CreateQmsProfileSettingsProps) {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.profileSetWrapper}>
      <Box component='div' textAlign='center'>
        <Typography component='h3' className={classes.onboardingFormTitle}>
          Qualitätsmanagementsystem erstellen
        </Typography>
        <Typography component='p' className={classes.onboardingFormDescription}>
          Wie möchtest du mit deinem neuen QM-System beginnen?
        </Typography>
      </Box>
      <Box component='div' display='flex' justifyContent='center' gap='3rem'>
        <Box component='div' p='1rem'>
          <CheckCard
            value='ai_support'
            badge
            fieldValue={qmSystem}
            sx={{ height: '18.6rem' }}
            onClick={() => updateFields({ qmSystem: 'ai_support' })}
            icon={{
              name: 'waving_hand_filled',
              size: 2.25,
              fill: qmSystem === 'new_system' ? '#4599bf' : '#00000099',
            }}
            header='Neues QM-System eigenständig erstellen'
            description='Erstelle eigenständig dein QM-System ohne weiteren Hilfen und Anweisungen. (QM-Expertise von Vorteil)'
          />
        </Box>
        <DividerOder />
        <Box component='div' padding='1rem'>
          <CheckCard
            value='new_system'
            sx={{ height: '18.6rem' }}
            fieldValue={qmSystem}
            header='Neues QM-System mit KI-Unterstützung'
            // onClick={() => updateFields({ qmSystem: 'new_system' })}
            icon={{
              name: 'smart_toy_filled',
              size: 2.25,
              fill: qmSystem === 'ai_support' ? '#4599bf' : '#00000099',
            }}
            description='Erstelle mithilfe einer KI die grundlegenden Inhalte, die für dein QM-System nach ISO 9001:2015 notwendig sind.
              (Ca. 30 Minuten)'
          />
        </Box>
      </Box>
      <Box component='div' className={classes.ipsButtonsBox}>
        <Button
          variant='text'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onBack}
        >
          ZURÜCK
        </Button>
        <Button
          variant='contained'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onNext}
          disabled={!qmSystem}
        >
          STARTEN
        </Button>
      </Box>
    </Box>
  );
}
