import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import initialState from './states';

export const pageFiltersSlice = createSlice({
  name: 'persistedPageFilters',
  initialState,
  reducers: {
    resetFilters(state, action: PayloadAction<{ key: string }>) {
      const { key } = action.payload;
      const nullValues = Object.keys(state[key]).reduce(
        (accumulator, current) => {
          accumulator[current] = null;
          return accumulator;
        },
        {}
      );
      return {
        ...state,
        [key]: nullValues,
      };
    },

    setSingleFilter(
      state,
      action: PayloadAction<{ key: string; subKey: string; value: string }>
    ) {
      const { key, subKey, value } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          [subKey]: value,
        },
      };
    },

    setMultipleFilters(
      state,
      action: PayloadAction<{
        key: string;
        subKey: string;
        values: Array<string>;
      }>
    ) {
      const { key, subKey, values } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          [subKey]: values,
        },
      };
    },
  },
});

export const { setSingleFilter, resetFilters, setMultipleFilters } =
  pageFiltersSlice.actions;
