import { Box, Button, Typography } from '@mui/material';
import { FabButton } from 'components/button';
import { FiltersBox, SortFilterBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { FilterDropdown } from 'components/filter-dropdown';
import { Icon } from 'components/icon';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { ModalCreateNewDocument } from 'modals/create-new-document';
import {
  resetFilters,
  setMultipleFilters,
} from 'store/common/page-filters/page-filters-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  fetchDocuments,
  handleModal,
} from 'store/qsistant/document-overview/document-overview-slice';
import type { DocumentOverviewTypes } from 'store/qsistant/document-overview/types';
import {
  querySearchParam,
  sortByCriteria,
  tableMultipleFiltersChange,
} from 'utils/utils';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { sortByOptions } from './document-overview.constants';
import useTableCells from './table-cells.hook';

export function DocumentOverviewPage({ pathName, parentTab, homePage }) {
  const { t } = useTranslation(['sort', 'status', 'document']);
  const dispatch = useAppDispatch();
  const urlLocation = useLocation();

  const { cells } = useTableCells();

  const {
    documents,
    modal,
    documentTypes,
    processList,
    listOfUsers,
    listOfOrganizations,
    statusList,
    elTypesList,
    status,
  } = useAppSelector(({ documentOverviewReducer }) => ({
    documents: documentOverviewReducer.documents,
    error: documentOverviewReducer.error,
    status: documentOverviewReducer.status,
    modal: documentOverviewReducer.modal,
    documentTypes: documentOverviewReducer.documentTypes,
    processList: documentOverviewReducer.processList,
    listOfUsers: documentOverviewReducer.listOfUsers,
    listOfOrganizations: documentOverviewReducer.listOfOrganizations,
    statusList: documentOverviewReducer.statusList,
    elTypesList: documentOverviewReducer.elTypesList,
  }));

  const {
    persistedStatus,
    persistedElType,
    persistedDocType,
    persistedProcess,
    persistedPerson,
    persistedLocation,
    persistedOrganization,
  } = useAppSelector(({ pageFiltersSlice }) => ({
    persistedStatus: pageFiltersSlice.documentOverviewPage?.persistedStatus,
    persistedElType: pageFiltersSlice.documentOverviewPage?.persistedElType,
    persistedDocType: pageFiltersSlice.documentOverviewPage?.persistedDocType,
    persistedProcess: pageFiltersSlice.documentOverviewPage?.persistedProcess,
    persistedPerson: pageFiltersSlice.documentOverviewPage?.persistedPerson,
    persistedOrganization:
      pageFiltersSlice.documentOverviewPage?.persistedOrganization,
    persistedLocation: pageFiltersSlice.documentOverviewPage?.persistedLocation,
  }));

  const [filtered, setFiltered] = useState<Array<DocumentOverviewTypes>>(null);

  const [sortBy, setSortBy] = useState<(typeof sortByOptions)[number]['value']>(
    sortByOptions[0].value
  );

  const [filters, setFilters] = useState({
    status: persistedStatus || [''],
    elType: persistedElType || [''],
    docType: persistedDocType || [''],
    person: persistedPerson || [''],
    organization: persistedOrganization || [''],
    location: persistedLocation || [''],
    process: urlLocation.search
      ? ['', querySearchParam(urlLocation)]
      : persistedProcess || [''],
  });

  const handleResetFilters = () => {
    dispatch(resetFilters({ key: 'documentOverviewPage' }));
    setFilters({
      docType: [''],
      elType: [''],
      location: [''],
      organization: [''],
      person: [''],
      process: [''],
      status: [''],
    });
  };

  const filterByOptions = (
    arrayToFilter: DocumentOverviewTypes[],
    filter1: string[],
    filter2: string[],
    filter3: string[],
    filter4: string[],
    filter5: string[],
    filter6: string[],
    filter7: string[]
  ) =>
    arrayToFilter.filter((item) => {
      if (filter1.length > 1 && !filter1.includes(item.status)) {
        return false;
      }
      if (filter2.length > 1 && !filter2.includes(item.elementType)) {
        return false;
      }
      if (filter3.length > 1 && !filter3.includes(item.documentType.name)) {
        return false;
      }
      if (filter4.length > 1 && !filter4.includes(item.process?.name)) {
        return false;
      }
      if (filter5.length > 1 && !filter5.includes(item.creator.fullName)) {
        return false;
      }
      if (
        filter6.length > 1 &&
        filter6.includes(item.creator.organization.name)
      ) {
        return false;
      }
      // if (filter7.length > 1 && !filter7.includes(item.anotherField)) {
      //   return false;
      // }
      return true;
    });

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const filterRes = filterByOptions(
      documents,
      filters.status,
      filters.elType,
      filters.docType,
      filters.process,
      filters.person,
      filters.organization,
      filters.location
    );
    const sorted = sortByCriteria(filterRes, sortBy);
    setFiltered(sorted);
  }, [
    sortBy,
    documents,
    filters.status,
    filters.elType,
    filters.docType,
    filters.process,
    filters.person,
    filters.organization,
    filters.location,
  ]);

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      {status === 'pending' || filtered === null || status === null ? (
        <Box component='div' mt={2}>
          {cells.map((cell) => (
            <TableSkeleton key={cell.id} />
          ))}
        </Box>
      ) : documents.length > 0 ? (
        <>
          <Typography component='p' className='PageInfo'>
            {t('document:availabledocuments', {
              count: filtered && filtered.length,
            })}
          </Typography>
          <SortFilterBox>
            <FiltersBox>
              <FilterDropdown
                multiple
                value={filters.status}
                isFilterApplied={filters.status.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'documentOverviewPage',
                      subKey: 'persistedStatus',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'status', setFilters);
                }}
                options={statusList.map((item) => ({
                  ...item,
                  label: t(`status:${item.label}`),
                }))}
                filterLabel={t(`sort:status`)}
              />
              <FilterDropdown
                multiple
                value={filters.elType}
                isFilterApplied={filters.elType.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'documentOverviewPage',
                      subKey: 'persistedElType',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'elType', setFilters);
                }}
                options={elTypesList.map((item) => ({
                  ...item,
                  label: t(`document:${item.label}`),
                }))}
                filterLabel={t(`sort:itemtype`)}
              />
              <FilterDropdown
                multiple
                value={filters.docType}
                isFilterApplied={filters.docType.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'documentOverviewPage',
                      subKey: 'persistedDocType',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'docType', setFilters);
                }}
                options={documentTypes}
                filterLabel={t(`sort:documenttype`)}
              />
              <FilterDropdown
                multiple
                value={filters.process}
                isFilterApplied={filters.process.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'documentOverviewPage',
                      subKey: 'persistedProcess',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'process', setFilters);
                }}
                options={processList}
                filterLabel={t(`sort:process`)}
              />
              <FilterDropdown
                multiple
                value={filters.person}
                isFilterApplied={filters.person.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'documentOverviewPage',
                      subKey: 'persistedPerson',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'person', setFilters);
                }}
                options={listOfUsers}
                filterLabel={t(`sort:person`)}
              />
              <FilterDropdown
                multiple
                value={filters.organization}
                isFilterApplied={filters.organization.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'documentOverviewPage',
                      subKey: 'persistedOrganization',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'organization', setFilters);
                }}
                options={listOfOrganizations}
                filterLabel={t(`sort:customerorsupplier`)}
              />
              <FilterDropdown
                multiple
                disabled
                value={filters.location}
                isFilterApplied={filters.location.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'documentOverviewPage',
                      subKey: 'persistedLocation',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'location', setFilters);
                }}
                options={[]}
                filterLabel={t(`sort:locationofpurpose`)}
              />
              <Button
                variant='text'
                color='primary'
                onClick={handleResetFilters}
                disabled={[
                  filters.docType,
                  filters.elType,
                  filters.location,
                  filters.organization,
                  filters.person,
                  filters.process,
                  filters.status,
                ].every((filter) => filter.length === 1)}
              >
                {t('sort:resetfilter')}
              </Button>
            </FiltersBox>
            <SortSelect
              options={sortByOptions}
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              noOneChosen={sortBy === sortByOptions[0].value}
            />
          </SortFilterBox>
          {filtered && filtered.length > 0 ? (
            <>
              <Table cells={cells} data={filtered} />
              <Box component='div' display='flex' justifyContent='center'>
                <FabButton
                  className='AbsolutePosition primary'
                  onClick={() => dispatch(handleModal(true))}
                  label={t('document:newdocument')}
                />
              </Box>
            </>
          ) : (
            <EmptyList
              image={{ name: ICON.NO_FILTERS_DOCUMENTS }}
              title='Wir konnten keine Dokumente dazu finden.'
              primaryButton={
                <Button
                  variant='outlined'
                  color='qsistant'
                  onClick={handleResetFilters}
                >
                  {t('document:resetfilter')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <EmptyList
          image={{ name: ICON.NO_DOCUMENTS }}
          title='Du hast noch keine Dokumente erstellt oder hochgeladen.'
          description='Du hast noch keine Dokumente. Füge mit Hilfe des Buttons “Dokument hinzufügen” dein erstes Qsistant-Dokument hinzu.'
          primaryButton={
            <Button
              variant='contained'
              color='qsistant'
              onClick={() => dispatch(handleModal(true))}
              startIcon={<Icon name={ICON.PLUS} fill='white' />}
            >
              {t('document:adddocument')}
            </Button>
          }
        />
      )}
      {modal && (
        <ModalCreateNewDocument
          onClose={() => {
            dispatch(fetchDocuments());
            dispatch(handleModal(false));
          }}
          open={modal}
        />
      )}
    </PageWrapper>
  );
}
