import { Box, Checkbox, Radio, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { Status } from 'components/status';

import React from 'react';

import { CheckCardProps } from './check-card.types';
import {
  checkboxStyle,
  descriptionStyle,
  headerStyle,
  infoStyle,
  radiosStyle,
  wrapper,
} from './style';

export function CheckCardComponent({
  sx,
  onClick,
  icon,
  header,
  value,
  fieldValue,
  description,
  checker = 'radio',
  badge = false,
  onChange,
  color = '#4599bf',
}: CheckCardProps) {
  return (
    <Box
      component='div'
      onClick={onClick}
      defaultValue={value}
      sx={{
        ...wrapper,
        ...sx,
        borderColor: fieldValue === value ? color : '#0000001F',
        background: fieldValue === value ? `${color}24` : '#FFF',
        '&:hover': {
          background: !onClick ? '#fff' : `${color}08`,
        },
        cursor: !onClick ? 'not-allowed' : 'pointer',
      }}
    >
      {icon ? (
        <Icon
          name={icon.name}
          size={icon.size || 1.5}
          fill={icon.fill || '#00000099'}
          style={{ opacity: !onClick ? 0.35 : 1 }}
        />
      ) : null}
      <Typography
        component='h6'
        sx={{ opacity: !onClick ? 0.35 : 1, ...headerStyle }}
      >
        {header}
      </Typography>
      {description ? (
        <Typography
          component='p'
          sx={{ opacity: !onClick ? 0.35 : 1, ...descriptionStyle }}
        >
          {description}
        </Typography>
      ) : null}
      {badge ? (
        <Status
          status='basic'
          statusText='Neu'
          style={{
            position: 'absolute',
            top: '0.4rem',
            left: '0.4rem',
            textTransform: 'capitalize',
            fontSize: '0.75rem',
          }}
        />
      ) : null}
      {checker === 'radio' ? (
        <Radio
          checked={fieldValue === value}
          sx={{ ...radiosStyle }}
          disabled={!onClick}
        />
      ) : checker === 'checkbox' ? (
        <Checkbox
          checked={fieldValue === value}
          disabled={!onClick}
          onChange={onChange}
          value={fieldValue}
          sx={{
            ...checkboxStyle,
            '&.MuiCheckbox-root.Mui-checked': {
              color,
            },
          }}
        />
      ) : null}
      {!onClick ? (
        <Box component='div' sx={infoStyle}>
          coming soon
        </Box>
      ) : null}
    </Box>
  );
}
