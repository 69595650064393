import { Button, CircularProgress } from '@mui/material';

import React from 'react';

import { LoadingButtonProps } from './button.types';

export function LoadingButtonComponent({
  isLoading = false,
  label,
  color,
  onClick,
  loaderSize = 1,
  startIcon,
  endIcon,
  disabled,
  className,
  ...props
}: LoadingButtonProps) {
  return (
    <Button
      variant='contained'
      color={color}
      disabled={disabled}
      className={className}
      sx={{
        color: isLoading ? 'transparent !important' : '#fff !important',
        '& .MuiButton-startIcon': {
          visibility: isLoading ? 'hidden' : 'visible',
        },
      }}
      onClick={onClick}
      id='loading_btn'
      type='button'
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
    >
      {isLoading ? (
        <CircularProgress
          size={`${loaderSize}rem`}
          sx={{ color: 'white', mr: 0, position: 'absolute' }}
        />
      ) : null}
      {label}
    </Button>
  );
}
