import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'wrapper'
  | 'privilegeBox'
  | 'btnStyle'
  | 'packageLabel'
  | 'title'
  | 'privelegesBox'
  | 'subTitle'
  | 'context';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, colors }) => {
    return {
      wrapper: {
        background: colors.gray.background,
        borderRadius: typography.pxToRem(size.font_4),
        padding: typography.pxToRem(size.font24L),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: typography.pxToRem(size.font24L),
      },
      privilegeBox: {
        display: 'flex',
        gap: typography.pxToRem(size.font_8),
        alignItems: 'center',
        paddingBottom: typography.pxToRem(size.font_8),
      },
      privelegesBox: {
        width: '100%',
        background: '#4599BF14',
        borderRadius: typography.pxToRem(size.font_4 / 2),
        padding: typography.pxToRem(size.font_8),
      },
      btnStyle: {
        borderRadius: `${typography.pxToRem(size.font_4 / 2)} !important`,
        letterSpacing: 0,
      },
      packageLabel: {
        color: '#132E39CC',
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        letterSpacing: `${typography.pxToRem(0.24)} !important`,
      },
      title: {
        textAlign: 'center',
        letterSpacing: `${typography.pxToRem(0.128)} !important`,
        '& > span': {
          textTransform: 'capitalize',
          '&.basic': {
            color: '#4599BF',
            background: '#4599BF14',
          },
          '&.free': {
            color: '#000000CC',
            background: '#00000014',
          },
          '&.regulatory': {
            color: '#401226',
            background: '#40122614',
          },
        },
      },
      subTitle: {
        textAlign: 'left',
        color: colors.gray.icon,
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        letterSpacing: `${typography.pxToRem(0.24)} !important`,
      },
      context: {
        width: typography.pxToRem(352),
        padding: typography.pxToRem(size.font24L),
        display: 'flex',
        flexDirection: 'column',
        gap: typography.pxToRem(size.font_8),
      },
    };
  }
);
