import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';
import { ModalProcessCategoryAction } from 'modals/process-category-action';

import React, { useRef, useState } from 'react';

import type { ProcessCategorieCardProps } from './process-categorie.card.types';
import { absIcon, useStyles } from './styles';

export function ProcessCategorieCard({
  id,
  name,
  description,
  checked,
  disabled = false,
  onChange,
  isSelected = false,
  isCreated,
  onRemove,
  onAction,
}: ProcessCategorieCardProps) {
  const iconRef = useRef();
  const classes = useStyles();
  const [isMenu, setIsMenu] = useState(false);
  const [editing, setEditing] = useState(false);
  const [titleState, setTitleState] = useState(name);
  const [descriptionState, setDescriptionState] = useState(description);

  const contextContent = [
    {
      id: 12,
      name: 'delete',
      label: 'Entfernen',
      show: true,
      onClick: onRemove,
    },
    {
      id: 13,
      name: 'edit',
      label: 'Bearbeiten',
      show: true,
      onClick: () => setEditing(true),
    },
  ];

  return (
    <Box
      component='div'
      className={classes.cardWrapper}
      sx={{
        opacity: disabled ? 0.54 : 1,
        borderColor: isSelected ? '#4599bf' : '#0000001F',
      }}
    >
      <Box component='div'>
        <Typography component='h6' className={classes.cardTitle}>
          {name}
        </Typography>
        <Typography component='p' className={classes.cardDescription}>
          {description}
        </Typography>
      </Box>
      <Box component='div'>
        <Checkbox
          checked={checked}
          sx={{ padding: 0 }}
          color='qsistant'
          disabled={disabled}
          onChange={() => onChange(id)}
        />
      </Box>
      {isCreated && isSelected ? (
        <IconButton
          sx={absIcon}
          ref={iconRef}
          onClick={() => setIsMenu((prev) => !prev)}
        >
          <Icon name='more_horiz' />
        </IconButton>
      ) : null}
      {isMenu ? (
        <ContextMenu
          open={isMenu}
          anchorEl={iconRef}
          onClose={() => setIsMenu(false)}
          dataList={contextContent}
        />
      ) : null}
      {editing ? (
        <ModalProcessCategoryAction
          header='Prozesskategorie bearbeiten'
          color='qsistant'
          icon={{ name: 'label' }}
          open={editing}
          name={titleState}
          onChangeTitle={(e) => setTitleState(e.target.value)}
          description={descriptionState}
          onChangeDescription={(e) => setDescriptionState(e.target.value)}
          onClose={() => setEditing(false)}
          onAction={() => {
            onAction(titleState, descriptionState, id);
            setEditing(false);
          }}
        />
      ) : null}
    </Box>
  );
}
