import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createNewDepartment,
  deleteDepartment,
  departmentEditing,
  getDepartmentsList,
} from 'api/services/departments.services';

import { asyncThunkFetchDepartments, initialState } from './state';
import type { DepartmentTypes, NewDepartmentTypes } from './types';

export const fetchDepartments = createAsyncThunk(
  'departments/fetchDepartments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getDepartmentsList();
      await Promise.all([
        dispatch(updateDepartments(response.data)),
        dispatch(setDepartmentsList(response.data)),
      ]);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const addDepartment = createAsyncThunk(
  'departments/addDepartment',
  async (data: NewDepartmentTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await createNewDepartment(data);
      dispatch(updateDepartments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const removeDepartment = createAsyncThunk(
  'departments/removeDepartment',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteDepartment(id);
      dispatch(handleRemoveDepartment(id));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const editDepartment = createAsyncThunk(
  'departments/editDepartment',
  async (department: NewDepartmentTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await departmentEditing(department.id, department);
      dispatch(updateDepartments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const departmentsSlice = createSlice({
  name: 'departmentsSlice',
  initialState,
  reducers: {
    updateDepartments(state, action: PayloadAction<DepartmentTypes[]>) {
      return {
        ...state,
        departments: action.payload,
      };
    },

    handleRemoveDepartment(state, action: PayloadAction<number>) {
      const filtered = state.departments.filter((d) => d.id !== action.payload);
      return {
        ...state,
        departments: filtered,
      };
    },

    handleModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    setDepartmentsList(state, action: PayloadAction<DepartmentTypes[]>) {
      const uniqueValues = [
        ...new Set(action.payload.map((item) => item.name)),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        departmentsList: uniqueValues,
      };
    },
  },
  extraReducers: (builder) => {
    asyncThunkFetchDepartments(builder, fetchDepartments);
  },
});

export const {
  updateDepartments,
  handleRemoveDepartment,
  handleModal,
  setDepartmentsList,
} = departmentsSlice.actions;

export default departmentsSlice.reducer;
