import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1.5rem',
    },
    title: {
      color: '#00000099',
      fontSize: `${typography.pxToRem(34)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0 !important',
    },
    description: {
      color: '#00000099',
      fontSize: `${typography.pxToRem(16)} !important`,
      fontWeight: `${typography.fontWeightRegular} !important`,
      letterSpacing: '0.008rem !important',
    },
    subDescription: {
      color: '#000000DE',
      fontSize: `${typography.pxToRem(16)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.008rem !important',
    },
    drawerText: {
      color: '#00000099',
      fontSize: `${typography.pxToRem(14)} !important`,
      fontWeight: `${typography.fontWeightRegular} !important`,
      letterSpacing: '0.008rem !important',
    },
    topDrawerDescBox: {
      backgroundColor: '#F5F5F5',
      borderRadius: '0.5rem',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      marginBottom: '1.5rem',
    },
    progressCardsBox: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '1.5rem',
    },
    numberCompletedDocs: {
      color: '#000000DE',
      fontSize: `${typography.pxToRem(14)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.07rem !important',
      textTransform: 'uppercase !important',
    } as CSSProperties & { textTransform: 'uppercase !important' },
  };
});
