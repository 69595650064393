import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'processCard'
  | 'modalStyle'
  | 'modalHeaderStyle'
  | 'dividerStyle'
  | 'processesWrapper'
  | 'subProcessBox'
  | 'typeStyle'
  | 'processStyle'
  | 'processSubStyle'
  | 'emptyVersionLabel';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, colors, shadows, palette }) => ({
    processCard: {
      boxShadow: shadows[3],
      borderRadius: '0.25rem',
      backgroundColor: palette.primary.main,
      width: `${typography.pxToRem(352)}`,
      height: '2.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      fontSize: '1rem',
      fontWeight: typography.fontWeightMedium,
    },
    modalStyle: {
      maxHeight: '80%',
      minWidth: '65%',
      maxWidth: '65%',
    },
    modalHeaderStyle: {
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      height: `${typography.pxToRem(88)}`,
      background: colors.white,
      zIndex: 1,
    },
    dividerStyle: {
      position: 'sticky',
      top: `${typography.pxToRem(88)}`,
    },
    processesWrapper: {
      padding: typography.pxToRem(size.font24L),
      gap: typography.pxToRem(72),
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      '@media(max-width:1000px)': {
        flexDirection: 'column',
      },
    },
    subProcessBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      marginBottom: '0.25rem',
      borderRadius: '0.125rem',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
    typeStyle: {
      color: colors.gray.icon,
      fontSize: '0.75rem',
      fontWeight: typography.fontWeightRegular,
      letterSpacing: '0.025rem',
      lineHeight: '1rem',
      borderRadius: '0.25rem',
      padding: '0.1rem 0.25rem',
      background: 'rgba(0, 0, 0, 0.12)',
    },
    processStyle: {
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: `${typography.pxToRem(0.1)} !important`,
      color: colors.text.primary,
      margin: '0.5rem 0 0.25rem 2rem !important',
    },
    processSubStyle: {
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: `${typography.pxToRem(0.25)} !important`,
      color: colors.gray.icon,
    },
    emptyVersionLabel: {
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      color: colors.gray.icon,
    },
  })
);
