import { Box, Button, IconButton, Radio, Typography } from '@mui/material';

import React from 'react';

import { Icon } from '../icon';
import { contentBox } from './theme';

function TFAuthentication({ onChangeStage, value, onChange }) {
  return (
    <Box component='div' sx={{ ...contentBox, width: '38rem' }}>
      <Box
        component='div'
        width='100%'
        display='flex'
        justifyContent='flex-end'
      >
        <IconButton onClick={() => onChangeStage('login')}>
          <Icon name='close' size={2.5} />
        </IconButton>
      </Box>
      <Icon name='new_logo' height={4.75} width={22.5} />
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        textAlign='center'
        alignItems='center'
        gap='1.5rem'
        width='100%'
        marginBottom='2.5rem'
      >
        <Typography className='StageLabel' component='h4'>
          Zwei-Faktor-Authentifizierung
        </Typography>
        <Typography className='StageDescription' component='h6'>
          Der Admin hat die Zwei-Faktor-Authentifizierung aktiviert damit dein
          Profil geschützt ist. Wähle eine Authentifizierungsmethode:
        </Typography>
        <Box
          component='div'
          width='100%'
          display='flex'
          flexDirection='column'
          gap='1rem'
        >
          <Box
            display='flex'
            component='div'
            sx={{ cursor: 'pointer' }}
            onClick={() => onChange('app')}
          >
            <Radio checked={value === 'app'} onChange={() => onChange('app')} />
            <Box component='div' textAlign='start'>
              <Typography component='h6' className='VerifyWayLabel'>
                Authentifikations App
              </Typography>
              <Typography component='span' className='VerifyWayDescription'>
                Erhalte den PIN von einer App wie XY oder AB
              </Typography>
            </Box>
          </Box>
          <Box
            display='flex'
            component='div'
            sx={{ cursor: 'pointer' }}
            onClick={() => onChange('e-mail')}
          >
            <Radio
              checked={value === 'e-mail'}
              onChange={() => onChange('e-mail')}
            />
            <Box component='div' textAlign='start'>
              <Typography component='h6' className='VerifyWayLabel'>
                E-Mail
              </Typography>
              <Typography component='span' className='VerifyWayDescription'>
                Erhalte den PIN per E-Mail
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component='div'>
        <Button
          variant='contained'
          type='button'
          className='Further'
          disabled={!value}
          onClick={() => onChangeStage('auth_account')}
        >
          WEITER
        </Button>
      </Box>
    </Box>
  );
}

export default TFAuthentication;
