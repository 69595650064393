import { Box, Typography } from '@mui/material';

import React from 'react';

import { StructureStepComponent } from './structure-step.component';
import { useStyles } from './styles';
import type { DocumentStructureCardComponentProps } from './types';

export function DocumentStructureCardComponent({
  activeDocument,
  onClick,
  structureStepIndex,
  selectedOption,
}: DocumentStructureCardComponentProps) {
  const classes = useStyles();

  return (
    <Box component='div' className={classes.structureCardBox}>
      <Typography component='h4' className={classes.structureDocumentLabel}>
        {`Dokument ${activeDocument.id}: ${activeDocument.label}`}
      </Typography>
      {activeDocument.structureSteps.length > 0 &&
        activeDocument.structureSteps.map((step, index) => (
          <StructureStepComponent
            key={step.id}
            index={index}
            onClick={onClick}
            structureStepIndex={structureStepIndex}
            selectedOption={selectedOption}
            {...step}
          />
        ))}
    </Box>
  );
}
