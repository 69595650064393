import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    badgeWrapper: {
      background: '#D9D9D9',
      borderRadius: '0.25rem',
      boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.16)',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '0.5rem',
    },
    labelStyles: {
      color: '#626262',
      fontSize: `${typography.pxToRem(14)} !important`,
      fontWeight: `${typography.fontWeightRegular} !important`,
      lineHeight: `${typography.pxToRem(16)} !important`,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      maxWidth: '20ch',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    iconText: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(16)} !important`,
      fontWeight: `${typography.fontWeightRegular} !important`,
      letterSpacing: '0.006rem !important',
    },
    popperWrapper: {
      boxShadow:
        '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
      background: '#fff',
      borderRadius: '0.25rem',
    },
  };
});
