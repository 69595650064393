import {
  Box,
  Button,
  IconButton,
  List,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { UploadedFileCard } from 'cards/uploaded-file-card';
import { LoadingButton } from 'components/button';
import { DragDrop } from 'components/drag-drop';
import { Icon } from 'components/icon';
import { NewDocumentForm } from 'forms/create-new-document';
import { getDisplayedFiles } from 'utils/utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../../api/api';
import { useStyles } from '../styles';
import type { DocumentFileTypes, ResponseFileData } from '../types';

type CustomDocumentProps = {
  onSuccess: () => void;
};

export function CustomDocument({ onSuccess }: CustomDocumentProps) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'document']);
  const screenBreakpoint = useMediaQuery('(min-width:1920px)');
  const step = 2;

  const [uploadedFiles, setUploadedFiles] = useState<DocumentFileTypes[]>([]);
  const [currentFilePosition, setCurrentFilePosition] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const handleUploadFiles = (files: FileList) => {
    if (files instanceof FileList) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i += 1) {
        const formData = new FormData();
        formData.append('file', files[i]);
        api
          .post('/files/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            setUploadedFiles((prev) => [...prev, createNewFile(response.data)]);
          });
      }
    }
  };

  const createNewFile = (data: ResponseFileData): DocumentFileTypes => {
    return {
      ...data,
      process: '',
      documentType: '',
      revisionNumber: 0,
      progress: 0,
    };
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    property: string
  ) => {
    const { uuid } = uploadedFiles[currentFilePosition];
    const { value } = e.target;
    const updated = uploadedFiles.map((file) => {
      if (file.uuid === uuid) {
        return {
          ...file,
          [property]: property === 'revisionNumber' ? Number(value) : value,
        };
      }
      return file;
    });
    setUploadedFiles(updated);
  };

  const clickBottom = () => {
    if (
      currentFilePosition % 2 !== 0 &&
      currentFilePosition + 1 < uploadedFiles.length
    ) {
      setOffset(offset + step);
    }
    setCurrentFilePosition(currentFilePosition + 1);
  };

  const clickUp = () => {
    if (
      currentFilePosition % 2 === 0 &&
      currentFilePosition + 1 <= uploadedFiles.length
    ) {
      setOffset(offset - step);
    }
    setCurrentFilePosition(currentFilePosition - 1);
  };

  const handleRemoveFile = (id: string) => {
    setOffset(0);
    setCurrentFilePosition(0);
    const filtered = uploadedFiles.filter((file) => file.uuid !== id);
    setUploadedFiles(filtered);
  };

  const handleLoading = () => {
    // on submit click
    // setLoading(true);
  };

  return (
    <Box component='div' className={classes.contentBox} sx={{ gap: 0 }}>
      <Box
        component='div'
        className={classes.dragDropBox}
        sx={{
          height:
            uploadedFiles.length === 0
              ? screenBreakpoint
                ? '17.4rem'
                : '18.5rem'
              : '4rem',
        }}
      >
        <DragDrop
          uploadFiles={uploadedFiles}
          handleChange={handleUploadFiles}
          fileTypesText='PDF, DOCX, ZIP, JPEG ODER ANDERE'
          dragDropName='Dateien'
          color='#4599BD'
        />
      </Box>
      {uploadedFiles.length > 0 && (
        <Box component='div' className={classes.listWrapper}>
          <IconButton
            onClick={clickUp}
            disabled={currentFilePosition + 1 === 1}
            sx={{ p: '0.1rem' }}
          >
            <Icon
              name='expand_less'
              fill={currentFilePosition + 1 === 1 ? '#0000001F' : '#00000099'}
              size={1.25}
              style={{ cursor: 'pointer' }}
            />
          </IconButton>
          <List className={classes.filesListBox}>
            {uploadedFiles.length > 0 &&
              getDisplayedFiles(uploadedFiles, offset, step).map(
                (file, index) => (
                  <UploadedFileCard
                    key={file.uuid}
                    type='Dokumente'
                    index={index}
                    offset={offset}
                    file={file}
                    currentFilePosition={currentFilePosition}
                    isSelected={currentFilePosition === index + offset}
                    filesLength={uploadedFiles.length}
                    onClick={() => setCurrentFilePosition(index + offset)}
                    onRemove={handleRemoveFile}
                  />
                )
              )}
          </List>
          <IconButton
            onClick={clickBottom}
            disabled={currentFilePosition + 1 === uploadedFiles.length}
            sx={{ p: '0.1rem' }}
          >
            <Icon
              name='expand_more'
              fill={
                currentFilePosition + 1 === uploadedFiles.length
                  ? '#0000001F'
                  : '#00000099'
              }
              size={1.25}
              style={{ cursor: 'pointer' }}
            />
          </IconButton>
          {uploadedFiles.length > 0 && (
            <NewDocumentForm
              key={uploadedFiles[currentFilePosition].uuid}
              className={classes.newDocumentForm}
              title={uploadedFiles[currentFilePosition]?.fileName}
              onTitleChange={(e) => handleInputChange(e, 'fileName')}
              number={uploadedFiles[currentFilePosition]?.revisionNumber}
              onNumberChange={(e) => handleInputChange(e, 'revisionNumber')}
              documentType={uploadedFiles[currentFilePosition]?.documentType}
              onDocumentTypeChange={(e) => handleInputChange(e, 'documentType')}
              process={uploadedFiles[currentFilePosition]?.process}
              onChangeProcess={(e) => handleInputChange(e, 'process')}
            />
          )}
          <Box component='div' className={classes.paginationBox}>
            <Button
              variant='text'
              color='qsistant'
              disabled={currentFilePosition <= 0}
              startIcon={
                <Icon
                  name='chevron_left'
                  fill={currentFilePosition <= 1 ? '#BDBDBD' : '#4599BF'}
                  size={1.5}
                />
              }
              onClick={clickUp}
            >
              {t('common:previous')}
            </Button>
            <Typography component='p' className={classes.counterText}>{`${
              currentFilePosition + 1
            }/${uploadedFiles.length}`}</Typography>
            <Button
              variant='text'
              disabled={currentFilePosition + 1 >= uploadedFiles.length}
              color='qsistant'
              endIcon={
                <Icon
                  name='chevron_right'
                  fill={
                    currentFilePosition + 1 >= uploadedFiles.length
                      ? '#BDBDBD'
                      : '#4599BF'
                  }
                  size={1.5}
                />
              }
              onClick={clickBottom}
            >
              {t('common:next')}
            </Button>
          </Box>
        </Box>
      )}
      <Box component='div' width='100%' mt='2rem'>
        <LoadingButton
          label={t('document:createdocument')}
          onClick={handleLoading}
          variant='contained'
          loaderSize={1.875}
          color='qsistant'
          disabled={uploadedFiles.length === 0}
          className={classes.createButton}
          fullWidth
        />
      </Box>
    </Box>
  );
}
