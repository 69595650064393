import { cardFileData } from 'cards/product-files-card/product-files-card.types';

export const cardsFilesData: cardFileData[] = [
  {
    // titleIcon: 'from_scratch',
    pOne: 'Du möchtest Entwicklungsakten individuell in diesem Produkt anlegen und eventuell später',
    pTwo: 'als Vorlage für weiter Produkte speichern.',
    btnText: 'NEUE ENTWICKLUNGSAKTEN ERSTELLEN',
    btnIco: 'add',
  },
  {
    // titleIcon: 'start_template',
    pOne: 'Du möchtest eine vorhandene Aktenstruktur importieren.',
    pTwo: 'Wähle eine bestehende Ordnerstruktur von deinem PC und lass sie automatisch umwandeln.',
    btnText: 'BESTEHENDE VORLAGE NUTZEN',
    btnIco: 'upload_filled',
  },
];
