import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from 'components/button';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { useStyles } from 'pages/administration/changemanagement/styles';

import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeManagementCardProps } from './types';

export function ChangeManagementCard({
  title,
  description,
  loading,
  switchState,
  button,
  autocomplete,
  documents = false,
}: ChangeManagementCardProps) {
  const { t } = useTranslation(['common']);
  const classes = useStyles();

  return (
    <Box component='div' className={classes.widgetBox}>
      {!loading && documents ? (
        <Box component='div' className={classes.topBox}>
          <Box component='div' className={classes.textBox}>
            <Typography component='h4' className={classes.cardLabel}>
              {title}
            </Typography>
            <Typography component='p' className={classes.cardDescription}>
              {description}
            </Typography>
          </Box>
          <Box component='div' className={classes.textBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={switchState.checked}
                    onChange={switchState.onChange}
                    color='management'
                  />
                }
                label={t('common:usefeature')}
              />
            </FormGroup>
            <Autocomplete
              disablePortal
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={autocomplete.disabled}
              id='change-documents'
              color='management'
              onChange={(event, value) =>
                autocomplete.onChange('change', value)
              }
              value={autocomplete.value}
              options={autocomplete.options}
              getOptionLabel={(option) => {
                // filter value
                return `${option.name} Rev. ${option.revision}/(${option.version})`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color='management'
                  label={autocomplete.label}
                />
              )}
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress color='management' />
      )}
      <Box component='div' display='flex' justifyContent='flex-end'>
        <LoadingButton
          variant='contained'
          isLoading={button.loader}
          loaderSize={1.5}
          color='management'
          label={t('common:savechanges')}
          startIcon={<Icon name={ICON.SAVE} fill='#fff' />}
          disabled={button.disabled}
          onClick={button.onSave}
        />
      </Box>
    </Box>
  );
}
