import { createSlice } from '@reduxjs/toolkit';
import { Product } from 'store/product/types';

const currentProduct = createSlice({
  name: 'currentProduct',
  initialState: {
    productName: '',
    productDesc: '',
    image: null,
    id: '',
    selectedPhaseId: null,
    selectedDatasetId: null,
    phasesSaveStatus: false,
    filesSaveStatus: false,
    phases: [
      {
        id: '23543',
        name: 'Phasenname1',
        status: 'ENTWURF',
        filled: '0',
        released: false,
        datasets: [],
      },
    ],
    files: [],
  } as Product,
  reducers: {
    setProduct: (state, action) => {
      state.productName = action.payload.productName;
      state.productDesc = action.payload.productDesc;
      state.image = action.payload.image;
      state.id = action.payload.id;
    },
    setSelectedPhaseId: (state, action) => {
      state.selectedPhaseId = action.payload;
    },
    setSelectedDatasetId: (state, action) => {
      state.selectedDatasetId = action.payload;
    },
    updateProduct: (state, action) => {
      const { productName, productDesc, image, id } = action.payload;
      if (productName !== undefined) state.productName = productName;
      if (productDesc !== undefined) state.productDesc = productDesc;
      if (image !== undefined) state.image = image;
      if (id !== undefined) state.id = id;
    },
    addPhase: (state, action) => {
      state.phases.push(action.payload);
    },
    updatePhaseField: (state, action) => {
      const { id, field, value, released, status } = action.payload;
      const phaseIndex = state.phases.findIndex((phase) => phase.id === id);
      if (phaseIndex !== -1) {
        state.phases[phaseIndex] = {
          ...state.phases[phaseIndex],
          [field]: value,
          released,
          status,
        };
      }
    },
    removePhase: (state, action) => {
      state.phases = state.phases.filter((p) => p.id !== action.payload);
    },
    updateFilledPhase: (state, action) => {
      const { selectedId, filled, released, status } = action.payload;
      const phaseToUpdate = state.phases.find((p) => p.id === selectedId);
      if (phaseToUpdate) {
        phaseToUpdate.filled = filled;
        phaseToUpdate.released = released;
        phaseToUpdate.status = status;
        if (filled === 100 && phaseToUpdate.released) {
          phaseToUpdate.status = 'IN PRÜFUNG';
        } else if (filled === 100 && !phaseToUpdate.released) {
          phaseToUpdate.status = 'FREIGEGEBEN';
        } else if (filled > 0) {
          phaseToUpdate.status = 'IN BEARBEITUNG';
        } else {
          phaseToUpdate.status = 'ENTWURF';
        }
      }
    },
    setPhaseName: (state, action) => {
      const { id, name } = action.payload;
      const phaseIndex = state.phases.findIndex((phase) => phase.id === id);
      if (phaseIndex !== -1) {
        state.phases[phaseIndex].name = name;
      }
    },
    addDataset: (state, action) => {
      const { dataset } = action.payload;
      const phase = state.phases.find((p) => p.id === dataset.parentId);
      if (phase && phase.datasets) {
        phase.datasets.push(dataset);
      }
    },
    removeDataset: (state, action) => {
      const { datasetId, phaseId } = action.payload;
      const phase = state.phases.find((p) => p.id === phaseId);
      if (phase && phase.datasets) {
        phase.datasets = phase.datasets.filter(
          (d) => d.datasetId !== datasetId
        );
      }
    },
    updateDataset: (state, action) => {
      const { selectedId, selectedDatasetId, updates } = action.payload;
      const phaseIndex = state.phases.findIndex((p) => p.id === selectedId);
      if (phaseIndex !== -1) {
        const datasetIndex = state.phases[phaseIndex].datasets.findIndex(
          (d) => d.id === selectedDatasetId
        );
        if (datasetIndex !== -1) {
          state.phases[phaseIndex].datasets[datasetIndex] = {
            ...state.phases[phaseIndex].datasets[datasetIndex],
            ...updates,
          };
        }
      }
    },
    addDocument: (state, action) => {
      const { documents, datasetId } = action.payload;
      const phase = state.phases.find((p) =>
        p.datasets.find((d) => d.id === datasetId)
      );
      if (phase && phase.datasets) {
        const dataset = phase.datasets.find((d) => d.id === datasetId);
        dataset?.documents.push(...documents);
      }
    },
    savePhasesChanges: (state, action) => {
      state.phasesSaveStatus = action.payload;
    },
    saveFilesChanges: (state, action) => {
      state.filesSaveStatus = action.payload;
    },
  },
});

export const {
  setProduct,
  setSelectedPhaseId,
  setSelectedDatasetId,
  updateProduct,
  addPhase,
  removePhase,
  addDataset,
  savePhasesChanges,
  saveFilesChanges,
  setPhaseName,
  addDocument,
  updateDataset,
  updateFilledPhase,
  updatePhaseField,
} = currentProduct.actions;

export default currentProduct.reducer;
