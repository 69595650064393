import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL?.trim() ?? 'https://dev.qsistant.de',
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token') ?? '';
  if (config.headers) {
    config.headers.Authorization = token;
    config.headers['X-Auth-Token'] = token;
  }
  return config;
});

export default instance;
