import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { API_URL } from 'api/api-urls';
import { Icon } from 'components/icon';
import { AuthContext } from 'components/login/AuthContext';
import LongMenu from 'components/long-menu/LongMenu';
import { deleteProduct } from 'store/product/products-slice';
import { dotDividerForDate } from 'utils/utils';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MyImg } from '../../styles/styles';

export function ProductCard({ ...props }) {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const { state } = React.useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBookmarkClick = (e) => {
    e.stopPropagation();
    setIsBookmarked(!isBookmarked);
  };

  const handleDeleteProduct = (productId) => {
    dispatch(deleteProduct(productId));
  };

  const isImageObject =
    props.image instanceof File || props.image instanceof Blob;

  const isEmpty = (obj) => {
    return Object.entries(obj).length === 0;
  };

  return (
    <Box onClick={() => navigate(`/product_development/product/${props.name}`)}>
      <Card
        sx={{
          borderRadius: '4px',
          width: '23.75rem',
          cursor: 'pointer',
          '&:hover': { scale: '1.015' },
          transition: (theme) =>
            theme.transitions.create('all', {
              duration: theme.transitions.duration.shortest,
              easing: theme.transitions.easing.easeInOut,
            }),
        }}
      >
        <CardMedia
          sx={{
            flex: '0 0 20%',
            position: 'relative',
            minHeight: '170px',
            maxHeight: '171px',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-end',
            flexDirection: 'row-reverse',
          }}
        >
          {!props.image && isEmpty(props.image) ? (
            <Box
              sx={{
                width: '100%',
                height: '171px',
                background: '#0000000A',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon size='1.5rem' name='image_filled' fill='#00000033' />
            </Box>
          ) : (
            <MyImg
              src={
                isImageObject ? URL.createObjectURL(props.image) : props.image
              }
              alt='img'
            />
          )}
          <Tooltip title='Favorit hinzufügen'>
            <IconButton
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '2.2rem',
                top: '0.11rem',
              }}
              onClick={handleBookmarkClick}
            >
              {isBookmarked ? (
                <BookmarkIcon size='medium' />
              ) : (
                <BookmarkBorderIcon size='medium' />
              )}
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '0.05rem',
              top: '0.11rem',
            }}
          >
            <LongMenu
              id={props.id}
              onRenameClick={props.handleRename}
              // onDeleteClick={handleDelete}
              onDeleteClick={handleDeleteProduct}
            />
          </Box>
        </CardMedia>
        <CardContent
          sx={{
            pl: '1.25rem',
            position: 'relative',
          }}
        >
          <Box>
            <Typography className='PSectionTitle'>{props.name}</Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 'normal',
                color: '#00000099',
                letterSpacing: '0',
                display: 'inline-block',
                mr: '0.4rem',
              }}
            >
              Erstellt am {dotDividerForDate(state.user.created)} von
            </Typography>
            <Typography
              sx={{
                color: '#45BD81',
                cursor: 'pointer',
                display: 'inline-block',
              }}
            >
              {state.user?.fullName}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: '1.25rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'flex-end',
              alignItems: 'center',
              flexDirection: 'row-reverse',
            }}
          >
            <Tooltip title={state.user?.fullName}>
              <Avatar
                sx={{
                  backgroundColor: state.user.avatarColor,
                  width: '2.125rem',
                  height: '2.125rem',
                }}
                src={
                  !state.user.avatar
                    ? null
                    : `${process.env.REACT_APP_API_URL}${API_URL.files.download}/${state.user.avatar}?thumb=100`
                }
              >
                {state.user.prename?.charAt(0)}
              </Avatar>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
