import { Button, ButtonProps } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

type NoOneModeProps = {
  onClick: () => void;
  color?: ButtonProps['color'];
  label: string;
};

export function NoOneMode({
  onClick,
  color = 'qsistant',
  label,
}: NoOneModeProps) {
  return (
    <Button
      variant='text'
      color={color}
      onClick={onClick}
      startIcon={<Icon name='cancel_send' fill='#4599bf' size={1.5} />}
    >
      {label}
    </Button>
  );
}
