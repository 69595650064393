import { Box, Breadcrumbs, Button, IconButton } from '@mui/material';
import { Icon } from 'components/icon';
import { CustomSelectComponent } from 'components/select';
import { saveFilesChanges } from 'store/product/current-product-slice';

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveBtn } from '../theme';
import { breadcrumbsLinks, stepsBtn } from './styles';
import { ProductFilesTabNavProps, SelectOption } from './types';

export function ProductFilesTabNav({
  editMode,
  setIsEditMode,
  setSavedFiles,
  handlePrevClick,
  // handleNextClick,
  currentPath,
  disableFilesBtn,
  setDisableFilesBtn,
  handleBreadcrumbItemClick,
}: ProductFilesTabNavProps) {
  const selectProductData: SelectOption[] = useMemo(
    () => [
      {
        id: `${crypto.randomUUID()}`,
        name: 'edit_outlined',
        label: 'Bearbeiten',
        show: true,
        onClick: undefined,
        subLabel: 'Alle Elemente direkt bearbeiten',
        fill: '#7F7120',
      },
      {
        id: `${crypto.randomUUID()}`,
        name: 'visibility18',
        label: 'Vorschau',
        show: true,
        onClick: undefined,
        subLabel: 'Alltägliche Phasenansicht',
        fill: '#00000099',
      },
    ],
    [editMode]
  );
  const dispatch = useDispatch();
  const filesSaveStatus = useSelector(
    (state) => state.currentProductReducer.filesSaveStatus
  );
  // const [disableFilesBtn, setDisableFilesBtn] = useState(true);
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    if (selectedOption === 'Bearbeiten') {
      setIsEditMode(true);
      setSavedFiles(false);
    } else if (selectedOption === 'Vorschau') {
      setIsEditMode(false);
    }
  };

  const handleSaveFiles = () => {
    dispatch(saveFilesChanges(true));
    setDisableFilesBtn(true);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <IconButton
            type='button'
            disableRipple
            disabled={currentPath.length === 1}
            onClick={handlePrevClick}
            sx={{ ...stepsBtn }}
          >
            <Icon
              name='arrow_back_ios'
              size={0.5625}
              fill={currentPath.length === 1 ? '#cbcbcb' : '#000000DE'}
            />
          </IconButton>
          <IconButton
            type='button'
            disableRipple
            disabled
            sx={{ ...stepsBtn, ml: '0.4375rem' }}
          >
            <Icon name='arrow_forward_ios' size={0.5625} fill='#cbcbcb' />
          </IconButton>
        </Box>
        <Box>
          {editMode && (
            <Button
              type='submit'
              onClick={handleSaveFiles}
              startIcon={
                <Icon
                  name={filesSaveStatus ? 'done' : 'save_filled'}
                  fill='#fff'
                  size={1.3}
                />
              }
              color='product'
              disabled={disableFilesBtn}
              sx={{
                ...saveBtn,
                p: '5.75px 16px 5.75px  11px',
                mr: '0.75rem',
                width: 'max-content',
              }}
              variant='contained'
            >
              ÄNDERUNGEN SPEICHERN
            </Button>
          )}
          <CustomSelectComponent
            sx={{
              '& .MuiInputLabel-root': {
                color: 'green',
                borderRadius: '0.25rem',
              },
              '& .MuiFilledInput-input': {
                background: editMode ? '#E2D16B80' : '#45BD8133',
                padding: '7px 0.375rem 5px 0.375rem',
                borderRadius: '0.25rem',
                height: '24px',
              },
              '& .MuiFilledInput-input:focus': {
                background: editMode ? '#E2D16B80' : '#45BD8133',
              },
            }}
            color='product'
            variant='filled'
            listData={selectProductData}
            edit={+editMode}
            // saved={savedFiles}
            defaultValue={selectProductData[0].label}
            onChange={handleSelectChange}
          />
        </Box>
      </Box>
      <Breadcrumbs aria-label='breadcrumb'>
        {currentPath.map((pathItem, i) => (
          <Box
            key={pathItem.id}
            onClick={() => handleBreadcrumbItemClick(i)}
            sx={{
              ...breadcrumbsLinks,
              fontWeight: i === currentPath.length - 1 ? '500' : '400',
              color: i === currentPath.length - 1 ? '#000000DE' : '#00000099',
            }}
          >
            {pathItem.name}
          </Box>
        ))}
        {currentPath.length === 1 && <Box>...</Box>}
      </Breadcrumbs>
    </Box>
  );
}
