import { Box, LinearProgress } from '@mui/material';

import React from 'react';

import type { StepProgressProps } from './progress.types';

export function StepProgressComponent({
  progress,
  className,
  color,
  sx,
}: StepProgressProps) {
  return (
    <Box component='div' className={className} sx={{ ...sx }}>
      <LinearProgress variant='determinate' value={progress} color={color} />
    </Box>
  );
}
