import { Typography } from '@mui/material';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { TableIconTooltipProps } from './types';

export function TableIconTooltip({
  open,
  onClose,
  anchorEl,
  title,
}: TableIconTooltipProps) {
  const classes = useStyles();

  return (
    <ContextMenuWrapper
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className={classes.tableIconModal}
      placement='top'
    >
      <Typography component='h6' className={classes.TITitle}>
        {title}
      </Typography>
    </ContextMenuWrapper>
  );
}
