import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { RadioGroup } from 'components/radio-group';
import { Rating } from 'components/rating';
import { ICON } from 'enum/icons.enum';
import { sendFeedback } from 'store/common/feedback/feedback-slice';
import { useAppDispatch } from 'store/hook';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FeedbackProps } from './feedback.types';
import { useStyles } from './styles';

export function FeedbackModal({
  open,
  onClose,
  feedbackOptions,
}: FeedbackProps) {
  const classes = useStyles();
  const dispatcher = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('feedback');

  const [rating, setRating] = useState<number>(3);
  const [feedbackType, setFeedbackType] = useState<'feedback' | 'bug'>(
    'feedback'
  );
  const [description, setDescription] = useState<string>('');
  const [images, setImages] = useState<File[]>([]);

  const handleFeedbackInfo = () => {
    dispatcher(
      sendFeedback({ rating, feedback: feedbackType, description, images })
    );
    setRating(3);
    setFeedbackType('feedback');
    setDescription('');
    setImages([]);
  };

  const handleRating = (e: React.SyntheticEvent, newValue: number) => {
    setRating(newValue);
  };

  const handleImages = (newFiles: FileList | null) => {
    if (!newFiles) {
      return;
    }
    const filesArray = Array.from(newFiles);
    const existingNames = images.map((image) => image.name);
    const filteredFiles = filesArray.filter(
      (file) => !existingNames.includes(file.name)
    );
    setImages([...images, ...filteredFiles]);
  };

  const handleRemoveImg = (imgName: string) => {
    const filtered = images.filter((img) => img.name !== imgName);
    setImages(filtered);
  };

  return (
    <ModalWrapper open={open} onClose={onClose} className={classes.modalStyle}>
      <ModalHeader
        header='Fehler melden & Feedback geben'
        onClose={onClose}
        icon={{ name: ICON.FEEDBACK }}
      />
      <Divider />
      <Box component='div' className={classes.contentBox}>
        <RadioGroup
          title={t('feedback:message')}
          options={feedbackOptions}
          value={feedbackType}
          onChange={(e) =>
            setFeedbackType(e.target.value as 'bug' | 'feedback')
          }
        />
        <Rating
          title={t('feedback:enjoyqsistant')}
          max={5}
          value={rating}
          onChange={handleRating}
        />
        <Box component='div' className={classes.textfieldBox}>
          <Typography component='h4' className={classes.captionStyle}>
            {t('feedback:feddbackinfo')}
          </Typography>
          <TextField
            multiline
            fullWidth
            color='qsistant'
            rows={4}
            placeholder={t('feedback:messageplaceholder')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box component='div' className={classes.imageUploadBox}>
            <IconButton
              disableRipple
              onClick={() => inputRef?.current?.click()}
            >
              <Icon name='add_photo' size={1.5} fill='#00000099' />
            </IconButton>
            {images.length > 0 &&
              images.map((image) => (
                <Box key={image.name} sx={{ position: 'relative' }}>
                  <img
                    alt=''
                    width='50px'
                    height='30px'
                    src={
                      image
                        ? URL.createObjectURL(image)
                        : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
                    }
                  />
                  <IconButton
                    sx={{ position: 'absolute', padding: 0, top: 0, right: 0 }}
                    onClick={() => handleRemoveImg(image.name)}
                  >
                    <Icon name='close_filled' size={1} fill='#4599BF' />
                  </IconButton>
                </Box>
              ))}
          </Box>
          <input
            type='file'
            id='myFile'
            multiple
            hidden
            ref={inputRef}
            accept='image/png, image/jpeg, image/jpg'
            onChange={(e) => handleImages(e.target.files)}
          />
        </Box>
        <Box component='div' className={classes.buttonsBox}>
          <Button variant='text' color='qsistant' onClick={onClose}>
            {t('feedback:abort')}
          </Button>
          <Button
            variant='contained'
            onClick={handleFeedbackInfo}
            color='qsistant'
            disabled={!description}
          >
            {t('feedback:send')}
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
}
