import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes = 'modal' | 'closeBox' | 'label' | 'cardsBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size }) => {
    return {
      modal: {
        width: typography.pxToRem(800),
      },
      closeBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: typography.pxToRem(size.fontL),
        paddingBottom: 0,
      },
      label: {
        fontSize: `${typography.pxToRem(size.font24L + 4)} !important`,
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
      cardsBox: {
        paddingTop: typography.pxToRem(size.fontL * 2),
        paddingLeft: typography.pxToRem(size.fontL * 2),
        position: 'relative',
        height: typography.pxToRem(480),
      },
    };
  }
);
