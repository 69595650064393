import { ClickAwayListener, Popper } from '@mui/material';

import React from 'react';

import clsx from 'clsx';

import { popperStyle } from './styles';
import { ContextMenuWrapperProps } from './types';

export function ContextMenuWrapper({
  sx,
  onClose,
  open,
  anchorEl,
  children,
  placement,
  className,
  offset = false,
}: ContextMenuWrapperProps) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        placement={placement}
        open={open}
        anchorEl={anchorEl.current}
        className={clsx(className)}
        sx={{ ...popperStyle, ...sx }}
        modifiers={[
          {
            name: 'offset',
            enabled: offset,
            options: {
              offset: [0, 10],
            },
          },
        ]}
      >
        {children}
      </Popper>
    </ClickAwayListener>
  );
}
