import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

// de
import actions from './de-DE/actions.json';
import activity from './de-DE/activity.json';
import approval from './de-DE/approval.json';
import changemanagement from './de-DE/changemanagement.json';
import common from './de-DE/common.json';
import customer from './de-DE/customer.json';
import dataset from './de-DE/dataset.json';
import department from './de-DE/department.json';
import development from './de-DE/development.json';
import documents from './de-DE/document.json';
import documenttype from './de-DE/documenttype.json';
import feedback from './de-DE/feedback.json';
import file from './de-DE/file.json';
import fillout from './de-DE/fillout.json';
import home from './de-DE/home.json';
import location from './de-DE/location.json';
import login from './de-DE/login.json';
import management from './de-DE/management.json';
import modal from './de-DE/modal.json';
import onboarding from './de-DE/onboarding.json';
import organization from './de-DE/organization.json';
import phase from './de-DE/phase.json';
import processes from './de-DE/processes.json';
import product from './de-DE/product.json';
import profile from './de-DE/profile.json';
import release from './de-DE/release.json';
import sidebar from './de-DE/sidebar.json';
import snackbar from './de-DE/snackbar.json';
import sort from './de-DE/sort.json';
import status from './de-DE/status.json';
import user from './de-DE/user.json';
import activityEn from './en-US/activity.json';
import approvalEn from './en-US/approval.json';
import commonEn from './en-US/common.json';
import filloutEn from './en-US/fillout.json';
import homeEn from './en-US/home.json';
// en
import loginEn from './en-US/login.json';
import managementEn from './en-US/management.json';
import profileEn from './en-US/profile.json';
import sidebarEn from './en-US/sidebar.json';

// the translations
const resources = {
  de: {
    login,
    common,
    sidebar,
    home,
    profile,
    management,
    approval,
    activity,
    fillout,
    status,
    organization,
    development,
    customer,
    product,
    snackbar,
    changemanagement,
    actions,
    phase,
    dataset,
    location,
    onboarding,
    document: documents,
    file,
    user,
    department,
    sort,
    modal,
    feedback,
    documenttype,
    processes,
    release,
  },
  en: {
    login: loginEn,
    common: commonEn,
    sidebar: sidebarEn,
    home: homeEn,
    profile: profileEn,
    management: managementEn,
    approval: approvalEn,
    activity: activityEn,
    fillout: filloutEn,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: true,
    fallbackLng: 'de-DE',
    defaultNS: 'common',
    react: {
      useSuspense: false,
    },
  });

switch (document.getElementsByTagName('html')[0].getAttribute('lang')) {
  case 'en':
    i18n.changeLanguage('en-US');
    break;
  case 'de':
    i18n.changeLanguage('de-DE');
    break;
  default:
    i18n.changeLanguage('de-DE');
    break;
}

export default i18n;
