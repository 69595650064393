import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import {
  editCustomer,
  removeCustomer,
} from 'store/administration/customer/customer-slice';
import { dotDividerForDate } from 'utils/utils';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IconBox } from './icon-box.component';

export default function useTableCells(categories, errors) {
  const { t } = useTranslation(['status', 'customer']);
  const dispatch = useDispatch();
  const cells = [
    {
      id: '2yhj',
      heading: t('customer:name'),
      render: (item) => (
        <Box component='div' display='flex' alignItems='center' gap='0.5rem'>
          <Tooltip title='Prozess'>
            <IconButton disableRipple sx={{ padding: 0 }}>
              <Icon name='label' size={1.5} />
            </IconButton>
          </Tooltip>
          <Typography component='span' className='TBasicText'>
            {item.name}
          </Typography>
        </Box>
      ),
    },
    {
      id: '6iqve',
      heading: t('customer:changed'),
      render: (item) => (
        <Typography component='span' className='TDateAvatar'>
          {dotDividerForDate(item.updated)}
        </Typography>
      ),
    },
    {
      id: '6id2qve',
      heading: t('customer:type'),
      render: (item) => (
        <Typography component='span' className='TDateAvatar'>
          {t(`customer:type_${item.type}`)}
        </Typography>
      ),
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item) => (
        <IconBox
          item={item}
          options={categories}
          onEdit={(data) => dispatch(editCustomer(data))}
          onRemove={(data) => dispatch(removeCustomer(data))}
          errors={errors}
        />
      ),
    },
  ];

  return {
    cells,
  };
}
