import { Box, Button, Divider, TextField } from '@mui/material';
import { ModalHeader } from 'components/modal-header';

import React from 'react';

import { ModalWrapper } from '../../../wrappers/modal-wrapper/modal-wrapper';

export function ModalAddFile({
  open,
  onClose,
  onChangeFileName,
  newFileName,
  newFileSubmit,
  aktenTab,
}) {
  return (
    <ModalWrapper open={open} onClose={onClose} sx={{ width: '55rem' }}>
      <ModalHeader
        onClose={onClose}
        header={`Neue ${aktenTab ? 'Aktenname' : 'Ordner'} erstellen`}
        primaryColor='product'
        icon={{ name: 'folder', fill: '#fff' }}
      />
      <Divider />
      <Box sx={{ m: '1.5rem 1.5rem 0 1.5rem' }}>
        <TextField
          color='product'
          label={aktenTab ? 'Aktenname' : 'Ordnername'}
          type='text'
          value={newFileName || ''}
          onChange={onChangeFileName}
          fullWidth
          sx={{
            m: '1.5rem 0',
          }}
        />
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='end'
        p='1rem 1.5rem 1.5rem 1.5rem'
      >
        <Button
          variant='text'
          color='product'
          onClick={onClose}
          sx={{
            maxHeight: '36px',
            '&:hover': {
              background: 'rgb(27%, 74%, 51%, 0.12);',
              color: '#45BD81',
              borderColor: 'rgb(27%, 74%, 51%, 0.12)',
            },
          }}
          // disabled={status === 'loading'}
        >
          ABBRECHEN
          {/* {t('product:abort')} */}
        </Button>
        <Button
          onClick={newFileSubmit}
          variant='contained'
          color='product'
          sx={{
            maxHeight: '36px',
            '&.Mui-disabled': {
              background: 'rgb(27%, 74%, 51%, 0.54)',
              color: '#fff',
            },
          }}
          // disabled={status === 'loading' || addedDocument.length === 0}
          disabled={newFileName.length < 3}
        >
          ERSTELLEN
          {/* {t('product:upload')} */}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
