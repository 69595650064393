import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { FeatureCard } from 'cards/new-feature-card';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';
import type { Modal } from 'wrappers/modal-wrapper/modal-wrapper.types';

import React, { useState } from 'react';

import { features } from './constants';
import { useStyles } from './styles';

export function NewFeaturesModal({ open, onClose }: Modal) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string>('1');

  const handleChange = (panel: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '1');
  };

  const handleNext = () => {
    if (expanded >= '4') onClose();
    setExpanded(String(Number(expanded) + 1));
  };

  return (
    <ModalWrapper open={open} className={classes.modal}>
      <Box component='div' className={classes.closeBox}>
        <IconButton onClick={onClose}>
          <Icon name={ICON.CLOSE} size={1.5} />
        </IconButton>
      </Box>
      <Box component='div' textAlign='center'>
        <Typography component='h5' className='ModalHeader'>
          Neue Features in Qsistant 4.1🚀
        </Typography>
        <Typography component='h4' className={classes.label}>
          NEU: Dateien als Qsistant-Dokumente hochladen
        </Typography>
      </Box>
      <Box component='div' className={classes.cardsBox}>
        {features &&
          features.map(({ id, info, desc, icon, image }) => (
            <FeatureCard
              key={id}
              id={id}
              info={info}
              desc={desc}
              icon={icon}
              image={image}
              expanded={expanded}
              handleChange={handleChange}
            />
          ))}
      </Box>
      <Divider />
      <Box component='div' display='flex' justifyContent='flex-end' p='1rem'>
        <Button color='qsistant' variant='text' onClick={onClose}>
          ÜBERSPRINGEN
        </Button>
        <Button color='qsistant' variant='contained' onClick={handleNext}>
          {expanded === '4' ? 'FERTIG' : 'WEITER'}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
