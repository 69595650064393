import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    titleStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(24)} !important`,
      letterSpacing: '0.18px !important',
    },
    descriptionStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(14)} !important`,
      letterSpacing: '0.1px !important',
    },
    notValidBox: {
      display: 'inline-flex',
      gap: '0.5rem',
      alignItems: 'center',
      padding: '0.502rem',
      background: '#FFF4E5',
      fontSize: '0.75rem',
      borderRadius: '0.25rem',
      fontWeight: `${typography.fontWeightMedium}`,
      letterSpacing: '0.025rem',
      color: '#663C00',
    },
    badgeBox: {
      fontSize: `${typography.pxToRem(14)} !important`,
      textTransform: 'capitalize',
      cursor: 'auto',
      padding: '0.31rem',
      borderRadius: '0.25rem',
      fontWeight: `${typography.fontWeightMedium}`,
      lineHeight: '1rem',
      letterSpacing: '0.025rem',
      color: '#fff',
    },
    mainBox: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    titleBox: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      marginBottom: '0.75rem',
    },
    docInfoBox: {
      display: 'flex',
      gap: '0.5rem',
      alignItems: 'center',
    },
  };
});

export const accordion = {
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    padding: '0.375rem 2rem 0 2rem',
    '& .MuiAccordionSummary-root': {
      cursor: 'auto !important',
      userSelect: 'text',
      '&.Mui-focusVisible': {
        background: 'inherit',
      },
    },
    '&.MuiAccordion-root.Mui-expanded': {
      marginBottom: '0.5rem',
    },
    '&.MuiAccordion-root': {
      marginBottom: '0.5rem',
    },
    '& .MuiAccordionDetails-root': {
      width: 'max-content',
    },
  },
};

export const textField = {
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: '#4599bf',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: '#4599bf',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(0, 0, 0, 0.6)',
  },
};
