import { Box, Button } from '@mui/material';
import { AppbarSecondary } from 'components/appbar';
import { Icon } from 'components/icon';
import { CustomStepper } from 'components/stepper';
import {
  DocumentTypes,
  ProcessCategories,
  StandardCertification,
  StandardDocuments,
} from 'forms/documents-setup-components';
import useMultistepForm from 'hooks/useMultistepForm';
import {
  handleActiveDocument,
  handleUpdateFields,
} from 'store/documents-setup/documents-setup-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useState } from 'react';

import { OnboardingWelcomePage } from './components/onboarding-welcome.page';
import { documentTypes, processCategories, steps } from './constants';
import { DocumentStructurePage } from './document-structure.page';
import { useStyles } from './styles';

export default function DocumentsSetupPage() {
  const tariffPlan = 'free';
  const classes = useStyles();
  const dispatcher = useAppDispatch();

  const documentSettings = useAppSelector(
    (state) => state.documentsSetupReducer.documentSettings
  );

  const documents = useAppSelector(
    (state) => state.documentsSetupReducer.documentSettings.documents
  );

  const [page, setPage] = useState<'welcome_page' | 'profile_settings'>(
    'welcome_page'
  );

  const [documentView, setDocumentView] = useState<boolean>(false);

  const handleProfile = () => setPage('profile_settings');

  const updateFields = (fields) => dispatcher(handleUpdateFields(fields));

  const setActiveDocument = (index: number) => {
    setDocumentView(true);
    dispatcher(handleActiveDocument(index));
  };

  const { step, next, back, currentStepIndex, goTo } = useMultistepForm([
    <StandardCertification
      key='standardCertification'
      updateFields={updateFields}
      {...documentSettings}
    />,
    <ProcessCategories
      key='processCategories'
      processCategories={processCategories}
    />,
    <DocumentTypes key='documentTypes' documentTypes={documentTypes} />,
    <StandardDocuments
      key='standardDocuments'
      documents={documents}
      setActiveDocument={setActiveDocument}
    />,
  ]);

  return (
    <>
      <AppbarSecondary
        tariffPlan={tariffPlan}
        userImage='Maxime'
        userName='Maxime'
        badge
        documentBadge={{
          name: 'smart_toy',
          fill: '#6E358C',
          size: 1,
          text: 'KI-unterstütze QM-System Erstellung',
        }}
      />
      {page === 'welcome_page' ? (
        <OnboardingWelcomePage
          isVideo
          marginTop='6rem'
          button={{ label: 'ERSTELLEN STARTEN', color: '#6E358C' }}
          title='QM-System mit KI-Unterstützung'
          onClick={handleProfile}
          description='Unsere KI unterstützt dich, die grundlegenden Inhalte wie Dokumente und Prozesse für dein zertifizierungs-fähiges QM-System schnell und einfach zu erstellen.'
        />
      ) : (
        <Box component='div' className={classes.mainBoxDocSetup}>
          {!documentView ? (
            <>
              <Box component='div' alignSelf='center'>
                <CustomStepper
                  activeStep={currentStepIndex}
                  steps={steps}
                  onClick={goTo}
                />
              </Box>
              <Box
                component='div'
                sx={{
                  width:
                    currentStepIndex === 1
                      ? '43rem'
                      : currentStepIndex === 2
                      ? '47rem'
                      : currentStepIndex === 3
                      ? 'auto'
                      : '39rem',
                }}
              >
                <Box component='div' mb='2.5rem'>
                  {step}
                </Box>
                <Box
                  component='div'
                  display='flex'
                  justifyContent='space-around'
                >
                  {currentStepIndex === 3 ? (
                    <Button variant='contained' color='documentSetup'>
                      DOKUMENTE IN QSISTANT IMPORTIEREN
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant='text'
                        color='documentSetup'
                        onClick={back}
                      >
                        ZURÜCK
                      </Button>
                      <Button
                        variant='contained'
                        color='documentSetup'
                        onClick={next}
                      >
                        WEITER
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              <Box component='div'>
                <Button
                  variant='outlined'
                  color='qsistant'
                  startIcon={
                    <Icon name='open_in_new' size={1.5} fill='#4599BF' />
                  }
                  onClick={() => goTo(0)}
                >
                  ZUR ANFANGSAUSWAHL
                </Button>
              </Box>
            </>
          ) : (
            <DocumentStructurePage
              setDocumentView={() => setDocumentView(false)}
            />
          )}
        </Box>
      )}
    </>
  );
}
