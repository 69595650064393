import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, object, 'filterBtnBadge'>(
  ({ typography }) => {
    return {
      filterBtnBadge: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.625rem',
        height: '2.06rem',
        borderRadius: '1rem',
        border: '1px solid lightgray',
        cursor: 'pointer',
        letterSpacing: '0.25px',
        textTransform: 'capitalize',
        fontSize: '0.875rem',
        minWidth: '7ch',
        fontWeight: typography.fontWeightRegular,
      },
    };
  }
);
