import { generateExtraReducers } from 'utils/utils';

import type { DepartmentsSliceTypes } from './types';

export const initialState: DepartmentsSliceTypes = {
  departments: [],
  modal: false,
  departmentsList: [],
  fetchStatus: null,
  fetchError: null,
};

export const asyncThunkFetchDepartments = generateExtraReducers({
  initialStateProp: 'departments',
  statusKey: 'fetchStatus',
  errorKey: 'fetchError',
});
