import { Info } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { uploadAvatarFile } from 'api/services/user.services';
import { AvatarColorPicker } from 'components/avatar-color-picker';
import { AvatarUploadImage } from 'components/avatar-upload-image';
import { LoadingButton } from 'components/button';
import { DividerOder } from 'components/divider-oder';
import { Icon } from 'components/icon';
import { AuthContext } from 'components/login/AuthContext';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalAppVerification } from 'modals/app-verification';
import { ModalChangePassword } from 'modals/change-password';
import { ModalConfirm } from 'modals/confirm';
import { ModalTwoFactorAuth } from 'modals/two-factor-auth';
import { tooltipContent } from 'pages/onboarding/constants';
import { routes } from 'routes/routes';
import {
  handleHasChanges,
  handleModalHasChanges,
} from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import type { ExtendedUserTypes } from 'store/user-profile/types';
import {
  changePassword,
  getUser,
  updateUser,
} from 'store/user-profile/user-slice';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TooltipInfo } from 'tooltips';

import { useStyles } from './styles';

export function MyProfilePage({ pathName }) {
  const iconBtnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['user', 'common']);
  const dispatcher = useAppDispatch();
  const { dispatch, state } = React.useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    modalHasChanges,
    discardedRoute,
    hasChanges,
    userData,
    saveUserDataStatus,
  } = useAppSelector((states) => ({
    modalHasChanges: states.commonSlice.modalHasChanges,
    discardedRoute: states.commonSlice.discardedRoute,
    hasChanges: states.commonSlice.hasChanges,
    userData: states.userReducer.user,
    saveUserDataStatus: states.userReducer.saveUserDataStatus,
  }));

  const addNotification = useNotification();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const [emailAuth, setEmailAuth] = useState<boolean>(false);
  const [appAuth, setAppAuth] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<ExtendedUserTypes>(null);
  const [activeOption, setActiveOption] = useState<'image' | 'colored'>(
    'image'
  );

  useEffect(() => {
    dispatcher(getUser()).then((response) => {
      if (Object.keys(response.payload).length !== 0) {
        setProfileData(response.payload);
      }
    });
  }, [dispatcher]);

  const hanleChangePassword = (
    oldPassword: string,
    password: string,
    confirmPassword: string
  ) => {
    changePassword({
      oldPassword,
      newPassword: password,
      newPassword2: confirmPassword,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate(routes().login);
  };

  const handleUserUpdate = () => {
    if (
      'avatar' in profileData &&
      profileData.avatar !== null &&
      typeof profileData.avatar === 'object'
    ) {
      const formData = new FormData();
      formData.append('file', profileData.avatar);
      uploadAvatarFile(formData).then((result) => {
        dispatcher(
          updateUser({
            ...(profileData as ExtendedUserTypes),
            avatar: result?.data?.uuid,
          })
        ).then(({ payload }) => {
          dispatch({ type: 'UPDATE_USER', user: payload });
          addNotification({
            text: t('common:alertchangessaved'),
          });
          dispatcher(handleHasChanges(false));
        });
      });
    } else {
      dispatcher(
        updateUser({
          ...(profileData as ExtendedUserTypes),
          avatar:
            activeOption === 'colored'
              ? null
              : (profileData as ExtendedUserTypes).avatar,
        })
      ).then(({ payload }) => {
        dispatch({ type: 'UPDATE_USER', user: payload });
        addNotification({
          text: t('common:alertchangessaved'),
        });
        dispatcher(handleHasChanges(false));
      });
    }
  };

  const updateFields = (fields) => {
    setProfileData((prev) => ({ ...prev, ...fields }));
    dispatcher(handleHasChanges(true));
  };

  return (
    <PageWrapper pageLabel={pathName} pathName={pathName}>
      {!profileData || !('email' in profileData) ? (
        <CircularProgress color='outlined' />
      ) : (
        <Box component='div' className={classes.wrapper}>
          <Box component='div' display='flex' width='60%' gap='6.7rem'>
            <Box component='div' className={classes.sectionBox}>
              <Typography component='h6' className={classes.sectionTitle}>
                {t('user:profilepicture')}
              </Typography>
              <Box component='div'>
                <AvatarUploadImage
                  avatar={profileData.avatar}
                  avatarSize='7rem'
                  color='outlined'
                  activeOption={activeOption}
                  onChange={(e) => updateFields({ avatar: e.target.files[0] })}
                  onRemove={() => updateFields({ avatar: null })}
                  setActiveOption={() => setActiveOption('image')}
                />
                <DividerOder
                  sx={{
                    width: '100%',
                    flexDirection: 'row',
                    margin: '1rem 0 2rem 0',
                  }}
                  orientation='horizontal'
                  primaryColor='#000000CC'
                />
                <AvatarColorPicker
                  avatarColor={profileData.avatarColor}
                  activeOption={activeOption}
                  avatarSize='7rem'
                  setActiveOption={() => {
                    setActiveOption('colored');
                    updateFields({ avatar: null });
                  }}
                  onClick={(color) => {
                    updateFields({ avatarColor: color });
                  }}
                  userName={state.user.prename}
                />
              </Box>
            </Box>
            <Box component='div'>
              <Box component='div' className={classes.sectionBox} mb='2.5rem'>
                <Typography component='h6' className={classes.sectionTitle}>
                  {t('user:profileinformation')}
                </Typography>
                <Box
                  component='div'
                  display='flex'
                  justifyContent='space-between'
                  gap='1.5rem'
                >
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    label={t('user:prename')}
                    color='outlined'
                    value={profileData.prename}
                    onChange={(e) => updateFields({ prename: e.target.value })}
                  />
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={t('user:surname')}
                    color='outlined'
                    value={profileData.surname}
                    onChange={(e) => updateFields({ surname: e.target.value })}
                  />
                </Box>
                <TextField
                  fullWidth
                  variant='outlined'
                  label={t('user:email')}
                  value={profileData.email}
                  onChange={(e) => updateFields({ email: e.target.value })}
                  color='outlined'
                />
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  value='Organisationsmanager'
                  className='MyProfileFieldDisabled'
                  label={
                    <Box
                      display='flex'
                      alignItems='center'
                      gap='0.3rem'
                      component='div'
                    >
                      {t('user:roleatqualitymanagement')}
                      <IconButton
                        onClick={() => {
                          setShowTooltip((prev) => !prev);
                        }}
                        className={classes.iconBtn}
                        ref={iconBtnRef}
                      >
                        <Info className={classes.infoIcon} />
                      </IconButton>
                    </Box>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box component='div' className={classes.sectionBox}>
                <Typography component='h6' className={classes.sectionTitle}>
                  {t('user:passwordandsecurity')}
                </Typography>
                <Button
                  variant='outlined'
                  color='outlined'
                  sx={{ letterSpacing: '0.07rem' }}
                  onClick={() => setPasswordModal(true)}
                  startIcon={<Icon name={ICON.KEY} fill='inherit' size={1.5} />}
                >
                  {t('user:changepassword')}
                </Button>
                <Box component='div' className={classes.verifyBox}>
                  <Icon
                    name={ICON.EMAIL}
                    fill='#000000CC'
                    size={1.5}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <Box component='div'>
                    <Typography component='h6' className={classes.pVerTitle}>
                      {t('user:2fawithemailtitle')}
                    </Typography>
                    <Typography component='p' className={classes.pVerText}>
                      {t('user:2fawithemaildescription')}
                    </Typography>
                  </Box>
                  <Box component='div' position='relative' top='0.875rem'>
                    <Switch
                      checked={emailAuth}
                      onChange={() => setEmailAuth(true)}
                      color='outlined'
                    />
                  </Box>
                </Box>
                {/* <Box component='div' className={classes.verifyBox}>
                  <Icon
                    name='app_shortcut_filled'
                    fill='#000000CC'
                    size={1.5}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <Box component='div'>
                    <Typography component='h6' className={classes.pVerTitle}>
                      Zwei-Faktor-Authentifizierung mit App
                    </Typography>
                    <Typography component='p' className={classes.pVerText}>
                      Füge eine zusätzliche Sicherheitsebene hinzu, indem du
                      einen von einer Authentifizierungs-App generierten
                      Authentifizierungscode erhältst.
                    </Typography>
                  </Box>
                  <Box component='div' position='relative' top='0.875rem'>
                    <Switch
                      checked={appAuth}
                      onChange={() => setAppAuth(true)}
                      color='outlined'
                    />
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Box>
          <Box component='div' className={classes.btnBox}>
            <LoadingButton
              label={t('common:savechanges')}
              disabled={
                !hasChanges ||
                !profileData.prename ||
                !profileData.surname ||
                !profileData.email
              }
              isLoading={saveUserDataStatus === 'pending'}
              loaderSize={1.5}
              onClick={handleUserUpdate}
              startIcon={
                <Icon
                  name={
                    !hasChanges && saveUserDataStatus === 'resolved'
                      ? ICON.DONE
                      : ICON.SAVE
                  }
                  fill='#fff'
                  size={1.5}
                />
              }
              color='outlined'
            />
          </Box>
        </Box>
      )}
      {passwordModal ? (
        <ModalChangePassword
          open={passwordModal}
          onClose={() => setPasswordModal(false)}
          onSave={hanleChangePassword}
        />
      ) : null}
      {emailAuth ? (
        <ModalTwoFactorAuth
          open={emailAuth}
          user={userData}
          onClose={() => {
            dispatcher(getUser()).then(() => setEmailAuth(false));
          }}
        />
      ) : null}
      {appAuth ? (
        <ModalAppVerification
          open={appAuth}
          onClose={() => setAppAuth(false)}
          header={t('user:twofactoractivation')}
        />
      ) : null}
      {modalHasChanges && (
        <ModalConfirm
          open={modalHasChanges}
          title={t('user:modalhaschangestitle')}
          description={t('user:modalhaschangesdesc')}
          onClose={() => dispatcher(handleModalHasChanges(false))}
          onContinue={() => {
            dispatcher(handleHasChanges(false));
            dispatcher(handleModalHasChanges(false));
            navigate(discardedRoute.route && discardedRoute.route);
          }}
          btnNextLabel={t('common:discard')}
          color='outlined'
        />
      )}
      {showTooltip ? (
        <TooltipInfo
          open={showTooltip}
          width='35rem'
          anchorEl={iconBtnRef}
          content={tooltipContent}
          onClose={() => setShowTooltip(false)}
          title={t('user:rolesinfo')}
          description={t('user:tooltipdesc')}
        />
      ) : null}
    </PageWrapper>
  );
}
