import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { FloatingButton } from 'components/button';
import { ContextButtonComponent } from 'components/button/context-button.component';
import { Icon } from 'components/icon';
import { PhaseDataset } from 'components/product-phase-dataset';
import { CustomSelectComponent } from 'components/select/custom-select.component';
import { ImportDocumentListModal } from 'modals/product-development/import-document-list';
import {
  addDataset,
  addDocument,
  addPhase,
  removePhase,
  savePhasesChanges,
  setPhaseName,
  setSelectedDatasetId,
  setSelectedPhaseId,
  updateDataset,
  updatePhaseField,
} from 'store/product/current-product-slice';

import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PhasesItem } from '../../../../../cards/product-phase-card';
import useHorizontallScroll from '../../../../../hooks/useHorizontallScroll';
import { ModalDatasetPhases } from '../../../../../modals/product-development/create-new-dataset';
import { saveBtn } from '../theme';
import { cardsData } from './data';
import { TabsCard } from './tabs-card';
import {
  addBtn,
  addDatasetBtn,
  cardsDataWrap,
  datasetWrap,
  divider,
  emptyWrap,
  formWrapper,
  openPhasesBox,
  phasesTabWrapper,
  prodPhasesBox,
} from './theme';

export function ProductPhasesTab({
  openPhases,
  handleOpenPhases,
  docsModal,
  setDocsModal,
  addedDocument,
  setAddedDocument,
  expanded,
  fakeData,
  setFakeData,
  setReviewModal,
  setExpanded,
  calcPhasesFilling,
  currentDataset,
  selectedDataset,
  handleAddData,
  handleAddDataPhase,
}) {
  const [datasetName, setDatasetName] = useState('');
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);
  const [modal, setModal] = useState(false);
  const inputRefs = useRef([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const parentBoxRef = useRef(null);
  const contentBoxRef = useRef(null);
  const phases = useSelector((state) => state.currentProductReducer.phases);
  const selectedPhaseId = useSelector(
    (state) => state.currentProductReducer.selectedPhaseId
  );
  const selectedDatasetId = useSelector(
    (state) => state.currentProductReducer.selectedDatasetId
  );
  const phasesSaveStatus = useSelector(
    (state) => state.currentProductReducer.phasesSaveStatus
  );

  const {
    handleScroll,
    handleLeft,
    handleRight,
    leftButtonHidden,
    rightButtonHidden,
  } = useHorizontallScroll(parentBoxRef, contentBoxRef);

  useEffect(() => {
    dispatch(setSelectedPhaseId(phases[0].id));
  }, []);

  useEffect(() => {
    inputRefs.current = phases.map(
      (_, index) => inputRefs.current[index] || React.createRef()
    );
    dispatch(setSelectedPhaseId(phases[phases.length - 1]?.id || null));
    inputRefs.current[phases.length - 1]?.current?.focus();
  }, [phases.length]);

  const handleAddInput = () => {
    const newInput = {
      id: `${crypto.randomUUID()}`,
      name: '',
      status: 'ENTWURF',
      filled: 0,
      released: false,
      datasets: [],
    };
    dispatch(addPhase(newInput));
    inputRefs.current.push(React.createRef());
    dispatch(setSelectedPhaseId(newInput.id));
  };

  const handleInputChange = (id, name) => {
    dispatch(setPhaseName({ id, name }));
    if (phases[selectedPhaseId]?.name !== name) {
      setDisableBtn(false);
    }
  };

  const handleDeleteInput = (id) => {
    dispatch(removePhase(id));

    inputRefs.current = inputRefs.current.filter(
      (ref, index) => phases[index].id !== id
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddInput();
    }
  };

  const handleFocus = (id) => {
    dispatch(setSelectedPhaseId(id));
  };

  const handleChangeDatasetName = (e) => {
    setDatasetName(e.target.value);
  };

  const handleChangeOptions = (e, newValue) => {
    setFakeData(newValue);
  };

  const handleDatasetBtn = () => {
    setModal(true);
    setDisableBtn(false);
  };

  const handleRejectBtn = () => {
    dispatch(
      updateDataset({
        selectedId: selectedPhaseId,
        selectedDatasetId,
        updates: {
          status: 'ENTWURF',
        },
      })
    );
  };

  const handleSave = () => {
    dispatch(savePhasesChanges(true));
    setDisableBtn(true);
  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === 'Bearbeiten') {
      setEdit(true);
      dispatch(savePhasesChanges(false));
    } else if (selectedOption === 'Vorschau') {
      setEdit(false);
    }
  };
  const handleDocumentsSubmit = () => {
    dispatch(
      addDocument({ documents: addedDocument, datasetId: selectedDatasetId })
    );
    setDocsModal(false);
    setAddedDocument([]);
  };

  const selectProductData = useMemo(
    () => [
      {
        id: `${crypto.randomUUID()}`,
        name: 'edit_outlined',
        label: 'Bearbeiten',
        show: true,
        onClick: undefined,
        subLabel: 'Alle Elemente direkt bearbeiten',
        fill: '#7F7120',
      },
      {
        id: `${crypto.randomUUID()}`,
        name: 'visibility18',
        label: 'Vorschau',
        show: true,
        onClick: undefined,
        subLabel: 'Alltägliche Phasenansicht',
        fill: '#00000099',
      },
    ],
    [edit]
  );

  useEffect(() => {
    const phaseToUpdate = phases.find((p) => p.id === selectedPhaseId);
    if (phaseToUpdate && phaseToUpdate.filled === 100) {
      dispatch(
        updatePhaseField({
          id: selectedPhaseId,
          field: 'filled',
          value: calcPhasesFilling,
          releas: 'released',
          released: phaseToUpdate.status === 'FREIGEGEBEN',
          status: phaseToUpdate.status,
        })
      );
    }
  }, [edit]);

  const handleDatasetFormSubmit = (e) => {
    e.preventDefault();

    const newDataset = {
      id: `${crypto.randomUUID()}`,
      parentId: selectedPhaseId,
      status: 'ENTWURF',
      dataset: datasetName,
      revisionNumber: 'Revision 0',
      responsible: fakeData,
      personModifedDoc: 'Rainer Zufall',
      ava: fakeData.avatar,
      timeChanged: new Date().toISOString(),
      documents: [],
    };

    dispatch(addDataset({ dataset: newDataset }));
    dispatch(setSelectedDatasetId(newDataset.id));
    setFakeData([]);
    setModal(false);
    setDatasetName('');
  };

  const datasetNotEmpty = phases.length > 0 && currentDataset;

  const currentDatasetName = () =>
    phases.find((p) => p.id === selectedPhaseId) &&
    phases?.find((p) => p.id === selectedPhaseId).name;
  return (
    <Box sx={{ ...phasesTabWrapper }}>
      {!openPhases ? (
        <Box sx={{ ...openPhasesBox }}>
          <Typography className='TextProduktSubtitle' sx={{ p: '2rem 0' }}>
            {cardsData[0].title}
          </Typography>
          <Box sx={{ ...cardsDataWrap }}>
            {cardsData.map((cardData, i) => (
              <Fragment key={cardData.title}>
                <TabsCard
                  img={cardData.img}
                  title={cardData.title}
                  subtitle={cardData.subtitle}
                  btnText={cardData.btnText}
                  btnIco={cardData.btnIco}
                  handleOpenPhases={handleOpenPhases}
                  i={i}
                  edit={edit}
                />
                {i !== cardsData.length - 1 && (
                  <Divider orientation='vertical' sx={{ ...divider }} flexItem>
                    oder
                  </Divider>
                )}
              </Fragment>
            ))}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              ...prodPhasesBox,
              justifyContent: edit ? 'space-between' : 'flex-end',
            }}
          >
            {edit && (
              <Button
                sx={{
                  ml: 'auto',
                  mr: '16.4%',
                  maxHeight: '36px',
                  lineHeight: '1.5rem',
                  p: '7.5px 12px 7.5px 7px',
                  color: '#45BD81',
                  whiteSpace: 'nowrap',
                }}
                startIcon={
                  <Icon
                    sx={{ pr: '0', mr: '0' }}
                    name='view_agenda_filled'
                    fill='#45BD81'
                    size='1.5'
                  />
                }
                className='ProductPhasesWhiteButton'
              >
                ALS VORLAGE SPEICHERN
              </Button>
            )}
            <Box sx={{ display: 'flex', gap: '1.125rem' }}>
              {edit && (
                <Button
                  type='submit'
                  onClick={handleSave}
                  startIcon={
                    <Icon
                      name={phasesSaveStatus ? 'done' : 'save_filled'}
                      fill='#fff'
                      size='1.3'
                    />
                  }
                  color='product'
                  disabled={disableBtn}
                  sx={{
                    ...saveBtn,
                    p: '5.75px 16px 5.75px  11px',
                    width: 'max-content',
                  }}
                  variant='contained'
                >
                  ÄNDERUNGEN SPEICHERN
                </Button>
              )}
              <Box sx={{ position: 'relative' }}>
                <CustomSelectComponent
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: 'green',
                      borderRadius: '0.25rem',
                    },
                    '& .MuiFilledInput-input': {
                      background: edit ? '#E2D16B80' : '#45BD8133',
                      padding: '7px 0.375rem 5px 0.375rem',
                      borderRadius: '0.25rem',
                      height: '24px',
                    },
                    '& .MuiFilledInput-input:focus': {
                      background: edit ? '#E2D16B80' : '#45BD8133',
                    },
                  }}
                  color='product'
                  variant='filled'
                  listData={selectProductData}
                  edit={+edit}
                  defaultValue={selectProductData[0].label}
                  onChange={handleSelectChange}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ position: 'relative', overflow: 'hidden' }}
            ref={parentBoxRef}
            onScroll={handleScroll}
          >
            <Box sx={{ ...formWrapper }} ref={contentBoxRef}>
              {phases.map((input, index) => (
                <Box key={input.id}>
                  <PhasesItem
                    onFocus={handleFocus}
                    isSelected={selectedPhaseId === input.id}
                    onDelete={handleDeleteInput}
                    inputRef={inputRefs.current[index]}
                    input={input}
                    edit={edit}
                    index={index}
                    onChange={handleInputChange}
                    setReviewModal={setReviewModal}
                    selectedId={selectedPhaseId}
                    handleAddData={handleAddDataPhase}
                    onKeyDown={handleKeyDown}
                    fillingInterest={calcPhasesFilling}
                  />
                </Box>
              ))}
              {edit && (
                <Box sx={{ ...addBtn }}>
                  <IconButton
                    aria-label='add'
                    onClick={handleAddInput}
                    sx={{
                      '&:hover': {
                        background: '#e7f5ee',
                        '& .MuiIconButton-root': {
                          backgroundColor: '#e7f5ee',
                        },
                        '& .ico': {
                          color: '#000',
                        },
                      },
                    }}
                  >
                    <Icon name='add' size='3' fill='#45BD81' />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          <FloatingButton
            direction='left'
            onClick={handleLeft}
            sx={{
              top: '47.5%',
              left: '3rem',
              display: leftButtonHidden ? 'none' : 'block',
            }}
          />
          {
            <FloatingButton
              direction='right'
              onClick={handleRight}
              sx={{
                top: '47.5%',
                right: '3rem',
                display: rightButtonHidden ? 'none' : 'block',
              }}
            />
          }{' '}
          <Box sx={{ p: '4.5rem 1.5rem 0 1.5rem' }}>
            <Box
              sx={{
                mb: '2rem',
                display: 'flex',
                alignItems: 'center',
                gap: '0.3rem',
              }}
            >
              <Box variant='span'>
                <Typography
                  className='TextPhasesSubTittle'
                  sx={{ fontWeight: '500' }}
                >
                  Datasets für &quot;
                  {currentDatasetName()}
                  &quot;
                </Typography>
              </Box>
              <Icon name='help_outline' size={1.5} fill='#000000' />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <ContextButtonComponent
                  disabled={
                    !expanded ||
                    !currentDataset ||
                    selectedDataset?.status === 'FREIGEGEBEN'
                  }
                  setDocsModal={setDocsModal}
                />
                {docsModal && (
                  <ImportDocumentListModal
                    open={docsModal}
                    addedDocument={addedDocument}
                    setAddedDocument={setAddedDocument}
                    handleDocumentsSubmit={handleDocumentsSubmit}
                    onClose={() => {
                      setDocsModal(false);
                    }}
                  />
                )}
                {!edit &&
                  selectedDataset?.status === 'IN PRÜFUNG' &&
                  expanded && (
                    <>
                      <Button
                        type='submit'
                        onClick={handleAddData}
                        startIcon={
                          <Icon name='lock_open' fill='#fff' size='1.5' />
                        }
                        className='ProductPhasesButton'
                        sx={{ ...saveBtn }}
                        variant='contained'
                        color='product'
                      >
                        <Typography
                          className='TextPhasesBtn'
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          FREIGABE
                        </Typography>
                      </Button>
                      <Button
                        variant='outlined'
                        onClick={handleRejectBtn}
                        startIcon={
                          <Icon
                            name='not_interested'
                            fill='#45BD81'
                            size='1.5'
                          />
                        }
                        sx={{ ...addDatasetBtn }}
                      >
                        ABLEHNEN
                      </Button>
                    </>
                  )}
                {edit ? (
                  <Button
                    variant='outlined'
                    startIcon={<Icon name='add' fill='#45BD81' size='1.5' />}
                    sx={{ ...addDatasetBtn }}
                    onClick={handleDatasetBtn}
                  >
                    NEUES DATASET
                  </Button>
                ) : selectedDataset?.status === 'ENTWURF' && expanded ? (
                  <Button
                    type='submit'
                    onClick={() => setReviewModal(true)}
                    startIcon={<Icon name='lock_open' fill='#fff' size='1.5' />}
                    className='ProductPhasesButton'
                    sx={{
                      ...saveBtn,
                      p: '6px 16px 6px  12px',
                    }}
                    variant='contained'
                    color='product'
                  >
                    <Typography
                      className='TextPhasesBtn'
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      FREIGABE ANFORDERN
                    </Typography>
                  </Button>
                ) : null}
              </Box>
              {!edit && expanded && (
                <Button
                  variant='outlined'
                  startIcon={
                    <Icon name='inventory' fill='#45BD81' size='1.5' />
                  }
                  sx={{ ...addDatasetBtn }}
                >
                  ARCHIVIEREN
                </Button>
              )}
            </Box>
            {modal && (
              <ModalDatasetPhases
                closeModal={() => {
                  setFakeData([]);
                  setModal(false);
                  setDatasetName('');
                }}
                modal={modal}
                name={currentDatasetName() || ''}
                datasetName={datasetName || ''}
                onChangeDatasetName={handleChangeDatasetName}
                data={fakeData}
                onChangeOptions={handleChangeOptions}
                onFormSubmit={handleDatasetFormSubmit}
              />
            )}
            <Box
              sx={{
                ...datasetWrap,
                justifyContent: datasetNotEmpty ? 'flex-start' : 'center',
              }}
            >
              {datasetNotEmpty ? (
                <PhaseDataset expanded={expanded} setExpanded={setExpanded} />
              ) : (
                <Box sx={{ ...emptyWrap }}>
                  <Icon name='empty_datas' height={16.0625} width={16.0625} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
