/* eslint-disable func-names */

/* eslint-disable no-underscore-dangle */
import { SelectChangeEvent } from '@mui/material';
import {
  ActionReducerMapBuilder,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import type {
  DocumentFileTypes,
  SupportFileTypes,
} from 'modals/create-new-document/types';
import type { DocumentTypes } from 'store/administration/document-type/types';
import type { CategoryTypes } from 'store/administration/process-category/types';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';
import type { ArchiveDocumentsTypes } from 'store/qsistant/archive/types';
import type { DocumentOverviewTypes } from 'store/qsistant/document-overview/types';
import type { SupportingDocumentTypes } from 'store/qsistant/supporting-documents/types';

import React from 'react';
import { Location } from 'react-router';

import { AxiosError } from 'axios';
import { debounce } from 'lodash';

export const dotDividerForDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);
  return formattedDate.replace(/\//g, '.');
};

export const shortName = (fullName: string): string => {
  return fullName.replace(/[a-z]{1,}/, '.');
};

export const getTime = (date: Date): string => {
  const dateObj = new Date(date);
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const generateDocLabel = (num: number): string => {
  const prefix = 'DOC-';
  const zeros = '0000';
  const number = num < 10 ? `0${num}` : num < 100 ? num : 99;
  return prefix + (zeros + number).slice(-5);
};

export const updatedDay = (incomeDate: Date): string => {
  const date = new Date(incomeDate);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return 'Heute';
  }
  if (date.toDateString() === yesterday.toDateString()) {
    return 'Gestern';
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${day}.${month}.${year}`;
};

export const removeDotFromFile = (fileName: string) =>
  fileName.replace(/\.[a-z]{1,}/g, '');

export const getDisplayedFiles = <
  T extends (SupportFileTypes | DocumentFileTypes)[]
>(
  array: T,
  from: number,
  stepper: number
): T => [...array].slice(from, from + stepper) as T;

export const multipleFiltersChange = (
  event: SelectChangeEvent<string | string[]>,
  setState: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const {
    target: { value },
  } = event;
  if (typeof value === 'string') {
    setState(value.split(','));
  } else if (Array.isArray(value)) {
    setState(value);
  }
};

export const tableMultipleFiltersChange = <T>(
  event: SelectChangeEvent<string | string[]>,
  stateKey: keyof T,
  setState: React.Dispatch<React.SetStateAction<T>>
) => {
  const {
    target: { value },
  } = event;
  if (typeof value === 'string') {
    setState((prevState) => ({
      ...prevState,
      [stateKey]: value.split(','),
    }));
  } else if (Array.isArray(value)) {
    setState((prevState) => ({
      ...prevState,
      [stateKey]: value,
    }));
  }
};

// time before some action happens
export function wait(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export const querySearchParam = (location: Location): string => {
  const searchParams = new URLSearchParams(location.search);
  const preselectedFilter = searchParams.get('q');
  return preselectedFilter;
};

export const sortByCriteria = <
  T extends
    | DocumentOverviewTypes
    | ArchiveDocumentsTypes
    | SupportingDocumentTypes
    | CategoryTypes
    | ProcessDocumentsTypes
    | DocumentTypes
>(
  array: T[],
  criteria: string
): T[] => {
  const modifiedArray = [...array];
  switch (criteria) {
    case 'default':
      break;
    case 'alphabet ascending':
      modifiedArray.sort((a, b) => a.name.localeCompare(b.name));
      break;
    case 'alphabet descending':
      modifiedArray.sort((a, b) => b.name.localeCompare(a.name));
      break;
    case 'completion date ascending':
      modifiedArray.sort(
        (a, b) => new Date(a.updated).getTime() - new Date(b.updated).getTime()
      );
      break;
    case 'completion date descending':
      modifiedArray.sort(
        (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()
      );
      break;
    case 'number ascending':
      modifiedArray.sort((a, b) => {
        if ('documentId' in a && 'documentId' in b) {
          return a.documentId - b.documentId;
        }
        return 0;
      });
      break;
    case 'number descending':
      modifiedArray.sort((a, b) => {
        if ('documentId' in a && 'documentId' in b) {
          return b.documentId - a.documentId;
        }
        return 0;
      });
      break;
    case 'favorites ascending':
      modifiedArray.sort((a, b) => {
        if ('isFavourite' in a && 'isFavourite' in b) {
          return (a.isFavourite ? 1 : 0) - (b.isFavourite ? 1 : 0);
        }
        return 0;
      });
      break;
    case 'favorites descending':
      modifiedArray.sort((a, b) => {
        if ('isFavourite' in a && 'isFavourite' in b) {
          return (b.isFavourite ? 1 : 0) - (a.isFavourite ? 1 : 0);
        }
        return 0;
      });
      break;
    case 'status':
      modifiedArray.sort((a, b) => {
        if ('status' in a && 'status' in b) {
          return a.status.localeCompare(b.status);
        }
        return 0;
      });
      break;
    case 'document type':
      modifiedArray.sort((a, b) => {
        if ('documentType' in a && 'documentType' in b) {
          return a.documentType.name.localeCompare(b.documentType.name);
        }
        return 0;
      });
      break;
    case 'process':
      modifiedArray.sort((a, b) => {
        if ('process' in a && 'process' in b) {
          return a.process.name.localeCompare(b.process.name);
        }
        return 0;
      });
      break;
    case 'person':
      modifiedArray.sort((a, b) => {
        if ('creator' in a && 'creator' in b) {
          return b.creator.prename.localeCompare(a.creator.prename);
        }
        return 0;
      });
      break;
    case 'last modified ascending':
      modifiedArray.sort(
        (a, b) => new Date(a.updated).getTime() - new Date(b.updated).getTime()
      );
      break;
    case 'last modified descending':
      modifiedArray.sort(
        (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()
      );
      break;
    default:
      break;
  }
  return Array.from(modifiedArray);
};

// debounce function for array filtering
export const debounceFilterArray = <T extends { name: string }>(
  array: T[],
  query: string,
  callback: React.Dispatch<React.SetStateAction<T[]>>,
  delay = 500
) => {
  const debouncedFilter = debounce(() => {
    const filteredData = array.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(filteredData);
  }, delay);
  debouncedFilter();
};

// for redux Slice

interface ReducerArgs {
  initialStateProp: string;
  statusKey: string;
  errorKey: string;
  loadingKey?: string;
}

type ExtraReducersActionPayload<T> = {
  error?: string;
  payload?: T;
};

export function generateExtraReducers<T extends ReducerArgs>(args: T) {
  const { initialStateProp, statusKey, errorKey, loadingKey } = args;

  return function (
    builder: ActionReducerMapBuilder<unknown>,
    asyncThunk: AsyncThunk<unknown, any, any>
  ) {
    builder
      .addCase(asyncThunk.pending, (state) => {
        state[statusKey] = 'pending';
        state[errorKey] = false;
        state[loadingKey] = true;
      })
      .addCase(asyncThunk.fulfilled, (state, action) => {
        state[initialStateProp] = action.payload;
        state[loadingKey] = false;
        state[statusKey] = 'resolved';
      })
      .addCase(
        asyncThunk.rejected,
        (
          state,
          action: PayloadAction<ExtraReducersActionPayload<AxiosError>>
        ) => {
          state[statusKey] = 'rejected';
          state[errorKey] =
            (action.payload as AxiosError)?.response?.data || null;
          state[loadingKey] = false;
        }
      );
  };
}
