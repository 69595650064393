import type { PageFilterSliceTypes } from './types';

const initialState: PageFilterSliceTypes = {
  documentTypePage: {
    persistedCapability: null,
  },
  processPage: {
    persistedProcessCats: null,
  },
  membersPage: {
    persistedRoles: null,
  },
  departmentsPage: {
    persistedParentDeps: null,
  },
  documentOverviewPage: {
    persistedStatus: null,
    persistedElType: null,
    persistedDocType: null,
    persistedProcess: null,
    persistedPerson: null,
    persistedOrganization: null,
    persistedLocation: null,
  },
  supportingDocumentsPage: {
    persistedStatus: null,
    persistedFormTemplate: null,
    persistedOrganization: null,
    persistedPeson: null,
  },
  archivePage: {
    persistedUsers: null,
    persistedElTypes: null,
  },
};

export default initialState;
