import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import { routes } from 'routes/routes';
import { dotDividerForDate } from 'utils/utils';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function useTableCells() {
  const navigate = useNavigate();
  const { t } = useTranslation(['status', 'document']);

  const cells = [
    {
      id: '1tgbvfd',
      heading: t('document:status'),
      render: (item) => (
        <Status status={item.status} statusText={t(`status:${item.status}`)} />
      ),
    },
    {
      id: '2cdsbgyhj',
      heading: t('document:name'),
      render: (item) => (
        <Box component='div' display='flex' alignItems='center' gap='0.5rem'>
          <Tooltip title={item.name}>
            <IconButton disableRipple sx={{ padding: 0 }}>
              <Icon name='description' />
            </IconButton>
          </Tooltip>
          <Typography component='p'>{item.name}</Typography>
        </Box>
      ),
    },
    {
      id: '3tysdvwuh',
      heading: t('document:number'),
      render: (item) => (
        <Typography component='p'>{`Revision ${item.revision}`}</Typography>
      ),
    },
    {
      id: '4edcsdfdws',
      heading: t('document:documenttype'),
      render: (item) => (
        <Typography component='p'>{item.document_type?.name}</Typography>
      ),
    },
    {
      id: '5qr2lzi',
      heading: t('document:process'),
      render: (item) => (
        <Typography component='p'>{item.process?.name}</Typography>
      ),
    },
    {
      id: '667qve',
      heading: t('document:changed'),
      render: (item) => (
        <Box component='div' display='flex' gap='0.5rem' alignItems='center'>
          <Tooltip title={item.creator?.fullName}>
            <IconButton
              disableTouchRipple
              sx={{
                padding: 0,
              }}
            >
              <Avatar
                alt={item.creator?.fullName}
                sx={{ width: '2.25rem', height: '2.25rem' }}
                src='/photo'
              />
            </IconButton>
          </Tooltip>
          <Box component='div' display='flex' flexDirection='column'>
            <Typography component='p' style={{ width: 'max-content' }}>
              {item.creator?.fullName.replace(/[a-z]{1,}/, '.')}
            </Typography>
            <Typography component='p' className='DateWithAvatar'>
              {dotDividerForDate(item.created?.substring(0, 10))}
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  return {
    cells,
  };
}
