import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    otpGroup: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      columnGap: `${typography.pxToRem(10)}`,
    },
    otpInput: {
      height: `${typography.pxToRem(70)}`,
      width: `${typography.pxToRem(56)}`,
      borderRadius: `${typography.pxToRem(4)}`,
      fontSize: `${typography.pxToRem(38)}`,
      textAlign: 'center',
      '&:nth-of-type(3)': {
        marginRight: `${typography.pxToRem(24)}`,
      },
    },
  };
});
