/* eslint-disable global-require */
import { Box, Button, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { useStyles } from '../styles';
import type { OnboardingWelcomePageProps } from './onboarding-welcome.page.types';

export function OnboardingWelcomePage({
  button,
  description,
  title,
  onClick,
  isVideo = false,
  marginTop,
}: OnboardingWelcomePageProps) {
  const classes = useStyles();
  return (
    <Box
      component='div'
      className={classes.welcomeBoxWrapper}
      sx={{ marginTop }}
    >
      {!isVideo ? (
        <Box
          component='video'
          loop
          autoPlay
          muted
          controls={false}
          className={classes.videoStyle}
        >
          <Box
            component='source'
            src={require('../../../video/animatedgradient.mp4')}
            type='video/mp4'
          />
        </Box>
      ) : null}
      <Box component='div' className={classes.welcomeContentBox}>
        <Icon
          name={ICON.QSISTANT_LOGO}
          style={{ position: 'relative', right: '1rem' }}
        />
        <Box component='div' textAlign='center'>
          <Typography component='h4' className={classes.welcomeTitleStyle}>
            {title}
          </Typography>
          <Typography
            component='h6'
            className={classes.welcomeDescriptionStyle}
          >
            {description}
          </Typography>
        </Box>
        <Button
          variant='contained'
          sx={{
            background:
              button.color || 'linear-gradient(45deg, #4599BF, #6E358C)',
            letterSpacing: '0.08rem',
            '&:hover': {
              background: button.color,
            },
          }}
          onClick={onClick}
          startIcon={
            button.icon ? (
              <Icon name={button.icon} fill='#fff' size={1.5} />
            ) : null
          }
        >
          {button.label}
        </Button>
      </Box>
    </Box>
  );
}
