/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { FiltersBox, SortFilterBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { Filter } from 'components/filter';
import { FilterDropdown } from 'components/filter-dropdown';
import { Table } from 'components/table';
import { ICON } from 'enum/icons.enum';
import { useAppSelector } from 'store/hook';
import { multipleFiltersChange } from 'utils/utils';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  filters,
  personDocumentFilter,
  statusDocumentFilter,
} from './search.constants';
import useTableCells from './table-cell.hook';

export function SearchPage({ pathName }) {
  const { t } = useTranslation(['status', 'document', 'sort']);
  const { searchResult } = useAppSelector((state) => ({
    searchResult: state.searchResultsReducer.searchResult,
  }));

  const { cells } = useTableCells();

  const [statusFilter, setStatusFilter] = useState<string[]>(['']);
  const [personFilter, setPersonFilter] = useState<string[]>(['']);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [documentType, setDocumentType] =
    useState<(typeof filters)[number]['value']>('');

  const handleResetFilters = () => {
    setDocumentType('');
    setPersonFilter(['']);
    setStatusFilter(['']);
  };

  return (
    <PageWrapper pathName={pathName} pageLabel={pathName}>
      <Typography component='p' className='PageInfo'>
        {searchResult.length === 0
          ? t('document:nosearchresults')
          : t('document:searchresultscount', { count: searchResult.length })}
      </Typography>
      <SortFilterBox>
        <FiltersBox>
          <FilterDropdown
            multiple
            disabled={searchResult.length === 0}
            isFilterApplied={statusFilter.length > 1}
            options={statusDocumentFilter}
            value={statusFilter}
            onChange={(e) => multipleFiltersChange(e, setStatusFilter)}
            filterLabel={t('sort:status')}
          />
          {filters.map((filter) => (
            <Filter
              key={filter.id}
              label={filter.label}
              value={filter.value}
              disabled={searchResult.length === 0}
              checked={documentType === filter.value}
              onClick={() => setDocumentType(filter.value)}
            />
          ))}
          <FilterDropdown
            multiple
            isFilterApplied={personFilter.length > 1}
            disabled={searchResult.length === 0}
            options={personDocumentFilter}
            value={personFilter}
            onChange={(e) => multipleFiltersChange(e, setPersonFilter)}
            filterLabel={t('sort:person')}
          />
          <Button
            variant='text'
            color='qsistant'
            onClick={handleResetFilters}
            disabled={
              documentType.length === 0 &&
              statusFilter.length === 1 &&
              personFilter.length === 1
            }
          >
            {t('sort:resetfilter')}
          </Button>
        </FiltersBox>
        <Box component='div' display='flex' alignItems='center'>
          <FormControlLabel
            control={
              <Switch
                color='qsistant'
                onChange={() => setShowDescription((prev) => !prev)}
                disabled={searchResult.length === 0}
              />
            }
            label={t('document:fullsearchview')}
          />
        </Box>
      </SortFilterBox>
      {searchResult.length > 0 ? (
        <Table
          cells={cells}
          data={searchResult}
          showDescription={showDescription}
          descriptionItem={{
            name: (item) => t('document:textsection', { name: item?.name }),
            text: (item) => item?.highlight,
          }}
        />
      ) : (
        <EmptyList
          image={{ name: ICON.NO_FOUND_DATA, height: 22 }}
          title={t('document:nosearchresultsdescription')}
          description={t('document:tryanothersearchkeyword')}
        />
      )}
    </PageWrapper>
  );
}
