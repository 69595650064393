import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Typography,
  useMediaQuery,
} from '@mui/material';
import api from 'api/api';
import { UploadedFileCard } from 'cards/uploaded-file-card';
import { LoadingButton } from 'components/button';
import { DragDrop } from 'components/drag-drop';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { NewSupportDocumentForm } from 'forms/create-new-document';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchDocumentVersions } from 'store/qsistant/supporting-documents/supporting-documents-slice';
import { getDisplayedFiles } from 'utils/utils';
import { ModalWrapper } from 'wrappers/modal-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import type {
  CreateNewDocumentProps,
  ResponseFileData,
  SupportFileTypes,
} from './types';

export function ModalCreateNewSupportDocument({
  open,
  onClose,
}: CreateNewDocumentProps) {
  const { t } = useTranslation('fillout');
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const screenBreakpoint = useMediaQuery('(min-width:1920px)');
  const step = 2;

  const { versionsStatus } = useAppSelector(
    ({ supportingDocumentsReducer }) => ({
      versionsStatus: supportingDocumentsReducer.versionStatus,
    })
  );

  const [uploadedFiles, setUploadedFiles] = useState<SupportFileTypes[]>([]);
  const [currentFilePosition, setCurrentFilePosition] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const handleUploadFiles = (files: FileList) => {
    if (files instanceof FileList) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i += 1) {
        const formData = new FormData();
        formData.append('file', files[i]);
        api
          .post('/files/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            setUploadedFiles((prev) => [...prev, createNewFile(response.data)]);
          });
      }
    }
  };

  const createNewFile = (data: ResponseFileData): SupportFileTypes => {
    return {
      ...data,
      reference: '',
      externReference: '',
      progress: 0,
    };
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    property: 'fileName' | 'reference' | 'externReference'
  ) => {
    const { uuid } = uploadedFiles[currentFilePosition];
    const { value } = e.target;
    const updated = uploadedFiles.map((file) => {
      if (file.uuid === uuid) {
        return {
          ...file,
          [property]: value,
        };
      }
      return file;
    });
    setUploadedFiles(updated);
  };

  const handleRemoveFile = (id: string) => {
    setCurrentFilePosition(0);
    setOffset(0);
    const filtered = uploadedFiles.filter((file) => file.uuid !== id);
    setUploadedFiles(filtered);
  };

  const clickBottom = () => {
    if (
      currentFilePosition % 2 !== 0 &&
      currentFilePosition + 1 < uploadedFiles.length
    ) {
      setOffset(offset + step);
    }
    setCurrentFilePosition(currentFilePosition + 1);
  };

  const clickUp = () => {
    if (
      currentFilePosition % 2 === 0 &&
      currentFilePosition + 1 <= uploadedFiles.length
    ) {
      setOffset(offset - step);
    }
    setCurrentFilePosition(currentFilePosition - 1);
  };

  useEffect(() => {
    dispatch(fetchDocumentVersions());
  }, [dispatch]);

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      className={classes.supportModal}
    >
      <ModalHeader
        header={t('fillout:addfilloutdocument')}
        onClose={onClose}
        icon={{ name: ICON.UPLOAD_FILE }}
      />
      <Divider />
      {versionsStatus === 'resolved' ? (
        <Box component='div' className={classes.contentBox} sx={{ gap: 0 }}>
          <Box
            component='div'
            className={classes.dragDropBox}
            sx={{
              height:
                uploadedFiles.length === 0
                  ? screenBreakpoint
                    ? '17.4rem'
                    : '18.5rem'
                  : '4rem',
            }}
          >
            <DragDrop
              uploadFiles={uploadedFiles}
              handleChange={handleUploadFiles}
              dragDropName='Dateien'
              color='#4599BD'
              fileTypesText='PDF, DOCX, ZIP, JPEG ODER ANDERE'
            />
          </Box>
          {uploadedFiles.length > 0 && (
            <Box component='div' className={classes.listWrapper}>
              <IconButton
                onClick={clickUp}
                disabled={currentFilePosition + 1 === 1}
                sx={{ p: '0.1rem' }}
              >
                <Icon
                  name='expand_less'
                  fill={
                    currentFilePosition + 1 === 1 ? '#0000001F' : '#00000099'
                  }
                  size={1.25}
                  style={{ cursor: 'pointer' }}
                />
              </IconButton>
              <List className={classes.filesListBox}>
                {uploadedFiles.length > 0 &&
                  getDisplayedFiles(uploadedFiles, offset, step).map(
                    (file, index) => (
                      <UploadedFileCard
                        key={file.uuid}
                        type='Nachweis'
                        index={index}
                        offset={offset}
                        file={file}
                        currentFilePosition={currentFilePosition}
                        filesLength={uploadedFiles.length}
                        isSelected={currentFilePosition === index + offset}
                        onRemove={handleRemoveFile}
                        onClick={() => setCurrentFilePosition(index + offset)}
                      />
                    )
                  )}
              </List>
              <IconButton
                onClick={clickBottom}
                disabled={currentFilePosition + 1 === uploadedFiles.length}
                sx={{ p: '0.1rem' }}
              >
                <Icon
                  name='expand_more'
                  fill={
                    currentFilePosition + 1 === uploadedFiles.length
                      ? '#0000001F'
                      : '#00000099'
                  }
                  size={1.25}
                  style={{ cursor: 'pointer' }}
                />
              </IconButton>
              {uploadedFiles.length > 0 && (
                <NewSupportDocumentForm
                  key={uploadedFiles[currentFilePosition].uuid}
                  className={classes.newDocumentForm}
                  title={uploadedFiles[currentFilePosition]?.fileName}
                  onTitleChange={(e) => handleInputChange(e, 'fileName')}
                  reference={uploadedFiles[currentFilePosition]?.reference}
                  onReferenceChange={(e) => handleInputChange(e, 'reference')}
                  externReference={
                    uploadedFiles[currentFilePosition]?.externReference
                  }
                  onExternReferenceChange={(e) =>
                    handleInputChange(e, 'externReference')
                  }
                />
              )}
              <Box component='div' className={classes.paginationBox}>
                <Button
                  variant='text'
                  color='qsistant'
                  disabled={currentFilePosition <= 0}
                  startIcon={
                    <Icon
                      name='chevron_left'
                      fill={currentFilePosition <= 1 ? '#BDBDBD' : '#4599BF'}
                      size={1.5}
                    />
                  }
                  onClick={clickUp}
                >
                  {t('common:previous')}
                </Button>
                <Typography component='p' className={classes.counterText}>{`${
                  currentFilePosition + 1
                }/${uploadedFiles.length}`}</Typography>
                <Button
                  variant='text'
                  disabled={currentFilePosition + 1 >= uploadedFiles.length}
                  color='qsistant'
                  endIcon={
                    <Icon
                      name='chevron_right'
                      fill={
                        currentFilePosition + 1 >= uploadedFiles.length
                          ? '#BDBDBD'
                          : '#4599BF'
                      }
                      size={1.5}
                    />
                  }
                  onClick={clickBottom}
                >
                  {t('common:next')}
                </Button>
              </Box>
            </Box>
          )}
          <Box component='div' width='100%' marginTop='2rem'>
            <LoadingButton
              variant='contained'
              loaderSize={1.875}
              color='qsistant'
              className={classes.createButton}
              fullWidth
              onClick={() => console.log('sd')}
              disabled={uploadedFiles.length === 0}
              // isLoading={loading}
              label={t('fillout:addfilloutdocument')}
            />
          </Box>
        </Box>
      ) : (
        [1, 2, 3, 4, 5].map((item) => <TableSkeleton key={item} height={65} />)
      )}
    </ModalWrapper>
  );
}
