/* eslint-disable react/no-array-index-key */
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextField,
  Tooltip,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import type { ChapterItem, StandardTypes } from 'store/common/standards/types';

import React from 'react';

import { listBox } from './theme';
import type { StandardsAutocompleteProps } from './types';

export function StandardsAutocompleteComponent({
  sx,
  options,
  label,
  value,
  onChange,
  variant = 'outlined',
  fullWidth = false,
  handler,
  color = 'qsistant',
  disableCloseOnSelect = false,
}: StandardsAutocompleteProps) {
  return (
    <Autocomplete
      multiple
      // open
      fullWidth={fullWidth}
      id='standards-autocomplete'
      value={value}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={onChange}
      options={options}
      color={color}
      filterSelectedOptions
      clearIcon={null}
      getOptionLabel={(option) => {
        return option.chapters
          .map((c) => c.chapter || c.sub_chapters.map((sc) => sc.chapter))
          .join(', ');
      }}
      groupBy={(option: StandardTypes) => option.name}
      renderOption={(
        props,
        { name: standardName, chapters }: StandardTypes
      ) => {
        return (
          <li
            key={standardName}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {chapters.map((item) => (
              <Box key={item.chapter} sx={{ width: '100%' }}>
                <Box
                  sx={{ ...listBox }}
                  onClick={() => onChange(item, standardName)}
                >
                  <Checkbox
                    checked={value.some((val) => val.chapter === item.chapter)}
                    style={{ marginRight: 8 }}
                  />
                  {item.chapter}
                </Box>
                {item.sub_chapters &&
                  item.sub_chapters.length > 0 &&
                  item.sub_chapters.map((sub) => (
                    <Box
                      onClick={() => onChange(sub, standardName)}
                      sx={{
                        marginLeft: '8px',
                        ...listBox,
                      }}
                      key={sub.chapter}
                    >
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={value.some(
                          (val) => val.chapter === sub.chapter
                        )}
                      />
                      {sub.chapter}
                    </Box>
                  ))}
              </Box>
            ))}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} sx={sx} variant={variant} label={label} />
      )}
      renderTags={() =>
        value.map((tag, index) => {
          return (
            <Tooltip title={tag.standard} key={index}>
              <Chip
                label={tag.chapter}
                onDelete={(e) => {
                  e.stopPropagation();
                  const filtered = value.filter(
                    (t, i) => t.chapter !== tag.chapter
                  );
                  handler(filtered);
                }}
              />
            </Tooltip>
          );
        })
      }
      popupIcon={<Icon name={ICON.ARROW_DROP_DOWN} fill='inherit' />}
    />
  );
}
