import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addToFavourite,
  removeFromFavourite,
} from 'api/services/document-overview.services';
import { getUserDashboardData } from 'api/services/home.services';
import type { ExtraReducersActionPayload } from 'store/types';

import { AxiosError } from 'axios';

import type {
  ActivityTypes,
  ChangesTypes,
  CheckdocumentsTypes,
  ExpiringTypes,
  FacouritesTypes,
  HistoryTypes,
  HomeSliceTypes,
  OpenRequestsTypes,
} from './home.types';

const initialState: HomeSliceTypes = {
  activity: [],
  changes: [],
  checkdocuments: [],
  history: [],
  favourites: [],
  finishedrequest: [],
  openrequests: [],
  expiringdocuments: [],
  error: null,
  status: null,
};

export const getUserActivityData = createAsyncThunk(
  'hom/files-pri/T03GC71EZGC-F0560MEFY4E/screenshot_2023-05-04_at_13.01.27.pnge/getUserActivityData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUserDashboardData();
      dispatch(setUserActivity(response.data.activity));
      dispatch(setUserChanges(response.data.changes));
      dispatch(setCheckdocuments(response.data.checkdocuments));
      dispatch(setFavourites(response.data.favourites));
      dispatch(setHistory(response.data.history));
      dispatch(setFinishedRequests(response.data.finishedrequests));
      dispatch(setOpenRequests(response.data.openrequests));
      dispatch(setExpiringDocuments(response.data.expiringdocuments));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const handleRemoveFavourite = createAsyncThunk(
  'home/removeFavouriteDocument',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await removeFromFavourite(id);
      dispatch(removeFavouriteDocument(id));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const handleAddFavourite = createAsyncThunk(
  'home/removeFavouriteDocument',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await addToFavourite(id);
      dispatch(addDocumentToFavourite(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {
    setUserActivity(state, action: PayloadAction<ActivityTypes[]>) {
      return {
        ...state,
        activity: action.payload,
      };
    },

    setUserChanges(state, action: PayloadAction<ChangesTypes[]>) {
      return {
        ...state,
        changes: action.payload,
      };
    },

    setCheckdocuments(state, action: PayloadAction<CheckdocumentsTypes[]>) {
      return {
        ...state,
        checkdocuments: action.payload,
      };
    },

    setHistory(state, action: PayloadAction<HistoryTypes[]>) {
      return {
        ...state,
        history: action.payload,
      };
    },

    setFavourites(state, action: PayloadAction<FacouritesTypes[]>) {
      return {
        ...state,
        favourites: action.payload,
      };
    },

    removeFavouriteDocument(state, action: PayloadAction<number>) {
      const filtered = state.favourites.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        favourites: filtered,
      };
    },

    addDocumentToFavourite(state, action: PayloadAction<FacouritesTypes>) {
      return {
        ...state,
        favourites: [...state.favourites, action.payload],
      };
    },

    setFinishedRequests(state, action: PayloadAction<any>) {
      return {
        ...state,
        finishedrequest: action.payload,
      };
    },

    setOpenRequests(state, action: PayloadAction<OpenRequestsTypes[]>) {
      return {
        ...state,
        openrequests: action.payload,
      };
    },

    setExpiringDocuments(state, action: PayloadAction<ExpiringTypes[]>) {
      state.expiringdocuments = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUserActivityData.pending, (state) => {
        state.error = null;
        state.status = 'pending';
      })
      .addCase(
        getUserActivityData.fulfilled,
        (state, action: PayloadAction<HomeSliceTypes>) => {
          state.error = null;
          state.status = 'resolved';
          if (state.status === 'resolved') {
            state.activity = action.payload.activity;
            state.changes = action.payload.changes;
            state.checkdocuments = action.payload.checkdocuments;
            state.favourites = action.payload.favourites;
            state.finishedrequest = action.payload.finishedrequest;
            state.history = action.payload.history;
            state.openrequests = action.payload.openrequests;
          }
        }
      )
      .addCase(
        getUserActivityData.rejected,
        (
          state,
          action: PayloadAction<ExtraReducersActionPayload<AxiosError>>
        ) => {
          const { response } = action.payload as AxiosError<any, any>;
          state.error = response?.data ?? null;
          state.status = 'rejected';
        }
      ),
});

export const {
  setUserActivity,
  setUserChanges,
  setCheckdocuments,
  setHistory,
  setFavourites,
  removeFavouriteDocument,
  addDocumentToFavourite,
  setFinishedRequests,
  setOpenRequests,
  setExpiringDocuments,
} = homeSlice.actions;

export default homeSlice.reducer;
