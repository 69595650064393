import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    otpBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(8)}`,
      padding: `${typography.pxToRem(24)}`,
      textAlign: 'center',
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: `${typography.pxToRem(24)}`,
    },
    modal: {
      width: `${typography.pxToRem(560)}`,
    },
    text: {
      color: '#00000099',
      '& > a': {
        color: '#4599bf',
      },
      '& > span': {
        color: '#4599bf',
      },
    },
  };
});
