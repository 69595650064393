import { Box, Divider, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { HomeActivityCard } from 'cards/home-activity-card';
import { HomeWidgetCard } from 'cards/home-widget-card';
import { StatusCard } from 'cards/status-card';
import { EmptyList } from 'components/empty-list';
import { Icon } from 'components/icon';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import type { UserTypes } from 'store/common-document.types';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  getUserActivityData,
  handleAddFavourite,
  handleRemoveFavourite,
} from 'store/qsistant/home/home-slice';
import type {
  ChangesTypes,
  CheckdocumentsTypes,
  ExpiringTypes,
  FacouritesTypes,
  HistoryTypes,
  OpenRequestsTypes,
} from 'store/qsistant/home/home.types';
import { dotDividerForDate, getTime, shortName, updatedDay } from 'utils/utils';
import { HomeInnerBox, HomeWidgetWrapper } from 'wrappers/home-widget-wrapper';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

export function HomePage({ pathName, parentTab, homePage }) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const today = new Date();
  const { t } = useTranslation(['home', 'status', 'common', 'snackbar']);
  const addSnack = useNotification();
  const localUser = localStorage.getItem('user');
  const parsedUserData: UserTypes = JSON.parse(localUser);

  const [approveTabs, setApproveTabs] = useState<number>(0);
  const [mainTabs, setMainTabs] = useState<number>(0);

  const {
    activity,
    changes,
    checkdocuments,
    status,
    openRequests,
    finishedRequests,
    history,
    favourites,
    expiringDocuments,
  } = useAppSelector((state) => ({
    activity: state.homeReducer.activity,
    changes: state.homeReducer.changes,
    checkdocuments: state.homeReducer.checkdocuments,
    favourites: state.homeReducer.favourites,
    finishedRequests: state.homeReducer.finishedrequest,
    history: state.homeReducer.history,
    openRequests: state.homeReducer.openrequests,
    status: state.homeReducer.status,
    expiringDocuments: state.homeReducer.expiringdocuments,
  }));

  useEffect(() => {
    dispatch(getUserActivityData());
  }, [dispatch]);

  function getWeek(date) {
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    const week1 = new Date(date.getFullYear(), 0, 4);
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  }

  const groupedActivities = activity.reduce((acc, active) => {
    const date = updatedDay(active.created);
    if (!acc[date]) {
      acc[date] = [active];
    } else {
      acc[date].push(active);
    }
    return acc;
  }, {});

  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;

  const greeting = (u) => {
    const hour = new Date().getHours();
    return `${
      hour > 0 && hour < 12
        ? `${t('home:goodmorning')} ${u?.prename}`
        : hour < 18
        ? `${t('home:goodafternoon')} ${u?.prename}`
        : `${t('home:goodevening')} ${u?.prename}`
    }`;
  };

  const getImageSrc = () => {
    const hour = new Date().getHours();
    return `${
      hour > 0 && hour < 12
        ? '/img/morning.png'
        : hour < 18
        ? '/img/afternoon.png'
        : '/img/evening.png'
    }`;
  };

  return (
    <PageWrapper
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      pageLabel={greeting(user)}
      dayTimeImgSrc={getImageSrc()}
    >
      {status === 'resolved' ? (
        <>
          <Typography component='h6' className='PageInfo'>
            {t('home:detailsfortheday', {
              date: today.toLocaleDateString('de-DE', {
                weekday: 'long',
              }),
              month: today.toLocaleDateString('de-DE', {
                day: 'numeric',
                month: 'long',
              }),
              week: getWeek(today),
            })}
          </Typography>
          <Box component='div' className={classes.changesBox}>
            {changes.map((e: ChangesTypes) => (
              <StatusCard
                key={e.title}
                cardInfo={{
                  amount: t(`home:${e.title}`, {
                    value: e.value,
                    value2: e.value2,
                    value3: e.value3,
                  }),
                  info: t(`home:${e.info}`, {
                    value: e.value,
                    value2: e.value2,
                    value3: e.value3,
                  }),
                  label: t(`home:${e.description}`, {
                    value: e.value,
                    value2: e.value2,
                    value3: e.value3,
                  }),
                }}
                colors={{
                  border:
                    e.change === 'higher'
                      ? '#4FB47E'
                      : e.change === 'lower'
                      ? '#D4BD35'
                      : e.change === 'highest'
                      ? '#4FB47E'
                      : e.change === 'lowest'
                      ? '#D4BD35'
                      : 'rgba(0, 0, 0, 0.12)',
                  background:
                    e.change === 'higher'
                      ? '#EDF8F2'
                      : e.change === 'lower'
                      ? '#D4BD3512'
                      : e.change === 'highest'
                      ? '#EDF8F2'
                      : e.change === 'lowest'
                      ? '#D4BD3512'
                      : '#fff',
                }}
                icon={{
                  name:
                    e.change === 'higher'
                      ? ICON.ARROW_NORTH_EAST
                      : e.change === 'lower'
                      ? ICON.ARROW_SOUTH_EAST
                      : e.change === 'highest'
                      ? ICON.ARROW_NORTH
                      : e.change === 'lowest'
                      ? ICON.ARROW_SOUTH
                      : e.change === 'same'
                      ? ICON.ARROW_EAST
                      : '',
                  fill:
                    e.change === 'higher'
                      ? '#4FB47E'
                      : e.change === 'lower'
                      ? '#D4BD35'
                      : e.change === 'highest'
                      ? '#4FB47E'
                      : e.change === 'lowest'
                      ? '#D4BD35'
                      : 'rgba(0, 0, 0, 0.12)',
                }}
              />
            ))}
          </Box>

          <Box component='div' className={classes.widgetsBox}>
            <HomeWidgetWrapper
              label={t('home:widgetcheckandrelease')}
              icon={{ name: ICON.LOCK_OPEN }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={approveTabs}
                  onChange={(e, val) => setApproveTabs(val)}
                  TabIndicatorProps={{ className: 'Qsistant' }}
                  className={classes.tabs}
                  variant='scrollable'
                  allowScrollButtonsMobile
                >
                  <Tab
                    value={0}
                    sx={{ textTransform: 'capitalize' }}
                    label={t('home:openrequeststablabel', {
                      count: checkdocuments.length,
                    })}
                  />
                  <Tab
                    value={1}
                    sx={{ textTransform: 'capitalize' }}
                    label={t('home:finishedrequeststablabel')}
                  />
                  <Tab
                    value={2}
                    sx={{ textTransform: 'capitalize' }}
                    label={t('home:myopenrequeststablabel', {
                      count: openRequests.length,
                    })}
                  />
                  <Tab
                    value={3}
                    sx={{ textTransform: 'capitalize' }}
                    label={t('home:expiringdocumentstablabel', {
                      count: expiringDocuments.length,
                    })}
                  />
                </Tabs>
              </Box>
              {approveTabs === 0 ? (
                <HomeInnerBox
                  disabled={!checkdocuments || checkdocuments.length <= 3}
                >
                  {checkdocuments.length > 0 ? (
                    checkdocuments.map((document: CheckdocumentsTypes) => (
                      <HomeWidgetCard
                        key={document.id}
                        documentId={document.id}
                        documentName={document.name}
                        elementType={document.elementType}
                        revision={`Revision ${document.revision}`}
                        documentNumber={document.documentNumber}
                        tooltip={t(`document:${document.elementType}`)}
                        status={{
                          status: document.status,
                          statusText: t(`status:${document.status}`),
                        }}
                        updatedDate={dotDividerForDate(
                          document.creator.updated
                        )}
                        userName={shortName(document.creator.fullName)}
                        user={document.creator}
                      />
                    ))
                  ) : (
                    <EmptyList
                      image={{ name: ICON.NO_DATA_BELL, height: 13 }}
                      title={t('home:checkdocumentsrequestlistemptytitle')}
                      description={t(
                        'home:checkdocumentsrequestlistemptydescription'
                      )}
                    />
                  )}
                </HomeInnerBox>
              ) : approveTabs === 1 ? (
                <HomeInnerBox
                  disabled={!finishedRequests || finishedRequests.length <= 3}
                >
                  <EmptyList
                    image={{ name: ICON.NO_DATA_BELL, height: 13 }}
                    title={t('home:finishedrequestlistemptytitle')}
                    description={t('home:finishedrequestlistemptydescription')}
                  />
                  {/* <HomeWidgetCard /> */}
                </HomeInnerBox>
              ) : approveTabs === 2 ? (
                <HomeInnerBox
                  disabled={!openRequests || openRequests.length <= 3}
                >
                  {openRequests.length > 0 ? (
                    openRequests.map((document: OpenRequestsTypes) => (
                      <HomeWidgetCard
                        key={document.id}
                        documentId={document.id}
                        documentName={document.name}
                        elementType={document.elementType}
                        revision={`Revision ${document.revision}`}
                        documentNumber={document.documentNumber}
                        tooltip={t(`document:${document.elementType}`)}
                        status={{
                          status: document.status,
                          statusText: t(`status:${document.status}`),
                        }}
                        updatedDate={dotDividerForDate(
                          document.creator.updated
                        )}
                        userName={shortName(document.creator.fullName)}
                        user={document.creator}
                      />
                    ))
                  ) : (
                    <EmptyList
                      image={{ name: ICON.NO_DATA_BELL, height: 13 }}
                      title={t('home:openrequestlistemptytitle')}
                      description={t('home:openrequestlistemptydescription')}
                    />
                  )}
                </HomeInnerBox>
              ) : (
                <HomeInnerBox
                  disabled={!expiringDocuments || expiringDocuments.length <= 3}
                >
                  {expiringDocuments.length > 0 ? (
                    expiringDocuments.map((document: ExpiringTypes) => (
                      <HomeWidgetCard
                        key={document.id}
                        documentId={document.id}
                        documentName={document.name}
                        elementType={document.elementType}
                        revision={`Revision ${document.revision}`}
                        documentNumber={document.documentNumber}
                        tooltip={t(`document:${document.elementType}`)}
                        status={{
                          status: document.status,
                          statusText: t(`status:${document.status}`),
                        }}
                        updatedDate={dotDividerForDate(
                          document.creator.updated
                        )}
                        userName={shortName(document.creator.fullName)}
                        user={document.creator}
                      />
                    ))
                  ) : (
                    <EmptyList
                      image={{ name: ICON.NO_DATA_BELL, height: 13 }}
                      title={t('home:expiringlistemptytitle')}
                      description={t('home:expiringlistemptydescription')}
                    />
                  )}
                </HomeInnerBox>
              )}
            </HomeWidgetWrapper>
            <HomeWidgetWrapper label='Mein Qsistant' avatar={parsedUserData}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={mainTabs}
                  onChange={(e, val) => setMainTabs(val)}
                  TabIndicatorProps={{ className: 'Qsistant' }}
                  className={classes.secondaryTabs}
                >
                  <Tab
                    value={0}
                    sx={{ textTransform: 'capitalize' }}
                    label={t('home:historytablabel')}
                  />
                  <Tab
                    value={1}
                    sx={{ textTransform: 'capitalize' }}
                    label={t('home:activitytablabel')}
                  />
                  <Tab
                    value={2}
                    sx={{ textTransform: 'capitalize' }}
                    label={t('home:favouritestablabel')}
                  />
                </Tabs>
              </Box>
              {mainTabs === 0 ? (
                <HomeInnerBox disabled={!history || history.length <= 4}>
                  {history.length > 0 ? (
                    history.map((document: HistoryTypes) => (
                      <HomeWidgetCard
                        key={document.id}
                        documentId={document.id}
                        documentName={document.name}
                        elementType={document.elementType}
                        revision={`Revision ${document.revision}`}
                        documentNumber={document.documentNumber}
                        tooltip={t(`document:${document.elementType}`)}
                        status={{
                          status: document.status,
                          statusText: t(`status:${document.status}`),
                        }}
                        updatedDate={dotDividerForDate(
                          document.creator.updated
                        )}
                        userName={shortName(document.creator.fullName)}
                        user={document.creator}
                      />
                    ))
                  ) : (
                    <EmptyList
                      image={{ name: ICON.NO_DATA_FILES, height: 13 }}
                      title={t('home:historylistemptytitle')}
                      description={t('home:historylistemptydescription')}
                    />
                  )}
                </HomeInnerBox>
              ) : mainTabs === 1 ? (
                <HomeInnerBox disabled={!activity || activity.length <= 4}>
                  {activity.length > 0 ? (
                    Object.entries(groupedActivities).map(([date]) => (
                      <React.Fragment key={date}>
                        <Typography component='p' className={classes.dayStyle}>
                          {date}
                        </Typography>
                        {activity.map((active) => (
                          <HomeActivityCard
                            key={active.id}
                            versionId={active.data.parameters?.version_id}
                            createdTime={getTime(active.created)}
                            messageKey={active.data.message}
                            message={t(
                              `activity:${active.data.message}`,
                              active.data.parameters
                            )}
                            text={t(
                              `activity:${active.data.message}_text`,
                              active.data.parameters
                            )}
                          />
                        ))}
                        <Divider className={classes.divider} />
                      </React.Fragment>
                    ))
                  ) : (
                    <EmptyList
                      image={{ name: ICON.NO_DATA_FILES, height: 13 }}
                      title={t('home:activitylistemptytitle')}
                      description={t('home:activitylistemptydescription')}
                    />
                  )}
                </HomeInnerBox>
              ) : (
                <HomeInnerBox disabled={!favourites || favourites.length <= 4}>
                  {favourites.length > 0 ? (
                    favourites.map((document: FacouritesTypes) => (
                      <HomeWidgetCard
                        key={document.id}
                        documentId={document.id}
                        actionItem={
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(handleRemoveFavourite(document.id));
                              addSnack({
                                type: 'snack',
                                text: t('snackbar:removefavouritesuccess', {
                                  document,
                                }),
                                anchor: {
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                },
                                button: {
                                  text: t('common:revert'),
                                  onClick: () =>
                                    dispatch(handleAddFavourite(document.id)),
                                },
                              });
                            }}
                          >
                            <Icon name={ICON.BOOKMARK_FILLED} />
                          </IconButton>
                        }
                        documentName={document.name}
                        elementType={document.elementType}
                        revision={`Revision ${document.revision}`}
                        documentNumber={document.documentNumber}
                        tooltip={t(`document:${document.elementType}`)}
                        status={{
                          status: document.status,
                          statusText: t(`status:${document.status}`),
                        }}
                        updatedDate={dotDividerForDate(
                          document?.creator?.updated
                        )}
                        userName={shortName(document?.creator?.fullName)}
                        user={document?.creator}
                      />
                    ))
                  ) : (
                    <EmptyList
                      image={{ name: ICON.NO_DATA_STARS, height: 13 }}
                      title={t('home:favouritlistemptytitle')}
                      description={t('home:favouritlistemptydescription')}
                    />
                  )}
                </HomeInnerBox>
              )}
            </HomeWidgetWrapper>
          </Box>
        </>
      ) : (
        [1, 2, 3, 4, 5, 6, 7, 88].map((item) => <TableSkeleton key={item} />)
      )}
    </PageWrapper>
  );
}
