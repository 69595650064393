import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes = 'cardsBox' | 'circularBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography }) => {
    return {
      cardsBox: {
        padding: '1rem',
        borderRadius: '.25rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        minHeight: 'calc(100vh - (6rem + 8.625rem))',
      },
      circularBox: {
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
  }
);
