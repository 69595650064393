import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { useStyles } from './styles';

export function StepManagementBoxComponent() {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.boxWrapper}>
      <Box component='div' className={classes.boxWrapper}>
        <Box component='div' className={classes.iconTitleBox}>
          <Typography component='p' className={classes.title}>
            Änderungsdokumente
          </Typography>
          <IconButton className={classes.iconButton}>
            <Icon name={ICON.OPEN_NEW_TAB} size={1} fill='black' />
          </IconButton>
        </Box>
        <Typography component='p' className={classes.description}>
          Ausgewählte Wiedervorlagedokumente und Dokumentenänderungen müssen
          freigegeben sein, um aktuelle vorliegendes Dokument endgültig
          freigeben zu können.
        </Typography>
      </Box>
      <Box component='div' className={classes.boxWrapper}>
        <Box component='div' className={classes.iconTitleBox}>
          <Typography component='p' className={classes.title}>
            Wiedervorlagedokument
          </Typography>
        </Box>
        <Tooltip title='HELLO'>
          <Box component='div' className={classes.documentNameBox}>
            <IconButton className={classes.statusIcon}>
              <Icon name={ICON.LOCK_OPEN} size={0.625} />
            </IconButton>
            <Typography component='p' className={classes.documentName}>
              Document name
            </Typography>
            <IconButton className={classes.iconButton}>
              <Icon name={ICON.OPEN_NEW_TAB} size={1} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Box component='div' className={classes.boxWrapper}>
        <Box component='div' className={classes.iconTitleBox}>
          <Typography component='p' className={classes.title}>
            Dokumentenänderungsdokument
          </Typography>
        </Box>
        <Tooltip title='WORlds'>
          <Box component='div' className={classes.documentNameBox}>
            <IconButton className={classes.statusIcon}>
              <Icon name={ICON.LOCK_OPEN} size={0.625} />
            </IconButton>
            <Typography component='p' className={classes.documentName}>
              CAPA-Bericht
            </Typography>
            <IconButton className={classes.iconButton}>
              <Icon name={ICON.OPEN_NEW_TAB} size={1} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
}
