import { TBG_OPTIONS } from 'enum/common.enum';
import { ICON } from 'enum/icons.enum';

export const documentFileList = [
  { id: 1, label: 'Bewertungskriterien', value: 'Bewertungskriterien' },
  { id: 2, label: 'Bewertungsstand', value: 'Bewertungsstand' },
  {
    id: 3,
    label: 'Zusätzliche, wichtige Informationen',
    value: 'Zusätzliche, wichtige Informationen',
  },
];

export const toggleOptions = [
  {
    id: 234,
    value: 'visibility',
    label: ICON.VISIBILITY_FILLED,
    disabled: false,
    tooltip: TBG_OPTIONS.VIEW_MODE,
  },
  {
    id: 1124,
    value: 'edit',
    label: ICON.EDIT,
    disabled: true,
    tooltip: TBG_OPTIONS.EDIT_MODE,
  },
  {
    id: 8924,
    value: 'compare',
    label: ICON.COMPARE,
    disabled: false,
    tooltip: TBG_OPTIONS.COMPARE_MODE,
  },
];

export const revisions = [
  {
    id: 1,
    label: 'Revision 0',
    value: 'Revision 0',
    document: 'DOC-00000',
    date: 'Freigegeben am 03.07.2020',
  },
  {
    id: 12,
    label: 'Revision 1',
    value: 'Revision 1',
    document: 'DOC-000001',
    date: 'Freigegeben am 01.01.2021',
  },
];

export const sortByOptions = [
  { id: 1, value: 'default', label: 'noselection' },
  { id: 2, value: 'alphabet ascending', label: 'alphabetascending' },
  { id: 3, value: 'alphabet descending', label: 'alphabetdescending' },
  {
    id: 4,
    value: 'completion date ascending',
    label: 'archivingdateascending',
  },
  {
    id: 42,
    value: 'completion date descending',
    label: 'archivingdatedescending',
  },
  {
    id: 429,
    value: 'number ascending',
    label: 'numberascending',
  },
  {
    id: 420,
    value: 'number descending',
    label: 'numberdescending',
  },
];
