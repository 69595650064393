import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'title'
  | 'boxWrapper'
  | 'mainBoxContent'
  | 'expandIcon'
  | 'itemText'
  | 'iconButton'
  | 'itemBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, colors, size }) => {
    return {
      title: {
        color: colors.black,
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        letterSpacing: `${typography.pxToRem(0.15)} !important`,
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
      boxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: `${typography.pxToRem(size.fontL)} !important`,
      },
      mainBoxContent: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
      },
      expandIcon: {
        transition: 'rotate 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        padding: '0 !important',
      },
      itemText: {
        color: colors.text.primary,
        fontSize: `${typography.pxToRem(size.fontS)} !important`,
        letterSpacing: `${typography.pxToRem(0.15)} !important`,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '15ch',
      },
      iconButton: {
        padding: `${typography.pxToRem(size.font_4)} !important`,
      },
      itemBox: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '3fr 4fr 3fr 1fr',
        padding: `${typography.pxToRem(size.font_4)} 0`,
      },
    };
  }
);
