import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    modal: {
      padding: `${typography.pxToRem(size.font24L)}`,
      width: `${typography.pxToRem(280)}`,
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(8)}`,
    },
    headerStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.009rem !important',
    },
    descriptionStyle: {
      color: 'rgba(0, 0, 0, 0.6)',
      letterSpacing: '0.015rem !important',
      lineHeight: `${typography.pxToRem(size.fontXL)} !important`,
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
    },
    btnsBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
});
