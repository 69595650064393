export const routes = () => ({
  // Qsistant
  qsistant: '/',
  home: '/home',
  map: '/map',
  organigramm: '/organization_chart',
  document: '/document',
  chart: '/chart',
  support: '/support',
  archive: '/archive',
  file: '/file',
  singleDocumentOverview: '/document/:id',
  singleSupportDocument: '/support/:id',
  singleArchiveDocument: '/archive/:id',
  singleHomeDocument: '/home/:id',
  singleHomeSupport: '/home/:id',
  // My profile settings
  profile: '/my_profile',
  login: '/login',
  search: '/search',
  viewDocument: '/document/view',
  // Administration
  companyProfile: '/management/company_profile',
  process: '/management/process',
  types: '/management/documenttypes',
  management: '/management/company_profile',
  members: '/management/members',
  departments: '/management/departments',
  customers: '/management/customers',
  changemanagement: '/management/changemanagement',
  temp: '/management/temp',
  // Onboarding
  bossInvitation: '/bossinvitation/:token',
  lowerPerson: '/useronboarding/:token',
  simpleUser: '/onboarding/:token',
  documentsSetup: '/documents_setup',
  // Product Dev
  productDevelopment: '/product_development/product',
  singleProduct: '/product_development/product/:name',
  // singleProduct: '/product_development/product/:id',
  documentOverview: '/product_development/document_overview',
  fileOverview: '/product_development/file_overview',
  fileArchive: '/product_development/file_archive',
});
