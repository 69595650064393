import { Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { ProcessMapTooltipProps } from './types';

export function ProcessMapTooltip({
  open,
  onClose,
  anchorEl,
  image,
  description,
}: ProcessMapTooltipProps) {
  const classes = useStyles();
  return (
    <ContextMenuWrapper
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className={classes.processMapModal}
    >
      <Typography component='h6' className={classes.caption}>
        {description}
      </Typography>
      <Icon name={image} height={18} width={30} />
    </ContextMenuWrapper>
  );
}
