import { Info } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { InviteSection } from 'components/invite-section';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { tooltipContent } from 'pages/onboarding/constants';

import React, { useRef, useState } from 'react';

import { TooltipInfo } from 'tooltips';

import { useStyles } from './styles';
import type { InvitationsProfileSettingsProps } from './types';

export function InvitationsProfileSettingsComponent({
  emailInvites,
  userRoles,
  addEmail,
  updateEmails,
  updateRoles,
  removeInviteSection,
  onNext,
  onBack,
}: InvitationsProfileSettingsProps) {
  const iconBtnRef = useRef(null);
  const classes = useStyles();

  const addNotification = useNotification();

  const [refLink, setRefLink] = useState<string>('');

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const copyToClipboard = () => {
    window.navigator.clipboard.writeText('REF_LINKK').then(() => {
      window.navigator.clipboard.readText().then((text) => {
        const value = Promise.resolve(text);
        value.then((resolvedValue) => {
          setRefLink(resolvedValue);
          addNotification({
            text: 'Einladungslink zum Teilen kopiert.',
          });
        });
      });
    });
  };

  return (
    <Box component='div'>
      <Box component='div' textAlign='center' mb='3rem'>
        <Typography component='h3' className={classes.onboardingFormTitle}>
          Teammitglieder einladen
        </Typography>
        <Typography component='p' className={classes.onboardingFormDescription}>
          Hole alles aus deinem QM-System heraus, indem du Mitarbeiter &
          Verantwortliche einlädst.
        </Typography>
      </Box>
      <Box component='div' className={classes.ipsMainContentBox}>
        <Box component='div' display='flex' justifyContent='flex-end'>
          <Button
            sx={{ visibility: 'hidden' }}
            variant='text'
            color='qsistant'
            startIcon={
              <Icon
                name={refLink ? ICON.DONE : ICON.COPY_LINK}
                size={1.5}
                fill='inherit'
              />
            }
            onClick={copyToClipboard}
          >
            EINLADUNGSLINK ZUM TEILEN
          </Button>
        </Box>
        <Box component='div' className={classes.ipsLinkLabelsBox}>
          <Typography component='h6' className={classes.emailRoleStyle}>
            E-Mail Einladungen
          </Typography>
          <Box component='div' className={classes.labelIconBox}>
            <Typography component='h6' className={classes.emailRoleStyle}>
              Rolle des Teammitgliedes
            </Typography>
            <IconButton
              onClick={() => {
                setShowTooltip((prev) => !prev);
                setAnchorEl(iconBtnRef);
              }}
              ref={iconBtnRef}
              className={classes.iconHover}
            >
              <Info
                sx={{
                  fill: ({ palette }) =>
                    showTooltip ? palette.qsistant.main : 'black',
                  '&:hover': { fill: 'inherit' },
                }}
              />
            </IconButton>
            {showTooltip && (
              <TooltipInfo
                title='Information Rollen'
                description='Die im folgenden erklärten Rollen wurden für die Einfachheit des QM-Systems von uns vorab festgelegt:'
                width='35rem'
                open={showTooltip}
                anchorEl={anchorEl}
                content={tooltipContent}
                onClose={() => setShowTooltip(false)}
              />
            )}
          </Box>
        </Box>
        {emailInvites.map((invite) => (
          <InviteSection
            key={invite.id}
            {...invite}
            updateEmails={updateEmails}
            userRoles={userRoles}
            updateRoles={updateRoles}
            removeInviteSection={removeInviteSection}
          />
        ))}
      </Box>
      <Box component='div' display='flex' justifyContent='flex-start'>
        <Button
          variant='text'
          color='qsistant'
          className={classes.furtherButtonStyle}
          startIcon={<Icon name='add' fill='inherit' size={1.5} />}
          onClick={addEmail}
        >
          WEITERE TEAMMITGLIEDER HINZUFÜGEN
        </Button>
      </Box>
      <Box component='div' className={classes.ipsButtonsBox}>
        <Button
          variant='text'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onBack}
        >
          ZURÜCK
        </Button>
        <Box component='div' className={classes.ipsNextBox}>
          <Button
            variant='contained'
            color='qsistant'
            className={classes.furtherButtonStyle}
            onClick={onNext}
          >
            WEITER
          </Button>
          <Button
            variant='text'
            color='qsistant'
            className={classes.furtherButtonStyle}
            onClick={onNext}
          >
            SCHRITT ÜBERSPRINGEN
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
