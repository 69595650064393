import { AppBar, Avatar, Box, Button, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { useStyles } from './styles';
import type { AppbarSecondaryProps } from './types';

export function AppbarSecondaryComponent({
  userName,
  userImage,
  tariffPlan,
  showAvatar = false,
  avatarColor,
  documentBadge = {
    name: 'waving_hand_filled',
    text: 'Eigenständige QM-System Erstellung',
  },
  badge = false,
  ...props
}: AppbarSecondaryProps) {
  const classes = useStyles();
  return (
    <AppBar position='fixed'>
      <Box component='div' display='flex' alignItems='center' gap='0.3rem'>
        <Icon name={ICON.QSISTANT_LOGO} />
        <Status
          statusText={tariffPlan}
          status={tariffPlan}
          style={{
            position: 'relative',
            cursor: 'pointer',
            textTransform: 'capitalize',
            fontSize: '.75rem',
            userSelect: 'none',
            height: '1.375rem',
            padding: '0 0.625rem',
          }}
        />
        {badge ? (
          <Box component='div' className={classes.documentBadgeBox}>
            <Icon
              name={documentBadge.name}
              fill={documentBadge.fill || '#3F97BE'}
              size={documentBadge.size || 1}
            />
            <Typography component='p' className={classes.documentBadge}>
              {documentBadge.text}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Box component='div'>
        {showAvatar ? (
          <Button
            variant='outlined'
            className={classes.secondaryUserBox}
            startIcon={
              <Avatar
                alt='user_img'
                src={userImage ? URL.createObjectURL(userImage) : null}
                sx={{ background: avatarColor }}
                className={classes.avatar}
              />
            }
          >
            {userName}
          </Button>
        ) : null}
      </Box>
    </AppBar>
  );
}
