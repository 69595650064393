import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    header: {
      fontWeight: `${typography.fontWeightMedium} !important`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(28)} !important`,
      lineHeight: '2rem !important',
    },
    label: {
      fontWeight: `${typography.fontWeightRegular} !important`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(20)} !important`,
    },
    planBox: {
      padding: '0.75rem',
      background: '#fff',
      border: '1.5px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '0.25rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.375rem',
    },
    plansBox: {
      display: 'flex',
      gap: '1.5rem',
      marginBottom: '2rem',
      alignItems: 'baseline',
    },
    planDetailsHeader: {
      fontWeight: `${typography.fontWeightMedium} !important`,
      color: '#00000',
      fontSize: `${typography.pxToRem(28)} !important`,
      lineHeight: `${typography.pxToRem(32)} !important`,
      background: '#fff',
      '& > span': {
        color: '#3F97BE',
        background: 'rgba(69, 153, 191, 0.08)',
        padding: '0.1875rem 0.5rem',
        borderRadius: '0.25rem',
      },
    },
    planDetailsText: {
      fontWeight: `${typography.fontWeightRegular} !important`,
      fontSize: `${typography.pxToRem(16)} !important`,
      color: 'rgba(0, 0, 0, 0.87)',
      '& > a': {
        color: '#3F97BE',
        textDecoration: 'underline',
      },
    },
    finalPageText: {
      fontWeight: `${typography.fontWeightRegular} !important`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(16)} !important`,
      letterSpacing: '0.009rem !important',
      textAlign: 'justify',
      '& > span': {
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
    },
    planDetailsCaption: {
      fontWeight: `${typography.fontWeightMedium} !important`,
      fontSize: `${typography.pxToRem(16)} !important`,
      color: 'rgba(0, 0, 0, 0.87)',
      lineHeight: '1.1875rem !important',
      '& > span': {
        color: '#3F97BE',
        background: 'rgba(69, 153, 191, 0.08)',
        padding: '0.1875rem 0.5rem',
        borderRadius: '0.25rem',
        cursor: 'pointer',
      },
    },
    planDetailsModal: {
      width: '38.75rem',
      padding: `${typography.pxToRem(24)}`,
    },
    upgradePlanModal: {
      width: '73rem',
      padding: `${typography.pxToRem(48)}`,
      textAlign: 'center',
    },
    finalModal: {
      width: '30rem',
      padding: `${typography.pxToRem(24)}`,
    },
    planCardsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '3rem',
    },
  };
});
