import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes = 'btnsBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography }) => {
    return {
      btnsBox: {
        background: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '0.25rem',
      },
    };
  }
);
