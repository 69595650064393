import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    modal: {
      minHeight: `${typography.pxToRem(600)}`,
      width: `${typography.pxToRem(600)}`,
    },
    supportModal: {
      minHeight: `${typography.pxToRem(536)}`,
      width: `${typography.pxToRem(600)}`,
    },
    contentBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: `${typography.pxToRem(32)}`,
      padding: `${typography.pxToRem(32)}`,
    },
    createButton: {
      padding: `${typography.pxToRem(19.2)} !important`,
      fontSize: `${typography.pxToRem(18)} !important`,
    },
    listWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paginationBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingTop: `${typography.pxToRem(4)}`,
    },
    counterText: {
      color: '#4599BF',
      letterSpacimg: '0.01rem !important',
    },
    filesListBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(8)}`,
      padding: '0 !important',
    },
    newDocumentForm: {
      padding: '1rem',
      borderRadius: '0.25rem',
      boxShadow:
        '0px -1px 1px 0px #E0D289, 0px 2px 2px 0px #E0D289, 0px 1px 5px 0px #E0D289',
    },
    dragDropBox: {
      width: '100%',
      cursor: 'pointer',
      border: '1px solid #E0E0E0',
      borderRadius: '0.25rem',
    },
  };
});
