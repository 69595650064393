import React from 'react';

import type { LordIconProps } from './lord-icon.types';

export function LordIconComponent({
  trigger,
  style,
  delay,
  src = 'tqywkdcz',
}: LordIconProps) {
  return (
    <lord-icon
      src={`https://cdn.lordicon.com/${src}.json`}
      trigger={trigger}
      style={style}
      delay={delay}
    />
  );
}
