import { Box, Button, Divider, InputAdornment, TextField } from '@mui/material';
import { FiltersBox, SortFilterBox } from 'components/common';
import { FilterDropdown } from 'components/filter-dropdown';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { SortSelect } from 'components/select/sort-select';
import { TableModal } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchDocuments } from 'store/qsistant/document-overview/document-overview-slice';
import { debounceFilterArray, multipleFiltersChange } from 'utils/utils';
import { ModalWrapper } from 'wrappers/modal-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useTableCells from './table-cell.hook';

export function ImportDocumentListModal({
  open,
  onClose,
  addedDocument,
  setAddedDocument,
  handleDocumentsSubmit,
}) {
  const { t } = useTranslation(['status', 'document']);
  const dispatch = useAppDispatch();

  const { documents, status } = useAppSelector((state) => ({
    documents: state.documentOverviewReducer.documents,
    status: state.documentOverviewReducer.status,
  }));

  const [filtered, setFiltered] = useState([]);
  const [sort, setSort] = useState('default');
  const [documentList, setDocumentList] = useState(['']);
  const [supportingList, setSupportingList] = useState(['']);
  const [dateList, setDateList] = useState(['']);
  const [query, setQuery] = useState('');

  const handleSelectItems = (item) => {
    const isExist = addedDocument.find((el) => el.id === item.id);
    console.log('item', item);
    if (!isExist) {
      setAddedDocument((prev) => [...prev, item]);
    } else {
      setAddedDocument((prev) => prev.filter((el) => el.id !== item.id));
    }
  };
  console.log('documents', documents);
  console.log('addedDocument', addedDocument);
  const { cells } = useTableCells(addedDocument);

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (query) {
      debounceFilterArray(documents, query, setFiltered);
    } else {
      setFiltered(documents);
    }
  }, [documents, query]);

  return (
    <ModalWrapper open={open} sx={{ width: '55rem' }}>
      <ModalHeader
        onClose={onClose}
        header='QSISTANT® Dateien hochladen/verknüpfen'
        primaryColor='product'
        icon={{ name: ICON.SEARCH_FILE }}
      />
      <Divider />
      <Box component='div' p='1.5rem'>
        <Box component='div' display='flex' justifyContent='center' mb='1.5rem'>
          <TextField
            color='product'
            placeholder='Suche Dokumente und Dateien in QSISTANT®'
            value={query}
            disabled={status === 'pending'}
            onChange={(e) => setQuery(e.target.value)}
            sx={{ width: '50%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Icon name={ICON.SEARCH} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <SortFilterBox>
          <FiltersBox>
            <FilterDropdown
              multiple
              disabled={status === 'pending'}
              primaryColor='product'
              value={documentList}
              onChange={(e) => multipleFiltersChange(e, setDocumentList)}
              options={[
                {
                  id: 1,
                  label: 'test',
                  value: 'test',
                },
              ]}
              filterLabel='Dokumente'
            />
            <FilterDropdown
              multiple
              disabled={status === 'pending'}
              primaryColor='product'
              value={supportingList}
              onChange={(e) => multipleFiltersChange(e, setSupportingList)}
              options={[
                {
                  id: 1,
                  label: 'support',
                  value: 'support',
                },
              ]}
              filterLabel='Nachweisdokumente'
            />
            <FilterDropdown
              multiple
              disabled={status === 'pending'}
              primaryColor='product'
              value={dateList}
              onChange={(e) => multipleFiltersChange(e, setDateList)}
              options={[
                {
                  id: 1,
                  label: 'date',
                  value: 'date',
                },
              ]}
              filterLabel='Dateien'
            />
          </FiltersBox>
          <SortSelect
            disabled={status === 'pending'}
            primaryColor='product'
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            options={[{ id: 1, value: 'default', label: 'kein Auswahl' }]}
          />
        </SortFilterBox>
        <Box component='div' mt='1.5rem'>
          {status === 'pending' ? (
            cells.map((cell) => <TableSkeleton key={cell.id} />)
          ) : (
            <TableModal
              cells={cells}
              data={filtered}
              onToggle={(item) => handleSelectItems(item)}
              selected={addedDocument}
              onReset={() => setAddedDocument([])}
            />
          )}
        </Box>
      </Box>
      <Divider />
      <Box component='div' display='flex' justifyContent='end' p='1.5rem'>
        <Button
          variant='text'
          color='product'
          onClick={onClose}
          disabled={status === 'pending'}
        >
          {t('product:abort')}
        </Button>
        <Button
          onClick={handleDocumentsSubmit}
          variant='contained'
          color='product'
          disabled={status === 'pending' || addedDocument.length === 0}
        >
          {t('product:upload')}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
