import { Box, IconButton, MenuItem, MenuList, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ModalActionProcess } from 'modals/process-action';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React, { useRef, useState } from 'react';

import type { ProcessBadgeComponentProps } from './process-badge.types';
import { useStyles } from './styles';

export function ProcessBadgeComponent({
  id,
  label,
  onRemoveProcess,
  parentId,
  options,
  category,
  onAction,
}: ProcessBadgeComponentProps) {
  const btnRef = useRef();
  const classes = useStyles();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [titleState, setTitleState] = useState<string>(label);
  const [categoryState, setCategoryState] = useState<string>(category);
  return (
    <Box component='div' className={classes.badgeWrapper}>
      <Typography component='p' className={classes.labelStyles}>
        {label}
      </Typography>
      <IconButton
        onClick={() => setShowMenu(true)}
        ref={btnRef}
        sx={{ padding: 0 }}
      >
        <Icon name='more' size={1} fill='rgba(0, 0, 0, 0.6)' />
      </IconButton>
      {showMenu ? (
        <ContextMenuWrapper
          open={showMenu}
          onClose={() => setShowMenu(false)}
          anchorEl={btnRef}
        >
          <MenuList className={classes.popperWrapper}>
            <MenuItem onClick={() => onRemoveProcess(parentId, id)}>
              <Icon name='delete' size={1.5} fill='rgba(0, 0, 0, 0.6)' />
              <Typography component='p' className={classes.iconText}>
                Entfernen
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => setIsEdit(true)}>
              <Icon name='edit' size={1.5} fill='rgba(0, 0, 0, 0.6)' />
              <Typography component='p' className={classes.iconText}>
                Bearbeiten
              </Typography>
            </MenuItem>
          </MenuList>
        </ContextMenuWrapper>
      ) : null}
      {isEdit ? (
        <ModalActionProcess
          disabled
          color='qsistant'
          icon={{ name: 'label' }}
          header='Prozess bearbeiten'
          open={isEdit}
          onClose={() => setIsEdit(false)}
          options={options}
          title={titleState}
          category={categoryState}
          onChangeTitle={(e) => setTitleState(e.target.value)}
          onChangeCategory={(e) => setCategoryState(e.target.value)}
          onAction={(title, parentCategory) => {
            onAction(title, parentCategory, id);
            setIsEdit(false);
          }}
        />
      ) : null}
    </Box>
  );
}
