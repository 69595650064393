import { Box, Button, Typography } from '@mui/material';
import { ProcessCategorieStore } from 'cards/process-categorie-card';
import { ModalActionProcess } from 'modals/process-action';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  handleCreateProcess,
  handleEditProcess,
  handleRemoveProcess,
} from 'store/user-profile/basic-user';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessMapTooltip } from 'tooltips';

import { useStyles } from './styles';

export function ProcessSetup({ onBack, onNext }) {
  const labelRef = useRef<HTMLElement>();
  const labelRef1 = useRef<HTMLElement>();
  const dispatcher = useAppDispatch();
  const classes = useStyles();

  const selectedCategories = useAppSelector(
    (state) => state.basicUserReducer.selectedCategories
  );

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showTooltip1, setShowTooltip1] = useState<boolean>(false);
  const [createProcess, setCreateProcess] = useState<boolean>(false);
  const [processTitle, setProcessTitle] = useState<string>('');
  const [processCategory, setProcessCategory] = useState<string>('');
  const { t } = useTranslation(['common', 'process']);

  const createNewProcess = (title: string, cat: string) => {
    dispatcher(handleCreateProcess({ title, cat }));
    setProcessTitle('');
    setProcessCategory('');
    setCreateProcess(false);
  };

  const removeProcess = (parentId: string, id: string) => {
    dispatcher(handleRemoveProcess({ parentId, id }));
  };

  const editProcess = (title: string, parentCat: string, id: string) =>
    dispatcher(handleEditProcess({ title, parentCat, id }));

  return (
    <Box component='div' textAlign='center' width='45%'>
      <Typography component='h3' className={classes.onboardingFormTitle}>
        Prozesse festlegen
      </Typography>
      <Typography component='h6' className={classes.processCatDescription}>
        Prozesse stellen die Ebene unter den Prozesskategorien dar. Mit Hilfe
        deiner bereits erstellten Prozesskategorien und den{' '}
        <span
          role='presentation'
          ref={labelRef}
          onClick={() => setShowTooltip(true)}
        >
          Prozessen
        </span>{' '}
        ist deine Qsistant{' '}
        <span
          role='presentation'
          ref={labelRef1}
          onClick={() => setShowTooltip1(true)}
        >
          Prozesslandkarte
        </span>{' '}
        dann komplett.
      </Typography>
      <Box
        component='div'
        textAlign='left'
        display='flex'
        flexDirection='column'
        gap='2rem'
        mt='2rem'
      >
        {selectedCategories.length > 0 &&
          selectedCategories.map((cat) => (
            <ProcessCategorieStore
              key={cat.id}
              onShow={() => setCreateProcess(true)}
              onRemoveProcess={removeProcess}
              options={selectedCategories}
              onAction={(title, parentCategory, id) =>
                editProcess(title, parentCategory, id)
              }
              {...cat}
            />
          ))}
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='space-around'
        margin='1.5rem 0'
      >
        <Button
          variant='text'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onBack}
        >
          zurück
        </Button>
        <Button
          variant='contained'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onNext}
        >
          Weiter
        </Button>
      </Box>
      {showTooltip ? (
        <ProcessMapTooltip
          anchorEl={labelRef}
          open={showTooltip}
          onClose={() => setShowTooltip(false)}
          image='img_process_map'
          description={
            <>
              Wo Prozesse später in Qsistant eingesetzt werden -{' '}
              <span>Die Prozesslandkarte:</span>
            </>
          }
        />
      ) : null}
      {showTooltip1 ? (
        <ProcessMapTooltip
          anchorEl={labelRef1}
          open={showTooltip1}
          onClose={() => setShowTooltip1(false)}
          image='png_square'
          description={
            <>
              Wo die Prozesskategorien später in Qsistant eingesetzt werden -{' '}
              <span>Die Prozesslandkarte:</span>
            </>
          }
        />
      ) : null}
      {createProcess ? (
        <ModalActionProcess
          header='Prozess hinzufügen'
          color='qsistant'
          icon={{ name: 'label' }}
          open={createProcess}
          onClose={() => setCreateProcess(false)}
          options={selectedCategories}
          title={processTitle}
          category={processCategory}
          onChangeTitle={(e) => setProcessTitle(e.target.value)}
          onChangeCategory={(e) => setProcessCategory(e.target.value)}
          onAction={createNewProcess}
        />
      ) : null}
    </Box>
  );
}
