import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'profileSetWrapper'
  | 'onboardingFormTitle'
  | 'onboardingFormDescription'
  | 'employeeLabelStyle'
  | 'furtherButtonStyle'
  | 'ipsButtonsBox'
  | 'ipsNextBox'
  | 'ipsMainContentBox'
  | 'ipsLinkLabelsBox'
  | 'emailRoleStyle'
  | 'qmsButtonsBox'
  | 'startCreateBox'
  | 'startCreateTitle'
  | 'startCreateDescription'
  | 'defineProcessWrapper'
  | 'defineProcessContentBox'
  | 'processCatDescription'
  | 'processCarCaption'
  | 'selectedCatsBox'
  | 'finalPageBox'
  | 'finalPageTitle'
  | 'finalPageDescription'
  | 'iconHover'
  | 'labelIconBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, palette }) => {
    return {
      profileSetWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '2.5rem',
      },
      onboardingFormTitle: {
        color: '#00000099',
        fontSize: `${typography.pxToRem(34)} !important`,
        fontWeight: `${typography.fontWeightMedium} !important`,
        letterSpacing: '0 !important',
      },
      onboardingFormDescription: {
        color: '#00000099',
        letterSpacing: '0.008rem !important',
      },
      employeeLabelStyle: {
        color: '#00000099',
        fontSize: '0.75rem !important',
        letterSpacing: '0.025rem !important',
        marginLeft: '1rem !important',
        marginBottom: '0.5rem !important',
      },
      furtherButtonStyle: {
        letterSpacing: '0.08rem !important',
      },
      ipsButtonsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: '1.5rem',
      },
      ipsNextBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-end',
      },
      ipsMainContentBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '1.5rem',
        marginBottom: '2rem',
      },
      ipsLinkLabelsBox: {
        display: 'grid',
        gridTemplateColumns: '1.5fr 1fr',
        alignItems: 'center',
        marginBottom: '-1.5rem',
      },
      emailRoleStyle: {
        fontWeight: `${typography.fontWeightMedium} !important`,
        color: 'black',
        letterSpacing: '0.008rem !important',
      },
      qmsButtonsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: '1.5rem',
      },
      startCreateBox: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        opacity: 1,
        padding: '2.5rem',
        width: '45rem',
        minHeight: '32rem',
        border: '0.25rem solid #FFFFFF40',
        boxShadow: '1rem 1rem 3rem #00000008',
        borderRadius: '3.3rem',
        backdropFilter: 'blur(1.625rem)',
        background: 'rgba(255,255,255,0.25)',
      },
      startCreateTitle: {
        fontSize: '2.125rem !important',
        color: '#000000DE',
        letterSpacing: '0 !important',
        fontWeight: `${typography.fontWeightMedium} !important`,
        marginBottom: '1rem !important',
      },
      startCreateDescription: {
        color: '#00000066',
        fontSize: '1.5rem !important',
        letterSpacing: '0.013rem !important',
      },
      defineProcessWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '0 1rem',
        height: '100%',
      },
      defineProcessContentBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      processCatDescription: {
        color: 'rgba(0, 0, 0, 0.6)',
        letterSpacing: '0.03rem !important',
        '& > span': {
          color: '#3F97BE',
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
      processCarCaption: {
        fontWeight: `${typography.fontWeightMedium} !important`,
        color: 'rgba(0, 0, 0, 0.87)',
        letterSpacing: '0.03rem !important',
      },
      selectedCatsBox: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },
      finalPageBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      finalPageTitle: {
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '2.125rem !important',
        lineHeight: '2.25rem !important',
      },
      finalPageDescription: {
        color: 'rgba(0, 0, 0, 0.6)',
        letterSpacing: '0.03rem !important',
        width: '65ch !important',
        margin: 'auto !important',
      },
      iconHover: {
        padding: '0 !important',
        '&:hover': {
          fill: palette.qsistant.main,
        },
      },
      labelIconBox: {
        display: 'flex',
        alignItems: 'center',
        padding: `${typography.pxToRem(size.font_8)} 0`,
        gap: typography.pxToRem(size.font_8),
      },
    };
  }
);
