// TODO Back end Response shouldnt be an empty object
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getChangemanagement,
  sendChangeDocument,
  sendExpiryDocument,
} from 'api/services/changemanagement.services';
import type {
  ChangemanagementType,
  DocumentType,
  RootState,
} from 'store/administration/changemanagement/types';

import {
  asyncThunkFetchDepartments,
  asyncThunkSaveChangeDocument,
  asyncThunkSaveExpiryDocument,
  initialState,
} from './state';

export const fetchChangemanagement = createAsyncThunk(
  'changemanagement/fetch',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getChangemanagement();
      dispatch(updateChangemanagement(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const handleUpdateChangeDocument = createAsyncThunk(
  'changemanagement/updateChangeDocument',
  async (data: DocumentType, { dispatch, rejectWithValue, getState }) => {
    const { data: documents } = (getState() as RootState)
      .changemanagementReducer;
    try {
      const response = await sendChangeDocument(data?.id);
      console.log('response is empty', response);
      dispatch(updateChangeDocument(data));
      const updatedDocuments = {
        ...documents,
        changeDocument: { ...documents.changeDocument, ...data },
      };

      return updatedDocuments;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const handleUpdateExpiryDocument = createAsyncThunk(
  'changemanagement/updateExpiryDocument',
  async (data: DocumentType, { dispatch, rejectWithValue, getState }) => {
    const { data: documents } = (getState() as RootState)
      .changemanagementReducer;
    try {
      const response = await sendExpiryDocument(data?.id);
      console.log('response is empty', response);
      dispatch(updateExpiryDocument(data));

      const updatedDocuments = {
        ...documents,
        changeDocument: { ...documents.expiryDocument, ...data },
      };

      return updatedDocuments;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const changemanagementSlice = createSlice({
  name: 'changemanagementSlice',
  initialState,
  reducers: {
    updateChangemanagement(state, action: PayloadAction<ChangemanagementType>) {
      const { documents, changeDocument, expiryDocument } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          documents,
          changeDocument,
          expiryDocument,
        },
      };
    },
    updateChangeDocument(state, action: PayloadAction<DocumentType>) {
      return {
        ...state,
        data: {
          ...state.data,
          changeDocument: action.payload,
        },
      };
    },
    updateExpiryDocument(state, action: PayloadAction<DocumentType>) {
      return {
        ...state,
        data: {
          ...state.data,
          expiryDocument: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    asyncThunkFetchDepartments(builder, fetchChangemanagement);
    asyncThunkSaveChangeDocument(builder, handleUpdateChangeDocument);
    asyncThunkSaveExpiryDocument(builder, handleUpdateExpiryDocument);
  },
});

export const {
  updateChangemanagement,
  updateExpiryDocument,
  updateChangeDocument,
} = changemanagementSlice.actions;

export default changemanagementSlice.reducer;
