import { Box, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { useStyles } from './styles';
import type { EmptyListComponentProps } from './types';

export function EmptyListComponent({
  title,
  image,
  description,
  primaryButton,
  secondaryButton,
}: EmptyListComponentProps) {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.wrapper}>
      <Icon
        name={image.name}
        height={image.height || 18}
        // style={{ marginBottom: '3.25rem', marginRight: '3rem' }}
      />
      <Box component='div' className={classes.textBox}>
        <Typography component='p' className={classes.title}>
          {title}
        </Typography>
        {description ? (
          <Box component='div' className={classes.descriptionBox}>
            <Typography component='p' className={classes.description}>
              {description}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Box component='div' className={classes.btnsBox}>
        {primaryButton || null}
        {secondaryButton || null}
      </Box>
    </Box>
  );
}
