import { Box, Typography } from '@mui/material';
import { CheckCard } from 'cards/check-card';

import React from 'react';

import { useStyles } from './styles';

export function StandardCertificationComponent({
  certification,
  updateFields,
}) {
  const classes = useStyles();
  return (
    <Box component='div'>
      <Box component='div' textAlign='center' mb='3.7rem'>
        <Typography component='h3' className={classes.title}>
          Angestrebte Normzertifizierung wählen
        </Typography>
        <Typography component='p' className={classes.description}>
          Wähle die Normen aus, für welche du in Zukunft eine Zertifizierung
          anstrebst.
        </Typography>
      </Box>
      <Box component='div' display='flex' gap='2rem' justifyContent='center'>
        <CheckCard
          checker='checkbox'
          color='#6e358c'
          value='DIN EN ISO 9001'
          fieldValue={certification}
          header='DIN EN ISO 9001'
          description='Die ISO 9001 legt die internationalen Anforderungen für Qualitätsmanagement-Systeme fest.'
          icon={{ name: 'ISO_1', size: 2.7 }}
          onClick={() =>
            updateFields({
              certification:
                certification === 'DIN EN ISO 9001' ? '' : 'DIN EN ISO 9001',
            })
          }
        />
        <CheckCard
          checker='checkbox'
          color='#6e358c'
          value='DIN EN ISO 13485'
          fieldValue={certification}
          header='DIN EN ISO 13485'
          description='Die ISO 9001 legt die internationalen Anforderungen für Qualitätsmanagement-Systeme fest.'
          icon={{ name: 'ISO_2', size: 2.7 }}
          // onClick={() =>
          //   updateFields({
          //     certification:
          //       certification === 'DIN EN ISO 13485'
          //         ? ''
          //         : 'DIN EN ISO 13485',
          //   })
          // }
        />
      </Box>
    </Box>
  );
}
