// Wie möchtest du die Produktentwicklungsphasen zum Produkt hinzufügen?
export const cardsData = [
  {
    img: 'from_scratch',
    title: 'Produktentwicklungsphasen nue erstellen',
    subtitle:
      'Erstelle neue Entwicklungsphasen für dein Produkt und stelle sie dir individuell und Schritt für Schritt zusammen.',
    btnText: 'PRODUKTPHASEN NEU ERSTELLEN',
  },
  {
    img: 'start_template',
    title: 'Vorlage für Produktentwicklungsphasen nutzen',
    subtitle:
      'Benutze eine schon erstellte Vorlage oder eine aus bereits bestehenden Produkten.',
    btnText: 'BESTEHENDE VORLAGE NUTZEN',
    btnIco: 'view_agenda_filled',
  },
];
