import { createTheme } from '@mui/material';

export const video = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  opacity: 0.2,
};

export const pageBox = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
};

export const contentBox = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  opacity: 1,
  padding: '2.5rem',
  minHeight: '34rem',
  border: '0.25rem solid #FFFFFF40',
  boxShadow: '1rem 1rem 3rem #00000008',
  borderRadius: '3.3rem',
  backdropFilter: 'blur(1.625rem)',
  background: 'rgba(255,255,255,0.25)',
};

export const sso = {
  '& > a': {
    color: '#000009',
    fontSize: '1rem',
    width: 'max-content',
    letterSpacing: '0.018rem',
    textTransform: 'capitalize',
    textDecoration: 'underline rgba(0, 0, 9, 0.44)',
    '&:hover': {
      textDecoration: 'underline rgba(0, 0, 9, 1)',
    },
  },
};

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.StageLabel': {
            color: '#000000DE',
            fontSize: '2.125rem',
            letterSpacing: 0,
          },
          '&.StageDescription': {
            color: '#00000066',
            fontSize: '1.5rem',
            letterSpacing: '0.013rem',
          },
          '&.Help': {
            color: '#00000099',
            letterSpacing: '0.018rem',
            '& > a': {
              textDecoration: 'underline',
              color: '#00000099',
              fontSize: '1rem',
              letterSpacing: '0.018rem',
              fontWeight: 400,
            },
          },
          '&.PasswordCriteriaLabel': {
            color: '#000000DE',
            letterSpacing: '0.016rem',
          },
          '&.VerifyWayLabel': {
            color: '#00000099',
            letterSpacing: '0.018rem',
            fontWeight: 500,
          },
          '&.VerifyWayDescription': {
            fontSize: '0.875rem',
            color: '#00000099',
            letterSpacing: '0.015rem',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.LoginTextfield': {
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#4599BF',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4599bf !important',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          '&.Register': {
            color: '#FFFFFF',
            fontSize: '0.875rem',
            width: '100%',
            height: '3.125rem',
            background: 'linear-gradient(45deg, #4599BF, #6E358C)',
            letterSpacing: '0.078rem',
            '&.Mui-disabled': {
              opacity: 0.44,
            },
          },
          '&.ForgotPassword': {
            color: '#00000099',
            fontSize: '1rem',
            width: 'max-content',
            letterSpacing: '0.018rem',
            textTransform: 'capitalize',
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
            },
          },
          '&.ResetPassword': {
            color: '#4599bf',
            fontSize: '0.875rem',
            width: 'max-content',
            letterSpacing: '0.018rem',
            textTransform: 'uppercase',
            '&.Mui-disabled': {
              opacity: 0.44,
            },
          },
          '&.Further': {
            background: '#4599bf',
            color: '#fff',
            fontSize: '0.875rem',
            letterSpacing: '0.078rem',
            textTransform: 'uppercase',
            '&.Mui-disabled': {
              opacity: 0.44,
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.StrengthLabel': {
            fontSize: '0.875rem',
            color: '#7F7F7F',
            letterSpacing: '0.015rem',
            fontWeight: 400,
            padding: '0.5rem 0 0.5rem 0.5rem',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.MuiRadio-root.Mui-checked': {
            color: '#4599BF',
          },
        },
      },
    },
  },
});
