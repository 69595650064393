import { Box, Button, ListItem, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React, { useState } from 'react';

import {
  actualStyle,
  idealStyle,
  labelStyle,
  priceStyle,
  privLabelStyle,
  useStyles,
  validStyle,
} from './styles';
import { UpgradePlanCardProps } from './upgrade-plan.card.types';

export function UpgradePlanCard({
  icon,
  label,
  active,
  privilege,
  price,
  ideal,
  valid,
  onClick,
}: UpgradePlanCardProps) {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      component='div'
      onMouseEnter={() => (!active ? setHovered(true) : undefined)}
      onMouseLeave={() => (!active ? setHovered(false) : undefined)}
      className={classes.cardStyle}
      sx={{
        opacity: active ? 0.54 : 1,
        '&:hover': {
          transform: active ? 'scale(1, 1)' : 'scale(1.1, 1.1)',
          border: active ? '2px solid #fff' : '2px solid #4499BD',
          borderTop: active ? '6px solid #fff' : '6px solid #4499BD',
          cursor: active ? 'auto' : 'pointer',
          pointerEvents: active ? 'none' : 'all',
        },
      }}
    >
      <Box component='div' display='flex' flexDirection='column' gap='1.25rem'>
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Icon name={icon.basic} fill='white' />
          <Typography
            component='h6'
            sx={{ ...labelStyle, color: hovered ? '#4499BD' : '#000000' }}
          >
            {label}
          </Typography>
        </Box>
        <Box component='div' textAlign='center'>
          <Typography component='p' sx={{ ...idealStyle }}>
            {ideal}
          </Typography>
          <Typography component='p' sx={{ ...validStyle }}>
            {valid}
          </Typography>
        </Box>
        <Box component='div' textAlign='center'>
          <Typography
            component='p'
            sx={{ ...priceStyle, color: hovered ? '#4499BD' : '#404040' }}
          >
            € <span>{`${price}`}</span>{' '}
            <span style={{ fontSize: '0.875rem' }}>/mo</span>
          </Typography>
        </Box>
        <Box component='div'>
          {privilege.map((priv) => (
            <ListItem key={priv.id} className={classes.listStyle}>
              <Icon
                name='check_circle'
                size={1.25}
                fill={hovered ? '#4499BD' : '#00000099'}
              />
              <Typography component='p' sx={{ ...privLabelStyle }}>
                {priv.label}
              </Typography>
            </ListItem>
          ))}
        </Box>
      </Box>
      <Box component='div'>
        {active ? (
          <Typography component='h6' sx={{ ...actualStyle }}>
            Aktuelles paket
          </Typography>
        ) : (
          <Button
            variant='contained'
            onClick={onClick}
            color='qsistant'
            className={classes.buttonStyle}
          >
            Jetzt starten
          </Button>
        )}
      </Box>
    </Box>
  );
}
