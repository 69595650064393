import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { Avatar, AvatarGroup, Box, Grid } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Icon } from 'components/icon';
import LongMenu from 'components/long-menu/LongMenu';
import { productHeaderCell } from 'components/product-phase-dataset/theme';
import { setSelectedDatasetId } from 'store/product/current-product-slice';

import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {},
  '&:before': {
    display: 'none',
  },
  border: '2px solid transparent',
  borderRadius: '0.5rem',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{ pl: '1.0625rem' }}
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: '1rem',
          transform: 'rotate(90deg)',
          m: '0.4375rem',
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  borderTopLeftRadius: '0.35rem',
  borderTopRightRadius: '0.35rem',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingRight: theme.spacing(2),
  borderTop: '2px solid #45BD8199',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
  },
}));

export function PhaseDatasetComponent({ expanded, setExpanded }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const phases = useSelector((state) => state.currentProductReducer.phases);
  const parentId = useSelector(
    (state) => state.currentProductReducer.selectedPhaseId
  );
  const index = phases.findIndex((p) => p.id === parentId);

  const rows = useSelector(
    (state) => state.currentProductReducer.phases[index].datasets
  );
  useEffect(() => {
    setExpanded(`panel${rows.length}`);
  }, [rows.length]);

  const handleChange = (panel, id) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    dispatch(setSelectedDatasetId(id));
  };
  console.log('rows', rows);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        sx={{ mb: '1rem', mt: '1.5rem' }}
        container
        spacing={0}
        wrap='nowrap'
        className={classes.root}
      >
        <Grid item xs={0.43} sx={{ ...productHeaderCell }} />
        <Grid item xs={1.5} sx={{ ...productHeaderCell, ml: '1.0625rem' }}>
          STATUS
        </Grid>
        <Grid item xs={2.42} sx={{ ...productHeaderCell }}>
          BEZEICHNUNG
        </Grid>
        <Grid item xs={1.73} sx={{ ...productHeaderCell }}>
          NUMMER
        </Grid>
        <Grid item xs={2.26} sx={{ ...productHeaderCell }}>
          VERANTWORTLICH
        </Grid>
        <Grid item xs={3.52} sx={{ ...productHeaderCell }}>
          GEÄNDERT
        </Grid>
      </Grid>
      {rows.map((row, i) => (
        <Accordion
          key={i} // eslint-disable-line react/no-array-index-key
          sx={{
            borderRadius: '0.5rem',
            mb: '1rem',
            border: 2,
            borderColor: expanded === `panel${i + 1}` ? '#45BD8199' : '#E6E6E6',
          }}
          expanded={expanded === `panel${i + 1}`}
          onChange={handleChange(`panel${i + 1}`, row.id)}
        >
          <AccordionSummary
            aria-controls={`panel${i + 1}d-content`}
            id={`panel${i + 1}d-header`}
          >
            <Grid
              item
              xs={12}
              container
              spacing={0}
              wrap='nowrap'
              className={classes.root}
              sx={{
                minHeight: '4.625rem',
              }}
            >
              <Grid
                item
                xs={1.63}
                sx={{ ...productHeaderCell, ml: '1.0625rem' }}
              >
                {/* <Status> {row.status}</Status> */}
                <Box
                  sx={{
                    borderRadius: '4px',
                    p: '0.5625rem 0.75rem',
                    color:
                      row.status === 'ENTWURF'
                        ? '#00000099'
                        : row.status === 'IN PRÜFUNG'
                        ? '#FF6961'
                        : row.status === 'FREIGEGEBEN'
                        ? '#5CB85C'
                        : '#00000099',
                    background:
                      row.status === 'ENTWURF'
                        ? '#0000000A'
                        : row.status === 'IN PRÜFUNG'
                        ? '#FF69611F'
                        : row.status === 'FREIGEGEBEN'
                        ? '#5CB85C1F'
                        : '#0000000A',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textTransform: 'uppercase',
                    whiteSpace: 'nowrap',
                    fontWeight: '500',
                  }}
                >
                  {row.status}
                </Box>
              </Grid>
              <Grid item xs={2.54} sx={{ ...productHeaderCell }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <SnippetFolderIcon
                    sx={{
                      color: '#41BDB1',
                    }}
                  />
                  <Typography className='ProductCell'>
                    {`${index + 1}.${i + 1} `}
                    {row.dataset}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1.855} sx={{ ...productHeaderCell }}>
                <Typography className='ProductCell'>
                  {row.revisionNumber}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2.3}
                sx={{ ...productHeaderCell }}
                style={{ flexShrink: '3' }}
              >
                <AvatarGroup
                  sx={{ display: 'flex', flexDirection: 'row', m: '0' }}
                  max={4}
                >
                  {row.responsible.map((option) => (
                    <Avatar
                      key={option.id}
                      sx={{
                        mr: '-0.8rem',
                        width: '2rem',
                        height: '2rem',
                        background: '#25583F',
                      }}
                      alt='avatar'
                    >
                      {option.label[0]}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </Grid>
              <Grid
                item
                xs={3.665}
                sx={{
                  ...productHeaderCell,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '0.625rem',
                    alignItems: 'center',
                  }}
                >
                  <Box />
                  <Avatar
                    // className='DatasetAvatar'
                    sx={{
                      width: '2.5rem',
                      height: '2.5rem',
                      border: '2px solid #fff',
                    }}
                    src='/img/1.jpg'
                    alt='sdsa'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      alignItems: 'flex-start',
                      mr: 'auto',
                    }}
                  >
                    <Typography className='ProductCell'>
                      {row.personModifedDoc}
                    </Typography>
                    <Typography className='InfoSmall'>{`${new Date()
                      .toLocaleDateString()
                      .replace(/\//g, '.')}`}</Typography>
                  </Box>
                </Box>
                <LongMenu />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: '#F7F7F7',
              minHeight: '4.625rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {row.documents?.length ? (
              <>
                {row.documents.map((docs) => (
                  <Grid
                    key={rows.id}
                    sx={{
                      minHeight: '4.625rem',
                    }}
                    container
                    item
                    xs={12}
                    spacing={0}
                    wrap='nowrap'
                    className={classes.root}
                  >
                    <Grid
                      item
                      xs={2.04}
                      sx={{ ...productHeaderCell, ml: '1.0625rem' }}
                      style={{
                        flexBasis: '17.083333%',
                      }}
                    />
                    <Grid item xs={2.51} sx={{ ...productHeaderCell }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <Icon name='new_qs_icon' size='1.4' />
                        <Typography className='ProductCell'>
                          {`${index + 1}.${i + 1} `}
                          {docs.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={1.87} sx={{ ...productHeaderCell }}>
                      <Typography className='ProductCell'>
                        Revision {docs.revision}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2.35}
                      sx={{ ...productHeaderCell }}
                      style={{ flexShrink: '3' }}
                    />
                    <Grid
                      sx={{
                        ...productHeaderCell,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      item
                      xs={3.665}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '0.625rem',
                          alignItems: 'center',
                        }}
                      >
                        <Box />
                        <Avatar
                          sx={{
                            width: '36px',
                            height: '36px',
                            border: '2px solid #fff',
                            background: '#25583F',
                          }}
                          src='/img/1.jpg'
                          alt='sdsa'
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            alignItems: 'flex-start',
                            mr: 'auto',
                          }}
                        >
                          <Typography className='ProductCell'>
                            {docs.creator.fullName}
                          </Typography>
                          <Typography className='InfoSmall'>{`${docs.updated.substring(
                            8,
                            10
                          )}.${docs.updated.substring(
                            5,
                            7
                          )}.${docs.updated.substring(0, 4)}`}</Typography>
                        </Box>
                      </Box>
                      <LongMenu />
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <Typography>
                Noch keine Dokumente und Inhalte vorhanden.
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
