import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    contentBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(10)} !important`,
    },
    title: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(16)} !important`,
      letterSpacing: '0.009rem !important',
    },
    description: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: `${typography.pxToRem(14)} !important`,
      letterSpacing: '0.015rem !important',
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
});
