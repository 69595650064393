import { ICON } from 'enum/icons.enum';

export const features = [
  {
    id: 1,
    info: 'Verbesserte Dokumenten-vorschau und Kopfzeile',
    desc: 'Übersichtlichere Dokumenten- und Nachweisvorschau mit neuem Design & Funktionen.',
    icon: '📄',
    image: ICON.NEW_FEATURE,
  },
  {
    id: 2,
    info: 'Verbesserungen an Suche & Freigabeworkflow',
    desc: 'Nachweise können jetzt von mehreren Personen bearbeitet und freigegeben werden.',
    icon: '🔍',
    image: ICON.NEW_FEATURE1,
  },
  {
    id: 3,
    info: 'Neue Listenansichten mit Filter- und Sortierfunktionen',
    desc: 'Dokumentenübersicht mit neuer Filterfunktion zur verbesserten Übersicht & Navigation.',
    icon: '📑',
    image: ICON.NEW_FEATURE2,
  },
  {
    id: 4,
    info: 'Schnellere Ladezeiten & Performanceverbesserungen',
    desc: 'Die neue Struktur ermöglicht eine deutlich schnellere Performance mit weniger Ladezeiten.',
    icon: '🏃‍♂️',
    image: ICON.NEW_FEATURE3,
  },
];
