import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';

import { InputPassword } from '../custom-input';
import { Icon } from '../icon';
import { contentBox } from './theme';

function PasswordStrength({ value, onChange, onChangeStage }) {
  const [length, setLength] = useState(false);
  const [extra, setExtra] = useState(false);
  const [mincase, setMinCase] = useState(false);
  const [numbers, setNumbers] = useState(false);

  const checkPassword = () => {
    if (value.password?.length >= 8) setLength(true);
    else setLength(false);

    const pattern = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
    if (pattern.test(value.password)) setMinCase(true);
    else setMinCase(false);

    const pattern2 = /^(?=.*[0-9]).+$/;
    if (pattern2.test(value.password)) setNumbers(true);
    else setNumbers(false);

    const pattern3 = /^(?=.*[-+_!@#$%^&*., ?]).+$/;
    if (pattern3.test(value.password)) setExtra(true);
    else setExtra(false);
  };

  useEffect(() => {
    checkPassword();
  }, [value.password]);

  return (
    <Box component='div' sx={{ ...contentBox, width: '38rem' }}>
      <Box
        component='div'
        width='100%'
        display='flex'
        justifyContent='flex-end'
      >
        <IconButton onClick={() => onChangeStage('login')}>
          <Icon name='close' size={2.5} />
        </IconButton>
      </Box>
      <Icon name='new_logo' height={4.75} width={22.5} />
      <Box
        component='form'
        display='flex'
        flexDirection='column'
        textAlign='center'
        alignItems='center'
        gap='1.5rem'
        width='100%'
        marginBottom='2.5rem'
      >
        <Typography className='StageLabel' component='h4'>
          Passwort zurücksetzen
        </Typography>
        <InputPassword
          fullWidth
          name='create_new_password'
          label='Neues Passwort'
          variant='outlined'
          value={value.password}
          onChange={(e) => onChange({ ...value, password: e.target.value })}
          className='LoginTextfield'
        />
        <Box component='div' width='100%' textAlign='left'>
          <Typography component='p' className='PasswordCriteriaLabel'>
            Stärke des neuen Passworts:
          </Typography>
          <List
            sx={{
              display: 'grid',
              gridTemplateColumns: '1.2fr 2fr',
            }}
          >
            <Box component='div' display='flex' alignItems='center'>
              <Box
                component='div'
                sx={{
                  width: '8px',
                  background: length ? 'green' : 'red',
                  height: '8px',
                  borderRadius: '50%',
                }}
              />
              <ListItem
                className='StrengthLabel'
                sx={{ textDecoration: length ? 'line-through' : 'none' }}
              >
                Mindestens 8 Zeichen
              </ListItem>
            </Box>
            <Box component='div' display='flex' alignItems='center'>
              <Box
                component='div'
                sx={{
                  width: '8px',
                  background: mincase ? 'green' : 'red',
                  height: '8px',
                  borderRadius: '50%',
                }}
              />
              <ListItem
                className='StrengthLabel'
                sx={{ textDecoration: mincase ? 'line-through' : 'none' }}
              >
                Mindestens einen Groß-und Kleinbuchstaben
              </ListItem>
            </Box>
            <Box component='div' display='flex' alignItems='center'>
              <Box
                component='div'
                sx={{
                  width: '8px',
                  background: numbers ? 'green' : 'red',
                  height: '8px',
                  borderRadius: '50%',
                }}
              />
              <ListItem
                className='StrengthLabel'
                sx={{ textDecoration: numbers ? 'line-through' : 'none' }}
              >
                Mindestens eine Zahl
              </ListItem>
            </Box>
            <Box component='div' display='flex' alignItems='center'>
              <Box
                component='div'
                sx={{
                  width: '8px',
                  background: extra ? 'green' : 'red',
                  height: '8px',
                  borderRadius: '50%',
                }}
              />
              <ListItem
                className='StrengthLabel'
                sx={{ textDecoration: extra ? 'line-through' : 'none' }}
              >
                Mindestens ein Sonderzeichen
              </ListItem>
            </Box>
          </List>
        </Box>
        <InputPassword
          fullWidth
          name='confirm_new_password'
          variant='outlined'
          label='Neues Passwort bestätigen'
          className='LoginTextfield'
          value={value.confirmPassword}
          onChange={(e) =>
            onChange({ ...value, confirmPassword: e.target.value })
          }
        />
      </Box>
      <Box component='div'>
        <Button
          variant='text'
          className='ResetPassword'
          disabled={
            value.password !== value.confirmPassword ||
            !length ||
            !mincase ||
            !extra ||
            !numbers
          }
        >
          PASSWORT BESTÄTIGEN
        </Button>
      </Box>
    </Box>
  );
}

export default PasswordStrength;
