import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes = 'pageWrapper';

export const useStyles = makeStyles<Theme, object, Classes>(({ colors }) => {
  return {
    pageWrapper: {
      background: colors.gray.background,
      minHeight: '100vh',
      display: 'grid',
      placeItems: 'center',
    },
  };
});
