import { Box, FormControlLabel, Switch, TextField } from '@mui/material';
import { DefaultModal } from 'modals/default/default.modal';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../styles';
import type { DocumentTypeModalProps } from './types';

export function DocumentTypeModal({
  onClose,
  open,
  handleSuccess,
  document = null,
  icon,
}: DocumentTypeModalProps) {
  const { t } = useTranslation('documenttype');
  const classes = useStyles();

  const [name, setName] = useState(document?.name ?? '');
  const [description, setDescription] = useState(document?.description ?? '');
  const [fillable, setFillable] = useState(
    document ? !!document.fillable : false
  );
  const [id] = useState(document ? document.id : 0);

  useEffect(() => {
    setName(document?.name ?? '');
    setDescription(document?.description ?? '');
    setFillable(document ? !!document.fillable : false);
  }, [document]);

  const handleSubmit = () => {
    handleSuccess({
      name,
      fillable,
      description,
      id,
    });
    if (!document) {
      setName('');
      setDescription('');
    }
  };

  return (
    <DefaultModal
      closeModal={onClose}
      successLabel={id === 0 ? t('modal:success') : t('documenttype:save')}
      successColor='management'
      handleSuccess={handleSubmit}
      modal={open}
      disabled={name === '' || name.length < 3}
      modalTitle={
        id === 0 ? t('documenttype:newtype') : t('documenttype:edittype')
      }
      icon={icon}
    >
      <Box className={classes.belowLineBox} component='div'>
        <TextField
          color='management'
          variant='outlined'
          autoComplete='off'
          label={t('documenttype:typename')}
          type='text'
          autoFocus
          value={name || ''}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          color='management'
          variant='outlined'
          autoComplete='off'
          label={t('documenttype:typedescription')}
          type='text'
          value={description || ''}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={
            <Switch
              checked={fillable}
              color='management'
              onChange={() => setFillable(!fillable)}
            />
          }
          label={t('documenttype:fillable')}
        />
      </Box>
    </DefaultModal>
  );
}
