import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React, { useEffect } from 'react';

import { MyBtn, MyTypo, chipBox } from './theme';

export function PhasesItem({
  input,
  onChange,
  onKeyDown,
  onFocus,
  inputRef,
  isSelected: selected,
  onDelete,
  edit,
  index,
  setReviewModal,
  handleAddData,
}) {
  useEffect(() => {
    if (selected && inputRef.current) {
      inputRef.current.focus();
    }
  }, [selected, inputRef]);

  const fill = input?.datasets?.filter(
    (i) => i.status === 'FREIGEGEBEN'
  ).length;

  return (
    <Box sx={{ opacity: selected ? '1' : '0.54' }}>
      <Box
        sx={{
          position: 'relative',
          minHeight: '21.125rem',
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.75rem',
        }}
      >
        {!edit && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {input.filled === 100 &&
            !input.released &&
            input.status === 'FREIGEGEBEN' ? (
              <Button
                type='submit'
                onClick={() => setReviewModal(true)}
                startIcon={<Icon name='lock_open' fill='#fff' size='1.5' />}
                className='ProductPhasesButton'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: '-0.375rem',
                  right: 0,
                }}
              >
                <Typography
                  className='TextPhasesBtn'
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  FREIGABE ANFORDERN
                </Typography>
              </Button>
            ) : input.filled === 100 &&
              input.released &&
              input.status === 'IN PRÜFUNG' ? (
              <Button
                type='submit'
                onClick={handleAddData}
                startIcon={<Icon name='lock_open' fill='#fff' size='1.5' />}
                className='ProductPhasesButton'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  right: 0,
                  fontSize: '0.875rem',
                  color: '#fff',
                  letterSpacing: '0.078rem',
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography
                  className='TextPhasesBtn'
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  FREIGABE
                </Typography>
              </Button>
            ) : input.filled === 100 &&
              input.released &&
              input.status === 'FREIGEGEBEN' ? (
              <Box
                sx={{
                  ...chipBox,
                  fontWeight: '500',
                  m: '0 auto',
                  width: 'auto',
                  height: '36px',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  justifyContent: 'center',
                  ml: '0',
                  mr: '0',
                  background:
                    input.filled === 100
                      ? '#5CB85C1F'
                      : input.filled > 0
                      ? '#D4BD351F'
                      : '#0000000A',
                  color:
                    input.filled === 100
                      ? '#5CB85C'
                      : input.filled > 0
                      ? '#D4BD35'
                      : '#00000099',
                }}
              >
                {input.filled === 100 && input.released
                  ? 'FREIGEGEBEN'
                  : input.status}
              </Box>
            ) : (
              <Box
                sx={{
                  ...chipBox,
                  fontWeight: '500',
                  m: '0 auto',
                  width: 'auto',
                  height: '36px',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  justifyContent: 'center',
                  ml: '0',
                  mr: '0',
                  background:
                    input.filled === 100
                      ? '#5CB85C1F'
                      : input.filled > 0
                      ? '#D4BD351F'
                      : '#0000000A',
                  color:
                    input.filled === 100
                      ? '#5CB85C'
                      : input.filled > 0
                      ? '#D4BD35'
                      : '#00000099',
                }}
              >
                {input.filled === 100
                  ? 'FREIGEGEBEN'
                  : input.filled > 0
                  ? 'IN BEARBEITUNG'
                  : 'ENTWURF'}
              </Box>
            )}
          </Box>
        )}
        <Box
          sx={{
            width: '12.625rem',
            flexShrink: 0,
            textAlign: 'left',
          }}
          onClick={() => onFocus(input.id)}
        >
          <Box
            sx={{
              borderBottom: '0.5rem solid #45BD81',
              backgroundColor: 'RGBA(69,189,129,0.24)',
              height: '18.125rem',
              display: 'flex',
              justifyContent: edit ? 'center' : 'space-between',
              alignItems: 'center',
              flexDirection: 'column-reverse',
              position: 'relative',
              cursor: 'pointer',
              borderColor: '#45BD81',
            }}
          >
            {!edit && (
              <Box
                sx={{
                  height: `${input.filled}%`,
                  width: '12.625rem',
                  backgroundColor: '#45BD81',
                }}
              />
            )}
            {!edit && (
              <Box sx={{ p: '0 0.875rem', textAlign: 'center' }}>
                <Typography
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 500,
                    wordBreak: 'break-all',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '20px',
                    left: 0,
                    right: 0,
                  }}
                >
                  {index + 1} {input.name}
                </Typography>
              </Box>
            )}
            {edit && (
              <MyBtn selected={selected}>
                <IconButton
                  sx={{ cursor: !selected ? 'default' : 'pointer' }}
                  aria-label='Delete'
                  onClick={() => onDelete(input.id)}
                  disabled={!selected}
                >
                  <Icon
                    className='ico'
                    component='img'
                    varian='img'
                    type='button'
                    fill='#45BD81'
                    name='delete'
                    size='3'
                  />
                </IconButton>
              </MyBtn>
            )}
            <MyTypo>
              {fill}/{input?.datasets?.length ? input?.datasets?.length : 0}
            </MyTypo>
          </Box>
          {edit && (
            <TextField
              fullWidth
              variant='filled'
              label={`Phasenname ${index + 1}`}
              color='product'
              value={input.value}
              onChange={(event) => onChange(input.id, event.target.value)}
              onKeyDown={onKeyDown}
              inputRef={inputRef}
              autoFocus={selected}
              sx={{ mt: '1.1875rem' }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
