import { Box, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { isEmpty } from 'lodash';

import { useStyles } from './styles';
import type { StructureStepComponentProps } from './types';

export function StructureStepComponent({
  id,
  label,
  index,
  onClick,
  structureStepIndex,
  selectedOption,
}: StructureStepComponentProps) {
  const classes = useStyles();
  return (
    <Box
      component='div'
      className={classes.structureStepBox}
      sx={{
        background: id === structureStepIndex ? '#6E358C1F' : '#ECECEC',
        borderColor: id === structureStepIndex ? '#6E358C' : '',
      }}
      onClick={() => onClick(index)}
    >
      <Typography
        component='p'
        className={classes.structureStepTitle}
        sx={{
          color: id === structureStepIndex ? '#6E358C' : '#00000099',
        }}
      >
        {`Frage ${id + 1}: ${label}`}
      </Typography>
      <Icon
        name='check_circle'
        size={1.25}
        fill='#00000099'
        style={{
          visibility: !isEmpty(selectedOption) ? 'visible' : 'hidden',
        }}
      />
    </Box>
  );
}
