import { Box, IconButton, Typography } from '@mui/material';
import { LordIcon } from 'components/lord-icon';

import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../icon';
import { contentBox } from './theme';

function CheckEmail({ onChangeStage }) {
  return (
    <Box component='div' sx={{ ...contentBox, width: '38rem' }}>
      <Box
        component='div'
        width='100%'
        display='flex'
        justifyContent='flex-start'
      >
        <IconButton onClick={() => onChangeStage('login')}>
          <Icon name='arrow_back' size={2.5} />
        </IconButton>
      </Box>
      <Icon name='new_logo' height={4.75} width={22.5} />
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        textAlign='center'
        alignItems='center'
        gap='1.5rem'
        width='100%'
      >
        <Typography className='StageLabel' component='h4'>
          Checke deine Mails
        </Typography>
        <Typography className='StageDescription' component='h6'>
          Wir haben dir eine Anleitung zum Zurücksetzen deines Passworts per
          Mail zugeschickt
        </Typography>
      </Box>
      <LordIcon
        src='iepbfivp'
        trigger='loop'
        style={{ width: '100%', height: '250px' }}
      />
      <Typography component='p' className='Help' textAlign='center'>
        Die E-Mail ist nicht angekommen? Schau mal im Spam nach oder{' '}
        <Link to='/'>Versuche eine andere E-Mail Adresse</Link>
      </Typography>
    </Box>
  );
}

export default CheckEmail;
