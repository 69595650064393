/* eslint-disable no-unsafe-optional-chaining */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FloatingButton } from 'components/button';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import useHorizontallScroll from 'hooks/useHorizontallScroll';
import { dotDividerForDate } from 'utils/utils';

import React, { useRef } from 'react';

import { accordion, textField, useStyles } from './styles';
import type { SingleDocumentCardProps } from './types';

export function SingleDocumentCard({
  notValid = false,
  expanded,
  onClose,
  children,
  cardTitle,
  badge,
  statusBadge,
  documentBasicInfo,
  editMode = false,
  textFieldWidth = '100%',
  value = '',
  onChange = undefined,
  primaryColor = '#4599bf',
  backgroundColor = '#fff',
}: SingleDocumentCardProps) {
  const classes = useStyles();
  const parentBoxRef = useRef<HTMLDivElement>(null);
  const contentBoxRef = useRef<HTMLDivElement>(null);

  const {
    showScrollButton,
    handleScroll,
    handleLeft,
    handleRight,
    leftButtonHidden,
    rightButtonHidden,
  } = useHorizontallScroll(parentBoxRef, contentBoxRef);

  return (
    <Accordion
      expanded={expanded}
      sx={{
        ...accordion,
        position: 'relative',
        border: `2px solid ${primaryColor}`,
        backgroundColor,
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton onClick={onClose}>
            <Icon name='expand_more' />
          </IconButton>
        }
      >
        <Box component='div' className={classes.mainBox}>
          <Box component='div'>
            <Box component='div' className={classes.titleBox}>
              {editMode ? (
                <TextField
                  autoComplete='off'
                  value={value}
                  onChange={onChange}
                  label='Titel'
                  sx={{ ...textField, minWidth: textFieldWidth }}
                />
              ) : (
                <Typography component='h6' className={classes.titleStyle}>
                  {cardTitle}
                </Typography>
              )}
              <Status
                statusText={statusBadge.text}
                status={statusBadge.status}
                style={{ padding: '0.625rem' }}
              />
              {notValid ? (
                <Box component='div' className={classes.notValidBox}>
                  <Icon name='warning' fill='#ED6C02' size={1.25} />
                  Achtung: Dieses Dokument ist nichtmehr gültig
                </Box>
              ) : null}
            </Box>
            <Box component='div' className={classes.docInfoBox}>
              <Box
                component='div'
                className={classes.badgeBox}
                sx={{ background: badge.color }}
              >
                {badge.label}
              </Box>
              <Stack
                direction='row'
                divider={<Divider orientation='vertical' flexItem />}
                spacing={1}
                sx={{ display: expanded ? 'none' : 'flex' }}
              >
                <Typography component='p' className={classes.descriptionStyle}>
                  {documentBasicInfo.document}
                </Typography>
                <Typography component='p' className={classes.descriptionStyle}>
                  {`Revision ${documentBasicInfo.revision}`}
                </Typography>
                {documentBasicInfo.date ? (
                  <Typography
                    component='p'
                    className={classes.descriptionStyle}
                  >
                    {dotDividerForDate(documentBasicInfo.date)}
                  </Typography>
                ) : (
                  '-'
                )}
              </Stack>
            </Box>
          </Box>
          <Box component='div' pr='1rem'>
            <Icon name='only_q_logo' size={3.125} />
          </Box>
        </Box>
      </AccordionSummary>
      <Box
        component='div'
        ref={parentBoxRef}
        sx={{ overflow: 'hidden' }}
        onScroll={handleScroll}
      >
        <AccordionDetails ref={contentBoxRef}>{children}</AccordionDetails>
      </Box>
      {showScrollButton ? (
        <FloatingButton
          direction='left'
          onClick={handleLeft}
          sx={{
            top: '50%',
            left: 0,
            display: leftButtonHidden ? 'none' : 'block',
          }}
        />
      ) : null}
      {showScrollButton ? (
        <FloatingButton
          direction='right'
          onClick={handleRight}
          sx={{
            top: '50%',
            right: 0,
            display: rightButtonHidden ? 'none' : 'block',
          }}
        />
      ) : null}
    </Accordion>
  );
}
