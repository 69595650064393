import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface PathItem {
  id: string;
  name: string;
}

const currentFilePathSlice = createSlice({
  name: 'currentPath',
  initialState: [] as PathItem[],
  reducers: {
    setCurrentPath: (state, action: PayloadAction<PathItem[]>) => {
      return action.payload;
    },
  },
});

export const { setCurrentPath } = currentFilePathSlice.actions;

export default currentFilePathSlice.reducer;
