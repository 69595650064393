import { Box, Button, SelectChangeEvent, Typography } from '@mui/material';
import { FabButton } from 'components/button';
import { FiltersBox, SortFilterBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { FilterDropdown } from 'components/filter-dropdown';
import { Icon } from 'components/icon';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { DocumentTypeModal } from 'modals/document-type';
import {
  addDocumentType,
  fetchDocuments,
  handleModal,
} from 'store/administration/document-type/document-type-slice';
import type {
  DocumentTypes,
  NewDocumentTypeTypes,
} from 'store/administration/document-type/types';
import {
  resetFilters,
  setSingleFilter,
} from 'store/common/page-filters/page-filters-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { sortByCriteria } from 'utils/utils';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { detectionCapabilities, sortByOptions } from './constants';
import useTableCells from './table-cells.hook';

export function DocumentTypePage({ pathName, parentTab, homePage }) {
  const dispatch = useAppDispatch();
  const addNotification = useNotification();
  const { t } = useTranslation([
    'sort',
    'status',
    'document',
    'common',
    'documenttype',
  ]);

  const { documents, modal, status } = useAppSelector((state) => ({
    documents: state.typeOverviewReducer.documents,
    modal: state.typeOverviewReducer.modal,
    status: state.typeOverviewReducer.status,
  }));

  const { persistedCapability } = useAppSelector((state) => ({
    persistedCapability:
      state.pageFiltersSlice.documentTypePage.persistedCapability,
  }));

  const { cells } = useTableCells();

  const [filtered, setFiltered] = useState<DocumentTypes[]>(null);
  const [sortBy, setSortBy] = useState<(typeof sortByOptions)[number]['value']>(
    sortByOptions[0].value
  );
  const [capability, setCapability] = useState<
    (typeof detectionCapabilities)[number]['value']
  >(persistedCapability || detectionCapabilities[0].value);

  const filterArrayByOptions = (
    arrayToFilter: DocumentTypes[],
    option: string
  ) => {
    return arrayToFilter.filter((doc) => {
      if (option === detectionCapabilities[1].value) {
        return doc.fillable;
      }
      if (option === detectionCapabilities[2].value) {
        return !doc.fillable;
      }
      return doc;
    });
  };

  const handleFilter = (e: SelectChangeEvent<string | string[]>) => {
    setCapability(e.target.value as string);
    dispatch(
      setSingleFilter({
        key: 'documentTypePage',
        subKey: 'persistedCapability',
        value: e.target.value as string,
      })
    );
  };

  const resetFilter = () => {
    dispatch(resetFilters({ key: 'documentTypePage' }));
    setCapability(detectionCapabilities[0].value);
  };

  const createNewDocument = (data: NewDocumentTypeTypes) => {
    dispatch(addDocumentType(data));
    addNotification({
      text: t('documenttype:alertnewdocument'),
    });
  };

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const filterRes = filterArrayByOptions(documents, capability);
    const sorting = sortByCriteria(filterRes, sortBy);
    setFiltered(sorting);
  }, [capability, documents, sortBy]);

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      {status === 'pending' || filtered === null || status === null ? (
        <Box component='div' mt={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <TableSkeleton key={item} />
          ))}
        </Box>
      ) : documents.length > 0 ? (
        <>
          <Typography component='p' className='PageInfo'>
            Insgesamt {filtered && filtered.length} Dokumentenarten verfügbar.
          </Typography>
          <SortFilterBox>
            <FiltersBox>
              <FilterDropdown
                value={capability}
                onChange={handleFilter}
                options={detectionCapabilities.map((option) => ({
                  ...option,
                  label: t(`sort:${option.label}`),
                }))}
                primaryColor='management'
                isFilterApplied={capability !== detectionCapabilities[0].value}
              />
              <Button
                variant='text'
                color='management'
                disabled={capability === detectionCapabilities[0].value}
                onClick={resetFilter}
              >
                {t('sort:resetfilter')}
              </Button>
            </FiltersBox>
            <SortSelect
              options={sortByOptions}
              value={sortBy}
              primaryColor='management'
              noOneChosen={sortBy === sortByOptions[0].value}
              onChange={(e) => setSortBy(e.target.value)}
            />
          </SortFilterBox>
          {filtered && filtered.length > 0 ? (
            <>
              <Table cells={cells} data={filtered} />
              <Box component='div' display='flex' justifyContent='center'>
                <FabButton
                  className='AbsolutePosition management'
                  onClick={() => dispatch(handleModal(true))}
                  label={t('document:newdocumenttype')}
                />
              </Box>
            </>
          ) : (
            <EmptyList
              image={{ name: ICON.UNFILTER_DOCUMENT_TYPES }}
              title={t('documenttype:emptyfilterlist')}
              primaryButton={
                <Button
                  variant='outlined'
                  color='management'
                  onClick={resetFilter}
                >
                  <Icon name={ICON.PLUS} size={1.5} fill='white' />
                  {t('document:resetfilter')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <EmptyList
          image={{ name: ICON.NO_DOCUMENT_TYPES }}
          title={t('documenttype:nodocuments')}
          description={t('documenttype:nodocumentsdesc')}
          primaryButton={
            <Button
              variant='contained'
              color='management'
              onClick={() => dispatch(handleModal(true))}
            >
              <Icon name={ICON.PLUS} size={1.5} fill='white' />
              {t('documenttype:newtype')}
            </Button>
          }
        />
      )}
      <DocumentTypeModal
        open={modal}
        onClose={() => dispatch(handleModal(false))}
        handleSuccess={(data) => createNewDocument(data)}
        icon={{ name: ICON.SCANNER, color: 'management' }}
      />
    </PageWrapper>
  );
}
