export const wrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const lineColumn = {
  width: '1px',
  background: 'rgba(0, 0, 0, 0.08)',
  height: '100%',
};

export const lineRow = {
  width: '100%',
  background: 'rgba(0, 0, 0, 0.08)',
  height: '1px',
};

export const labelStyle = {
  fontSize: '0.875rem',
  fontWeight: 500,
  letterSpacing: '0.008rem',
  margin: '0.5rem',
};
