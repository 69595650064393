import {
  Avatar,
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { BasicSelect } from 'components/select/basic-select';

import React from 'react';

import { Icon } from '../../../../../components/icon';

const gridBox = {
  display: 'grid',
  gridTemplateRows: '1.5fr 1fr',
};

export function EditableContent({
  customerValue,
  customerList,
  onCustomerChange,
}) {
  return (
    <Stack
      direction='row'
      divider={<Divider orientation='vertical' flexItem />}
      spacing={2}
    >
      <Box component='div'>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            Nachweisnummer
          </Typography>
          <Typography component='p' className='SDText'>
            NWS-0001
          </Typography>
        </Box>
      </Box>
      <Box component='div' sx={gridBox}>
        <Box component='div' display='flex' gap='4rem'>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              Freigeber
            </Typography>
            <Typography component='p' className='SDText'>
              -
            </Typography>
          </Box>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              Bearbeiter
            </Typography>
            <Tooltip title='M.Meister'>
              <Avatar className='SDAvatar' />
            </Tooltip>
          </Box>
        </Box>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            Freigabedatum
          </Typography>
          <Typography component='p' className='SDText'>
            -
          </Typography>
        </Box>
      </Box>
      <Box component='div' sx={gridBox}>
        <BasicSelect
          label='Kunde'
          sx={{ minWidth: '14rem' }}
          color='qsistant'
          options={customerList}
          value={customerValue}
          onChange={onCustomerChange}
        />
        <TextField
          autoComplete='off'
          defaultValue='78576 Emmingen-Liptingen'
          label='Standort der Gültigkeit'
          sx={{ minWidth: '14rem' }}
          color='qsistant'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon
                  name='location_on'
                  size={1.125}
                  fill='rgba(0, 0, 0, 0.87)'
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box component='div' display='flex' flexDirection='column'>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            Formularvorlage
          </Typography>
          <Typography component='p' className='SDText'>
            Auftragsgewinnungsprozess DOC-00000 | Revision 0
          </Typography>
        </Box>
        <Button
          variant='text'
          sx={{ color: '#4599BF' }}
          startIcon={<Icon name='open_in_new' fill='#4599BF' size={1.5} />}
        >
          öffnen
        </Button>
        <Button
          variant='text'
          sx={{ color: '#4599BF' }}
          startIcon={
            <Icon name='description_outlined' fill='#4599BF' size={1.5} />
          }
        >
          Elterndokument ändern
        </Button>
      </Box>
    </Stack>
  );
}
