import { Box, CircularProgress, Typography } from '@mui/material';
import { MultiSelectDropdown } from 'components/select/multiple-select/';
import { DefaultModal } from 'modals/default/default.modal';
import { fetchDocuments } from 'store/administration/members/members-slice';
import type { MemberDocumentTypes } from 'store/administration/members/types';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import type { RequestReleaseModalProps } from './types';

export function RequestReleaseModal({
  closeModal,
  modal,
  handleSuccess,
  icon,
  isLoading = false,
}: RequestReleaseModalProps) {
  const classes = useStyles();
  const { t } = useTranslation('release');
  const dispatch = useAppDispatch();

  const members = useAppSelector((state) => state.membersReducer.documents);

  const [reviewers, setReviewers] = useState<Array<MemberDocumentTypes>>([]);
  const [approvers, setApprovers] = useState<MemberDocumentTypes | null>(null);

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  const handleSubmit = () => {
    handleSuccess({
      reviewers,
      approvers,
    });
  };

  return (
    <DefaultModal
      closeModal={closeModal}
      successLabel={t('release:request')}
      handleSuccess={handleSubmit}
      modal={modal}
      modalTitle={t('release:requestrelease')}
      icon={icon}
      isLoading={isLoading}
      disabled={!approvers}
      className={classes.modal}
    >
      {members.length > 0 ? (
        <Box component='div' className={classes.contentWrapper}>
          <Box component='div' className={classes.sectionBox}>
            <Typography component='h6' className={classes.sectionTitle}>
              Dokumentenprüfung
            </Typography>
            <Typography component='p' className={classes.sectionDesc}>
              Wähle Prüfer für das Dokument aus, welche es auf die Richtigkeit
              des Inhalts überprüfen sollen. Eine Mehrfachauswahl ist möglich.
            </Typography>
            <MultiSelectDropdown<MemberDocumentTypes>
              showAvatars
              multiple
              disableCloseOnSelect
              options={members}
              values={reviewers}
              onChange={(e, v) => setReviewers(Array.isArray(v) ? v : [v])}
              label={t('release:reviewer')}
            />
          </Box>
          <Box component='div' className={classes.sectionBox}>
            <Typography component='h6' className={classes.sectionTitle}>
              Dokumentenfreigabe
            </Typography>
            <Typography component='p' className={classes.sectionDesc}>
              {`Wähle Freigeber für das Dokument aus, welche es offiziell für die Nutzung freigeben. `}
              <span>Keine</span>
              {` Mehrfachauswahl möglich.`}
            </Typography>
            <MultiSelectDropdown<MemberDocumentTypes>
              showAvatars
              options={members}
              values={approvers}
              onChange={(e, v) => setApprovers(v as MemberDocumentTypes)}
              label={t('release:releaser')}
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </DefaultModal>
  );
}
