import { Box, Modal } from '@mui/material';

import React from 'react';

import { ModalWrapperProps } from './modal-wrapper.types';
import { modalStyle } from './styles';

export function ModalWrapper({
  open,
  children,
  sx,
  onClose,
  className,
}: ModalWrapperProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box component='div' className={className} sx={{ ...modalStyle, ...sx }}>
        {children}
      </Box>
    </Modal>
  );
}
