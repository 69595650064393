import { createSlice } from '@reduxjs/toolkit';
import { ProductsType } from 'store/product/types';

const Products = createSlice({
  name: 'ProductsSlice',
  initialState: {
    products: [],
  } as ProductsType,
  reducers: {
    addProduct: (state, action) => {
      state.products.push(action.payload);
    },
    deleteProduct: (state, action) => {
      const productId = action.payload;
      state.products = state.products.filter(
        (product) => product.id !== productId
      );
    },
    updateProducts: (state, action) => {
      const { id, ...updatedData } = action.payload;
      state.products = state.products.map((product) => {
        if (product.id !== id) return product;
        return { ...product, ...updatedData };
      });
    },
  },
});

export const { addProduct, updateProducts, deleteProduct } = Products.actions;

export default Products.reducer;
