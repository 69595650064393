import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ListItem } from 'components/list-item';
import { PackageInfo } from 'components/package-info';
import { Status } from 'components/status';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';
import {
  handleActiveList,
  handleModalHasChanges,
  handleQAModal,
  handleUpgradePlanModal,
  setDiscardedRoute,
} from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BottomMenu } from './components/bottom-menu';
import { SidebarProps } from './sidebar.types';
import { drawerIconBox, labelStyle } from './styles';

export function SidebarDrawerComponent({
  config,
  open,
  onClose,
  openFeedback,
}: SidebarProps) {
  const navigate = useNavigate();
  const statusRef = useRef<HTMLDivElement>(null);
  const tariffPlan = 'regulatory';
  const dispatch = useAppDispatch();

  const { activeTabValue, activeListItem, hasChanges } = useAppSelector(
    (state) => ({
      activeTabValue: state.commonSlice.activeTabValue,
      activeListItem: state.commonSlice.activeListItem,
      hasChanges: state.commonSlice.hasChanges,
    })
  );

  const [openStatus, setOpenStatus] = useState(false);

  const handleListItem = (list: string, i: number, path: string) => {
    if (list === 'Schnellzugriff') {
      dispatch(handleQAModal(true));
    } else {
      navigate(path);
      dispatch(
        handleActiveList({
          parent: activeTabValue,
          item: i,
        })
      );
    }
  };

  const navigateTo = (path: string, iIndex: number, lItem: string) => {
    if (hasChanges) {
      dispatch(handleModalHasChanges(true));
      dispatch(setDiscardedRoute({ route: path, tab: 2, item: iIndex }));
      return;
    }
    handleListItem(lItem, iIndex, path);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { justifyContent: 'space-between' } }}
    >
      <nav>
        <List
          sx={{
            pt: '0',
            width: '97%',
          }}
        >
          <Box component='div' sx={{ ...drawerIconBox }}>
            <IconButton sx={{ p: '0.35rem', mr: '0.5rem' }} onClick={onClose}>
              <Icon name={ICON.MENU} size={1.5} />
            </IconButton>
            <Box sx={{ cursor: 'pointer' }}>
              <Icon
                name={ICON.QSISTANT_LOGO}
                onClick={() => navigate(routes().home)}
              />
            </Box>
            <Status
              ref={statusRef}
              statusText={tariffPlan}
              status={tariffPlan}
              style={{
                position: 'relative',
                cursor: 'pointer',
                textTransform: 'capitalize',
                fontSize: '.75rem',
                userSelect: 'none',
                height: '1.375rem',
                padding: '0 0.625rem',
              }}
              onClick={() => setOpenStatus((prev) => !prev)}
            />
          </Box>
          {config.map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem
                item={item}
                selected={activeListItem[activeTabValue] === index}
                onClick={() => navigateTo(item.path, index, item.list)}
              />
              {item.divider && <Divider sx={{ margin: '0.75rem 0' }} />}
              {item.label && (
                <Typography component='p' sx={{ ...labelStyle }}>
                  {item.label}
                </Typography>
              )}
            </React.Fragment>
          ))}
        </List>
        {openStatus && (
          <PackageInfo
            anchorEl={statusRef}
            open={openStatus}
            onClose={() => setOpenStatus(false)}
            tariff={tariffPlan}
            onClick={() => dispatch(handleUpgradePlanModal(true))}
          />
        )}
      </nav>
      <BottomMenu sidebarSqueezed={false} onOpen={openFeedback} />
    </Drawer>
  );
}
