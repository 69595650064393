import api from 'api/api';
import { API_URL } from 'api/api-urls';
import type {
  MemberDocumentTypes,
  NewMemberDataTypes,
} from 'store/administration/members/types';

export const getMembersList = () => {
  return api.get(`${API_URL.members.getAll}`);
};

export const addNewMember = (data: NewMemberDataTypes) => {
  return api.post(`${API_URL.members.add}`, data);
};

export const editMember = (data: MemberDocumentTypes) => {
  return api.post(`${API_URL.members.edit}/${data.id}`, data);
};

export const removeMember = (id: number) => {
  return api.delete(`${API_URL.members.remove}/${id}`);
};
