export const modalHeader = {
  fontSize: '1rem',
  fontWeight: '500',
};

export const modalSubtitle = {
  fontSize: '0.875rem',
  fontWeight: 'normal',
  color: '#00000099',
  pt: '0.5rem',
  letterSpacing: '0',
};
