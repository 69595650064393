import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { SingleComparisonCard } from 'cards/single-comparison-card';
import { SingleDocumentCard } from 'cards/single-document-card';
import { ActionBox, BackBox } from 'components/common';
import { Icon } from 'components/icon';
import { BasicSelect } from 'components/select/basic-select';
import { SingleDocumentSidebar } from 'components/single-document-sidebar';
import type { StatusValues } from 'components/status/status.types';
import { TinyMce } from 'components/tinymce-editor';
import { ToggleButtonGroup } from 'components/toggle-button-group';
import { ICON } from 'enum/icons.enum';
import { ModalAttention } from 'modals/attention/attention.modal';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  fetchSingleDocument,
  handleCloseModal,
  handleToggleValue,
} from 'store/qsistant/archive/archive-slice';
import type { ArchiveDocumentsTypes } from 'store/qsistant/archive/types';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  documentFileList,
  revisions,
  toggleOptions,
} from './archive.constants';
import { UneditableContent } from './components/card-content/uneditable-content';
import { useStyles } from './styles';

export function SingleArchivePage({
  pathName,
  parentTab,
  homePage,
  parentRoute,
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['status', 'document']);
  const { id } = useParams();
  const classes = useStyles();

  const { toggleValue, modal, loading } = useAppSelector((state) => ({
    toggleValue: state.archiveReducer.toggleValue,
    error: state.archiveReducer.singleError,
    status: state.archiveReducer.singleStatus,
    loading: state.archiveReducer.singleLoader,
    modal: state.archiveReducer.modal,
  }));

  const [documentData, setDocumentData] = useState<{
    document: ArchiveDocumentsTypes | null;
    status: StatusValues | null;
    name: string;
    id: number;
    content: string;
    revision: number;
    approved: Date;
    documentType: string;
    documentNumber: string;
    processCategory: string;
    processName: string;
    expirydate: Date;
    creatorName: string;
  }>({
    document: null,
    status: null,
    revision: null,
    id: null,
    name: '',
    content: '',
    approved: null,
    documentType: '',
    documentNumber: '',
    processCategory: '',
    processName: '',
    expirydate: null,
    creatorName: '',
  });

  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(true);
  const [cardExpanded, setCardExpanded] = useState<boolean>(true);
  const [review, setReview] = useState('');

  useEffect(() => {
    dispatch(fetchSingleDocument(Number(id))).then((data) => {
      const document: ArchiveDocumentsTypes = data.payload;
      setDocumentData({
        document,
        approved: document.approved,
        content: document.content,
        name: document.name,
        status: document.status,
        revision: document.revision,
        id: document.id,
        documentType: document.documentType.name,
        documentNumber: document.documentNumber,
        processCategory: document.process.category.name,
        processName: document.process.name,
        expirydate: document.expirydate,
        creatorName: document.creator.fullName,
      });
    });
  }, [id]);

  const dataToCompare = revisions.find((rev) => rev.value === review);

  const updateDocumentData = (
    field: keyof typeof documentData,
    value: string | boolean
  ) => {
    setDocumentData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  if (loading) {
    <PageWrapper
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      parentRoute={parentRoute}
      nestedDocument={`${t('document:archivedocument')} "${documentData.name}"`}
    >
      <CircularProgress color='qsistant' />
    </PageWrapper>;
  }

  return (
    <PageWrapper
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      parentRoute={parentRoute}
      nestedDocument={`${t('document:archivedocument')} "${documentData.name}"`}
    >
      <BackBox>
        <IconButton
          disableRipple
          sx={{ padding: 0 }}
          onClick={() => navigate(routes().archive)}
        >
          <Icon name={ICON.ARROW_BACK_IOS} size={2} fill='rgba(0, 0, 0, 0.6)' />
          <Typography component='h4' className='PageHeader'>
            Zur Archivübersicht
          </Typography>
        </IconButton>
      </BackBox>
      <ActionBox>
        {toggleValue === 'visibility' ? (
          <Box component='div' display='flex' gap='1rem' alignItems='center'>
            <Button
              color='qsistant'
              variant='contained'
              startIcon={<Icon name={ICON.RESTORE_FILE} fill='#fff' />}
            >
              Dokument wiederherstellen
            </Button>
            <Box
              component='div'
              sx={{
                background: '#fff',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '0.25rem',
              }}
            >
              <Tooltip title='Herunterladen'>
                <IconButton>
                  <Icon name={ICON.DOWNLOAD} size={1.125} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          <BasicSelect
            value={review}
            label='Zu vergleichendes Dokument'
            onChange={(e) => setReview(e.target.value)}
            options={revisions}
            color='qsistant'
            sx={{ width: '37rem' }}
          />
        )}
        <ToggleButtonGroup
          selectedValue={toggleValue}
          options={toggleOptions.map((option) => {
            return {
              ...option,
              tooltip: t(`document:${option.tooltip}`),
            };
          })}
          onChange={(e, newValue) => dispatch(handleToggleValue(newValue))}
        />
      </ActionBox>
      <Box component='div' display='flex' margin='1rem 0' gap='0.5rem'>
        <Box
          component='div'
          className={classes.mainContentBox}
          sx={{
            width: sidebarExpanded
              ? 'calc(100% - 25.5rem)'
              : 'calc(100% - 5rem)',
          }}
        >
          {!review || toggleValue === 'visibility' ? (
            <SingleDocumentCard
              notValid
              expanded={cardExpanded}
              onClose={() => setCardExpanded((prev) => !prev)}
              cardTitle='Auftragsgewinnungsprozess 44'
              badge={{ label: 'Qsistant® Nachweisdokument', color: '#4599bf' }}
              statusBadge={{
                text: t('status:archive'),
                status: t(`status:${documentData.status}`),
              }}
              documentBasicInfo={{
                document: documentData.name,
                revision: documentData.revision,
                date: documentData.approved,
              }}
              primaryColor='rgba(0, 0, 0, 0.12)'
            >
              <UneditableContent
                documentType={documentData.documentType}
                revision={documentData.revision}
                documentNumber={documentData.documentNumber}
                processCategory={documentData.processCategory}
                processName={documentData.processName}
                approved={documentData.approved}
                expirydate={documentData.expirydate}
                creatorName={documentData.creatorName}
              />
            </SingleDocumentCard>
          ) : (
            <Box component='div' className={classes.comparisonsBox}>
              <SingleComparisonCard
                title='Auftragsgewinnungsprozess 44'
                primaryColor='#D4BD35'
                indicator={{ color: '#D4BD35', label: 'Alter Inhalt' }}
                data={dataToCompare}
                bgColor='#FDFDF7'
                status={{
                  status: 'archive' as StatusValues,
                  text: t('status:archive'),
                }}
              />
              <SingleComparisonCard
                title='Auftragsgewinnungsprozess 44'
                indicator={{ color: '#5CB85C', label: 'Aktueller Inhalt' }}
                data={{
                  id: 55,
                  label: 'Revision 2',
                  document: 'DOC-000002',
                  date: 'Freigegeben am 07.03.2023',
                }}
                bgColor='#F9FCF9'
                status={{
                  status: 'archive' as StatusValues,
                  text: t('status:archive'),
                }}
              />
            </Box>
          )}
          <TinyMce
            onChange={(content) => updateDocumentData('content', content)}
            documentContent={documentData?.content}
            readOnly={toggleValue === 'visibility'}
          />
        </Box>
        <SingleDocumentSidebar
          open={sidebarExpanded}
          onClose={() => setSidebarExpanded(false)}
          onExpand={() => setSidebarExpanded(true)}
          title='Weiter Informationen “Auftragsgewinnungsprozess”'
          caption='Kurzzusammenfassung Dokument'
          description='Dieses Dokument gilt als Richtlinie für den Prozess der generellen Auftragsgewinnung mit dem Kunden “Weber Instrumente GmbH & Co. KG”.'
          list={documentFileList}
          status={documentData?.status}
        />
      </Box>
      {modal ? (
        <ModalAttention
          open={modal}
          className={classes.attentionModal}
          onClose={() => dispatch(handleCloseModal())}
          title='Achtung: Dieses Dokument ist nichtmehr gültig'
          description='Archivierte Dokumente sind nichtmehr gültig, können aber können noch
            mit anderen Revisionen verglichen oder wiederhergestellt werden.'
        />
      ) : null}
    </PageWrapper>
  );
}
