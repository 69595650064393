import { Box, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { StatusCardProps } from './status.card.types';
import {
  amountStyle,
  iconStyle,
  infoStyle,
  labelStyle,
  useStyles,
} from './styles';

export function StatusCard({ cardInfo, colors, icon }: StatusCardProps) {
  const classes = useStyles();
  return (
    <Box
      component='div'
      sx={{
        border: `2px solid ${colors?.border || 'rgba(0, 0, 0, 0.12)'}`,
        background: colors?.background || '#fff',
      }}
      className={classes.wrapperStyle}
    >
      {icon && (
        <IconButton disableRipple sx={{ ...iconStyle }}>
          <Icon
            name={icon.name}
            size={icon.size || 1}
            fill={icon.fill || '#00000099'}
          />
        </IconButton>
      )}
      <Box component='div' className={classes.textBox}>
        <Typography component='p' sx={{ ...amountStyle }}>
          {cardInfo.amount}
        </Typography>
        <Typography component='p' sx={{ ...labelStyle }}>
          {cardInfo.label}
        </Typography>
        <Typography component='p' sx={{ ...infoStyle }}>
          {cardInfo.info}
        </Typography>
      </Box>
    </Box>
  );
}
