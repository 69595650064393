import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    cardWrapper: {
      display: 'grid',
      alignItems: 'center',
      cursor: 'pointer',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: `${typography.pxToRem(6)}`,
      borderRadius: `${typography.pxToRem(6)}`,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
    },
    revisionText: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    userName: {
      color: 'rgba(0, 0, 0, 0.87)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '10ch',
    },
    documentName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dateText: {
      color: 'rgba(0, 0, 0, 0.8)',
      fontSize: `${typography.pxToRem(12)} !important`,
    },
    iconBox: {
      width: 'max-content',
    },
    revisionBox: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});
