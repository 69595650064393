import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'titleStyle'
  | 'headerBox'
  | 'labelImgBox'
  | 'alertBox'
  | 'breadcrumbsBox'
  | 'pageWrapper';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, colors, palette }) => {
    return {
      titleStyle: {
        fontWeight: `${typography.fontWeightMedium} !important`,
        letterSpacing: '0 !important',
      },
      headerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      labelImgBox: {
        display: 'flex',
        alignItems: 'center',
        gap: typography.pxToRem(size.font_8),
      },
      alertBox: {
        position: 'absolute',
        top: typography.pxToRem(size.font24L),
        right: typography.pxToRem(size.fontL * 2),
        zIndex: 10,
        background: '#E2EEE2',
        width: typography.pxToRem(768),
        height: typography.pxToRem(48),
        marginLeft: 'auto',
      },
      breadcrumbsBox: {
        paddingTop: typography.pxToRem(size.font24L),
        paddingBottom: typography.pxToRem(size.fontXSA),
        position: 'sticky',
        top: typography.pxToRem(96),
        zIndex: 5,
        background: colors.gray.background,
      },
      pageWrapper: {
        background: colors.gray.background,
        position: 'relative',
        minHeight: 'calc(100vh - 6rem)',
        padding: `0 ${typography.pxToRem(size.fontL * 2)}`,
      },
    };
  }
);

export const pageWrapper = {
  '@media(max-width: 1200px)': {
    padding: '0 2rem 2rem 6.5rem',
    width: 'calc(100vw - 0rem)',
    margin: 0,
  },
  '@media(max-width: 790px)': {
    width: '100vw',
    height: '100%',
    padding: '0 2rem 2rem 2rem',
  },
};
