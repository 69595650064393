import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { ProcessCategorieCard } from 'cards/process-categorie-card';
import { Icon } from 'components/icon';

import React, { useState } from 'react';

import { useStyles } from './styles';

export function ProcessCategoriesComponent({ processCategories }) {
  const classes = useStyles();
  const [hidden, setHidden] = useState<boolean>(false);

  return (
    <Box component='div'>
      <Box component='div' textAlign='center' mb='3.7rem'>
        <Box
          component='div'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography component='h3' className={classes.title}>
            Prozesskategorien
          </Typography>
          <IconButton onClick={() => setHidden(true)}>
            <Icon name='info' fill='#00000099' size={1.25} />
          </IconButton>
        </Box>
        <Typography component='p' className={classes.description}>
          Prozesskategorien stellen die oberste Ebene der Prozesse dar und damit
          ermöglichen wir eine übersichtlichere Darstellung aller Prozesse in
          der Prozesslandkarte.
        </Typography>
      </Box>
      <Box component='div' mb='1.5rem'>
        <Typography component='h6' className={classes.subDescription} ml='2rem'>
          Von uns vorerst festgelegte Prozesskategorien (später änderbar):
        </Typography>
      </Box>
      <Box component='div' className={classes.cardContainer}>
        {processCategories.length > 0 &&
          processCategories.map((cat) => (
            <ProcessCategorieCard key={cat.id} disabled {...cat} />
          ))}
      </Box>
      <Drawer
        open={hidden}
        anchor='right'
        onClose={() => setHidden(false)}
        sx={{
          '& .MuiDrawer-paper': {
            maxWidth: '34rem',
          },
        }}
      >
        <Box component='div' p='1.5rem'>
          <Box component='div' display='flex' alignItems='baseline' mb='1.5rem'>
            <Typography component='h3' className={classes.title}>
              Prozesskategorien - Das Wichtigste auf einen Blick:
            </Typography>
            <IconButton onClick={() => setHidden(false)}>
              <Icon name='close' size={1.5} fill='#000' />
            </IconButton>
          </Box>
          <Box component='div' className={classes.topDrawerDescBox}>
            <Typography component='p' className={classes.drawerText}>
              • Unterstützungsprozesse beschreiben häufig Prozesse, die
              ergänzend zu Kernprozessen durchgeführt werden, jedoch keinen
              direkten Nutzen für den Kunden erzeugen.
            </Typography>
            <Typography component='p' className={classes.drawerText}>
              • Der allgemeine Wertschöpfungsprozess (Herstellung von Produkten
              oder erbringen einer Dienstleistung) eines Unternehmens wird
              klassischerweise in den Kernprozessen.
            </Typography>
          </Box>
          <Box component='div' mb='1.5rem'>
            <Typography component='h6' className={classes.subDescription}>
              Wo es später in Qsistant eingesetzt wird -{' '}
              <span style={{ color: '#6E358C' }}>Prozesslandkarte:</span>
            </Typography>
            <Icon name='process_map_purple' />
          </Box>
          <Box component='div'>
            <Typography component='h6' className={classes.subDescription}>
              Weitere zusätliche Tipps zur Prozesslankarte:
            </Typography>
            <Typography
              component='p'
              className={classes.drawerText}
              mb='0.5rem'
            >
              • Der allgemeine Wertschöpfungsprozess (Herstellung von Produkten
              oder erbringen einer Dienstleistung) eines Unternehmens wird
              klassischerweise in den Kernprozessen.
            </Typography>
            <Typography
              component='p'
              className={classes.drawerText}
              mb='0.5rem'
            >
              • Der Prozesskategorien stellen die oberste Ebene der Prozesse dar
              und damit ermöglichen wir eine übersichtlichere Darstellung aller
              Prozesse in der Prozesslandkarte.
            </Typography>
            <Typography component='p' className={classes.drawerText}>
              • Der Prozesskategorien stellen die oberste Ebene der Prozesse dar
              und damit ermöglichen wir eine übersichtlichere Darstellung aller
              Prozesse in der Prozesslandkarte.
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
