import { Box, Button, Typography } from '@mui/material';
import { ProcessCategorieCard } from 'cards/process-categorie-card';
import { Icon } from 'components/icon';
import { ModalProcessCategoryAction } from 'modals/process-category-action';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  handleCreateNewCategorie,
  handleEditCategorie,
  handleRemoveCategorie,
  handleSelectCategorie,
  handleUnSelectCategorie,
} from 'store/user-profile/basic-user';

import React, { useRef, useState } from 'react';

import { ProcessMapTooltip } from 'tooltips';

import { useStyles } from './styles';

export function ProcessCategoriesSetup({ onBack, onNext }) {
  const labelRef = useRef<HTMLElement>();
  const classes = useStyles();
  const dispatcher = useAppDispatch();
  const processCategories = useAppSelector(
    (state) => state.basicUserReducer.processCategories
  );
  const selectedCategories = useAppSelector(
    (state) => state.basicUserReducer.selectedCategories
  );

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [createCategorie, setCreateCategorie] = useState<boolean>(false);
  const [catTitle, setCatTitle] = useState<string>('');
  const [catDescription, setCatDescription] = useState<string>('');

  const createNewCategorie = (name: string, description: string) => {
    dispatcher(handleCreateNewCategorie({ name, description }));
    setCatTitle('');
    setCatDescription('');
    setCreateCategorie(false);
  };

  const removeCategorie = (id: string) =>
    dispatcher(handleRemoveCategorie({ id }));

  const editCategorie = (name: string, description: string, id: string) =>
    dispatcher(handleEditCategorie({ name, description, id }));

  const selectCategorie = (id: string) =>
    dispatcher(handleSelectCategorie({ id }));

  const unSelectCategorie = (id: string) =>
    dispatcher(handleUnSelectCategorie({ id }));

  return (
    <Box component='div' textAlign='center' width='40%'>
      <Typography component='h3' className={classes.onboardingFormTitle}>
        Prozesskategorien festlegen
      </Typography>
      <Typography component='h6' className={classes.processCatDescription}>
        Prozesskategorien stellen die oberste Ebene der Prozesse dar. Damit
        ermöglichen wir eine übersichtlichere Darstellung aller Prozesse in
        deiner{' '}
        <span
          role='presentation'
          ref={labelRef}
          onClick={() => setShowTooltip(true)}
        >
          Prozesslandkarte.
        </span>
      </Typography>
      <Typography
        component='h6'
        className={classes.processCarCaption}
        sx={{ mt: '1.5rem' }}
      >
        Prozesskategorien für dein QM-System:
      </Typography>
      <Button
        variant='text'
        startIcon={<Icon name='add' fill='#3F97BE' />}
        sx={{ marginTop: '1.5rem' }}
        color='qsistant'
        onClick={() => setCreateCategorie(true)}
      >
        Prozesskategorie hinzufügen
      </Button>
      <Box component='div' className={classes.selectedCatsBox}>
        {selectedCategories.length > 0 &&
          selectedCategories.map((cat) => (
            <ProcessCategorieCard
              key={cat.id}
              isSelected
              onChange={unSelectCategorie}
              onRemove={(e) => {
                e.stopPropagation();
                removeCategorie(cat.id);
              }}
              onAction={(title, description, id) =>
                editCategorie(title, description, id)
              }
              {...cat}
            />
          ))}
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='space-around'
        margin='1.5rem 0'
      >
        <Button
          variant='text'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onBack}
        >
          zurück
        </Button>
        <Button
          variant='contained'
          color='qsistant'
          className={classes.furtherButtonStyle}
          disabled={selectedCategories.length === 0}
          onClick={onNext}
        >
          Weiter
        </Button>
      </Box>
      <Box component='div' className={classes.selectedCatsBox}>
        <Typography component='h6' className={classes.processCarCaption}>
          Von uns vorgeschlagene Prozesskategorien:
        </Typography>
        {processCategories.length > 0 &&
          processCategories.map((cat) => (
            <ProcessCategorieCard
              key={cat.id}
              onChange={selectCategorie}
              {...cat}
            />
          ))}
      </Box>
      {showTooltip ? (
        <ProcessMapTooltip
          anchorEl={labelRef}
          open={showTooltip}
          onClose={() => setShowTooltip(false)}
          image='png_square'
          description={
            <>
              Wo die Prozesskategorien später in Qsistant eingesetzt werden -{' '}
              <span>Die Prozesslandkarte:</span>
            </>
          }
        />
      ) : null}
      {createCategorie ? (
        <ModalProcessCategoryAction
          header='Prozesskategorie hinzufügen'
          color='qsistant'
          icon={{ name: 'label' }}
          open={createCategorie}
          onClose={() => setCreateCategorie(false)}
          name={catTitle}
          description={catDescription}
          onChangeTitle={(e) => setCatTitle(e.target.value)}
          onChangeDescription={(e) => setCatDescription(e.target.value)}
          onAction={createNewCategorie}
        />
      ) : null}
    </Box>
  );
}
