import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    modal: {
      width: `${typography.pxToRem(560)} !important`,
    },
    contentWrapper: {
      width: '100%',
      gap: `${typography.pxToRem(48)}`,
      display: 'flex',
      flexDirection: 'column',
      padding: `${typography.pxToRem(32)}`,
    },
    sectionBox: {
      width: '100%',
      gap: `${typography.pxToRem(8)}`,
      display: 'flex',
      flexDirection: 'column',
    },
    sectionTitle: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: `${typography.fontWeightMedium} !important`,
    },
    sectionDesc: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: `${typography.pxToRem(14)} !important`,
      letterSpacing: '0.25px !important',
      '& > span': {
        color: '#4599bf',
      },
    },
  };
});
