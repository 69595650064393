import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  MainContainer: {},
  SubMenu: {
    marginBottom: '20px',
  },
  qsistantHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    color: '#333333',
    fontWeight: 'normal',
    opacity: 0.7,
    cursor: 'pointer',
  },
  divider: {
    margin: '0px 8px 0px 8px',
    color: '#777777',
  },
  HomeHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    color: '#000000',
    cursor: 'pointer',
  },
  MainHeading: {
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font3XLL,
    fontWeight: 500,
    color: '#747474',
    margin: '0',
    marginBottom: '15px',
    marginTop: '15px !important',
  },
  HomeWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  HomeCard: {
    maxWidth: 'calc(50% - 16px)',
    width: '100%',
    marginBottom: '45px',
    background: '#fff',
    borderRadius: '5px',
    padding: '29px 34px',
  },
  HomeCardHead: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '33px',
  },
  HomeCardHeadTitle: {
    margin: 0,
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font2XXL,
    color: '#747474',
    fontWeight: 500,
  },
  HomeCardHeadMenu: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  HomeCardList: {
    marginBottom: '14px',
    overflowY: 'scroll',
    paddingRight: 15,
    maxHeight: 212,
  },
  ChangemanagementCardList: {
    marginBottom: '14px',
    maxHeight: '40vh',
  },
  MainCardTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: theme.fonts.family.primary,
    fontSize: theme.fonts.sizes.font2XL,
    color: '#4199bf',
    textTransform: 'capitalize',
    marginTop: '23px',
  },
  CustomCardWrap: {
    maxWidth: 'calc(50% - 16px)',
    width: '100%',
  },
  CustomCardWrapSingle: {
    marginBottom: '45px',
    background: '#fff',
    borderRadius: '5px',
    padding: '29px 34px',
    width: '100%',
  },
  ListCardItem: {
    display: 'flex',
    marginTop: '-10px',
  },
  ListCardWrap: {
    width: '68px',
    height: '68px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginRight: '16px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  AddNewCard: {
    width: '100%',
    borderRadius: '5px',
    background: 'rgba(65, 153, 191, 0.05)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '144px',
    '& .textWrap': {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.fonts.family.primary,
      fontSize: theme.fonts.sizes.font1XL,
      color: '#4199bf',
      textTransform: 'uppercase',
    },
  },
}));

export const MyImg = styled('img')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  top: 0,
  left: 0,
});
