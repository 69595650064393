import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';
import type { Identifier, XYCoord } from 'dnd-core';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalConfirm } from 'modals/confirm';
import { ModalActionProcess } from 'modals/process-action';
import { routes } from 'routes/routes';
import {
  editProcess,
  handleDndProcess,
  hideProcess,
  removeProcess,
} from 'store/administration/process/process-slice';
import { handleActiveList } from 'store/common/common-slice';
import { useAppDispatch } from 'store/hook';

import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { useStyles } from './styles';
import type { ProcessCardItemProps } from './types';

export function ProcessCardItem({
  name,
  options,
  category,
  id,
  activity,
  cardIndex,
}: ProcessCardItemProps) {
  const moreRef = useRef<HTMLButtonElement>(null);
  const processCardRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['product', 'processes', 'common', 'customer']);
  const classes = useStyles();
  const addNotification = useNotification();

  const [processTitle, setProcessTitle] = useState<string>(name);
  const [processCat, setProcessCat] = useState<string>(category);
  const [edit, setEdit] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [context, setContext] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const updateProcess = (label: string, cat: string) => {
    const categoryObj = options.find((c) => c.name === cat && c.id);
    const categoryId: number =
      categoryObj && categoryObj.id ? categoryObj.id : null;
    const data = {
      name: label,
      category: categoryId,
      id,
    };
    dispatch(editProcess(data));
    addNotification({
      text: t('processes:alertprocessedited'),
    });
    setEdit(false);
  };

  const handleNavigate = () => {
    navigate({
      pathname: routes().document,
      search: createSearchParams({
        q: name,
      }).toString(),
    });
    dispatch(handleActiveList({ parent: 0, item: 3 }));
  };

  const menuContext = [
    {
      id: 1,
      name: ICON.LABEL_OFF,
      label: t('processes:hideprocessmap'),
      onClick: () => {
        setModal(true);
        setContext(false);
      },
      show: activity,
    },
    {
      id: 223,
      name: ICON.DELETE,
      label: t('product:remove'),
      onClick: () => {
        setRemoveModal(true);
        setContext(false);
      },
      show: !activity,
    },
    {
      id: 2,
      name: ICON.EDIT,
      label: t('product:edit'),
      onClick: () => {
        setEdit(true);
        setContext(false);
      },
      show: true,
    },
  ];

  const [, drop] = useDrop<
    { id: number; index: number; category: string },
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'Process Card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (item.category === category) {
        addNotification({
          text: t('processes:alertprocessdndsuccess'),
        });
      }
    },
    hover(item, monitor) {
      if (!processCardRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = cardIndex;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = processCardRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      dispatch(handleDndProcess({ id: item.id, nextId: id }));
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'Process Card',
    item: { id, index: cardIndex, category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(processCardRef));

  return (
    <>
      <Box
        component='div'
        sx={{
          cursor: activity ? 'pointer' : 'auto',
          opacity: isDragging ? 0.54 : 1,
          '&:after': {
            display: isDragging ? 'block' : 'none',
          },
        }}
        className={classes.processItem}
        ref={processCardRef}
      >
        <IconButton sx={{ cursor: 'grab' }}>
          <Icon name={ICON.DRAG_HANDLE} fill='#3F97BE' />
        </IconButton>
        <Typography
          component='p'
          className={classes.itemLabel}
          onClick={() => {
            if (activity) {
              handleNavigate();
            }
          }}
        >
          {name}
        </Typography>
        <IconButton ref={moreRef} onClick={() => setContext((prev) => !prev)}>
          <Icon name={ICON.MORE} fill='#3F97BE' />
        </IconButton>
      </Box>
      {context ? (
        <ContextMenu
          sx={{ width: '11rem' }}
          open={context}
          onClose={() => setContext(false)}
          anchorEl={moreRef}
          dataList={menuContext}
        />
      ) : null}
      {edit ? (
        <ModalActionProcess
          isEditing
          open={edit}
          icon={{ name: ICON.PROCESS }}
          options={options}
          onClose={() => setEdit(false)}
          title={processTitle}
          header={t('processes:editprocess')}
          color='processMap'
          onChangeTitle={(e) => setProcessTitle(e.target.value)}
          category={processCat}
          onChangeCategory={(e) => setProcessCat(e.target.value)}
          onAction={(label, cat) => updateProcess(label, cat)}
        />
      ) : null}
      {modal ? (
        <ModalConfirm
          open={modal}
          onClose={() => setModal(false)}
          color='qsistant'
          onContinue={() => {
            dispatch(hideProcess({ id }));
            addNotification({
              text: t('processes:hideprocesstoastsuccess'),
            });
            setModal(false);
          }}
          btnNextLabel={t('processes:hideprocessmap')}
          title={t('processes:hideprocess')}
          description={t('processes:hideteprocessmodaldescription')}
        />
      ) : null}
      {removeModal ? (
        <ModalConfirm
          open={removeModal}
          onClose={() => setRemoveModal(false)}
          color='qsistant'
          onContinue={() => {
            dispatch(removeProcess({ id }));
            addNotification({
              text: t('processes:alertprocessremoved'),
            });
            setRemoveModal(false);
          }}
          btnNextLabel={t('product:remove')}
          title={t('processes:removeprocess')}
          description={t('processes:removeprocessdescription')}
        />
      ) : null}
    </>
  );
}
