import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    cardWrapper: {
      width: '100%',
      padding: `${typography.pxToRem(32)}`,
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(8)}`,
      borderTopLeftRadius: `${typography.pxToRem(5)}`,
      overflowX: 'scroll',
    },
    titleStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(24)} !important`,
      letterSpacing: '0.18px !important',
    },
    descriptionStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: `${typography.pxToRem(14)}`,
      letterSpacing: '0.1px !important',
    },
    badgeStyle: {
      fontSize: `${typography.pxToRem(12)}`,
      textTransform: 'capitalize',
      cursor: 'auto',
      padding: `${typography.pxToRem(4.96)}`,
      borderRadius: `${typography.pxToRem(4)}`,
      fontWeight: `${typography.fontWeightMedium}`,
      lineHeight: `${typography.pxToRem(16)}`,
      letterSpacing: '0.025rem',
      color: '#fff',
    },
    notValidBox: {
      display: 'inline-flex',
      gap: '0.5rem',
      alignItems: 'center',
      height: '2.25rem',
      background: '#FFF4E5',
      fontSize: `${typography.pxToRem(12)}`,
      borderRadius: '0.25rem',
      fontWeight: `${typography.fontWeightMedium}`,
      letterSpacing: '0.025rem',
      color: '#663C00',
      padding: `${typography.pxToRem(8)}`,
    },
    circleBox: {
      borderRadius: '50%',
      width: `${typography.pxToRem(20)}`,
      height: `${typography.pxToRem(20)}`,
    },
    indicatorBox: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: `${typography.pxToRem(4)}`,
      borderRadius: '1rem',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      background: '#fff',
      fontSize: `${typography.pxToRem(14)}`,
      fontWeight: `${typography.fontWeightRegular}`,
      textTransform: 'capitalize',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.015rem',
      padding: '0.4rem 0.6rem 0.4rem 0.4rem',
    },
  };
});
