export const colors = [
  { id: 1, value: '#2A1A1F', color: '#2A1A1F' },
  { id: 2, value: '#023E8A', color: '#023E8A' },
  { id: 3, value: '#ff8787', color: '#ff8787' },
  { id: 4, value: '#A9A875', color: '#A9A875' },
  { id: 5, value: '#E76F51', color: '#E76F51' },
  { id: 6, value: '#833C36', color: '#833C36' },
  { id: 7, value: '#57479A', color: '#57479A' },
  { id: 8, value: '#9A8C98', color: '#9A8C98' },
];
