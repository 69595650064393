import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllVersions,
  getSingleDocument,
  getSupportingDocumentsList,
} from 'api/services/supporting-documents.services';

import {
  asyncThunkFetchSingleDocument,
  asyncThunkFetchSupporting,
  asyncThunkFetchVersionDocuments,
  initialState,
} from './state';
import type { SupportingDocumentTypes } from './types';

export const fetchDocuments = createAsyncThunk(
  'supportingDocuments/fetchSupportingDocuments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getSupportingDocumentsList();
      dispatch(updateDocuments(response.data));
      dispatch(setStatusList(response.data));
      dispatch(setListOfOrganizations(response.data));
      dispatch(setListOfUsers(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const fetchDocumentVersions = createAsyncThunk(
  'supportingDocuments/fetchDocumentVersions',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllVersions();
      dispatch(setDocumentVersions(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const fetchSingleDocument = createAsyncThunk(
  'supportingDocuments/fetchSingleDocument',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getSingleDocument(id);
      dispatch(setSingleDocument(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const supportingDocumentsSlice = createSlice({
  name: 'supportingDocumentsSlice',
  initialState,
  reducers: {
    updateDocuments(state, action) {
      return {
        ...state,
        documents: action.payload,
      };
    },

    handleModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    setSingleDocument(state, action: PayloadAction<SupportingDocumentTypes>) {
      return {
        ...state,
        singleDocument: action.payload,
      };
    },

    setStatusList(state, action: PayloadAction<SupportingDocumentTypes[]>) {
      const uniqueValues = [
        ...new Set(action.payload.map((item) => item.status)),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        statusList: uniqueValues,
      };
    },

    setListOfOrganizations(
      state,
      action: PayloadAction<SupportingDocumentTypes[]>
    ) {
      const uniqueValues = [
        ...new Set(
          action.payload.map((item) =>
            item.users.map((user) => user.organization.name).join('')
          )
        ),
      ].reduce((acc, val) => {
        const newObj = { value: val, label: val, id: crypto.randomUUID() };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        listOfOrganizations: uniqueValues,
      };
    },

    setListOfUsers(state, action: PayloadAction<SupportingDocumentTypes[]>) {
      const uniqueValues = [
        ...new Set(
          action.payload.map((item) =>
            item.users.map((user) => `${user.prename} ${user.surname}`).join('')
          )
        ),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          avatar: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        listOfUsers: uniqueValues,
      };
    },

    setDocumentVersions(
      state,
      action: PayloadAction<SupportingDocumentTypes[]>
    ) {
      return {
        ...state,
        versions: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    asyncThunkFetchSupporting(builder, fetchDocuments);
    asyncThunkFetchVersionDocuments(builder, fetchDocumentVersions);
    asyncThunkFetchSingleDocument(builder, fetchSingleDocument);
  },
});

export const {
  updateDocuments,
  handleModal,
  setStatusList,
  setListOfOrganizations,
  setListOfUsers,
  setDocumentVersions,
  setSingleDocument,
} = supportingDocumentsSlice.actions;

export default supportingDocumentsSlice.reducer;
