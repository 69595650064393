import api from 'api/api';
import { API_URL } from 'api/api-urls';
import type { NewCategorieTypes } from 'store/administration/process-category/types';

export const getCategoriesList = () => {
  return api.get(`${API_URL.processCategories.getAll}`);
};

export const handleAddCategory = (data: NewCategorieTypes) => {
  return api.post(`${API_URL.processCategories.add}`, data);
};

export const handleEditCategory = (id: number, data: NewCategorieTypes) => {
  return api.post(`${API_URL.processCategories.edit}/${id}`, data);
};

export const removeCategory = (id: number) => {
  return api.delete(`${API_URL.processCategories.delete}/${id}`);
};
