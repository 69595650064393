import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  ListItemText,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import { Icon } from 'components/icon';
import type { MemberDocumentTypes } from 'store/administration/members/types';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';

import React from 'react';

import type { MultiSelectDropdownProps } from './types';

const StyledChip = styled(Chip)`
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.56);
`;

export function MultiSelectDropdownComponent<
  T extends MemberDocumentTypes | ProcessDocumentsTypes
>({
  options,
  showAvatars = false,
  label,
  multiple = false,
  onChange,
  renderValue,
  values = undefined,
  disableCloseOnSelect = false,
  color,
}: MultiSelectDropdownProps<T>) {
  const getLabel = (option: T) => {
    if (renderValue) {
      return renderValue(option);
    }
    if ('fullName' in option) {
      return option.fullName;
    }
    return option.name;
  };

  return (
    <Autocomplete
      multiple={multiple}
      value={values}
      fullWidth
      color={color}
      onChange={onChange}
      options={options}
      disableCloseOnSelect={disableCloseOnSelect}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => getLabel(option)}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          {showAvatars && 'avatar' in option && (
            <Avatar
              alt={getLabel(option)}
              src={option.avatar}
              style={{ marginRight: 8 }}
            />
          )}
          <ListItemText primary={getLabel(option)} />
          {multiple && <Checkbox checked={selected} color={color} />}
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <StyledChip
            key={option.id}
            avatar={
              showAvatars && 'avatar' in option ? (
                <Avatar alt={getLabel(option)} src={option.avatar} />
              ) : null
            }
            label={getLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} color={color} label={label} />
      )}
      popupIcon={<Icon name='arrow_drop_down' fill='inherit' />}
    />
  );
}
