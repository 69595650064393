import { Box, CircularProgress, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { DocumentProgressCardProps } from './document-progress-card.types';
import { useStyles } from './styles';

export function DocumentProgressCard({
  label,
  onClick,
  progress,
  documentIndex,
}: DocumentProgressCardProps) {
  const classes = useStyles();

  return (
    <Box component='div' className={classes.wrapper} onClick={onClick}>
      <Box component='div' className={classes.topBox}>
        <CircularProgress
          variant='determinate'
          value={progress === 0 ? 1 : progress}
          thickness={4}
          color='documentSetup'
          className={classes.circularProgress}
        />
        <Box className={classes.progressLabel}>
          <Typography
            variant='caption'
            component='div'
            color='#6E358C'
            fontWeight={900}
          >
            {progress === 0 ? '0%' : `${progress}%`}
          </Typography>
        </Box>
      </Box>
      <Box component='div' className={classes.bottomBox}>
        <Typography component='p' className={classes.documentLabel}>
          Dok {documentIndex + 1}: <span>{label}</span>
        </Typography>
        <Box component='div' display='flex' justifyContent='flex-end'>
          <Box component='div' className={classes.timeBox}>
            <Icon name='watch_later_filled' fill='#6E358C' size={1} />
            <Typography component='p' className={classes.progressCardTime}>
              Etwa 15 Minuten
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
