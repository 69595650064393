import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { LordIcon } from 'components/lord-icon';

import React from 'react';

import { useStyles } from './styles';
import type { DocumentCompletedPageProps } from './types';

export function DocumentCompletedPage({
  onClick,
  documentsNumber,
  completedDocumentsNumber,
}: DocumentCompletedPageProps) {
  const classes = useStyles();

  return (
    <Box component='div' className={classes.finalBoxWrapper}>
      <Typography component='h2' mb='1.5rem' className={classes.finalTitle}>
        Erstes Dokument &quot;Dokumentenlenkung&quot; erfolgreich abgeschlossen!
      </Typography>
      <Box
        component='div'
        mb='4rem'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Icon name='checked_documents' style={{ marginLeft: '3.25rem' }} />
        <Box
          component='div'
          sx={{ width: '25rem', position: 'relative', height: '2.5rem' }}
        >
          <LinearProgress
            variant='determinate'
            className={classes.finalProgress}
            value={Number(
              ((completedDocumentsNumber * 100) / documentsNumber).toFixed(0)
            )}
          />
          <Box
            component='div'
            sx={{ position: 'absolute', top: '0.5rem', left: 0, right: 0 }}
          >
            <Typography component='span' className='ProgressCaption'>
              {`${completedDocumentsNumber} VON ${documentsNumber}`} DOKUMENTEN
              ABGESCHLOSSEN
            </Typography>
            <LordIcon
              trigger={
                completedDocumentsNumber === documentsNumber ? 'loop' : null
              }
              delay='500'
              style={{
                width: '32px',
                height: '32px',
                position: 'absolute',
                top: '-0.5rem',
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box component='div'>
        <Typography component='h2' className={classes.finalTitle}>
          Alle Dokumente abgeschlossen.
        </Typography>
        <Typography component='p' className={classes.finalCaption} mb='1.5rem'>
          Importiere das erstellte System jetzt in deine Qsistant-Umgebung.
        </Typography>
        <Button
          variant='outlined'
          color='outlined'
          sx={{ fontSize: '1.5rem' }}
          endIcon={<Icon name='start' fill='#6E358C' size={2.25} />}
        >
          Dokumente in Qsistant einfügen
        </Button>
        <Box
          component='div'
          display='flex'
          alignItems='baseline'
          justifyContent='center'
        >
          <Typography component='p' className={classes.finalCaption}>
            oder
          </Typography>
          <Button variant='text' color='documentSetup' onClick={onClick}>
            ZUR DOKUMENTENÜBERSICHT
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
