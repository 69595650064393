import { Box, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { UserAvatar } from 'components/user';

import React from 'react';

import { useStyles } from './styles';
import { HomeWidgetWrapperProps } from './types';

export function HomeWidgetWrapper({
  children,
  label,
  icon,
  avatar,
}: HomeWidgetWrapperProps) {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.wrapperBox}>
      <Box component='div' className={classes.labelBox}>
        {icon && (
          <IconButton
            disableRipple
            sx={{
              background: ({ palette }) => palette.qsistant.main,
              cursor: 'auto',
            }}
          >
            <Icon
              name={icon.name}
              fill={icon.fill || '#fff'}
              size={icon.size || 1.5}
            />
          </IconButton>
        )}
        {avatar && <UserAvatar user={avatar} />}
        <Typography component='header' className={classes.labelStyle}>
          {label}
        </Typography>
      </Box>
      {children}
    </Box>
  );
}
