import { Box, Button, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { packagePrivelege } from './package-info.constants';
import {
  PackageInfoProfileProps,
  PackagePrivelegeType,
} from './package-info.types';
import { useStyles } from './styles';

export function PackageInfoProfileComponent({
  color = 'qsistant',
  onClick,
  title,
}: PackageInfoProfileProps) {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.wrapper}>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        sx={{ fill: (theme) => theme.palette[color].main }}
      >
        <Typography component='header' className={classes.title}>
          {title}
        </Typography>
        <Icon name={ICON.PREMIUM} size={1.5} fill='inherit' />
      </Box>
      <Box component='div'>
        {packagePrivelege.map(({ id, icon, label }: PackagePrivelegeType) => (
          <Box
            key={id}
            component='div'
            sx={{ fill: (theme) => theme.palette[color].main }}
            className={classes.privilegeBox}
          >
            <Icon name={icon} fill='inherit' size={1.5} />
            <Typography component='p' className={classes.packageLabel}>
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box component='div' display='flex' justifyContent='center'>
        <Button
          variant='contained'
          onClick={onClick}
          color={color}
          className={classes.btnStyle}
        >
          JETZT UPGRADEN
        </Button>
      </Box>
    </Box>
  );
}
