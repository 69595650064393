import { Box } from '@mui/material';
import api from 'api/api';
import { AppbarSecondary } from 'components/appbar';
import { StepProgress } from 'components/progress';
import { BasicProfileSettings } from 'forms/profile-settings-components';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AlmostDoneComponent } from './components/almost-done.page';
import { OnboardingWelcomePage } from './components/onboarding-welcome.page';
import { useStyles } from './styles';

const INITIAL_STATE = {
  avatar: '',
  avatarColor: '',
  name: '',
  surName: '',
  email: '',
  previousPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export function LowerRolePage() {
  const tariffPlan = 'free';
  const classes = useStyles();

  const [page, setPage] = useState('welcome_page');
  const [userData, setUserData] = useState(INITIAL_STATE);
  const [progress, setProgress] = useState(50);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'onboarding']);
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const updateFields = (fields: typeof INITIAL_STATE) =>
    setUserData((prev) => ({ ...prev, ...fields }));

  const handleProfile = () => setPage('profile_page');

  const handleSubmit = () => {
    setProgress((prev) => prev + 50);
    setIsLoading(true);
    api
      .post('/onboarding/saveuser', {
        token,
        user: userData,
      })
      .then(() => {
        setIsLoading(false);
        navigate('/login');
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    api
      .post(`/onboarding/verifyusertoken`, {
        token,
      })
      .then((r) => setUser(r.data))
      .catch(() => console.log('furz')); // token invalid or already initiated
  }, []);

  return (
    <>
      <AppbarSecondary
        userName={user?.email}
        tariffPlan={tariffPlan}
        userImage='Ronja'
      />
      <Box component='div' className={classes.mainBox}>
        {page !== 'welcome_page' ? (
          <StepProgress
            progress={progress}
            color='qsistant'
            className={classes.progressBox}
          />
        ) : null}
        {page === 'welcome_page' ? (
          <OnboardingWelcomePage
            button={{
              label: 'QSISTANT entdecken',
              color: 'linear-gradient(45deg, #4599BF, #6E358C)',
              icon: 'not_started',
            }}
            onClick={handleProfile}
            title={t('onboarding:greatloweruser', { email: user?.email })}
            description={t('onboarding:loweruserwelcomemessage')}
          />
        ) : page === 'profile_page' ? (
          <Box
            component='div'
            height='100%'
            width='29rem'
            display='flex'
            flexDirection='column'
            margin='3rem auto auto auto'
          >
            <Box component='div' mb='1.5rem'>
              <BasicProfileSettings
                updateFields={updateFields}
                isLoading={isLoading}
                onNext={handleSubmit}
                formDisabled={
                  !userData.name || !userData.surName || !userData.email
                }
                {...userData}
              />
            </Box>
          </Box>
        ) : (
          <AlmostDoneComponent />
        )}
      </Box>
    </>
  );
}
