// TODO api functions to slices. UserModal max-width: 418px
import { Typography } from '@mui/material';
import { editMember, removeMember } from 'api/services/members.services';
import { TableIconsBox } from 'components/table-icons-box';
import { ICON } from 'enum/icons.enum';
import { ModalConfirm } from 'modals/confirm';
import { UserModal } from 'modals/user-modal/user.modal';
import { fetchDocuments } from 'store/administration/members/members-slice';
import type { MemberDocumentTypes } from 'store/administration/members/types';
import { useAppDispatch } from 'store/hook';
import { dotDividerForDate } from 'utils/utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTabelCells() {
  const { t } = useTranslation(['user', 'common']);
  const dispatch = useAppDispatch();

  const [editedId, setEditedId] = useState<number | null>(null);
  const [removeId, setRemoveId] = useState<number | null>(null);

  const edit = (data: MemberDocumentTypes) => {
    if (data.id) {
      editMember(data).then(() => {
        dispatch(fetchDocuments());
        setEditedId(null);
      });
    }
  };

  const remove = (id: number) => {
    if (id) {
      removeMember(id).then(() => {
        dispatch(fetchDocuments());
        setRemoveId(null);
      });
    }
  };

  const getOptions = (item: MemberDocumentTypes) => {
    return [
      {
        id: 111,
        show: true,
        tooltip: t('common:edit'),
        icon: { name: ICON.EDIT_PERSON },
        onClick: () => setEditedId(item.id),
        modal: (
          <UserModal
            modal={editedId === item.id}
            user={item}
            closeModal={() => setEditedId(null)}
            handleSuccess={edit}
            icon={{ name: ICON.EDIT_PERSON, color: 'management' }}
          />
        ),
      },
      {
        id: 222,
        show: true,
        tooltip: t('common:delete'),
        icon: { name: ICON.REMOVE_PERSON },
        onClick: () => setRemoveId(item.id),
        modal: (
          <ModalConfirm
            open={removeId === item.id}
            onClose={() => setRemoveId(null)}
            onContinue={() => remove(item.id)}
            title='Teammitglied deaktivieren?'
            color='management'
            btnNextLabel='Deaktivieren'
            description='Dieses Teammitglied wird deaktiviert und hat dann keinen Zugang mehr. Die Lizenz wird freigegeben und kann für ein anderes Teammitglied verwendet werden, kann aber jederzeit wiederhergestellt werden.'
          />
        ),
      },
    ];
  };

  const cells = [
    {
      id: '2yhj',
      heading: t('user:name'),
      render: (item: MemberDocumentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.fullName}
        </Typography>
      ),
    },
    {
      id: '5qlzi',
      heading: t('user:email'),
      render: (item: MemberDocumentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.email}
        </Typography>
      ),
    },
    {
      id: 'asd65s',
      heading: t('user:active'),
      render: (item: MemberDocumentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.enabled ? t('common:yes') : t('common:no')}
        </Typography>
      ),
    },
    {
      id: 'asd6s5s',
      heading: t('user:lastactive'),
      render: (item: MemberDocumentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.lastLogin ? dotDividerForDate(item.lastLogin) : '-'}
        </Typography>
      ),
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item: MemberDocumentTypes) => (
        <TableIconsBox options={getOptions(item)} />
      ),
    },
  ];

  return {
    cells,
  };
}
