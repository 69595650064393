import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import customerReducer from 'store/administration/customer/customer-slice';
import departmentsReducer from 'store/administration/departments/departments-slice';
import typeOverviewReducer from 'store/administration/document-type/document-type-slice';
import membersReducer from 'store/administration/members/members-slice';
import processTypeReducer from 'store/administration/process-category/processtype-slice';
import processReducer from 'store/administration/process/process-slice';
import { commonSlice } from 'store/common/common-slice';
import feedbackReducer from 'store/common/feedback/feedback-slice';
import notificationsReducer from 'store/common/notifications/notifications-slice';
import { pageFiltersSlice } from 'store/common/page-filters/page-filters-slice';
import searchResultsReducer from 'store/common/search/search-results-slice';
import standardsReducer from 'store/common/standards/standards-slice';
import curFilePathProductReducer from 'store/product/current-file-path-slice';
import currentProductReducer from 'store/product/current-product-slice';
import filesProductReducer from 'store/product/product-files-slice';
import productsReducer from 'store/product/products-slice';
import archiveReducer from 'store/qsistant/archive/archive-slice';
import documentOverviewReducer from 'store/qsistant/document-overview/document-overview-slice';
import homeReducer from 'store/qsistant/home/home-slice';
import supportingDocumentsReducer from 'store/qsistant/supporting-documents/supporting-documents-slice';

import changemanagementReducer from './administration/changemanagement/changemanagement-slice';
import companyProfileReducer from './company-profile/company-profile.slice';
import documentsSetupReducer from './documents-setup/documents-setup-slice';
import quickaccessReducer from './qsistant/quickaccess/quickaccess-slice';
import basicUserReducer from './user-profile/basic-user';
import userReducer from './user-profile/user-slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['pageFiltersSlice'],
};

const commonPersistConfig = {
  key: 'common',
  storage,
  blacklist: ['hasChanges', 'modalHasChanges', 'discardedRoute'],
};

const rootReducer = combineReducers({
  // Common slices
  commonSlice: persistReducer(commonPersistConfig, commonSlice.reducer),
  pageFiltersSlice: pageFiltersSlice.reducer,
  feedbackReducer,
  notificationsReducer,
  standardsReducer,
  // Search results
  searchResultsReducer,
  // Qsistant slices
  homeReducer,
  archiveReducer,
  documentOverviewReducer,
  supportingDocumentsReducer,
  quickaccessReducer,
  // management/administartion slices
  companyProfileReducer,
  typeOverviewReducer,
  membersReducer,
  departmentsReducer,
  customerReducer,
  changemanagementReducer,
  // User Profile Settings reducers
  basicUserReducer,
  // Documents Setup reducer
  documentsSetupReducer,
  // Create New Document with/without uploaded files
  processTypeReducer,
  processReducer,
  userReducer,
  // Create new product/ upload dataset
  currentProductReducer,
  productsReducer,
  filesProductReducer,
  curFilePathProductReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
