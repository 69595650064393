import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Avatar,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { MultipleAutocompleteProps } from 'components/autocomplete/multiple-autocomplete/multiple-autocomplete.types';
import { Icon } from 'components/icon';

import React from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
const checkedIcon = <CheckBoxIcon fontSize='medium' />;

const useStyles = makeStyles({
  option: {
    display: 'flex',
    // height: '56px',
    padding: '9px 0 7px 16px',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(69, 189, 129, 0.04)',
    },
    cursor: 'pointer',
    transition: '0.3 ease-in-out',
  },
  selectedOption: {
    background: '#daf2e6',
  },
});

export function MultipleAutocompleteCheckboxComponent({
  sx,
  options,
  label,
  value,
  onChange,
  placeholder = '',
  variant = 'outlined',
  getOptionLabel,
  className,
  fullWidth = false,
  color = 'product',
  bg,
}: MultipleAutocompleteProps) {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      fullWidth={fullWidth}
      id='multiple-autocomplete'
      value={value}
      color={color}
      onChange={onChange}
      options={options}
      isOptionEqualToValue={(option, selectedValue) =>
        option.value === selectedValue.value
      }
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          className={`${classes.option} ${
            selected ? classes.selectedOption : ''
          }`}
        >
          <Typography
            component='span'
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {option?.avatar ? (
              <Avatar
                sx={{
                  mr: '0.5rem',
                  width: '2.5rem',
                  height: '2.5rem',
                }}
              >
                {option?.label[0]}
              </Avatar>
            ) : null}
            {option.label}
          </Typography>
          <Checkbox
            icon={icon}
            color={color}
            checkedIcon={checkedIcon}
            style={{ marginRight: '6px' }}
            checked={selected}
            sx={{ marginLeft: 'auto' }}
          />
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={sx}
          className={className}
          variant={variant}
          label={label}
          color={color}
          placeholder={placeholder}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            sx={{ m: '1rem' }}
            avatar={
              <Avatar sx={{ display: option?.avatar ? 'flex' : 'none' }}>
                {option?.label[0]}
              </Avatar>
            }
          />
        ))
      }
      popupIcon={<Icon name='arrow_drop_down' fill={bg || '#4599bf'} />}
    />
  );
}
