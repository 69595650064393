import api from 'api/api';
import { API_URL } from 'api/api-urls';
import type { ExtendedUserTypes } from 'store/user-profile/types';

export const fetchUserData = () => {
  return api.get(`${API_URL.user.getUserData}`);
};

export const updateUserData = (userData: ExtendedUserTypes) => {
  return api.post(`${API_URL.user.updateUserData}`, userData);
};

export const changeUserPassword = (data: {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
}) => {
  return api.post(`${API_URL.user.changePassword}`, data);
};

export const uploadAvatarFile = (formData: FormData) => {
  return api.post(`${API_URL.user.uploadUserAvatar}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const renewAuthCode = () => {
  return api.get(`${API_URL.user.renewAuthCode}`);
};

export const onDisableEmailAuth = () => {
  return api.get(`${API_URL.user.disableEmailAuth}`);
};

export const onInitiateEmailAuth = () => {
  return api.get(`${API_URL.user.initiateEmailAuth}`);
};

export const onValidateEmailAuth = (code: { code: string }) => {
  return api.post(`${API_URL.user.validateEmailAuth}`, code);
};
