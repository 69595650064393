import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { TableIconsBox } from 'components/table-icons-box';
import { ICON } from 'enum/icons.enum';
import { ModalConfirm } from 'modals/confirm';
import { DepartmentAddModal } from 'modals/department';
import {
  editDepartment,
  removeDepartment,
} from 'store/administration/departments/departments-slice';
import type {
  DepartmentTypes,
  NewDepartmentTypes,
} from 'store/administration/departments/types';
import { useAppDispatch } from 'store/hook';
import { dotDividerForDate } from 'utils/utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTableCells() {
  const { t } = useTranslation(['status', 'department', 'common']);
  const dispatch = useAppDispatch();

  const [editId, setEditedId] = useState<number | null>(null);
  const [removeId, setRemoveId] = useState<number | null>(null);

  const onEdit = (data: NewDepartmentTypes) => {
    dispatch(editDepartment(data));
    setEditedId(null);
  };

  const onRemove = (id: number) => {
    dispatch(removeDepartment(id));
    setRemoveId(null);
  };

  const getOptions = (item: DepartmentTypes) => {
    return [
      {
        id: 112,
        show: true,
        tooltip: t('common:edit'),
        icon: { name: ICON.EDIT },
        onClick: () => setEditedId(item.id),
        modal: (
          <DepartmentAddModal
            modal={editId === item.id}
            department={item}
            closeModal={() => setEditedId(null)}
            handleSuccess={onEdit}
            icon={{ name: ICON.HOUSE, color: 'management' }}
          />
        ),
      },
      {
        id: 222,
        show: !item.subDepartments,
        tooltip: t('common:delete'),
        icon: { name: ICON.DELETE },
        onClick: () => setRemoveId(item.id),
        modal: (
          <ModalConfirm
            open={removeId === item.id}
            onClose={() => setRemoveId(null)}
            onContinue={() => onRemove(item.id)}
            color='management'
            btnNextLabel={t('common:remove')}
            title={t('department:removedepartment')}
            description={t('department:removedepartmentdescription')}
          />
        ),
      },
    ];
  };

  const cells = [
    {
      id: '2yhj',
      heading: t('department:name'),
      render: (item: DepartmentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.name}
        </Typography>
      ),
    },
    {
      id: '3tyuh',
      heading: t('department:parent'),
      render: (item: DepartmentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.parentDepartment?.name ?? '-'}
        </Typography>
      ),
    },
    {
      id: '5qlzi',
      heading: t('department:leader'),
      render: (item: DepartmentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.leaders && item.leaders.length > 0
            ? item.leaders.map((leader) => leader.fullName)
            : '-'}
        </Typography>
      ),
    },
    {
      id: '5qlszi',
      heading: t('department:changed'),
      render: (item: DepartmentTypes) =>
        item.editor ? (
          <Box component='div' display='flex' alignItems='center' gap='.5rem'>
            <Tooltip title={item.editor.fullName}>
              <Avatar alt={item.editor?.fullName} src='/photo' />
            </Tooltip>
            <Box component='div' display='flex' flexDirection='column'>
              <Typography component='p' className='TBasicText'>
                {item.editor.fullName.replace(/[a-z]{1,}/, '.')}
              </Typography>
              <Typography component='p' className='TDateAvatar'>
                {dotDividerForDate(item.updated)}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography component='p' className='TBasicText'>
            -
          </Typography>
        ),
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item: DepartmentTypes) => (
        <TableIconsBox options={getOptions(item)} />
      ),
    },
  ];
  return {
    cells,
  };
}
