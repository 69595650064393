export const phasesTabWrapper = {
  background: 'white',
  minHeight: '30.25rem',
  m: '2.0625rem auto 2rem auto',
};

export const openPhasesBox = {
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const cardsDataWrap = {
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  height: 'max-content',
};

export const divider = {
  p: '0.75rem 2rem',
  fontSize: '0.875rem',
  color: '#45BD81',
  '&::before, &::after': {
    borderColor: '#45BD81',
  },
};

export const prodPhasesBox = {
  display: 'flex',
  p: '1.5rem 1.5rem 5.0625rem 0',
};

export const alsBtn = {
  fontSize: '0.875rem',
  color: '#41BDB1',
  letterSpacing: '0.078rem',
  whiteSpace: 'nowrap',
};

export const formWrapper = {
  display: 'flex',
  gap: '0.8125rem',
  flexWrap: 'nowrap',
  pl: '1.625rem',
};

export const phase = {
  borderBottom: 8,
  borderColor: '#45BD81',
  height: '18.125rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  mb: '1.1875rem',
  cursor: 'pointer',
};

export const addBtn = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgb(27%, 74%, 51%, 0.04)',
  height: '18.125rem',
  width: '12.625rem',
  cursor: 'pointer',
};

export const addDatasetBtn = {
  borderColor: 'rgba(0, 0, 0, 0.12)',
  color: '#45BD81',
  lineHeight: '1.5rem',
  p: '7px 12px',
  maxHeight: '36px',
  '&:hover': {
    background: 'rgb(27%, 74%, 51%, 0.12);',
    borderColor: 'rgb(27%, 74%, 51%, 0.12)',
    color: '#45BD81',
  },
};

export const datasetWrap = {
  minHeight: '30rem',
  display: 'flex',
  textAlign: 'center',
};

export const emptyWrap = {
  minHeight: '30rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
