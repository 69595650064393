export const wrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.5rem',
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
};

export const colorsBox = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: '1rem',
};

export const avatarStyle = {
  fontSize: '2rem',
  letterSpacing: 0,
  lineHeight: 'normal',
};

export const colorButtonStyle = {
  width: '1.25rem',
  height: '1.25rem',
  minWidth: 0,
  borderRadius: '50%',
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
};
