import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { ModalHeader } from 'components/modal-header';
import { setProduct } from 'store/product/current-product-slice';
import { addProduct } from 'store/product/products-slice';
import { Product } from 'store/product/types';

import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// import { useTranslation } from 'react-i18next';
import { Icon } from '../../../components/icon';
import { ICON } from '../../../enum/icons.enum';
import {
  modalHeader,
  modalSubtitle,
} from '../../../forms/create-new-product/theme';
import { MyImg } from '../../../styles/styles';
import { ModalWrapper } from '../../../wrappers/modal-wrapper/modal-wrapper';
import { useStyles } from './styles';

export function ModalCreateNewProduct({
  closeModal,
  modal,
  loading,
  setLoading,
}) {
  // const { t } = useTranslation([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  // Todo: use it with endpoint
  // const client = useQueryClient();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid, errors },
  } = useForm();

  // Todo: use it(1st mutationFn) with endpoint
  // const mutationFn = async (newProduct: Product) => {
  //   const formData = new FormData();
  //   formData.append('productName', newProduct.productName);
  //   formData.append('productDesc', newProduct.productDesc);
  //   formData.append('image', newProduct.image);
  //   formData.append('id', newProduct.id);
  //   fetch('api/products', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //     body: formData,
  //   });
  // };

  // const mutationFn = async (newProduct: Product) => {
  //   fetch('api/products', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: newProduct,
  //   }).then((res) => {
  //     console.log(res);
  //     if (!res.ok) {
  //       throw new Error(res.statusText);
  //     }
  //   });
  // };
  //
  // const mutationOptions = {
  //   onSuccess: () => {
  //     client.invalidateQueries(['products']);
  //   },
  //   onError: (error) => {
  //     alert(error);
  //   },
  // };

  // const { mutate, ...other } = useMutation(mutationFn, mutationOptions);

  const onSubmit = (data) => {
    const { productName, productDesc, image } = data;
    if (productName.length < 3) {
      return;
    }

    if (!isValid) {
      return;
    }

    const newProduct: Product = {
      productName,
      productDesc,
      image,
      id: crypto.randomUUID(),
    };
    // Todo: use it(1st mutationFn) with endpoint
    // mutate(newProduct);
    dispatch(setProduct(newProduct)); // del  with endpoint
    dispatch(addProduct(newProduct)); // del  with endpoint
    reset();
    closeModal();
  };

  const handleImgUpload = (e) => {
    setLoading(true);
    setValue('image', e.target.files[0]);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  if (!modal) return null;

  const inputRef = useRef<HTMLInputElement>(null);

  const maxLength = 200;

  let timer;
  const onClick = React.useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      inputRef.current?.click();
    }, 200);
  }, [timer]);

  if (!modal) return null;
  return (
    <ModalWrapper
      open={modal}
      onClose={closeModal}
      sx={{ minHeight: '50.375rem', width: '37.6885rem' }}
    >
      <ModalHeader
        header='Neues Produkt erstellen'
        onClose={closeModal}
        primaryColor='product'
        icon={{ name: ICON.TEST_TUBE }}
        className={classes.wrapper}
        gap='0.875rem'
      />
      <Divider />
      <Box sx={{ p: '2rem 2rem 1.25rem 2rem ', position: 'relative' }}>
        <Typography component='h6' sx={{ ...modalHeader }}>
          {/* {t('')} */}
          Produktdetails
        </Typography>
        <Typography sx={{ ...modalSubtitle }}>
          {/* {t('')} */}
          Benenne das neue Produkt und beschreibe es.
        </Typography>
        {/* <form onSubmit={onFormSubmit}> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='productName'
            control={control}
            defaultValue=''
            rules={{ required: true, minLength: 3 }}
            render={({ field }) => (
              <TextField
                color='product'
                label='Produktname'
                type='text'
                value={field.value}
                onChange={field.onChange}
                inputRef={field.ref}
                autoFocus
                fullWidth
                sx={{
                  m: '1.5rem 0',
                }}
              />
            )}
          />
          <Controller
            name='productDesc'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
                color='product'
                helperText={`${field.value.length}/${maxLength}`}
                sx={{
                  mb: '3rem',
                  '& .MuiFormHelperText-root': {
                    position: 'absolute',
                    bottom: '0.1rem',
                    right: '-0.5rem',
                  },
                }}
                label='Beschreibung Beitrag'
                type='text'
                value={field.value}
                onChange={field.onChange}
                inputProps={{ maxLength }}
                fullWidth
                multiline
                rows={3}
              />
            )}
          />
          <Typography component='h6' sx={{ ...modalHeader }}>
            {/* {t('')} */}
            Produktbild
          </Typography>
          <Typography sx={{ ...modalSubtitle }}>
            {/* {t('')} */}
            Lade ein eigenes Bild für dieses Produkt.
          </Typography>
          <Box>
            <Box className={classes.imgBlock}>
              <Box className={classes.imgWrap}>
                <Box className={classes.img}>
                  <Controller
                    name='image'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <>
                        {field.value ? (
                          <MyImg
                            alt='img'
                            src={
                              field.value && URL.createObjectURL(field.value)
                            }
                          />
                        ) : (
                          <Box className={classes.noImgBg}>
                            <Icon
                              size={1.5}
                              name='image_filled'
                              fill='#00000033'
                            />
                          </Box>
                        )}
                        <TextField
                          name='image'
                          ref={field.ref}
                          className={classes.hidden}
                          inputProps={{ accept: 'image/*' }}
                          type='file'
                          onChange={handleImgUpload}
                        />
                      </>
                    )}
                  />
                </Box>
              </Box>
              <Button
                variant='outlined'
                component='label'
                sx={{ color: '#45BD81' }}
                startIcon={
                  !loading ? (
                    <Icon
                      name='file_upload_fill_rounded'
                      fill='#45BD81'
                      size={1.5}
                    />
                  ) : (
                    <CircularProgress size={1.5} color='product' />
                  )
                }
                onClick={() => onClick}
              >
                {/* {t('')} */}
                BILD HOCHLADEN
                <TextField
                  name='uploadInp'
                  ref={inputRef}
                  className={classes.hidden}
                  inputProps={{ accept: 'image/*' }}
                  type='file'
                  onChange={handleImgUpload}
                />
              </Button>
            </Box>
            <Box />
            <Divider />
            <Button
              type='submit'
              variant='contained'
              color='product'
              disabled={!isValid || Object.keys(errors).length > 0}
              sx={{
                marginTop: '1.25rem',
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                padding: '7.5px 16px',
                maxHeight: '36px',
              }}
            >
              {/* {t('')} */}
              WEITER
            </Button>
          </Box>
        </form>
      </Box>
    </ModalWrapper>
  );
}
