import { Box, Button, Divider, Typography } from '@mui/material';
import {
  onDisableEmailAuth,
  onInitiateEmailAuth,
  renewAuthCode,
} from 'api/services/user.services';
import { LoadingButton } from 'components/button';
import { OtpInput } from 'components/custom-input';
import { ModalHeader } from 'components/modal-header';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { validateEmailAuth } from 'store/user-profile/user-slice';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useStyles } from './styles';

export function ModalTwoFactorAuth({ open, onClose, user }) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'user']);
  const enabled: boolean = user.emailAuth;

  const { error, isLoading } = useAppSelector((state) => ({
    error: state.userReducer.validateEmailError,
    isLoading: state.userReducer.validateEmailLoading,
    status: state.userReducer.validateEmailStatus,
  }));

  const [otp, setOtp] = useState<string>('');

  const onChange = (value: string) => setOtp(value);

  useEffect(() => {
    enabled ? onDisableEmailAuth() : onInitiateEmailAuth();
  }, []);

  const verifyCode = () => {
    dispatch(validateEmailAuth({ code: otp })).then(() => {
      if (!error) {
        setOtp('');
        onClose();
      }
    });
  };

  const renewCode = () => {
    setOtp('');
    renewAuthCode();
  };

  return (
    <ModalWrapper open={open} className={classes.modal}>
      <ModalHeader
        header={!enabled ? t('user:enableemail2fa') : t('user:disableemail2fa')}
        onClose={onClose}
        primaryColor='#000000CC'
        icon={{ name: 'email_filled' }}
      />
      <Divider />
      <Typography component='p' p='1.5rem' className={classes.text}>
        {t('user:checkyourmailforcode')} <span>{user.email}</span>{' '}
        {t('common:sent')}
      </Typography>
      <Box component='div' className={classes.otpBox}>
        <OtpInput
          valueLength={6}
          value={otp}
          onChange={onChange}
          error={!!error}
        />
        <Typography component='p' className={classes.text}>
          {t('user:notreceivedanycode')}{' '}
          <NavLink to='' onClick={renewCode}>
            {t('user:clickheretoresendcode')}
          </NavLink>
        </Typography>
      </Box>
      <Box component='div' className={classes.btnBox}>
        <Button variant='text' onClick={onClose} color='outlined'>
          {t('common:abort')}
        </Button>
        <LoadingButton
          variant='contained'
          color='outlined'
          disabled={otp.length < 6 || isLoading}
          onClick={verifyCode}
          label={t('common:next')}
          isLoading={isLoading}
        />
      </Box>
    </ModalWrapper>
  );
}
