import {
  Box,
  ClassNameMap,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';

import { RotateDirection } from '@react-pdf-viewer/core';
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import React from 'react';

export const renderToolbar = (
  Toolbar: (
    props: ToolbarProps
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  fileName: string,
  classes: ClassNameMap<string>
) => {
  return (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          ZoomOut,
          Rotate,
          ZoomIn,
          CurrentScale,
          CurrentPageInput,
          NumberOfPages,
        } = slots;
        return (
          <Box component='div' className={classes.toolbarWrapper}>
            <Box component='div' className={classes.toolbarNameBox}>
              <IconButton>
                <Icon name='menu' fill='#fff' />
              </IconButton>
              <Typography sx={{ color: '#fff' }}>{fileName}</Typography>
            </Box>
            <Stack
              direction='row'
              divider={<Divider orientation='vertical' flexItem />}
              spacing={2}
            >
              <Box component='div' display='flex' alignItems='center'>
                <CurrentPageInput />
                <NumberOfPages>
                  {(props) => (
                    <Typography component='p' className={classes.numberOfPages}>
                      / {props.numberOfPages}
                    </Typography>
                  )}
                </NumberOfPages>
              </Box>
              <Box component='div' className={classes.toolbarZoomBox}>
                <ZoomOut>
                  {(props) => (
                    <IconButton onClick={props.onClick}>
                      <Icon name='minus' fill='#fff' />
                    </IconButton>
                  )}
                </ZoomOut>
                <CurrentScale>
                  {(props) => (
                    <Typography
                      component='p'
                      className={classes.numberOfPages}
                    >{`${Math.round(props.scale * 100)}%`}</Typography>
                  )}
                </CurrentScale>
                <ZoomIn>
                  {(props) => (
                    <IconButton onClick={props.onClick}>
                      <Icon name='add' fill='#fff' />
                    </IconButton>
                  )}
                </ZoomIn>
              </Box>
              <Box>
                <Rotate direction={RotateDirection.Backward}>
                  {(props) => (
                    <IconButton onClick={props.onClick}>
                      <Icon name='rotate_90_ccw' fill='#fff' />
                    </IconButton>
                  )}
                </Rotate>
              </Box>
            </Stack>
            <Box sx={{ visibility: 'hidden' }}>Hello Konstantin</Box>
          </Box>
        );
      }}
    </Toolbar>
  );
};
