import { Box, useMediaQuery } from '@mui/material';
import { GlobalSnackbar, GlobalToast } from 'components/alert';
import { Sidebar, SidebarDrawer } from 'components/sidebar';
import { FeedbackModal } from 'modals/feedback/feedback.modal';
import { NewFeaturesModal } from 'modals/new-features/new-features.modal';
import { ModalQuickAccess } from 'modals/quick-access';
import {
  FinalBookingModal,
  PlanDetailsModal,
  UpgradePlanModal,
} from 'modals/tariff-plan';
import { productDevSidebarConfig } from 'pages/product_development/constants';
import {
  handleBookingSuccessModal,
  handleChangeTP,
  handleModalNewFeatures,
  handlePlanDetailsModal,
  handleQAModal,
  handleSidebar,
  handleTariffPlan,
  handleUpgradePlanModal,
} from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { Suspense, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  feedbackOptions,
  myProfileSidebarConfig,
  qsistantManagementSidebarConfig,
  qsistantSidebarConfig,
  upgradePlans,
} from './qsistant.constants';

export default function Qsistant() {
  const {
    narrowSidebar,
    isQuickAccessModal,
    isUpgradePlanModal,
    isPlanDetailsModal,
    selectedTariffPlan,
    bookingSuccessModal,
    showNewFeatures,
  } = useAppSelector((state) => ({
    narrowSidebar: state.commonSlice.narrowSidebar,
    isQuickAccessModal: state.commonSlice.isQuickAccessModal,
    isUpgradePlanModal: state.commonSlice.isUpgradePlanModal,
    isPlanDetailsModal: state.commonSlice.isPlanDetailsModal,
    selectedTariffPlan: state.commonSlice.selectedTariffPlan,
    bookingSuccessModal: state.commonSlice.bookingSuccessModal,
    showNewFeatures: state.commonSlice.showNewFeatures,
  }));

  const snacks = useAppSelector((state) => state.notificationsReducer.snacks);

  const dispatch = useAppDispatch();
  const sidebarBreakpoint = useMediaQuery('(max-width:850px)');

  const [showFeedback, setShowFeedback] = useState(false);

  useLocation();

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '6rem',
        backgroundColor: '#f5f5f5',
      }}
    >
      {sidebarBreakpoint && narrowSidebar ? (
        <SidebarDrawer
          open={narrowSidebar}
          onClose={() => dispatch(handleSidebar())}
          config={
            document.location.href.includes('/management')
              ? qsistantManagementSidebarConfig
              : document.location.href.includes('/my_profile')
              ? myProfileSidebarConfig
              : document.location.href.includes('/product_development')
              ? productDevSidebarConfig
              : document.location.href.includes('/qsistant')
              ? qsistantSidebarConfig
              : qsistantSidebarConfig
          }
          openFeedback={() => setShowFeedback(true)}
        />
      ) : (
        <Sidebar
          open={narrowSidebar}
          config={
            document.location.href.includes('/management')
              ? qsistantManagementSidebarConfig
              : document.location.href.includes('/my_profile')
              ? myProfileSidebarConfig
              : document.location.href.includes('/product_development')
              ? productDevSidebarConfig
              : document.location.href.includes('/qsistant')
              ? qsistantSidebarConfig
              : qsistantSidebarConfig
          }
          openFeedback={() => setShowFeedback(true)}
        />
      )}
      {showFeedback ? (
        <FeedbackModal
          open={showFeedback}
          onClose={() => setShowFeedback(false)}
          feedbackOptions={feedbackOptions}
        />
      ) : null}
      {isQuickAccessModal ? (
        <ModalQuickAccess
          open={isQuickAccessModal}
          onClose={() => dispatch(handleQAModal(false))}
        />
      ) : null}
      {isUpgradePlanModal ? (
        <UpgradePlanModal
          open={isUpgradePlanModal}
          options={upgradePlans}
          onClose={() => dispatch(handleUpgradePlanModal(false))}
          onClick={(option) => {
            dispatch(handlePlanDetailsModal(true));
            dispatch(handleUpgradePlanModal(false));
            dispatch(handleTariffPlan({ option }));
          }}
        />
      ) : null}
      {isPlanDetailsModal ? (
        <PlanDetailsModal
          open={isPlanDetailsModal}
          onClose={() => dispatch(handlePlanDetailsModal(false))}
          onBack={() => {
            dispatch(handleUpgradePlanModal(true));
            dispatch(handlePlanDetailsModal(false));
          }}
          selectedPlan={selectedTariffPlan}
          onNext={() => {
            dispatch(handlePlanDetailsModal(false));
            dispatch(handleBookingSuccessModal(true));
          }}
        />
      ) : null}
      {bookingSuccessModal ? (
        <FinalBookingModal
          open={bookingSuccessModal}
          onClose={() => {
            dispatch(handleChangeTP());
            dispatch(handleBookingSuccessModal(false));
          }}
          onClick={() => {
            dispatch(handleChangeTP());
            dispatch(handleBookingSuccessModal(false));
          }}
        />
      ) : null}
      {showNewFeatures ? (
        <NewFeaturesModal
          open={showNewFeatures}
          onClose={() => dispatch(handleModalNewFeatures(false))}
        />
      ) : null}
      {snacks.map((item) =>
        item.type === 'notification' ? (
          <GlobalToast
            key={item.id}
            message={item.text}
            severity={item.severity}
            anchor={item.anchor}
            onClose={item.button.onClick}
            position={item.position}
            autoDelete={item.autoDelete}
          />
        ) : (
          <GlobalSnackbar
            key={item.id}
            button={item.button}
            message={item.text}
            anchor={item.anchor}
          />
        )
      )}
      <Suspense fallback={<p>Loading...</p>}>
        <Outlet />
      </Suspense>
    </Box>
  );
}
