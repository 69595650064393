import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { MultipleAutocompleteChecked } from 'components/autocomplete/multiple-autocomplete-checkbox';

import React from 'react';

import { Icon } from '../../../components/icon';
import { datasetData } from '../../../pages/product_development/constants';
import { ModalWrapper } from '../../../wrappers/modal-wrapper/modal-wrapper';
import { commonHeaderProductBox } from '../../styles';

export function ModalDatasetPhases({
  closeModal,
  modal,
  onChangeDatasetName,
  datasetName,
  data,
  onChangeOptions,
  onFormSubmit,
  name,
}) {
  if (!modal) return null;

  return (
    <ModalWrapper
      open={modal}
      onClose={closeModal}
      sx={{ minHeight: '22.9375rem', width: '35rem' }}
    >
      <Box
        sx={{ ...commonHeaderProductBox, p: '1.25rem 1.0625rem 1.25rem 2rem' }}
        component='div'
      >
        <Box display='flex' alignItems='center' gap='1rem'>
          <IconButton
            disableRipple
            sx={{ background: '#45BD81', cursor: 'auto' }}
          >
            <Icon name='snippet_folder' size={1.5} fill='#fff' />
          </IconButton>
          <Typography
            component='h4'
            sx={{
              fontSize: '1.25rem',
              letterSpacing: '0.36px',
              fontWeight: '500',
            }}
          >
            Neues Dataset in &quot;{name}&quot; erstellen
          </Typography>
        </Box>
        <IconButton
          onClick={closeModal}
          sx={{ p: '0.5rem', borderRadius: '50%' }}
        >
          <Icon name='close' size={1.5} style={{ cursor: 'pointer' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ p: '2rem', position: 'relative' }}>
        <form onSubmit={onFormSubmit}>
          <TextField
            color='product'
            label='Datasetname'
            type='text'
            value={datasetName || ''}
            onChange={onChangeDatasetName}
            fullWidth
            sx={{
              m: '1.5rem 0',
            }}
          />
          <MultipleAutocompleteChecked
            color='product'
            fullWidth
            label='Verantwortliche'
            value={data}
            onChange={onChangeOptions}
            options={datasetData}
            getOptionLabel={(option) => option.label}
            bg='#45BD81'
          />
          <Box
            sx={{
              mt: '2.5rem',
              ml: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Button
              onClick={closeModal}
              variant='outlined'
              sx={{
                borderColor: '#fff',
                color: '#45BD81',
                letterSpacing: '0',
                maxHeight: '36px',
                p: '6.5px 16px',
                '&:hover': {
                  background: 'rgb(27%, 74%, 51%, 0.12);',
                  color: '#45BD81',
                  borderColor: 'rgb(27%, 74%, 51%, 0.12)',
                },
              }}
            >
              ABBRECHEN
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={datasetName.length < 3 || !data.length}
              sx={{
                background: '#45BD81',
                letterSpacing: '0',
                p: '7.5px 16px',
                maxHeight: '36px',
                '&:hover': { background: '#45BD81' },
                '&.Mui-disabled': {
                  background: 'rgb(27%, 74%, 51%, 0.54)',
                  color: '#fff',
                },
              }}
            >
              ERSTELLEN
            </Button>
          </Box>
        </form>
      </Box>
    </ModalWrapper>
  );
}
