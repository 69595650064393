import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { StepManagementBox } from 'components/step-management-box';
import { StepVersionBox } from 'components/step-version-box';
import { DocumentInfoStepper } from 'components/stepper';
import { ICON } from 'enum/icons.enum';
import { revisionVersions } from 'pages/qsistant/qsistant.constants';

import React, { useState } from 'react';

import { useStyles } from './styles';
import type { SingleDocumentSidebarProps } from './types';

export function SingleDocumentSidebarComponent({
  open,
  onClose,
  onExpand,
  title,
  caption,
  description,
  list,
  onChange,
  editMode = false,
  status,
}: SingleDocumentSidebarProps) {
  const classes = useStyles();

  const [currentListItem, setCurrentListItem] = useState(list[0]?.value);
  const [primaryTab, setPrimaryTab] = useState<number>(0);
  const [secondaryTab, setSecondaryTab] = useState<number>(0);

  const onOpenSecondTab = () => {
    onExpand();
    setPrimaryTab(1);
  };

  return (
    <Box
      component='div'
      className={classes.wrapper}
      sx={{ width: open ? '25rem' : '4.5rem' }}
    >
      {open ? (
        <Box className={classes.mainContentBox}>
          <Box component='div'>
            <Box component='div' className={classes.headerBox}>
              <Typography component='h3' className={classes.title}>
                {title}
              </Typography>
              <IconButton onClick={onClose}>
                <Icon name={ICON.CLOSE} />
              </IconButton>
            </Box>
            <Tabs
              value={primaryTab}
              onChange={(event, newValue: number) => {
                setPrimaryTab(newValue);
              }}
              className={classes.tabStyle}
            >
              <Tab value={0} label='Dokumentenstruktur' />
              <Tab value={1} label='Auditlog' />
            </Tabs>
            <Divider />
            <Box component='div' padding='1.5rem'>
              {primaryTab === 0 ? (
                <>
                  <Typography component='h6' className={classes.caption}>
                    {caption}
                  </Typography>
                  {!editMode ? (
                    <Typography
                      component='p'
                      sx={{ marginBottom: '1rem' }}
                      className={classes.description}
                    >
                      {description}
                    </Typography>
                  ) : (
                    <TextField
                      fullWidth
                      multiline
                      defaultValue={description}
                      onChange={onChange}
                      color='qsistant'
                    />
                  )}
                  <Typography component='h6' className={classes.caption}>
                    Dokumentenstruktur nach Überschriften
                  </Typography>
                  <List sx={{ padding: 0 }}>
                    {list &&
                      list.length > 0 &&
                      list.map((item, index: number) => (
                        <ListItem
                          key={item.id}
                          onClick={() => setCurrentListItem(item.value)}
                          className={classes.listItem}
                          sx={{
                            '&:before': {
                              visibility:
                                currentListItem === item.value
                                  ? 'visible'
                                  : 'hidden',
                            },
                            color: ({ colors, palette }) =>
                              currentListItem === item.value
                                ? palette.qsistant.main
                                : colors.gray.icon,
                          }}
                        >
                          {index + 1} {item.label}
                        </ListItem>
                      ))}
                  </List>
                </>
              ) : (
                <Box component='div'>
                  {secondaryTab === 0 ? (
                    <DocumentInfoStepper status={status}>
                      {revisionVersions.map((item) => (
                        <StepVersionBox
                          key={item.id}
                          label={item.label}
                          date={item.date}
                          users={item.users}
                        />
                      ))}
                    </DocumentInfoStepper>
                  ) : (
                    <DocumentInfoStepper status={status}>
                      <StepManagementBox />
                    </DocumentInfoStepper>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {primaryTab === 1 ? (
            <Box
              component='div'
              sx={{
                width: '25rem',
                position: 'fixed',
                bottom: 0,
                background: '#fff',
              }}
            >
              <Tabs
                value={secondaryTab}
                onChange={(event, newValue: number) => {
                  setSecondaryTab(newValue);
                }}
                className={classes.secondaryTabs}
              >
                <Tab
                  value={0}
                  label={
                    <Icon
                      name={ICON.HISTORY}
                      fill={
                        secondaryTab === 0 ? '#4599BF' : 'rgba(0, 0, 0, 0.6)'
                      }
                    />
                  }
                />
                <Tab
                  value={1}
                  label={
                    <Icon
                      name={ICON.REPEAT}
                      fill={
                        secondaryTab === 1 ? '#4599BF' : 'rgba(0, 0, 0, 0.6)'
                      }
                    />
                  }
                />
              </Tabs>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box component='div' className={classes.unexpandedWrapperBox}>
          <IconButton onClick={onExpand}>
            <Icon name={ICON.INFO} fill='rgba(0, 0, 0, 0.6)' size={1.25} />
          </IconButton>
          <IconButton onClick={onOpenSecondTab}>
            <Icon name={ICON.HISTORY} fill='rgba(0, 0, 0, 0.6)' size={1.25} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
