import { Box, Typography } from '@mui/material';
import { DragDropUploadComponentProps } from 'components/drag-drop/types';
import { Icon } from 'components/icon';

import React from 'react';
import { FileUploader } from 'react-drag-drop-files';

import { useStyles } from './styles';

const fileTypes = [
  'PDF',
  'ZIP',
  'JPG',
  'PNG',
  'DOCX',
  'DOC',
  'XSLX',
  'XSL',
  'PPT',
  'PPTX',
];

export function DragDropComponent({
  uploadFiles,
  handleChange,
  fileTypesText = '',
  dragDropName,
  color,
}: DragDropUploadComponentProps) {
  const classes = useStyles();
  return (
    <FileUploader
      multiple
      handleChange={handleChange}
      name='file'
      types={fileTypes}
      classes='dragDropLabel'
    >
      <Box component='div' className={classes.labelBox}>
        {uploadFiles.length === 0 ? (
          <Icon
            name='upload'
            fill={color}
            style={{ cursor: 'pointer' }}
            size={3}
          />
        ) : null}
        <Typography
          component='p'
          className={classes.label}
          sx={{
            '& > span > span': {
              color,
              ml: fileTypesText ? '3px' : 0,
            },
          }}
        >
          {uploadFiles.length === 0 ? (
            <span>
              Drag & Drop deine {dragDropName}
              <span> suchen</span>
            </span>
          ) : (
            <span>
              Drag & Drop deine {dragDropName} oder<span>suchen</span>
            </span>
          )}
        </Typography>
        <Typography component='p' className={classes.fileTypeStyle}>
          {fileTypesText}
        </Typography>
      </Box>
    </FileUploader>
  );
}
