import { generateExtraReducers } from 'utils/utils';

import type { QuickAccessSliceType } from './types';

export const initialState: QuickAccessSliceType = {
  categories: [],
  categoriesStatus: null,
  categoriesError: null,
  categoriesLoading: false,
};

export const asyncThunkFetchDepartments = generateExtraReducers({
  initialStateProp: 'categories',
  statusKey: 'categoriesStatus',
  errorKey: 'categoriesError',
  loadingKey: 'categoriesLoading',
});
