import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getProcessesList,
  handleAddProcess,
  handleEditProcess,
  onHideProcess,
  onRemoveProcess,
  onUnhideProcess,
} from 'api/services/processes.services';
import type { CategoryTypes } from 'store/administration/process-category/types';

import { asyncThunkFetchProcesses, initialState } from './state';
import type { NewProcessType, ProcessDocumentsTypes, RootState } from './types';

export const fetchProcesses = createAsyncThunk(
  'processes/fetchProcessesDocuments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getProcessesList();
      dispatch(updateDocuments(response.data));
      dispatch(setProcessCatList(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const addProcess = createAsyncThunk(
  'processes/addProcess',
  async (data: NewProcessType, { dispatch, rejectWithValue, getState }) => {
    const { documents } = (getState() as RootState).processReducer;
    try {
      const response = await handleAddProcess(data);
      dispatch(updateSingleProcess(response.data));
      dispatch(handleModal(false));
      return documents.concat(response.data);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const editProcess = createAsyncThunk(
  'processes/editProcess',
  async (data: NewProcessType, { dispatch, rejectWithValue }) => {
    try {
      const response = await handleEditProcess(data.id, data);
      dispatch(fetchProcesses());
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const removeProcess = createAsyncThunk(
  'processes/removeProcess',
  async (data: { id: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await onRemoveProcess(data.id);
      dispatch(handleRemoveProcess(data.id));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const hideProcess = createAsyncThunk(
  'processes/hideProcess',
  async (data: { id: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await onHideProcess(data.id);
      dispatch(updateDocuments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const showProcess = createAsyncThunk(
  'processes/showProcess',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await onUnhideProcess(id);
      dispatch(updateDocuments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const processSlice = createSlice({
  name: 'processSlice',
  initialState,
  reducers: {
    updateDocuments(state, action: PayloadAction<ProcessDocumentsTypes[]>) {
      return {
        ...state,
        documents: action.payload,
      };
    },

    updateSingleProcess(state, action: PayloadAction<ProcessDocumentsTypes>) {
      return {
        ...state,
        documents: state.documents.concat(action.payload),
      };
    },

    handleRemoveProcess(state, action: PayloadAction<number>) {
      const filtered = state.documents.filter(
        (process) => process.id !== action.payload
      );
      return {
        ...state,
        documents: filtered,
      };
    },

    handleModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    setProcessCatList(state, action: PayloadAction<ProcessDocumentsTypes[]>) {
      const unique = [
        ...new Set(action.payload.map((item) => item.category.name)),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        processCatList: unique,
      };
    },

    handleDndProcess(
      state,
      action: PayloadAction<{
        id: number;
        nextId: number;
      }>
    ) {
      const { id, nextId } = action.payload;
      const { documents: processes } = state;
      const cardToMove = processes.find((process) => process.id === id);
      const movedCardIndex = processes.findIndex(
        (process) => process.id === id
      );

      const cardIndexToReplace = processes.findIndex(
        (process) => process.id === nextId
      );

      const reorderedDocuments = [...processes];
      reorderedDocuments.splice(movedCardIndex, 1);
      reorderedDocuments.splice(cardIndexToReplace, 0, cardToMove);

      return {
        ...state,
        documents: reorderedDocuments,
      };
    },

    handleProcessToNewCategory(
      state,
      action: PayloadAction<{ processId: number; category: CategoryTypes }>
    ) {
      const { processId, category } = action.payload;
      const { documents: processes } = state;

      const updatedProcesses = processes.map((process) => {
        if (process.id === processId) {
          return {
            ...process,
            category,
          };
        }
        return process;
      });

      return {
        ...state,
        documents: updatedProcesses,
      };
    },
  },

  extraReducers: (builder) => {
    asyncThunkFetchProcesses(builder, fetchProcesses);
  },
});

export const {
  updateDocuments,
  updateSingleProcess,
  handleModal,
  handleRemoveProcess,
  setProcessCatList,
  handleDndProcess,
  handleProcessToNewCategory,
} = processSlice.actions;

export default processSlice.reducer;
