import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import { TableIconsBox } from 'components/table-icons-box';
import { ICON } from 'enum/icons.enum';
import { ConfirmWithPasswordModal } from 'modals/confirm';
import { routes } from 'routes/routes';
import { handleActiveList } from 'store/common/common-slice';
import { useAppDispatch } from 'store/hook';
import { restoreArchiveDocument } from 'store/qsistant/archive/archive-slice';
import type { ArchiveDocumentsTypes } from 'store/qsistant/archive/types';
import { dotDividerForDate } from 'utils/utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function useTableCells() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['status', 'document', 'common']);

  const [itemId, setItemId] = useState<number | null>(null);

  const handleRestore = (
    item: ArchiveDocumentsTypes,
    data: { password: string; comment: string }
  ) => {
    dispatch(restoreArchiveDocument({ id: item.id, data }));
    if (!item.filloutDocument) {
      navigate(
        routes().singleDocumentOverview.replace(':id', item.id.toString())
      );
      dispatch(handleActiveList({ parent: 0, item: 3 }));
    } else {
      navigate(
        routes().singleSupportDocument.replace(':id', item.id.toString())
      );
      dispatch(handleActiveList({ parent: 0, item: 4 }));
    }
  };

  const getOptions = (item: ArchiveDocumentsTypes) => {
    return [
      {
        id: 1123,
        show: true,
        tooltip: t('document:restore'),
        icon: { name: ICON.RESTORE_FILE },
        onClick: () => setItemId(item.id),
        modal: (
          <ConfirmWithPasswordModal
            open={itemId === item.id}
            onClose={() => setItemId(null)}
            title={t('document:restoredocument')}
            description={t('document:reallyrestore', { name: item?.name })}
            onContinue={(data) => handleRestore(item, data)}
            btnNextLabel={t('common:restore')}
          />
        ),
      },
    ];
  };

  const cells = [
    {
      id: '1tg21',
      heading: t('document:status'),
      onClick: (item: ArchiveDocumentsTypes) =>
        navigate(
          routes().singleArchiveDocument.replace(':id', item.id.toString())
        ),
      render: (item: ArchiveDocumentsTypes) => (
        <Status
          status={item.status as 'archive'}
          statusText={t(`status:${item.status}`)}
        />
      ),
    },
    {
      id: '2mmyyhj',
      heading: t('document:name'),
      onClick: (item: ArchiveDocumentsTypes) =>
        navigate(
          routes().singleArchiveDocument.replace(':id', item.id.toString())
        ),
      render: (item: ArchiveDocumentsTypes) => (
        <Box display='flex' alignItems='cemter' gap='0.5rem' component='div'>
          <Tooltip title={t(`document:${item.elementType}`)}>
            <IconButton disableRipple sx={{ padding: 0 }}>
              <Icon name='qs_icon_blue' size={1.5} />
            </IconButton>
          </Tooltip>
          <Typography component='p' className='TBasicText'>
            {item.name}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'twwyuh',
      heading: t('document:number'),
      onClick: (item: ArchiveDocumentsTypes) =>
        navigate(
          routes().singleArchiveDocument.replace(':id', item.id.toString())
        ),
      render: (item: ArchiveDocumentsTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.documentNumber}
          <br />
          {t('document:revision')}&nbsp;
          {item.revision}
        </Typography>
      ),
    },
    {
      id: '4efewdc',
      heading: t('document:documenttype'),
      onClick: (item: ArchiveDocumentsTypes) =>
        navigate(
          routes().singleArchiveDocument.replace(':id', item.id.toString())
        ),
      render: (item: ArchiveDocumentsTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.documentType?.name}
        </Typography>
      ),
    },
    {
      id: '5qlzi',
      heading: t('document:process'),
      onClick: (item: ArchiveDocumentsTypes) =>
        navigate(
          routes().singleArchiveDocument.replace(':id', item.id.toString())
        ),
      render: (item: ArchiveDocumentsTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.process?.name}
        </Typography>
      ),
    },
    {
      id: '6iqve',
      heading: t('document:archive'),
      onClick: (item: ArchiveDocumentsTypes) =>
        navigate(
          routes().singleArchiveDocument.replace(':id', item.id.toString())
        ),
      render: (item: ArchiveDocumentsTypes) => (
        <Box component='div' display='flex' alignItems='center' gap='0.5rem'>
          <Tooltip title={item.creator?.fullName}>
            <Avatar
              alt={item.creator?.fullName}
              src='/photo'
              className='TAvatar'
            />
          </Tooltip>
          <Box display='flex' flexDirection='column' component='div'>
            <Typography component='p' style={{ width: 'max-content' }}>
              {item.creator?.fullName.replace(/[a-z]{1,}/, '.')}
            </Typography>
            <Typography component='p' className='TDateAvatar'>
              {dotDividerForDate(item.updated)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item: ArchiveDocumentsTypes) => (
        <TableIconsBox options={getOptions(item)} />
      ),
    },
  ];

  return {
    cells,
  };
}
