import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { TableIconsBox } from 'components/table-icons-box';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalConfirm } from 'modals/confirm';
import { ModalActionProcess } from 'modals/process-action';
import type { CategoryTypes } from 'store/administration/process-category/types';
import {
  editProcess,
  hideProcess,
  removeProcess,
  showProcess,
} from 'store/administration/process/process-slice';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';
import { useAppDispatch } from 'store/hook';
import { dotDividerForDate } from 'utils/utils';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTableCells(
  categories: CategoryTypes[],
  errors: any
) {
  const { t } = useTranslation(['status', 'document', 'common', 'processes']);
  const dispatch = useAppDispatch();
  const addNotification = useNotification();

  const [removeId, setRemoveId] = useState<number | null>(null);
  const [editId, setEditId] = useState<number | null>(null);
  const [hideId, setHideId] = useState<number | null>(null);
  const [title, setTitle] = useState<string>('');
  const [category, setCategory] = useState<string>('');

  const setItem = (item: ProcessDocumentsTypes) => {
    setEditId(item.id);
    setTitle(item.name);
    setCategory(item.category.name);
  };

  const handleRemove = (id: number) => {
    dispatch(removeProcess({ id }));
    addNotification({
      text: t('processes:alertprocessremoved'),
    });
    setRemoveId(null);
  };

  const updateProcess = (label: string, catName: string, id: number) => {
    const categoryObj = categories.find((c) => c.name === catName && c.id);
    const categoryId = categoryObj && categoryObj.id ? categoryObj.id : null;
    const data = {
      name: label,
      category: categoryId,
      id,
    };
    dispatch(editProcess(data));
    addNotification({
      text: t('processes:alertprocessedited'),
    });
    setEditId(null);
  };

  const hideAction = (id: number) => {
    dispatch(hideProcess({ id }));
    addNotification({
      text: t('processes:hideprocesstoastsuccess'),
    });
    setHideId(null);
  };

  const unHideAction = (id: number) => {
    dispatch(showProcess(id));
    addNotification({
      text: t('processes:activateprocesstoastsuccess'),
    });
    setHideId(null);
  };

  const getOptions = (item: ProcessDocumentsTypes) => {
    return [
      {
        id: 112,
        show: true,
        tooltip: t('common:edit'),
        icon: { name: ICON.EDIT },
        onClick: () => setItem(item),
        modal: (
          <ModalActionProcess
            isEditing
            icon={{ name: ICON.NEW_PROCESS }}
            open={editId === item.id}
            errors={errors}
            options={categories}
            onClose={() => setEditId(null)}
            title={title}
            header={t('processes:editprocess')}
            color='management'
            onChangeTitle={(e) => setTitle(e.target.value)}
            category={category}
            onChangeCategory={(e) => setCategory(e.target.value)}
            onAction={(label, cat) => updateProcess(label, cat, item.id)}
          />
        ),
      },
      {
        id: 113,
        show: item.active,
        tooltip: !item.hidden
          ? t('processes:hideprocess')
          : t('processes:activateprocess'),
        icon: { name: item.hidden ? ICON.INVISIBLE : ICON.VISIBILITY_FILLED },
        onClick: () => setHideId(item.id),
        modal: (
          <ModalConfirm
            open={hideId === item.id}
            onClose={() => setHideId(null)}
            onContinue={() =>
              item.hidden ? unHideAction(item.id) : hideAction(item.id)
            }
            btnNextLabel={
              item.hidden
                ? t('processes:unhideprocessmap')
                : t('processes:hideprocessmap')
            }
            color='management'
            title={
              item.hidden
                ? t('processes:activateprocess')
                : t('processes:hideprocess')
            }
            description={
              item.hidden
                ? t('processes:activateprocessmodaldescription')
                : t('processes:hideteprocessmodaldescription')
            }
          />
        ),
      },
      {
        id: 222,
        show: !item.active,
        tooltip: t('common:delete'),
        icon: { name: ICON.DELETE },
        onClick: () => setRemoveId(item.id),
        modal: (
          <ModalConfirm
            open={removeId === item.id}
            onClose={() => setRemoveId(null)}
            onContinue={() => handleRemove(item.id)}
            btnNextLabel={t('common:remove')}
            color='management'
            title={t('processes:removeprocess')}
            description={t('processes:removeprocessdescription')}
          />
        ),
      },
    ];
  };

  const cells = [
    {
      id: '2yhj',
      heading: t('document:name'),
      render: (item: ProcessDocumentsTypes) => (
        <Box
          component='div'
          display='flex'
          alignItems='center'
          gap='0.5rem'
          sx={{ opacity: item.hidden ? 0.53 : 1 }}
        >
          <Tooltip title='Prozess'>
            <IconButton disableRipple sx={{ padding: 0 }}>
              <Icon name='label' size={1.5} />
            </IconButton>
          </Tooltip>
          <Typography component='span' className='TBasicText'>
            {item.name}
          </Typography>
        </Box>
      ),
    },
    {
      id: '3tyuh',
      heading: t('processes:processcategory'),
      render: (item: ProcessDocumentsTypes) => (
        <Typography
          component='span'
          className='TBasicText'
          sx={{ opacity: item.hidden ? 0.53 : 1 }}
        >
          {item.category?.name}
        </Typography>
      ),
    },
    {
      id: '6iqve',
      heading: t('document:changed'),
      render: (item: ProcessDocumentsTypes) => (
        <Typography
          component='span'
          className='TDateAvatar'
          sx={{ opacity: item.hidden ? 0.53 : 1 }}
        >
          {dotDividerForDate(item.updated)}
        </Typography>
      ),
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item: ProcessDocumentsTypes) => {
        return <TableIconsBox options={getOptions(item)} />;
      },
    },
  ];

  return {
    cells,
  };
}
