import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { useStyles } from './styles';
import type { FeatureCardProps } from './types';

export function FeatureCard({
  id,
  info,
  desc,
  icon,
  image,
  expanded,
  handleChange,
}: FeatureCardProps) {
  const classes = useStyles();
  return (
    <>
      <Accordion
        expanded={expanded === `${id}`}
        onChange={(_, isExpanded) => handleChange(`${id}`, isExpanded)}
        className={classes.accordion}
        sx={{
          boxShadow:
            expanded === `${id}`
              ? '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
              : 0,
        }}
      >
        <AccordionSummary className={classes.summary}>
          <Typography component='p' className={classes.icon}>
            {icon}
          </Typography>
          <Typography component='p' className={classes.cardLabel}>
            {info}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Typography component='p' className={classes.description}>
            {desc}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {expanded === `${id}` && (
        <Icon
          name={image}
          size={27}
          style={{ position: 'absolute', top: 0, right: 0 }}
        />
      )}
    </>
  );
}
