import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapperStyle: {
    borderRadius: '0.5rem',
    position: 'relative',
    minWidth: '13.75rem',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
}));

export const amountStyle = {
  color: '#000000',
  fontWeight: 400,
  fontSize: '2.125rem',
};

export const labelStyle = {
  color: '#000000',
  fontWeight: 500,
  fontSize: '0.875rem',
  letterSpacing: '0.1px',
};

export const infoStyle = {
  color: 'rgba(0, 0, 0, 0.38)',
  fontWeight: 500,
  fontSize: '0.875rem',
  letterSpacing: '0.1px',
};

export const iconStyle = {
  cursor: 'auto',
  position: 'absolute',
  top: 0,
  right: 0,
};
