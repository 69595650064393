import { PROJECT_TABS } from 'enum/common.enum';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';

export const appbarTabs = [
  {
    id: 0,
    path: routes().home,
    label: 'QSISTANT',
    color: PROJECT_TABS.QSISTANT,
  },
  {
    id: 1,
    path: routes().productDevelopment,
    label: 'PRODUKTENTWICKLUNG',
    color: PROJECT_TABS.PRODUCT,
  },
  {
    id: 2,
    path: routes().management,
    label: 'VERWALTUNG',
    color: PROJECT_TABS.MANAGEMENT,
  },
] as const;

export const qsistantSidebarConfig = [
  {
    id: 1,
    list: 'Home',
    icon: {
      name: ICON.HOME,
      activeColor: PROJECT_TABS.QSISTANT,
    },
    path: routes().home,
  },
  {
    id: 2,
    list: 'Prozesslandkarte',
    icon: {
      name: ICON.MAP,
      activeColor: PROJECT_TABS.QSISTANT,
    },
    path: routes().map,
    squeezedLabel: 'Karte',
  },
  {
    id: 3,
    list: 'Schnellzugriff',
    icon: {
      name: ICON.LIGHTNING,
      activeColor: PROJECT_TABS.QSISTANT,
    },
    path: '#',
    role: 'button',
  },
  {
    id: 4,
    list: 'Organigramm',
    icon: {
      name: ICON.ORGANIZATION_CHART,
      activeColor: PROJECT_TABS.QSISTANT,
    },
    path: routes().organigramm,
    divider: true,
    label: 'DOKUMENTE',
  },
  {
    id: 5,
    list: 'Dokumente',
    icon: {
      name: ICON.DOCUMENT,
      activeColor: PROJECT_TABS.QSISTANT,
    },
    path: routes().document,
    waitingCount: 3,
  },
  {
    id: 6,
    list: 'Nachweise',
    icon: {
      name: ICON.FILL_DOCUMENT,
      activeColor: PROJECT_TABS.QSISTANT,
    },
    path: routes().support,
  },
  {
    id: 7,
    list: 'Archiv',
    icon: {
      name: ICON.ARCHIVE,
      activeColor: PROJECT_TABS.QSISTANT,
    },
    path: routes().archive,
    divider: true,
    // label: 'DATEIEN',
  },
];

export const qsistantManagementSidebarConfig = [
  {
    id: 1,
    list: 'Unternehmensprofil',
    icon: { name: ICON.BUSINESS, activeColor: PROJECT_TABS.MANAGEMENT },
    divider: true,
    label: 'DOKUMENTE',
    path: routes().companyProfile,
    squeezedLabel: 'Unternehmen',
  },
  {
    id: 2,
    list: 'Dokumentenarten',
    icon: {
      name: ICON.SCANNER,
      activeColor: PROJECT_TABS.MANAGEMENT,
    },
    path: routes().types,
    squeezedLabel: 'Arten',
  },
  {
    id: 3,
    list: 'Wiedervorlage',
    icon: {
      name: ICON.REPEAT,
      activeColor: PROJECT_TABS.MANAGEMENT,
    },
    divider: true,
    label: 'Prozesse',
    path: routes().changemanagement,
  },
  {
    id: 10,
    list: 'Prozessübersicht',
    icon: { name: ICON.PROCESS, activeColor: PROJECT_TABS.MANAGEMENT },
    path: routes().process,
    divider: true,
    label: 'ZUWEISUNGEN',
    squeezedLabel: 'Prozesse',
  },
  {
    id: 9,
    list: 'Team',
    icon: {
      name: ICON.GROUP_PEOPLE,
      activeColor: PROJECT_TABS.MANAGEMENT,
    },
    path: routes().members,
  },
  {
    id: 61,
    list: 'Abteilungen',
    icon: { name: ICON.HOUSE, activeColor: PROJECT_TABS.MANAGEMENT },
    path: routes().departments,
  },
  {
    id: 666,
    list: 'Kunden',
    icon: { name: ICON.HOUSE, activeColor: PROJECT_TABS.MANAGEMENT },
    path: routes().customers,
  },
];

export const myProfileSidebarConfig = [
  {
    id: 0,
    list: 'Mein Profil',
    icon: {
      name: ICON.PROFILE_SETTINGS,
      activeColor: PROJECT_TABS.MY_PROFILE,
    },
    path: routes().profile,
  },
];

export const feedbackOptions = [
  { id: 1, value: 'feedback', label: 'Feedback' },
  { id: 2, value: 'bug', label: 'Fehler/Bug' },
];

export const industryTypes = [
  {
    id: 0,
    label: 'Keine Angabe',
    value: 'Keine Angabe',
  },
  {
    id: 1,
    label: 'Abfall und Recycling',
    value: 'Abfall und Recycling',
  },
  {
    id: 2,
    label: 'Apotheken',
    value: 'Apotheken',
  },
  {
    id: 3,
    label: 'Automobilzulieferer',
    value: 'Automobilzulieferer',
  },
  {
    id: 4,
    label: 'Baugewerbe, Bautechnik, Baustoffindustrie',
    value: 'Baugewerbe, Bautechnik, Baustoffindustrie',
  },
  {
    id: 5,
    label: 'Chemieindustrie',
    value: 'Chemieindustrie',
  },
  {
    id: 6,
    label: 'Einzelhandel',
    value: 'Einzelhandel',
  },
  {
    id: 7,
    label: 'Elektrotechnik',
    value: 'Elektrotechnik',
  },
  {
    id: 8,
    label: 'Fahrzeugbau',
    value: 'Fahrzeugbau',
  },
  {
    id: 9,
    label: 'Fertigungstechnik',
    value: 'Fertigungstechnik',
  },
  {
    id: 10,
    label: 'Finanzen',
    value: 'Finanzen',
  },
  {
    id: 11,
    label: 'Gastronomie',
    value: 'Gastronomie',
  },
  {
    id: 12,
    label: 'Gebäudeservice',
    value: 'Gebäudeservice',
  },
  {
    id: 13,
    label: 'Großhandel',
    value: 'Großhandel',
  },
  {
    id: 14,
    label: 'Immobilien',
    value: 'Immobilien',
  },
  {
    id: 15,
    label: 'Industriedienstleistungen',
    value: 'Industriedienstleistungen',
  },
  {
    id: 16,
    label: 'IT-Dienstleistungen',
    value: 'IT-Dienstleistungen',
  },
  {
    id: 17,
    label: 'IT-Technologie / Computer / Software',
    value: 'IT-Technologie / Computer / Software',
  },
  {
    id: 18,
    label: 'Kliniken, Reha und Gesundheit',
    value: 'Kliniken, Reha und Gesundheit',
  },
  {
    id: 19,
    label: 'Kunststoffverarbeitung',
    value: 'Kunststoffverarbeitung',
  },
  {
    id: 20,
    label: 'Labore und Prüfeinrichtungen',
    value: 'Labore und Prüfeinrichtungen',
  },
  {
    id: 21,
    label: 'Lebensmittelindustrie',
    value: 'Lebensmittelindustrie',
  },
  {
    id: 22,
    label: 'Luft- und Raumfahrt',
    value: 'Luft- und Raumfahrt',
  },
  {
    id: 23,
    label: 'Maschinenbau',
    value: 'Maschinenbau',
  },
  {
    id: 24,
    label: 'Medizintechnik',
    value: 'Medizintechnik',
  },
  {
    id: 25,
    label: 'Mess- und Regelungstechnik',
    value: 'Mess- und Regelungstechnik',
  },
  {
    id: 26,
    label: 'Metallverarbeitung',
    value: 'Metallverarbeitung',
  },
  {
    id: 27,
    label: 'Oberflächentechnik',
    value: 'Oberflächentechnik',
  },
  {
    id: 28,
    label: 'Personalberater',
    value: 'Personalberater',
  },
  {
    id: 29,
    label: 'Phrama, Arzneimittel und Biotech',
    value: 'Phrama, Arzneimittel und Biotech',
  },
  {
    id: 30,
    label: 'Schiffbau, Marinetechnik, Reedereien',
    value: 'Schiffbau, Marinetechnik, Reedereien',
  },
  {
    id: 31,
    label: 'Sicherheitsdienstleistungen',
    value: 'Sicherheitsdienstleistungen',
  },
  {
    id: 32,
    label: 'Steuer- Rechts- und Unternehmensberatung',
    value: 'Steuer- Rechts- und Unternehmensberatung',
  },
  {
    id: 33,
    label: 'Textilindustrie',
    value: 'Textilindustrie',
  },
  {
    id: 34,
    label: 'Touristik',
    value: 'Touristik',
  },
  {
    id: 35,
    label: 'Transport, Logistik, Spedition',
    value: 'Transport, Logistik, Spedition',
  },
  {
    id: 36,
    label: 'Verpackungsindustrie',
    value: 'Verpackungsindustrie',
  },
  {
    id: 37,
    label: 'Werbe, PR- und Medienagenturen',
    value: 'Werbe, PR- und Medienagenturen',
  },
  {
    id: 38,
    label: 'Werkzeugbau',
    value: 'Werkzeugbau',
  },
];

export const upgradePlans = [
  {
    id: 123,
    active: true,
    price: 0,
    icon: { basic: ICON.LOW_MOUNTAIN },
    label: 'Free',
    ideal: 'Ideal für Einsteiger und 1 Personen Unternehmen',
    valid: null,
    privilege: [
      { id: 213, label: 'Gratis für unbegrenzte Zeit' },
      { id: 223, label: '1 Benutzer' },
    ],
  },
  {
    id: 124,
    active: false,
    price: 45,
    icon: { basic: ICON.MID_MOUNTAIN },
    label: 'Basic',
    ideal: 'Ideal für ISO 9001',
    valid: 'gültig für 12 Monate + 30 Tage kostenlos testen',
    privilege: [
      { id: 23, label: '30 Tage kostenlos testen' },
      { id: 123, label: 'Unbegrenzte Nutzer' },
      { id: 35, label: 'Telefonischer Service' },
    ],
  },
  {
    id: 125,
    active: false,
    price: 99,
    icon: { basic: ICON.BIG_MOUNTAIN },
    label: 'Regulatory',
    ideal: 'Ideal im regulierten Umfeld und ISO 13485',
    valid: 'gültig für 12 Monate + 30 Tage kostenlos testen',
    privilege: [
      { id: 43, label: '30 Tage kostenlos testen' },
      { id: 203, label: 'Unbegrenzte Nutzer' },
      { id: 45, label: 'Telefonischer Service' },
      { id: 39, label: 'Softwarevalidierung inklusive' },
    ],
  },
];

export const revisionsData = [
  { id: 11, label: 'Rev. 3' },
  { id: 22, label: 'Rev. 1' },
  { id: 33, label: 'Rev. 0' },
];

export const revisionVersions = [
  {
    id: 11,
    label: 'Version 4',
    date: '19.06.22, 12:43',
    users: [
      { id: 112, name: 'A.Andris', desc: 'Hello world', date: '22.04.12' },
      {
        id: 113,
        name: 'K.Meladze',
        desc: 'Hello world hello world',
        date: '22.04.12',
      },
      { id: 114, name: 'A.Tiesto', desc: 'Hello world', date: '22.04.12' },
    ],
  },
  {
    id: 21,
    label: 'Version 3',
    date: '19.06.22, 12:43',
    users: [
      {
        id: 112,
        name: 'F.Florian',
        desc: 'Hello world Hello world Hello world',
        date: '22.04.12',
      },
      { id: 113, name: 'Y.Lysenko', desc: 'Hello world', date: '22.04.12' },
      { id: 114, name: 'A.Andris', desc: 'Hello world', date: '22.04.12' },
    ],
  },
  {
    id: 31,
    label: 'Version 2',
    date: '19.06.22, 12:43',
    users: [
      {
        id: 112,
        name: 'A.Angerfist',
        desc: 'Hello world Hello world',
        date: '22.04.12',
      },
      { id: 113, name: 'M.Keit', desc: 'Hello world', date: '22.04.12' },
      {
        id: 114,
        name: 'P.Estak',
        desc: 'Hello world hello world',
        date: '22.04.12',
      },
    ],
  },
];
