import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Icon } from 'components/icon';
import LongMenu from 'components/long-menu/LongMenu';
import { Status } from 'components/status';
import {
  deleteFolderItem,
  updateFolderName,
} from 'store/product/product-files-slice';
import { dotDividerForDate } from 'utils/utils';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  headerCell,
  headerRow,
  tableCell,
} from '../../pages/product_development/single-product/poduct_tabs/product-files-tab/styles';

function BreadcrumbItem({ onClick, children }) {
  return <Box onClick={onClick}>{children}</Box>;
}

interface FileData {
  id: string;
  name: string;
  revision: string;
  fileVersion: string;
  release: string;
  status: string;
  path: { id: string; name: string }[];
  files: FileData[];
  folders: FileData[];
}

export function ProductImportedRecordsTableComponent({
  selectedRows,
  handleRowSelect,
  editMode,
  columnNames,
  columnWidths,
  currentPath,
  childFiles,
  childFolders,
  handleFolderClick,
  // cells,
}) {
  const totalWidth = 5.5 + 26 + 14.125 + 16.75 + 33.5; // rem
  const factor = 100 / totalWidth;
  const { t } = useTranslation('document');
  console.log('childFolders', childFolders);
  const [editNameMode, setEditNameMode] = useState(true);
  const [editNameValue, setEditNameValue] = useState('');
  const [idForEdit, setIdForEdit] = useState('');
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const handleEditNameChange = (event) => {
    setEditNameValue(event.target.value);
  };

  const handleEditNameSave = () => {
    dispatch(
      updateFolderName({
        itemId: idForEdit,
        newName: editNameValue,
      })
    );
    setEditNameMode(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleEditNameSave();
    }
  };

  const onDeleteClick = (id) => {
    dispatch(deleteFolderItem({ folderId: id }));
  };

  const handleRename = (id) => {
    setIdForEdit(id);
    setEditNameValue(
      childFolders.find((fileData) => fileData.id === id)?.name ||
        childFiles.find((fileData) => fileData.id === id)?.name
    );
    setEditNameMode(true);
  };

  useEffect(() => {
    if (editNameMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editNameMode]);

  const handleBlur = () => {
    setTimeout(() => {
      if (document.activeElement !== inputRef.current) {
        handleEditNameSave();
      }
    }, 0);
  };

  return (
    <Box>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead sx={{ borderBottom: '1px solid #e0e0e0' }}>
          <TableRow sx={{ ...headerRow }}>
            <TableCell />
            {columnNames.map((columnName, index) => (
              <TableCell
                key={columnName}
                sx={{
                  width: `${columnWidths[index] * factor}%`,
                  ...headerCell,
                }}
              >
                {columnName.toUpperCase()}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {childFolders.map((fileData) => (
            <React.Fragment key={fileData.id}>
              <TableRow
                onDoubleClick={() => {
                  handleFolderClick(fileData, fileData.id);
                }}
                onClick={() => {
                  handleRowSelect(fileData.id);
                }}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor:
                    selectedRows.includes(fileData.id) && !editMode
                      ? '#45BD8114'
                      : 'inherit',
                  '&:hover': {
                    backgroundColor: 'rgba(69, 189, 129, 0.04)',
                  },
                  maxHeight: '4rem',
                  cursor: 'pointer',
                }}
              >
                <TableCell
                  sx={{
                    display: 'flex',
                    justifyContent: !editMode ? 'space-between' : 'flex-start',
                    alignItems: 'center',
                    ml: !editMode ? 0 : '1rem',
                    mr: '0.5rem',
                    width: `${5.5 * factor}%`,
                    ...tableCell,
                  }}
                >
                  {!editMode && currentPath.length === 1 && (
                    <Checkbox
                      checked={selectedRows.includes(fileData.id)}
                      onChange={(event) => {
                        handleRowSelect(fileData.id);
                      }}
                      sx={{
                        opacity: selectedRows.includes(fileData.id) ? 1 : 0,
                        '&:hover': {
                          opacity: 1,
                        },
                      }}
                      color={
                        selectedRows.includes(fileData.id)
                          ? 'product'
                          : 'default'
                      }
                    />
                  )}
                  {fileData?.path?.length <= 2 ? (
                    <Icon name='inventory' size={1.5} fill='#45BD81' />
                  ) : (
                    <Icon name='folder' size={1.5} fill='#45BD81' />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCell,
                    width: `${26 * factor}%`,
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {editNameMode && idForEdit === fileData.id ? (
                    <TextField
                      inputRef={inputRef}
                      value={editNameValue}
                      variant='standard'
                      color='product'
                      onChange={handleEditNameChange}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                      InputProps={{
                        disableUnderline: true,
                        // autoFocus: true,
                      }}
                      autoFocus
                    />
                  ) : (
                    <Typography
                      onClick={() => handleFolderClick(fileData, fileData.id)}
                      sx={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {fileData.name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCell,
                    color: '#00000099',
                    width: `${14.125 * factor}%`,
                  }}
                >
                  <Box>{fileData.fileVersion}</Box>
                  <Box>{fileData.revision}</Box>
                </TableCell>
                {currentPath.length === 1 && (
                  <TableCell
                    sx={{
                      ...tableCell,
                      pl: '1.25rem',
                      width: `${16.75 * factor}%`,
                    }}
                  >
                    {fileData.release}
                  </TableCell>
                )}
                <TableCell sx={{ ...tableCell, width: `${50.25 * factor}%` }}>
                  {currentPath.length === 1 ? (
                    <Status
                      status={fileData.status}
                      statusText={t(`status:${fileData.status}`)}
                    />
                  ) : (
                    <Box
                      component='div'
                      display='flex'
                      alignItems='center'
                      gap='0.5rem'
                    >
                      <Tooltip title={fileData.creator?.fullName}>
                        <Avatar
                          alt={fileData.creator?.fullName}
                          // src={`${apiUrl()}/files/download/${fileData.creator?.avatar}`}
                          src='/img/1.jpg'
                          // className='TAvatar'
                          sx={{
                            height: '2.125rem',
                            width: '2.125rem',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                      <Box
                        component='div'
                        display='flex'
                        flexDirection='column'
                      >
                        <Typography
                          component='p'
                          style={{ width: 'max-content' }}
                        >
                          {fileData.creator?.fullName.replace(/[a-z]{1,}/, '.')}
                        </Typography>
                        <Typography component='p' className='TDateAvatar'>
                          {dotDividerForDate(fileData.updated)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </TableCell>
                <TableCell sx={{ ...tableCell }}>
                  <LongMenu
                    id={fileData.id}
                    onRenameClick={handleRename}
                    onDeleteClick={onDeleteClick}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          {childFiles?.map((fileData) => (
            <React.Fragment key={fileData.id}>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: selectedRows.includes(fileData.id)
                    ? '#45BD8114'
                    : 'inherit',
                  '&:hover': {
                    backgroundColor: 'rgba(69, 189, 129, 0.04)',
                  },
                  maxHeight: '4rem',
                  cursor: 'pointer',
                }}
              >
                <TableCell
                  sx={{
                    display: 'flex',
                    justifyContent: !editMode ? 'space-between' : 'flex-start',
                    alignItems: 'center',
                    ml: !editMode ? 0 : '1rem',
                    mr: '0.5rem',
                    width: `${5.5 * factor}%`,
                    ...tableCell,
                  }}
                >
                  <Icon name='insert_drive_file' size={1.5} />
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCell,
                    width: `${26 * factor}%`,
                  }}
                >
                  {editNameMode && idForEdit === fileData.id ? (
                    <TextField
                      inputRef={inputRef}
                      value={editNameValue}
                      variant='standard'
                      color='product'
                      onChange={handleEditNameChange}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                      InputProps={{
                        disableUnderline: true,
                        autoFocus: true,
                      }}
                      autoFocus
                    />
                  ) : (
                    fileData.name
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCell,
                    color: '#00000099',
                    width: `${14.125 * factor}%`,
                  }}
                >
                  {fileData.documentNumber ? (
                    <>
                      <Box>{fileData.documentNumber}</Box>
                      <Box>Revision {fileData.revision}</Box>
                    </>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ ...tableCell, width: `${50.25 * factor}%` }}>
                  <Box
                    component='div'
                    display='flex'
                    alignItems='center'
                    gap='0.5rem'
                  >
                    <Tooltip title={fileData.creator?.fullName}>
                      <Avatar
                        alt={fileData.creator?.fullName}
                        // src={`${apiUrl()}/files/download/${fileData.creator?.avatar}`}
                        src='/img/1.jpg'
                        className='TAvatar'
                      />
                    </Tooltip>
                    <Box component='div' display='flex' flexDirection='column'>
                      <Typography
                        component='p'
                        style={{ width: 'max-content' }}
                      >
                        {fileData.creator?.fullName.replace(/[a-z]{1,}/, '.')}
                      </Typography>
                      <Typography component='p' className='TDateAvatar'>
                        {dotDividerForDate(fileData.updated)}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ ...tableCell }}>
                  <LongMenu
                    id={fileData.id}
                    onRenameClick={handleRename}
                    onDeleteClick={onDeleteClick}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
