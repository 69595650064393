export const statusClassMap = {
  released: 'released',
  approved: 'released',
  completed: 'released',
  waiting: 'waiting',
  check: 'check',
  empty: 'empty',
  edit: 'edit',
  obsolete: 'obsolete',
  checkout: 'checkout',
  archived: 'archive',
  archive: 'archive',
  archiviert: 'archive',
  basic: 'basic',
  free: 'free',
  regulatory: 'regulatory',
  entwurf: 'entwurf',
};
