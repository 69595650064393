/* eslint-disable no-useless-return */
import { Box, Button } from '@mui/material';
import { ProcessMapCard } from 'cards/process-map-card';
import { Icon } from 'components/icon';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalProcessCategoryAction } from 'modals/process-category-action';
import { routes } from 'routes/routes';
import {
  addProcessCategorie,
  fetchDocuments as fetchCategories,
  handleModal,
} from 'store/administration/process-category/processtype-slice';
import { fetchProcesses } from 'store/administration/process/process-slice';
import { handleActiveList, handleTabValue } from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';

export function ProcessMapPage({ pathName, parentTab, homePage }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const { t } = useTranslation(['product', 'processes']);

  const { processes, fetchProcessStatus } = useAppSelector((state) => ({
    processes: state.processReducer.documents,
    fetchProcessStatus: state.processReducer.fetchProcessStatus,
  }));

  const {
    categories,
    addProcessCatError,
    modal: newCategoryModal,
  } = useAppSelector((state) => ({
    categories: state.processTypeReducer.documents,
    addProcessCatError: state.processTypeReducer.addProcessCatError,
    modal: state.processTypeReducer.modal,
  }));

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const addNotification = useNotification();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProcesses());
  }, [dispatch]);

  const createNewCategorie = (catName: string, catDescription: string) => {
    const data = {
      name: catName,
      description: catDescription,
    };
    dispatch(addProcessCategorie(data));
    addNotification({
      text: t('processes:alertcategoryadded'),
    });
    setTitle('');
    setDescription('');
  };

  const navigateToPage = () => {
    navigate(routes().process);
    dispatch(handleTabValue(2));
    dispatch(handleActiveList({ parent: 2, item: 3 }));
  };

  return (
    <PageWrapper
      pageLabel={pathName}
      button={
        <Button
          variant='outlined'
          color='management'
          onClick={navigateToPage}
          disabled={fetchProcessStatus !== 'resolved'}
          startIcon={<Icon name={ICON.NEXT_DOCUMENT} />}
        >
          {t('processes:toprocessmanagement')}
        </Button>
      }
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      <Box component='div' className={classes.cardsBox}>
        {fetchProcessStatus === 'resolved'
          ? categories.map((category, index) => {
              return (
                <ProcessMapCard
                  key={category.id}
                  cardIndex={index}
                  category={category}
                  processes={processes}
                  options={categories}
                  errors={addProcessCatError}
                />
              );
            })
          : [1, 2, 3, 4].map((item) => (
              <TableSkeleton key={item} height={250} />
            ))}
        {fetchProcessStatus === 'resolved' ? (
          <Button
            variant='contained'
            color='processMap'
            startIcon={<Icon name={ICON.PLUS} fill='#fff' />}
            onClick={() => dispatch(handleModal(true))}
          >
            {t('processes:addprocesscategory')}
          </Button>
        ) : (
          <TableSkeleton height={36} />
        )}
      </Box>
      {newCategoryModal ? (
        <ModalProcessCategoryAction
          icon={{ name: ICON.CATEGORY }}
          open={newCategoryModal}
          onClose={() => dispatch(handleModal(false))}
          name={title}
          color='processMap'
          errors={addProcessCatError}
          description={description}
          onChangeTitle={(e) => setTitle(e.target.value)}
          onChangeDescription={(e) => setDescription(e.target.value)}
          header={t('processes:newprocesscategory')}
          onAction={(catName, catDescription) =>
            createNewCategorie(catName, catDescription)
          }
        />
      ) : null}
    </PageWrapper>
  );
}
