import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  ActiveListPayload,
  CommonSliceType,
  DiscardedRouteOpts,
} from './common.types';

const initialState: CommonSliceType = {
  narrowSidebar: false,
  isMinAppbar: false,
  isQuickAccessModal: false,
  isUpgradePlanModal: false,
  isPlanDetailsModal: false,
  selectedTariffPlan: {},
  changeTariffPlan: false,
  bookingSuccessModal: false,
  showNewFeatures: false,
  activeTabValue: 0,
  activeListItem: [0, 0, 0],
  hasChanges: false,
  modalHasChanges: false,
  discardedRoute: null,
};

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    handleHasChanges(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasChanges: action.payload,
      };
    },

    setDiscardedRoute(state, action: PayloadAction<DiscardedRouteOpts>) {
      const { route, item, tab } = action.payload;
      return {
        ...state,
        discardedRoute: {
          route,
          item,
          tab,
        },
      };
    },

    handleModalHasChanges(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modalHasChanges: action.payload,
      };
    },

    handleSidebar(state) {
      return {
        ...state,
        narrowSidebar: !state.narrowSidebar,
      };
    },

    hideSidebar(state) {
      return {
        ...state,
        narrowSidebar: false,
      };
    },

    handleAppbar(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isMinAppbar: action.payload,
      };
    },

    handleQAModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isQuickAccessModal: action.payload,
      };
    },
    handleUpgradePlanModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isUpgradePlanModal: action.payload,
      };
    },
    handlePlanDetailsModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isPlanDetailsModal: action.payload,
      };
    },
    handleTariffPlan(state, action) {
      return {
        ...state,
        selectedTariffPlan: { ...action.payload.option },
      };
    },
    handleBookingSuccessModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        bookingSuccessModal: action.payload,
      };
    },
    handleChangeTP(state) {
      return {
        ...state,
        changeTariffPlan: true,
      };
    },
    handleModalNewFeatures(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        showNewFeatures: action.payload,
      };
    },
    handleTabValue(state, action: PayloadAction<number>) {
      const newActiveItems = [...state.activeListItem];
      newActiveItems[action.payload] = 0;
      return {
        ...state,
        activeListItem: newActiveItems,
        activeTabValue: action.payload,
      };
    },

    handleActiveList(state, action: PayloadAction<ActiveListPayload>) {
      const { parent, item } = action.payload;
      const newActiveItems = [...state.activeListItem];
      newActiveItems[parent] = item;
      return {
        ...state,
        activeListItem: newActiveItems,
      };
    },
  },
});

export const {
  handleSidebar,
  hideSidebar,
  handleAppbar,
  handleQAModal,
  handleUpgradePlanModal,
  handlePlanDetailsModal,
  handleTariffPlan,
  handleBookingSuccessModal,
  handleChangeTP,
  handleModalNewFeatures,
  handleTabValue,
  handleActiveList,
  handleHasChanges,
  handleModalHasChanges,
  setDiscardedRoute,
} = commonSlice.actions;

// export default commonSlice.reducer;
