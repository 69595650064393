import { generateExtraReducers } from 'utils/utils';

import type { DocumentOverviewSliceTypes } from './types';

export const initialState: DocumentOverviewSliceTypes = {
  documents: [],
  elTypesList: [],
  documentTypes: [],
  processList: [],
  listOfUsers: [],
  listOfOrganizations: [],
  statusList: [],
  modal: false,
  status: null,
  error: null,
  singleDocument: null,
  singleStatus: null,
  singleError: null,
  singleLoader: false,
};

export const asyncThunkFetchDocuments = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'status',
  errorKey: 'error',
});

export const asyncThunkFetchSingleDocument = generateExtraReducers({
  initialStateProp: 'singleDocument',
  statusKey: 'singleStatus',
  errorKey: 'singleError',
  loadingKey: 'singleLoader',
});
