import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'title'
  | 'description'
  | 'wrapper'
  | 'textBox'
  | 'btnsBox'
  | 'descriptionBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, colors }) => {
    return {
      title: {
        color: colors.black,
        fontSize: `${typography.pxToRem(34)} !important`,
        lineHeight: `${typography.pxToRem(42)} !important`,
      },
      description: {
        color: colors.gray.primary,
        fontSize: `${typography.pxToRem(20)} !important`,
        lineHeight: `${typography.pxToRem(24)} !important`,
        letterSpacing: `${typography.pxToRem(0.15)} !important`,
      },
      descriptionBox: {
        width: '80ch',
        margin: '0 auto',
      },
      wrapper: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: `${typography.pxToRem(16)}`,
      },
      textBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${typography.pxToRem(18)}`,
        marginBottom: `${typography.pxToRem(50)}`,
      },
      btnsBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${typography.pxToRem(28)}`,
      },
    };
  }
);
