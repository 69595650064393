import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/en-GB';

import React from 'react';

import { DatePickerPropss } from './date-picker.types';
import { popperSx } from './styles';

export function DatePickerComponent({
  sx,
  value,
  onChange,
  inputFormat = 'MM/dd/yyyy',
  placeholder = 'mm/dd/yyyy',
  variant,
  popperStyle,
  className,
  color,
  label,
}: DatePickerPropss) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DesktopDatePicker
        inputFormat={inputFormat}
        value={value}
        label={label}
        className={className}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            sx={sx}
            color={color}
            variant={variant}
            placeholder={placeholder}
            {...params}
          />
        )}
        PopperProps={{ sx: popperStyle || popperSx }}
      />
    </LocalizationProvider>
  );
}
