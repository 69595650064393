import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './constants';

const documentsSetupSlice = createSlice({
  name: 'documentsSetupSlice',
  initialState,
  reducers: {
    handleUpdateFields(state, action) {
      state.documentSettings = { ...state.documentSettings, ...action.payload };
    },

    handleUpdateDocument(state, action) {
      const updated = state.documentSettings.documents.map((doc) => {
        if (doc.id === action.payload.id) {
          return action.payload;
        }
        return doc;
      });
      state.documentSettings = {
        ...state.documentSettings,
        documents: updated,
      };
    },

    handleActiveDocument(state, action: PayloadAction<number>) {
      const active = state.documentSettings.documents.find(
        (document, index) => index === action.payload
      );
      state.documentSettings = {
        ...state.documentSettings,
        activeDocument: active,
      };
    },
  },
});

export const {
  handleUpdateFields,
  handleActiveDocument,
  handleUpdateDocument,
} = documentsSetupSlice.actions;

export default documentsSetupSlice.reducer;
