// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrapper_parentPath__TngO7 {
  color: #333333;
  font-size: 1rem;
  letter-spacing: 0;
  font-weight: 400;
}

.page-wrapper_pagePath__pztvX {
  font-size: 1rem;
  letter-spacing: 0;
  color: black;
}

.page-wrapper_pagePath__pztvX:hover {
  text-decoration: underline;
}

.page-wrapper_pagePath__pztvX:hover {
  text-decoration: underline;
}

.page-wrapper_parentPath__TngO7:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/wrappers/page-wrapper/page-wrapper.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".parentPath {\n  color: #333333;\n  font-size: 1rem;\n  letter-spacing: 0;\n  font-weight: 400;\n}\n\n.pagePath {\n  font-size: 1rem;\n  letter-spacing: 0;\n  color: black;\n}\n\n.pagePath:hover {\n  text-decoration: underline;\n}\n\n.pagePath:hover {\n  text-decoration: underline;\n}\n\n.parentPath:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentPath": `page-wrapper_parentPath__TngO7`,
	"pagePath": `page-wrapper_pagePath__pztvX`
};
export default ___CSS_LOADER_EXPORT___;
