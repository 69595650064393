import { Button } from '@mui/material';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';

import React, { useRef, useState } from 'react';

export function ContextButtonComponent({
  disabled,
  color,
  icon = { name: 'file_upload_black', fill: '#45BD81', size: 1.5 },
  setDocsModal,
}) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const btnRef = useRef(null);

  const contextList = [
    {
      id: 1,
      name: 'description',
      label: 'Lokale Dateien/Dokumente',
      show: true,
      onClick: undefined,
      disabled: false,
    },
    {
      id: 2,
      name: 'qs_icon_blue',
      label: 'QSISTANT Dokumente',
      show: true,
      onClick: () => {
        setShowMenu(false);
        setDocsModal(true);
      },
      subLabel: '',
      disabled: false,
    },
    {
      id: 3,
      name: 'note_add',
      fill: '#45BD81',
      label: 'Neues Dokument',
      show: true,
      onClick: undefined,
      disabled: false,
    },
  ];

  return (
    <>
      <Button
        variant='text'
        color={color}
        ref={btnRef}
        disabled={disabled}
        onClick={() => setShowMenu((prev) => !prev)}
        startIcon={<Icon name={icon.name} fill={icon.fill} size={icon.size} />}
        sx={{
          '&:disabled': {
            opacity: 0.54,
            color: '#45BD81',
            cursor: 'not-allowed',
          },
          color: '#45BD81',
          pl: '0.5rem',
          pr: '0.5rem',
          '& .MuiButton-startIcon': {
            marginRight: '0.275rem',
          },
          '& .MuiButton-endIcon': {
            marginLeft: '0.4rem',
          },
          maxHeight: '36px',
          background: showMenu && `${icon.fill}14`,
        }}
        endIcon={
          <Icon
            name='arrow_drop_down'
            fill={icon.fill}
            style={{ rotate: showMenu ? '180deg' : '0deg' }}
          />
        }
      >
        Hochladen
      </Button>
      {showMenu ? (
        <ContextMenu
          anchorEl={btnRef}
          open={showMenu}
          onClose={() => setShowMenu(false)}
          dataList={contextList}
        />
      ) : null}
    </>
  );
}
