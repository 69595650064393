import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'widgetsBox'
  | 'widgetBox'
  | 'cardLabel'
  | 'cardDescription'
  | 'textBox'
  | 'topBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size }) => {
    return {
      widgetsBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: `${typography.pxToRem(size.font2XL)}`,
        paddingTop: `${typography.pxToRem(size.font2XL)}`,
      },
      widgetBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '2px solid rgba(0, 0, 0, 0.12)',
        borderRadius: `${typography.pxToRem(8)}`,
        width: '100%',
        backgroundColor: '#fff',
        height: `${typography.pxToRem(410)}`,
        padding: `${typography.pxToRem(37)} ${typography.pxToRem(32)}`,
      },
      cardLabel: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: `${typography.fontWeightMedium} !important`,
        fontSize: `${typography.pxToRem(20)} !important`,
        lineHeight: `${typography.pxToRem(24)} !important`,
        letterSpacing: '0 !important',
      },
      cardDescription: {
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: `${typography.pxToRem(24)} !important`,
        letterSpacing: '0 !important',
      },
      textBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${typography.pxToRem(12)}`,
      },
      topBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: `${typography.pxToRem(20)}`,
      },
    };
  }
);
