import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getOrganizationData,
  saveOrganizationData,
} from 'api/services/profile.services';
import type { Organization } from 'store/common-document.types';

import { asyncThunkSaveData, initialState } from './state';
import type { CompanyDataTypes } from './types';

export const fetchOrganization = createAsyncThunk(
  'organization/fetch',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getOrganizationData();
      dispatch(updateOrganizationData(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const saveOrganization = createAsyncThunk(
  'organization/save',
  async (data: CompanyDataTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await saveOrganizationData(data);
      dispatch(updateOrganizationData(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const companyProfileSlice = createSlice({
  name: 'companyProfileSlice',
  initialState,
  reducers: {
    updateOrganizationData(state, action: PayloadAction<Organization>) {
      const {
        name,
        logo,
        additionalInformation,
        availableLicenses,
        maxUsers,
        usedLicenses,
      } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          name,
          availableLicenses,
          maxUsers,
          usedLicenses,
          organization: action.payload,
          logo,
          industry: additionalInformation.industry,
          associatedStandards: additionalInformation.associatedStandards,
        },
      };
    },
  },
  extraReducers(builder) {
    asyncThunkSaveData(builder, saveOrganization);
  },
});

export const { updateOrganizationData } = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
