import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    wrapper: {
      width: '14.8rem',
      height: '12.25rem',
      borderRadius: '0.25rem',
      background: '#fff',
      cursor: 'pointer',
      transition: 'all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      '&:hover': {
        transform: 'scale(1.03, 1.03)',
      },
    },
    topBox: {
      backgroundColor: '#F5F5F5',
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1rem',
      width: '100%',
    },
    bottomBox: {
      padding: '1rem 0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '55%',
    },
    timeBox: {
      backgroundColor: '#6E358C1F',
      borderRadius: '0.125rem',
      padding: '0.25rem',
      display: 'flex',
      alignItems: 'center',
      width: 'max-content',
      gap: '0.25rem',
    },
    progressLabel: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    progressCardTime: {
      color: '#6E358C',
      fontSize: `${typography.pxToRem(12)} !important`,
      letterSpacing: '0.013rem !important',
      fontWeight: `${typography.fontWeightRegular} !important`,
    },
    documentLabel: {
      color: '#000000DE',
      fontSize: `${typography.pxToRem(14)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.008rem !important',
      '& > span': {
        fontWeight: `${typography.fontWeightRegular} !important`,
      },
    },
    circularProgress: {
      width: '4rem !important',
      height: '4rem !important',
      borderRadius: '50%',
      boxShadow: 'inset 0 0 0 0.45rem #6E358C24',
    },
  };
});
