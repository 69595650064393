import { Box, Button, Typography } from '@mui/material';
import { FiltersBox, SortFilterBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { FilterDropdown } from 'components/filter-dropdown';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import {
  resetFilters,
  setMultipleFilters,
} from 'store/common/page-filters/page-filters-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchDocuments } from 'store/qsistant/archive/archive-slice';
import type { ArchiveDocumentsTypes } from 'store/qsistant/archive/types';
import { sortByCriteria, tableMultipleFiltersChange } from 'utils/utils';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sortByOptions } from './archive.constants';
import useTableCells from './table-cells.hook';

export function ArchivePage({ pathName, parentTab, homePage }) {
  const { t } = useTranslation(['sort', 'status', 'document']);
  const dispatch = useAppDispatch();

  const { cells } = useTableCells();

  const { documents, listOfUsers, elTypesList, status } = useAppSelector(
    (state) => ({
      documents: state.archiveReducer.documents,
      error: state.archiveReducer.error,
      listOfUsers: state.archiveReducer.listOfUsers,
      elTypesList: state.archiveReducer.elTypesList,
      status: state.archiveReducer.status,
    })
  );

  const { persistedElTypes, persistedUsers } = useAppSelector((state) => ({
    persistedElTypes: state.pageFiltersSlice.archivePage.persistedElTypes,
    persistedUsers: state.pageFiltersSlice.archivePage.persistedUsers,
  }));

  const [filtered, setFiltered] = useState<ArchiveDocumentsTypes[]>(null);
  const [sortBy, setSortBy] = useState<(typeof sortByOptions)[number]['value']>(
    sortByOptions[0].value
  );

  const [filters, setFilters] = useState({
    userFilter: persistedUsers || [''],
    elTypeFilter: persistedElTypes || [''],
  });

  const handleResetFilters = () => {
    dispatch(resetFilters({ key: 'archivePage' }));
    setFilters({ userFilter: [''], elTypeFilter: [''] });
  };

  const filterByOptions = (
    arrayToFilter: Array<ArchiveDocumentsTypes>,
    filter1: string[],
    filter2: string[]
  ) =>
    arrayToFilter.filter((item) => {
      if (filter1.length > 1 && !filter1.includes(item.creator.fullName)) {
        return false;
      }
      if (filter2.length > 1 && !filter2.includes(item.elementType)) {
        return false;
      }
      return true;
    });

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const filterRes = filterByOptions(
      documents,
      filters.userFilter,
      filters.elTypeFilter
    );
    const sorting = sortByCriteria(filterRes, sortBy);
    setFiltered(sorting);
  }, [filters.userFilter, filters.elTypeFilter, documents, sortBy]);

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      {status === 'pending' || filtered === null || status === null ? (
        <Box component='div' mt={2}>
          {cells.map((cell) => (
            <TableSkeleton key={cell.id} />
          ))}
        </Box>
      ) : documents.length > 0 && status === 'resolved' ? (
        <>
          <Typography component='p' className='PageInfo'>
            Insgesamt {filtered.length} archivierte Dokumente verfügbar.
          </Typography>
          <SortFilterBox>
            <FiltersBox>
              <FilterDropdown
                multiple
                value={filters.elTypeFilter}
                isFilterApplied={filters.elTypeFilter.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'archivePage',
                      subKey: 'persistedElTypes',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'elTypeFilter', setFilters);
                }}
                options={elTypesList.map((el) => ({
                  ...el,
                  label: t(`document:${el.label}`),
                }))}
                filterLabel={t('sort:itemtype')}
              />
              <FilterDropdown
                multiple
                value={filters.userFilter}
                isFilterApplied={filters.userFilter.length > 1}
                onChange={(e) => {
                  dispatch(
                    setMultipleFilters({
                      key: 'archivePage',
                      subKey: 'persistedUsers',
                      values: e.target.value as string[],
                    })
                  );
                  tableMultipleFiltersChange(e, 'userFilter', setFilters);
                }}
                options={listOfUsers}
                filterLabel={t('sort:person')}
              />
              <Button
                variant='text'
                color='primary'
                disabled={
                  filters.userFilter.length === 1 &&
                  filters.elTypeFilter.length === 1
                }
                onClick={handleResetFilters}
              >
                {t('sort:resetfilter')}
              </Button>
            </FiltersBox>
            <SortSelect
              options={sortByOptions}
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              noOneChosen={sortBy === sortByOptions[0].value}
            />
          </SortFilterBox>
          {filtered && filtered.length > 0 ? (
            <Table cells={cells} data={filtered} />
          ) : (
            <EmptyList
              image={{ name: ICON.NOFILTERS_ARCHIVE }}
              title='Wir konnten keine Dokumente oder Nachweise dazu finden.'
              primaryButton={
                <Button
                  variant='outlined'
                  color='qsistant'
                  onClick={handleResetFilters}
                >
                  {t('document:resetfilter')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <EmptyList
          image={{ name: ICON.NO_ARCHIVES }}
          title='Du hast noch keine Dokumente oder Nachweise archiviert.'
          description='Du hast noch keine Dokumente und Nachweise archiviert. Archiviere mit Hilfe der “Archivieren- Icons and den Dokumenten und Nachweisen.'
        />
      )}
    </PageWrapper>
  );
}
