import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    modalStyle: {
      width: '19rem',
      padding: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    buttonStyle: {
      textTransform: 'capitalize !important',
      letterSpacing: '0.08rem !important',
    } as CSSProperties & { textTransform: 'capitalize !important' },
    headerStyle: {
      color: '#000000DE',
      letterSpacing: '0.008rem !important',
      fontSize: '1rem !important',
      fontWeight: `${typography.fontWeightMedium} !important`,
    },
    descriptionStyle: {
      color: '#00000099',
      letterSpacing: '0.015rem !important',
      fontSize: '0.875rem !important',
      fontWeight: `${typography.fontWeightRegular} !important`,
    },
  };
});
