import { Rating, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { RatingComponentProps } from './rating.types';
import { label } from './styles';

export function RatingComponent({
  title,
  max = 5,
  value,
  onChange,
}: RatingComponentProps) {
  return (
    <>
      <Typography component='legend' sx={label}>
        {title}
      </Typography>
      <Rating
        max={max}
        name='controlled_rating'
        icon={<Icon name='favorite_filled' fill='#FF6D75' />}
        emptyIcon={<Icon name='favorite' />}
        value={value}
        onChange={onChange}
      />
    </>
  );
}
