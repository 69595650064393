import { Box, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function useTableCells(addedDocument) {
  const { t } = useTranslation(['status', 'document']);
  const cells = [
    {
      id: '2yhj',
      heading: '',
      cellWidth: 0,
      render: (item) => (
        <Checkbox
          color='product'
          checked={addedDocument.some((el) => el.name === item.name)}
        />
      ),
    },
    {
      id: '5qlzi',
      heading: 'BEZEICHNUNG',
      cellWidth: 300,
      render: (item) => (
        <Box component='div' display='flex' alignItems='center' gap='.5rem'>
          <Tooltip title={t(`document:${item.elementType}`)}>
            <IconButton disableRipple sx={{ padding: 0 }}>
              <Icon name='new_qs_icon' size={1.5} />
            </IconButton>
          </Tooltip>
          <Typography component='p' className='TBasicText'>
            {item.name}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'asd65s',
      heading: 'REFERENZ',
      cellWidth: 50,
      render: (item) => (
        <Box component='div'>
          <Typography
            component='p'
            className='TBasicText'
            sx={{ width: 'max-content' }}
          >
            {item.documentNumber}
          </Typography>
          <Typography
            component='p'
            className='TMRevisionText'
            sx={{ width: 'max-content' }}
          >
            {`Revision ${item.revision}` || 'Revision 1'}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'asd4s',
      heading: ' ',
      cellWidth: 300,
      render: () => <Box component='div' />,
    },
  ];

  return {
    cells,
  };
}
