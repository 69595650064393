import { Box } from '@mui/material';

import React from 'react';

import { useStyles } from './styles';
import type { FilterProps } from './types';

export function FilterComponent({
  label,
  checked = false,
  onClick,
  disabled = false,
  value,
  color = 'qsistant',
}: FilterProps) {
  const classes = useStyles();
  return (
    <Box
      component='button'
      aria-label='filter_btn_badge'
      disabled={disabled}
      value={value}
      onClick={onClick}
      className={classes.filterBtnBadge}
      sx={{
        color: ({ palette }) => (checked ? palette[color].main : '#000000de'),
        background: ({ palette, colors }) =>
          checked ? `${palette[color].main}14` : colors.white,
        borderColor: ({ palette, colors }) =>
          checked ? palette[color].main : colors.gray.border,
        '&:hover': {
          color: ({ palette }) => palette[color].main,
          background: '#0000000a',
        },
        '&:disabled': {
          color: '#00000061',
          pointerEvents: 'all',
          '&:hover': {
            color: ({ palette }) => palette[color].main,
            opacity: 0.54,
          },
        },
      }}
    >
      {label}
    </Box>
  );
}
