import { generateExtraReducers } from 'utils/utils';

import type { UserSlice } from './types';

export const initialState: UserSlice = {
  user: {},
  validatedEmail: false,
  validateEmailStatus: null,
  validateEmailError: null,
  validateEmailLoading: false,
  saveUserDataStatus: null,
  saveUserDataError: null,
};

export const asyncThunkValidateEmailAuth = generateExtraReducers({
  initialStateProp: 'validatedEmail',
  statusKey: 'validateEmailStatus',
  errorKey: 'validateEmailError',
  loadingKey: 'validateEmailLoading',
});

export const asyncThunkSaveUserData = generateExtraReducers({
  initialStateProp: 'user',
  statusKey: 'saveUserDataStatus',
  errorKey: 'saveUserDataError',
});
