import { generateExtraReducers } from 'utils/utils';

import type { CompanyProfileSlice } from './types';

export const initialState: CompanyProfileSlice = {
  data: {
    name: '',
    industry: '',
    logo: '',
    associatedStandards: [],
    maxUsers: 0,
    usedLicenses: 0,
    availableLicenses: 0,
    organization: null,
  },
  saveStatus: null,
  saveError: null,
};

export const asyncThunkSaveData = generateExtraReducers({
  initialStateProp: 'data',
  statusKey: 'saveStatus',
  errorKey: 'saveError',
});
