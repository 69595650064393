import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    status: {
      padding: `${typography.pxToRem(6)}`,
      height: typography.pxToRem(size.fontL * 2),
      width: 'max-content',
      // maxHeight: `${typography.pxToRem(26)}`,
      borderRadius: '0.25rem',
      fontSize: `${typography.pxToRem(14)}`,
      textTransform: 'uppercase',
      letterSpacing: '0.055rem',
      fontWeight: `${typography.fontWeightMedium}`,
      display: 'flex',
      alignItems: 'center',
    },
    released: {
      color: '#5cb85c !important',
      backgroundColor: '#5cb85c2e !important',
    },
    waiting: {
      color: '#d9534f !important',
      backgroundColor: '#5cb85c2e !important',
    },
    check: {
      color: '#d9534f !important',
      backgroundColor: '#d9534f1f !important',
    },
    empty: {
      color: '#00000099 !important',
      backgroundColor: '#0000001f !important',
    },
    archive: {
      color: '#79553c',
      backgroundColor: '#79553c2e',
    },
    checkout: {
      color: '#d4bd35 !important',
      backgroundColor: '#d4bd351f !important',
    },
    edit: {
      color: '#d4a373 !important',
      backgroundColor: '#d4a3731f !important',
    },
    entwurf: {
      color: '#00000099 !important',
      background: '#0000000A !important',
    },
    obsolete: {
      color: '#79553c !important',
      backgroundColor: '#79553c2e !important',
    },
    basic: {
      color: '#4599bf',
      backgroundColor: '#4599bf14',
      '&:hover': {
        backgroundColor: 'rgba(69, 153, 191, 0.12)',
      },
    },
    free: {
      color: '#000000cc',
      backgroundColor: '#00000014',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
    regulatory: {
      color: '#401226',
      backgroundColor: '#40122614',
      '&:hover': {
        backgroundColor: 'rgba(64, 18, 38, 0.12)',
      },
    },
  };
});

export const statusData = {
  released: {
    color: '#5cb85c !important',
    backgroundColor: '#5cb85c2e !important',
  },
  waiting: {
    color: '#d9534f !important',
    backgroundColor: '#5cb85c2e !important',
  },
  check: {
    color: '#d9534f !important',
    backgroundColor: '#d9534f1f !important',
  },
  empty: {
    color: '#00000099 !important',
    backgroundColor: '#0000001f !important',
  },
  archive: {
    color: '#79553c',
    backgroundColor: '#79553c2e',
  },
  checkout: {
    color: '#d4bd35 !important',
    backgroundColor: '#d4bd351f !important',
  },
  edit: {
    color: '#d4a373 !important',
    backgroundColor: '#d4a3731f !important',
  },
  entwurf: {
    color: '#00000099 !important',
    background: '#0000000A !important',
  },
  obsolete: {
    color: '#79553c !important',
    backgroundColor: '#79553c2e !important',
  },
};
