import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCategoriesList,
  handleAddCategory,
  handleEditCategory,
  removeCategory,
} from 'api/services/processtype.services';
import { generateExtraReducers } from 'utils/utils';

import { NewCategorieTypes, ProcessCategoriesTypes, RootState } from './types';

const initialState: ProcessCategoriesTypes = {
  documents: [],
  modal: false,
  addProcessCatStatus: null,
  addProcessCatError: null,
};

const asyncThunkFetchProcessCategoryDocuments = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'addProcessCatStatus',
  errorKey: 'addProcessCatError',
});

export const fetchDocuments = createAsyncThunk(
  'processCategorie/fetchProcessCategoryDocuments',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCategoriesList();
      dispatch(updateDocuments(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const addProcessCategorie = createAsyncThunk(
  'processCategorie/addProcessCategorie',
  async (data: NewCategorieTypes, { rejectWithValue, dispatch, getState }) => {
    const { documents } = (getState() as RootState).processTypeReducer;
    try {
      const response = await handleAddCategory(data);
      dispatch(updateSingleDocument(response.data));
      return documents.push(response.data);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const editProcessCategorie = createAsyncThunk(
  'processCategorie/editProcessCategorie',
  async (data: NewCategorieTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await handleEditCategory(data.id, data);
      dispatch(updateSingleDocument(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const removeProcessCategorie = createAsyncThunk(
  'processCategorie/removeProcessCategorie',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await removeCategory(id);
      dispatch(handleRemoveCategorie(id));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const processTypeSlice = createSlice({
  name: 'processTypeSlice',
  initialState,
  reducers: {
    updateDocuments(state, action) {
      state.documents = action.payload;
    },

    updateSingleDocument(state, action) {
      const isExist = state.documents.find(
        (categorie) => categorie.id === action.payload.id
      );
      if (isExist) {
        const filtered = state.documents.filter(
          (item) => item.id !== action.payload.id
        );
        state.documents = [...filtered, action.payload];
      } else {
        state.documents = [...state.documents, action.payload];
      }
    },

    handleRemoveCategorie(state, action: PayloadAction<number>) {
      const filtered = state.documents.filter(
        (process) => process.id !== action.payload
      );
      state.documents = filtered;
    },

    handleModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    handleDndCategory(
      state,
      action: PayloadAction<{
        dragIndex: number;
        hoverIndex: number;
        id: number;
      }>
    ) {
      const { dragIndex, hoverIndex, id } = action.payload;

      const { documents: categories } = state;
      const cardToMove = categories.find((category) => category.id === id);
      const reorderedDocuments = [...categories];

      reorderedDocuments.splice(dragIndex, 1);
      reorderedDocuments.splice(hoverIndex, 0, cardToMove);
      return {
        ...state,
        documents: reorderedDocuments,
      };
    },
  },

  extraReducers: (builder) => {
    asyncThunkFetchProcessCategoryDocuments(builder, addProcessCategorie);
  },
});

export const {
  updateDocuments,
  updateSingleDocument,
  handleRemoveCategorie,
  handleModal,
  handleDndCategory,
} = processTypeSlice.actions;

export default processTypeSlice.reducer;
