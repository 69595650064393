import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { theme } from './theme';
import type {
  CustomStepIconProps,
  CustomStepperComponentProps,
  IconProps,
} from './types';

function CheckIcon(props: IconProps) {
  const { fill, name, size } = props;
  return <Icon name={name} fill={fill} size={size} />;
}

function StepIcon(props: CustomStepIconProps) {
  const { icon, color, completed, onAction, id } = props;
  return (
    <Icon
      name={icon.name}
      fill={icon.fill || '#fff'}
      size={icon.size || 1.5}
      onClick={() => (completed ? onAction(id) : undefined)}
      style={{
        padding: '0.5rem',
        borderRadius: '50%',
        background: color,
        boxShadow: '0px 3px 6px #00000029',
      }}
    />
  );
}

export function CustomStepperComponent({
  activeStep,
  steps,
  onClick,
  primaryColor = '#6E358C',
}: CustomStepperComponentProps) {
  return (
    <ThemeProvider theme={theme}>
      <Stepper
        orientation='vertical'
        activeStep={activeStep}
        className='CustomStepper'
      >
        {steps.map((step, index) => (
          <Step key={step.id}>
            <StepLabel
              StepIconComponent={(props) =>
                StepIcon({
                  ...props,
                  ...step,
                  color: primaryColor,
                  onAction: () => onClick(index),
                })
              }
              className='MainIcon'
              // onClick={() => (onClick ? onClick(index) : undefined)}
            >
              {step.label}
              <StepLabel
                className='CheckIcon'
                StepIconComponent={(props) =>
                  CheckIcon({
                    ...props,
                    name: ICON.CHECKED,
                    size: 1.25,
                    fill: primaryColor,
                  })
                }
              />
            </StepLabel>
            <StepContent>
              <Typography
                sx={{ visibility: 'hidden', height: '3rem' }}
                component='p'
              >
                Hey Konstantin!!!
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </ThemeProvider>
  );
}
