import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiStepper: {
      styleOverrides: {
        root: {
          '&.CustomStepper': {
            '& .MuiStep-root': {
              width: 'max-content',
            },
            '& .MuiStepLabel-labelContainer': {
              '& > span': {
                display: 'flex',
                gap: '0.25rem',
                alignItems: 'center',
              },
            },
            '& .MuiStepLabel-root': {
              padding: 0,
              gap: '0.5rem',
              '&.CheckIcon': {
                padding: 0,
                boxShadow: 'none',
                background: 'none',
                cursor: 'auto',
                '& .MuiStepLabel-iconContainer': {
                  padding: 0,
                  background: 'none',
                  borderRadius: '50%',
                },
                '& .MuiStepLabel-iconContainer.Mui-disabled': {
                  display: 'none',
                  cursor: 'auto !important',
                },
                '& .MuiStepLabel-iconContainer.Mui-active': {
                  display: 'none',
                  cursor: 'auto !important',
                },
                '& .MuiStepLabel-iconContainer.Mui-completed': {
                  opacity: '1 !important',
                  cursor: 'auto !important',
                },
              },
              '&.MainIcon': {
                '& .MuiStepLabel-iconContainer.Mui-disabled': {
                  opacity: 0.54,
                },
                '& .MuiStepLabel-iconContainer.Mui-completed': {
                  opacity: 0.54,
                  cursor: 'pointer',
                },
                '& .MuiStepLabel-label.Mui-disabled': {
                  opacity: 0.54,
                },
                '& .MuiStepLabel-label.Mui-completed': {
                  opacity: 0.54,
                  cursor: 'auto',
                },
              },
            },
            '& .MuiStepLabel-label': {
              fontSize: '0.875rem',
              color: '#000000DE',
              letterSpacing: '0.008rem',
              fontWeight: 400,
              opacity: 1,
            },
            '& .MuiStepConnector-root': {
              marginLeft: '1.2rem',
            },
          },
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          '&.MuiStepContent-root': {
            marginLeft: '1.2rem',
          },
          '&.MuiStepContent-line': {
            borderColor: '#D5D5D5',
          },
        },
      },
    },
  },
});
