import { Box, Button, Typography } from '@mui/material';
import { InputField } from 'components/custom-input';
import { Icon } from 'components/icon';

import React, { useState } from 'react';

import { useStyles } from './styles';
import type { ResponsibilityStepProps } from './types';

export function ResponsibilityStep({
  stepId,
  toNextStep,
  audits,
  createField,
  removeField,
  editField,
  onSelect,
  selectedOption,
}: ResponsibilityStepProps) {
  const classes = useStyles();

  const [isCreation, setIsCreation] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');

  return (
    <>
      <Typography component='h4' className={classes.actionTitle}>
        Wer trägt die Verantwortung für die Überwachung interner Audits?
      </Typography>
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        gap='1rem'
        mb='1.5rem'
      >
        {audits.length > 0 &&
          audits.map((audit) => (
            <InputField
              key={audit.id}
              checker
              check={!!selectedOption.find((i) => i.id === audit.id)}
              customWidth
              className={classes.cycleAudits}
              onChange={(e) =>
                editField({ id: audit.id, label: e.target.value }, stepId)
              }
              onClick={() =>
                onSelect({ label: audit.label, id: audit.id }, stepId)
              }
              onDelete={(e) => {
                e.stopPropagation();
                removeField(audit.id, stepId);
              }}
              label={audit.label}
              {...audit}
            />
          ))}
        {isCreation && (
          <InputField
            className={classes.cycleAudits}
            label={label}
            customWidth={!!label}
            checker
            check
            onClick={() => {
              if (!label) return;
              createField(label, stepId);
              setIsCreation(false);
              setLabel('');
            }}
            onChange={(e) => setLabel(e.target.value)}
            onBlur={() => {
              if (!label) return;
              setIsCreation(false);
              createField(label, stepId);
              setLabel('');
            }}
          />
        )}
      </Box>
      <Button
        variant='text'
        color='documentSetup'
        onClick={() => setIsCreation(true)}
        startIcon={<Icon name='add' fill='#6E358C' size={1.5} />}
      >
        AUSWAHL HINZUFÜGEN
      </Button>
      <Box component='div' display='flex' justifyContent='flex-end' mt='2rem'>
        <Button
          variant='contained'
          color='documentSetup'
          onClick={() => toNextStep(selectedOption, stepId)}
        >
          EINGABEN SPEICHERN & WEITER
        </Button>
      </Box>
    </>
  );
}
