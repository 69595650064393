import { IconButton } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import type { FloatingButtonProps } from './button.types';

const floatingButton = {
  borderRadius: '50%',
  width: '2.5rem',
  height: '2.5rem',
  background: '#fff',
  position: 'absolute',
  zIndex: 2,
  boxShadow:
    '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
};

export function FloatingButtonComponent({
  direction,
  onClick,
  sx,
}: FloatingButtonProps) {
  return (
    <IconButton
      type='button'
      disableRipple
      onClick={onClick}
      sx={{ ...floatingButton, ...sx }}
    >
      <Icon
        name={direction === 'left' ? 'arrow_back_ios' : 'arrow_forward_ios'}
        size={1.25}
      />
    </IconButton>
  );
}
