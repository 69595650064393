import { createTheme } from '@mui/material';

import type { TypographyCustomOptions } from './types';

const fontFamily = ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',');

const typography: TypographyCustomOptions = {
  fontSize: 16,
  // fontFamily,
  htmlFontSize: 16,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 900,
  pxToRem: (size: number) => `${size / 16}rem`,
};

const customOptions = {
  size: {
    font_4: 4,
    font_8: 8,
    fontXXS: 9,
    fontXS: 10,
    fontXSA: 11,
    fontS: 12,
    fontS13: 13,
    fontM: 14,
    fontXM: 15,
    fontL: 16,
    fontXL: 18,
    font1XL: 19,
    font2XL: 20,
    font2XXL: 22,
    font24L: 24,
    font3XL: 36,
    font3XLL: 38,
  },
  colors: {
    white: '#fff',
    black: '#000000',
    text: {
      primary: '#000000DD', // rgba(0, 0, 0, 0.87)
    },
    gray: {
      icon: '#00000099', // rgba(0, 0, 0, 0.6)
      background: '#f5f5f5',
      border: '#d3d3d3',
      primary: '#9E9E9E',
      secondary: '#777777',
      darkGray: '#373a3c',
      shadow: '#00000029',
    },
    primary: {
      DrawerListColor: 'rgba(0, 0, 0, 0.8)',
    },
    secondary: {
      DrawerListActiveColor: '#000000',
      IconActiveColor: '#3F98BD',
      IconActiveManagementColor: '#E0D289',
    },
  },
};

export const muiTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          font-size: ${typography.pxToRem(customOptions.size.fontL)};
          font-family: ${fontFamily};
        }

        @media (max-width: 1670px) {
          html {
            font-size: ${typography.pxToRem(customOptions.size.fontM)};
          }
        }

        @media (max-width: 480px) {
          html {
            font-size: ${typography.pxToRem(customOptions.size.fontXS)};
          }
        }
      `,
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme }) => ({
          color: theme.palette.background.paper,
          background: theme.palette.deny.main,
          fontSize: theme.typography.pxToRem(theme.size.fontS),
          letterSpacing: 0,
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          '&.SidebarTooltip': {
            background: theme.palette.background.paper,
            color: '#000000',
            borderRadius: '0.1rem',
            border: '0.01rem solid #000000',
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#00000014',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#00000014',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: (props) => ({
          '&.Tab': {
            '&.MuiTab-root': {
              minHeight: '48px',
              height: '3rem',
              display: 'flex',
              gap: '0.5rem',
            },
          },
          '&.Capitalize': {
            textTransform: 'capitalize',
          },
          '&.IconTextSpace': {
            gap: props.theme.typography.pxToRem(8),
          },
          '&.ManagamentTab': {
            '&.Mui-selected': { color: props.theme.palette.management.main },
          },
          '&.QsistantTab': {
            '&.Mui-selected': { color: props.theme.palette.qsistant.main },
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: (props) => ({
          '&.Management': {
            backgroundColor: props.theme.palette.management.main,
          },
          '&.Qsistant': {
            backgroundColor: props.theme.palette.qsistant.main,
          },
        }),
        root: {
          '&.SingleDocumentTabs': {
            width: '100%',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-around',
              '& .MuiTab-root': {
                maxWidth: '50rem',
                minWidth: '50%',
              },
            },
            '&.MuiTabs-root': {
              minHeight: '4rem',
              background: '#fff',
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.PageHeader': {
            fontSize: '2.375rem',
            letterSpacing: 0,
            color: '#747474',
            textTransform: 'capitalize',
          },
          '&.TableHeader': {
            fontSize: '0.875rem',
            opacity: 0.54,
            letterSpacing: '0.11px',
            color: '#000000',
            textTransform: 'uppercase',
          },
          '&.PageInfo': {
            fontSize: '0.875rem',
            letterSpacing: '0.015rem',
            color: '#00000099',
          },
          '&.DatasetEmptyInfo': {
            letterSpacing: '0.015rem',
            color: '#00000099',
            lineHeight: '1.1875rem',
          },
          '&.InfoSmall': {
            fontSize: '0.75rem',
            letterSpacing: '0.015rem',
            color: '#00000099',
            lineHeight: '1',
          },
          '&.ModalHeader': {
            fontSize: '1.25rem',
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 500,
            letterSpacing: '0.009rem',
          },
          '&.SDCaption': {
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.1px',
          },
          '&.SDText': {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.1px',
          },
          '&.TBasicText': {
            letterSpacing: '0.12px',
          },
          '&.TMRevisionText': {
            fontSize: '0.875rem',
            letterSpacing: '0.25px',
            color: '#00000099',
          },
          '&.TMMessage': {
            letterSpacing: '0.5px',
            color: '#fff',
          },
          '&.TDateAvatar': {
            fontSize: '0.75rem',
            letterSpacing: '0.09px',
          },
          '&.TextSnippet': {
            fontWeight: 500,
            fontSize: '0.875rem',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.1px',
            lineHeight: '1.5rem',
            marginBottom: '0.25rem',
            paddingTop: '0.875rem',
          },
          '&.TextSnippetDesc': {
            fontSize: '0.875rem',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.1px',
            lineHeight: '1.25rem',
            minWidth: '180ch',
          },
          '&.TextPhasesBtn': {
            fontSize: '.875rem',
            color: '#fff',
            letterSpacing: '0.078rem',
          },
          '&.TextPhasesBtnClrd': {
            fontSize: '.875rem',
            color: '#45BD81',
            letterSpacing: '0.078rem',
          },
          '&.TextProduktSubtitle': {
            fontSize: '.875rem',
            letterSpacing: '0.14px',
            color: '#000000CC',
          },
          '&.TextProduktMenuItems': {
            letterSpacing: '0.03125rem',
            fontWeight: 500,
          },
          '&.ProductCell': {
            letterSpacing: '0.12px',
            borderBottom: 'none',
            lineHeight: '1.1875',
          },
          '&. TextPhasesSubTittle': {
            fontSize: '1.25rem',
            fontWeight: 500,
            letterSpacing: '0',
          },
          '&.SectionTitle': {
            letterSpacing: '0.02rem',
            fontWeight: 500,
            fontSize: '1.25rem',
          },
          '&.SubLabel': {
            color: '#7F7F7F',
            letterSpacing: '0.033rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: ({ ownerState, theme }) => ({
          color: theme.colors.white,
          '&:disabled': {
            color: theme.colors.white,
            backgroundColor: theme.palette[ownerState.color].main,
          },
        }),
        outlined: {
          border: '1px solid #0000001F',
          width: 'max-content',
          '&:hover': {
            borderColor: '#0000001F',
          },
        },

        root: ({ ownerState, theme }) => ({
          fontSize: theme.typography.pxToRem(theme.size.fontM),
          fontWeight: theme.typography.fontWeightMedium,
          borderRadius: theme.typography.pxToRem(4),
          fill:
            theme.palette[ownerState.color].main || theme.palette.qsistant.main,
          '&:disabled': {
            opacity: 0.54,
          },
          '&.ProductButton': {
            width: 'max-content',
            color: '#45BD81',
            letterSpacing: '0.078rem',
            borderColor: '#0000001F',
            '&:hover': {
              borderColor: '#61c994',
            },
          },
          '&.ProductOtlined': {
            lineHeight: '1.5rem',
            '&:hover': {
              background: 'rgb(27%, 74%, 51%, 0.12);',
              borderColor: 'rgb(27%, 74%, 51%, 0.12)',
            },
          },
          '&.ProductPhasesButton': {
            width: 'max-content',
            background: '#45BD81',
            borderRadius: '0.3125rem',
            '&:hover': {
              borderColor: '#6bcb99',
            },
          },
          '&.ProductPhasesWhiteButton': {
            width: 'max-content',
            borderRadius: '0.3125rem',
            '&:hover': {
              borderColor: '#61c994',
            },
          },
        }),
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          gap: '0.3rem',
          fontWeight: 500,
          fontSize: '0.875rem',
          zIndex: 1,
          color: '#fff',
          '&.Mui-disabled': {
            opacity: 0.54,
            color: '#fff',
          },
          '&.management': {
            background: '#B4A656',
          },
          '&.primary': {
            background: '#4599bf',
          },
          '&.product': {
            background: '#45BD81',
          },
          '&.AbsolutePosition': {
            position: 'fixed',
            bottom: '2.5rem',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            '& .MuiOutlinedInput-root:hover': {
              '&:not(.Mui-disabled)': {
                '& > fieldset': {
                  borderColor: theme.palette[ownerState.color].main,
                },
              },
            },
            '& .MuiFilledInput-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.06)',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette[ownerState.color].main,
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: theme.palette[ownerState.color].main,
              },
            },
            '& .Mui-disabled': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
            },
            '&.ProductModalInp': {
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  borderColor: '#45BD81',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#45BD81',
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: '#45BD81',
                },
              },
            },
            '&.SingleProductError': {
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  borderColor: '#c52525',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#c52525',
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: '#c52525',
                },
              },
              '& .MuiOutlinedInput-input ': { padding: '5px 12px' },
              '& .MuiOutlinedInput-root': {
                p: '5px',
              },
            },
            '&.SingleProduct': {
              '& .MuiOutlinedInput-input ': { padding: '5px 12px' },
              '& .MuiOutlinedInput-root': {
                p: '5px',
              },
            },
            '&.MyProfileFieldDisabled': {
              '& .MuiInputLabel-root': {
                color: '#000000CC',
              },
              '& .Mui-disabled:not(label)': {
                backgroundColor: '#F5F5F5',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
              },
            },
          };
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          letterSpacing: '0.1px',
          fontSize: '0.875rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: 400,
          lineHeight: '1.5rem',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          letterSpacing: 0,
          '&.SDAvatar': {
            width: theme.typography.pxToRem(theme.size.font3XL),
            height: theme.typography.pxToRem(theme.size.font3XL),
            cursor: 'pointer',
          },
          '&.TAvatar': {
            width: theme.typography.pxToRem(theme.size.font3XL),
            height: theme.typography.pxToRem(theme.size.font3XL),
            cursor: 'pointer',
          },
          '&.DatasetAvatar': {
            width: '2.25rem',
            height: '2.25rem',
            border: '2px solid #fff',
            background: '#25583F',
          },
        }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '&.BasicTable': {
            minWidth: 650,
            borderCollapse: 'separate',
            borderSpacing: '0 0.8rem',
          },
          '&.ModalBasicTable': {
            minWidth: 650,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.HeaderCell': {
            fontSize: '1rem',
            letterSpacing: '0.12px',
            color: '#777777',
            textTransform: 'uppercase',
            background: '#f5f5f5',
            zIndex: 2,
            '@media (max-width: 1200px)': {
              '&.HeaderCell': {
                '&:nth-of-type(6)': {
                  paddingRight: '1.8rem',
                },
              },
            },
          },
          '&.SnippetCell': {
            borderBottom: 0,
            padding: '0 1rem 1rem 1.5rem',
            wordBreak: 'break-all',
          },
          '&.ProductCollapse': {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.875rem',
            textTransform: 'uppercase',
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.HeadRow': {
            '&:nth-of-type(n) td, &:nth-of-type(n) th': {
              border: 0,
              padding: '0 1.5rem',
            },
          },
          '&.BodyRow': {
            '&:nth-of-type(n) td, &:nth-of-type(n) th': {
              border: 0,
              borderRadius: 1,
              height: '4.5rem',
              padding: '0 1.5rem',
              cursor: 'pointer',
            },
            background: '#FFFFFFDE',
            '&:hover': {
              background: '#4599bf14',
            },
          },
          '&.SnippetRow': {
            background: '#FFFFFFDE',
            position: 'relative',
            top: '-0.875rem',
            '&:hover': {
              background: '#4599bf14',
            },
          },
          '&.ModalTableRow': {
            '&:hover': {
              background: 'rgba(69, 189, 129, 0.04)',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: 'none',
          background: theme.colors.white,
          padding: '0 1rem',
          height: '6rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: ({ ownerState, theme }) => ({
          color: theme.palette[ownerState.color].main,
          fontSize: theme.typography.pxToRem(theme.size.fontS),
          letterSpacing: theme.typography.pxToRem(0.5),
          position: 'initial',
        }),
        popupIndicator: ({ ownerState, theme }) => ({
          fill: theme.palette[ownerState.color].main,
        }),
      },
    },
  },
  // New [colors, paletteColors] should be added to declaration.d.ts => createPalette
  palette: {
    secondary: {
      main: '#E0D289',
    },
    primary: {
      main: '#3F98BD',
    },
    deny: {
      main: '#d9534f',
    },
    management: {
      main: '#B4A656',
      hover: '#f9f6e2',
    },
    documentSetup: {
      main: '#6E358C',
    },
    processMap: {
      main: '#7AB8D2',
    },
    outlined: {
      main: '#000000CC',
      hover: '#000000CC12',
    },
    qsistant: {
      main: '#4599bf',
      hover: '#4599bf12',
    },
    product: {
      main: '#45BD81',
      hover: '#45BD8112',
    },
    menuSelected: {
      main: '#00000014',
    },
    text: {
      primary: '#000000DE',
    },
    icon: {
      main: '#00000099',
    },
  },
  typography,
  ...customOptions,
});
