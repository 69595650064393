import { Box, Button, Divider, TextField } from '@mui/material';
import { ModalHeader } from 'components/modal-header';
import { BasicSelect } from 'components/select/basic-select';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function ModalActionCustomer({
  open,
  header,
  onClose,
  onAction,
  options,
  name,
  type,
  errors,
  onChangeName,
  onChangeType,
  disabled = false,
  color = 'qsistant',
  icon,
  isEditing = false,
}) {
  const { t } = useTranslation(['customer', 'modal']);
  const inputRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const hasViolation = (fieldType) => {
    if (!errors) return false; // no errors no violation
    const violation = errors?.violations.filter(
      (v) => v.propertyPath === fieldType
    );
    return violation && violation.length > 0;
  };

  const getViolation = (fieldType) => {
    if (!hasViolation(fieldType)) return ''; // no errors no violation

    const violation = errors?.violations.filter(
      (v) => v.propertyPath === fieldType
    );

    return violation && violation.length > 0 ? violation[0].title : '';
  };
  return (
    <ModalWrapper open={open} sx={{ width: '35rem' }}>
      <ModalHeader
        header={header}
        onClose={onClose}
        icon={{ name: icon.name }}
        primaryColor={color}
      />
      <Divider />
      <Box
        component='div'
        padding='1.5rem'
        display='flex'
        flexDirection='column'
        gap='1.5rem'
      >
        <TextField
          autoComplete='off'
          label={t('customer:name')}
          color={color}
          value={name}
          onChange={onChangeName}
          error={hasViolation('name')}
          helperText={`${getViolation('name')}`}
          inputRef={inputRef}
        />
        <BasicSelect
          disabled={disabled}
          color={color}
          value={type}
          onChange={onChangeType}
          options={options}
          label={t('customer:type')}
        />
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='flex-end'
        padding='0 1.5rem 1.5rem 1.5rem'
      >
        <Button variant='text' onClick={onClose} color={color}>
          {t('modal:abort')}
        </Button>
        <Button
          disabled={name.length < 4 || !type}
          variant='contained'
          color={color}
          onClick={() => onAction(name, type)}
        >
          {isEditing ? t('modal:save') : t('modal:success')}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
