import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  addToFavourite,
  removeFromFavourite,
} from 'api/services/document-overview.services';
import { addToArchive } from 'api/services/supporting-documents.services';
import { SingleComparisonCard } from 'cards/single-comparison-card';
import { SingleDocumentCard } from 'cards/single-document-card';
import { ActionBox, BackBox, ComparisonCardsBox } from 'components/common';
import { Icon } from 'components/icon';
import { SingleDocumentSidebar } from 'components/single-document-sidebar';
import type { StatusValues } from 'components/status/status.types';
import { TinyMce } from 'components/tinymce-editor';
import { ToggleButtonGroup } from 'components/toggle-button-group';
import { ICON } from 'enum/icons.enum';
import { ConfirmWithPasswordModal } from 'modals/confirm';
// import { ConfirmModalWithPassword } from 'modals/confirm/confirm-with-password.modal';
import { SwitchModeModal } from 'modals/switch-mode';
import { routes } from 'routes/routes';
import { fetchAllTypesAndProcesses } from 'store/administration/document-type/document-type-slice';
import type { CategoryTypes } from 'store/administration/process-category/types';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchSingleDocument } from 'store/qsistant/supporting-documents/supporting-documents-slice';
import type { SupportingDocumentTypes } from 'store/qsistant/supporting-documents/types';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { EditableContent } from './component/card-content/editable-content';
import { UneditableContent } from './component/card-content/uneditable-content';
import { useStyles } from './styles';
import {
  customerType,
  documentFileList,
  revisions,
  toggleOptions,
} from './supporting-documents.constants';

export function SingleSupportPage({
  pathName,
  parentRoute,
  parentTab,
  homePage,
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation(['document', 'common', 'modal', 'home']);
  const { pathname: path } = useLocation();
  const classes = useStyles();

  const { isLoading } = useAppSelector((state) => ({
    isLoading: state.supportingDocumentsReducer.singleLoading,
  }));

  const [toggleValue, setToggleValue] = useState<
    'visibility' | 'edit' | 'compare'
  >('visibility');

  const [modal, setModal] = useState(false);
  const [compareMode, setCompareMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [cardExpanded, setCardExpanded] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [customer, setCustomer] = useState(customerType[0].value);

  const [review, setReview] = useState('');
  const dataToCompare = revisions.find((rev) => rev.value === review);

  const [documentData, setDocumentData] = useState<{
    document: SupportingDocumentTypes | null;
    status: StatusValues | null;
    documentType: string;
    documentProcess: string;
    category: string;
    documentName: string;
    isFavourite: boolean;
    documentId: number;
    content: string;
  }>({
    document: null,
    status: null,
    documentId: null,
    documentType: '',
    documentProcess: '',
    category: '',
    documentName: '',
    isFavourite: false,
    content: '',
  });

  const [commondata, setCommonData] = useState<{
    processes: ProcessDocumentsTypes[] | null;
    types: CategoryTypes[] | null;
  }>({
    processes: null,
    types: null,
  });

  useEffect(() => {
    dispatch(fetchSingleDocument(id)).then(({ payload }) => {
      const singleDocument: SupportingDocumentTypes = payload;
      setDocumentData({
        document: singleDocument,
        status: singleDocument.status,
        content: singleDocument.content,
        documentId: singleDocument.id,
        documentType: singleDocument.documentType.name,
        documentProcess: singleDocument.process.name,
        category: singleDocument.process.category.name,
        documentName: singleDocument.name,
        isFavourite: singleDocument.isFavourite,
      });
    });
  }, [id]);

  useEffect(() => {
    dispatch(fetchAllTypesAndProcesses()).then((data) => {
      setCommonData({
        processes: data.payload.processes,
        types: data.payload.documentTypes,
      });
    });
  }, [dispatch]);

  const updateDocumentData = (
    field: keyof typeof documentData,
    value: string | boolean
  ) => {
    setDocumentData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const onFavouriteChange = () => {
    if (!documentData.isFavourite) {
      addToFavourite(documentData.documentId).then(() =>
        updateDocumentData('isFavourite', true)
      );
    } else {
      removeFromFavourite(documentData.documentId).then(() =>
        updateDocumentData('isFavourite', false)
      );
    }
  };

  const onArchiveClick = (data) => {
    addToArchive(documentData.documentId, data).then(() => {
      navigate(routes().archive);
    });
  };

  const boxIcons = [
    {
      id: 3,
      iconName: ICON.RESTORE,
      title: 'Auf Revision X zurücksetzen',
      onClick: undefined,
    },
    { id: 4, iconName: ICON.SHARE, title: 'Teilen', onClick: undefined },
    {
      id: 5,
      iconName: ICON.ARCHIVE,
      title: 'Archivieren',
      onClick: () => setConfirmationOpen(true),
    },
    {
      id: 7,
      iconName: ICON.DOWNLOAD,
      title: 'Herunterladen',
      onClick: undefined,
    },
  ];

  if (isLoading) {
    return (
      <PageWrapper
        pathName={pathName}
        parentTab={parentTab}
        homePage={homePage}
        parentRoute={parentRoute}
        nestedDocument={`${t('document:recorddocument')}`}
      >
        <CircularProgress />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      parentRoute={parentRoute}
      nestedDocument={`${t('document:recorddocument')} "${
        documentData?.documentName
      }"`}
    >
      <BackBox>
        <IconButton
          disableRipple
          sx={{ padding: 0 }}
          onClick={() =>
            path.includes('home')
              ? navigate(routes().home)
              : navigate(routes().support)
          }
        >
          <Icon name={ICON.ARROW_BACK_IOS} size={2} fill='rgba(0, 0, 0, 0.6)' />
          <Typography component='h4' className='PageHeader'>
            {path.includes('home')
              ? t('home:toHome')
              : 'Alle Nachweisdokumente'}
          </Typography>
        </IconButton>
      </BackBox>
      <ActionBox>
        <Box component='div' display='flex' gap='1rem' alignItems='center'>
          {!compareMode ? (
            <Button
              color='qsistant'
              variant='contained'
              startIcon={
                <Icon
                  name={!editMode ? ICON.LOCK_FILLED : ICON.SAVE}
                  fill='#fff'
                />
              }
            >
              {!editMode ? 'freigabe anfordern' : 'Entwurf aktualisieren'}
            </Button>
          ) : null}
          {!editMode && !compareMode ? (
            <Box component='div' className={classes.btnsBox}>
              <Tooltip key={1} title={t('document:addfavourite')}>
                <IconButton onClick={onFavouriteChange}>
                  <Icon
                    name={
                      documentData.isFavourite
                        ? ICON.BOOKMARK_FILLED
                        : ICON.BOOKMARK
                    }
                    size={1.125}
                  />
                </IconButton>
              </Tooltip>
              {boxIcons.map((icon) => (
                <Tooltip key={icon.id} title={icon.title}>
                  <IconButton onClick={icon.onClick}>
                    <Icon name={icon.iconName} size={1.125} />
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          ) : compareMode ? (
            <TextField
              select
              color='qsistant'
              label='Zu vergleichendes Dokument'
              value={review}
              sx={{ width: '25rem' }}
              onChange={(e) => setReview(e.target.value)}
            >
              {revisions.map((rev) => (
                <MenuItem key={rev.id} value={rev.value}>
                  {rev.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Button
              variant='text'
              color='qsistant'
              startIcon={<Icon name={ICON.DESIGN_SERVICES} fill='inherit' />}
            >
              Zwischenspeichern
            </Button>
          )}
        </Box>
        <ToggleButtonGroup
          selectedValue={toggleValue}
          options={toggleOptions.map((option) => {
            return {
              ...option,
              tooltip: t(`document:${option.tooltip}`),
            };
          })}
          onChange={(e, newValue) => {
            if (newValue === 'edit') {
              if (documentData.status === 'released') {
                setModal(true);
                setCompareMode(false);
              } else {
                setEditMode(true);
                setToggleValue('edit');
              }
            }
            if (newValue === 'visibility') {
              setModal(false);
              setEditMode(false);
              setCompareMode(false);
              setToggleValue('visibility');
            }
            if (newValue === 'compare') {
              setCompareMode(true);
              setModal(false);
              setEditMode(false);
              setToggleValue('compare');
            }
          }}
        />
      </ActionBox>
      <Box component='div' display='flex' margin='1rem 0' gap='0.5rem'>
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          width='100%'
          sx={{
            width: sidebarExpanded
              ? 'calc(100% - 25.5rem)'
              : 'calc(100% - 5rem)',
            minHeight: 'calc(100vh - 20rem)',
          }}
        >
          {!compareMode ? (
            <SingleDocumentCard
              expanded={cardExpanded}
              onClose={() => setCardExpanded((prev) => !prev)}
              cardTitle={documentData.documentName}
              badge={{ label: 'Qsistant® Nachweisdokument', color: '#B4A656' }}
              statusBadge={{ text: 'Entwurf', status: 'approved' }}
              documentBasicInfo={{
                document: 'NWS-0001',
                revision: 'Revision 2',
                date: 'Freigegeben am 14.11.2022',
              }}
              editMode={editMode}
              value={documentData.documentName}
              onChange={(e) =>
                updateDocumentData('documentName', e.target.value)
              }
              primaryColor='#B4A656'
              backgroundColor='#fcfcf8'
            >
              {!editMode ? (
                <UneditableContent />
              ) : (
                <EditableContent
                  customerValue={customer}
                  onCustomerChange={(e) => setCustomer(e.target.value)}
                  customerList={customerType}
                />
              )}
            </SingleDocumentCard>
          ) : (
            <ComparisonCardsBox>
              {review ? (
                <SingleComparisonCard
                  isValid
                  title='Nachweis “Biologische Bewertung für Prothesender Kategorie F1 & F2””'
                  primaryColor='#D4BD35'
                  indicator={{ color: '#D4BD35', label: 'Alter Inhalt' }}
                  data={dataToCompare}
                  bgColor='#FDFDF7'
                  status={{ status: 'approved', text: 'Nachweise' }}
                />
              ) : null}
              <SingleComparisonCard
                isValid
                title='Nachweis “Biologische Bewertung für Prothesender Kategorie F1 & F2””'
                indicator={{ color: '#5CB85C', label: 'Aktueller Inhalt' }}
                data={{
                  id: 55,
                  label: 'Revision 2',
                  document: 'DOC-000002',
                  date: 'Freigegeben am 07.03.2023',
                }}
                status={{ status: 'approved', text: 'Nachweise' }}
                bgColor='#F9FCF9'
              />
            </ComparisonCardsBox>
          )}
          <TinyMce
            key={
              toggleValue === 'visibility' || toggleValue === 'compare'
                ? 'View Mode'
                : 'Edit Mode'
            }
            documentContent={documentData.content}
            onChange={(content) => updateDocumentData('content', content)}
            hideToolbar={
              toggleValue === 'visibility' || toggleValue === 'compare'
            }
            readOnly={toggleValue === 'visibility' || toggleValue === 'compare'}
          />
        </Box>
        <SingleDocumentSidebar
          open={sidebarExpanded}
          onClose={() => setSidebarExpanded(false)}
          onExpand={() => setSidebarExpanded(true)}
          title={`Weiter Informationen "${documentData.documentName}"`}
          caption='Kurzzusammenfassung Nachweisdokument'
          description='Dieser Nachweis gilt als Richtlinie für den Prozess der biologischen Bewertung für Prothesen nach F1 und der Methode F2.'
          list={documentFileList}
          status={documentData.status}
          editMode={editMode}
        />
      </Box>
      {modal ? (
        <SwitchModeModal
          open={modal}
          onClose={() => {
            setToggleValue('visibility');
            setCompareMode(false);
            setEditMode(false);
            setModal(false);
          }}
          onChange={() => {
            setModal(false);
            setEditMode(true);
            setCompareMode(false);
            setToggleValue('edit');
          }}
          header={t('modal:switcheditmodeheader')}
          description={t('modal:switcheditmodalrecorddescription')}
          confirmBtnLabel={t('common:edit')}
        />
      ) : null}
      <ConfirmWithPasswordModal
        open={confirmationOpen}
        title={t('document:archivefillout')}
        description={t('document:reallyarchivefillout', {
          name: documentData.documentName,
        })}
        btnNextLabel={t('document:doarchive')}
        onClose={() => setConfirmationOpen(false)}
        onContinue={onArchiveClick}
      />
    </PageWrapper>
  );
}
