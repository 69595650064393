import { Box, Typography } from '@mui/material';
import { DocumentProgressCard } from 'cards/document-progress-card';
import { useAppSelector } from 'store/hook';

import React from 'react';

import { useStyles } from './styles';

export function StandardDocumentsComponent({ documents, setActiveDocument }) {
  const classes = useStyles();

  const documentsList = useAppSelector(
    (state) => state.documentsSetupReducer.documentSettings.documents
  );

  const completedDocs = documentsList.filter(
    (document) => document.progress === 100
  );

  return (
    <Box component='div'>
      <Box component='div' textAlign='center' mb='1rem'>
        <Typography component='h3' className={classes.title}>
          Normdokumente
        </Typography>
        <Typography component='p' className={classes.description}>
          Schließe jeweils alle Fragen eines Dokumentes ab, um mit dem nächsten
          fortfahren zu können.
        </Typography>
      </Box>
      <Box component='div' textAlign='center' mb='1rem'>
        <Typography component='h6' className={classes.numberCompletedDocs}>
          {`${completedDocs.length}/${documents.length}`} DOKUMENTE
          ABGESCHLOSSEN
        </Typography>
      </Box>
      <Box component='div' className={classes.progressCardsBox}>
        {documents.length > 0 &&
          documents.map((document, index: number) => (
            <DocumentProgressCard
              key={document.id}
              documentIndex={index}
              onClick={() => setActiveDocument(index)}
              {...document}
            />
          ))}
      </Box>
    </Box>
  );
}
