/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

export enum PROJECT_TABS {
  QSISTANT = 'qsistant',
  PRODUCT = 'product',
  MANAGEMENT = 'management',
  MY_PROFILE = 'outlined',
}

export enum ELEMENT_TYPES {
  FILE_FILL_OUT = 'filefillout',
  QSISTANT_FILL_OUT = 'qsistantfillout',
  QSISTANT_FILE_DOCUMENT = 'qsistantfiledocument',
  QSISTANT_DOCUMENT = 'qsistantdocument',
}

export enum SORT_OPTIONS {
  DEFAULT = 'default',
  ALPHABET_UP = 'alphabet ascending',
  ALPHABET_DOWN = 'alphabet descending',
  COMPLETION_DATE_UP = 'completion date ascending',
  COMPLETION_DATE_DOWN = 'completion date descending',
  DOCUMENT_NUMBER_UP = 'number ascending',
  DOCUMENT_NUMBER_DOWN = 'number descending',
  FAVORITES_UP = 'favorites ascending',
  FAVORITES_DOWN = 'favorites descending',
  STATUS = 'status',
  DOCUMENT_TYPE = 'document type',
  PROCESS = 'process',
  PERSON = 'person',
  LAST_MODIFIED_UP = 'last modified ascending',
  LAST_MODIFIED_DOWN = 'last modified descending',
}

export enum TBG_OPTIONS {
  VIEW_MODE = 'viewmode',
  EDIT_MODE = 'editmode',
  COMPARE_MODE = 'comparemode',
}
