import { Box } from '@mui/material';
import { LordIcon } from 'components/lord-icon';

import React, { forwardRef } from 'react';

import { statusClassMap } from './status.constants';
import { ForwardedRef, StatusComponentProps } from './status.types';
import { useStyles } from './styles';

export const StatusComponent = forwardRef<HTMLDivElement, StatusComponentProps>(
  (
    { status, statusText, style, onClick, onChangeTariff },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const classes = useStyles();
    const baseClassName = classes.status;
    const statusClass = statusClassMap[status] || '';
    const className = `${baseClassName} ${classes[statusClass]}`;

    return (
      <Box
        ref={ref}
        onClick={onClick}
        style={style}
        className={className}
        component='div'
      >
        {statusText}
        {onChangeTariff ? (
          <LordIcon
            src='sbiheqdr'
            trigger='loop'
            style={{ width: '12px', height: '12px' }}
          />
        ) : null}
      </Box>
    );
  }
);

StatusComponent.displayName = 'Status';
