import { Alert, Box, TextField } from '@mui/material';
import { DefaultModal } from 'modals/default/default.modal';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const uploadInputsBox = {
  width: '100%',
  gap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
};

export function SimpleConfirmationModal({
  closeModal,
  modal,
  title,
  handleSuccess,
  successColor = 'qsistant',
  successLabel,
  icon,
  disabled = false,
  error = '',
  isLoading = false,
}) {
  const [password, setPassword] = useState('');
  const [description, setDescription] = useState('');
  const { t } = useTranslation(['modal', 'snackbar']);

  return (
    <DefaultModal
      closeModal={closeModal}
      successColor={successColor}
      successLabel={successLabel}
      handleSuccess={() => handleSuccess(password, description)}
      disabled={disabled}
      denyDisabled={password.length < 5}
      modal={modal}
      modalTitle={title ?? t('modal:passwordconfirmation')}
      icon={icon}
      isLoading={isLoading}
    >
      {error !== '' ? <Alert severity='error'>{error}</Alert> : null}
      <Box sx={{ ...uploadInputsBox }} component='div'>
        <TextField
          color='qsistant'
          variant='outlined'
          label={t('release:confirmpassword')}
          type='password'
          autoFocus
          value={password || ''}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          placeholder={t('release:confirmpassword')}
        />
        <TextField
          color='qsistant'
          variant='outlined'
          autoComplete='off'
          label={t('release:confirmdescription')}
          type='text'
          value={description || ''}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </Box>
    </DefaultModal>
  );
}
