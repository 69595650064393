import { Alert, Snackbar } from '@mui/material';

import React, { useEffect } from 'react';

import type { GlobalToastComponentProps } from './types';

export function GlobalToastComponent({
  severity,
  message,
  anchor,
  onClose,
  autoDelete,
}: GlobalToastComponentProps) {
  useEffect(() => {
    let timerId = null;
    if (autoDelete) {
      timerId = setTimeout(onClose, 3000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [autoDelete, onClose]);

  return (
    <Snackbar
      open
      autoHideDuration={autoDelete ? 4000 : null}
      onClose={onClose}
      sx={{
        '&.MuiSnackbar-root': {
          top: '105px',
        },
      }}
      anchorOrigin={{
        vertical: anchor.vertical,
        horizontal: anchor.horizontal,
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ minWidth: ({ typography }) => typography.pxToRem(480) }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
