import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    wrapper: {
      padding: `1.25rem  1.5rem 1.25rem 2rem !important`,
    },
    imgBlock: {
      marginTop: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '1.25rem',
      marginLeft: 'auto',
    },
    imgWrap: {
      width: '19.5625rem',
      height: '12.5rem',
    },
    img: {
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
      position: 'relative',
    },
    noImgBg: {
      width: '19.5625rem',
      height: '12.5rem',
      background: '#0000000A',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    hidden: {
      opacity: 0,
      height: 0,
      width: 0,
      lineHeight: 0,
      overflow: 'hidden',
      padding: 0,
      margin: 0,
    },
  };
});
