import api from 'api/api';
import { API_URL } from 'api/api-urls';
import type { RestoredArchiveData } from 'store/qsistant/archive/types';

export const fetchArchiveDocuments = () => {
  return api.get(`${API_URL.archiveDocuments.getAll}`);
};

export const restoreDocument = ({ id, data }: RestoredArchiveData) => {
  return api.post(`${API_URL.archiveDocuments.restore}/${id}`, data);
};

export const getSingleDocument = (id: number) => {
  return api.get(`${API_URL.singleDocument.getSingle}/${id}`);
};
