import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

export function TableModalComponent({
  cells,
  data,
  onToggle,
  selected,
  onReset,
}) {
  return (
    <TableContainer sx={{ height: '31rem' }}>
      <Table stickyHeader className='ModalBasicTable'>
        <TableHead>
          <TableRow>
            {cells.map(({ heading, cellWidth }) => (
              <TableCell
                component='th'
                key={heading}
                align='left'
                sx={{ width: cellWidth }}
              >
                <Typography component='span' className='TableHeader'>
                  {heading}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              display: selected.length > 0 ? 'table-row' : 'none',
            }}
          >
            <TableCell
              component='th'
              colSpan={10}
              sx={{ background: '#45BD81' }}
            >
              <Box display='flex' alignItems='center' gap='3.5rem'>
                <IconButton onClick={onReset}>
                  <Icon name='close' size={1.5} />
                </IconButton>
                <Typography component='p' className='TMMessage'>
                  {`${selected.length} ${
                    selected.length > 1 ? 'Dokuments' : 'Dokument'
                  } ausgewählt`}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          {data.map((item) => (
            <TableRow
              key={item.id}
              hover
              className='ModalTableRow'
              color='product'
              sx={{
                '&.Mui-selected': {
                  background: '#daf2e6',
                },
              }}
              onClick={() => onToggle(item)}
              selected={selected.some((el) => el.name === item.name)}
            >
              {cells.map(({ render, id }) => (
                <TableCell component='th' key={id}>
                  {render(item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
