import React from 'react';

export default function useMultistepForm(
  steps: Array<React.ReactNode>,
  activeStep?: number
) {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [structureStepIndex, setStructureStepIndex] = React.useState(
    activeStep || 0
  );
  const [progress, setProgress] = React.useState(100 / steps.length);

  const goTo = (index: number) => setCurrentStepIndex(index);
  const goToStructureStep = (i: number) => setStructureStepIndex(i);

  const next = () => {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
    setProgress((prev) => (prev === 100 ? 100 : prev + 100 / steps.length));
  };

  const nextStructureStep = () => {
    setStructureStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  };

  const back = () => {
    setCurrentStepIndex((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
    setProgress((prev) => (prev === 0 ? 0 : prev - 100 / steps.length));
  };

  return {
    steps,
    currentStepIndex,
    step: steps[currentStepIndex],
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    progress,
    goTo,
    next,
    back,
    // For Document Structure Step
    structureStepIndex,
    goToStructureStep,
    nextStructureStep,
    structureStep: steps[structureStepIndex],
    isLast: structureStepIndex === steps.length - 1,
  };
}
