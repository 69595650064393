import { Box, Button } from '@mui/material';
import { EmptyList } from 'components/empty-list';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';
import { handleActiveList } from 'store/common/common-slice';
import { useAppDispatch } from 'store/hook';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';

export function DefunctPage() {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToHome = () => {
    navigate(routes().home);
    dispatch(handleActiveList({ parent: 0, item: 0 }));
  };
  return (
    <Box component='div' className={classes.pageWrapper}>
      <EmptyList
        image={{ name: ICON.NO_CONNECTION }}
        title={t('common:nopagetitle')}
        description={t('common:nopagedesc')}
        primaryButton={
          <Button
            variant='contained'
            color='qsistant'
            startIcon={<Icon name={ICON.HOME} fill='white' />}
            sx={{ alignItems: 'flex-start' }}
            onClick={navigateToHome}
          >
            Zum Dashboard
          </Button>
        }
      />
    </Box>
  );
}
