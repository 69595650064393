import { IconButton, ListItemButton } from '@mui/material';
import { Icon } from 'components/icon';
import { NewDocumentProgress } from 'components/progress';
import { Status } from 'components/status';

import React from 'react';

import type { UploadedFileCardProps } from './types';

export function UploadedFileCard({
  onClick,
  onRemove,
  index,
  file,
  offset,
  isSelected,
  currentFilePosition,
  filesLength,
  type = 'Dokumente',
}: UploadedFileCardProps) {
  return (
    <ListItemButton
      disableRipple
      onClick={onClick}
      sx={{
        gap: '0.875rem',
        border: isSelected ? '2px solid #E0D289' : '1px solid #e2e2e2',
      }}
    >
      <Status
        statusText={`${type}${offset + index + 1}/${filesLength}`}
        style={{
          backgroundColor: isSelected
            ? 'rgba(224, 210, 137, 0.24)'
            : 'rgba(226, 226, 226, 0.24)',
          color: isSelected ? '#948425' : '#4599bf',
        }}
      />
      <NewDocumentProgress
        fileName={file.fileName}
        value={file.progress}
        index={index}
        offset={offset}
        currentFilePosition={currentFilePosition}
      />
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onRemove(file.uuid);
        }}
      >
        <Icon name='delete' size={1.25} />
      </IconButton>
    </ListItemButton>
  );
}
