import {
  IconButton,
  IconButtonProps,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { get } from 'lodash';

import { useStyles } from './styles';
import { BasicSelectProps } from './types';

const dropdownIcon = (props: IconButtonProps) => (
  <IconButton sx={{ padding: 0 }} {...props}>
    <Icon name={ICON.ARROW_DROP_DOWN} />
  </IconButton>
);

export function BasicSelectComponent({
  fullWidth = false,
  label,
  variant = 'outlined',
  value,
  onChange,
  options,
  className,
  defaultValue,
  sx,
  color,
  disabled,
  groupBy,
  customLabel = null,
}: BasicSelectProps) {
  const classes = useStyles();

  return (
    <TextField
      select
      sx={{ ...sx }}
      color={color}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      disabled={disabled}
      label={label}
      variant={variant}
      value={value}
      className={className}
      onChange={onChange}
      SelectProps={{
        renderValue: (option) => {
          return (
            <Typography component='p' className={classes.renderedText}>
              {customLabel
                ? customLabel(options.find((o) => o.id === option))
                : (option as string)}
            </Typography>
          );
        },
        IconComponent: (props) => dropdownIcon(props),
        MenuProps: {
          className: classes.menuList,
        },
      }}
    >
      {options.map((item) =>
        groupBy ? (
          <MenuItem
            key={item.id}
            value={item.name}
            sx={{ flexDirection: 'column', alignItems: 'baseline' }}
          >
            <Typography component='p' className={classes.groupByText}>
              {get(item, groupBy)}
            </Typography>
            <Typography component='p'>{item.name}</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            key={item.id}
            value={item.value || item.name}
            disabled={item.disabled}
            sx={{ gap: '0.5rem' }}
          >
            {item.icon ? (
              <Icon
                name={item.icon}
                size={item.size || 1.5}
                fill={item.fill || '#00000099'}
              />
            ) : null}
            {customLabel ? customLabel(item) : item.label || item.name}
          </MenuItem>
        )
      )}
    </TextField>
  );
}
