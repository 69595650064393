import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => ({
  wrapper: {
    width: '100%',
    background: '#7AB8D2',
    border: '2px solid #7AB8D2',
    borderRadius: '0.25rem',
  },
  processItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#D9EAF2',
    position: 'relative',
    borderRadius: '0.25rem',
    padding: '0.5rem 0.375rem 0.5rem 1rem',
    '&:hover': {
      boxShadow:
        '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '100%',
      right: '-9px',
      background: '#7AB8D2',
    },
  },
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  bottomBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    padding: '1rem',
  },
  itemLabel: {
    color: '#3F97BE',
    fontWeight: `${typography.fontWeightMedium} !important`,
    fontSize: `${typography.pxToRem(size.fontM)} !important`,
    textTransform: 'uppercase',
    letterSpacing: '0.08rem !important',
  },
  cardHeader: {
    color: '#fff',
    fontWeight: `${typography.fontWeightMedium} !important`,
    fontSize: `${typography.pxToRem(34)} !important`,
  },
}));
