export const style = {
  height: '2.06rem',
  fontSize: '0.875rem',
  fontWeight: 400,
  letterSpacing: '0.25px',
  borderRadius: '1rem',
  background: '#fff',
  border: '1px solid lightgray',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};
