import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'iconsBox'
  | 'comparisonCardsBox'
  | 'singleCardBox'
  | 'mainCardsBox';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size }) => {
    return {
      iconsBox: {
        background: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: `${typography.pxToRem(4)}`,
      },
      comparisonCardsBox: {
        display: 'flex',
        gap: `${typography.pxToRem(12)}`,
        marginBottom: `${typography.pxToRem(12)}`,
        '@media(max-width: 1600px)': {
          flexDirection: 'column',
        },
      },
      singleCardBox: {
        minHeight: 'calc(100vh - 20rem)',
        display: 'flex',
        flexDirection: 'column',
      },
      mainCardsBox: {
        display: 'flex',
        margin: `${typography.pxToRem(16)} 0`,
        gap: `${typography.pxToRem(8)}`,
        position: 'relative',
      },
    };
  }
);
