import { Avatar, Box, Button, Divider, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { RevisionItemContextProps } from './types';

export function RevisionItemContextComponent({
  open,
  onClose,
  anchorEl,
  date,
  description,
  name,
  ip,
  email,
  status,
}: RevisionItemContextProps) {
  const classes = useStyles();
  return (
    <ContextMenuWrapper
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      className={classes.revisionContext}
    >
      <Box component='div' className={classes.revisionContentBox}>
        <Box component='div' className={classes.textBox}>
          <Box component='div' className={classes.avatarNameBox}>
            <Avatar className={classes.revisionAvatar} />
            <Typography component='p' className={classes.revisionInfoText}>
              {name}
            </Typography>
          </Box>
          <Typography component='p' className={classes.revisionInfoText}>
            {status}
          </Typography>
          <Typography component='p' className={classes.revisionInfoText}>
            {email}
          </Typography>
          <Typography component='p' className={classes.revisionInfoText}>
            {ip}
          </Typography>
          <Typography component='p' className={classes.revisionInfoText}>
            {date}
          </Typography>
        </Box>
        <Divider />
        <Box component='div' className={classes.descriptionBox}>
          <Icon name={ICON.COMMENT} fill='#4599bf' />
          <Typography component='p' className={classes.revisionInfoText}>
            {description}
          </Typography>
        </Box>
      </Box>
      <Box component='div' className={classes.bottomBtnBox}>
        <Button variant='text' color='qsistant' onClick={onClose}>
          OK
        </Button>
      </Box>
    </ContextMenuWrapper>
  );
}
