export const sidebarWrapper = {
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  top: '6rem',
  minHeight: 'calc(100vh - 6rem)',
  zIndex: 4,
  background: '#ffff',
  overflow: 'hidden',
  '@media(max-width:798px)': {
    display: 'none',
  },
};

export const labelStyle = {
  textTransform: 'uppercase',
  letterSpacing: '0.28px',
  color: '#00000099',
  fontSize: '0.875rem',
  opacity: 1,
  padding: '0 0 0.5rem 1.6rem',
  fontWeight: 500,
};

export const drawerIconBox = {
  paddingLeft: '1.2rem',
  height: '6rem',
  display: 'flex',
  alignItems: 'center',
};
