// TODO logic to slices
import { Box } from '@mui/material';
import api from 'api/api';
import { LoadingButton } from 'components/button';
import { TableSkeleton } from 'components/table-skeleton';
import { NewDocumentForm } from 'forms/create-new-document';
import { routes } from 'routes/routes';
import { useAppSelector } from 'store/hook';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from '../styles';

type TemplateDocumentProps = {
  onSuccess: () => void;
};

export function TemplateDocument({ onSuccess }: TemplateDocumentProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('document');

  const { documentTypes, processes, status } = useAppSelector((state) => ({
    documentTypes: state.typeOverviewReducer.documents,
    processes: state.typeOverviewReducer.processes,
    status: state.typeOverviewReducer.typesAndProcessesStatus,
  }));

  const [fileTitle, setFileTitle] = useState<string>('');
  const [number, setNumber] = useState<number>(0);
  const [type, setType] = useState<string>('');
  const [process, setProcess] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (Number(e.target.value) < 0) {
      return;
    }
    setNumber(Number(e.target.value));
  };

  const handleLoading = () => {
    setLoading(true);

    const proc = processes.filter((p) => p.name === process)[0]?.id;
    const typ = documentTypes.filter((ty) => ty.name === type)[0]?.id;

    if (!proc || !typ) {
      setLoading(false);
      return;
    }

    api
      .post('/version/init', {
        process: proc,
        type: typ,
        title: fileTitle,
        number,
      })
      .then((response) => {
        setLoading(false);
        setFileTitle('');
        navigate(
          routes().singleDocumentOverview.replace(
            ':id',
            response.data?.id.toString()
          )
        );
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
        setFileTitle('');
      });
  };

  return (
    <Box component='div' className={classes.contentBox}>
      {status !== 'resolved' &&
      documentTypes.length === 0 &&
      processes.length === 0 ? (
        <>
          {[1, 2, 3, 4, 5].map((item) => (
            <TableSkeleton key={item} height={13} />
          ))}
        </>
      ) : (
        <NewDocumentForm
          title={fileTitle}
          onTitleChange={(e) => setFileTitle(e.target.value)}
          number={number}
          onNumberChange={(e) => handleNumberChange(e)}
          documentType={type}
          onDocumentTypeChange={(e) => setType(e.target.value)}
          process={process}
          onChangeProcess={(e) => setProcess(e.target.value)}
        />
      )}
      <Box component='div' width='100%'>
        <LoadingButton
          variant='contained'
          loaderSize={1.875}
          color='qsistant'
          className={classes.createButton}
          fullWidth
          onClick={handleLoading}
          disabled={!fileTitle || !type || !process}
          isLoading={loading}
          label={t('document:createdocument').toUpperCase()}
        />
      </Box>
    </Box>
  );
}
