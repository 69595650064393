import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    fileName: {
      letterSpacing: '0.025rem !important',
      fontSize: `${typography.pxToRem(size.fontS)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      maxWidth: '20ch !important',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    progressText: {
      color: '#CCCCCC',
      letterSpacing: '0.02rem !important',
      fontSize: `${typography.pxToRem(size.fontXS)} !important`,
    },
  };
});
