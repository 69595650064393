import { Skeleton } from '@mui/material';

import React from 'react';

export function TableSkeletonComponent({ height = 72 }) {
  return (
    <Skeleton
      variant='rectangular'
      style={{ width: '100%' }}
      sx={{ mb: 2 }}
      animation='wave'
      height={height}
    />
  );
}
