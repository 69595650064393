import {
  Avatar,
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import React from 'react';

import { Icon } from '../../../../../components/icon';

export function UneditableContent() {
  return (
    <Stack
      direction='row'
      divider={<Divider orientation='vertical' flexItem />}
      spacing={2}
    >
      <Box component='div'>
        <Typography component='p' className='SDCaption'>
          Nachweisnummer
        </Typography>
        <Typography component='p' className='SDText'>
          NWS-0001
        </Typography>
      </Box>
      <Box component='div'>
        <Box component='div' display='flex' gap='4rem'>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              Freigeber
            </Typography>
            <Typography component='p' className='SDText'>
              -
            </Typography>
          </Box>
          <Box component='div'>
            <Typography component='p' className='SDCaption'>
              Bearbeiter
            </Typography>
            <Tooltip title='M.Meister'>
              <Avatar className='SDAvatar' />
            </Tooltip>
          </Box>
        </Box>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            Freigabedatum
          </Typography>
          <Typography component='p' className='SDText'>
            -
          </Typography>
        </Box>
      </Box>
      <Box component='div'>
        <Box component='div' mb='1.25rem'>
          <Typography component='p' className='SDCaption'>
            Kunde
          </Typography>
          <Typography component='p' className='SDText'>
            Weber Instrumente GmbH & Co. KG
          </Typography>
        </Box>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            Standort der Gültigkeit
          </Typography>
          <Box component='div' display='flex' gap='0.25rem' alignItems='center'>
            <Icon name='location_on' size={0.8} fill='rgba(0, 0, 0, 0.87' />
            <Typography component='p' className='SDText'>
              78576 Emmingen-Liptingen
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box component='div'>
        <Box component='div'>
          <Typography component='p' className='SDCaption'>
            Formularvorlage
          </Typography>
          <Typography component='p' className='SDText'>
            Auftragsgewinnungsprozess DOC-00000 | Revision 0
          </Typography>
        </Box>
        <Button
          variant='text'
          sx={{ color: '#4599BF' }}
          startIcon={<Icon name='open_in_new' fill='#4599BF' size={1.5} />}
        >
          öffnen
        </Button>
      </Box>
    </Stack>
  );
}
