import { generateExtraReducers } from 'utils/utils';

import type { DocumentTypeSlice } from './types';

export const initialState: DocumentTypeSlice = {
  documents: [],
  processes: [],
  processCategories: [],
  modal: false,
  error: null,
  status: null,
  typesAndProcessesError: null,
  typesAndProcessesStatus: null,
};

export const asyncThunkFetchDocuments = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'status',
  errorKey: 'error',
});
