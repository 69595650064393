import { Box, IconButton, TextField } from '@mui/material';
import { Icon } from 'components/icon';
import { BasicSelect } from 'components/select/basic-select';
import { useAppSelector } from 'store/hook';
import { TooltipInfo } from 'tooltips/info.tooltip';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { useStyles } from './styles';
import type { NewDocumentFormProps } from './types';

export function NewDocumentForm({
  title,
  onTitleChange,
  number,
  onNumberChange,
  documentType,
  onDocumentTypeChange,
  process,
  onChangeProcess,
  className,
}: NewDocumentFormProps) {
  const classes = useStyles();
  const iconBtnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('document');

  const { documentTypes, processes } = useAppSelector((state) => ({
    documentTypes: state.typeOverviewReducer.documents,
    processes: state.typeOverviewReducer.processes,
  }));

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <Box
      component='form'
      className={clsx(classes.wrapper, className && className)}
    >
      <TextField
        fullWidth
        autoFocus
        variant='outlined'
        color='qsistant'
        label={t('document:title')}
        value={title || ''}
        onChange={onTitleChange}
      />
      <BasicSelect
        fullWidth
        options={documentTypes}
        color='qsistant'
        label={t('document:choosedocumenttype')}
        value={documentType}
        onChange={onDocumentTypeChange}
      />
      <BasicSelect
        fullWidth
        options={processes}
        color='qsistant'
        label={t('document:chooseprocess')}
        value={process}
        onChange={onChangeProcess}
      />
      <Box component='div' className={classes.bottomInputsBox}>
        <TextField
          fullWidth
          disabled
          id='outlined-text'
          variant='outlined'
          label={t('document:number')}
          type='text'
          value='DOC-00012'
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .Mui-disabled:not(label)': {
              backgroundColor: '#F5F5F5',
            },
          }}
        />
        <TextField
          fullWidth
          variant='outlined'
          autoFocus={false}
          type='number'
          value={number}
          onChange={onNumberChange}
          label={
            <Box component='div' className={classes.tooltipBox}>
              {t('document:revisionnumber')}
              <IconButton
                onClick={() => setShowTooltip((prev) => !prev)}
                sx={{ padding: 0 }}
                ref={iconBtnRef}
              >
                <Icon
                  name='info'
                  size={1.5}
                  fill={showTooltip ? '#4599BF' : '#00000099'}
                />
              </IconButton>
            </Box>
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      {showTooltip && (
        <TooltipInfo
          width='40ch'
          open={showTooltip}
          anchorEl={iconBtnRef}
          onClose={() => setShowTooltip(false)}
          title={t('document:revisionnumbertootip_title')}
          description={t('document:revisionnumbertootip_description')}
        />
      )}
    </Box>
  );
}
