import { Box, Button, Typography } from '@mui/material';
import { MultipleAutocomplete } from 'components/autocomplete/multiple-autocomplete';
import { MultipleAutocompleteCheckboxComponent } from 'components/autocomplete/multiple-autocomplete-checkbox/multiple-autocomplete-checkbox.component';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { descriptionStyle, labelStyle, sectionStyle } from './styles';

export function RequestReviewModal({
  open,
  onClose,
  approver,
  setApprover,
  examiners,
  setExaminers,
  handleReviewFormSubmit,
}) {
  const { t } = useTranslation(['product', 'requestrelease']);

  return (
    <ModalWrapper open={open} onClose={onClose} sx={{ width: '37.5rem' }}>
      <ModalHeader
        header='Prüfung und Freigabe anfordern'
        onClose={onClose}
        primaryColor='product'
        icon={{ name: 'lock_open' }}
      />
      <form onSubmit={handleReviewFormSubmit}>
        <Box component='div' sx={{ ...sectionStyle }}>
          <Typography component='h6' sx={{ ...labelStyle }}>
            Datasetprüfung
          </Typography>
          <Typography component='p' sx={{ ...descriptionStyle }}>
            Wähle PrüferInnen für das Dataset aus, welche die sie auf die
            Richtigkeit des Inhalts überprüfen sollen. Eine Mehrfachauswahl ist
            möglich.
          </Typography>
          <MultipleAutocompleteCheckboxComponent
            color='product'
            fullWidth
            label='PrüferInnen'
            value={Array.isArray(examiners) ? examiners : []}
            onChange={(event, value) => setExaminers(value)}
            options={[
              { id: 1, label: 'Florian', value: 'Florian', avatar: true },
              { id: 3, label: 'Konstantin', value: 'Konstantin', avatar: true },
            ]}
            getOptionLabel={(option) => option.label}
            bg='#45BD81'
          />
        </Box>
        <Box component='div' sx={{ ...sectionStyle }}>
          <Typography component='h6' sx={{ ...labelStyle }}>
            Aktenfreigabe
          </Typography>
          <Typography component='p' sx={{ ...descriptionStyle }}>
            Wähle FreigeberInnen für das Dataset aus, welche sie offiziell für
            die Nutzung freigeben.{' '}
            <span style={{ color: '#45BD81' }}>Keine </span>
            Mehrfachauswahl möglich.
          </Typography>
          <MultipleAutocomplete
            color='product'
            fullWidth
            label='FreigeberInnen'
            value={approver}
            onChange={(e, value) => setApprover(value)}
            options={[
              { id: 2, value: 'Test', label: 'Test', avatar: true },
              { id: 4, value: 'Test4', label: 'Test4', avatar: true },
            ]}
            getOptionLabel={(option) => option.label}
            bg='#45BD81'
          />
        </Box>
        <Box component='div' display='flex' justifyContent='end' p='1.5rem'>
          <Button
            onClick={onClose}
            variant='outlined'
            sx={{
              borderColor: '#fff',
              color: '#45BD81',
              letterSpacing: '0',
              maxHeight: '36px',
              p: '6.5px 16px',
              '&:hover': {
                background: 'rgb(27%, 74%, 51%, 0.12);',
                color: '#45BD81',
                borderColor: 'rgb(27%, 74%, 51%, 0.12)',
              },
            }}
          >
            {t('product:abort')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            sx={{
              background: '#45BD81',
              letterSpacing: '0',
              p: '7.5px 16px',
              maxHeight: '36px',
              '&:hover': { background: '#45BD81' },
              '&.Mui-disabled': {
                background: 'rgb(27%, 74%, 51%, 0.54)',
                color: '#fff',
              },
            }}
          >
            {t('approval:requestrelease')}
          </Button>
        </Box>
      </form>
    </ModalWrapper>
  );
}
