import { Typography } from '@mui/material';
import { TableIconsBox } from 'components/table-icons-box';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalConfirm } from 'modals/confirm';
import { DocumentTypeModal } from 'modals/document-type';
import {
  activateDocumentType,
  deactivateDocumentType,
  deleteDocumentType,
  editDocumentType,
} from 'store/administration/document-type/document-type-slice';
import type {
  DocumentTypes,
  NewDocumentTypeTypes,
} from 'store/administration/document-type/types';
import { useAppDispatch } from 'store/hook';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useTableCells() {
  const { t } = useTranslation(['status', 'document', 'common']);
  const dispatch = useAppDispatch();
  const addNotification = useNotification();

  const [editId, setEditedId] = useState<number | null>(null);
  const [removeId, setRemoveId] = useState<number | null>(null);
  const [hideId, setHideId] = useState<number | null>(null);

  const onEdit = (data: NewDocumentTypeTypes) => {
    dispatch(editDocumentType(data));
    setEditedId(null);
    addNotification({
      text: t('documenttype:alerteditdocument'),
    });
  };

  const onDelete = (id: number) => {
    dispatch(deleteDocumentType(id));
    setRemoveId(null);
  };

  const hideAction = (id: number) => {
    dispatch(deactivateDocumentType(id))
      .then(() => {
        setHideId(null);
      })
      .then(() => {
        addNotification({
          text: t('documenttype:alerthidedocument'),
        });
      });
  };

  const unHideAction = (id: number) => {
    dispatch(activateDocumentType(id))
      .then(() => {
        setHideId(null);
      })
      .then(() => {
        addNotification({
          text: t('documenttype:alertunhidedocument'),
        });
      });
  };

  const getOptions = (item: DocumentTypes) => {
    return [
      {
        id: 112,
        show: true,
        tooltip: t('common:edit'),
        icon: { name: ICON.EDIT },
        onClick: () => setEditedId(item.id),
        modal: (
          <DocumentTypeModal
            open={editId === item.id}
            document={item}
            onClose={() => setEditedId(null)}
            handleSuccess={onEdit}
            icon={{ name: ICON.SCANNER, color: 'management' }}
          />
        ),
      },
      {
        id: 234,
        show: item.active,
        tooltip: !item.hidden
          ? t('documenttype:hidedocument')
          : t('documenttype:activatedocument'),
        icon: { name: !item.hidden ? ICON.VISIBILITY_FILLED : ICON.INVISIBLE },
        onClick: () => setHideId(item.id),
        modal: (
          <ModalConfirm
            open={hideId === item.id}
            onClose={() => setHideId(null)}
            color='management'
            title={
              item.hidden
                ? t('documenttype:modalactivatetitle')
                : t('documenttype:modalhidetitle')
            }
            description={
              item.hidden
                ? t('documenttype:activatedocumentmodaldescription')
                : t('documenttype:hidedocumentmodaldescription')
            }
            onContinue={() =>
              item.hidden ? unHideAction(item.id) : hideAction(item.id)
            }
            btnNextLabel={
              item.hidden
                ? t('documenttype:activatedocument')
                : t('documenttype:hidedocument')
            }
          />
        ),
      },
      {
        id: 222,
        show: !item.active,
        tooltip: t('common:delete'),
        icon: { name: ICON.DELETE },
        onClick: () => setRemoveId(item.id),
        modal: (
          <ModalConfirm
            open={removeId === item.id}
            onClose={() => setRemoveId(null)}
            onContinue={() => onDelete(item.id)}
            btnNextLabel={t('common:delete')}
            color='management'
            title={t('documenttype:removedocumenttype')}
            description={t('documenttype:removemodaldesc')}
          />
        ),
      },
    ];
  };
  const cells = [
    {
      id: '2yhj',
      heading: t('document:name'),
      render: (item: DocumentTypes) => (
        <Typography
          component='p'
          className='TBasicText'
          sx={{ opacity: item.hidden ? 0.53 : 1 }}
        >
          {item.name}
        </Typography>
      ),
    },
    {
      id: '3tyuh',
      heading: t('document:description'),
      render: (item: DocumentTypes) => (
        <Typography
          component='p'
          className='TBasicText'
          sx={{ opacity: item.hidden ? 0.53 : 1 }}
        >
          {item?.description === null || item.description.length === 0
            ? '-'
            : item.description}
        </Typography>
      ),
    },
    {
      id: '5qlzi',
      heading: t('document:isfillable'),
      render: (item: DocumentTypes) => (
        <Typography
          component='p'
          className='TBasicText'
          sx={{ opacity: item.hidden ? 0.53 : 1 }}
        >
          {item.fillable ? t('common:yes') : t('common:no')}
        </Typography>
      ),
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item: DocumentTypes) => (
        <TableIconsBox options={getOptions(item)} />
      ),
    },
  ];

  return {
    cells,
  };
}
