import { Box, IconButton, Tooltip } from '@mui/material';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';
import { routes } from 'routes/routes';

import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type IconBoxProps = {
  itemId: number;
};

export function IconBox({ itemId }: IconBoxProps) {
  const { t } = useTranslation('document');
  const contextIcon = useRef();
  const [showMore, setShowMore] = useState<boolean>(false);

  const openDocumentInNewTab = () => {
    const url = routes().singleSupportDocument.replace(
      ':id',
      itemId.toString()
    );
    setShowMore(false);
    window.open(url, '_blank');
  };

  const menuContextContent = useMemo(
    () => [
      {
        id: 1,
        name: 'edit',
        label: t('document:edit'),
        onClick: undefined,
        show: true,
      },
      {
        id: 2,
        name: 'restore',
        label: t('document:newrevision'),
        onClick: undefined,
        show: true,
      },
      {
        id: 3,
        name: 'open_in_new',
        label: t('document:openinnewtab'),
        onClick: () => openDocumentInNewTab(),
        show: true,
      },
    ],
    []
  );

  return (
    <>
      <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title='lesezeichen' placement='bottom'>
          <IconButton onClick={(e) => e.stopPropagation()}>
            <Icon name='bookmark' size={1.5} />
          </IconButton>
        </Tooltip>
        <Tooltip title='drucken' placement='bottom'>
          <IconButton onClick={(e) => e.stopPropagation()}>
            <Icon name='print' size={1.5} />
          </IconButton>
        </Tooltip>
        <Tooltip title='dokumentieren' placement='bottom'>
          <IconButton onClick={(e) => e.stopPropagation()}>
            <Icon name='description' size={1.5} />
          </IconButton>
        </Tooltip>
        <Tooltip title='mehr' placement='bottom'>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setShowMore(!showMore);
            }}
            ref={contextIcon}
          >
            <Icon name='more' size={1.5} />
          </IconButton>
        </Tooltip>
      </Box>
      {showMore ? (
        <ContextMenu
          open={showMore}
          anchorEl={contextIcon}
          onClose={() => setShowMore(false)}
          dataList={menuContextContent}
        />
      ) : null}
    </>
  );
}
