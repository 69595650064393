import { Box, Button } from '@mui/material';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import type { HomeInnerBoxProps } from './types';

export function HomeInnerBox({
  children,
  disabled = false,
  showMoreButton = true,
}: HomeInnerBoxProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { t } = useTranslation('home');

  return (
    <>
      <Box
        component='div'
        className={classes.cardsBox}
        sx={{
          overflow: expanded && !disabled ? 'scroll' : 'hidden',
          height: ({ typography }) =>
            expanded && !disabled
              ? `${typography.pxToRem(436.5)}`
              : `${typography.pxToRem(368)}`,
        }}
      >
        {children}
      </Box>
      {showMoreButton ? (
        <Box
          component='div'
          className={classes.btnBox}
          sx={{ display: expanded && !disabled ? 'none' : 'flex' }}
        >
          <Button
            variant='text'
            color='qsistant'
            disabled={disabled}
            className={classes.buttonStyle}
            onClick={() => setExpanded((prev) => !prev)}
          >
            {t('home:showmore')}
          </Button>
        </Box>
      ) : (
        ''
      )}
    </>
  );
}
