import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#000000DE',
          letterSpacing: '0.008rem',
          fontWeight: 500,
          '&.Mui-focused': {
            color: '#000000DE',
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#000000DE',
            letterSpacing: '0.03rem',
            fontWeight: 400,
          },
          '& .MuiRadio-root.Mui-checked': {
            color: '#4599BF',
          },
        },
      },
    },
  },
});
