import React from 'react';

export const industryTypes = [
  {
    id: 1,
    label: 'Abfall und Recycling',
    value: 'Abfall und Recycling',
  },
  {
    id: 2,
    label: 'Apotheken',
    value: 'Apotheken',
  },
  {
    id: 3,
    label: 'Automobilzulieferer',
    value: 'Automobilzulieferer',
  },
  {
    id: 4,
    label: 'Baugewerbe, Bautechnik, Baustoffindustrie',
    value: 'Baugewerbe, Bautechnik, Baustoffindustrie',
  },
  {
    id: 5,
    label: 'Chemieindustrie',
    value: 'Chemieindustrie',
  },
  {
    id: 6,
    label: 'Einzelhandel',
    value: 'Einzelhandel',
  },
  {
    id: 7,
    label: 'Elektrotechnik',
    value: 'Elektrotechnik',
  },
  {
    id: 8,
    label: 'Fahrzeugbau',
    value: 'Fahrzeugbau',
  },
  {
    id: 9,
    label: 'Fertigungstechnik',
    value: 'Fertigungstechnik',
  },
  {
    id: 10,
    label: 'Finanzen',
    value: 'Finanzen',
  },
  {
    id: 11,
    label: 'Gastronomie',
    value: 'Gastronomie',
  },
  {
    id: 12,
    label: 'Gebäudeservice',
    value: 'Gebäudeservice',
  },
  {
    id: 13,
    label: 'Großhandel',
    value: 'Großhandel',
  },
  {
    id: 14,
    label: 'Immobilien',
    value: 'Immobilien',
  },
  {
    id: 15,
    label: 'Industriedienstleistungen',
    value: 'Industriedienstleistungen',
  },
  {
    id: 16,
    label: 'IT-Dienstleistungen',
    value: 'IT-Dienstleistungen',
  },
  {
    id: 17,
    label: 'IT-Technologie / Computer / Software',
    value: 'IT-Technologie / Computer / Software',
  },
  {
    id: 18,
    label: 'Kliniken, Reha und Gesundheit',
    value: 'Kliniken, Reha und Gesundheit',
  },
  {
    id: 19,
    label: 'Kunststoffverarbeitung',
    value: 'Kunststoffverarbeitung',
  },
  {
    id: 20,
    label: 'Labore und Prüfeinrichtungen',
    value: 'Labore und Prüfeinrichtungen',
  },
  {
    id: 21,
    label: 'Lebensmittelindustrie',
    value: 'Lebensmittelindustrie',
  },
  {
    id: 22,
    label: 'Luft- und Raumfahrt',
    value: 'Luft- und Raumfahrt',
  },
  {
    id: 23,
    label: 'Maschinenbau',
    value: 'Maschinenbau',
  },
  {
    id: 24,
    label: 'Medizintechnik',
    value: 'Medizintechnik',
  },
  {
    id: 25,
    label: 'Mess- und Regelungstechnik',
    value: 'Mess- und Regelungstechnik',
  },
  {
    id: 26,
    label: 'Metallverarbeitung',
    value: 'Metallverarbeitung',
  },
  {
    id: 27,
    label: 'Oberflächentechnik',
    value: 'Oberflächentechnik',
  },
  {
    id: 28,
    label: 'Personalberater',
    value: 'Personalberater',
  },
  {
    id: 29,
    label: 'Phrama, Arzneimittel und Biotech',
    value: 'Phrama, Arzneimittel und Biotech',
  },
  {
    id: 30,
    label: 'Schiffbau, Marinetechnik, Reedereien',
    value: 'Schiffbau, Marinetechnik, Reedereien',
  },
  {
    id: 31,
    label: 'Sicherheitsdienstleistungen',
    value: 'Sicherheitsdienstleistungen',
  },
  {
    id: 32,
    label: 'Steuer- Rechts- und Unternehmensberatung',
    value: 'Steuer- Rechts- und Unternehmensberatung',
  },
  {
    id: 33,
    label: 'Textilindustrie',
    value: 'Textilindustrie',
  },
  {
    id: 34,
    label: 'Touristik',
    value: 'Touristik',
  },
  {
    id: 35,
    label: 'Transport, Logistik, Spedition',
    value: 'Transport, Logistik, Spedition',
  },
  {
    id: 36,
    label: 'Verpackungsindustrie',
    value: 'Verpackungsindustrie',
  },
  {
    id: 37,
    label: 'Werbe, PR- und Medienagenturen',
    value: 'Werbe, PR- und Medienagenturen',
  },
  {
    id: 38,
    label: 'Werkzeugbau',
    value: 'Werkzeugbau',
  },
];

export const workersAmount = [
  { id: 1, label: 'Nur ich', value: 'Just me' },
  { id: 2, label: '2-10', value: '2-10' },
  { id: 3, label: '11-50', value: '11-50' },
  { id: 4, label: '51-100', value: '51-100' },
  { id: 5, label: '101-250', value: '101-250' },
  { id: 6, label: '250+', value: '250+' },
];

export const userRoles = [
  { id: 1, label: 'Auswählen', value: 'Auswählen', disabled: true },
  {
    id: 2,
    icon: 'corporate_fare_rounded',
    label: 'Organisationsmanager',
    value: 'Organisationsmanager',
  },
  {
    id: 3,
    icon: 'description',
    label: 'Dokumentenmanager',
    value: 'Dokumentenmanager',
  },
  {
    id: 4,
    icon: 'account_circle_filled',
    label: 'Mitarbeiter',
    value: 'Mitarbeiter',
  },
];

export const processCategories = [
  {
    id: '00000',
    name: 'Managementprozesse',
    description:
      'Managementprozesse beinhalten Tätigkeitsbereiche, die sich mit Führung, Steuerung von Personal, organisatorischen Abläufen oder dem Bewerten von internen Abläufen beschäftigen.',
    checked: true,
  },
  {
    id: '1233',
    name: 'Kernprozesse',
    description:
      'Der allgemeine Wertschöpfungsprozess (Herstellung von Produkten oder erbringen einer Dienstleistung) eines Unternehmens wird klassischerweise in den Kernprozessen beschrieben.',
    checked: true,
  },
  {
    id: '201294',
    name: 'Unterstützungsprozesse',
    description:
      'Unterstützungsprozesse beschreiben häufig Prozesse, die ergänzend zu Kernprozessen durchgeführt werden, jedoch keinen direkten Nutzen für den Kunden erzeugen.',
    checked: true,
  },
];

export const documentTypes = [
  {
    id: '103031',
    name: 'Prozessbeschreibung',
    description:
      'Prozessbeschreibungen sind als übergeordnete Leitlinien zum Umgang mit Prozessen zu betrachten. Sie geben den allgemeinen Ablauf eines übergeordneten Prozesses wider.',
    checked: true,
  },
  {
    id: '93912931',
    name: 'Arbeitsanweisung',
    description:
      'Arbeitsanweisungen sind spezifische Anweisungen wie Verfahren oder Arbeitsschritte innerhalb von Prozessen durchgeführt werden. Oft werden hierfür Ablaufdiagramme verwendet.',
    checked: true,
  },
  {
    id: '99911102',
    name: 'Formular',
    description:
      'Formulare umfassen unter anderem auch Protokolle und Checklisten, die nach dem Ausfüllen in den Nachweisen abgelegt werden.',
    checked: true,
  },
  {
    id: '0967391',
    name: 'Liste',
    description:
      'Listen werden für Dokumente in Tabellen und Listenform herangezogen, dies können auch Aufzählungen wie Maschinen- und Materiallisten beinhalten.',
    checked: true,
  },
];

export const steps = [
  {
    id: 0,
    label: 'Angestrebte Zertifizierung',
    icon: { name: 'verified', fill: '#fff', size: 1.5 },
  },
  {
    id: 1,
    label: 'Prozesskategorien',
    icon: { name: 'new_label', fill: '#fff', size: 1.5 },
  },
  {
    id: 2,
    label: 'Dokumentenarten',
    icon: { name: 'document_scanner', fill: '#fff', size: 1.5 },
  },
  {
    id: 3,
    label: 'Basisdokumente',
    icon: { name: 'description', fill: '#fff', size: 1.5 },
  },
];

export const structureSteps = [
  { id: 0, label: 'Zyklen Audits', value: 'Cycle audits' },
  { id: 1, label: 'Umfang Audits', value: 'Scope audits' },
  {
    id: 2,
    label: 'Tabelle - Auflistung Audit',
    value: 'Table - Listing Audit',
  },
  {
    id: 3,
    label: 'Verantwortung',
    value: 'Responsibility',
  },
  {
    id: 4,
    label: 'Qualifikationen Auditoren',
    value: 'Qualifications auditors',
  },
  {
    id: 5,
    label: 'Auditkriterien',
    value: 'Audit Criteria',
  },
  {
    id: 6,
    label: 'Speicherort Nachweise',
    value: 'Location of evidence',
  },
];

export const tooltipContent = [
  {
    id: 0,
    header: 'Organisationsmanager',
    text: (
      <span>
        Die Organisationsmanager besitzen die{' '}
        <span style={{ color: '#4599BF' }}>höchste Rolle</span> im QM-System.
        Sie haben zusätzlich zu den Dokumentenmanagern die Rechte, um Prozesse,
        Mitarbeiter und Abteilungen anzulegen und zu bearbeiten.
      </span>
    ),
    icon: 'corporate_fare',
    iconColor: '#4599BF',
  },
  {
    id: 14,
    header: 'Dokumentenmanager',
    text: (
      <span>
        Die Dokumentenmanager haben{' '}
        <span style={{ color: '#4599BF' }}>
          vollen Zugriff auf die Dokumentenlenkung
        </span>
        . Mit dieser Rolle können öffentliche und vertrauliche Dokumente
        erstellt, bearbeitet, geprüft, freigeben und archiviert werden.
      </span>
    ),
    icon: 'description',
    iconColor: '#4599BF',
  },
  {
    id: 23,
    header: 'Teammitglieder',
    text: (
      <span>
        Das Teammitglied{' '}
        <span style={{ color: '#4599BF' }}>ist die Basisrolle</span> im
        QM-System. Mit dieser Rolle kann man freigegebene Dokumente lesen und
        Nachweise hochladen. Es können keine Prüfungen / Freigaben erteilt oder
        Dokumente bearbeitet werden.
      </span>
    ),
    icon: 'account_circle',
    iconColor: '#4599BF',
  },
];

export const createProcessesSteps = [
  {
    id: 0,
    label: 'Prozesskategorien',
    icon: { name: 'crop_32', fill: '#fff', size: 1.5 },
  },
  {
    id: 1,
    label: 'Prozesse',
    icon: { name: 'new_label', fill: '#fff', size: 1.5 },
  },
  {
    id: 2,
    label: 'Dokumentenarten',
    icon: { name: 'document_scanner', fill: '#fff', size: 1.5 },
  },
];
