import { Box } from '@mui/material';
import { ChangeManagementCard } from 'cards/changemanagement-card';
import { useNotification } from 'hooks/useNotification';
import { ModalConfirm } from 'modals/confirm';
import {
  fetchChangemanagement,
  handleUpdateChangeDocument,
  handleUpdateExpiryDocument,
} from 'store/administration/changemanagement/changemanagement-slice';
import type { DocumentType } from 'store/administration/changemanagement/types';
import {
  handleActiveList,
  handleHasChanges,
  handleModalHasChanges,
} from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';

export function ChangemanagementPage({ pathName, parentTab, homePage }) {
  const { t } = useTranslation(['changemanagement', 'common']);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const addNotification = useNotification();

  const {
    documents,
    changeDocument,
    expiryDocument,
    loading,
    changeDocumentStatus,
    expiryDocumentStatus,
    modalHasChanges,
    discardedRoute,
  } = useAppSelector((state) => ({
    documents: state.changemanagementReducer.data?.documents,
    changeDocument: state.changemanagementReducer.data?.changeDocument,
    expiryDocument: state.changemanagementReducer.data?.expiryDocument,
    loading: state.changemanagementReducer.dataLoading,
    changeDocumentStatus: state.changemanagementReducer.changeDocumentStatus,
    expiryDocumentStatus: state.changemanagementReducer.expiryDocumentStatus,
    modalHasChanges: state.commonSlice.modalHasChanges,
    discardedRoute: state.commonSlice.discardedRoute,
  }));

  const [changeChanges, setChangeChanges] = useState(false);
  const [expiryChanges, setExpiryChanges] = useState(false);

  const [changeAre, setChangeAre] = useState(false);
  const [expiryAre, setExpiryAre] = useState(false);

  const [changeDocumentId, setChangeDocumentId] =
    useState<DocumentType>(changeDocument);
  const [expiryDocumentId, setExpiryDocumentId] =
    useState<DocumentType>(expiryDocument);

  const handleChanges = (prop: 'change' | 'expiry', document: DocumentType) => {
    if (prop === 'change') {
      setChangeDocumentId(document);
      dispatch(handleHasChanges(true));
      setChangeAre(true);
    } else {
      setExpiryDocumentId(document);
      dispatch(handleHasChanges(true));
      setExpiryAre(true);
    }
  };

  const handleChangeSave = () => {
    dispatch(handleUpdateChangeDocument(changeDocumentId));
    if (changeDocumentStatus === 'resolved') {
      addNotification({
        text: t('changemanagement:alertsavesuccess'),
      });
    }
    setChangeAre(false);
  };

  const handleExpirySave = () => {
    dispatch(handleUpdateExpiryDocument(expiryDocumentId));
    if (expiryDocumentStatus === 'resolved') {
      addNotification({
        text: t('changemanagement:alertsavesuccess'),
      });
    }
    setExpiryAre(false);
  };

  const handleChangeSwitch = () => {
    setChangeAre((prev) => prev);
    setChangeChanges((prev) => !prev);
  };

  const handleExpirySwitch = () => {
    setExpiryAre((prev) => prev);
    setExpiryChanges((prev) => !prev);
  };

  useEffect(() => {
    dispatch(fetchChangemanagement());
  }, [dispatch]);

  useEffect(() => {
    setChangeDocumentId(changeDocument ?? null);
    setExpiryDocumentId(expiryDocument ?? null);
  }, [loading]);

  const navigateToPersistedRoute = () => {
    dispatch(handleHasChanges(false));
    dispatch(handleModalHasChanges(false));
    if (discardedRoute.tab && discardedRoute.item) {
      dispatch(
        handleActiveList({
          parent: discardedRoute.tab,
          item: discardedRoute.item,
        })
      );
      navigate(discardedRoute.route && discardedRoute.route);
    } else {
      navigate(discardedRoute.route && discardedRoute.route);
    }
  };

  return (
    <PageWrapper
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      pageLabel={t(`changemanagement:pagename`)}
    >
      <Box component='div' className={classes.widgetsBox}>
        <ChangeManagementCard
          button={{
            disabled: !changeChanges || !changeAre,
            onSave: handleChangeSave,
            loader: changeDocumentStatus === 'pending',
          }}
          loading={loading}
          title={t('changemanagement:changemanagementform')}
          description={t('changemanagement:changemanagementform_description')}
          switchState={{ checked: changeChanges, onChange: handleChangeSwitch }}
          documents={documents?.length > 0}
          autocomplete={{
            disabled: !changeChanges,
            options: documents,
            value: changeDocumentId,
            onChange: (event, value) => handleChanges('change', value),
            label: t('changemanagement:changedocument'),
          }}
        />
        <ChangeManagementCard
          title={t('changemanagement:expiringmanagementform')}
          description={t('changemanagement:expiringmanagementform_description')}
          switchState={{ checked: expiryChanges, onChange: handleExpirySwitch }}
          documents={documents?.length > 0}
          loading={loading}
          button={{
            disabled: !expiryChanges || !expiryAre,
            onSave: handleExpirySave,
            loader: expiryDocumentStatus === 'pending',
          }}
          autocomplete={{
            options: documents,
            disabled: !expiryChanges,
            value: expiryDocumentId,
            onChange: (event, value) => handleChanges('expiry', value),
            label: t('common:document'),
          }}
        />
      </Box>
      {modalHasChanges && (
        <ModalConfirm
          open={modalHasChanges}
          onClose={() => dispatch(handleModalHasChanges(false))}
          onContinue={navigateToPersistedRoute}
          color='management'
          title={t('changemanagement:modalhaschangestitle')}
          description={t('changemanagement:modalhaschangesdesc')}
          btnNextLabel={t('common:discard')}
        />
      )}
    </PageWrapper>
  );
}

/*
  const [showExpirylist, setShowExpiryList] = useState(false);
  const [showChangelist, setShowChangeList] = useState(false);
  const [defaultExpiry, setDefaultExpiry] = useState({});
  const [defaultChange, setDefaultChange] = useState({});
  const [chPassword, setChPassword] = useState('');
  const [exPassword, setExPassword] = useState('');
  const [exLoading, setExLoading] = useState(false);
  const [chLoading, setChLoading] = useState(false);
  const [documents, setDocuments] = useState();
  const [snack, setSnack] = useState({ message: '', type: '' });
  const classes = useStyles();
  const { t } = useTranslation(['management']);

  const set = (data) => {
    if (data.organization?.changeDocument) {
      setShowChangeList(true);
      setDefaultChange(
        getIndexDocumentById(data.documents, data.organization?.changeDocument)
      );
    }

    if (data.organization?.expiryDocument) {
      setShowExpiryList(true);
      setDefaultExpiry(
        getIndexDocumentById(data.documents, data.organization?.expiryDocument)
      );
    }

    setDocuments(data.documents);
  };

  const getIndexDocumentById = (d, id = 0) => {
    return d[
      d.findIndex(function (doc) {
        return doc.id === id;
      })
    ];
  };

  useEffect(() => {
    fetch('/organization/changemanagement/documentlist')
      .then((result) => result.json())
      .then((data) => set(data));
  }, []);

  const expiryChanged = (event) => {
    if (event?.target?.value === 'enable') {
      setShowExpiryList(true);
      setDefaultExpiry(documents[0] ?? null);
    } else {
      setShowExpiryList(false);
      setDefaultExpiry(null);
    }
  };

  const changeChanged = (event) => {
    if (event?.target?.value === 'enable') {
      setShowChangeList(true);
      setDefaultChange(documents[0] ?? null);
    } else {
      setShowChangeList(false);
      setDefaultChange(null);
    }
  };

  const expiryDocumentChanged = (event, value) => {
    setDefaultExpiry(value);
  };

  const changeDocumentChanged = (event, value) => {
    setDefaultChange(value);
  };

  const save = (type) => {
    type = type === 'expiry' ? 'expiry' : 'change';
    if (type === 'expiry') {
      setExLoading(true);
    } else {
      setChLoading(true);
    }
    const data = type === 'expiry' ? defaultExpiry : defaultChange;
    fetch(
      `/organization/changemanagement/save/${type}${data ? `/${data.id}` : ''}`,
      {
        method: 'POST',
        headers: {
          'x-auth': type === 'expiry' ? exPassword : chPassword,
        },
      }
    ).then((result) => {
      if (result.ok) {
        result.json().then((d) => {
          if (d.type === 'expiry') {
            setExPassword('');
            setExLoading(false);
          } else {
            setChPassword('');
            setChLoading(false);
          }
          setSnack({
            message: t(`save${d.type}`),
            type: 'success',
          });
        });
      } else {
        setSnack({
          message: t('saveerror'),
          type: 'error',
        });
        setExLoading(false);
        setChLoading(false);
      }
    });
  };

  const handleSnackClose = () => {
    setSnack({ message: '', type: '' });
  };

  return (
    <div id='home'>
      <Snackbar
        open={snack.message !== ''}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackClose}>{snack.message}</Alert>
      </Snackbar>
      <div className={classes.MainContainer}>
        <div className={classes.SubMenu}>
          <a className={classes.qsistantHeading}>{t('management')}</a>
          <span className={classes.divider}>/</span>
          <a className={classes.HomeHeading}>{t('changemanagement')}</a>
        </div>
        <h1 className={classes.MainHeading}>{t('changemanagement')}</h1>
        <div className={classes.HomeWrapper}>
          <div className={classes.HomeCard}>
            <div className={classes.HomeCardHead}>
              <h4 className={classes.HomeCardHeadTitle}>
                {t('changemanagementForm')}
              </h4>
            </div>
            <div className={classes.ChangemanagementCardList}>
              <p>{t('changemanagementFormDescription')}</p>
              <RadioGroup
                aria-label='change'
                name='change'
                value={showChangelist ? 'enable' : 'disable'}
                onChange={changeChanged}
              >
                <FormControlLabel
                  color='default'
                  value='disable'
                  onClick={() => changeChanged('change', null)}
                  control={<Radio />}
                  label={t('dontuse')}
                />
                <FormControlLabel
                  disabled={!documents}
                  color='default'
                  value='enable'
                  control={<Radio />}
                  label={t('use')}
                />
              </RadioGroup>
              {documents ? (
                <Autocomplete
                  disablePortal
                  id='change-documents'
                  options={documents}
                  hidden={!showChangelist}
                  sx={{ width: 300 }}
                  value={defaultChange}
                  getOptionLabel={(option) => {
                    // filter value
                    return `${option.name} Rev. ${option.revision}/(${option.version})`;
                  }}
                  onChange={changeDocumentChanged}
                  renderInput={(params) => (
                    <TextField {...params} label='Dokument' />
                  )}
                />
              ) : (
                ''
              )}
              <SaveWithPassword
                enabled
                onClick={() => save('change')}
                isLoading={chLoading}
                password={chPassword}
                setPassword={setChPassword}
              />
            </div>
          </div>
          <div className={classes.HomeCard}>
            <div className={classes.HomeCardHead}>
              <h4 className={classes.HomeCardHeadTitle}>
                {t('expiringmanagementform')}
              </h4>
            </div>
            <div className={classes.ChangemanagementCardList}>
              <p>{t('expiringmanagementFormDescription')}</p>
              <RadioGroup
                aria-label='expiry'
                name='expiry'
                onChange={expiryChanged}
                value={showExpirylist ? 'enable' : 'disable'}
              >
                <FormControlLabel
                  color='default'
                  value='disable'
                  control={<Radio />}
                  label={t('dontuse')}
                />
                <FormControlLabel
                  disabled={!documents}
                  color='default'
                  value='enable'
                  control={<Radio />}
                  label={t('use')}
                />
              </RadioGroup>
              {documents ? (
                <Autocomplete
                  disablePortal
                  id='expiry-documents'
                  options={documents}
                  hidden={!showExpirylist}
                  value={defaultExpiry}
                  getOptionLabel={(option) => {
                    // filter value
                    return `${option?.name} Rev. ${option?.revision}/(${option?.version})`;
                  }}
                  onChange={expiryDocumentChanged}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label='Dokument' />
                  )}
                />
              ) : (
                ''
              )}
              {/* <SaveWithPassword enabled={true} onClick={() => save("expiry")}  isLoading={exLoading} password={exPassword} setPassword={setExPassword}  /> }
            </div>
          </div>
        </div>
      </div>
    </div>
  ); */
