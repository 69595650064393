import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'icon'
  | 'cardLabel'
  | 'description'
  | 'summary'
  | 'details'
  | 'accordion';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, colors }) => {
    return {
      icon: {
        color: '#707070',
        fontSize: `${typography.pxToRem(size.fontL * 2)} !important`,
      },
      cardLabel: {
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
      description: {
        fontSize: `${typography.pxToRem(size.fontS)} !important`,
      },
      summary: {
        '& .MuiAccordionSummary-content': {
          alignItems: 'center',
          gap: typography.pxToRem(size.fontM),
        },
      },
      details: {
        paddingRight: `${typography.pxToRem(size.font_8)} !important`,
        paddingBottom: `${typography.pxToRem(size.font_8)} !important`,
        paddingTop: '0 !important',
        paddingLeft: `${typography.pxToRem(64)} !important`,
      },
      accordion: {
        width: '35%',
        '&.MuiAccordion-root:before': {
          backgroundColor: colors.white,
        },
      },
    };
  }
);
