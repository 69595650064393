import api from 'api/api';
import { API_URL } from 'api/api-urls';
import type { NewDepartmentTypes } from 'store/administration/departments/types';

export const getDepartmentsList = () => {
  return api.get(`${API_URL.departments.getAll}`);
};

export const createNewDepartment = (data: NewDepartmentTypes) => {
  return api.post(`${API_URL.departments.add}`, data);
};

export const departmentEditing = (
  id: number,
  department: NewDepartmentTypes
) => {
  return api.post(`${API_URL.departments.edit}/${id}`, department);
};

export const deleteDepartment = (id: number) => {
  return api.delete(`${API_URL.departments.delete}/${id}`);
};
