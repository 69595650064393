import { Box, Button, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ProcessBadge } from 'components/process-badge';

import React from 'react';

import type { ProcessCategoryStoreProps } from './process-categorie.card.types';
import { useStyles } from './styles';

export function ProcessCategorieStore({
  id,
  name,
  onShow,
  processList,
  onRemoveProcess,
  options,
  onAction,
}: ProcessCategoryStoreProps) {
  const classes = useStyles();
  return (
    <Box component='div'>
      <Box component='div' className={classes.boxStoreWrapper}>
        <IconButton sx={{ visibility: 'hidden' }}>
          <Icon name='info' fill='#fff' />
        </IconButton>
        <Typography component='h6' className={classes.cardStoreTitle}>
          {name}
        </Typography>
        <IconButton>
          <Icon name='info' fill='#fff' />
        </IconButton>
      </Box>
      <Box component='div' display='flex' gap='1rem' flexWrap='wrap' mt='1rem'>
        {processList.length > 0 &&
          processList.map((process) => (
            <ProcessBadge
              key={process.id}
              id={process.id}
              parentId={id}
              label={process.title}
              onRemoveProcess={onRemoveProcess}
              options={options}
              category={process.parentCategory}
              onAction={onAction}
            />
          ))}
      </Box>
      <Box
        component='div'
        width='100%'
        display='flex'
        justifyContent='center'
        mt='1.5rem'
      >
        <Button
          variant='text'
          startIcon={<Icon name='add' fill='#3F97BE' />}
          color='qsistant'
          onClick={onShow}
        >
          Prozess hinzufügen
        </Button>
      </Box>
    </Box>
  );
}
