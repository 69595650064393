import Login from 'components/login/Login';
import {
  Changemanagement,
  Customer,
  Departments,
  DocumentType,
  MembersList,
  Process,
} from 'pages/administration';
import {
  InviteBossPage,
  LowerRolePage,
  SimpleUserPage,
} from 'pages/onboarding';
import {
  Archive,
  Defunct,
  DocumentOverview,
  Home,
  MyProfile,
  Organigramm,
  ProcessMap,
  Search,
  SupportingDocuments,
} from 'pages/qsistant';
import Qsistant from 'pages/qsistant/qsistant';

import { lazy } from 'react';

import DocumentsSetupPage from '../pages/onboarding/documents-setup.page';
import { ProductPage } from '../pages/product_development/product';
import { SingleProductPage } from '../pages/product_development/single-product';
import { SingleArchivePage } from '../pages/qsistant/archive/single-archive.page';
import { SingleDocumentPage } from '../pages/qsistant/document-overview/single-document.page';
import { SingleSupportPage } from '../pages/qsistant/supporting-documents/single-support.page';
import { routes } from './routes';

const CompanyProfile = lazy(() =>
  import('pages/administration').then((module) => ({
    default: module.CompanyProfile,
  }))
);

export const routesData = [
  {
    path: `${routes().qsistant}`,
    pathName: 'Qsistant',
    Component: Qsistant,
    children: [
      {
        path: `${routes().process}`,
        Component: Process,
        pathName: 'Prozessübersicht',
        parentTab: 'Verwaltung',
        homePage: '/management/company_profile',
        children: [],
      },
      {
        path: `${routes().profile}`,
        Component: MyProfile,
        pathName: 'Mein Profil',
        parentTab: null,
        homePage: null,
      },
      {
        path: `${routes().companyProfile}`,
        Component: CompanyProfile,
        pathName: 'Unternehmensprofil',
        parentTab: 'Verwaltung',
        homePage: '/management/company_profile',
      },
      {
        path: `${routes().search}`,
        Component: Search,
        pathName: 'Suchergebnisse',
      },
      {
        path: `${routes().types}`,
        Component: DocumentType,
        pathName: 'Dokumentenarten',
        parentTab: 'Verwaltung',
        homePage: '/management/company_profile',
      },
      {
        path: `${routes().login}`,
        Component: Login,
        pathName: 'Login',
      },
      {
        path: `${routes().home}`,
        Component: Home,
        pathName: 'Home',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().map}`,
        Component: ProcessMap,
        pathName: 'Prozesslandkarte',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().organigramm}`,
        Component: Organigramm,
        pathName: 'Organigramm',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: 'quick access',
        Component: '',
        pathName: 'Schnellzugriff',
      },
      {
        path: `${routes().document}`,
        Component: DocumentOverview,
        pathName: 'Dokumente',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().singleDocumentOverview}`,
        Component: SingleDocumentPage,
        pathName: 'Dokumentenübersicht',
        parentRoute: 'document',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().singleHomeDocument}`,
        Component: SingleDocumentPage,
        pathName: 'Home',
        parentRoute: 'home',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().support}`,
        Component: SupportingDocuments,
        pathName: 'Nachweise',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().singleSupportDocument}`,
        Component: SingleSupportPage,
        pathName: 'Nachweisdokumente',
        parentRoute: 'support',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().singleHomeSupport}`,
        Component: SingleSupportPage,
        pathName: 'Home',
        parentRoute: 'home',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().archive}`,
        Component: Archive,
        pathName: 'Archiv',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().singleArchiveDocument}`,
        Component: SingleArchivePage,
        pathName: 'Archiv',
        parentRoute: 'archive',
        parentTab: 'Qsistant',
        homePage: `${routes().home}`,
      },
      {
        path: `${routes().members}`,
        Component: MembersList,
        pathName: 'Team',
        parentTab: 'Verwaltung',
        homePage: '/management/company_profile',
      },
      {
        path: `${routes().departments}`,
        Component: Departments,
        pathName: 'Abteilungen',
        parentTab: 'Verwaltung',
        homePage: '/management/company_profile',
      },
      {
        path: `${routes().customers}`,
        Component: Customer,
        pathName: 'Kunden',
        parentTab: 'Verwaltung',
        homePage: '/management/company_profile',
      },
      {
        path: `${routes().changemanagement}`,
        Component: Changemanagement,
        pathName: 'Wiedervorlage',
        parentTab: 'Verwaltung',
        homePage: '/management/changemanagement',
      },
      {
        path: `${routes().productDevelopment}`,
        Component: ProductPage,
        pathName: 'Produkte',
        parentTab: 'Produktenwicklung',
        homePage: '/product_development/product',
      },
      {
        path: `${routes().singleProduct}`,
        Component: SingleProductPage,
        pathName: 'Produkte', //
        parentRoute: 'Product',
        parentTab: 'Produktenwicklung', //
        homePage: '/product_development/product/', //
      },
    ],
  },
  {
    path: '*',
    Component: Defunct,
    pathName: 'No Page',
    children: [],
  },
  // Testing
  {
    path: `${routes().bossInvitation}`,
    Component: InviteBossPage,
    pathName: 'Invite Boss',
    children: [],
  },
  {
    path: `${routes().lowerPerson}`,
    Component: LowerRolePage,
    pathName: 'Lower Role',
    children: [],
  },
  {
    path: `${routes().simpleUser}`,
    Component: SimpleUserPage,
    pathName: 'Simple User',
    children: [],
  },
  {
    path: `${routes().documentsSetup}`,
    Component: DocumentsSetupPage,
    pathName: 'Documents Setup',
    children: [],
  },
];
