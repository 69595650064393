import { Box, Button, CardContent, Typography } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { ProductFilesCardProps } from './product-files-card.types';

export function ProductFilesCardComponent({
  cardData,
  onClick,
}: ProductFilesCardProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        background: '#fff',
        flex: 1,
        borderRad: '0.3125rem',
        mb: '2rem',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {/* <CardMedia */}
        {/*  sx={{ */}
        {/*    display: 'flex', */}
        {/*    alignItems: 'center', */}
        {/*    justifyContent: 'center', */}
        {/*  }} */}
        {/* > */}
        {/*  <Icon name={img} /> */}
        {/* </CardMedia> */}
        <Box
          sx={{
            width: '6.25rem',
            height: '6.25rem',
            background: '#e6e6e6',
            borderRadius: '50%',
            mt: '4.8125rem',
            p: 0,
          }}
        />
        <CardContent sx={{ maxWidth: '40.125rem', textAlign: 'center' }}>
          <Typography className='PageInfo'>{cardData.pOne}</Typography>
          <Typography className='PageInfo'>{cardData.pTwo}</Typography>
        </CardContent>
      </Box>
      <Button
        variant='outlined'
        color='product'
        startIcon={<Icon name={cardData.btnIco} fill='#45BD81' />}
        className='ProductOtlined'
        sx={{ m: '1.5rem 0 4.8125rem 0', p: '5px 16px 5px 13px' }}
        onClick={onClick}
      >
        {cardData.btnText}
      </Button>
    </Box>
  );
}
