import { Alert, Box, Breadcrumbs, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  saveFilesChanges,
  savePhasesChanges,
  updateProduct as updateCurrentProduct,
} from 'store/product/current-product-slice';
import { updateProducts } from 'store/product/products-slice';
import { PageWrapperProps } from 'wrappers/page-wrapper/types';

import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { debounce } from 'lodash';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './page-wrapper.module.css';
import { pageWrapper, useStyles } from './styles';

export function PageWrapper(props: PageWrapperProps) {
  const {
    name,
    linkName,
    id,
    pathName,
    parentTab,
    homePage,
    children,
    parentRoute,
    nestedDocument,
    pageLabel,
    button = null,
    dayTimeImgSrc,
    // saved,
    // setSaved,
  } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const headerRef = useRef<HTMLInputElement | null>(null);
  const narrowSidebar = useAppSelector(
    (state) => state.commonSlice.narrowSidebar
  );
  const { phasesSaveStatus, filesSaveStatus } = useAppSelector(
    (state) => state.currentProductReducer
  );
  const [header, setHeader] = useState(name);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    let intervalId;
    if (phasesSaveStatus) {
      dispatch(savePhasesChanges(true));
      intervalId = setTimeout(() => {
        dispatch(savePhasesChanges(false));
      }, 5000);
    }
    if (filesSaveStatus) {
      dispatch(saveFilesChanges(true));
      intervalId = setTimeout(() => {
        dispatch(saveFilesChanges(false));
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [phasesSaveStatus, filesSaveStatus, dispatch]);

  const [inputWidth, setInputWidth] = useState('200px');

  useEffect(() => {
    const handleResize = debounce(() => {
      const inputElement = headerRef.current;
      if (inputElement !== null) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = window.getComputedStyle(inputElement).font;
        const textWidth =
          context.measureText(inputElement.value.split('\n').pop()).width + 12;
        setInputWidth(`${textWidth}px`);
      }
    }, 50);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [header]);

  useEffect(() => {
    if (headerRef.current) {
      if (headerRef?.current?.value?.length < 3) {
        headerRef.current.focus();
      }
    }
  }, [headerRef.current]);

  const handleChangeHeader = (e) => {
    setHeader(e.target.value);
  };

  const handleBlurHeader = (e) => {
    const newProductName = e.target.value;
    if (newProductName.length >= 3) {
      setHeader(newProductName);
      dispatch(updateCurrentProduct({ productName: newProductName }));
      dispatch(updateProducts({ id, productName: newProductName }));
    } else {
      setIsInputFocused(true);
      if (headerRef.current) {
        headerRef.current.focus();
      }
    }
  };

  return (
    <Box
      component='main'
      className={classes.pageWrapper}
      sx={{
        ...pageWrapper,
        width: narrowSidebar ? 'calc(100vw - 4.5rem)' : 'calc(100vw - 15rem)',
        marginLeft: narrowSidebar ? '4.5rem' : '15rem',
      }}
    >
      {(phasesSaveStatus || filesSaveStatus) && (
        <Box component='div' className={classes.alertBox}>
          <Alert
            onClose={() => {
              phasesSaveStatus && dispatch(savePhasesChanges(false));
              filesSaveStatus && dispatch(saveFilesChanges(false));
            }}
          >
            {phasesSaveStatus && (
              <Typography>Änderungen in `Produktphasen` gespeichert</Typography>
            )}
            {filesSaveStatus && (
              <Typography>Änderungen in `Produktakten` gespeichert</Typography>
            )}
          </Alert>
        </Box>
      )}
      <Breadcrumbs className={classes.breadcrumbsBox}>
        {homePage ? (
          <Link to={homePage} className={styles.parentPath}>
            {parentTab}
          </Link>
        ) : null}
        <NavLink
          end
          to={`/${parentRoute}`}
          style={({ isActive }) => {
            return {
              fontWeight: parentRoute || name ? 400 : 500,
              pointerEvents: parentRoute ? 'auto' : 'none',
            };
          }}
          className={styles.pagePath}
        >
          {pathName}
        </NavLink>
        {nestedDocument ? (
          <Typography component='h6' className={classes.titleStyle}>
            {nestedDocument}
          </Typography>
        ) : null}
        {name ? (
          <Typography component='h6' className={classes.titleStyle}>
            {header}
          </Typography>
        ) : null}
      </Breadcrumbs>
      <Box component='div' className={classes.headerBox}>
        {linkName && (
          <TextField
            color='product'
            value={header}
            inputRef={headerRef}
            onChange={handleChangeHeader}
            onBlur={handleBlurHeader}
            className={
              isInputFocused && header.length < 3
                ? 'SingleProductError'
                : 'SingleProduct'
            }
            inputProps={{
              style: {
                width: inputWidth,
                transition: 'width 0.2s ease-in-out',
                padding: '0 !important',
                fontSize: '2.375rem',
                color: '#00000099',
                letterSpacing: '0.0175rem',
              },
            }}
          />
        )}
        {pageLabel && !linkName ? (
          <Box component='div' className={classes.labelImgBox}>
            <Typography component='h3' className='PageHeader'>
              {pageLabel}
            </Typography>
            {dayTimeImgSrc && (
              <img
                alt='day time'
                src={dayTimeImgSrc}
                width='37px'
                height='37px'
              />
            )}
          </Box>
        ) : null}
        {button || null}
      </Box>
      {children}
    </Box>
  );
}
