import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import { API_URL } from 'api/api-urls';
import { AppbarSearch } from 'components/autocomplete/appbar-search';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import { ICON } from 'enum/icons.enum';
import { appbarTabs } from 'pages/qsistant/qsistant.constants';
import { routes } from 'routes/routes';
import {
  handleAppbar,
  handleModalHasChanges,
  handleSidebar,
  handleTabValue,
  handleUpgradePlanModal,
  setDiscardedRoute,
} from 'store/common/common-slice';
import { searchDocuments } from 'store/common/search/search-results-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useEffect, useRef, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { UserMenu } from '../context-menu';
import { AuthContext } from '../login/AuthContext';
import { PackageInfo } from '../package-info';
import { useStyles } from './styles';

export function AppbarComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatcher = useAppDispatch();
  const tariffPlan = 'regulatory';
  const userMenuRef = useRef<HTMLButtonElement>(null);
  const statusRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const searchQuery = useMediaQuery('(max-width:1400px)');
  const userBoxIcon = useMediaQuery('(max-width:1024px)');
  const logoBadgeQuery = useMediaQuery('(max-width:968px)');

  const [query, setQuery] = useState('');
  const [userDropdown, setUserDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStatus, setOpenStatus] = useState(false);
  const [localData, setLocalData] = useState([]);
  const { state } = React.useContext(AuthContext);

  const { changeTariffPlan, activeTabValue, foundData, hasChanges } =
    useAppSelector((data) => ({
      changeTariffPlan: data.commonSlice.changeTariffPlan,
      activeTabValue: data.commonSlice.activeTabValue,
      foundData: data.searchResultsReducer.searchResult,
      hasChanges: data.commonSlice.hasChanges,
      discardedRoute: data.commonSlice.discardedRoute,
    }));

  let searchHistory = localStorage.getItem('searchHistory');

  if (!searchHistory) searchHistory = '';
  else searchHistory = JSON.parse(searchHistory);

  const handleOnClick = (path: string, id: number) => {
    dispatcher(handleTabValue(id));
    navigate(path);
  };

  const handleSearch = (e) => {
    if (query !== '' && e.key === 'Enter') {
      navigate({
        pathname: '/search',
        search: createSearchParams({
          q: query,
        }).toString(),
      });
      e.target.blur();
      let searchHistoryData = [];
      if (localData.length === 1) {
        const sliced = localData.slice(1);
        setLocalData([
          ...sliced,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ]);
        searchHistoryData = [
          ...localData,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ];
      } else {
        setLocalData([
          ...localData,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ]);
        searchHistoryData = [
          ...localData,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ];
      }
      localStorage.setItem('searchHistory', JSON.stringify(searchHistoryData));
    }
  };

  useEffect(() => {
    if (query.length >= 3) {
      dispatcher(searchDocuments(query));
    }
  }, [query]);

  useEffect(() => {
    const activeTab = appbarTabs.find((tab) =>
      location.pathname.startsWith(tab.path)
    );
    if (activeTab) {
      dispatcher(handleTabValue(activeTab.id));
    }
  }, [location]);

  return (
    <AppBar component='header' position='fixed'>
      <Box display='flex' alignItems='center' component='div' height='100%'>
        <IconButton
          onClick={() => dispatcher(handleSidebar())}
          sx={{ mr: '0.25rem' }}
        >
          <Icon name={ICON.MENU} size={1.5} fill='#00000099' />
        </IconButton>
        {!logoBadgeQuery ? (
          <Icon
            name={ICON.QSISTANT_LOGO}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => navigate(routes().home)}
          />
        ) : (
          <Icon
            name={ICON.Q_LOGO}
            size={2.92}
            style={{
              cursor: 'pointer',
              marginLeft: '5px',
              marginBottom: '6px',
            }}
            onClick={() => navigate(routes().home)}
          />
        )}
        <Status
          ref={statusRef}
          statusText={tariffPlan}
          status={tariffPlan}
          style={{
            position: 'relative',
            cursor: 'pointer',
            textTransform: 'capitalize',
            fontSize: '.75rem',
            userSelect: 'none',
            height: '1.375rem',
            padding: '0 0.625rem',
            display: logoBadgeQuery ? 'none' : 'flex',
          }}
          onClick={() => setOpenStatus((prev) => !prev)}
          onChangeTariff={changeTariffPlan}
        />
      </Box>
      <Box component='div' className={classes.navbarActionsBox}>
        <Tabs
          value={
            window.location.href.includes('my_profile') ? false : activeTabValue
          }
          onChange={(event, newValue) => {
            if (hasChanges) {
              dispatcher(handleModalHasChanges(true));
              return;
            }
            dispatcher(handleTabValue(newValue));
          }}
          className={classes.muiTabs}
          TabIndicatorProps={{
            sx: {
              background: ({ palette }) =>
                palette[appbarTabs[activeTabValue].color].main,
            },
          }}
        >
          {appbarTabs.map((tab) => (
            <Tab
              key={tab.id}
              value={tab.id}
              label={tab.label}
              className={classes.tab}
              onClick={() => {
                if (hasChanges) {
                  dispatcher(handleModalHasChanges(true));
                  dispatcher(setDiscardedRoute({ route: tab.path }));
                  return;
                }
                handleOnClick(tab.path, tab.id);
              }}
            />
          ))}
        </Tabs>
        {!searchQuery ? (
          <AppbarSearch
            placeholder='Suche nach Dokumenten und Nachweisen'
            value={query}
            onInputChange={(e, val) => setQuery(val)}
            onHistoryChange={(val) => setQuery(val)}
            onKeyDown={handleSearch}
            options={[
              { id: 52401359, component: 'dividerTop', lab: 'dt' },
              ...searchHistory,
              { id: 1540099, component: 'dividerBottom', lab: 'db' },
              { id: 34568, component: 'label', lab: 'Zuletzt verwendet' },
              { id: 123423, component: 'viewHistory', lab: 'Test' },
              { id: 342160, component: 'viewHistory', lab: 'Document ISO' },
              ...foundData,
            ]}
          />
        ) : (
          <IconButton onClick={() => dispatcher(handleAppbar(true))}>
            <Icon name={ICON.SEARCH} />
          </IconButton>
        )}
        <Button
          variant='outlined'
          ref={userMenuRef}
          className={classes.primaryUserBox}
          onClick={() => {
            setUserDropdown(!userDropdown);
            setAnchorEl(userMenuRef);
          }}
          onBlur={() => setUserDropdown(false)}
          sx={{
            outline: window.location.href.includes('my_profile')
              ? '0.14rem solid #000000de'
              : '0.095rem solid #e0e0e0',
          }}
          endIcon={
            <Icon
              name={ICON.ARROW_DROP_DOWN}
              style={{
                rotate: userDropdown ? '180deg' : '0deg',
                display: userBoxIcon ? 'none' : 'flex',
              }}
              size={1.5}
            />
          }
          startIcon={
            <Avatar
              sx={{
                backgroundColor: state.user?.avatarColor,
              }}
              className={classes.avatar}
              src={
                !state.user?.avatar
                  ? null
                  : `${process.env.REACT_APP_API_URL}${API_URL.files.download}/${state.user?.avatar}?thumb=100`
              }
            >
              {state.user?.prename?.charAt(0)}
            </Avatar>
          }
        >
          {userBoxIcon ? null : state.user?.prename}
        </Button>
      </Box>
      {openStatus && (
        <PackageInfo
          tariff={tariffPlan}
          onClick={() => {
            dispatcher(handleUpgradePlanModal(true));
            setOpenStatus(false);
          }}
          open={openStatus}
          onClose={() => setOpenStatus(false)}
          anchorEl={statusRef}
        />
      )}
      {userDropdown && (
        <UserMenu
          open={userDropdown}
          anchorEl={anchorEl}
          onClose={() => setUserDropdown(false)}
        />
      )}
    </AppBar>
  );
}
