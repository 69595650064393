import { Box, Button, Divider, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { ModalAppVerificationProps } from './types';

export function ModalAppVerification({
  open,
  onClose,
  header,
}: ModalAppVerificationProps) {
  const classes = useStyles();
  return (
    <ModalWrapper open={open} className={classes.modal}>
      <ModalHeader
        header={header}
        onClose={onClose}
        primaryColor='outlined'
        icon={{ name: 'app_shortcut_filled' }}
      />
      <Divider className={classes.divider} />
      <Box component='div' className={classes.contentBox}>
        <Typography component='p' className={classes.description}>
          Scanne zur Aktivierung der Zwei-Faktor-Authentifizierung den folgenden
          QR-Code mit einer der unterstützten Apps.{' '}
        </Typography>
        <Icon
          name='info'
          size={1}
          style={{ position: 'absolute', top: '2.875rem', left: '17rem' }}
        />
      </Box>
      <Box component='div' className={classes.linkBox}>
        <a
          href='https://www.qr-code-generator.com/'
          style={{ cursor: 'pointer' }}
          rel='noopener noreferrer'
          target='_blank'
        >
          <img
            alt='code'
            src='https://chart.googleapis.com/chart?cht=qr&chl=Hello%20guys&chs=180x180&choe=UTF-8&chld=L|2'
          />
        </a>
      </Box>
      <Box component='div' className={classes.btnsBox}>
        <Button variant='text' onClick={onClose} color='documentSetup'>
          Abbrechen
        </Button>
        <Button variant='text' color='documentSetup'>
          Weiter
        </Button>
      </Box>
    </ModalWrapper>
  );
}
