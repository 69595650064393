import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  changeUserPassword,
  fetchUserData,
  onValidateEmailAuth,
  updateUserData,
} from 'api/services/user.services';

import {
  asyncThunkSaveUserData,
  asyncThunkValidateEmailAuth,
  initialState,
} from './state';
import type { ExtendedUserTypes, UserPasswordData } from './types';

export const getUser = createAsyncThunk(
  'user/fetch',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchUserData();
      dispatch(setUserData(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/update',
  async (userData: ExtendedUserTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateUserData(userData);
      dispatch(setUserData(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (userPassword: UserPasswordData, { rejectWithValue, dispatch }) => {
    try {
      const response = await changeUserPassword(userPassword);
      dispatch(setUserData(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);
// TODO define response.data
export const validateEmailAuth = createAsyncThunk(
  'user/validateEmailAuth',
  async (code: { code: string }, { rejectWithValue }) => {
    try {
      const response = await onValidateEmailAuth(code);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<ExtendedUserTypes>) {
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    asyncThunkValidateEmailAuth(builder, validateEmailAuth);
    asyncThunkSaveUserData(builder, updateUser);
  },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
