import { generateExtraReducers } from 'utils/utils';

import type { ProcessTypesSlice } from './types';

export const initialState: ProcessTypesSlice = {
  documents: [],
  modal: false,
  processCatList: [],
  addProcessError: false,
  fetchProcessStatus: null,
  fetchProcessError: null,
};

export const asyncThunkFetchProcesses = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'fetchProcessStatus',
  errorKey: 'fetchProcessError',
});
