import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'wrapper'
  | 'title'
  | 'headerBox'
  | 'caption'
  | 'description'
  | 'unexpandedWrapperBox'
  | 'tabStyle'
  | 'secondaryTabs'
  | 'mainContentBox'
  | 'listItem';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, palette, colors }) => {
    return {
      wrapper: {
        background: colors.white,
        height: 'calc(100vh - 18rem)',
        borderTopRightRadius: '0.3125rem',
        position: 'fixed',
        right: '2rem',
      },
      mainContentBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // '@media(max-width: 1700px)': {
        //   height: '100%',
        // },
        // '@media(max-height: 720px)': {
        //   height: '63%',
        // },
      },
      title: {
        color: colors.text.primary,
        fontWeight: `${typography.fontWeightMedium} !important`,
        fontSize: `${typography.pxToRem(size.font2XL)} !important`,
        letterSpacing: '0.15px !important',
      },
      headerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${typography.pxToRem(size.fontL)} ${typography.pxToRem(
          size.font24L
        )}`,
      },
      caption: {
        color: colors.text.primary,
        fontWeight: `${typography.fontWeightMedium} !important`,
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        letterSpacing: '0.1px !important',
      },
      description: {
        color: colors.text.primary,
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        lineHeight: '1.125rem !important',
        letterSpacing: '0.1px !important',
      },
      unexpandedWrapperBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${typography.pxToRem(size.fontL)} 0`,
      },
      tabStyle: {
        '& .MuiTabs-flexContainer': {
          justifyContent: 'center',
          textTransform: 'capitalize',
          '& .Mui-selected': {
            color: palette.qsistant.main,
          },
          '& .MuiTab-root': {
            textTransform: 'capitalize',
          },
        },
      },
      secondaryTabs: {
        '& .MuiTabs-flexContainer': {
          justifyContent: 'space-evenly',
          '& .Mui-selected': {
            color: palette.qsistant.main,
          },
          '& .MuiTab-root': {
            width: '50%',
          },
        },
        '& .MuiTabs-indicator': {
          top: 0,
        },
      },
      listItem: {
        fontWeight: typography.fontWeightMedium,
        lineHeight: `${typography.pxToRem(size.fontL)}`,
        fontSize: `${typography.pxToRem(size.fontM)}`,
        letterSpacing: '0.015rem',
        cursor: 'pointer',
        width: 'max-content !important',
        '&:before': {
          content: '"━"',
          position: 'absolute',
          top: `${typography.pxToRem(size.font_8)}`,
          left: `${typography.pxToRem(-size.font_8)}`,
          color: palette.qsistant.main,
        },
      },
    };
  }
);
