import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'title'
  | 'boxWrapper'
  | 'description'
  | 'iconTitleBox'
  | 'iconButton'
  | 'documentNameBox'
  | 'documentName'
  | 'statusIcon';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, colors, size }) => {
    return {
      title: {
        color: colors.text.primary,
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        letterSpacing: `${typography.pxToRem(0.15)} !important`,
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
      iconTitleBox: {
        display: 'flex',
        alignItems: 'center',
        gap: typography.pxToRem(size.font_4),
      },
      description: {
        color: colors.text.primary,
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        letterSpacing: `${typography.pxToRem(0.1)} !important`,
      },
      boxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: `${typography.pxToRem(size.fontL)} !important`,
      },
      iconButton: {
        padding: '0 !important',
      },
      documentNameBox: {
        display: 'flex',
        alignItems: 'center',
        gap: typography.pxToRem(size.font_4),
        padding: `${typography.pxToRem(size.font_8)} 0`,
        cursor: 'pointer',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.04)',
        },
      },
      documentName: {
        color: colors.gray.icon,
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        letterSpacing: `${typography.pxToRem(0.25)} !important`,
      },
      statusIcon: {
        padding: `${typography.pxToRem(size.font_4)} !important`,
        borderRadius: `${typography.pxToRem(size.font_4)} !important`,
        backgroundColor: 'green !important',
      },
    };
  }
);
