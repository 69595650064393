// TODO Slice, CRUD operations - to follow one code style
import { Box, Button, Fab, SelectChangeEvent, Typography } from '@mui/material';
import { FiltersBox, SortFilterBox } from 'components/common';
import { EmptyList } from 'components/empty-list';
import { FilterDropdown } from 'components/filter-dropdown';
import { Icon } from 'components/icon';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { UserModal } from 'modals/user-modal';
import {
  addUser,
  fetchDocuments,
  handleModal,
} from 'store/administration/members/members-slice';
import type { MemberDocumentTypes } from 'store/administration/members/types';
import {
  resetFilters,
  setMultipleFilters,
} from 'store/common/page-filters/page-filters-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { multipleFiltersChange } from 'utils/utils';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sortByOptions } from './members-list.constants';
import useTabelCells from './table-cells.hook';

export function MembersListPage({ pathName, parentTab, homePage }) {
  const { t } = useTranslation([
    'sort',
    'status',
    'user',
    'common',
    'document',
  ]);
  const dispatch = useAppDispatch();
  const {
    documents,
    modal,
    addUserError: errors,
    rolesList,
    status,
  } = useAppSelector((state) => ({
    documents: state.membersReducer.documents,
    modal: state.membersReducer.modal,
    addUserError: state.membersReducer.addUserError,
    rolesList: state.membersReducer.rolesList,
    status: state.membersReducer.status,
  }));

  const { persistedRoles } = useAppSelector((state) => ({
    persistedRoles: state.pageFiltersSlice.membersPage.persistedRoles,
  }));

  const { cells } = useTabelCells();

  const [filtered, setFiltered] = useState<MemberDocumentTypes[]>(null);
  const [sortBy, setSortBy] = useState<(typeof sortByOptions)[number]['value']>(
    sortByOptions[0].value
  );
  const [roles, setRoles] = useState(persistedRoles || ['']);

  const sortByCriteria = (array: MemberDocumentTypes[], criteria: string) => {
    const modifiedArray = [...array];
    switch (criteria) {
      case 'default':
        break;
      case 'alphabet ascending':
        modifiedArray.sort((a, b) => a.fullName.localeCompare(b.fullName));
        break;
      case 'alphabet descending':
        modifiedArray.sort((a, b) => b.fullName.localeCompare(a.fullName));
        break;
      default:
        break;
    }
    return modifiedArray;
  };

  const filterArrayByOptions = (
    arrayToFilter: MemberDocumentTypes[],
    options: Array<string>
  ) => {
    if (options.length === 1) {
      return arrayToFilter;
    }
    return arrayToFilter.filter((doc) => {
      const availableRoles = doc.access_Roles.map((role) => role.name);
      return options.some((option) => availableRoles.includes(option));
    });
  };

  const handleRoleFilter = (e: SelectChangeEvent<string[] | string>) => {
    multipleFiltersChange(e, setRoles);
    dispatch(
      setMultipleFilters({
        key: 'membersPage',
        subKey: 'persistedRoles',
        values: e.target.value as string[],
      })
    );
  };

  const resetFilter = () => {
    dispatch(resetFilters({ key: 'membersPage' }));
    setRoles(['']);
  };

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const filterRes = filterArrayByOptions(documents, roles);
    const sorting = sortByCriteria(filterRes, sortBy);
    setFiltered(sorting);
  }, [roles, documents, sortBy]);

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      {documents.length > 0 && status === 'resolved' ? (
        <>
          <Typography component='p' className='PageInfo'>
            Insgesamt {filtered && filtered.length} Mitglieder verfügbar.
          </Typography>
          <SortFilterBox>
            <FiltersBox>
              <FilterDropdown
                multiple
                value={roles}
                isFilterApplied={roles.length > 1}
                onChange={handleRoleFilter}
                options={rolesList}
                primaryColor='management'
                filterLabel={t('sort:role')}
              />
              <Button
                variant='text'
                color='management'
                disabled={roles.length === 1}
                onClick={resetFilter}
              >
                {t('sort:resetfilter')}
              </Button>
            </FiltersBox>
            <SortSelect
              options={sortByOptions}
              value={sortBy}
              primaryColor='management'
              onChange={(e) => setSortBy(e.target.value)}
              noOneChosen={sortBy === sortByOptions[0].value}
            />
          </SortFilterBox>
          {filtered && filtered.length > 0 ? (
            <>
              <Table cells={cells} data={filtered} />
              <Box component='div' display='flex' justifyContent='center'>
                <Fab
                  variant='extended'
                  size='large'
                  color='primary'
                  aria-label='add'
                  className='AbsolutePosition management'
                  onClick={() => dispatch(handleModal(true))}
                >
                  <Icon name={ICON.PLUS} size={1.5} fill='white' />
                  {t('user:adduser')}
                </Fab>
              </Box>
            </>
          ) : (
            <EmptyList
              image={{ name: ICON.NOFILTERS_MEMBERS }}
              title='Wir konnten keine Teammitglieder finden.'
              primaryButton={
                <Button
                  variant='outlined'
                  color='management'
                  onClick={resetFilter}
                >
                  {t('document:resetfilter')}
                </Button>
              }
            />
          )}
        </>
      ) : (
        <Box component='div' mt={2}>
          {cells.map((item) => (
            <TableSkeleton key={item.id} />
          ))}
        </Box>
      )}
      {modal && (
        <UserModal
          closeModal={() => dispatch(handleModal(false))}
          modal={modal}
          errors={errors}
          handleSuccess={(data) => dispatch(addUser(data))}
          icon={{ name: ICON.ADD_PERSON, color: 'management' }}
        />
      )}
    </PageWrapper>
  );
}
