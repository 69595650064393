import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import clsx from 'clsx';

import api from '../../api/api';
import QsistantImage from '../../images/QsistantImage.svg';
import Logo from '../../images/logo_bg_white.png';
import { AuthContext } from './AuthContext';
import { useStyles } from './LoginStyle';
import PasswordStrength from './PasswordStrength';

function NewPassword() {
  const classes = useStyles();

  const { dispatch } = React.useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['login', 'common']);
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');

  const newPasswordAction = () => {
    setError(false);
    setIsLoading(true);
    api
      .post(
        '/login/newpassword',
        {
          password,
          password2,
          token: searchParams.get('token'),
        },
        {}
      )
      .then((result) => {
        dispatch({
          type: 'LOGIN',
          user: result?.data?.user,
          token: result?.data?.token,
        });
        window.location.href = '/';
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.LogoWrapper}>
        <div className={classes.QsistantArrangement}>
          <img className={classes.Logo} src={Logo} alt='logo' />
          <p className={classes.LogoText}>{t('login:logoText')}</p>
          <img
            className={classes.QsistantImage}
            src={QsistantImage}
            alt='Qsistant Logo'
          />
        </div>
      </div>

      <div className={classes.LoginContainer}>
        <div className={classes.LoginArrangement}>
          <h1 className={classes.FormHeading}>{t('login:reset')}</h1>
          <h4 className={classes.subTitle}>{t('login:new-password')}</h4>
          <Collapse in={error}>
            <Alert
              severity='error'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <Close fontSize='inherit' />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t('login:passwordmissmatchorinvalid')}
            </Alert>
          </Collapse>
          <PasswordStrength password={password} password2={password2} />
          <div className={classes.InputContainer} style={{ marginBottom: 10 }}>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              style={{ marginTop: 10 }}
              variant='outlined'
              error={error}
            >
              <InputLabel htmlFor='outlined-adornment-password'>
                {t('login:password')}
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password'
                variant='outlined'
                label={t('login:password')}
                name='password'
                type={!showPassword ? 'password' : 'text'}
                error={error}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: 10 }}
                className={classes.Emailinput}
                helperText={password === '' ? t('login:nopassword') : ''}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              style={{ marginTop: 10 }}
              variant='outlined'
              error={error}
            >
              <InputLabel htmlFor='outlined-adornment-password2'>
                {t('login:password2')}
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password2'
                variant='outlined'
                name='password_new'
                label={t('login:password2')}
                type={!showPassword2 ? 'password' : 'text'}
                error={error}
                value={password2}
                style={{ marginBottom: 10 }}
                onChange={(e) => setPassword2(e.target.value)}
                className={classes.Emailinput}
                helperText={password2 === '' ? t('login:nopassword2') : ''}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword2(!showPassword2)}
                      edge='end'
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <Button
            variant='contained'
            className={classes.LoginBtn}
            onClick={newPasswordAction}
            disabled={
              password === '' ||
              password2 === '' ||
              password !== password2 ||
              isLoading
            }
          >
            {t('login:reset')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
