import { AppBar, IconButton } from '@mui/material';
import { AppbarSearch } from 'components/autocomplete/appbar-search';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { handleAppbar } from 'store/common/common-slice';
import { searchDocuments } from 'store/common/search/search-results-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

export function MinAppbarComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let searchHistory = localStorage.getItem('searchHistory');

  if (!searchHistory) searchHistory = '';
  else searchHistory = JSON.parse(searchHistory);

  const { foundData } = useAppSelector((state) => ({
    foundData: state.searchResultsReducer.searchResult,
  }));
  const [query, setQuery] = useState<string>('');
  const [localData, setLocalData] = useState<
    Array<{ lab: string; component: string; id: string }>
  >([]);

  const handleSearch = (e) => {
    if (query !== '' && e.key === 'Enter') {
      navigate({
        pathname: '/search',
        search: createSearchParams({
          q: query,
        }).toString(),
      });
      e.target.blur();
      let searchHistoryData = [];
      if (localData.length === 1) {
        const sliced = localData.slice(1);
        setLocalData([
          ...sliced,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ]);
        searchHistoryData = [
          ...localData,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ];
      } else {
        setLocalData([
          ...localData,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ]);
        searchHistoryData = [
          ...localData,
          { lab: query, component: 'searchHistory', id: crypto.randomUUID() },
        ];
      }
      localStorage.setItem('searchHistory', JSON.stringify(searchHistoryData));
    }
  };

  useEffect(() => {
    if (query.length >= 3) {
      dispatch(searchDocuments(query));
    }
  }, [query]);

  return (
    <AppBar component='header'>
      <IconButton onClick={() => dispatch(handleAppbar(false))}>
        <Icon name={ICON.ARROW_LEFT} />
      </IconButton>
      <AppbarSearch
        sx={{ width: '70% !important' }}
        placeholder='Suche nach Dokumenten und Nachweisen'
        value={query}
        onInputChange={(e, val) => setQuery(val)}
        onHistoryChange={(val) => setQuery(val)}
        onKeyDown={handleSearch}
        options={[
          { id: 52401359, component: 'dividerTop', lab: 'dt' },
          ...searchHistory,
          { id: 1540099, component: 'dividerBottom', lab: 'db' },
          { id: 34568, component: 'label', lab: 'Zuletzt verwendet' },
          { id: 123423, component: 'viewHistory', lab: 'Test' },
          { id: 342160, component: 'viewHistory', lab: 'Document ISO' },
          ...foundData,
        ]}
      />
      <IconButton sx={{ visibility: 'hidden' }}>
        <Icon name={ICON.ARROW_LEFT} />
      </IconButton>
    </AppBar>
  );
}
