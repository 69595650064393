import { Box, Button, Typography } from '@mui/material';
import { InputField } from 'components/custom-input';
import { Icon } from 'components/icon';

import React, { useState } from 'react';

import { isEmpty } from 'lodash';

import { useStyles } from './styles';
import type { CycleAuditsStepProps } from './types';

export function CycleAuditsStep({
  stepId,
  audits,
  createField,
  removeField,
  editField,
  setOption,
  toNextStep,
  selectedOption,
}: CycleAuditsStepProps) {
  const classes = useStyles();

  const [isCreation, setIsCreation] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');

  return (
    <>
      <Typography component='h4' className={classes.actionTitle}>
        In welchen Zyklen werden bei Ihnen interne Audits durchgeführt?
      </Typography>
      <Box component='div' className={classes.textfieldsBox}>
        {audits.map((audit) => (
          <InputField
            key={audit.id}
            className={classes.cycleAudits}
            onChange={(e) =>
              editField(
                { id: audit.id.toString(), label: e.target.value },
                stepId
              )
            }
            onClick={() =>
              setOption({ id: audit.id, label: audit.label }, stepId)
            }
            onDelete={(e) => {
              e.stopPropagation();
              removeField(audit.id, stepId);
            }}
            check={
              !isEmpty(selectedOption) &&
              'label' in selectedOption &&
              selectedOption.label === audit.label
            }
            {...audit}
          />
        ))}
        {isCreation && (
          <InputField
            className={classes.cycleAudits}
            label={label}
            onChange={(e) => setLabel(e.target.value)}
            onBlur={() => {
              if (!label) return;
              setIsCreation(false);
              createField(label, stepId);
              setLabel('');
            }}
          />
        )}
        <Button
          variant='text'
          color='documentSetup'
          startIcon={<Icon name='add' fill='#6E358C' size={1.5} />}
          onClick={() => setIsCreation(true)}
        >
          AUSWAHL HINZUFÜGEN
        </Button>
      </Box>
      <Box component='div' display='flex' justifyContent='flex-end' mt='2rem'>
        <Button
          variant='contained'
          color='documentSetup'
          disabled={
            !selectedOption ||
            !('id' in selectedOption) ||
            isEmpty(selectedOption)
          }
          onClick={() =>
            toNextStep(selectedOption as { id: number; label: string }, stepId)
          }
        >
          EINGABEN SPEICHERN & WEITER
        </Button>
      </Box>
    </>
  );
}
