// TODo Steps data
import {
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import { statusData } from 'components/status/styles';
import { revisionsData } from 'pages/qsistant/qsistant.constants';

import React, { useState } from 'react';

import type {
  DocumentInfoStepperProps,
  RevisionIconProps,
  StatusValuesTypes,
} from './types';

function StepIcon(props: RevisionIconProps) {
  const { label, active, color, backgroundColor } = props;
  return (
    <IconButton
      sx={{
        color,
        backgroundColor,
        width: ({ typography }) => typography.pxToRem(56),
        height: ({ typography }) => typography.pxToRem(56),
        borderRadius: '50%',
        outline: active ? `2px solid ${color}` : 'none',
        fontSize: ({ typography, size }) => typography.pxToRem(size.fontM),
        fontWeight: ({ typography }) => typography.fontWeightMedium,
      }}
    >
      {label}
    </IconButton>
  );
}

export function DocumentInfoStepperComponent({
  status,
  children,
}: DocumentInfoStepperProps) {
  const statusValues: StatusValuesTypes = statusData[status] || '';

  const [activeStep, setActiveStep] = useState(0);
  return (
    <Stepper
      orientation='vertical'
      activeStep={activeStep}
      sx={{
        '& .MuiStepContent-root': {
          marginLeft: '1.7rem',
          paddingLeft: '2.5rem',
        },
        '& .MuiStepConnector-root': {
          marginLeft: '1.7rem',
        },
        '& .MuiStepLabel-root': {
          padding: 0,
        },
        '& .MuiCollapse-wrapperInner': {
          marginTop: '-2.4rem',
        },
      }}
    >
      {revisionsData.map((step, index) => (
        <Step key={step.id}>
          <StepLabel
            onClick={() => setActiveStep(index)}
            StepIconComponent={(props: RevisionIconProps) =>
              StepIcon({
                ...props,
                label: step.label,
                color: statusValues.color,
                backgroundColor: statusValues.backgroundColor,
              })
            }
          />
          <StepContent>{children}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
