import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from './styles';
import type { PlanDetailsModalProps } from './types';

export function PlanDetailsModal({
  open,
  onClose,
  onBack,
  selectedPlan,
  onNext,
}: PlanDetailsModalProps) {
  const classes = useStyles();
  const [option, setOption] = useState<boolean>(false);
  const [option1, setOption1] = useState<boolean>(false);
  return (
    <ModalWrapper open={open} className={classes.planDetailsModal}>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <IconButton onClick={onBack}>
          <Icon name={ICON.ARROW_LEFT} />
        </IconButton>
        <IconButton onClick={onClose}>
          <Icon name={ICON.CLOSE} />
        </IconButton>
      </Box>
      <Box component='div' mt='1rem' mb='2rem'>
        <Typography component='h4' className={classes.planDetailsHeader}>
          <span>{`${
            selectedPlan && 'privilege' in selectedPlan && selectedPlan?.label
          } Plan`}</span>{' '}
          - Buchungsdetails im Überblick
        </Typography>
      </Box>
      <Box component='div' display='flex' gap='2rem'>
        <Box component='div'>
          <Typography component='h6' className={classes.planDetailsCaption}>
            Firma
          </Typography>
          <Typography component='p' className={classes.planDetailsText}>
            MiQ GmbH & Co. KG
          </Typography>
        </Box>
        <Box component='div' mb='1rem'>
          <Box component='div' display='flex' alignItems='center'>
            <Typography component='h6' className={classes.planDetailsCaption}>
              Zahlungsmethode
            </Typography>
            <Icon name={ICON.INFO} size={1.25} fill='rgba(0, 0, 0, 0.8)' />
          </Box>
          <Typography component='p' className={classes.planDetailsText}>
            Rechnung
          </Typography>
        </Box>
      </Box>
      <Box component='div' className={classes.plansBox}>
        <Box component='div' className={classes.planBox} sx={{ opacity: 0.54 }}>
          <Typography
            component='h6'
            sx={{ cursor: 'auto' }}
            className={classes.planDetailsCaption}
          >
            Aktuell: Free
          </Typography>
          <Box component='div' display='flex' alignItems='center' gap='0.5rem'>
            <Icon name={ICON.CHECKED} size={1.25} />
            <Typography component='p' className={classes.planDetailsText}>
              1 Benutzer
            </Typography>
          </Box>
          <Box component='div' display='flex' alignItems='center' gap='0.5rem'>
            <Icon name={ICON.CHECKED} size={1.25} />
            <Typography component='p' className={classes.planDetailsText}>
              Ideal für Einsteiger
            </Typography>
          </Box>
        </Box>
        <Box component='div' className={classes.planBox}>
          <Typography component='h6' className={classes.planDetailsCaption}>
            Neues Paket:{' '}
            <span>
              {selectedPlan && 'label' in selectedPlan && selectedPlan?.label}
            </span>
          </Typography>
          {selectedPlan &&
            'privilege' in selectedPlan &&
            selectedPlan?.privilege.map((item) => (
              <Box
                key={item.id}
                component='div'
                display='flex'
                alignItems='center'
                gap='0.5rem'
              >
                <Icon name={ICON.CHECKED} fill='#3F97BE' size={1.25} />
                <Typography component='p' className={classes.planDetailsText}>
                  {item.label}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
      <Box component='div' mb='2.5rem'>
        <Typography component='h6' className={classes.planDetailsCaption}>
          Datenschutz & Rechtliches
        </Typography>
        <FormControlLabel
          sx={{ ml: 0 }}
          control={
            <Checkbox
              checked={option}
              onChange={(e) => setOption(e.target.checked)}
              sx={{ p: '0.25rem' }}
            />
          }
          label={
            <Typography component='p' className={classes.planDetailsText}>
              Ich akzeptiere die allgemeinen Geschäftsbedingungen{' '}
              <Link to='/'>AGB</Link>.
            </Typography>
          }
        />
        <FormControlLabel
          sx={{ ml: 0 }}
          control={
            <Checkbox
              checked={option1}
              onChange={(e) => setOption1(e.target.checked)}
              sx={{ p: '0.25rem' }}
            />
          }
          label={
            <Typography component='p' className={classes.planDetailsText}>
              Ich habe die <Link to='/'>Datenschutzerklärung</Link> zur Kenntnis
              genommen.
            </Typography>
          }
        />
      </Box>
      <Box component='div' display='flex' justifyContent='flex-end'>
        <Button
          variant='contained'
          disabled={!option || !option1}
          onClick={onNext}
          color='qsistant'
          sx={{ letterSpacing: '0.025rem' }}
        >
          Verbindlich Buchen
        </Button>
      </Box>
    </ModalWrapper>
  );
}
