import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${typography.pxToRem(size.font24L)}`,
  },
  header: {
    fontSize: `${typography.pxToRem(size.font2XL)} !important`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: `${typography.fontWeightMedium} !important`,
  },
}));
