import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';

import React, { useRef, useState } from 'react';

import { TableIconTooltip } from 'tooltips';

import type { IdentifiedType, TableComponentProps } from './table.types';

export function TableComponent<T extends IdentifiedType>({
  cells,
  data,
  showDescription = false,
  descriptionItem = null,
}: TableComponentProps<T>) {
  const iconRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <TableContainer>
      <Table stickyHeader className='BasicTable'>
        <TableHead>
          <TableRow className='HeadRow'>
            {cells?.map(({ heading, icon }) => (
              <TableCell key={heading} className='HeaderCell' align='left'>
                {heading}
                {icon && (
                  <>
                    <IconButton
                      onClick={() => setOpen(true)}
                      sx={{
                        padding: 0,
                        position: 'absolute',
                        top: '-1px',
                        opacity: 0.64,
                      }}
                      ref={iconRef}
                    >
                      <Icon name={icon.name} fill={icon.fill} size={1.5} />
                    </IconButton>
                    {open ? (
                      <TableIconTooltip
                        open={open}
                        onClose={() => setOpen(false)}
                        anchorEl={iconRef}
                        title='Lieferanten werden im TAB Verwaltung hinterlegt und können zugeordnet
                        und sortiert werden.'
                      />
                    ) : null}
                  </>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <React.Fragment key={item.id}>
              <TableRow className='BodyRow'>
                {cells.map(({ render, id, onClick }) => (
                  <TableCell
                    key={id}
                    onClick={() => (onClick ? onClick(item) : undefined)}
                  >
                    {render(item)}
                  </TableCell>
                ))}
              </TableRow>
              {showDescription ? (
                <TableRow className='SnippetRow'>
                  <TableCell colSpan={6} className='SnippetCell'>
                    <Box sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                      <Typography component='h5' className='TextSnippet'>
                        {descriptionItem?.name(item)}
                      </Typography>
                      <Typography
                        component='p'
                        className='TextSnippetDesc'
                        dangerouslySetInnerHTML={{
                          __html: descriptionItem?.text(item),
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
