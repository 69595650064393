import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    renderedText: {
      color: '#000000CC',
      letterSpacing: '0.009rem !important',
    },
    groupByText: {
      color: 'rgba(0, 0, 0, 0.6)',
      textTransform: 'uppercase !important',
      fontSize: `${typography.pxToRem(10)} !important`,
    } as CSSProperties & { textTransform: 'uppercase !important' },
    menuList: {
      '& .MuiPaper-root': {
        '& .MuiMenu-list': {
          maxHeight: `${typography.pxToRem(240)}`,
        },
      },
    },
  };
});
