/* eslint-disable no-unsafe-optional-chaining */
import { RefObject, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export default function useHorizontallScroll(
  parentBoxRef: RefObject<HTMLDivElement>,
  contentBoxRef: RefObject<HTMLDivElement>
) {
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const { width: contentWidth } = useResizeDetector({
    targetRef: contentBoxRef,
    handleHeight: false,
  });

  const { width: parentWidth } = useResizeDetector({
    targetRef: parentBoxRef,
    handleHeight: false,
  });

  // To define scrollPosition to display left and right buttons (parent div)
  const handleScroll = () => {
    setScrollPosition(parentBoxRef.current?.scrollLeft);
  };

  useEffect(() => {
    if (contentWidth > parentWidth) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }, [contentWidth, parentWidth]);

  const handleLeft = () =>
    parentBoxRef.current.scrollTo({
      left: parentBoxRef.current.scrollLeft - parentWidth / 2,
      top: 0,
      behavior: 'smooth',
    });

  const handleRight = () =>
    parentBoxRef.current.scrollTo({
      left: parentBoxRef.current.scrollLeft + parentWidth / 2,
      top: 0,
      behavior: 'smooth',
    });

  return {
    parentWidth,
    contentWidth,
    scrollPosition,
    showScrollButton,
    handleScroll,
    handleLeft,
    handleRight,
    leftButtonHidden: scrollPosition === 0,
    rightButtonHidden:
      Math.floor(scrollPosition) ===
      parentBoxRef.current?.scrollWidth - parentBoxRef.current?.clientWidth,
  };
}
