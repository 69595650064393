import { generateExtraReducers } from 'utils/utils';

import type { SearchResultsSlice } from './types';

export const initialState: SearchResultsSlice = {
  searchResult: [],
  searchStatus: null,
  searchError: null,
  searchLoader: false,
};

export const asyncThunkSearch = generateExtraReducers({
  initialStateProp: 'searchResult',
  statusKey: 'searchStatus',
  errorKey: 'searchError',
  loadingKey: 'searchLoader',
});
