import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import { EmptyList } from 'components/empty-list';
import { Icon } from 'components/icon';
import { TableSkeleton } from 'components/table-skeleton';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';
import { fetchDepartments } from 'store/administration/departments/departments-slice';
import type { DepartmentTypes } from 'store/administration/departments/types';
import { handleActiveList, handleTabValue } from 'store/common/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { PageWrapper } from 'wrappers/page-wrapper';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './styles.css';

function updateChildVisibility(
  data: DepartmentTypes[],
  id: number,
  showChildren: boolean
) {
  return data.map((item) => {
    if (item.id === id) {
      return { ...item, showChildren };
    }
    if (item.childs) {
      return {
        ...item,
        childs: updateChildVisibility(item.childs, id, showChildren),
      };
    }
    return item;
  });
}

function setAllVisible(data: DepartmentTypes[]) {
  return data.map((item) => {
    if (item.childs) {
      return {
        ...item,
        childs: setAllVisible(item.childs),
        showChildren: true,
      };
    }
    return { ...item, showChildren: true };
  });
}

function Position({
  data,
  toggleShowChildren,
}: {
  data: DepartmentTypes[];
  toggleShowChildren: (id: number) => void;
}) {
  return (
    <>
      {data.map((item) => (
        <li key={item.id}>
          <Card
            style={{ cursor: item.childs?.length > 0 ? 'pointer' : 'default' }}
          >
            <CardContent
              onClick={() =>
                item.childs?.length > 0 && toggleShowChildren(item.id)
              }
            >
              <Typography variant='h5' component='div'>
                <strong>{item.name}</strong>
                {item.childs?.length > 0 && !item.showChildren && (
                  <span> [+] </span>
                )}
              </Typography>
              {item.leaders && item.leaders.length > 0 && (
                <Typography variant='body2'>
                  {item.leaders.map((leader, index) => (
                    <span key={leader.id}>
                      {index > 0 ? ' | ' : ''}
                      {leader.prename} {leader.surname}
                    </span>
                  ))}
                </Typography>
              )}
            </CardContent>
          </Card>
          {item.showChildren && item.childs?.length > 0 && (
            <ul>
              <Position
                data={item.childs}
                toggleShowChildren={toggleShowChildren}
              />
            </ul>
          )}
        </li>
      ))}
    </>
  );
}

export function OrganigramPage({ pathName, parentTab, homePage }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['status', 'department', 'common', 'document']);
  const navigate = useNavigate();
  const outerDivRef = useRef(null);
  const { departments, status } = useAppSelector((state) => ({
    departments: state.departmentsReducer.departments,
    status: state.departmentsReducer.fetchStatus,
  }));
  const [data, setData] = useState<DepartmentTypes[]>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

  const navigateToPage = () => {
    navigate(routes().departments);
    dispatch(handleTabValue(2));
    dispatch(handleActiveList({ parent: 2, item: 5 }));
  };

  useEffect(() => {
    const updatedData = setAllVisible(departments);
    setData(updatedData);
  }, [departments]);

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  const toggleShowChildren = (id: number) => {
    setData((prevData) =>
      updateChildVisibility(
        prevData,
        id,
        !prevData.find((d) => d.id === id).showChildren
      )
    );
  };

  useLayoutEffect(() => {
    if (isInitialRender) {
      const outerDiv = outerDivRef.current;
      if (outerDiv && data) {
        const { clientWidth, scrollWidth } = outerDiv.firstChild;
        const newZoom = clientWidth / scrollWidth;
        setZoom(newZoom > 1 ? 1 : newZoom);
        setIsInitialRender(false);
      }
    }
  }, [data, isInitialRender]);

  if (!data) return null;

  const rootData = data.filter((el) => el.rootDepartment === true);

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
      button={
        <Button
          variant='outlined'
          color='management'
          sx={{ visibility: departments.length === 0 ? 'hidden' : 'visible' }}
          startIcon={<Icon name={ICON.HOUSE} fill='inherit' />}
          onClick={navigateToPage}
        >
          {t('department:todepartments')}
        </Button>
      }
    >
      <div style={{ position: 'relative' }}>
        {status === 'pending' || data === null ? (
          [1, 2, 3, 4, 5, 6].map((item) => <TableSkeleton key={item} />)
        ) : status === 'resolved' && departments.length > 0 ? (
          <>
            <Typography component='p' className='PageInfo'>
              Hier findest du alle Abteilungen, Rollen und Teammitglieder in
              einer Übersicht.
            </Typography>
            <div className='zoom-controls'>
              <IconButton onClick={() => setZoom((prevZoom) => prevZoom + 0.1)}>
                <Icon name={ICON.PLUS} />
              </IconButton>
              <IconButton onClick={() => setZoom((prevZoom) => prevZoom - 0.1)}>
                <Icon name={ICON.MINUS} />
              </IconButton>
            </div>
            <div id='orgchart' ref={outerDivRef}>
              <div
                style={{
                  transform: `scale(${zoom})`,
                  transformOrigin: 'left center',
                }}
              >
                <ul className='tree'>
                  <Position
                    data={rootData}
                    toggleShowChildren={toggleShowChildren}
                  />
                </ul>
              </div>
            </div>
          </>
        ) : (
          <EmptyList
            image={{ name: ICON.NO_ORGANIGRAMM }}
            title='Du hast noch keine Abteilungen mit Teammitgliedern.'
            description='Wenn du Abteilungen erstellst und Teammitglieder zuordnest erscheinen diese hier im Organigramm.'
            primaryButton={
              <Button
                variant='contained'
                color='qsistant'
                startIcon={<Icon name={ICON.OPEN_NEW_TAB} fill='white' />}
                onClick={navigateToPage}
              >
                zu abteilungen
              </Button>
            }
          />
        )}
      </div>
    </PageWrapper>
  );
}
