import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from '../icon';
import { contentBox } from './theme';

function ResetContent({
  resetAction,
  error,
  setError,
  success,
  setSuccess,
  isLoading,
  onChangeStage,
}) {
  const [email, setEmail] = useState('');

  const { t } = useTranslation(['login', 'common']);

  return (
    <>
      <Collapse
        in={error}
        sx={{ position: 'absolute', top: 0, width: '100vw' }}
      >
        <Alert
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setError(false);
              }}
            >
              <Icon name='close' size={1.5} />
            </IconButton>
          }
        >
          {t('login:invalidemail')}
        </Alert>
      </Collapse>
      <Collapse
        in={success}
        sx={{ position: 'absolute', top: 0, width: '100vw' }}
      >
        <Alert
          severity='success'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setSuccess(false);
              }}
            >
              <Icon size={1.5} name='close' />
            </IconButton>
          }
        >
          {t('login:resetsuccessfull')}
        </Alert>
      </Collapse>
      <Box component='div' sx={{ ...contentBox, width: '38rem' }}>
        <Box
          component='div'
          width='100%'
          display='flex'
          justifyContent='flex-start'
        >
          <IconButton onClick={() => onChangeStage('login')}>
            <Icon name='arrow_back' size={2.5} />
          </IconButton>
        </Box>
        <Icon name='new_logo' height={4.75} width={22.5} />
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          textAlign='center'
          alignItems='center'
          gap='1.5rem'
          width='100%'
          marginBottom='2.5rem'
        >
          <Typography className='StageLabel' component='h4'>
            Passwort vergessen?
          </Typography>
          <Typography className='StageDescription' component='h6'>
            Gib deine E-Mail für deinen Account ein auf welche wir eine
            Anleitung zum Zurücksetzen deines Passworts senden
          </Typography>
          <TextField
            fullWidth
            label={t('login:email')}
            variant='outlined'
            name='email'
            type='email'
            error={error}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='LoginTextfield'
            helperText={email === '' ? t('login:noemail') : ''}
          />
          <Box component='div'>
            <Button
              variant='text'
              className='ResetPassword'
              onClick={() => resetAction(email)}
              disabled={email === '' || isLoading}
            >
              {t('login:reset')}
            </Button>
          </Box>
        </Box>
        <Box component='div' width='100%' textAlign='center'>
          <Typography component='p' className='Help'>
            Probleme beim einloggen? <Link to='/'>Hilfe</Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default ResetContent;
