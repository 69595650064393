import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import { UserAvatar } from 'components/user';
import { ELEMENT_TYPES } from 'enum/common.enum';
import { routes } from 'routes/routes';
import type { ElementTypes } from 'store/common-document.types';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { HomeWidgetCardProps } from './home-widget-card.types';
import { useStyles } from './styles';

export function HomeWidgetCard({
  status,
  updatedDate,
  userName,
  documentName,
  revision,
  documentNumber,
  elementType,
  tooltip,
  actionItem,
  documentId,
  user,
}: HomeWidgetCardProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const setIcon = (type: ElementTypes): string => {
    return type === ELEMENT_TYPES.QSISTANT_DOCUMENT ||
      type === ELEMENT_TYPES.QSISTANT_FILE_DOCUMENT
      ? 'qs_icon_blue'
      : 'qsistant_blue';
  };

  const navigateToDocument = (documentType: ElementTypes, id: number): void => {
    switch (documentType) {
      case ELEMENT_TYPES.QSISTANT_DOCUMENT:
      case ELEMENT_TYPES.QSISTANT_FILE_DOCUMENT:
        navigate(routes().singleHomeDocument.replace(':id', id.toString()));
        break;
      case ELEMENT_TYPES.FILE_FILL_OUT:
      case ELEMENT_TYPES.QSISTANT_FILL_OUT:
        navigate(routes().singleHomeSupport.replace(':id', id.toString()));
        break;
      default:
        break;
    }
  };

  return (
    <Box
      component='div'
      onClick={() => navigateToDocument(elementType, documentId)}
      className={classes.cardWrapper}
      sx={{
        gridTemplateColumns: actionItem
          ? '2fr 3fr 2.5fr 2fr 0.5fr'
          : 'repeat(auto-fit, minmax(100px, 2fr))',
      }}
    >
      <Box component='div' className={classes.cell}>
        <Status status={status.status} statusText={status.statusText} />
      </Box>
      <Box component='div' className={classes.cell} sx={{ maxWidth: '20ch' }}>
        <Tooltip title={tooltip}>
          <IconButton sx={{ padding: '0.25rem' }}>
            <Icon name={setIcon(elementType)} size={1.5} />
          </IconButton>
        </Tooltip>
        <Typography component='p' className={classes.documentName}>
          {documentName}
        </Typography>
      </Box>
      <Box
        component='div'
        className={classes.cell}
        sx={{ justifyContent: 'center' }}
      >
        <Box component='div' className={classes.revisionBox}>
          <Typography component='p' className={classes.revisionText}>
            {`${documentNumber}`}
          </Typography>
          <Typography component='p' className={classes.revisionText}>
            {`${revision}`}
          </Typography>
        </Box>
      </Box>
      <Box component='div' className={classes.cell} sx={{ gap: '0.5rem' }}>
        <UserAvatar user={user} />
        <Box component='div'>
          <Typography component='p' className={classes.userName}>
            {userName}
          </Typography>
          <Typography component='p' className={classes.dateText}>
            {updatedDate}
          </Typography>
        </Box>
      </Box>
      {actionItem ? <Box component='div'>{actionItem}</Box> : null}
    </Box>
  );
}
