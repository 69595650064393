import {
  IconButton,
  IconButtonProps,
  MenuItem,
  TextField,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';
import { useTranslation } from 'react-i18next';

import type { SortSelectComponentProps } from './types';

const dropdownIcon = (
  props: IconButtonProps,
  primaryColor: IconButtonProps['color'],
  noOneChosen?: boolean
) => (
  <IconButton
    sx={{
      padding: 0,
      '&.MuiSelect-icon': {
        fill: ({ palette }) =>
          !noOneChosen ? palette[primaryColor].main : '#00000099',
      },
      '&.MuiSelect-iconOpen': {
        fill: ({ palette }) => palette[primaryColor].main,
      },
      '&.MuiSelect-icon.Mui-disabled': {
        opacity: 0.54,
      },
    }}
    {...props}
  >
    <Icon name={ICON.ARROW_DROP_DOWN} size={1.5} fill='inherit' />
  </IconButton>
);

export function SortSelectComponent({
  options,
  value,
  onChange,
  disabled = false,
  noOneChosen = false,
  primaryColor = 'qsistant',
}: SortSelectComponentProps) {
  const { t } = useTranslation('sort');

  return (
    <TextField
      select
      disabled={disabled}
      variant='standard'
      value={value}
      onChange={onChange}
      SelectProps={{
        renderValue: (prop) => {
          const itemList = options.find(
            (item) => item.value === prop && item.label
          );
          return `${t(`sort:sortby`)} ${t(`sort:${itemList.label}`)}`;
        },
        IconComponent: (props: IconButtonProps) =>
          dropdownIcon(props, primaryColor, noOneChosen),
        disableUnderline: true,
      }}
      sx={{
        '& .MuiInput-root': {
          fontSize: ({ typography }) => typography.pxToRem(14),
          color: ({ palette }) =>
            noOneChosen ? '#00000099' : palette[primaryColor].main,
          borderRadius: ({ typography }) => typography.pxToRem(4),
          userSelect: 'none',
        },
        '& .MuiInput-input': {
          padding: '0.4rem 0.5rem 0.4rem 1rem',
        },
        '&:hover .MuiInput-root': {
          background: '#0000000a',
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          value={option.value}
          sx={{
            fontSize: ({ typography }) => typography.pxToRem(14),
            color: ({ palette }) => palette.text.primary,
          }}
        >
          {t(`sort:${option.label}`)}
        </MenuItem>
      ))}
    </TextField>
  );
}
