import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { BasicSelect } from 'components/select/basic-select';

import React from 'react';

import { Icon } from '../icon';

const wrapper = {
  display: 'grid',
  alignItems: 'center',
  gap: '3rem',
  gridTemplateColumns: '1.6fr 1.2fr',
};

export function InviteSectionCompponent({
  id,
  email,
  updateRoles,
  userRoles,
  roleValue,
  role,
  updateEmails,
  removeInviteSection,
}) {
  return (
    <Box component='div' sx={wrapper}>
      <TextField
        type='email'
        label='E-Mail-Adresse Teammitglied'
        variant='filled'
        value={email}
        onChange={(e) => updateEmails(e.target.value, id)}
      />
      <Box component='div' display='flex' alignItems='center'>
        <BasicSelect
          fullWidth
          label='Rolle im Qualitätsmanagement'
          variant='filled'
          value={roleValue}
          options={userRoles}
          role={role}
          onChange={(e, v) =>
            updateRoles(e.target.value, v.props.children[1], id)
          }
        />
        <Tooltip title='Löschen'>
          <IconButton onClick={() => removeInviteSection(id)}>
            <Icon name='delete' size={1.5} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
