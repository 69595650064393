import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';
import { Status } from 'components/status';

import React, { useRef, useState } from 'react';

import { useStyles } from './styles';
import type { SingleComparisonCardProps } from './types';

export function SingleComparisonCard({
  title,
  badge = { label: 'Qsistant® Dokument', color: '#4599bf' },
  primaryColor = '#5CB85C',
  bgColor,
  indicator,
  data,
  status,
  isValid = false,
  handleClose = null,
  handleNewTab = null,
}: SingleComparisonCardProps) {
  const classes = useStyles();
  const iconRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const contextMenu = [
    {
      id: 4,
      label: 'In neuem Tab öffnen',
      onClick: () => handleNewTab(data),
      name: 'open_in_new',
      show: true,
    },
    {
      id: 9,
      label: 'Vergleich beenden',
      onClick: handleClose,
      name: 'cancel_presentation',
      show: true,
    },
  ];

  return (
    <Box
      component='div'
      className={classes.cardWrapper}
      sx={{
        border: `2px solid ${primaryColor}`,
        background: `${bgColor}`,
      }}
    >
      <Box component='div' display='flex' justifyContent='space-between'>
        <Box component='div' display='flex' gap='1rem' alignItems='center'>
          <Typography component='h6' className={classes.titleStyle}>
            {title}
          </Typography>
          <Status status={status.status} statusText={status.text} />
        </Box>
        <IconButton ref={iconRef} onClick={() => setShowMenu((prev) => !prev)}>
          <Icon name='more' size={1.5} />
        </IconButton>
      </Box>
      <Box component='div' display='flex' gap='0.5rem' alignItems='center'>
        <Box
          component='div'
          className={classes.badgeStyle}
          sx={{ background: badge.color }}
        >
          {badge.label}
        </Box>
        <Stack
          direction='row'
          divider={<Divider orientation='vertical' flexItem />}
          spacing={1}
        >
          <Typography component='p' className={classes.descriptionStyle}>
            {data?.documentNumber}
          </Typography>
          <Typography component='p' className={classes.descriptionStyle}>
            {data?.label}
          </Typography>
          <Typography component='p' className={classes.descriptionStyle}>
            {data?.date}
          </Typography>
        </Stack>
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent={!isValid ? 'space-between' : 'flex-end'}
      >
        {!isValid ? (
          <Box component='div' className={classes.notValidBox}>
            <Icon name='warning' fill='#ED6C02' size={1.25} />
            Achtung: Dieses Dokument ist nichtmehr gültig
          </Box>
        ) : null}
        <Box component='div' className={classes.indicatorBox}>
          <Box
            component='div'
            className={classes.circleBox}
            sx={{ background: indicator.color }}
          />
          {indicator?.label}
        </Box>
      </Box>
      {showMenu ? (
        <ContextMenu
          open={showMenu}
          onClose={() => setShowMenu(false)}
          anchorEl={iconRef}
          dataList={contextMenu}
          textSize='1rem'
        />
      ) : null}
    </Box>
  );
}
