import { Box, Button } from '@mui/material';
import { Icon } from 'components/icon';
import { CustomStepper } from 'components/stepper';
import { ICON } from 'enum/icons.enum';
import useMultistepForm from 'hooks/useMultistepForm';
import { createProcessesSteps } from 'pages/onboarding/constants';

import React from 'react';

import { DocumentTypes } from './document-types';
import { FinalPage } from './final-page';
import { ProcessCategoriesSetup } from './process-categories-setup';
import { ProcessSetup } from './process-setup';
import { useStyles } from './styles';
import type { DefineProcessProps } from './types';

export function DefineProcessComponent({
  onBack,
  onSave,
  isLoading,
}: DefineProcessProps) {
  const classes = useStyles();
  const handleNextStep = () => next();
  const handleBackStep = () => back();

  const { step, currentStepIndex, goTo, next, back } = useMultistepForm([
    <ProcessCategoriesSetup
      key='processCategorieSetup'
      onBack={onBack}
      onNext={handleNextStep}
    />,
    <ProcessSetup
      key='processSetup'
      onBack={handleBackStep}
      onNext={handleNextStep}
    />,
    <DocumentTypes
      key='documentTypes'
      onBack={handleBackStep}
      onNext={handleNextStep}
    />,
    <FinalPage key='finalPage' isLoading={isLoading} onSave={onSave} />,
  ]);

  return (
    <Box component='div' className={classes.defineProcessWrapper}>
      <Box component='div' className={classes.defineProcessContentBox}>
        <CustomStepper
          steps={createProcessesSteps}
          activeStep={currentStepIndex}
          onClick={goTo}
          primaryColor='#3F97BE'
        />
      </Box>
      {step}
      <Box component='div'>
        <Button
          variant='outlined'
          color='qsistant'
          onClick={() => goTo(0)}
          startIcon={
            <Icon name={ICON.OPEN_NEW_TAB} size={1.5} fill='inherit' />
          }
        >
          Zur anfangsauswahl
        </Button>
      </Box>
    </Box>
  );
}
