/* eslint-disable no-return-assign */
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';
import type { Identifier, XYCoord } from 'dnd-core';
import { ICON } from 'enum/icons.enum';
import { useNotification } from 'hooks/useNotification';
import { ModalConfirm } from 'modals/confirm';
import { ModalActionProcess } from 'modals/process-action';
import { ModalProcessCategoryAction } from 'modals/process-category-action';
import {
  editProcessCategorie,
  handleDndCategory,
  handleModal,
  removeProcessCategorie,
} from 'store/administration/process-category/processtype-slice';
import type { CategoryTypes } from 'store/administration/process-category/types';
import {
  addProcess,
  handleProcessToNewCategory,
} from 'store/administration/process/process-slice';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';
import { useAppDispatch } from 'store/hook';
import { TooltipInfo } from 'tooltips/info.tooltip';

import React, { useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { ProcessCardItem } from './process-card-item';
import { useStyles } from './styles';
import type { ProcessMapCardProps } from './types';

export function ProcessMapCard({
  category,
  processes,
  options,
  errors,
  cardIndex,
}: ProcessMapCardProps) {
  const { t } = useTranslation(['product', 'processes', 'common']);
  const dispatch = useAppDispatch();
  const infoRef = useRef<HTMLButtonElement>(null);
  const contextRef = useRef<HTMLButtonElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const bottomBoxRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const addNotification = useNotification();

  const [tooltip, setTooltip] = useState<boolean>(false);
  const [context, setContext] = useState<boolean>(false);

  const [edit, setEdit] = useState<boolean>(false);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [newProcess, setNewProcess] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(category.name);
  const [description, setDescription] = useState<string>(
    category.description || ''
  );
  const [processTitle, setProcessTitle] = useState<string>('');
  const [processCat, setProcessCat] = useState<string>(category.name || '');

  // moving process to other categorie states
  const [movedProcessId, setMovedProcessId] = useState<number | null>(null);
  const [newCategory, setNewCategory] = useState<CategoryTypes | null>(null);
  const [moveProcessModal, setMoveProcessModal] = useState(false);

  const menuContext = useMemo(
    () => [
      {
        id: 1,
        name: ICON.DELETE,
        label: t('product:remove'),
        subLabel: category.active
          ? t('processes:containassignedprocesses')
          : '',
        onClick: () => {
          setIsRemove(true);
          setContext(false);
        },
        show: true,
        disabled: category.active,
      },
      {
        id: 2,
        name: ICON.EDIT,
        label: t('product:edit'),
        onClick: () => {
          setEdit(true);
          setContext(false);
        },
        show: true,
      },
      {
        id: 3,
        name: ICON.PLUS,
        label: t('processes:newprocesscategory'),
        onClick: () => {
          dispatch(handleModal(true));
          setContext(false);
        },
        show: true,
      },
    ],
    [edit]
  );

  const createNewProcess = (lab: string, cat: string) => {
    const categoryObj = options.find((c) => c.name === cat && c.id);
    const categoryId = categoryObj && categoryObj.id ? categoryObj.id : null;
    const data = {
      category: categoryId,
      name: lab,
      id: 0,
    };
    dispatch(addProcess(data));
    addNotification({
      text: t('processes:alertprocessadded'),
    });
    setProcessTitle('');
    setProcessCat('');
  };

  const getProcessesByCategoryName = (
    processesArray: Array<ProcessDocumentsTypes>
  ) => {
    return processesArray.filter(
      (p) => p.category.name === category.name && !p.hidden
    );
  };

  const moveProcessToNewCategorie = () => {
    dispatch(
      handleProcessToNewCategory({
        processId: movedProcessId,
        category: newCategory,
      })
    );
    addNotification({
      text: t('processes:alertprocessmoved'),
    });
    setMovedProcessId(null);
    setNewCategory(null);
    setMoveProcessModal(false);
  };

  const [, drop] = useDrop<
    { id: number; index: number },
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'Process Category Card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop() {
      addNotification({
        text: t('processes:alertcategorydndsuccess'),
      });
    },
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = cardIndex;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      dispatch(handleDndCategory({ dragIndex, hoverIndex, id: item.id }));

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'Process Category Card',
    item: { id: category.id, index: cardIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropToCategory] = useDrop<
    { id: number; index: number; category: string },
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'Process Card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (category.name === item.category) {
        return;
      }

      if (!bottomBoxRef.current) {
        return;
      }
      const hoveredCategory = cardIndex;

      setMoveProcessModal(true);
      setMovedProcessId(item.id);
      setNewCategory(category);

      item.index = hoveredCategory;
    },
  });

  drag(drop(dragRef));
  dropToCategory(bottomBoxRef);
  return (
    <Box
      component='div'
      className={classes.wrapper}
      ref={dragRef}
      sx={{ opacity: isDragging ? 0.54 : 1 }}
    >
      <Box component='div' className={classes.topBox}>
        <Box component='div'>
          <IconButton
            ref={infoRef}
            onClick={() => setTooltip(true)}
            sx={{ p: '0.75rem' }}
          >
            <Icon name={ICON.INFO} fill='#fff' size={1.75} />
          </IconButton>
          <Tooltip title='Drag over this icon or use the entire element'>
            <IconButton sx={{ cursor: 'grab' }}>
              <Icon name={ICON.DRAG_HANDLE} fill='#fff' size={1.75} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <Typography component='h3' className={classes.cardHeader}>
            {category.name}
          </Typography>
          <Box component='div' display='flex' alignItems='center' gap='.5rem'>
            <Icon name={ICON.PROCESS} fill='#fff' />
            <Typography component='p' sx={{ color: '#fff', opacity: 0.8 }}>
              {
                processes.filter(
                  (process) =>
                    process.category.name === category.name && !process.hidden
                ).length
              }
              untergeordnete Prozesse
            </Typography>
          </Box>
        </Box>
        <IconButton
          ref={contextRef}
          onClick={() => setContext(true)}
          sx={{ p: '0.75rem' }}
        >
          <Icon name={ICON.MORE} fill='#fff' size={1.75} />
        </IconButton>
      </Box>
      <Box component='div' className={classes.bottomBox} ref={bottomBoxRef}>
        {getProcessesByCategoryName(processes).map((process, index) => (
          <ProcessCardItem
            key={process.id}
            cardIndex={index}
            id={process.id}
            name={process.name}
            category={process.category.name}
            activity={process.active}
            options={options}
          />
        ))}
        <Button
          variant='text'
          color='processMap'
          onClick={() => setNewProcess(true)}
          startIcon={<Icon name={ICON.PLUS} fill='#3F97BE' />}
        >
          {t('processes:addprocess')}
        </Button>
      </Box>
      {tooltip ? (
        <TooltipInfo
          open={tooltip}
          width='25rem'
          anchorEl={infoRef}
          onClose={() => setTooltip(false)}
          title={t('processes:tooltiptitle')}
          description={category.description || t('processes:tooltipdesc')}
        />
      ) : null}
      {context ? (
        <ContextMenu
          sx={{ minWidth: '11rem' }}
          anchorEl={contextRef}
          open={context}
          onClose={() => setContext(false)}
          dataList={menuContext}
        />
      ) : null}
      {edit ? (
        <ModalProcessCategoryAction
          isEditing
          open={edit}
          icon={{ name: ICON.CATEGORY }}
          errors={errors}
          onClose={() => setEdit(false)}
          name={title}
          description={description}
          onChangeTitle={(e) => setTitle(e.target.value)}
          onChangeDescription={(e) => setDescription(e.target.value)}
          color='processMap'
          header={t('processes:editprocesscategory')}
          onAction={(tit, d) => {
            const data = {
              name: tit,
              description: d,
              id: category.id,
            };
            dispatch(editProcessCategorie(data));
            addNotification({
              text: t('processes:alertprocessedited'),
            });
            setEdit(false);
          }}
        />
      ) : null}
      {newProcess ? (
        <ModalActionProcess
          icon={{ name: ICON.NEW_PROCESS }}
          color='processMap'
          open={newProcess}
          options={options}
          onClose={() => setNewProcess(false)}
          title={processTitle}
          header={t('processes:newprocess')}
          onChangeTitle={(e) => setProcessTitle(e.target.value)}
          category={processCat}
          onChangeCategory={(e) => setProcessCat(e.target.value)}
          onAction={(label, cat) => createNewProcess(label, cat)}
        />
      ) : null}
      {isRemove ? (
        <ModalConfirm
          open={isRemove}
          onClose={() => setIsRemove(false)}
          color='qsistant'
          onContinue={() => {
            dispatch(removeProcessCategorie(category.id));
            addNotification({
              text: t('processes:alertcategoryremoved'),
            });
          }}
          btnNextLabel={t('product:remove')}
          title={t('processes:deleteprocesscategory')}
          description={t('processes:deletecategorydesc')}
        />
      ) : null}
      {moveProcessModal ? (
        <ModalConfirm
          open={moveProcessModal}
          onClose={() => setMoveProcessModal(false)}
          onContinue={moveProcessToNewCategorie}
          btnNextLabel={t('common:postpone')}
          title={t('processes:modalprocesstocategorytitle')}
          description={t('processes:modalprocesstocategorydesc')}
        />
      ) : null}
    </Box>
  );
}
