import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes = 'comparisonsBox' | 'mainContentBox' | 'attentionModal';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size }) => {
    return {
      attentionModal: {
        width: `${typography.pxToRem(320)}`,
        padding: `${typography.pxToRem(size.font24L)}`,
      },
      comparisonsBox: {
        display: 'flex',
        gap: `${typography.pxToRem(12)}`,
        marginBottom: `${typography.pxToRem(12)}`,
        '@media(max-width: 1600px)': {
          flexDirection: 'column',
        },
      },
      mainContentBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: 'calc(100vh - 20rem)',
      },
    };
  }
);
