import { Box, Button, IconButton, Typography } from '@mui/material';
import { DragDrop } from 'components/drag-drop';
import { Icon } from 'components/icon';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper';

import React, { useState } from 'react';

export function ModalUploadRecord({
  onClose,
  open,
  onSubmitFiles,
  file,
  setFile,
}) {
  const [disable, setDisabled] = useState(true);
  // eslint-disable-next-line no-shadow
  const handleChange = (file) => {
    setFile(file);
  };

  console.log('file', file);
  console.log('Length', file?.length);
  console.log('Name', file?.[0].name);

  const uploadProductFiles = (files) => {
    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i += 1) {
        const formData = new FormData();
        formData.append('file', files[i]);
        //   api
        //     .post('/files/upload', formData, {
        //       headers: {
        //         'Content-Type': 'multipart/form-data',
        //       },
        //     })
        //     .then(() => {
        //       // dispatcher(handleUploadFiles(files[i]));
        //       // addFile(createFile(files[i].name));
        //     });
        // }
      }
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      sx={{ maxHeight: '66rem', width: '37.5rem' }}
    >
      <ModalHeader
        header='Aktenstruktur importieren'
        onClose={onClose}
        primaryColor='product'
        icon={{ name: 'file_upload_fill_rounded' }}
        sx={{ p: '1.25rem  1.5rem 0 2rem !important' }}
      />
      <Box component='div' sx={{ cursor: 'pointer', width: '100%', p: '2rem' }}>
        <Box
          sx={{
            width: '100%',
            cursor: 'pointer',
            border: '1px solid #E0E0E0',
            borderRadius: '0.25rem',
            height: file?.length ? '14.125rem' : '17.45rem',
          }}
          component='div'
        >
          <DragDrop
            uploadFiles={uploadProductFiles}
            handleChange={handleChange}
            fileTypes={['ZIP']}
            fileTypesText=', Ordnerstruktur oder einzelne Ordner'
            dragDropName='Aktenstruktur und Akten'
            color='#45BD81'
          />
        </Box>
        {file?.length && (
          <Box
            sx={{
              m: '1.5rem 0 0 0',
              height: '2.875rem',
              border: '1px solid #E0E0E0',
              borderRadius: '0.25rem',
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'default',
            }}
          >
            <Box
              sx={{
                borderBottom: '2px solid #45BD81',
                width: '100%',
                height: '1.25rem',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'space-between',
                m: '12px 4px 14px 12px',
                pb: '22px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: '500',
                  letterSpacing: '0.4px',
                  color: '#45BD81',
                  pt: '5px',
                }}
              >
                {file?.[0].name}
              </Typography>
              <Box
                sx={{
                  fontSize: '0.625rem',
                  fontWeight: '500',
                  letterSpacing: '0.4px',
                  color: '#CCCCCC',
                  pt: '9px',
                }}
              >
                100%, abgeschlossen
              </Box>
            </Box>
            <IconButton
              sx={{ cursor: 'pointer', mr: '1rem' }}
              aria-label='Delete'
              onClick={() => setFile(null)}
            >
              <Icon fill='#00000099' name='delete' />
            </IconButton>
          </Box>
        )}
        <Button
          variant='contained'
          color='product'
          sx={{
            padding: '1.2rem',
            fontSize: '1.187rem',
            mt: '2rem',
            gap: '1rem',
            letterSpacing: '0.1rem',
            cursor: 'pointer',
            '&.Mui-disabled': {
              background: '#45BD81',
            },
          }}
          disabled={!file?.length}
          fullWidth
          onClick={onSubmitFiles}
        >
          {/* {loading && ( */}
          {/* <CircularProgress sx={{ ...progressStyle }} color='inherit' /> */}
          {/* )} */}
          {/* {t('product:createdocument').toUpperCase()} */}
          AKTEN IMPORTIEREN
        </Button>
      </Box>
    </ModalWrapper>
  );
}

ModalUploadRecord.displayName = 'UploadRecord';
