import { Box, Button, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ContextMenuWrapper } from 'wrappers/context-menu-wrapper';

import React from 'react';

import { packagePrivelege } from './package-info.constants';
import { PackageInfoProps, PackagePrivelegeType } from './package-info.types';
import { useStyles } from './styles';

export function PackageInfoComponent({
  tariff,
  onClick,
  open,
  onClose,
  anchorEl,
}: PackageInfoProps) {
  const classes = useStyles();
  return (
    <ContextMenuWrapper
      offset
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      className={classes.context}
    >
      <Typography component='h4' className={classes.title}>
        MiQ GmbH & Co. KG nutzt aktuell die{' '}
        <span className={tariff}>{tariff}</span>-Version
      </Typography>
      <Typography component='h6' className={classes.subTitle}>
        Mit dieser Version können Standardfunktionen mit 5 Teammitgliedern
        genutzt werden.
      </Typography>
      <Box component='div' className={classes.privelegesBox}>
        {packagePrivelege.map(({ id, icon, label }: PackagePrivelegeType) => (
          <Box
            key={id}
            component='div'
            className={classes.privilegeBox}
            sx={{ fill: (theme) => theme.palette.qsistant.main }}
          >
            <Icon name={icon} fill='inherit' size={1.5} />
            <Typography component='p' className={classes.packageLabel}>
              {label}
            </Typography>
          </Box>
        ))}
        <Box component='div' display='flex' justifyContent='center' mt='1rem'>
          <Button
            variant='contained'
            onClick={onClick}
            color='qsistant'
            className={classes.btnStyle}
          >
            JETZT UPGRADEN
          </Button>
        </Box>
      </Box>
    </ContextMenuWrapper>
  );
}
