export const sortProcessOptions = [
  { id: 1, value: 'default', label: 'noselection' },
  { id: 2, value: 'alphabet ascending', label: 'alphabetascending' },
  { id: 3, value: 'alphabet descending', label: 'alphabetdescending' },
  {
    id: 4,
    value: 'last modified ascending',
    label: 'lastmodifiedascending',
  },
  {
    id: 42,
    value: 'last modified descending',
    label: 'lastmodifieddescending',
  },
];

export const customerTypeOptions = [
  { id: 1, value: 'customer', label: 'type_customer' },
  { id: 2, value: 'supplier', label: 'type_supplier' },
  { id: 3, value: 'both', label: 'type_both' },
];
