export const popperSx = {
  '& .MuiPickersDay-dayWithMargin': {
    color: '#000000DE',
  },
  '& .MuiPickersDay-root.MuiPickersDay-today': {
    border: '1px solid #4599bf',
  },
  '& .MuiPickersDay-root.Mui-selected': {
    backgroundColor: '#4599bf !important',
    color: '#fff',
  },
  '& .PrivatePickersYear-root.PrivatePickersYear-modeDesktop': {
    '.Mui-selected': {
      backgroundColor: '#4599bf',
    },
  },
  '& .MuiPickersCalendarHeader-labelContainer': {
    fontWeight: 400,
    color: '#00000099',
  },
};
