import { Box, Button, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { LordIcon } from 'components/lord-icon';
import { ICON } from 'enum/icons.enum';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { FinalBookingModalProps } from './types';

export function FinalBookingModal({
  open,
  onClose,
  onClick,
}: FinalBookingModalProps) {
  const classes = useStyles();
  return (
    <ModalWrapper open={open} className={classes.finalModal}>
      <Box component='div' display='flex' justifyContent='flex-end'>
        <IconButton onClick={onClose}>
          <Icon name={ICON.CLOSE} />
        </IconButton>
      </Box>
      <Box component='div' display='flex' justifyContent='center'>
        <LordIcon trigger='loop' style={{ width: '136px', height: '136px' }} />
      </Box>
      <Typography
        component='h5'
        className={classes.header}
        sx={{ textAlign: 'center', mb: '1.25rem' }}
      >
        Vielen Dank, deine Buchung war erfolgreich.
      </Typography>
      <Typography component='p' className={classes.finalPageText}>
        Ihr Upgrade wird <span>zeitnah aktiviert</span> und steht Ihnen dann
        auch zur Verfügung. Sie erhalten eine <span>E-Mail</span> mit allen
        wichtigen
        <span> Buchungs-und Rechnungsinformation</span>. Sollten Sie Fragen
        haben, kontaktieren Sie uns gerne.
      </Typography>
      <Box component='div' display='flex' justifyContent='flex-end' mt='1.5rem'>
        <Button variant='contained' color='qsistant' onClick={onClick}>
          Fertig
        </Button>
      </Box>
    </ModalWrapper>
  );
}
