export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '4px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: '0px 2px 3px #00000029',
  overflow: 'scroll',
};
