import { Box, LinearProgress, Typography } from '@mui/material';

import React from 'react';

import type { NewDocumentProgressProps } from './progress.types';
import { useStyles } from './styles';

export function NewDocumentProgressComponent({
  value,
  fileName,
  index,
  offset,
  currentFilePosition,
}: NewDocumentProgressProps) {
  const classes = useStyles();
  return (
    <Box width='100%'>
      <Box display='flex' justifyContent='space-between' mb='0.08rem'>
        <Typography
          className={classes.fileName}
          component='p'
          sx={{
            color:
              currentFilePosition === index + offset ? '#E0D289' : '#4599BF',
          }}
        >
          {fileName}
        </Typography>
        <Typography
          component='p'
          className={classes.progressText}
        >{`${Math.round(value || 0)}%, noch 2 Sekunden`}</Typography>
      </Box>
      <Box component='div' sx={{ width: '100%' }}>
        <LinearProgress
          variant='determinate'
          color='inherit'
          value={value}
          sx={{
            background:
              currentFilePosition === index + offset
                ? 'rgba(148, 132, 37, 0.24)'
                : 'rgba(69, 153, 191, 0.24)',
            color:
              currentFilePosition === index + offset ? '#948425' : '#4599BF',
          }}
        />
      </Box>
    </Box>
  );
}
