import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  IconButtonProps,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { style } from './styles';
import { FilterDropdownProps } from './types';

const dropdownIcon = (
  props: IconButtonProps,
  primaryColor: IconButtonProps['color'],
  isFilterApplied?: boolean
) => (
  <IconButton
    sx={{
      padding: 0,
      '&.MuiSelect-icon': {
        fill: ({ palette }) =>
          isFilterApplied ? palette[primaryColor].main : '#00000099',
      },
      '&.MuiSelect-iconOpen': {
        fill: ({ palette }) => palette[primaryColor].main,
      },
      '&.MuiSelect-icon.Mui-disabled': {
        opacity: 0.54,
      },
    }}
    {...props}
  >
    <Icon
      name={ICON.ARROW_DROP_DOWN}
      fill='inherit'
      size={1.5}
      style={{ opacity: 'inherit' }}
    />
  </IconButton>
);

export function FilterDropdownComponent({
  value,
  onChange,
  options,
  disabled,
  multiple = false,
  filterLabel,
  isFilterApplied,
  primaryColor = 'qsistant',
}: FilterDropdownProps) {
  return (
    <Select
      variant='outlined'
      disabled={disabled}
      value={value}
      onChange={onChange}
      multiple={multiple}
      renderValue={(selected) => {
        const item = options.find((i) => i.value === value);
        if (typeof selected === 'string' && !multiple) {
          return item.label;
        }
        if (multiple && filterLabel && selected.length === 1) {
          return filterLabel;
        }
        return `${filterLabel} + ${selected.length - 1}`;
      }}
      IconComponent={(props) =>
        dropdownIcon(props, primaryColor, isFilterApplied)
      }
      sx={{
        ...style,
        borderColor: ({ palette }) =>
          isFilterApplied ? palette[primaryColor].main : 'lightGray',
        background: ({ palette }) =>
          isFilterApplied ? `${palette[primaryColor].main}14` : '#fff',
        color: ({ palette }) =>
          isFilterApplied ? palette[primaryColor].main : '#000000de',
        '&:hover': {
          color: ({ palette }) => palette[primaryColor].main,
          background: ({ palette }) => `${palette[primaryColor].main}14`,
          border: '1px solid lightGray',
        },
        '&.Mui-focused': {
          borderColor: ({ palette }) => palette[primaryColor].main,
        },
      }}
    >
      {options.map((item) => (
        <MenuItem
          key={item.value}
          value={item.value}
          disabled={item?.disabled}
          sx={{
            '&.MuiMenuItem-root': {
              display: 'flex',
              gap: '0.5rem',
              pl: '0.875rem',
              textTransform: 'capitalize',
              flexDirection: item.avatar ? 'row-reverse' : 'row',
              justifyContent: item.avatar ? 'space-between' : 'start',
            },
          }}
        >
          {multiple ? (
            <Checkbox
              checked={value.indexOf(item.value) > -1}
              sx={{
                p: 0,
                '&.MuiCheckbox-root.Mui-checked': {
                  color: ({ palette }) => palette[primaryColor].main,
                },
              }}
            />
          ) : null}
          <Box component='div' display='flex' alignItems='center' gap='0.5rem'>
            {item?.avatar ? (
              <Avatar sx={{ width: '2.5rem', height: '2.5rem' }} />
            ) : null}
            <Typography
              component='span'
              sx={{
                fontSize: '0.875rem',
                color: '#000000DE',
                maxWidth: item.avatar ? '16ch' : 'max-content',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {item.label}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}
