import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, ListItemIcon, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Icon } from 'components/icon';
import { deleteProduct } from 'store/product/products-slice';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { ICON } from '../../enum/icons.enum';

const ITEM_HEIGHT = 35;

const defautOptions = [
  { id: 'rename', label: 'Umbenennen', icon: ICON.RENAME },
  { id: 'delete', label: 'Entfernen', icon: ICON.DELETE },
  { id: 'download', label: 'Herunterladen', icon: ICON.DOWNLOAD },
];

export default function LongMenu({
  options = defautOptions,
  id,
  onRenameClick,
  onDeleteClick,
}) {
  const [anchorEl, setAnchorEl] = React.useState();
  const [selectedOption, setSelectedOption] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option, event) => {
    event.stopPropagation();
    setSelectedOption(option);
    handleClose();

    if (option === 'delete') {
      onDeleteClick(id);
    } else if (option === 'rename') {
      onRenameClick(id);
      // dispatch(
      //   updateFolderName({
      //     itemId: id,
      //     newName: 'ggg',
      //   })
      // );
    }
  };

  return (
    <Box>
      <Tooltip title='mehr'>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5,
            width: '18.4375rem',
            color: '#000000',
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.id}
            selected={selectedOption === option.id}
            onClick={(event) => handleOptionClick(option.id, event)}
          >
            <ListItemIcon>
              <Icon name={option.icon} fill='#00000099' />
            </ListItemIcon>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
