import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { InputPassword } from 'components/custom-input';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import type { ModalChangePasswordProps } from './types';

export function ModalChangePassword({
  open,
  onClose,
  onSave,
}: ModalChangePasswordProps) {
  const { t } = useTranslation(['user']);
  const classes = useStyles();

  const [password, setPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [length, setLength] = useState<boolean>(false);
  const [number, setNumber] = useState<boolean>(false);
  const [special, setSpecial] = useState<boolean>(false);
  const [upper, setUpper] = useState<boolean>(false);

  useEffect(() => {
    const lengthValid = /(?=.{8,})/;
    const numberValid = /(?=.*[0-9])/;
    const specialValid = /(?=.*[!@#$%^&*.])/;
    const upperValid = /(?=.*[a-z])(?=.*[A-Z])/;

    lengthValid.test(password) ? setLength(true) : setLength(false);
    numberValid.test(password) ? setNumber(true) : setNumber(false);
    specialValid.test(password) ? setSpecial(true) : setSpecial(false);
    upperValid.test(password) ? setUpper(true) : setUpper(false);
  }, [password]);

  const passwordStrength = [
    { id: 11, label: t('user:passwordminlength'), value: length },
    {
      id: 22,
      label: t('user:passwordminoneupperandonelowercase'),
      value: upper,
    },
    { id: 33, label: t('user:passwordminonenumber'), value: number },
    { id: 44, label: t('user:passwordminonespecialchar'), value: special },
  ];

  return (
    <ModalWrapper open={open} className={classes.modal}>
      <ModalHeader
        header={t('user:changepassword')}
        onClose={onClose}
        primaryColor='outlined'
        icon={{ name: 'vpn_key_filled' }}
      />
      <Divider />
      <Box
        component='form'
        onSubmit={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <Box component='div' className={classes.topInputsBox}>
          <InputPassword
            label={t('user:currentpassword')}
            fullWidth
            color='outlined'
            name='old-password'
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <InputPassword
            label={t('user:newpassword')}
            fullWidth
            color='outlined'
            name='new-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box component='div'>
            <Typography component='h6' className={classes.caption}>
              {t('user:passwordstrength')}
            </Typography>
            <List className={classes.list}>
              {passwordStrength.map(({ id, label, value }) => (
                <Box
                  key={id}
                  component='div'
                  display='flex'
                  alignItems='center'
                >
                  <Box
                    component='div'
                    className={classes.circle}
                    sx={{ background: value ? 'green' : 'red' }}
                  />
                  <ListItem
                    sx={{ textDecoration: value ? 'line-through' : 'none' }}
                    className={classes.listItem}
                  >
                    {label}
                  </ListItem>
                </Box>
              ))}
            </List>
          </Box>
          <InputPassword
            label={t('user:confirmnewpassword')}
            fullWidth
            color='outlined'
            name='confirm-password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Box>
        <Box component='div' className={classes.btnBox}>
          <Button variant='text' onClick={onClose} color='outlined'>
            {t('common:abort')}
          </Button>
          <Button
            variant='contained'
            type='button'
            color='outlined'
            onClick={() => onSave(oldPassword, password, confirmPassword)}
            disabled={
              password !== confirmPassword ||
              !confirmPassword ||
              !number ||
              !upper ||
              !special ||
              !length
            }
          >
            {t('common:save')}
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
}
