import { Box, Button, Fab, Typography } from '@mui/material';
import { ModalCreateNewProduct } from 'modals/product-development/create-new-product';
import { ModalRenameProduct } from 'modals/product-development/rename-product';
import { RootState } from 'store/index';
import { updateProducts } from 'store/product/products-slice';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { useTranslation } from 'react-i18next';
import { ProductCard } from '../../../cards/pdoduct-card';
import { Icon } from '../../../components/icon';
// import { queryClient } from '../../../index';
import { useStyles } from './styles';
import { productPageProps } from './types';

// fetchMock.config.overwriteRoutes = true;
// const productsMock = [];
// fetchMock.get('api/products', productsMock);
// fetchMock.post('api/products', (_, res) => {
//   productsMock.push(res.body);
//   return 200;
// });
// fetchMock.get('express:/api/products/:name', (url) => {
//   const name = url.split('/').pop();
//   console.log('NAME', name);
//   return productsMock.find((item) => item.name === name);
// });
// fetchMock.deleteOnce('api/products/:id', (url, options) => {
//   const productId = url.split('/').pop();
//
//   const index = productsMock.findIndex((item) => item.id === productId);
//   if (index !== -1) {
//     productsMock.splice(index, 1);
//     return 200;
//   }
//
//   return 404;
// });

export function ProductPage(props: productPageProps) {
  const { pathName, parentTab, homePage } = props;
  // const { t } = useTranslation([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  // const client = useQueryClient();
  // const {
  //   data: products = [],
  //   isLoading,
  //   isFetching,
  // } = useQuery(['products'], async () => {
  //   return fetch('api/products').then((res) => res.json());
  // });

  const { products } = useSelector((state: RootState) => state.productsReducer); // TODO: no more need if we have endpoint

  //     client.invalidateQueries('products');
  //   } catch (error) {
  //     console.error('error when del product:', error);
  //   }
  // };

  // const deleteProductMutation = useMutation((id) =>
  //   fetch(`api/products/${id}`, { method: 'DELETE' })
  // );

  const [modal, setModal] = useState(false);
  const [modalRename, setModalRename] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  // if (isLoading) {
  //   return <CircularProgress color='product' />;
  // }
  //
  // if (isError) {
  //   return <h2>{error?.message}</h2>;
  // }

  const handleRenameProduct = (productId, newName) => {
    // Вызовите действие для обновления имени продукта
    dispatch(updateProducts({ id: productId, productName: newName }));
    setModalRename(true);
  };

  return (
    <PageWrapper
      isPageHeader
      pathName={pathName || ''}
      parentTab={parentTab || ''}
      homePage={homePage}
    >
      <Box>
        {products.length === 0 ? (
          <Box className={classes.wrapper}>
            <Icon name='empty_products' height={13.19} width={15.125} />
            <Typography
              component='h3'
              variant='h3'
              className='PageHeadline'
              sx={{ mt: '2.78rem' }}
            >
              {/* {t('')} */}
              Oh nein! Du hast noch keine Produkte
            </Typography>
            <Typography
              component='h6'
              variant='subtitle1'
              className='PageHeadline'
              sx={{ color: '#00000066', mt: '0.875rem', mb: '2.5rem' }}
            >
              {/* {t('')} */}
              Erstelle ein neues Produkt, um dessen Entwicklung dokumentieren zu
              können.
            </Typography>
            <Button
              color='product'
              onClick={() => setModal(true)}
              className={classes.btn}
              variant='contained'
              startIcon={<Icon name='add' fill='#fff' />}
            >
              {/* {t('')} */}
              PRODUKT ERSTELLEN
            </Button>
          </Box>
        ) : (
          <Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1.25rem' }}>
              {products?.map((product) => (
                // <Link
                //   key={product?.id}
                //   to={`product_development/product/${product.name}`}
                // >
                <ProductCard
                  id={product?.id}
                  key={product?.id}
                  name={product?.productName}
                  description={product?.productDesc}
                  image={product?.image}
                  handleRename={(productId, newName) => {
                    setSelectedProductId(productId);
                    handleRenameProduct(productId, newName);
                  }}
                />
                // </Link>
              ))}
            </Box>
            <Box component='div' display='flex' justifyContent='center'>
              <Fab
                variant='extended'
                size='large'
                className='AbsolutePosition product'
                onClick={() => setModal(true)}
                sx={{ letterSpacing: '0.078rem', color: '#fff' }}
              >
                <Icon name='add' size={1.5} fill='white' />
                {/* {t('')} */}
                PRODUKT HINZUFÜGEN
              </Fab>
            </Box>
          </Box>
        )}
        {modal && (
          <ModalCreateNewProduct
            closeModal={() => {
              setModal(false);
            }}
            modal={modal}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {modalRename && (
          <ModalRenameProduct
            onClose={() => {
              setModalRename(false);
            }}
            open={modalRename}
            handleRenameProduct={handleRenameProduct}
            id={selectedProductId}
          />
        )}
      </Box>
    </PageWrapper>
  );
}

ProductPage.displayName = 'product.page';
