import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

type Classes =
  | 'documentBadgeBox'
  | 'navbarActionsBox'
  | 'documentBadge'
  | 'secondaryUserBox'
  | 'primaryUserBox'
  | 'muiTabs'
  | 'tab'
  | 'avatar';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, colors }) => {
    return {
      documentBadgeBox: {
        background: '#D9EAF2',
        borderRadius: '0.25rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        padding: '0.181rem 0.375rem',
        marginLeft: '0.5rem',
        marginBottom: '0.25rem',
      },
      documentBadge: {
        color: '#3F97BE',
        fontSize: `${typography.pxToRem(size.fontS)} !important`,
      },
      navbarActionsBox: {
        width: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '2rem',
        '@media(max-width: 1200px)': {
          width: '100%',
        },
        '@media(max-width: 2560px)': {
          width: '100%',
        },
        '@media(max-width: 1920px)': {
          width: '70%',
        },
        '@media(max-width: 968px)': {
          width: '90%',
        },
      },
      secondaryUserBox: {
        borderRadius: '1.44rem !important',
        color: '#000000de !important',
        letterSpacing: '0.015rem !important',
        textTransform: 'capitalize !important',
        height: '3rem',
        borderColor: '#e0e0e0 !important',
        outline: '0.095rem solid #e0e0e0',
        '&:hover': {
          backgroundColor: colors.gray.background,
          borderColor: colors.gray.background,
        },
      } as CSSProperties & { textTransform: 'capitalize !important' },
      primaryUserBox: {
        borderRadius: '1.44rem !important',
        color: '#000000de !important',
        letterSpacing: '0.015rem !important',
        textTransform: 'capitalize !important',
        height: '3rem',
        borderColor: '#e0e0e0 !important',
        '&:hover': {
          backgroundColor: colors.gray.background,
          borderColor: colors.gray.background,
        },
        '&:focus': {
          outline: '0.14rem solid #000000de',
        },
        '@media(max-width: 1024px)': {
          borderRadius: '50% !important',
          minWidth: '48px !important',
          width: '48px !important',
          height: '48px !important',
        },
      } as CSSProperties & { textTransform: 'capitalize !important' },
      muiTabs: {
        '& .MuiTabs-flexContainer': {
          gap: '2.5rem',
        },
      },
      tab: {
        '&.Mui-selected': {
          color: `${colors.black} !important`,
          opacity: '1 !important',
        },
        opacity: '0.87 !important',
        letterSpacing: '0.04rem !important',
        padding: `${typography.pxToRem(40)} 0 !important`,
      },
      avatar: {
        width: `${typography.pxToRem(34)} !important`,
        height: `${typography.pxToRem(34)} !important`,
        marginLeft: `${typography.pxToRem(-size.font_4)}`,
        '@media(max-width: 1024px)': {
          marginLeft: `${typography.pxToRem(-size.font_4 / 2)}`,
          marginRight: `${typography.pxToRem(-size.font2XL)}`,
        },
      },
    };
  }
);
