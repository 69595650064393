import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/styles';
import { DefaultModal } from 'modals/default/default.modal';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../api/api';

export const uploadInputsBox = {
  width: '100%',
  gap: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function UserModal({
  closeModal,
  modal,
  handleSuccess,
  user = null,
  errors = null,
  icon,
}) {
  const [prename, setPrename] = useState(user?.prename ?? '');
  const [surname, setSurname] = useState(user?.surname ?? '');
  const [departments, setDepartments] = useState(user?.departments ?? []);
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState(null);
  const [email, setEmail] = useState(user?.email ?? '');
  const [id] = useState(user ? user.id : 0);

  const { t } = useTranslation('user');

  const handleSubmit = () => {
    handleSuccess({
      prename,
      surname,
      email,
      accessRoles: formData.roles
        .filter((d) => roles.includes(d.name))
        ?.map((d) => d.id),
      departments: formData.departments
        .filter((d) => departments.includes(d.name))
        ?.map((d) => d.id),
      id,
    });
  };

  const hasViolation = (fieldType) => {
    if (!errors) return false; // no errors no violation
    const violation = errors?.violations?.filter(
      (v) => v.propertyPath === fieldType
    );
    return violation && violation.length > 0;
  };

  const getViolation = (fieldType) => {
    if (!hasViolation(fieldType)) return ''; // no errors no violation

    const violation = errors?.violations.filter(
      (v) => v.propertyPath === fieldType
    );

    return violation && violation.length > 0 ? violation[0].title : '';
  };

  const theme = useTheme();

  useEffect(() => {
    // when display is set
    if (modal) {
      api.get('/user/formdata').then((result) => {
        setFormData(result.data);
      });
      if (user) {
        const uRoles = [];
        user.access_Roles?.forEach((r) => uRoles.push(r.name));
        setRoles(uRoles);
        const deps = [];
        user.departments?.forEach((d) => deps.push(d.name));
        setDepartments(deps);
      }
    }
  }, [modal]);

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setDepartments(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <DefaultModal
      closeModal={closeModal}
      successLabel={id === 0 ? t('modal:success') : t('user:save')}
      handleSuccess={handleSubmit}
      modal={modal}
      disabled={email === '' || email.length < 3}
      modalTitle={id === 0 ? t('user:newuser') : t('user:edituser')}
      icon={icon}
      width='35rem'
      successColor={icon.color}
      color={icon.color}
    >
      <Box sx={{ ...uploadInputsBox }} component='div'>
        <Box component='div' display='flex' gap='1rem'>
          <TextField
            color='management'
            error={hasViolation('prename')}
            helperText={`${getViolation('prename')}`}
            variant='outlined'
            label={t('user:prename')}
            type='text'
            value={prename || ''}
            onChange={(e) => setPrename(e.target.value)}
            fullWidth
          />
          <TextField
            color='management'
            error={hasViolation('surname')}
            helperText={`${getViolation('surname')}`}
            variant='outlined'
            label={t('user:surname')}
            type='text'
            value={surname || ''}
            onChange={(e) => setSurname(e.target.value)}
            fullWidth
          />
        </Box>
        <TextField
          color='management'
          disabled={user !== null}
          error={hasViolation('email')}
          helperText={`${getViolation('email')}`}
          variant='outlined'
          label={t('user:emailinput')}
          type='text'
          autoFocus
          value={email || ''}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        {formData ? (
          <>
            <FormControl>
              <InputLabel id='demo-multiple-name-label'>
                {t('user:roles')}
              </InputLabel>
              <Select
                labelId='demo-multiple-name-label'
                id='demo-multiple-name'
                multiple
                color='management'
                value={roles}
                onChange={handleRoleChange}
                input={<OutlinedInput label={t('user:roles')} />}
                MenuProps={MenuProps}
              >
                {formData.roles.map((role) => (
                  <MenuItem
                    key={role.id}
                    value={role.name}
                    style={getStyles(role.name, roles, theme)}
                  >
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='demo-multiple-name-label'>
                {t('user:departments')}
              </InputLabel>
              <Select
                labelId='demo-multiple-name-label'
                id='demo-multiple-name'
                multiple
                color='management'
                value={departments}
                onChange={handleDepartmentChange}
                input={<OutlinedInput label={t('user:departments')} />}
                MenuProps={MenuProps}
              >
                {formData.departments.map((d) => (
                  <MenuItem
                    key={d.id}
                    value={d.name}
                    style={getStyles(d.name, departments, theme)}
                  >
                    {d.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </DefaultModal>
  );
}
