export const statusDocumentFilter = [
  {
    value: 'approved',
    id: 11,
    label: 'Dokumentenart',
  },
  { value: 'In Examination', id: 22, label: 'In Prüfung' },
  {
    value: 'draft',
    id: 33,
    label: 'Entwurf',
  },
  {
    value: 'in processing',
    id: 44,
    label: 'In Bearbeitung',
  },
  { value: 'archive', id: 55, label: 'Archiviert' },
];

export const personDocumentFilter = [
  { value: 'Master Meister', id: 56, label: 'M. Meister' },
  {
    value: 'Frederik Nitzsche',
    id: 78,
    label: 'F. Nitzsche',
  },
  { value: 'Flo Florian', id: 93, label: 'F. Florian' },
];

export const filters = [
  { value: 'document', id: 123, label: 'Dokumente' },
  { value: 'proof', id: 456, label: 'Nachweise' },
  { value: 'date', id: 981, label: 'Dateien' },
];
