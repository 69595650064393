import api from 'api/api';
import { API_URL } from 'api/api-urls';
import type { FeedbackTypes } from 'store/common/feedback/feedback.types';

export const postFeedback = (data: FeedbackTypes) => {
  return api.post(`${API_URL.feedback.send}`, data);
};

export const getStandards = () => {
  return api.get(`${API_URL.standards.getAll}`);
};
