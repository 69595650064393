import { Box, IconButton, Tooltip } from '@mui/material';
import { Icon } from 'components/icon';
import { ModalConfirm } from 'modals/confirm';
import { CustomerModal } from 'modals/customer-action';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function IconBox({ item, errors, options, onEdit, onRemove }) {
  const { t } = useTranslation(['common', 'customer']);
  const [editOpen, setEditOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [name, setName] = useState(item.name);
  const [type, setType] = useState(item.type);

  const updateCustomer = (cName, cType) => {
    const data = {
      name: cName,
      type: cType,
      id: item.id,
    };
    onEdit(data);
    setEditOpen(false);
  };

  return (
    <Box
      component='div'
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
    >
      {editOpen ? (
        <CustomerModal
          isEditing
          icon={{ name: 'new_label_filled' }}
          open={editOpen}
          errors={errors}
          onClose={() => setEditOpen(false)}
          header={t('customer:newcustomer')}
          color='management'
          primaryColor='#B4A656'
          name={name}
          type={type}
          onChangeName={(e) => setName(e.target.value)}
          onChangeType={(e) => setType(e.target.value)}
          options={options}
          onAction={(label, cat) => updateCustomer(label, cat)}
        />
      ) : null}
      <Tooltip title={t('common:edit')} placement='bottom'>
        <IconButton onClick={() => setEditOpen(true)}>
          <Icon name='edit' size={1.5} />
        </IconButton>
      </Tooltip>
      {!item.active ? (
        <Tooltip title={t('common:delete')} placement='bottom'>
          <IconButton onClick={() => setRemoveOpen(true)}>
            <Icon name='delete' size={1.5} />
          </IconButton>
        </Tooltip>
      ) : null}
      {removeOpen ? (
        <ModalConfirm
          open={removeOpen}
          onClose={() => setRemoveOpen(false)}
          onContinue={() => onRemove({ id: item.id })}
          primaryColor='#B4A656'
          btnNextLabel={t('common:delete')}
          title={t('customer:removecustomer')}
          description={t('customer:removecustomerdescription')}
        />
      ) : null}
    </Box>
  );
}
