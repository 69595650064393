import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'widgetsBox'
  | 'changesBox'
  | 'dayStyle'
  | 'divider'
  | 'tabs'
  | 'secondaryTabs';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, size, colors, palette }) => {
    return {
      widgetsBox: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        gap: `${typography.pxToRem(size.font2XL)}`,
        paddingTop: `${typography.pxToRem(size.font2XL)}`,
        '@media(max-width:1200px)': {
          flexDirection: 'column',
        },
      },
      changesBox: {
        display: 'flex',
        gap: `${typography.pxToRem(size.font2XL)}`,
        marginTop: `${typography.pxToRem(size.fontL)}`,
        flexWrap: 'wrap',
      },
      dayStyle: {
        color: colors.text.primary,
        fontSize: `${typography.pxToRem(size.fontL)} !important`,
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
      divider: {
        width: '94%',
        margin: '0 0 0 auto !important',
      },
      tabs: {
        '& .MuiTabs-scrollButtons': {
          color: palette.qsistant.main,
        },
        '& .MuiTabs-flexContainer': {
          justifyContent: 'space-between',
        },
      },
      secondaryTabs: {
        '& .MuiTabs-flexContainer': {
          justifyContent: 'space-evenly',
        },
      },
    };
  }
);
