import { Avatar, Box, Button } from '@mui/material';

import React from 'react';

import { colors } from './avatar-color-picker.constants';
import {
  AvatarColorPickerProps,
  ColorOption,
} from './avatar-color-picker.types';
import { avatarStyle, colorButtonStyle, colorsBox, wrapper } from './styles';

export function AvatarColorPickerComponent({
  activeOption = 'colored',
  setActiveOption,
  avatarColor,
  onClick,
  userName = 'Q',
  avatarSize = '5rem',
}: AvatarColorPickerProps) {
  return (
    <Box
      component='div'
      onClick={setActiveOption || undefined}
      sx={{
        ...wrapper,
        opacity: activeOption === 'colored' ? 1 : 0.2,
        '&:hover': {
          opacity: 1,
        },
      }}
    >
      <Avatar
        sx={{
          background: avatarColor || '#2A1A1F',
          width: avatarSize,
          height: avatarSize,
          ...avatarStyle,
        }}
      >
        {userName.charAt(0)}
      </Avatar>
      <Box component='div' sx={colorsBox}>
        {colors.map((color: ColorOption) => (
          <Button
            disableRipple
            key={color.id}
            value={color.value}
            type='button'
            aria-label='Color Button'
            sx={{
              background: color.color,
              '&:hover': {
                background: color.color,
                transform: 'scale(1.3, 1.3)',
              },
              ...colorButtonStyle,
            }}
            onClick={() => onClick(color.value)}
          />
        ))}
      </Box>
    </Box>
  );
}
