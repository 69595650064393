import { Box, IconButton, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import clsx from 'clsx';

import { useStyles } from './styles';
import { ModalHeaderProps } from './types';

export function ModalHeaderComponent({
  sx,
  header,
  onClose,
  primaryColor = 'qsistant',
  icon,
  className,
  gap = '0.5rem',
}: ModalHeaderProps) {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.wrapper, className)}
      sx={{ ...sx }}
      component='div'
    >
      <Box component='div' display='flex' alignItems='center' gap={gap}>
        <IconButton
          disableRipple
          sx={{
            cursor: 'auto',
            background: ({ palette }) => palette[primaryColor].main,
          }}
        >
          <Icon
            name={icon.name}
            size={icon.size || 1.5}
            fill={icon.fill || '#fff'}
          />
        </IconButton>
        <Typography component='h4' className={classes.header}>
          {header}
        </Typography>
      </Box>
      <Box component='div'>
        <IconButton onClick={onClose}>
          <Icon name={ICON.CLOSE} size={1.5} />
        </IconButton>
      </Box>
    </Box>
  );
}
