import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type {
  BasicUserSliceTypes,
  ProcessCategoryTypes,
} from './basic-user.types';

const initialState: BasicUserSliceTypes = {
  userData: {
    avatar: null,
    avatarColor: '',
    name: '',
    surName: '',
    email: '',
    previousPassword: '',
    newPassword: '',
    firmLogo: '',
    confirmPassword: '',
    companyName: '',
    industry: '',
    workersNumber: 'Just me',
    systemType: '',
    qmSystem: '',
    emailInvites: [
      { id: '12', email: '', role: 'Auswählen', roleValue: 'Auswählen' },
      { id: '32', email: '', role: 'Auswählen', roleValue: 'Auswählen' },
      { id: '4556', email: '', role: 'Auswählen', roleValue: 'Auswählen' },
    ],
  },
  processCategories: [
    {
      id: '111',
      name: 'Kernprozesse',
      description:
        'Der allgemeine Wertschöpfungsprozess (Herstellung von Produkten oder erbringen einer Dienstleistung) eines Unternehmens wird klassischerweise in den Kernprozessen beschrieben.',
      checked: false,
      processList: [],
      isCreated: false,
    },
    {
      id: '222',
      name: 'Managementprozesse',
      description:
        'Managementprozesse beinhalten Tätigkeitsbereiche, die sich mit Führung, Steuerung von Personal, organisatorischen Abläufen oder dem Bewerten von internen Abläufen beschäftigen.',
      checked: false,
      processList: [],
      isCreated: false,
    },
    {
      id: '333',
      name: 'Unterstützungsprozesse',
      description:
        'Unterstützungsprozesse beschreiben häufig Prozesse, die ergänzend zu Kernprozessen durchgeführt werden, jedoch keinen direkten Nutzen für den Kunden erzeugen.',
      checked: false,
      processList: [],
      isCreated: false,
    },
  ],
  selectedCategories: [],
  documentTypes: [
    {
      id: '4123',
      title: 'Prozessbeschreibung ➡️',
      description:
        'Prozessbeschreibungen sind als übergeordnete Leitlinien zum Umgang mit Prozessen zu betrachten. Sie geben den allgemeinen Ablauf eines übergeordneten Prozesses wider.',
      checked: false,
      allowOutput: false,
      isCreated: false,
    },
    {
      id: '5890',
      title: 'Arbeitsanweisung 📋',
      description:
        'Arbeitsanweisungen sind spezifische Anweisungen wie Verfahren oder Arbeitsschritte innerhalb von Prozessen durchgeführt werden. Oft werden hierfür Ablaufdiagramme verwendet.',
      checked: false,
      allowOutput: false,
      isCreated: false,
    },
    {
      id: '7190',
      title: 'Formular ✍🏼',
      description:
        'Formulare umfassen unter anderem auch Protokolle und Checklisten, die nach dem Ausfüllen in den Nachweisen abgelegt werden.',
      checked: false,
      allowOutput: false,
      isCreated: false,
    },
    {
      id: '7177',
      title: 'Liste 🟰',
      description:
        'Listen werden für Dokumente in Tabellen und Listenform herangezogen, dies können auch Aufzählungen wie Maschinen- und Materiallisten beinhalten.',
      checked: false,
      allowOutput: false,
      isCreated: false,
    },
  ],
  selectedDocumentTypes: [],
};

const basicUserSlice = createSlice({
  name: 'basicUserSlice',
  initialState,
  reducers: {
    handleUpdateFields(state, action) {
      state.userData = { ...state.userData, ...action.payload };
    },

    handleUpdateAvatar(state, action) {
      state.userData = { ...state.userData, ...action.payload };
    },

    handleAddEmail(state) {
      state.userData = {
        ...state.userData,
        emailInvites: [
          ...state.userData.emailInvites,
          {
            id: crypto.randomUUID(),
            email: '',
            role: 'Auswählen',
            roleValue: 'Auswählen',
          },
        ],
      };
    },

    handleUpdateEmails(
      state,
      action: PayloadAction<{ iD: string; fields: string }>
    ) {
      const { iD, fields } = action.payload;
      const newEmails = state.userData.emailInvites.map((item) => {
        if (item.id === iD) {
          return {
            ...item,
            email: fields,
          };
        }
        return item;
      });
      state.userData = { ...state.userData, emailInvites: newEmails };
    },

    handleUpdateRoles(
      state,
      action: PayloadAction<{ iD: string; field: string; label: string }>
    ) {
      const { iD, label, field } = action.payload;
      const newRoles = state.userData.emailInvites.map((item) => {
        if (item.id === iD) {
          return {
            ...item,
            role: label,
            roleValue: field,
          };
        }
        return item;
      });
      state.userData = { ...state.userData, emailInvites: newRoles };
    },

    handleRemoveInviteSection(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const filtered = state.userData.emailInvites.filter(
        (section) => section.id !== id
      );
      state.userData = { ...state.userData, emailInvites: filtered };
    },

    handleCreateNewCategorie(
      state,
      action: PayloadAction<{ name: string; description: string }>
    ) {
      const { name, description } = action.payload;
      state.selectedCategories = [
        ...state.selectedCategories,
        {
          id: crypto.randomUUID(),
          name,
          description,
          checked: true,
          processList: [],
          isCreated: true,
        },
      ];
    },

    handleRemoveCategorie(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.selectedCategories = state.selectedCategories.filter(
        (category) => category.id !== id
      );
    },

    handleEditCategorie(
      state,
      action: PayloadAction<{ id: string; name: string; description: string }>
    ) {
      const { name, description, id } = action.payload;
      state.selectedCategories = state.selectedCategories.map(
        (category: ProcessCategoryTypes) => {
          if (category.id === id) {
            return {
              ...category,
              name,
              description,
            };
          }
          return category;
        }
      );
    },

    handleSelectCategorie(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const selected = state.processCategories.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked: true,
          };
        }
        return item;
      });
      const unChecked = selected.filter((item) => item.checked === false);
      const isChecked = selected.filter((item) => item.checked === true);
      state.processCategories = [...unChecked];
      state.selectedCategories = [...state.selectedCategories, ...isChecked];
    },

    handleUnSelectCategorie(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const selected = state.selectedCategories.map(
        (item: ProcessCategoryTypes) => {
          if (item.id === id) {
            return {
              ...item,
              checked: false,
            };
          }
          return item;
        }
      );
      const unChecked = selected.filter((item) => item.checked === false);
      const isChecked = selected.filter((item) => item.checked === true);
      state.processCategories = [...state.processCategories, ...unChecked];
      state.selectedCategories = [...isChecked];
    },

    handleCreateProcess(
      state,
      action: PayloadAction<{ title: string; cat: string }>
    ) {
      const { title, cat } = action.payload;
      state.selectedCategories = state.selectedCategories.map(
        (category: ProcessCategoryTypes) => {
          if (category.name === cat) {
            return {
              ...category,
              processList: [
                ...category.processList,
                { id: crypto.randomUUID(), title, parentCategory: cat },
              ],
            };
          }
          return category;
        }
      );
    },

    handleEditProcess(
      state,
      action: PayloadAction<{ title: string; parentCat: string; id: string }>
    ) {
      const { title, parentCat, id } = action.payload;
      state.selectedCategories = state.selectedCategories.map(
        (category: ProcessCategoryTypes) => {
          if (category.name === parentCat) {
            const updatedProcess = [...category.processList].map((process) => {
              if (process.id === id) {
                return {
                  ...process,
                  title,
                  parentCategory: parentCat,
                };
              }
              return process;
            });
            return {
              ...category,
              processList: updatedProcess,
            };
          }
          return category;
        }
      );
    },

    handleRemoveProcess(
      state,
      action: PayloadAction<{ parentId: string; id: string }>
    ) {
      const { parentId, id } = action.payload;
      const finded = state.selectedCategories.map(
        (category: ProcessCategoryTypes) => {
          if (category.id === parentId) {
            return {
              ...category,
              processList: category.processList.filter(
                (process) => process.id !== id
              ),
            };
          }
          return category;
        }
      );
      state.selectedCategories = finded;
    },

    handleSelectType(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const selected = state.documentTypes.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked: true,
          };
        }
        return item;
      });
      const unChecked = selected.filter((item) => item.checked === false);
      const isChecked = selected.filter((item) => item.checked === true);
      state.documentTypes = [...unChecked];
      state.selectedDocumentTypes = [
        ...state.selectedDocumentTypes,
        ...isChecked,
      ];
    },

    handleUnSelectType(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const selected = state.selectedDocumentTypes.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });
      const unChecked = selected.filter((item) => item.checked === false);
      const isChecked = selected.filter((item) => item.checked === true);
      state.documentTypes = [...state.documentTypes, ...unChecked];
      state.selectedDocumentTypes = [...isChecked];
    },

    handleCreateType(
      state,
      action: PayloadAction<{
        title: string;
        description: string;
        allow: boolean;
      }>
    ) {
      const { title, description, allow } = action.payload;
      state.selectedDocumentTypes = [
        ...state.selectedDocumentTypes,
        {
          id: crypto.randomUUID(),
          title,
          description,
          checked: true,
          allowOutput: allow,
          isCreated: true,
        },
      ];
    },

    handleRemoveType(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.selectedDocumentTypes = state.selectedDocumentTypes.filter(
        (categorie) => categorie.id !== id
      );
    },

    handleEditType(
      state,
      action: PayloadAction<{
        title: string;
        description: string;
        allow: boolean;
        id: string;
      }>
    ) {
      const { title, description, allow, id } = action.payload;
      state.selectedDocumentTypes = state.selectedDocumentTypes.map(
        (categorie) => {
          if (categorie.id === id) {
            return {
              ...categorie,
              title,
              description,
              allowOutput: allow,
            };
          }
          return categorie;
        }
      );
    },
  },
});

export const {
  handleUpdateFields,
  handleAddEmail,
  handleUpdateEmails,
  handleUpdateAvatar,
  handleUpdateRoles,
  handleRemoveInviteSection,
  handleCreateNewCategorie,
  handleSelectCategorie,
  handleUnSelectCategorie,
  handleCreateProcess,
  handleRemoveProcess,
  handleSelectType,
  handleUnSelectType,
  handleCreateType,
  handleRemoveCategorie,
  handleEditCategorie,
  handleRemoveType,
  handleEditType,
  handleEditProcess,
} = basicUserSlice.actions;

export default basicUserSlice.reducer;
