import {
  Autocomplete,
  Avatar,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from 'components/icon';

import React from 'react';

import { MultipleAutocompleteProps } from './multiple-autocomplete.types';

export const useStyles = makeStyles((theme) => ({
  option: {
    display: 'flex',
    padding: '9px 0 7px 16px',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(69, 189, 129, 0.04)',
    },
    cursor: 'pointer',
    transition: '0.3 ease-in-out',
  },
  selectedOption: {
    background: '#daf2e6',
  },
  productOption: {
    '&:hover': {
      background: 'rgba(69, 189, 129, 0.04)',
    },
  },
  qsistantOption: {
    '&:hover': {
      background: '#4599bf14',
    },
  },
}));
export function MultipleAutocompleteComponent({
  sx,
  options,
  label,
  value,
  onChange,
  placeholder = '',
  variant = 'outlined',
  getOptionLabel,
  defaultValue,
  className,
  fullWidth = false,
  color = 'qsistant',
  disableCloseOnSelect = false,
}: MultipleAutocompleteProps) {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      fullWidth={fullWidth}
      id='multiple-autocomplete'
      value={value}
      color={color}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, selectedValue) =>
        option.value === selectedValue.value
      }
      defaultValue={defaultValue}
      filterSelectedOptions
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          className={`${classes.option} ${
            selected ? classes.selectedOption : ''
          } ${
            color === 'product'
              ? classes.productOption
              : color === 'qsistant'
              ? classes.qsistantOption
              : ''
          }`}
        >
          <Typography
            component='span'
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {option?.avatar ? (
              <Avatar
                sx={{
                  marginRight: '0.5rem',
                  width: '2.5rem',
                  height: '2.5rem',
                }}
              >
                {option?.label[0]}
              </Avatar>
            ) : null}
            {option.label}
          </Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={sx}
          className={className}
          variant={variant}
          label={label}
          color={color}
          placeholder={placeholder}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            avatar={
              <Avatar sx={{ display: option?.avatar ? 'flex' : 'none' }}>
                {option?.label}
              </Avatar>
            }
          />
        ))
      }
      popupIcon={<Icon name='arrow_drop_down' fill='inherit' />}
    />
  );
}
