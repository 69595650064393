export const detectionCapabilities = [
  {
    value: 'record function',
    id: 258,
    label: 'recordfunction',
    disabled: true,
  },
  {
    value: 'usable as record',
    id: 213,
    label: 'usableasrecord',
  },
  {
    value: 'unusable as record',
    id: 311,
    label: 'unusableasrecord',
  },
];

export const sortByOptions = [
  { id: 1, value: 'default', label: 'noselection' },
  { id: 2, value: 'alphabet ascending', label: 'alphabetascending' },
  { id: 3, value: 'alphabet descending', label: 'alphabetdescending' },
  {
    id: 4,
    value: 'last modified ascending',
    label: 'changedateascending',
  },
  {
    id: 42,
    value: 'last modified descending',
    label: 'changedatedescending',
  },
];
