import { Checkbox, TextField } from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import type { InputFieldComponentProps } from './types';

export function InputFieldComponent({
  className,
  label,
  onBlur,
  onChange,
  onDelete,
  onClick,
  check,
  checker = false,
  customWidth = false,
  blocked = false,
}: InputFieldComponentProps) {
  return (
    <TextField
      autoFocus={!(label.length > 0)}
      variant='outlined'
      className={className}
      onClick={!customWidth ? onClick : null}
      value={label}
      onChange={onChange}
      InputProps={{
        inputProps: {
          style: {
            textAlign: customWidth ? 'left' : 'center',
            width: customWidth ? `${label.length + 1}ch` : '10.2rem',
            paddingLeft: customWidth && '0.5rem',
            textOverflow: 'ellipsis',
            maxWidth: customWidth && '40ch',
          },
          onBlur,
        },
        endAdornment: blocked ? null : (
          <Icon
            name='highlight_off'
            size={1.25}
            fill='#6E358C'
            onClick={onDelete}
            style={{
              position: 'absolute',
              top: '-0.5rem',
              right: '-0.5rem',
              background: '#fff',
              borderRadius: '50%',
              cursor: 'pointer',
              zIndex: 1,
            }}
          />
        ),
        startAdornment: checker ? (
          <Checkbox
            disabled={false}
            checked={check}
            onClick={customWidth ? onClick : null}
            sx={{
              padding: 0,
              '&.MuiCheckbox-root.Mui-checked': {
                color: '#6E358C',
              },
            }}
          />
        ) : null,
      }}
      sx={{
        position: 'relative',
        width: 'max-content',
        input: { cursor: 'pointer' },
        '&.MuiTextField-root': {
          background: check ? '#6e358c24' : '#fff',
          '&:hover': {
            background: '#6e358c08',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #6E358C',
          '&:hover': {
            border: '2px solid #6E358C !important',
          },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
          '& > fieldset': {
            borderColor: check ? '#6E358C' : '#0000001F',
          },
        },
        '& .MuiOutlinedInput-root': {
          '& > fieldset': {
            borderColor: check ? '#6E358C' : '#0000001F',
          },
        },
      }}
    />
  );
}
