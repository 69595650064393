import {
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from 'components/icon';
import { RequestReviewModal } from 'modals/product-development/request-review';
import { ShareRequestDataModal } from 'modals/product-development/share-request-data';
import {
  updateProduct as updateCurrentProduct,
  updateDataset,
  updateFilledPhase,
  updatePhaseField,
} from 'store/product/current-product-slice';
import { updateFileStatus } from 'store/product/product-files-slice';
import { updateProducts } from 'store/product/products-slice';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PageWrapper } from '../../../wrappers/page-wrapper/page-wrapper';
import { ProductFilesTab } from './poduct_tabs/product-files-tab';
import { ProductPhasesTab } from './poduct_tabs/product_phases_tab';
import { DividerFullWidth } from './poduct_tabs/product_phases_tab/custom-styled-phases';

const MyImg = styled('img')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  top: 0,
  left: 0,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pb: 0 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function SingleProductPage({ pathName, parentTab, homePage }) {
  const dispatch = useDispatch();
  const { name } = useParams();
  const { products } = useSelector((state) => state.productsReducer);
  const currentProduct = useSelector((state) => state.currentProductReducer);
  const selectedPhaseId = useSelector(
    (state) => state.currentProductReducer.selectedPhaseId
  );

  const selectedDatasetId = useSelector(
    (state) => state.currentProductReducer.selectedDatasetId
  );
  const phases = useSelector((state) => state.currentProductReducer.phases);
  const filesFoldersData = useSelector(
    (state) => state.filesProductReducer.folders
  );
  const [openPhases, setOpenPhases] = useState(false);
  const [importedFiles, setImportedFiles] = useState(false);
  const { name: linkName } = useParams();
  const inputRef = useRef();
  const [value, setValue] = useState(0);
  const [header, setHeader] = useState(name);
  const product = products.find((p) => p.productName === name);
  const { id, productName, productDesc, image } = product || {};
  const [prodDesc, setProdDesc] = useState(product?.productDesc);
  const [saved, setSaved] = useState(false);
  const [savedFiles, setSavedFiles] = useState(false);
  const [docsModal, setDocsModal] = useState(false);
  const [addedDocument, setAddedDocument] = useState([]);
  //----
  const [fakeData, setFakeData] = useState([]);
  const [approver, setApprover] = useState([]);
  const [examiners, setExaminers] = useState([]);
  const [expanded, setExpanded] = useState('panel1');
  const [reviewModal, setReviewModal] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shareDataModal, setShareDataModal] = useState(false);
  const [shareMode, setShareMode] = useState(false);
  //---
  const handleChangeDesc = (e) => {
    setProdDesc(e.target.value);
  };
  const handleOpenPhases = () => {
    setOpenPhases(true);
  };

  const handleImportedFiles = () => {
    setImportedFiles(true);
  };

  const handleImg = (e) => {
    dispatch(updateCurrentProduct({ image: e.target.files[0] }));
    dispatch(
      updateProducts({
        id,
        description: productDesc,
        image: e.target.files[0],
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setValue(0);
    }, 100);
  }, []);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  //-------------------
  const countApprovedStatus = (array) => {
    if (!Array.isArray(array)) {
      return 0;
    }

    return array.reduce((count, obj) => {
      if (obj?.status === 'FREIGEGEBEN' || obj?.status === 'IN PRÜFUNG') {
        count += 1;
      }

      return count;
    }, 0);
  };

  const currentDataset = phases.find((p) => p.id === selectedPhaseId)?.datasets
    ?.length;
  const selectedDataset = phases
    .find((p) => p.id === selectedPhaseId)
    ?.datasets?.find((d) => d.id === selectedDatasetId);
  const selectedPhase = phases.find((p) => p.id === selectedPhaseId);
  const calcPhasesFilling =
    (countApprovedStatus(selectedPhase?.datasets) /
      (selectedPhase?.datasets?.length || 1)) *
    100;
  //-------------------
  const handleReviewFormSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateDataset({
        selectedId: selectedPhaseId,
        selectedDatasetId,
        updates: {
          status: calcPhasesFilling === 100 ? 'FREIGEGEBEN' : 'IN PRÜFUNG',
        },
      })
    );

    dispatch(
      updateFilledPhase({
        selectedId: selectedPhaseId,
        released: !!Math.floor(calcPhasesFilling / 100) && true,
        filled: calcPhasesFilling,
        status: !!Math.floor(calcPhasesFilling / 100) && 'IN PRÜFUNG',
      })
    );

    setApprover([]);
    setExaminers([]);
    setReviewModal(false);
  };

  const handleReviewFilesFormSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateFileStatus({
        checkedIds,
        updates: {
          status: 'check',
        },
      })
    );

    setApprover([]);
    setExaminers([]);
    setReviewModal(false);
  };

  const handleShareDataSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-shadow

    dispatch(
      updateDataset({
        selectedId: selectedPhaseId,
        selectedDatasetId,
        updates: {
          status: 'FREIGEGEBEN',
        },
      })
    );

    const phaseToUpdate = phases.find((p) => p.id === selectedPhaseId);
    if (phaseToUpdate) {
      dispatch(
        updatePhaseField({
          id: selectedPhaseId,
          field: 'filled',
          value: calcPhasesFilling,
          released: false,
          status: calcPhasesFilling === 100 && 'FREIGEGEBEN',
        })
      );
    }

    setShareDataModal(false);
  };
  const handleShareDataSubmit2 = (e) => {
    e.preventDefault();

    const phaseToUpdate = phases.find((p) => p.id === selectedPhaseId);
    if (phaseToUpdate) {
      dispatch(
        updatePhaseField({
          id: selectedPhaseId,
          field: 'filled',
          value: calcPhasesFilling,
          released: true,
          status: 'FREIGEGEBEN',
        })
      );
    }
    setShareDataModal(false);
  };

  const handleShareFileDataSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateFileStatus({
        checkedIds,
        updates: {
          status: 'released',
        },
      })
    );
    setSelectedRows([]);
    setCheckedIds([]);
    setShareDataModal(false);
  };

  const handleAddData = () => {
    setShareMode(false);
    setShareDataModal(true);
  };
  const handleAddDataPhase = () => {
    setShareMode(true);
    setShareDataModal(true);
  };

  return (
    <PageWrapper
      id={id}
      linkName={linkName}
      name={header}
      isPageHeader
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      <Card sx={{ display: 'flex', mb: '3.8125rem', cursor: 'pointer' }}>
        <Box sx={{ width: '21.5rem' }}>
          <Box
            sx={{
              flex: '0 0 20%',
              position: 'relative',
              minHeight: '136px',
            }}
          >
            {product?.image ? (
              <MyImg
                src={
                  product.image && typeof product.image !== 'string'
                    ? URL.createObjectURL(product.image)
                    : image && typeof image !== 'string'
                    ? URL.createObjectURL(image)
                    : null
                }
                alt='img'
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '137px',
                  background: '#0000000A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon size='1.5rem' name='image_filled' fill='#00000033' />
              </Box>
            )}
          </Box>
          <Button
            fullWidth
            sx={{
              background: '#E7F3ED',
              fontSize: '.875rem',
              color: '#45BD81',
              letterSpacing: '0.078rem',
              gap: '0.4375rem',
              height: '3.5625rem',
            }}
            onClick={() => inputRef.current.click()}
          >
            <Icon name='edit' size={1.5} fill='#45BD81' />
            BILD ÄNDERN
            <input
              hidden
              name='upload'
              ref={inputRef}
              accept='image/*'
              type='file'
              onChange={handleImg}
            />
          </Button>
        </Box>
        <CardContent
          sx={{
            width: '100%',
            p: '2rem',
          }}
        >
          <TextField
            color='product'
            variant='filled'
            label='Beschreibung'
            type='text'
            value={prodDesc}
            onChange={handleChangeDesc}
            fullWidth
            multiline
            rows={4}
          />
        </CardContent>
      </Card>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          textColor='inherit'
          TabIndicatorProps={{
            style: {
              backgroundColor: '#45BD81',
              height: '1px',
            },
          }}
          sx={{
            '& button': {
              textTransform: 'none',
            },
            '& .MuiTab-root': {
              color: 'rgba(0, 0, 0, 0.87)',
              minWidth: '382px',
            },
            '& .Mui-selected': {
              color: '#45BD81',
              minHeight: '48px',
            },
            '& button:active': {
              backgroundColor: '#ededed',
              color: '#45BD81',
              minHeight: '48px',
            },
            '& button:focus': {
              backgroundColor: '#ededed',
              color: '#45BD81',
              minHeight: '48px',
            },
            '& .MuiSvgIcon-root': {
              fill: '#45BD81',
            },
          }}
        >
          <Tab
            className='Tab'
            iconPosition='start'
            icon={
              value === 1 ? (
                <Icon
                  name='leaderboard'
                  fill='rgba(0, 0, 0, 0.60)'
                  size='1.5'
                />
              ) : (
                <Icon name='leaderboard' fill='#45BD81' size='1.5' />
              )
            }
            label='Produktphasen'
            {...a11yProps(0)}
          />
          <Tab
            className='Tab'
            iconPosition='start'
            icon={
              value === 0 ? (
                <Icon name='folder' fill='rgba(0, 0, 0, 0.60)' size='1.5' />
              ) : (
                <Icon name='folder' fill='#45BD81' size='1.5' />
              )
            }
            label='Akten'
            {...a11yProps(1)}
          />
        </Tabs>
        <DividerFullWidth />
        <TabPanel value={value} index={0}>
          <ProductPhasesTab
            openPhases={openPhases}
            handleOpenPhases={handleOpenPhases}
            docsModal={docsModal}
            setDocsModal={setDocsModal}
            addedDocument={addedDocument}
            setAddedDocument={setAddedDocument}
            expanded={expanded}
            fakeData={fakeData}
            setFakeData={setFakeData}
            setReviewModal={setReviewModal}
            setExpanded={setExpanded}
            calcPhasesFilling={calcPhasesFilling}
            currentDataset={currentDataset}
            selectedDataset={selectedDataset}
            handleAddData={handleAddData}
            handleAddDataPhase={handleAddDataPhase}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProductFilesTab
            savedFiles={savedFiles}
            setSavedFiles={setSavedFiles}
            handleImportedFiles={handleImportedFiles}
            importedFiles={importedFiles}
            docsModal={docsModal}
            setDocsModal={setDocsModal}
            addedDocument={addedDocument}
            setAddedDocument={setAddedDocument}
            setReviewModal={setReviewModal}
            checkedIds={checkedIds}
            setCheckedIds={setCheckedIds}
            setShareDataModal={setShareDataModal}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </TabPanel>
      </Box>
      {reviewModal && (
        <RequestReviewModal
          approver={approver}
          setApprover={setApprover}
          examiners={examiners}
          setExaminers={setExaminers}
          open={reviewModal}
          data={fakeData}
          handleReviewFormSubmit={
            value === 0 ? handleReviewFormSubmit : handleReviewFilesFormSubmit
          }
          onClose={() => {
            setReviewModal(false);
          }}
        />
      )}
      {shareDataModal && (
        <ShareRequestDataModal
          value={value}
          shareMode={shareMode}
          open={shareDataModal}
          handleShareDataSubmit={
            value === 0 && shareMode
              ? handleShareDataSubmit2
              : value !== 0
              ? handleShareFileDataSubmit
              : handleShareDataSubmit
          }
          headerName={
            value ? filesFoldersData[0]?.name : selectedDataset?.dataset
          }
          onClose={() => {
            setShareDataModal(false);
          }}
        />
      )}
    </PageWrapper>
  );
}
