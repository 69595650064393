import { Box, IconButton, Typography } from '@mui/material';
import { ProcessCategorieCard } from 'cards/process-categorie-card';
import { Icon } from 'components/icon';

import React from 'react';

import { useStyles } from './styles';

export function DocumentTypesComponent({ documentTypes }) {
  const classes = useStyles();
  return (
    <Box component='div'>
      <Box component='div' textAlign='center' mb='2rem'>
        <Box
          component='div'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography component='h3' className={classes.title}>
            Dokumententarten
          </Typography>
          <IconButton>
            <Icon name='info' fill='#00000099' size={1.25} />
          </IconButton>
        </Box>
        <Typography component='p' className={classes.description}>
          Dokumentenarten kategorisieren Dokumente in unterschiedliche Arten, um
          eine bessere Übersicht aller Dokumente zu gewährleisten
        </Typography>
      </Box>
      <Box component='div' mb='1.5rem' ml='2rem'>
        <Typography component='h6' className={classes.subDescription}>
          Von uns vorerst festgelegte Dokumentenarten (später änderbar):
        </Typography>
      </Box>
      <Box component='div' className={classes.cardContainer}>
        {documentTypes.length > 0 &&
          documentTypes.map((cat) => (
            <ProcessCategorieCard key={cat.id} disabled {...cat} />
          ))}
      </Box>
    </Box>
  );
}
