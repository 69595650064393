import { Box, CircularProgress, MenuItem, TextField } from '@mui/material';
import { MultiSelectDropdown } from 'components/select/multiple-select';
import { fetchProcesses } from 'store/administration/process/process-slice';
import type { ProcessDocumentsTypes } from 'store/administration/process/types';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultModal } from '../default/default.modal';
import { useStyles } from './styles';
import type { AddDepartmentModalProps } from './types';

export function DepartmentAddModalComponent({
  closeModal,
  modal,
  handleSuccess,
  department = null,
  icon,
}: AddDepartmentModalProps) {
  const classes = useStyles();
  const { t } = useTranslation('department');
  const dispatch = useAppDispatch();

  const processList = useAppSelector((state) => state.processReducer.documents);
  const departmentsList = useAppSelector(
    (state) => state.departmentsReducer.departments
  );

  const [name, setName] = useState<string>(department?.name ?? '');
  const [processes, setProcesses] = useState<typeof processList>(
    department?.responsibilityProcesses ?? []
  );

  const [parent, setParent] = useState<number>(
    department?.parent ? department?.parent.id : 0
  );

  const [id] = useState<number>(department ? department.id : 0);

  useEffect(() => {
    dispatch(fetchProcesses());
  }, [dispatch]);

  const handleSubmit = () => {
    handleSuccess({
      name,
      parent,
      id,
      responsibilityProcesses: processes.map((p) => p.id),
    });
  };

  return (
    <DefaultModal
      closeModal={closeModal}
      successLabel={id === 0 ? t('modal:success') : t('department:save')}
      handleSuccess={handleSubmit}
      successColor='management'
      modal={modal}
      icon={icon}
      className={classes.modal}
      disabled={name === '' || name.length < 3}
      modalTitle={
        id === 0
          ? t('department:newdepartment')
          : t('department:editdepartment')
      }
    >
      {processList?.length > 0 && departmentsList?.length > 0 ? (
        <Box component='div' className={classes.contentBox}>
          <TextField
            color='management'
            variant='outlined'
            autoComplete='off'
            label={t('department:departmentname')}
            type='text'
            autoFocus
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            color='management'
            autoComplete='off'
            select
            fullWidth
            variant='outlined'
            value={parent}
            label={t('department:departmentparent')}
            onChange={(e) => setParent(Number(e.target.value))}
          >
            <MenuItem key={0} value={0} selected>
              {t('department:noparentdepartment')}
            </MenuItem>
            {departmentsList?.map((dep) => (
              <MenuItem key={dep.id} value={dep.id}>
                {dep.name}
              </MenuItem>
            ))}
          </TextField>
          <MultiSelectDropdown<ProcessDocumentsTypes>
            multiple
            color='management'
            disableCloseOnSelect
            label={t('department:assignedprocesses')}
            onChange={(e, v) => setProcesses(Array.isArray(v) ? v : [v])}
            options={processList}
            values={processes}
            renderValue={(o) => o.name}
          />
        </Box>
      ) : (
        <CircularProgress color='management' />
      )}
    </DefaultModal>
  );
}
