import { Box, Button, Divider, MenuItem, TextField } from '@mui/material';
import { ModalHeader } from 'components/modal-header';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessActionModalProps } from './process-acton.types';

export function ModalActionProcess({
  open,
  header,
  onClose,
  onAction,
  options,
  title,
  category,
  errors = null,
  onChangeTitle,
  onChangeCategory,
  disabled = false,
  color,
  icon,
  isEditing = false,
}: ProcessActionModalProps) {
  const { t } = useTranslation(['document', 'processes', 'modal']);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef]);

  const hasViolation = (fieldType) => {
    if (!errors) return false; // no errors no violation
    const violation = errors?.violations.filter(
      (v) => v.propertyPath === fieldType
    );
    return violation && violation.length > 0;
  };

  const getViolation = (fieldType) => {
    if (!hasViolation(fieldType)) return ''; // no errors no violation

    const violation = errors?.violations.filter(
      (v) => v.propertyPath === fieldType
    );

    return violation && violation.length > 0 ? violation[0].title : '';
  };
  return (
    <ModalWrapper open={open} sx={{ width: '35rem' }}>
      <ModalHeader
        header={header}
        onClose={onClose}
        icon={{ name: icon.name }}
        primaryColor={color}
      />
      <Divider />
      <Box
        component='div'
        padding='1.5rem'
        display='flex'
        flexDirection='column'
        gap='1.5rem'
      >
        <TextField
          autoComplete='off'
          label={t('document:title')}
          color={color}
          value={title}
          onChange={onChangeTitle}
          error={hasViolation('name')}
          helperText={`${getViolation('name')}`}
          inputRef={inputRef}
        />
        <TextField
          select
          color={color}
          disabled={disabled}
          value={category}
          onChange={onChangeCategory}
          label='Übergeordnete Prozesskategorie'
        >
          {options.map((item) => (
            <MenuItem key={item.id} value={item.name} sx={{ gap: '0.5rem' }}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='flex-end'
        padding='0 1.5rem 1.5rem 1.5rem'
      >
        <Button variant='text' onClick={onClose} color={color}>
          {t('modal:abort')}
        </Button>
        <Button
          disabled={title.length < 1 || !category}
          variant='contained'
          color={color}
          onClick={() => {
            onAction(title, category);
            onClose();
          }}
        >
          {isEditing ? t('modal:save') : t('modal:success')}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
