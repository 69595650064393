import { Box, Button, Tab, Tabs } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';
import { routes } from 'routes/routes';
import { handleActiveList, handleTabValue } from 'store/common/common-slice';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProcessOverview } from './process-overview/process-overview';
import { ProcessTypeOverview } from './processtype-overview/processtype-overview';

export function ProcessPage({ pathName, parentTab, homePage }) {
  const [tabValue, setTabValue] = useState<number>(0);

  const { t } = useTranslation('processes');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const navigateToPage = () => {
    navigate(routes().map);
    dispatch(handleTabValue(0));
    dispatch(handleActiveList({ parent: 0, item: 1 }));
  };

  return (
    <PageWrapper
      pageLabel={pathName}
      button={
        <Button
          variant='outlined'
          color='qsistant'
          onClick={navigateToPage}
          startIcon={<Icon name={ICON.MAP} fill='inherit' />}
        >
          zur PROZESSLANDKARTE
        </Button>
      }
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          TabIndicatorProps={{ className: 'Management' }}
        >
          <Tab className='ManagamentTab' label={t('processes:processtypes')} />
          <Tab className='ManagamentTab' label={t('processes:processes')} />
        </Tabs>
      </Box>
      <Box component='div' p='1rem'>
        {tabValue === 0 ? <ProcessTypeOverview /> : <ProcessOverview />}
      </Box>
    </PageWrapper>
  );
}
