import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import { routes } from 'routes/routes';
import type { SupportingDocumentTypes } from 'store/qsistant/supporting-documents/types';
import { dotDividerForDate } from 'utils/utils';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconBox } from './component/icon-box/icon-box.component';

export default function useTableCells() {
  const navigate = useNavigate();
  const { t } = useTranslation(['status', 'document', 'fillouot']);
  const cells = [
    {
      id: '1t2gb',
      heading: t('document:status'),
      onClick: (item: SupportingDocumentTypes) =>
        navigate(
          routes().singleSupportDocument.replace(':id', item.id.toString())
        ),
      render: (item: SupportingDocumentTypes) => (
        <Status
          status={item.filloutAllowed ? 'free' : 'completed'}
          statusText={
            item.filloutAllowed ? t('status:empty') : t('status:signed')
          }
        />
      ),
    },
    {
      id: '1yhj',
      heading: t('document:name'),
      onClick: (item: SupportingDocumentTypes) =>
        navigate(
          routes().singleSupportDocument.replace(':id', item.id.toString())
        ),
      render: (item: SupportingDocumentTypes) => {
        if (item.name) {
          return (
            <Box
              component='div'
              display='flex'
              alignItems='center'
              gap='0.5rem'
            >
              <Tooltip title={t(`document:${item.elementType}`)}>
                <IconButton disableRipple sx={{ padding: 0 }}>
                  <Icon name='task' size={1.5} fill='#B4A656' />
                </IconButton>
              </Tooltip>
              <Typography component='p' className='TBasicText'>
                {item.name}
              </Typography>
            </Box>
          );
        }
        return (
          <Box component='div' p='0.5rem'>
            -
          </Box>
        );
      },
    },
    {
      id: '3tyuh',
      heading: t('document:number'),
      onClick: (item: SupportingDocumentTypes) =>
        navigate(
          routes().singleSupportDocument.replace(':id', item.id.toString())
        ),
      render: (item: SupportingDocumentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.version
            ? `${`${item.version}\n${t('document:revision')}`} ${item.revision}`
            : '-'}
        </Typography>
      ),
    },
    {
      id: '4ads',
      heading: t('document:formtemplate'),
      onClick: (item: SupportingDocumentTypes) =>
        navigate(
          routes().singleSupportDocument.replace(':id', item.id.toString())
        ),
      render: (item: SupportingDocumentTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.version
            ? `${item.version} | ${t('document:revision')} ${item.version}`
            : '-'}
        </Typography>
      ),
    },
    {
      id: '6ve',
      heading: t('document:signed'),
      onClick: (item: SupportingDocumentTypes) =>
        navigate(
          routes().singleSupportDocument.replace(':id', item.id.toString())
        ),
      render: (item: SupportingDocumentTypes) => (
        <Box display='flex' alignItems='center' gap='0.5rem' component='div'>
          {item.filloutAllowed ? (
            <Box component='div' sx={{ fontSize: '1rem', color: '#000000de' }}>
              -
            </Box>
          ) : (
            <>
              <Tooltip title={item.editor?.fullName}>
                <Avatar
                  alt={item.editor?.fullName}
                  src='/photo'
                  className='TAvatar'
                />
              </Tooltip>
              <Box display='flex' flexDirection='column' component='div'>
                <Typography component='p' style={{ width: 'max-content' }}>
                  {item.editor?.fullName.replace(/[a-z]{1,}/, '.')}
                </Typography>
                <Typography component='p' className='TDateAvatar'>
                  {dotDividerForDate(item.updated)}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      ),
    },
    {
      id: '4waads',
      heading: t('document:customerorsupplier'),
      onClick: (item: SupportingDocumentTypes) =>
        navigate(
          routes().singleSupportDocument.replace(':id', item.id.toString())
        ),
      icon: { name: 'info', fill: '#777777' },
      render: () => <Box sx={{ fontSize: '1rem', color: '#000000de' }}>-</Box>,
    },
    {
      id: '4hgJ',
      heading: t('document:tags'),
      onClick: (item: SupportingDocumentTypes) =>
        navigate(
          routes().singleSupportDocument.replace(':id', item.id.toString())
        ),
      render: () => <Chip label='Testtag' size='small' />,
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item: SupportingDocumentTypes) => <IconBox itemId={item.id} />,
    },
  ];

  return {
    cells,
  };
}
