import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from 'components/date-picker';
import { Icon } from 'components/icon';
import { ModalCreateOption } from 'modals/create-option/create-option.modal';

import React, { useState } from 'react';

import { useStyles } from './styles';
import type { TableAuditCardProps } from './table-audit-card.types';

export function TableAuditCard({
  label,
  departments,
  auditors,
  value,
  onChange,
  addNewOption,
}: TableAuditCardProps) {
  const classes = useStyles();
  const [isCreate, setIsCreate] = useState<boolean>(false);

  const handleOnChange = (
    newValue: React.ChangeEvent<HTMLInputElement> | string,
    key: string
  ) => {
    onChange({
      ...value,
      [key]: newValue,
    });
  };

  return (
    <Box component='div' className={classes.cardWrapper}>
      <Typography component='h6' className={classes.labelStyle}>
        {label}
      </Typography>
      <TextField
        select
        variant='standard'
        label='Abteilung auswählen'
        color='documentSetup'
        sx={{ width: '13rem' }}
        value={value.department}
        onChange={(e) => {
          if (!e.target.value) return;
          handleOnChange(e.target.value, 'department');
        }}
      >
        {departments.map((depart) => (
          <MenuItem key={depart.id} value={depart.value}>
            {depart.label}
          </MenuItem>
        ))}
        <MenuItem>
          <Button
            disableRipple
            className={classes.buttonStyle}
            color='documentSetup'
            onClick={() => setIsCreate(true)}
            startIcon={
              <Icon name='add_circle_filled' fill='#6E358C' size={1.25} />
            }
          >
            Produktion erstellen
          </Button>
        </MenuItem>
      </TextField>
      <TextField
        select
        variant='standard'
        label='Auditor auswählen'
        sx={{ width: '13rem' }}
        color='documentSetup'
        value={value.auditor}
        onChange={(e) => handleOnChange(e.target.value, 'auditor')}
      >
        {auditors.length > 0 &&
          auditors.map((audit) => (
            <MenuItem key={audit.id} value={audit.value}>
              {audit.label}
            </MenuItem>
          ))}
      </TextField>
      <DatePicker
        variant='standard'
        value={value.date}
        onChange={(v) => handleOnChange(v, 'date')}
        className={classes.datePickerStyle}
      />
      {isCreate ? (
        <ModalCreateOption
          open={isCreate}
          onClose={() => setIsCreate(false)}
          onCreate={addNewOption}
          color='documentSetup'
        />
      ) : null}
    </Box>
  );
}
