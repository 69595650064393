// TODO all steps should be described by TS
import { Box, Typography } from '@mui/material';
import api from 'api/api';
import { AppbarSecondary } from 'components/appbar';
import { StepProgress } from 'components/progress';
import {
  BasicProfileSettings,
  CompanyProfileSettings,
  CreateQmsProfileSettings,
  DefineProcess,
  InvitationsProfileSettings,
  QmsProfileSettings,
  StartCreateQMS,
} from 'forms/profile-settings-components';
import useMultistepForm from 'hooks/useMultistepForm';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  handleAddEmail,
  handleRemoveInviteSection,
  handleUpdateAvatar,
  handleUpdateEmails,
  handleUpdateFields,
  handleUpdateRoles,
} from 'store/user-profile/basic-user';
import type { UserDataTypes } from 'store/user-profile/basic-user.types';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { OnboardingWelcomePage } from './components/onboarding-welcome.page';
import { industryTypes, userRoles, workersAmount } from './constants';
import { useStyles } from './styles';

export function SimpleUserPage() {
  const tariffPlan = 'free';
  const classes = useStyles();
  const dispatcher = useAppDispatch();
  const { token } = useParams();
  const userData = useAppSelector((state) => state.basicUserReducer.userData);
  const categories = useAppSelector(
    (state) => state.basicUserReducer.selectedCategories
  );
  const selectedDocumentTypes = useAppSelector(
    (state) => state.basicUserReducer.selectedDocumentTypes
  );

  const [page, setPage] = useState<'profile_settings' | 'welcome_page'>(
    'welcome_page'
  );

  const updateFields = (fields: Partial<UserDataTypes>) =>
    dispatcher(handleUpdateFields(fields));

  const updateAvatar = (type: 'colored' | 'image', value: File | string) => {
    if (type === 'colored') {
      const avatar = null;
      const avatarColor = value as string;
      dispatcher(handleUpdateAvatar({ avatar, avatarColor }));
    } else {
      const avatar = value as File;
      const avatarColor = '';
      dispatcher(handleUpdateAvatar({ avatar, avatarColor }));
    }
  };

  const updateEmails = (fields: string, iD: string) =>
    dispatcher(handleUpdateEmails({ fields, iD }));

  const updateRoles = (field: string, label: string, iD: string) =>
    dispatcher(handleUpdateRoles({ field, label, iD }));

  const addEmail = () => dispatcher(handleAddEmail());

  const removeInviteSection = (id: string) =>
    dispatcher(handleRemoveInviteSection({ id }));

  const [loading, setLoading] = useState(false);
  const handleNextStep = () => next();
  const handleBackStep = () => back();
  const navigate = useNavigate();

  useEffect(() => {
    api
      .post(`/onboarding/verifytoken`, {
        token,
      })
      .then(() => console.log('token ok'))
      .catch(() => navigate('/login')); // token invalid or already initiated
  }, []);

  const handleSave = () => {
    setLoading(true);
    api
      .post(`/onboarding/save`, {
        token,
        categories,
        user: userData,
        types: selectedDocumentTypes,
      })
      .then(() => {
        setLoading(false);
        navigate('/login');
      });
  };

  const { steps, currentStepIndex, step, progress, back, next } =
    useMultistepForm([
      <BasicProfileSettings
        key='basic'
        updateFields={updateFields}
        updateAvatar={updateAvatar}
        onNext={handleNextStep}
        formDisabled={
          !userData.name ||
          !userData.surName ||
          !userData.email ||
          (!userData.avatar && !userData.avatarColor)
        }
        {...userData}
      />,
      <CompanyProfileSettings
        key='company'
        industryTypes={industryTypes}
        workersAmount={workersAmount}
        updateFields={updateFields}
        onNext={handleNextStep}
        onBack={handleBackStep}
        {...userData}
      />,
      <InvitationsProfileSettings
        key='invitations'
        userRoles={userRoles}
        addEmail={addEmail}
        removeInviteSection={removeInviteSection}
        updateEmails={updateEmails}
        updateRoles={updateRoles}
        onNext={handleNextStep}
        onBack={handleBackStep}
        {...userData}
      />,
      <QmsProfileSettings
        key='qms'
        updateFields={updateFields}
        onNext={handleNextStep}
        onBack={handleBackStep}
        {...userData}
      />,
      <CreateQmsProfileSettings
        key='createQms'
        updateFields={updateFields}
        onNext={handleNextStep}
        onBack={handleBackStep}
        {...userData}
      />,
      <StartCreateQMS
        key='startCreateQms'
        onBack={handleBackStep}
        onNext={handleNextStep}
      />,
      <DefineProcess
        key='defineProcess'
        onBack={handleBackStep}
        onSave={handleSave}
        isLoading={loading}
      />,
    ]);

  return (
    <>
      <AppbarSecondary
        userName={userData.name}
        userImage={userData.avatar}
        avatarColor={userData.avatarColor}
        tariffPlan={tariffPlan}
        stepIndex={currentStepIndex}
        badge={currentStepIndex >= 5}
        showAvatar={currentStepIndex > 0}
        documentBadge={{
          name: 'waving_hand_filled',
          fill: '#3F97BE',
          size: 1,
          text: 'Eigenständige QM-System Erstellung',
        }}
      />
      <Box
        component='div'
        className={classes.mainBox}
        sx={{
          backgroundColor: currentStepIndex === 5 ? '#D9EAF2' : '#f5f5f5',
        }}
      >
        {page !== 'welcome_page' ? (
          <StepProgress
            progress={progress}
            className={classes.progressBox}
            sx={{ display: currentStepIndex >= 5 ? 'none' : 'block' }}
          />
        ) : null}
        {page === 'welcome_page' ? (
          <OnboardingWelcomePage
            button={{
              icon: 'not_started',
              color: 'linear-gradient(45deg, #4599BF, #6E358C)',
              label: 'QSISTANT entdecken',
            }}
            onClick={() => setPage('profile_settings')}
            title='Hallo Maxime, willkommen bei Qsistant.'
            description='Erstelle innerhalb weniger Minuten eine solide Basis für dein
          QM-System oder bringe dein aktuelles auf ein neues Level.'
          />
        ) : (
          <Box
            component='div'
            display='flex'
            alignItems='center'
            flexDirection='column'
            margin={currentStepIndex === 5 ? 'auto' : '2rem auto 0.5rem auto'}
            width={
              currentStepIndex === 3
                ? '50rem'
                : currentStepIndex === 2 || currentStepIndex === 4
                ? '43rem'
                : currentStepIndex === 1
                ? '29rem'
                : currentStepIndex === 5 || currentStepIndex === 6
                ? '100%'
                : '28rem'
            }
          >
            <Box
              component='div'
              textAlign='center'
              sx={{ display: currentStepIndex >= 5 ? 'none' : 'block' }}
            >
              <Typography component='h5' className={classes.stepNumberLabel}>
                SCHRITT {currentStepIndex + 1} VON {steps.length - 2}
              </Typography>
            </Box>
            {step}
          </Box>
        )}
      </Box>
    </>
  );
}
