import { Box, Button, Typography } from '@mui/material';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import type { ConfirmModalProps } from './types';

export function ModalConfirm({
  open,
  onClose,
  title,
  description,
  onContinue,
  color = 'qsistant',
  btnNextLabel,
}: ConfirmModalProps) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <ModalWrapper open={open} className={classes.modalStyle}>
      <Typography component='h5' className={classes.headerStyle}>
        {title}
      </Typography>
      <Typography component='p' className={classes.descriptionStyle}>
        {description}
      </Typography>
      <Box component='div' display='flex' justifyContent='flex-end'>
        <Button
          variant='text'
          onClick={onClose}
          color={color}
          className={classes.buttonStyle}
        >
          {t('common:abort')}
        </Button>
        <Button
          variant='text'
          onClick={onContinue}
          color={color}
          className={classes.buttonStyle}
        >
          {btnNextLabel}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
