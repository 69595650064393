import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { Status } from 'components/status';
import { routes } from 'routes/routes';
import type { DocumentOverviewTypes } from 'store/qsistant/document-overview/types';
import { dotDividerForDate } from 'utils/utils';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconBox } from './components/icon-box/icon-box.component';

const apiUrl = () => process.env.REACT_APP_API_URL;

export default function useTableCells() {
  const navigate = useNavigate();
  const { t } = useTranslation(['status', 'document']);

  const cells = [
    {
      id: '1tgb',
      heading: t('document:status'),
      onClick: (item: DocumentOverviewTypes) =>
        navigate(
          routes().singleDocumentOverview.replace(':id', item.id.toString())
        ),
      render: (item: DocumentOverviewTypes) => (
        <Status status={item.status} statusText={t(`status:${item.status}`)} />
      ),
    },
    {
      id: '2yhj',
      heading: t('document:name'),
      onClick: (item: DocumentOverviewTypes) =>
        navigate(
          routes().singleDocumentOverview.replace(':id', item.id.toString())
        ),
      render: (item: DocumentOverviewTypes) => (
        <Box component='div' display='flex' alignItems='center' gap='.5rem'>
          <Tooltip title={t(`document:${item.elementType}`)}>
            <IconButton disableRipple sx={{ padding: 0 }}>
              <Icon
                name={
                  item.elementType === 'qsistantdocument'
                    ? 'qs_icon_blue'
                    : 'qsistant_blue'
                }
                size={1.5}
              />
            </IconButton>
          </Tooltip>
          <Typography component='p' className='TBasicText'>
            {item.name}
          </Typography>
        </Box>
      ),
    },
    {
      id: '3tyuh',
      heading: t('document:number'),
      onClick: (item: DocumentOverviewTypes) =>
        navigate(
          routes().singleDocumentOverview.replace(':id', item.id.toString())
        ),
      render: (item: DocumentOverviewTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.documentNumber}
          <br />
          {t('document:revision')}&nbsp;
          {item.revision}
        </Typography>
      ),
    },
    {
      id: '4edcs',
      heading: t('document:documenttype'),
      onClick: (item: DocumentOverviewTypes) =>
        navigate(
          routes().singleDocumentOverview.replace(':id', item.id.toString())
        ),
      render: (item: DocumentOverviewTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.documentType?.name}
        </Typography>
      ),
    },
    {
      id: '5qlzi',
      heading: t('document:process'),
      onClick: (item: DocumentOverviewTypes) =>
        navigate(
          routes().singleDocumentOverview.replace(':id', item.id.toString())
        ),
      render: (item: DocumentOverviewTypes) => (
        <Typography component='p' className='TBasicText'>
          {item.process?.name}
        </Typography>
      ),
    },
    {
      id: '6iqve',
      heading: t('document:changed'),
      onClick: (item: DocumentOverviewTypes) =>
        navigate(
          routes().singleDocumentOverview.replace(':id', item.id.toString())
        ),
      render: (item: DocumentOverviewTypes) => (
        <Box component='div' display='flex' alignItems='center' gap='0.5rem'>
          <Tooltip title={item.creator?.fullName}>
            <Avatar
              alt={item.creator?.fullName}
              src={`${apiUrl()}/files/download/${item.creator?.avatar}`}
              className='TAvatar'
            />
          </Tooltip>
          <Box component='div' display='flex' flexDirection='column'>
            <Typography component='p' style={{ width: 'max-content' }}>
              {item.creator?.fullName.replace(/[a-z]{1,}/, '.')}
            </Typography>
            <Typography component='p' className='TDateAvatar'>
              {dotDividerForDate(item.updated)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      id: '8xrqp',
      heading: '',
      render: (item: DocumentOverviewTypes) => <IconBox item={item} />,
    },
  ];

  return {
    cells,
  };
}
