import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    allowOutput: {
      color: 'rgba(0, 0, 0, 0.87)',
      lineHeight: '1.5rem !important',
      fontWeight: `${typography.fontWeightRegular} !important`,
      fontSize: `${typography.pxToRem(16)} !important`,
      letterSpacing: '0.009rem !important',
    },
    inputsBox: {
      padding: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
    },
    modalStyle: {
      width: '35rem',
    },
    buttonsBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 1.5rem 1.5rem 1.5rem',
    },
  };
});
