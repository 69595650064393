import { createTheme } from '@mui/material';

export const searchTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: '0 0 4px 4px',
        },
        listbox: {
          maxHeight: '40vh',
          padding: 0,
          // borderTop: '1px solid rgba(0, 0, 0, 0.18)',
          '& > :not(li:nth-of-type(2))': {
            '& > .MuiAutocomplete-groupLabel': {
              display: 'none',
            },
          },
          '& > li': {
            gap: '0.5rem',
            width: '100%',
            margin: 'auto',
            '&.MuiAutocomplete-option': {
              paddingLeft: '0.675rem',
              borderLeft: '3px solid #fff',
              fontSize: '0.875rem',
              width: '98.5%',
              margin: 'auto',
            },
            '&.MuiAutocomplete-option.Mui-focused': {
              borderLeft: '3px solid #4599bf',
            },
            // '& .MuiAutocomplete-groupLabel': {
            //   color: 'rgba(0, 0, 0, 0.87)',
            //   fontWeight: 500,
            //   letterSpacing: 0,
            //   fontSize: '0.875rem',
            //   height: '2rem',
            //   borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            // },
          },
        },
        root: {
          '&.SearchTextField': {
            width: '35%',
            background: '#F3F3F3',
            borderRadius: '0.25rem',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              borderRadius: 0,
              background: '#fff',
              boxShadow:
                'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
            '& .MuiOutlinedInput-input': {
              color: '#00000099',
              fontSize: '0.875rem',
              paddingLeft: '0.25rem',
              '&::placeholder': {
                color: 'black',
                opacity: 0.8,
              },
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Caption': {
            color: theme.colors.gray.icon,
            fontSize: theme.typography.pxToRem(theme.size.fontS),
            letterSpacing: theme.typography.pxToRem(0.25),
          },
          '&.Title': {
            fontSize: theme.typography.pxToRem(theme.size.fontM),
            color: theme.colors.text.primary,
            letterSpacing: theme.typography.pxToRem(0.25),
            width: '50ch',
            textOverflow: 'ellipsis',
          },
          '&.LastSearchLabel': {
            padding: '0.5rem 0 0.25rem 1.25rem',
            color: theme.colors.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.pxToRem(theme.size.fontM),
          },
        }),
      },
    },
  },
});
