import { Box, Typography } from '@mui/material';
import api from 'api/api';
import { AppbarSecondary } from 'components/appbar';
import { StepProgress } from 'components/progress';
import { BasicProfileSettings } from 'forms/profile-settings-components';
import { ModalConfirm } from 'modals/confirm/confirm.modal';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { OnboardingWelcomePage } from './components/onboarding-welcome.page';
import { useStyles } from './styles';

const INITIAL_STATE = {
  avatar: '',
  avatarColor: '',
  name: '',
  surName: '',
  email: '',
  previousPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export function InviteBossPage() {
  const tariffPlan = 'free';
  const classes = useStyles();
  const token = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'onboarding']);

  useEffect(() => {
    api
      .post(`/onboarding/verifyusertoken`, {
        token,
      })
      .then(() => console.log('token ok'))
      .catch(() => console.log('furz')); // token invalid or already initiated
  }, []);

  const [page, setPage] = useState<'welcome_page' | 'profile_page'>(
    'welcome_page'
  );
  const [modal, setModal] = useState<boolean>(false);
  const [userData, setUserData] = useState<typeof INITIAL_STATE>(INITIAL_STATE);
  const [progress] = React.useState(50);

  const updateFields = (fields: typeof INITIAL_STATE) =>
    setUserData((prev) => ({ ...prev, ...fields }));

  return (
    <>
      <AppbarSecondary tariffPlan={tariffPlan} userName='Uwe' userImage='Uwe' />
      <Box component='div' className={classes.mainBox}>
        {page !== 'welcome_page' ? (
          <StepProgress
            progress={progress}
            color='qsistant'
            className={classes.progressBox}
          />
        ) : null}
        {page === 'welcome_page' ? (
          <OnboardingWelcomePage
            button={{
              icon: 'not_started',
              color: 'linear-gradient(45deg, #4599BF, #6E358C)',
              label: 'QSISTANT entdecken',
            }}
            onClick={() => setPage('profile_page')}
            title='Hallo, willkommen bei Qsistant.'
            description='Erstelle innerhalb weniger Minuten eine solide Basis für dein
              QM-System oder bringe dein aktuelles auf ein neues Level.'
          />
        ) : (
          <Box
            component='div'
            width='29rem'
            height='100%'
            display='flex'
            flexDirection='column'
            mt='2rem'
          >
            <Box component='div' textAlign='center'>
              <Typography component='h5' className={classes.stepNumberLabel}>
                SCHRITT 1 VON 2
              </Typography>
            </Box>
            <Box component='div'>
              <BasicProfileSettings
                onNext={() => setModal(true)}
                updateFields={updateFields}
                formDisabled={
                  !userData.name || !userData.surName || !userData.email
                }
                {...userData}
              />
            </Box>
          </Box>
        )}
        {modal ? (
          <ModalConfirm
            open={modal}
            onClose={() => setModal(false)}
            onContinue={() => setModal(false)}
            color='qsistant'
            title='Begonnenes einrichten fortsetzen?'
            description='Organisationsmanager "Stefanie Sester" hat bereit mit dem
            Einrichten Ihres neuen QM-Systems begonnen. Möchtest du das
            Einrichten an dieser Stelle fortsetzen?'
            btnNextLabel='Fortsetzen'
          />
        ) : null}
      </Box>
    </>
  );
}
