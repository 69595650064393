import { Box, Button, Typography } from '@mui/material';
import { CheckCard } from 'cards/check-card';

import React from 'react';

import { useStyles } from './styles';
import type { QmsProfileSettingsProps } from './types';

export function QmsProfileSettingsComponent({
  systemType,
  updateFields,
  onNext,
  onBack,
}: QmsProfileSettingsProps) {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.profileSetWrapper}>
      <Box component='div' textAlign='center'>
        <Typography component='h3' className={classes.onboardingFormTitle}>
          Angaben bestehendes Qualitätsmanagementsystem
        </Typography>
        <Typography component='p' className={classes.onboardingFormDescription}>
          Gibt es ein bestehendes QM-System?
        </Typography>
      </Box>
      <Box component='div' display='flex' justifyContent='center' gap='2rem'>
        <CheckCard
          value='new'
          color='#4599BF'
          fieldValue={systemType}
          onClick={() => updateFields({ systemType: 'new' })}
          icon={{
            name: 'add_circle_filled',
            size: 2.25,
            fill: systemType === 'new' ? '#4599bf' : '#00000099',
          }}
          header={
            <>
              <span style={{ color: '#4599BF' }}>Neues</span> QM-System
              erstellen
            </>
          }
        />
        <CheckCard
          value='existing'
          color='#4599BF'
          fieldValue={systemType}
          // onClick={() => updateFields({ systemType: 'existing' })}
          icon={{
            name: 'auto_stories_filled',
            size: 2.25,
            fill: systemType === 'existing' ? '#4599bf' : '#00000099',
          }}
          header={
            <>
              <span style={{ color: '#4599BF' }}>Bestehendes</span> QM-System
              nutzen
            </>
          }
        />
      </Box>
      <Box component='div' className={classes.ipsButtonsBox}>
        <Button
          variant='text'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onBack}
        >
          ZURÜCK
        </Button>
        <Button
          variant='contained'
          color='qsistant'
          className={classes.furtherButtonStyle}
          onClick={onNext}
          disabled={!systemType}
        >
          WEITER
        </Button>
      </Box>
    </Box>
  );
}
