import { styled } from '@mui/styles';

export const chipBox = {
  width: '6rem',
  m: '0.75rem auto',
  borderRadius: '0.25rem',
  p: '0.5625rem 0.75rem',
  background: '#0000000A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const MyBtn = styled('span')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  zIndex: 10,
  '&:hover': {
    background: 'transparent',
    '& .MuiIconButton-root': {
      backgroundColor: (props) => (props.selected ? '#e7f5ee' : 'transparent'),
    },
    '& .ico': {
      color: '#000',
    },
  },
});

export const MyTypo = styled('span')({
  position: 'absolute',
  bottom: 8,
  textAlign: 'center',
  fontSize: '1rem',
  letterSpacing: 0,
});
