import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    wrapper: {
      width: '100%',
      border: '2px solid #323639',
    },
    topBar: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(size.fontS)}`,
      background: '#323639',
      padding: `${typography.pxToRem(size.fontL)} ${typography.pxToRem(
        size.font24L
      )}`,
      minHeight: `${typography.pxToRem(108)}`,
    },
    header: {
      color: '#fff',
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.006rem !important',
    },
    dragDropText: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.1px !important',
      '& > span': {
        color: '#4599bf',
      },
    },
    types: {
      color: '#CCCCCC',
      fontSize: `${typography.pxToRem(size.fontS)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.1px !important',
    },
    fileUplTextBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: `${typography.pxToRem(8)}`,
    },
    btnsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    toolbarWrapper: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: `0 ${typography.pxToRem(size.font24L)}`,
    },
    toolbarNameBox: {
      display: 'flex',
      gap: `0 ${typography.pxToRem(8)}`,
      alignItems: 'center',
    },
    numberOfPages: {
      color: '#fff',
      fontWeight: `${typography.fontWeightMedium} !important`,
    },
    toolbarZoomBox: {
      display: 'flex',
      alignItems: 'center',
      gap: `${typography.pxToRem(8)}`,
    },
  };
});
