import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    description: {
      fontSize: `${typography.pxToRem(14)} !important`,
      color: '#00000099',
      letterSpacing: '0.015rem !important',
      '& > span': {
        fontWeight: `${typography.fontWeightMedium} !important`,
        color: '#000000DE',
      },
      '& > a': {
        color: '#000000DE',
        textDecoration: 'underline',
      },
    },
    modal: {
      width: `${typography.pxToRem(560)} !important`,
    },
    btnsBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: `${typography.pxToRem(24)}`,
    },
    divider: {
      width: '100% !important',
    },
    contentBox: {
      padding: `${typography.pxToRem(24)}`,
      position: 'relative',
    },
    linkBox: {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});
