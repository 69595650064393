import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getDocumentOverviewList,
  getSingleDocument,
} from 'api/services/document-overview.services';

import {
  asyncThunkFetchDocuments,
  asyncThunkFetchSingleDocument,
  initialState,
} from './state';
import type { DocumentOverviewTypes } from './types';

export const fetchDocuments = createAsyncThunk(
  'documents/fetchDocumentOverview',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getDocumentOverviewList();
      dispatch(updateDocuments(response.data));
      dispatch(setDocumentTypes(response.data));
      dispatch(setProcessList(response.data));
      dispatch(setListOfUsers(response.data));
      dispatch(setListOfOrganizations(response.data));
      dispatch(setStatusList(response.data));
      dispatch(handleElementTypesList(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const fetchSingleDocument = createAsyncThunk(
  'documents/fetchSingleDocument',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getSingleDocument(id);
      dispatch(updateSingleDocument(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const documentOverviewSlice = createSlice({
  name: 'documentOverviewSlice',
  initialState,
  reducers: {
    updateDocuments(state, action: PayloadAction<DocumentOverviewTypes[]>) {
      return {
        ...state,
        documents: action.payload,
      };
    },

    updateSingleDocument(state, action: PayloadAction<DocumentOverviewTypes>) {
      return {
        ...state,
        singleDocument: action.payload,
      };
    },

    handleModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        modal: action.payload,
      };
    },

    setDocumentTypes(state, action: PayloadAction<DocumentOverviewTypes[]>) {
      const uniqueValues = [
        ...new Set(action.payload.map((item) => item.documentType.name)),
      ].reduce((acc, val) => {
        const newObj = { value: val, label: val, id: crypto.randomUUID() };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        documentTypes: uniqueValues,
      };
    },

    handleElementTypesList(
      state,
      action: PayloadAction<DocumentOverviewTypes[]>
    ) {
      const uniqueTypes = [
        ...new Set(action.payload.map((item) => item.elementType)),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        elTypesList: uniqueTypes,
      };
    },

    setProcessList(state, action: PayloadAction<DocumentOverviewTypes[]>) {
      const uniqueValues = [
        ...new Set(action.payload.map((item) => item.process.name)),
      ].reduce((acc, val) => {
        const newObj = { value: val, label: val, id: crypto.randomUUID() };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        processList: uniqueValues,
      };
    },

    setListOfUsers(state, action: PayloadAction<DocumentOverviewTypes[]>) {
      const uniqueValues = [
        ...new Set(action.payload.map((item) => item.creator.fullName)),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          avatar: '',
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        listOfUsers: uniqueValues,
      };
    },

    setListOfOrganizations(
      state,
      action: PayloadAction<DocumentOverviewTypes[]>
    ) {
      const uniqueValues = [
        ...new Set(
          action.payload.map((item) => item.creator.organization.name)
        ),
      ].reduce((acc, val) => {
        const newObj = { value: val, label: val, id: crypto.randomUUID() };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        listOfOrganizations: uniqueValues,
      };
    },

    setStatusList(state, action: PayloadAction<DocumentOverviewTypes[]>) {
      const uniqueValues = [
        ...new Set(action.payload.map((item) => item.status)),
      ].reduce((acc, val) => {
        const newObj = {
          value: val,
          label: val,
          id: crypto.randomUUID(),
        };
        acc.push(newObj);
        return acc;
      }, []);
      return {
        ...state,
        statusList: uniqueValues,
      };
    },
  },
  extraReducers: (builder) => {
    asyncThunkFetchDocuments(builder, fetchDocuments);
    asyncThunkFetchSingleDocument(builder, fetchSingleDocument);
  },
});

export const {
  updateDocuments,
  updateSingleDocument,
  handleModal,
  handleElementTypesList,
  setDocumentTypes,
  setProcessList,
  setListOfUsers,
  setListOfOrganizations,
  setStatusList,
} = documentOverviewSlice.actions;

export default documentOverviewSlice.reducer;
