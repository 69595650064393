import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SortFilterBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.typography.pxToRem(20)};
`;

export const FiltersBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.typography.pxToRem(theme.size.fontXS)};
  flex-wrap: wrap;
`;

export const BackBox = styled(Box)`
  display: flex;
  align-items: center;
  top: ${({ theme }) => theme.typography.pxToRem(155.2)};
  position: sticky;
  background-color: ${({ theme }) => theme.colors.gray.background};
  z-index: 5;
`;

export const ActionBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: ${({ theme }) => theme.typography.pxToRem(209.6)};
  padding-top: ${({ theme }) => theme.typography.pxToRem(theme.size.fontL)};
  position: sticky;
  background-color: ${({ theme }) => theme.colors.gray.background};
  z-index: 5;
`;

export const ComparisonCardsBox = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.typography.pxToRem(theme.size.fontS)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(theme.size.fontS)};
  '@media(max-width: 1600px)': {
    flexDirection: 'column',
  },
`;
