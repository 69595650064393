import {
  addNotification,
  removeNotification,
} from 'store/common/notifications/notifications-slice';
import type { Snack } from 'store/common/notifications/types';
import { useAppDispatch } from 'store/hook';

export const useNotification = (): ((notification: Snack) => void) => {
  const dispatch = useAppDispatch();
  const handleRemove = (id: string) => dispatch(removeNotification(id));

  return ({
    autoDelete = true,
    button,
    type = 'notification',
    severity = 'success',
    anchor = { horizontal: 'right', vertical: 'top' },
    id = crypto.randomUUID(),
    ...props
  }: Snack) => {
    const data: Snack = {
      autoDelete,
      type,
      id,
      severity,
      anchor,
      button: {
        text: button?.text,
        onClick:
          (() => {
            button?.onClick();
            handleRemove(id);
          }) || (() => handleRemove(id)),
        color: button?.color || 'qsistant',
      },
      ...props,
    };

    dispatch(addNotification(data));
  };
};
