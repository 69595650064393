import { Box, Button } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';
import { useLocation } from 'react-router-dom';

export function BottomMenu({ sidebarSqueezed, onOpen }) {
  const location = useLocation();
  return (
    <Box component='div'>
      {/* <Button
        variant='text'
        startIcon={<Icon name='person_add' size={1.375} fill='#4599BF' />}
        sx={{
          width: '100%',
          borderRadius: 0,
          textTransform: 'capitalize',
          alignItems: 'flex-start',
          padding: '1rem 0',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        {sidebarSqueezed ? '' : 'Teammitglieder einladen'}
      </Button> */}
      <Button
        startIcon={
          <Icon
            name={ICON.FEEDBACK_OUTLINED}
            size={1.375}
            fill={
              location.pathname.includes('product_development')
                ? '#45BD81'
                : location.pathname.includes('management')
                ? '#B4A656'
                : '#4599bf'
            }
          />
        }
        variant='text'
        onClick={onOpen}
        sx={{
          width: '100%',
          borderRadius: 0,
          textTransform: 'capitalize',
          marginBottom: '0.875rem',
          padding: '1rem 0',
          color: 'rgba(0, 0, 0, 0.6)',
          '& .MuiButton-startIcon': {
            ml: '4px',
          },
        }}
      >
        {sidebarSqueezed ? '' : 'Fehler/Feedback melden'}
      </Button>
    </Box>
  );
}
