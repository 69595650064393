import { Box, Typography } from '@mui/material';
import { Icon } from 'components/icon';
import { LordIcon } from 'components/lord-icon';

import React from 'react';

import { useStyles } from '../styles';

export function AlmostDoneComponent() {
  const classes = useStyles();
  return (
    <Box component='div' className={classes.almostDoneWrapper}>
      <Box component='div' className={classes.almostDoneContentBox}>
        <Box component='div' className={classes.almostDoneIconBox}>
          <Icon name='img_coming_soon' />
          <Icon name='logo_qsistant' />
        </Box>
        <Box component='div' textAlign='center'>
          <Typography component='h3' className={classes.almostDoneTitle}>
            Fast geschafft
          </Typography>
          <LordIcon
            trigger='loop'
            delay='500'
            style={{ width: '52px', height: '52px' }}
          />
          <Typography component='h6' className={classes.almostDoneDescription}>
            Ihr neuer Qsistant Workpace wird gerade von ihrem
            Organisationsmanager eingerichtet.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
