import { generateExtraReducers } from 'utils/utils';

import type { SupportingDocumentsSliceTypes } from './types';

export const initialState: SupportingDocumentsSliceTypes = {
  documents: [],
  singleDocument: null,
  versions: [],
  modal: false,
  statusList: [],
  listOfOrganizations: [],
  listOfUsers: [],
  status: null,
  error: null,
  versionStatus: null,
  versionError: null,
  singleStatus: null,
  singleError: null,
  singleLoading: false,
};

export const asyncThunkFetchSupporting = generateExtraReducers({
  initialStateProp: 'documents',
  statusKey: 'status',
  errorKey: 'error',
});

export const asyncThunkFetchSingleDocument = generateExtraReducers({
  initialStateProp: 'singleDocument',
  statusKey: 'singleStatus',
  errorKey: 'singleError',
  loadingKey: 'singleLoading',
});

export const asyncThunkFetchVersionDocuments = generateExtraReducers({
  initialStateProp: 'versions',
  statusKey: 'versionStatus',
  errorKey: 'versionError',
});
