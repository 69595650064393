export const stepsBtn = {
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  background: '#fff',
  boxShadow:
    '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  '&:disabled': {
    '& svg': {
      color: 'red', // Задаем красный цвет для иконки, когда кнопка задизейблена
    },
  },
};

export const breadcrumbsLinks = {
  textDecoration: 'none',
  fontSize: '1.25rem',
};

export const headerRow = {
  height: '34px',
  '& > *': { p: 0, color: '#000000', opacity: 0.54 },
};

export const headerCell = {
  p: 0,
  verticalAlign: 'top',
};

export const tableCell = {
  p: 0,
  height: '4rem',
};
