import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    wrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: `${typography.pxToRem(size.fontXL)}`,
    },
    bottomInputsBox: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      gap: `${typography.pxToRem(size.font2XL)}`,
    },
    tooltipBox: {
      display: 'flex',
      alignItems: 'center',
      gap: `${typography.pxToRem(5)}`,
    },
  };
});
