import { Box, IconButton, Tooltip } from '@mui/material';
import {
  addToFavourite,
  removeFromFavourite,
} from 'api/services/document-overview.services';
import { ContextMenu } from 'components/context-menu';
import { Icon } from 'components/icon';
import { routes } from 'routes/routes';
import type { DocumentOverviewTypes } from 'store/qsistant/document-overview/types';

import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type IconBoxProps = {
  item: DocumentOverviewTypes;
};

export function IconBox({ item }: IconBoxProps) {
  const { t } = useTranslation('document');
  const contextIcon = useRef<HTMLButtonElement>(null);
  const [favourite, setFavourite] = useState(item.isFavourite);
  const [showMore, setShowMore] = useState<boolean>(false);

  const handleFavourite = () => {
    if (!favourite) {
      addToFavourite(item.id);
    } else {
      removeFromFavourite(item.id);
    }
    setFavourite(!favourite);
  };

  const openDocumentInNewTab = () => {
    const url = routes().singleDocumentOverview.replace(
      ':id',
      item.id.toString()
    );
    setShowMore(false);
    window.open(url, '_blank');
  };

  const menuContextContent = useMemo(
    () => [
      {
        id: 1,
        name: 'edit',
        label: t('document:edit'),
        onClick: undefined,
        show: true,
      },
      {
        id: 2,
        name: 'restore',
        label: t('document:newrevision'),
        onClick: undefined,
        show: true,
      },
      {
        id: 234,
        name: 'description',
        label: t('document:fillout'),
        onClick: undefined,
        show: item.documentType?.fillable,
      },
      {
        id: 3,
        name: 'open_in_new',
        label: t('document:openinnewtab'),
        onClick: () => openDocumentInNewTab(),
        show: true,
      },
    ],
    [item.documentType?.fillable]
  );

  return (
    <>
      <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip
          placement='bottom'
          title={
            favourite
              ? t('document:removefavourite')
              : t('document:addfavourite')
          }
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleFavourite();
            }}
          >
            <Icon
              name={favourite ? 'bookmark_filled' : 'bookmark'}
              size={1.5}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('document:print')} placement='bottom'>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon name='print' size={1.5} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('document:more')} placement='bottom'>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setShowMore((prev) => !prev);
            }}
            ref={contextIcon}
          >
            <Icon name='more' size={1.5} />
          </IconButton>
        </Tooltip>
      </Box>
      {showMore ? (
        <ContextMenu
          open={showMore}
          anchorEl={contextIcon}
          onClose={() => setShowMore(false)}
          dataList={menuContextContent}
        />
      ) : null}
    </>
  );
}
