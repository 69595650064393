// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tox.tox-tinymce {
  border-radius: 0;
  border: 0;
}

.tox .tox-toolbar {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
}

.tox .tox-sidebar-wrap {
  padding: 0 2rem;
}

/* .tox-tinymce .tox-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  padding: 8px;
  z-index: 9999;
} */

.header {
  font-size: 2rem !important;
  color: #000000cc;
}

.description {
  font-size: 1.25rem !important;
  color: #00000066;
  font-weight: 500 !important;
  letter-spacing: 0.15px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/tinymce-editor/editor.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;;;;;GAOG;;AAEH;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,2BAA2B;EAC3B,iCAAiC;AACnC","sourcesContent":[".tox.tox-tinymce {\n  border-radius: 0;\n  border: 0;\n}\n\n.tox .tox-toolbar {\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);\n  padding: 0.5rem;\n}\n\n.tox .tox-sidebar-wrap {\n  padding: 0 2rem;\n}\n\n/* .tox-tinymce .tox-tooltip {\n  background-color: #fff;\n  border: 1px solid #ccc;\n  color: #333;\n  font-size: 14px;\n  padding: 8px;\n  z-index: 9999;\n} */\n\n.header {\n  font-size: 2rem !important;\n  color: #000000cc;\n}\n\n.description {\n  font-size: 1.25rem !important;\n  color: #00000066;\n  font-weight: 500 !important;\n  letter-spacing: 0.15px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
