import { Box, Button, Typography } from '@mui/material';
import { TableAuditCard } from 'cards/table-audit-card';
import { Icon } from 'components/icon';

import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import type {
  TableAuditSelectedOptiosTypes,
  TableListingAuditsStepProps,
} from './types';

const emptyAudit = {
  department: '',
  auditor: '',
  date: new Date(),
  label: 'Prozess 1',
  id: '0',
};

const auditors = [
  { id: 0, label: 'Sirius Neißt (Extern)', value: 'Sirius' },
  { id: 1, label: 'Florian', value: 'Flo' },
  { id: 2, label: 'Frederik', value: 'Freddy' },
];

export function TableListingAuditStep({
  stepId,
  departments,
  createDepartment,
  setOption,
  toNextStep,
  selectedOption,
}: TableListingAuditsStepProps) {
  const classes = useStyles();
  const [localAudits, setLocalAudits] = useState([emptyAudit]);

  useEffect(() => {
    if (selectedOption.length > 0) {
      setLocalAudits(selectedOption);
    }
  }, [selectedOption]);

  const handleChange = (
    changedAudit: TableAuditSelectedOptiosTypes,
    editIndex: number
  ) => {
    setLocalAudits((prev) =>
      prev.map((audit, i) => {
        if (editIndex === i) {
          return changedAudit;
        }
        return audit;
      })
    );
  };

  const addNewAudit = () => {
    setLocalAudits([
      ...localAudits,
      {
        ...emptyAudit,
        label: `Process ${localAudits.length + 1}`,
        id: crypto.randomUUID(),
      },
    ]);
  };

  return (
    <>
      <Typography component='h4' className={classes.actionTitle}>
        Fülle bitte Prozesse, Abteilungen, Auditor und geplantes Datum{' '}
        <span style={{ color: '#6E358C' }}>für Audits </span>
        aus.
      </Typography>
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        gap='1.5rem'
        mb='2rem'
      >
        {localAudits.map((audit, i) => (
          <TableAuditCard
            key={audit.id}
            departments={departments}
            auditors={auditors}
            value={audit}
            addNewOption={(val) => createDepartment(val, stepId)}
            onChange={(v) => handleChange(v, i)}
            {...audit}
          />
        ))}
      </Box>
      <Button
        variant='text'
        color='documentSetup'
        startIcon={<Icon name='add' fill='#6E358C' size={1.5} />}
        onClick={addNewAudit}
      >
        AUSWAHL HINZUFÜGEN
      </Button>
      <Box component='div' display='flex' justifyContent='flex-end' mt='2rem'>
        <Button
          variant='contained'
          color='documentSetup'
          disabled={
            Boolean(!localAudits[0].department) || !localAudits[0].auditor
          }
          onClick={() => {
            setOption(localAudits, stepId);
            toNextStep(localAudits, stepId);
          }}
        >
          EINGABEN SPEICHERN & WEITER
        </Button>
      </Box>
    </>
  );
}
