import { Box, Button, TextField, Typography } from '@mui/material';

import React from 'react';

import { useStyles } from './styles';
import type { EvidenceLocationStepProps } from './types';

export function EvidenceLocationStep({
  stepId,
  selectedOption,
  setOption,
  onSave,
  progress,
}: EvidenceLocationStepProps) {
  const classes = useStyles();

  return (
    <>
      <Typography component='h4' className={classes.actionTitle}>
        Wo werden Nachweise für die internen Audits gespeichert?
      </Typography>
      <TextField
        fullWidth
        multiline
        value={selectedOption}
        onChange={(e) => setOption(e.target.value, stepId)}
        variant='filled'
        color='documentSetup'
        label='Erklärung Speicherort Nachweise eingeben'
      />
      <Box component='div' display='flex' justifyContent='flex-end' mt='2rem'>
        <Button
          variant='contained'
          disabled={!selectedOption || progress !== 100}
          color='documentSetup'
          onClick={() => onSave(selectedOption, stepId)}
        >
          EINGABE SPEICHERN & WEITER
        </Button>
      </Box>
    </>
  );
}
