import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Classes =
  | 'userMenuWrapper'
  | 'profileInfoWrapper'
  | 'nameStyle'
  | 'positionStyle'
  | 'exitText'
  | 'exitBox'
  | 'avatarStyle'
  | 'subLabel'
  | 'listItem'
  | 'revisionContext'
  | 'bottomBtnBox'
  | 'revisionContentBox'
  | 'revisionInfoText'
  | 'textBox'
  | 'descriptionBox'
  | 'avatarNameBox'
  | 'revisionAvatar';

export const useStyles = makeStyles<Theme, object, Classes>(
  ({ typography, colors, size }) => {
    return {
      userMenuWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: `${typography.pxToRem(size.fontL)}`,
        padding: `${typography.pxToRem(size.font_8)} 0`,
      },
      profileInfoWrapper: {
        display: 'flex',
        gap: `${typography.pxToRem(size.font_8)}`,
        padding: `${typography.pxToRem(size.fontXS)}`,
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          background: colors.gray.background,
        },
      },
      nameStyle: {
        fontSize: `${typography.pxToRem(size.fontL)} !important`,
        letterSpacing: '0 !important',
        color: '#373a3c',
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
      positionStyle: {
        fontSize: `${typography.pxToRem(12.8)} !important`,
        letterSpacing: '0 !important',
        color: colors.gray.secondary,
      },
      exitText: {
        fontSize: `${typography.pxToRem(size.fontL)} !important`,
        letterSpacing: '0 !important',
        color: '#373a3c',
        fontWeight: `${typography.fontWeightMedium} !important`,
      },
      exitBox: {
        display: 'flex',
        gap: `${typography.pxToRem(size.font_8)}`,
        padding: `0 ${typography.pxToRem(size.fontXS)}`,
        alignItems: 'center',
        cursor: 'pointer',
        height: '100%',
        '&:hover': {
          background: colors.gray.background,
        },
      },
      avatarStyle: {
        width: `${typography.pxToRem(64)} !important`,
        height: `${typography.pxToRem(64)} !important`,
      },
      subLabel: {
        color: colors.gray.icon,
        letterSpacing: '0.4px !important',
        fontSize: `${typography.pxToRem(size.fontXS)} !important`,
      },
      listItem: {
        '& > span': {
          fontWeight: `${typography.fontWeightRegular} !important`,
          color: colors.text.primary,
          letterSpacing: '0.009rem !important',
          lineHeight: '1.5rem !important',
          textTransform: 'capitalize',
        },
      },
      revisionContext: {
        padding: `${typography.pxToRem(size.fontL)}`,
        width: `${typography.pxToRem(933)}`,
        height: `${typography.pxToRem(220)}`,
        display: 'flex',
        flexDirection: 'column',
        // gap: `${typography.pxToRem(size.font24L)}`,
        justifyContent: 'space-between',
      },
      bottomBtnBox: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      revisionContentBox: {
        background: '#ECF5F9',
        borderRadius: `${typography.pxToRem(size.font_4)}`,
        padding: `${typography.pxToRem(size.font_8)}`,
        display: 'flex',
        flexDirection: 'column',
      },
      revisionInfoText: {
        fontSize: `${typography.pxToRem(size.fontM)} !important`,
        color: colors.text.primary,
      },
      textBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${typography.pxToRem(size.font_8)}`,
      },
      descriptionBox: {
        display: 'flex',
        gap: `${typography.pxToRem(size.font_8)}`,
        alignItems: 'center',
        padding: `${typography.pxToRem(size.font_8)}`,
        paddingLeft: `${typography.pxToRem(size.fontM)}`,
      },
      avatarNameBox: {
        display: 'flex',
        gap: `${typography.pxToRem(size.font_4)}`,
        alignItems: 'center',
      },
      revisionAvatar: {
        width: `${typography.pxToRem(36)} !important`,
        height: `${typography.pxToRem(36)} !important`,
      },
    };
  }
);

export const iconStyle = {
  border: '0.6rem solid #f5f5f5',
  borderRadius: '50%',
  background: '#f5f5f5',
};
