export const saveBtn = {
  fontSize: '0.875rem',
  ml: '1.5rem',
  color: '#fff',
  background: '#45BD81',
  '&:hover': { background: '#45BD81' },
  letterSpacing: '0.078rem',
  whiteSpace: 'nowrap',
  '&:disabled': {
    background: '#45BD81',
    opacity: 0.54,
    cursor: 'not-allowed',
  },
};
