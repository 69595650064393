import React from 'react';
import { ReactSVG } from 'react-svg';

import clsx from 'clsx';

import './styles.css';
import type { IconComponentProps } from './types';

export function IconComponent({
  name,
  size,
  fill = '#00000099',
  stroke,
  onClick,
  style,
  height,
  width,
  className,
}: IconComponentProps) {
  return (
    <ReactSVG
      src={`/assets/icons/${name}.svg`}
      style={style}
      onClick={onClick}
      className={clsx('icon', className)}
      color={stroke}
      beforeInjection={(svg) => {
        svg.classList.add('svg-class-name');
        svg.setAttribute(
          'style',
          `height: ${height || size}rem; width: ${
            width || size
          }rem; fill: ${fill}; stroke: ${stroke}`
        );
      }}
    />
  );
}
