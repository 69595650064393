import { Fab, useMediaQuery } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React from 'react';

import { FabButtonProps } from './button.types';

export function FabButtonComponent({
  onClick,
  className,
  label,
  disabled,
}: FabButtonProps) {
  const largeBreakpoint = useMediaQuery('(max-width:1720px)');
  return (
    <Fab
      variant='extended'
      size='large'
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        name={ICON.PLUS}
        size={1.5}
        fill='#fff'
        style={{ paddingBottom: largeBreakpoint ? '3px' : 0 }}
      />
      {label}
    </Fab>
  );
}
