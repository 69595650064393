export const wrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
};

export const buttonStyle = {
  letterSpacing: '0.07rem',
  width: 'max-content',
};
