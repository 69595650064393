import { Appbar, MinAppbar } from 'components/appbar';
import { useAppDispatch, useAppSelector } from 'store/hook';

import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { AuthContext } from './components/login/AuthContext';
import Login from './components/login/Login';
import NewPassword from './components/login/NewPassword';
import { routesData } from './routes/routes-data';

type IUser = {
  tokenExpiry: string;
};

type IState = {
  isAuthenticated: boolean;
  user: IUser | null;
  token: string | null;
};

type IAction = {
  type: string;
  token?: string;
  user?: IUser | null;
};

type ComponentProps = {
  pathName: string;
};

type RouteData = {
  path: string;
  Component: React.ComponentType<ComponentProps>;
  children?: any[];
  pathName?: string;
};

const routesDat: RouteData[] = [...routesData];

const validateUserToken = (user: IUser | null): boolean => {
  if (!user) return false;
  return new Date(user.tokenExpiry) >= new Date();
};

const initialState: IState = {
  isAuthenticated:
    !!localStorage.getItem('token') ||
    validateUserToken(JSON.parse(localStorage.getItem('user') as string)),
  user: JSON.parse(localStorage.getItem('user') as string),
  token: localStorage.getItem('token'),
};

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'LOGIN':
      if (action.token && action.user) {
        localStorage.setItem('user', JSON.stringify(action.user));
        localStorage.setItem('token', action.token);
        return {
          ...state,
          isAuthenticated: true,
          user: action.user,
          token: action.token,
        };
      }
      return state;

    case 'LOGOUT':
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'UPDATE_USER':
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

export default function App() {
  const location = useLocation();
  const dispatcher = useAppDispatch();
  const { isMinAppbar } = useAppSelector((state) => ({
    isMinAppbar: state.commonSlice.isMinAppbar,
  }));

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const authContext = React.useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );
  React.useEffect(() => () => {}, [state.isAuthenticated]);

  return (
    <AuthContext.Provider value={authContext}>
      {!state.isAuthenticated &&
      !window.location.href.includes('onboarding') ? (
        window.location.href.includes('newpassword') ? (
          <NewPassword />
        ) : (
          <Login />
        )
      ) : (
        <>
          {location.pathname.includes('onboarding') ||
          location.pathname === '/documents_setup' ? null : isMinAppbar ? (
            <MinAppbar />
          ) : (
            <Appbar />
          )}
          <Routes>
            {routesDat.map(({ path, Component, children, pathName }) => (
              <Route
                key={path}
                path={path}
                element={<Component pathName={pathName!} />}
              >
                {children?.map(
                  ({
                    path: subPath,
                    Component: SubComponent,
                    pathName: subPathName,
                    parentRoute,
                    documentType,
                    parentTab,
                    homePage,
                  }) => (
                    <Route
                      key={subPath}
                      path={subPath}
                      element={
                        <SubComponent
                          pathName={subPathName!}
                          parentTab={parentTab ?? undefined}
                          homePage={homePage ?? undefined}
                          parentRoute={parentRoute ?? undefined}
                          documentType={documentType ?? undefined}
                          parentPathName={pathName}
                        />
                      }
                    />
                  )
                )}
              </Route>
            ))}
          </Routes>
        </>
      )}
    </AuthContext.Provider>
  );
}
