import { Theme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    mainBox: {
      minHeight: 'calc(100vh - 6rem)',
      backgroundColor: '#F5F5F5',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '6rem',
    },
    progressBox: {
      width: '100%',
      position: 'fixed',
      zIndex: 1,
      top: '6rem',
      left: 0,
      right: 0,
    },
    stepNumberLabel: {
      fontSize: '0.875rem !important',
      textTransform: 'uppercase !important',
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.08rem !important',
    } as CSSProperties & { textTransform: 'uppercase !important' },
    almostDoneWrapper: {
      backgroundColor: '#F5F5F5',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 6rem)',
    },
    almostDoneContentBox: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '60%',
      width: '34rem',
    },
    almostDoneIconBox: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    almostDoneTitle: {
      color: '#00000099',
      fontSize: '2.125rem !important',
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
    },
    almostDoneDescription: {
      color: '#00000066',
      fontSize: '1.5rem !important',
      fontWeight: `${typography.fontWeightRegular} !important`,
      letterSpacing: '0.006rem !important',
    },
    welcomeBoxWrapper: {
      width: '100vw',
      height: 'calc(100vh - 6rem)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f1ebf4',
    },
    welcomeContentBox: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      opacity: 1,
      padding: '2.5rem',
      width: '45rem',
      minHeight: '34rem',
      border: '0.25rem solid #FFFFFF40',
      boxShadow: '1rem 1rem 3rem #00000008',
      borderRadius: '3.3rem',
      backdropFilter: 'blur(1.625rem)',
      background: 'rgba(255,255,255,0.25)',
      '@media(max-width: 650px)': {
        width: '40rem',
      },
      '@media(max-width: 576px)': {
        width: '35rem',
      },
    },
    videoStyle: {
      width: '100vw',
      height: 'calc(100vh - 6rem)',
      objectFit: 'cover',
      opacity: 0.2,
    },
    welcomeTitleStyle: {
      fontSize: '2.125rem !important',
      color: '#000000DE',
      letterSpacing: '0 !important',
      fontWeight: `${typography.fontWeightLight} !important`,
      marginBottom: '1rem !important',
    },
    welcomeDescriptionStyle: {
      color: '#00000066',
      fontSize: '1.5rem !important',
      letterSpacing: '0.013rem !important',
      fontWeight: `${typography.fontWeightRegular} !important`,
    },
    mainBoxDocSetup: {
      minHeight: 'calc(100vh - 6rem)',
      backgroundColor: '#F5F5F5',
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      padding: '1rem 2rem',
      marginTop: '6rem',
    },
  };
});
