import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import api from 'api/api';
import { MultipleAutocomplete } from 'components/autocomplete/multiple-autocomplete';
import type { AutocompleteOption } from 'components/autocomplete/multiple-autocomplete/multiple-autocomplete.types';
import { AvatarUploadImage } from 'components/avatar-upload-image';
import { LoadingButton } from 'components/button';
import { Filter } from 'components/filter';
import { Icon } from 'components/icon';
import { PackageInfoProfile } from 'components/package-info';
import { BasicSelect } from 'components/select/basic-select';
import { Status } from 'components/status';
import { ICON } from 'enum/icons.enum';
import { ModalConfirm } from 'modals/confirm';
import {
  handleActiveList,
  handleHasChanges,
  handleModalHasChanges,
  handleUpgradePlanModal,
} from 'store/common/common-slice';
import {
  fetchOrganization,
  saveOrganization,
} from 'store/company-profile/company-profile.slice';
import type { CompanyDataTypes } from 'store/company-profile/types';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { PageWrapper } from 'wrappers/page-wrapper/page-wrapper';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { capitalize } from 'lodash';

import { industryTypes } from '../../qsistant/qsistant.constants';
import { useStyles } from './styles';

export function CompanyProfilePage({ pathName, parentTab, homePage }) {
  const { t } = useTranslation(['organization', 'common']);
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const tariff = 'regulatory';
  const {
    companyData,
    hasChanges,
    modalHasChanges,
    discardedRoute,
    saveStatus,
  } = useAppSelector((state) => ({
    companyData: state.companyProfileReducer.data,
    hasChanges: state.commonSlice.hasChanges,
    modalHasChanges: state.commonSlice.modalHasChanges,
    discardedRoute: state.commonSlice.discardedRoute,
    saveStatus: state.companyProfileReducer.saveStatus,
  }));

  const lowScreen = useMediaQuery('(max-width:900px)');
  const [data, setData] = useState<CompanyDataTypes>(null);

  const [standards, setStandards] = useState<AutocompleteOption[]>([]);

  useEffect(() => {
    dispatcher(fetchOrganization()).then(({ payload }) => {
      setStandards(payload.additionalInformation.associatedStandards);
    });
  }, [dispatcher]);

  useEffect(() => {
    if (companyData.organization !== null) {
      setData(companyData);
    }
  }, [companyData]);

  const updateFields = (fields: Partial<CompanyDataTypes>) => {
    setData((prev) => ({ ...prev, ...fields }));
    dispatcher(handleHasChanges(true));
  };

  const handleSaveData = () => {
    if (typeof data.logo === 'object') {
      const formData = new FormData();
      formData.append('file', data.logo);
      api
        .post('/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((result) => {
          dispatcher(saveOrganization({ ...data, logo: result?.data?.uuid }));
          dispatcher(handleHasChanges(false));
        });
    } else {
      dispatcher(saveOrganization({ ...data }));
      dispatcher(handleHasChanges(false));
    }
  };

  const navigateToPersistedRoute = () => {
    dispatcher(handleHasChanges(false));
    dispatcher(handleModalHasChanges(false));
    if (discardedRoute.tab && discardedRoute.item) {
      dispatcher(
        handleActiveList({
          parent: discardedRoute.tab,
          item: discardedRoute.item,
        })
      );
      navigate(discardedRoute.route && discardedRoute.route);
    } else {
      navigate(discardedRoute.route && discardedRoute.route);
    }
  };

  return (
    <PageWrapper
      pageLabel={pathName}
      pathName={pathName}
      parentTab={parentTab}
      homePage={homePage}
    >
      {!data ? (
        <CircularProgress color='management' />
      ) : (
        <Box component='div' className={classes.wrapper}>
          <Box
            component='div'
            width='60%'
            gap={lowScreen ? '3rem' : '6.7rem'}
            display='flex'
          >
            <Box component='div' textAlign='center'>
              <Typography
                component='h6'
                className={classes.sectionTitle}
                mb='1.5rem'
              >
                {t('organization:logo')}
              </Typography>
              <AvatarUploadImage
                avatarSize='7rem'
                color='management'
                avatar={data.logo}
                onChange={(e) => updateFields({ logo: e.target.files[0] })}
                onRemove={() => updateFields({ logo: '' })}
              />
            </Box>
            <Box component='div' className={classes.sectionBox}>
              <Typography component='h6' className={classes.sectionTitle}>
                {t('organization:generalinformation')}
              </Typography>
              <TextField
                fullWidth
                label={t('organization:nameoforganization')}
                color='management'
                value={data.name}
                onChange={(e) => updateFields({ name: e.target.value })}
              />
              <BasicSelect
                label={t('organization:sector')}
                color='management'
                options={industryTypes}
                value={data.industry}
                onChange={(e) => updateFields({ industry: e.target.value })}
              />
              <MultipleAutocomplete
                fullWidth
                label={t('organization:assignedstandards')}
                value={data.associatedStandards}
                onChange={(e, val) =>
                  updateFields({
                    associatedStandards: val,
                  })
                }
                options={standards}
                getOptionLabel={(option) => option.label}
                color='management'
              />
              <Box component='div' mb='2rem'>
                <Typography
                  component='p'
                  className={classes.sectionLabel}
                  mb='1rem'
                >
                  {t('organization:currentnumberofemployees')}
                </Typography>
                <Filter
                  label={data.maxUsers - data.availableLicenses}
                  value='10'
                  color='management'
                />
              </Box>
              <Box component='div' display='flex' gap='5rem'>
                <Box component='div'>
                  <Typography
                    component='h6'
                    className={classes.sectionTitle}
                    mb='1.5rem'
                  >
                    {t('organization:packageinformation')}
                  </Typography>
                  <Box component='div' mb='1.5rem'>
                    <Typography
                      component='p'
                      className={classes.sectionLabel}
                      mb='0.5rem'
                    >
                      {t('organization:actualpackage')}
                    </Typography>
                    <Status
                      statusText='Free'
                      status='free'
                      style={{
                        textTransform: 'capitalize',
                        marginBottom: '0.5rem',
                      }}
                    />
                    <Button
                      variant='outlined'
                      color='management'
                      className={classes.btnStyle}
                      startIcon={
                        <Icon name={ICON.CREDIT_CARD} fill='inherit' />
                      }
                      onClick={() => dispatcher(handleUpgradePlanModal(true))}
                    >
                      {t('organization:changepackage')}
                    </Button>
                  </Box>
                  <Box component='div'>
                    <Typography
                      component='p'
                      className={classes.sectionLabel}
                      mb='0.5rem'
                    >
                      {t('organization:usedlicenses')}
                    </Typography>
                    <Filter
                      label={`${data.usedLicenses}/${data.maxUsers}`}
                      value={`${data.usedLicenses}/${data.maxUsers}`}
                      color='management'
                    />
                  </Box>
                </Box>
                <PackageInfoProfile
                  color='management'
                  title={t('organization:packagelabel', {
                    tariff: capitalize(tariff),
                  })}
                  onClick={() => dispatcher(handleUpgradePlanModal(true))}
                />
              </Box>
            </Box>
          </Box>
          <Box component='div' className={classes.btnBox}>
            <LoadingButton
              label={t('common:savechanges')}
              color='management'
              isLoading={saveStatus === 'pending'}
              loaderSize={1.5}
              disabled={!hasChanges}
              className={classes.btnStyle}
              onClick={handleSaveData}
              startIcon={
                <Icon
                  name={!hasChanges ? ICON.DONE : ICON.SAVE}
                  fill='#fff'
                  size={1.5}
                />
              }
            />
          </Box>
        </Box>
      )}
      {modalHasChanges && (
        <ModalConfirm
          open={modalHasChanges}
          onClose={() => dispatcher(handleModalHasChanges(false))}
          onContinue={navigateToPersistedRoute}
          color='management'
          title={t('organization:modalhaschangestitle')}
          description={t('organization:modalhaschangesdesc')}
          btnNextLabel={t('common:discard')}
        />
      )}
    </PageWrapper>
  );
}
