import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NotificationsSlice, Snack } from './types';

const initialState: NotificationsSlice = {
  snacks: [],
};

export const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Snack>) {
      return {
        ...state,
        snacks: [action.payload, ...state.snacks],
      };
    },

    removeNotification(state, action: PayloadAction<string>) {
      const filtered = state.snacks.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        snacks: filtered,
      };
    },
  },
});

export const { addNotification, removeNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
