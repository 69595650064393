import { Box, Button, Fab } from '@mui/material';
import { Icon } from 'components/icon';
import { EmptyList } from 'components/list';
import { SortSelect } from 'components/select/sort-select';
import { Table } from 'components/table';
import { TableSkeleton } from 'components/table-skeleton';
import { CustomerModal } from 'modals/customer-action';
import {
  addCustomer,
  fetchCustomers,
  handleModal,
} from 'store/administration/customer/customer-slice';
import { sortByCriteria } from 'utils/utils';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { customerTypeOptions, sortProcessOptions } from '../constants';
import useTableCells from './table-cells.hook';

export function CustomerOverviewComponent() {
  const { t } = useTranslation(['sort', 'customer']);
  const dispatch = useDispatch();

  const {
    customers,
    modal,
    addCustomerError: errors,
  } = useSelector(
    (state) => ({
      customers: state.customerReducer.customers,
      modal: state.customerReducer.modal,
      addCustomerError: state.customerReducer.addCustomerError,
    }),
    shallowEqual
  );
  const { cells } = useTableCells([], errors);

  const [sortBy, setSortBy] = useState('default');
  const [type, setType] = useState('customer');
  const [name, setName] = useState('');
  const [filtered, setFiltered] = useState(customers);
  const [list, setList] = useState(['']);

  const filterArrayByOptions = (arrayToFilter, options) => {
    if (options.length === 1) {
      return arrayToFilter;
    }
    return arrayToFilter.filter((doc) => options.includes(doc.category.name));
  };

  const createNewCustomer = (cName, cType) => {
    const data = {
      name: cName,
      type: cType,
      id: 0,
    };
    dispatch(addCustomer(data));
    setName('');
    setType('');
    dispatch(handleModal(false));
  };

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  useEffect(() => {
    const filtersRes = filterArrayByOptions(customers, list);
    const sorting = sortByCriteria(filtersRes, sortBy);
    setFiltered(sorting);
  }, [list, customers, sortBy]);

  return (
    <Box component='div'>
      <Box component='div' display='flex' justifyContent='space-between'>
        <Box component='div' display='flex' alignItems='center' gap='0.625rem'>
          <Button
            variant='text'
            color='management'
            disabled={list.length === 1}
            sx={{ textTransform: 'none' }}
            onClick={() => setList([''])}
          >
            {t('sort:resetfilter')}
          </Button>
        </Box>
        <SortSelect
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          options={sortProcessOptions}
          primaryColor='management'
        />
      </Box>
      {customers.length > 0 && customers[0].id !== 0 ? (
        <Table cells={cells} data={filtered} />
      ) : customers.length !== 0 ? (
        <EmptyList
          title={t('document:emptydocumenttitle')}
          text={t('document:emptydocumenttext')}
          buttonLabel={t('document:createdocument').toUpperCase()}
          onClick={() => dispatch(handleModal(true))}
        />
      ) : (
        <Box component='div' mt={2}>
          {cells.map((item) => (
            <TableSkeleton key={item.id} />
          ))}
        </Box>
      )}
      {customers.length > 0 && customers[0].id !== 0 ? (
        <Box component='div' display='flex' justifyContent='center'>
          <Fab
            variant='extended'
            size='large'
            className='AbsolutePosition management'
            onClick={() => dispatch(handleModal(true))}
          >
            <Icon name='add' size={1.5} fill='white' />
            {t('customer:newcustomer').toUpperCase()}
          </Fab>
        </Box>
      ) : (
        ''
      )}
      {modal ? (
        <CustomerModal
          icon={{ name: 'new_label_filled' }}
          open={modal}
          errors={errors}
          onClose={() => dispatch(handleModal(false))}
          header={t('customer:newcustomer')}
          color='management'
          name={name}
          type={type}
          onChangeName={(e) => setName(e.target.value)}
          onChangeType={(e) => setType(e.target.value)}
          options={customerTypeOptions}
          onAction={(label, cat) => createNewCustomer(label, cat)}
        />
      ) : null}
    </Box>
  );
}
