import { IconButton, TextField } from '@mui/material';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React, { useState } from 'react';

import type { InputPasswordProps } from './types';

export function InputPasswordComponent({
  variant,
  fullWidth = false,
  value,
  label,
  onChange,
  className,
  onKeyUp,
  color,
  id,
  name = 'basic_password_input',
}: InputPasswordProps) {
  const [hidden, setHidden] = useState<boolean>(true);
  return (
    <TextField
      id={id}
      color={color}
      variant={variant}
      label={label}
      fullWidth={fullWidth}
      value={value}
      autoComplete='off'
      className={className}
      onChange={onChange}
      name={name}
      onKeyUp={onKeyUp}
      type={hidden ? 'password' : 'text'}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setHidden((prev) => !prev)}>
            <Icon
              name={hidden ? ICON.INVISIBLE : ICON.VISIBILITY_FILLED}
              size={1.375}
            />
          </IconButton>
        ),
      }}
    />
  );
}
