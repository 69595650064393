import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { OtpInput } from '../custom-input';
import { Icon } from '../icon';
import { contentBox } from './theme';

function VerifyAccount({
  onChangeStage,
  value,
  onChange,
  onSubmit,
  error,
  setError,
  email,
  loading,
}) {
  const { t } = useTranslation(['login', 'common']);

  const handleSubmit = () => {
    if (value.length === 6) {
      onSubmit(value);
      onChange('');
    }
  };
  return (
    <>
      <Collapse
        in={error}
        sx={{ position: 'absolute', top: 0, width: '100vw' }}
      >
        <Alert
          severity='error'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setError(false);
              }}
            >
              <Icon name='close' size={1.5} />
            </IconButton>
          }
        >
          {t('login:invalidcredentials')}
        </Alert>
      </Collapse>
      <Box component='div' sx={{ ...contentBox, width: '45rem' }}>
        <Box
          component='div'
          width='100%'
          display='flex'
          justifyContent='flex-start'
        >
          <IconButton onClick={() => onChangeStage('tfa')}>
            <Icon name='arrow_back' size={2.5} />
          </IconButton>
        </Box>
        <Icon name='new_logo' height={4.75} width={22.5} />
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          textAlign='center'
          alignItems='center'
          gap='1.5rem'
          width='100%'
          marginBottom='2.5rem'
        >
          <Typography className='StageLabel' component='h4'>
            Authentifiziere deinen Account
          </Typography>
          <Typography className='StageDescription' component='h6'>
            {`Deine Sicherheit hat bei uns Priorität. Bitte bestätige deinen
            Account über die Eingabe deines Authentifizierungscode der dir
            zugesendet wurde auf ${email.replace(
              /^[a-zA-Z || \d]{1,}/,
              '*****'
            )}`}
          </Typography>
          <OtpInput
            valueLength={6}
            value={value}
            onChange={(v) => {
              onChange(v);
            }}
          />
        </Box>
        <Box component='div' mb='2.5rem'>
          <Button
            variant='contained'
            className='Further'
            disabled={value?.length !== 6}
            onClick={handleSubmit}
            sx={{ width: '9rem' }}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: '#000000' }} />
            ) : (
              `LOS GEHT'S!`
            )}
          </Button>
        </Box>
        <Box component='div' width='100%' textAlign='center'>
          <Typography component='p' className='Help'>
            Code nicht angekommen? <Link to='/'>Einen neuen Code zusenden</Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default VerifyAccount;
