import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Icon } from 'components/icon';

import React from 'react';

import { cardsData } from './data';

export function TabsCard({
  img,
  title,
  subtitle,
  btnText,
  handleOpenPhases,
  i,
}) {
  return (
    <Box>
      <Card
        sx={{
          width: '23.0625rem',
          height: '23.0625rem',
          p: '1.5rem 0',
          flexShrink: 0,
        }}
      >
        <CardMedia
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name={img} />
        </CardMedia>
        <CardContent>
          <Typography
            className='PSectionTitle'
            sx={{ maxWidth: '18.06rem', textAlign: 'center', margin: '0 auto' }}
          >
            {title}
          </Typography>
          <Typography
            className='PageInfo'
            sx={{ mt: '1.25rem', mb: '0.75rem', height: '3.44rem' }}
          >
            {subtitle}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'felex', justifyContent: 'center', pt: '0.5rem' }}>
          {i ? (
            <Button
              variant='outlined'
              startIcon={
                <Icon name={cardsData[1].btnIco} fill='#45BD81' size='1.5' />
              }
              sx={{
                borderColor: 'rgba(0, 0, 0, 0.12)',
                color: '#45BD81',
                letterSpacing: '0.078rem',
                '&:hover': {
                  background: 'rgb(27%, 74%, 51%, 0.12);',
                  borderColor: 'rgb(27%, 74%, 51%, 0.12)',
                  color: '#45BD81',
                },
              }}
            >
              {cardsData[1].btnText}
            </Button>
          ) : (
            <Button
              onClick={handleOpenPhases}
              variant='contained'
              startIcon={<Icon name='add' fill='#fff' />}
              sx={{
                background: '#45BD81',
                letterSpacing: '0.078rem',
                '&:hover': { background: '#45BD81' },
              }}
            >
              {btnText}
            </Button>
          )}
        </Box>
      </Card>
    </Box>
  );
}
