import {
  Block,
  FactCheck,
  Inventory2,
  LockOpen,
  NoteAdd,
  OpenInNew,
  PublishedWithChanges,
  RestorePage,
  SupervisorAccount,
  Task,
  Verified,
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { routes } from 'routes/routes';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { HomeActivityCardProps } from './home-activity-card.types';
import { useStyles } from './styles';

type ActivityIconProps = {
  messageKey: string;
};

function ActivityIcon({ messageKey }: ActivityIconProps) {
  const iconSx = { color: 'white' };
  switch (messageKey) {
    case 'versioncreated':
      return <NoteAdd sx={iconSx} />;
    case 'versionreject':
      return <Block sx={iconSx} />;
    case 'versionrelease':
      return <LockOpen sx={iconSx} />;
    case 'versionapproveapprove':
      return <LockOpen sx={iconSx} />;
    case 'versionreviewapprove':
      return <FactCheck sx={iconSx} />;
    case 'versionapprovereject':
      return <Block sx={iconSx} />;
    case 'versionreviewreject':
      return <Block sx={iconSx} />;
    case 'versionrestored':
      return <RestorePage sx={iconSx} />;
    case 'filloutinit':
      return <Task sx={iconSx} />;
    case 'versionarchived':
      return <Inventory2 sx={iconSx} />;
    case 'versionaddedapprover':
      return <SupervisorAccount sx={iconSx} />;
    case 'versionaddedreviewer':
      return <SupervisorAccount sx={iconSx} />;
    case 'versionrenew':
      return <Verified sx={iconSx} />;
    case 'renewfilloutcreated':
      return <PublishedWithChanges sx={iconSx} />;
  }
  return <FactCheck sx={iconSx} />;
}

export function HomeActivityCard({
  createdTime,
  message,
  text,
  messageKey = '',
  versionId,
}: HomeActivityCardProps) {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleOnClick = () => {
    if (versionId) {
      navigate(
        `${routes().singleHomeDocument.replace(':id', versionId.toString())}`
      );
    }
  };

  return (
    <Box component='div' className={classes.contentWrapper}>
      <Box component='div' className={classes.mainContentBox}>
        <IconButton disableRipple className={classes.iconButton}>
          <ActivityIcon messageKey={messageKey} />
        </IconButton>
        <Box component='div'>
          <Typography component='p' className={classes.messageStyle}>
            {message}
            {versionId ? (
              <IconButton onClick={handleOnClick}>
                <OpenInNew className={classes.openinStyle} />
              </IconButton>
            ) : (
              ''
            )}
          </Typography>
          <Typography component='p' className={classes.textStyle}>
            {text}
          </Typography>
        </Box>
      </Box>
      <Typography component='p' className={classes.dateStyle}>
        {createdTime}
      </Typography>
    </Box>
  );
}
