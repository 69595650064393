import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    boxStoreWrapper: {
      background: '#7AB8D2',
      width: '100%',
      borderRadius: '0.25rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.5rem',
    },
    cardStoreTitle: {
      fontWeight: `${typography.fontWeightMedium}`,
      fontSize: `${typography.pxToRem(16)} !important`,
      color: '#fff',
      lineHeight: '1.19rem !important',
    },
    cardWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      border: '0.125rem solid',
      background: '#fff',
      borderRadius: '0.25rem',
    },
    cardTitle: {
      color: '#000000DE',
      fontSize: `${typography.pxToRem(16)} !important`,
      fontWeight: `${typography.fontWeightRegular} !important`,
      letterSpacing: '0.03rem',
    },
    cardDescription: {
      color: '#00000099',
      fontSize: `${typography.pxToRem(14)} !important`,
      fontWeight: `${typography.fontWeightRegular} !important`,
      letterSpacing: '0.015rem',
    },
  };
});

export const absIcon = {
  position: 'absolute',
  top: 0,
  right: '1rem',
  padding: 0,
};
