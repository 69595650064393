import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    contentBox: {
      width: '100%',
      gap: `${typography.pxToRem(16)}`,
      display: 'flex',
      flexDirection: 'column',
      padding: `${typography.pxToRem(32)}`,
    },
    modal: {
      width: `${typography.pxToRem(720)}`,
    },
  };
});
