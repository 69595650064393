import type { ChangemanagementSliceType } from 'store/administration/changemanagement/types';
import { generateExtraReducers } from 'utils/utils';

export const initialState: ChangemanagementSliceType = {
  data: null,
  dataStatus: null,
  dataError: null,
  dataLoading: false,
  changeDocumentStatus: null,
  changeDocumentError: null,
  expiryDocumentStatus: null,
  expiryDocumentError: null,
};

export const asyncThunkFetchDepartments = generateExtraReducers({
  initialStateProp: 'data',
  statusKey: 'dataStatus',
  errorKey: 'dataError',
  loadingKey: 'dataLoading',
});

export const asyncThunkSaveChangeDocument = generateExtraReducers({
  initialStateProp: 'data',
  statusKey: 'changeDocumentStatus',
  errorKey: 'changeDocumentError',
});

export const asyncThunkSaveExpiryDocument = generateExtraReducers({
  initialStateProp: 'data',
  statusKey: 'expiryDocumentStatus',
  errorKey: 'expiryDocumentError',
});
