import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography, size }) => {
  return {
    captionStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: `${typography.fontWeightMedium} !important`,
      fontSize: `${typography.pxToRem(size.fontL)} !important`,
      lineHeight: `${typography.pxToRem(size.font24L)} !important`,
      letterSpacing: '0.03rem !important',
      '& > span': {
        color: '#3F97BE',
      },
    },
    processMapModal: {
      width: `${typography.pxToRem(526)}`,
    },
    tableIconModal: {
      width: '30ch',
      padding: `${typography.pxToRem(size.font24L)}`,
    },
    createTypeModal: {
      width: `${typography.pxToRem(400)}`,
    },
    TITitle: {
      background: '#fff',
      color: '#000000',
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      opacity: 1,
      lineHeight: `${typography.pxToRem(size.font2XL)} !important`,
    },
    title: {
      color: '#000000DE',
      letterSpacing: '0.14px !important',
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
    },
    description: {
      color: '#00000099',
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      letterSpacing: '0.25px !important',
    },
    btnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    header: {
      color: '#000000DE',
      letterSpacing: '0.15px !important',
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
    },
    text: {
      color: '#00000099',
      letterSpacing: '0.13px !important',
      fontSize: `${typography.pxToRem(size.fontM)} !important`,
    },
  };
});
