import api from 'api/api';
import { API_URL } from 'api/api-urls';

export const getSupportingDocumentsList = () => {
  return api.get(`${API_URL.supportingDocuments.getAll}`);
};

export const getAllVersions = () => {
  return api.get(`${API_URL.supportingDocuments.getVersions}`);
};

export const getSingleDocument = (id: string) => {
  return api.get(`${API_URL.singleDocument.getSingle}/${id}`);
};

export const addToArchive = (
  id: number,
  data: { password: string; content: string }
) => {
  return api.post(`${API_URL.singleDocument.addToArchive}/${id}`, data);
};
