import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ typography }) => {
  return {
    structureCardBox: {
      background: '#fff',
      width: '16.2rem',
      height: '23rem',
      borderRadius: '0.25rem',
      padding: '1.25rem 1.25rem 0 1.25rem ',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    structureDocumentLabel: {
      color: '#000000DE',
      fontSize: `${typography.pxToRem(14)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.015rem !important',
      marginBottom: '1rem !important',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    structureStepBox: {
      padding: '0.13rem',
      marginBottom: '0.5rem',
      borderRadius: '0.25rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #ECECEC',
    },
    structureStepTitle: {
      fontSize: `${typography.pxToRem(12)} !important`,
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0.013rem !important',
    },
    actionTitle: {
      color: '#000000DE',
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0 !important',
      fontSize: `${typography.pxToRem(24)} !important`,
      marginBottom: '1.5rem !important',
    },
    textfieldsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: '1rem',
    },
    finalBoxWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    finalTitle: {
      fontSize: `${typography.pxToRem(24)} !important`,
      color: '#000000DE',
      fontWeight: `${typography.fontWeightMedium} !important`,
      letterSpacing: '0 !important',
    },
    finalProgress: {
      height: '100% !important',
      backgroundColor: '#DCCEE3 !important',
      borderRadius: '0.25rem',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#6E358C !important',
      },
    },
    finalCaption: {
      fontSize: `${typography.pxToRem(16)} !important`,
      color: '#000000DE',
      fontWeight: `${typography.fontWeightRegular} !important`,
      letterSpacing: '0.008rem !important',
    },
    cycleAudits: {
      fontSize: '1rem',
      color: '#6E358C',
      borderRadius: '0.625rem',
      '& .MuiOutlinedInput-input': {
        color: '#6E358C',
        textTransform: 'capitalize',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '0.625rem',
        cursor: 'pointer',
      },
      '& .MuiOutlinedInput-root:hover': {
        '& > fieldset': {
          borderColor: '#6E358C !important',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        '& > fieldset': {
          borderColor: '#6E358C !important',
        },
      },
      '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: '#6E358C !important',
      },
    },
  };
});
