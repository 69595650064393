// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/fonts/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/assets/fonts/Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/assets/fonts/Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../public/assets/fonts/Roboto-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Roboto';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
  font-weight: 900;
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,+DAA2E;EAC3E,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,+DAA0E;EAC1E,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,+DAAwE;EACxE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,+DAAyE;EACzE,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'Roboto';\n  src: url('../../public/assets/fonts/Roboto-Regular.ttf') format('truetype');\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: 'Roboto';\n  src: url('../../public/assets/fonts/Roboto-Medium.ttf') format('truetype');\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'Roboto';\n  src: url('../../public/assets/fonts/Roboto-Bold.ttf') format('truetype');\n  font-weight: 700;\n}\n\n@font-face {\n  font-family: 'Roboto';\n  src: url('../../public/assets/fonts/Roboto-Black.ttf') format('truetype');\n  font-weight: 900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
