import { Box, Button, TextField, Typography } from '@mui/material';
import { ModalWrapper } from 'wrappers/modal-wrapper/modal-wrapper';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ConfirmWithPasswordModalProps } from './types';

export function ConfirmWithPasswordModal({
  open,
  onClose,
  title,
  description,
  onContinue,
  btnNextLabel = 'Fortsetzen',
  color = 'qsistant',
}: ConfirmWithPasswordModalProps) {
  const { t } = useTranslation('common');
  const [password, setPassword] = useState('');
  const [comment, setComment] = useState('');

  return (
    <ModalWrapper open={open} sx={{ width: '25rem', padding: '1.5rem' }}>
      <Box component='div' mb='1.5rem'>
        <Typography component='h5' className='ModalHeader'>
          {title}
        </Typography>
        <Typography component='p'>{description}</Typography>
      </Box>

      <Box
        component='div'
        display='flex'
        flexDirection='column'
        gap='1rem'
        mb='1.5rem'
      >
        <TextField
          variant='outlined'
          label={t('common:currentpassword')}
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          color={color}
          autoFocus={false}
        />
        <TextField
          variant='outlined'
          label={t('common:commentoptional')}
          type='text'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
          color={color}
          autoFocus={false}
        />
      </Box>
      <Box component='div' display='flex' justifyContent='flex-end'>
        <Button
          variant='text'
          onClick={onClose}
          color={color}
          sx={{ textTransform: 'capitalize' }}
        >
          {t('common:abort')}
        </Button>
        <Button
          variant='text'
          onClick={() =>
            onContinue({
              password,
              comment,
            })
          }
          disabled={password.length < 4}
          color={color}
          sx={{ textTransform: 'capitalize' }}
        >
          {btnNextLabel}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
