import { Box, Button, Typography } from '@mui/material';
import { ModalWrapper } from 'wrappers/modal-wrapper';

import React from 'react';

import { useStyles } from './styles';
import type { SwitchModeModalProps } from './types';

export function SwitchModeModal({
  open,
  onClose,
  onChange,
  header,
  description,
  confirmBtnLabel,
  color = 'qsistant',
}: SwitchModeModalProps) {
  const classes = useStyles();
  return (
    <ModalWrapper open={open} className={classes.modal}>
      <Typography component='h6' className={classes.headerStyle}>
        {header}
      </Typography>
      <Typography component='p' className={classes.descriptionStyle}>
        {description}
      </Typography>
      <Box component='div' className={classes.btnsBox}>
        <Button variant='text' color={color} onClick={onClose}>
          abbrechen
        </Button>
        <Button variant='text' color={color} onClick={onChange}>
          {confirmBtnLabel}
        </Button>
      </Box>
    </ModalWrapper>
  );
}
