import { Alert, Box, TextField } from '@mui/material';
import { DefaultModal } from 'modals/default/default.modal';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../api/api';

export const uploadInputsBox = {
  width: '100%',
  gap: '1rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
};

export function ReleaseConfirmationModal({
  closeModal,
  modal,
  isReview = false,
  handleSuccess,
  signature,
  icon,
  isLoading = false,
}) {
  const [password, setPassword] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation(['release', 'snackbar']);

  const sign = (type) => {
    setError('');
    api
      .post(`/signature/sign/${signature.id}/${type}`, {
        password,
        comment: description,
      })
      .then((r) => handleSuccess(r.data))
      .catch((r) => {
        setError(t(`snackbar:${r.response?.data?.error}`));
      });
  };

  return (
    <DefaultModal
      closeModal={closeModal}
      successLabel={t('release:accept')}
      handleSuccess={() => sign('approve')}
      handleDeny={() => sign('reject')}
      disabled={password.length < 5}
      denyDisabled={password.length < 5}
      modal={modal}
      isLoading={isLoading}
      modalTitle={
        isReview ? t('release:confirmreview') : t('release:confirmrelease')
      }
      icon={icon}
    >
      {error !== '' ? <Alert severity='error'>{error}</Alert> : null}
      <Box sx={{ ...uploadInputsBox }} component='div'>
        <TextField
          color='qsistant'
          variant='outlined'
          label={t('release:confirmpassword')}
          type='password'
          autoFocus
          value={password || ''}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          placeholder={t('release:confirmpassword')}
        />
        <TextField
          color='qsistant'
          variant='outlined'
          autoComplete='off'
          label={t('release:confirmdescription')}
          type='text'
          value={description || ''}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </Box>
    </DefaultModal>
  );
}
