import { Box, IconButton, Typography } from '@mui/material';
import { RevisionItemContext } from 'components/context-menu';
import { Icon } from 'components/icon';
import { ICON } from 'enum/icons.enum';

import React, { useRef, useState } from 'react';

import { useStyles } from '../styles';
import type { VersionItemProps } from '../types';

export function VersionItem({ date, desc, name }: VersionItemProps) {
  const classes = useStyles();
  const iconRef = useRef<HTMLButtonElement>(null);

  const [openInfo, setOpenInfo] = useState<boolean>(false);

  return (
    <>
      <Box component='div' className={classes.itemBox}>
        <Typography component='p' className={classes.itemText}>
          {name}
        </Typography>
        <Typography component='p' className={classes.itemText}>
          {desc}
        </Typography>
        <Typography component='p' className={classes.itemText}>
          {date}
        </Typography>
        <IconButton
          ref={iconRef}
          onClick={(e) => {
            e.stopPropagation();
            setOpenInfo((prev) => !prev);
          }}
          className={classes.iconButton}
        >
          <Icon name={ICON.INFO} size={1} />
        </IconButton>
      </Box>
      <RevisionItemContext
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        anchorEl={iconRef}
        name='Sebastian Eberl'
        status='Freigabe gestartet'
        email='Sebastian.Eberl@qsistant.de'
        ip='IP-Adresse: 123.4.223'
        date='22.04.2023, 14:03'
        description='Die Änderungen von gestern bei Kapitel 3 waren alle korrekt. Wir können das Dokument jetzt so verwenden.'
      />
    </>
  );
}
