import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createNewCustomer,
  deleteConcreteCustomer,
  editConcreteCustomer,
  getAllCustomers,
} from 'api/services/customer.services';
import { generateExtraReducers } from 'utils/utils';

import type { CustomerSliceTypes, CustomerTypes } from './customer.types';

const initialState: CustomerSliceTypes = {
  customers: [],
  modal: false,
  fetchCustomersStatus: null,
  fetchCustomersError: null,
};

const asyncThunkFetchCustomers = generateExtraReducers({
  initialStateProp: 'customers',
  statusKey: 'fetchCustomersStatus',
  errorKey: 'fetchCustomersError',
});

export const fetchCustomers = createAsyncThunk(
  'customers/fetch',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllCustomers();
      dispatch(updateCustomers(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const addCustomer = createAsyncThunk(
  'customers/addCustomer',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await createNewCustomer(data);
      dispatch(updateSingleCustomer(response.data));
      dispatch(handleModal(false));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const editCustomer = createAsyncThunk(
  'customers/editCustomer',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await editConcreteCustomer(data);
      dispatch(fetchCustomers());
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

export const removeCustomer = createAsyncThunk(
  'customer/removeCustomer',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteConcreteCustomer(data);
      dispatch(handleRemoveCustomer(response));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const customerSlice = createSlice({
  name: 'customerSlice',
  initialState,
  reducers: {
    updateCustomers(state, action: PayloadAction<CustomerTypes[]>) {
      state.customers = action.payload;
    },

    updateSingleCustomer(state, action) {
      state.customers = [...state.customers, action.payload];
    },

    handleRemoveCustomer(state, action) {
      state.customers = state.customers.filter(
        (customer) => customer.id !== action.payload
      );
    },

    handleModal(state, action: PayloadAction<boolean>) {
      state.modal = action.payload;
    },
  },

  extraReducers: (builder) => {
    asyncThunkFetchCustomers(builder, fetchCustomers);
  },
});

export const {
  updateCustomers,
  updateSingleCustomer,
  handleModal,
  handleRemoveCustomer,
} = customerSlice.actions;

export default customerSlice.reducer;
