// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/normalize.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./styles/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Global Style for Drag & Drop File Component*/
.dragDropLabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragDropPDF {
  width: 100%;
  min-height: calc(100% - 6.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/*
Hide scrollbar but we still can scroll the page*/
::-webkit-scrollbar {
  display: none;
}

/*PDF Viewer main styles*/
.rpv-default-layout__toolbar {
  background-color: #323639;
  border-top: 1px solid #fff;
  height: 4.625rem !important;
}

.rpv-default-layout__container {
  border: none;
  height: max-content;
}

.rpv-core__inner-page {
  background-color: #323639;
}

.rpv-core__inner-pages {
  max-height: 40rem;
}

.rpv-default-layout__body {
  padding-top: 4.5rem;
}

.rpv-core__viewer--dark {
  height: max-content !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA,8CAA8C;AAC9C;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,+BAA+B;EAC/B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;gDACgD;AAChD;EACE,aAAa;AACf;;AAEA,yBAAyB;AACzB;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["@import './styles/normalize.css';\n@import './styles/fonts.css';\n\n/*Global Style for Drag & Drop File Component*/\n.dragDropLabel {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.dragDropPDF {\n  width: 100%;\n  min-height: calc(100% - 6.5rem);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n/*\nHide scrollbar but we still can scroll the page*/\n::-webkit-scrollbar {\n  display: none;\n}\n\n/*PDF Viewer main styles*/\n.rpv-default-layout__toolbar {\n  background-color: #323639;\n  border-top: 1px solid #fff;\n  height: 4.625rem !important;\n}\n\n.rpv-default-layout__container {\n  border: none;\n  height: max-content;\n}\n\n.rpv-core__inner-page {\n  background-color: #323639;\n}\n\n.rpv-core__inner-pages {\n  max-height: 40rem;\n}\n\n.rpv-default-layout__body {\n  padding-top: 4.5rem;\n}\n\n.rpv-core__viewer--dark {\n  height: max-content !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
