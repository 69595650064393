import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getQuickaccess } from 'api/services/quickaccess.services';

import { asyncThunkFetchDepartments, initialState } from './state';
import type { QuickAccessCategoryType } from './types';

export const fetchQuickaccess = createAsyncThunk(
  'quickaccess/fetch',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getQuickaccess();
      dispatch(updateQuickaccess(response.data));
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      return error;
    }
  }
);

const quickaccessSlice = createSlice({
  name: 'quickaccessSlice',
  initialState,
  reducers: {
    updateQuickaccess(state, action: PayloadAction<QuickAccessCategoryType[]>) {
      return {
        ...state,
        categories: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    asyncThunkFetchDepartments(builder, fetchQuickaccess);
  },
});

export const { updateQuickaccess } = quickaccessSlice.actions;

export default quickaccessSlice.reducer;
