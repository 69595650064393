import { Box } from '@mui/material';

import React, { useMemo } from 'react';

import { useStyles } from './styles';
import type { OtpInputProps } from './types';

const RE_DIGIT = /^\d+$/;

export function OtpInputComponent({
  valueLength,
  onChange,
  value,
  error = false,
}: OtpInputProps) {
  const classes = useStyles();

  const valueItems = useMemo<string[]>(() => {
    const valueArray = value.split('');
    const items = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  }, [value, valueLength]);

  const inputOnChange = (e: React.FormEvent<HTMLDivElement>, idx: number) => {
    const { target } = e;
    const inputElement = target as HTMLInputElement;
    const { nextElementSibling } = inputElement;
    let targetValue = inputElement.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';
    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);
      if (!isTargetValueDigit) {
        return;
      }
      if (nextElementSibling instanceof HTMLElement) {
        nextElementSibling.focus();
      }
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);
      inputElement.blur();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { target } = e;
    const inputElement = target as HTMLInputElement;
    const { previousElementSibling } = inputElement;
    const targetValue = inputElement.value;
    inputElement.setSelectionRange(0, targetValue.length);
    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }
    if (previousElementSibling instanceof HTMLElement) {
      previousElementSibling.focus();
    }
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      target.setSelectionRange(0, target.value.length);
    }
  };

  return (
    <Box component='div' className={classes.otpGroup}>
      {valueItems.map((digit, idx) => (
        <Box
          component='input'
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          type='text'
          inputMode='numeric'
          autoComplete='one-time-code'
          pattern='\d{1}'
          maxLength={valueLength}
          className={classes.otpInput}
          sx={{
            '&:focus': { outlineColor: '#000000CC' },
            borderColor: error ? 'red !important' : '',
            border:
              digit.length > 0 ? '2px solid #000000CC' : '2px solid #0000001F',
          }}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </Box>
  );
}
